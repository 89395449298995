import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { deleteSessionRecordings, getSessionRecordings, resetDeleteSessionRecordings } from "../../../../actions/sessionDetailAction";
import { Fancybox } from "../../../../shared/FancyboxJS";
import BootstrapDataTable from "../../../UI/DataTable/BootstrapDataTable";
import CustomToast from "../../../UI/customToast"; import { cloneDeep, get, isEmpty } from "lodash";
import { CheckboxColumnFormatter, ExpandRowForFilesTab } from "../FilesTab/ColumnFormatter/ColumnFormatter";
import { sortCaret, headerSortingClasses } from "../../../UI/DataTable/Pagination/TableSortingHelpers";
import { convertUtcToLocalInStr, formatBytes, generateZip, getCurrentScreen, handleErrorMsgToastVisiblity } from "../../../../shared/utility";
import { CheckboxHeaderFormatter } from "../FilesTab/ColumnFormatter/HeaderFormatter";
import { FILETABLE_WIDTH_CALCULATION_ARRAY, LOCAL_STORAGE_KEYS } from "../../../../constants/Constants";
import noRecordingsImage from '../../../../assets/images/no-recording.svg'
import storage from "../../../../services/storage";
import { Localize } from "../Localize";
import DeleteModal from "../DeleteModal";


const NoRecordingView = ({ localisedValues, getSessionDetailsData, getSessionRecordingsLoading, recordingList }) => {
    if (getSessionRecordingsLoading) {
        return <p>{localisedValues['locading_recordings']}</p>;
    }
    else {
        const pendingRecordingsCount = get(getSessionDetailsData, 'session.pendingRecordingCount', 0)
        const pendingRecordingMsg = localisedValues['single_pending_recording_label'] ? localisedValues['single_pending_recording_label'].replace("<count>", pendingRecordingsCount) : ''

        return (
            <>
                <div className="image">
                    <img src={noRecordingsImage} alt="No recordings" />
                </div>

                {isEmpty(recordingList) && pendingRecordingsCount > 0 ?
                    <div className="nofile-recording-loader">
                        <div className="loader-icon">
                            <span className="for-icon icon-loader spin"></span>
                        </div>
                        <div className="text">
                            <p>{pendingRecordingMsg}</p>
                        </div>
                    </div> :
                    <p>{localisedValues['norecording_text']}</p>
                }
            </>
        );
    }
}


NoRecordingView.propTypes = {
    localisedValues: PropTypes.object,
    getSessionDetailsData: PropTypes.object,
    getSessionRecordingsLoading: PropTypes.bool,
    recordingList: PropTypes.array
}

const RecordingsTab = ({ meetingID, details, setDetails, fancyRef }) => {
    const dispatch = useDispatch()
    const {
        getSessionRecordingsLoading,
        getSessionRecordingsData,
        getSessionDetailsData,
        deleteSessionRecordingsLoading,
        deleteSessionRecordingsData,
        deleteSessionRecordingsError
    } = useSelector((state) => state.sessionDetail);

    const { language } = useSelector((state) => state)

    const [recordingList, setRecordingList] = useState([])
    const [columnsToShow, setColumnsToShow] = useState(10)
    const columnsToShowRef = useRef(columnsToShow)
    const [selectedRecordings, setSelectedRecordings] = useState([])
    const [isRecordingDownloading, setIsRecordingDownloading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [localisedValues, setLocalisedValues] = useState({});

    //On screen resize show table columns
    useEffect(() => {
        getCurrentScreen(columnsToShowRef, setColumnsToShow, FILETABLE_WIDTH_CALCULATION_ARRAY);
        window.addEventListener("resize", () => {
            getCurrentScreen(columnsToShowRef, setColumnsToShow, FILETABLE_WIDTH_CALCULATION_ARRAY)
        });
    }, []);

    useEffect(() => {
        const curLang = language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
    }, [language.selectedlanguage]);

    useEffect(() => {
        columnsToShowRef.current = columnsToShow;
    }, [columnsToShow]);

    useEffect(() => {
        if (meetingID) {
            const request = { sessionId: meetingID };
            dispatch(getSessionRecordings(request));
        }
        const mainDiv = document.getElementById('session-recordings')
        if (mainDiv) {
            mainDiv.classList.add('active', 'show')
        }
        return () => {
            mainDiv.classList.remove('active', 'show')
        }
    }, [])


    useEffect(() => {
        if (!getSessionRecordingsData) return
        const recording_list = get(getSessionRecordingsData, "data.list", []);
        setRecordingList(recording_list);
    }, [getSessionRecordingsData]);


    const selectChangeHandler = (filePath) => {
        let selected_recordings = [...selectedRecordings]
        if (selected_recordings.includes(filePath)) {
            selected_recordings = selected_recordings.filter((d) => d !== filePath)
        }
        else {
            selected_recordings.push(filePath)
        }

        const updated_recording_list = recordingList.map((d) => ({
            ...d, ISSELECTED: selected_recordings.includes(d.filePath)
        }))

        setRecordingList(updated_recording_list)
        setSelectedRecordings(selected_recordings)
    };

    const selectAllChangeHandler = (e) => {
        const isChecked = e.target.checked;

        let selected_recordings = []
        if (isChecked) {
            selected_recordings = recordingList.map((d) => d.filePath)
        }

        const updated_recording_list = recordingList.map((d) => ({
            ...d, ISSELECTED: isChecked
        }))

        setRecordingList(updated_recording_list)
        setSelectedRecordings(selected_recordings)
    };

    const downloadRecording = (rec) => {
        const a = document.createElement('a');
        a.href = rec.fileUrl;
        a.download = rec.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const uncheckAll = () => {
        const updated_recording_list = recordingList.map((d) => ({
            ...d, ISSELECTED: false
        }))

        setRecordingList(updated_recording_list)
        setSelectedRecordings([])
    }

    const handleSelectedRecordingsDownload = async () => {
        if (isRecordingDownloading) return;
        setIsRecordingDownloading(true)
        const List = recordingList.filter((d) => selectedRecordings.includes(d.filePath));
        const zipPromise = generateZip(List, "-recVideo.zip", meetingID)
        CustomToast.promise(
            zipPromise,
            {
                pending: `${localisedValues['zipping_file']} (${selectedRecordings.length})`,
                success: localisedValues['successfully_downloaded'],
                error: localisedValues['download_failed']
            }
        )

        zipPromise.then(() => {
            setSelectedRecordings([])
            const updated_recording_list = recordingList.map((d) => ({ ...d, ISSELECTED: false }))
            setRecordingList(updated_recording_list)
        })
        setIsRecordingDownloading(false)
    }

    const handleOnDelete = () => {
        const deletedRecordings = selectedRecordings.map((d) => ({ name: d, uploadtype: '0' }))
        const requestPayload = { sessionId: meetingID, Files: deletedRecordings }
        dispatch(deleteSessionRecordings(requestPayload))
    }

    useEffect(() => {
        if (deleteSessionRecordingsLoading) return

        if (deleteSessionRecordingsData) {
            const updatedRecordingList = recordingList.filter((d) => !selectedRecordings.includes(d.filePath))
            setRecordingList(updatedRecordingList)
            if (details?.session?.recordingCount) {
                const updatedDetails = cloneDeep(details)
                updatedDetails.session.recordingCount = updatedDetails.session.recordingCount - selectedRecordings.length
                setDetails(updatedDetails)
            }
            setSelectedRecordings([])
            dispatch(resetDeleteSessionRecordings())
            CustomToast.success(localisedValues['successfully_deleted']);
        }
        else if (deleteSessionRecordingsError) {
            handleErrorMsgToastVisiblity(deleteSessionRecordingsError.message);
        }
        setIsDeleteModalOpen(false)
    }, [deleteSessionRecordingsData, deleteSessionRecordingsError])


    const columns = [
        {
            dataField: "filePath",
            text: "ID",
            hidden: true,
        },
        {
            dataField: "RowSelector",
            text: "",
            formatter: CheckboxColumnFormatter,
            formatExtraData: {
                selectChangeHandler: selectChangeHandler,
                selectAllChangeHandler: selectAllChangeHandler,
            },
            headerAttrs: {
                "data-test": recordingList.length > 0 && recordingList.every((file) => file.ISSELECTED),
            },
            headerFormatter: CheckboxHeaderFormatter,
            headerClasses: "tbl-th-select",
        },
        {
            dataField: "fileName",
            text: localisedValues['file_name'],
            sort: true,
            sortCaret: sortCaret,
            headerClasses: "tbl-th-filename",
            headerSortingClasses,
            formatter: (_cell, row) => (
                <div className="file-name-btn">
                    <Fancybox 
                    fancyRef={fancyRef}
                    options={{
                        Toolbar: {
                         enabled: true,
                   }
                   }}
                    >
                        <button
                            className="btn"
                            title={row.fileName}
                            data-fancybox='session-recordings'
                            data-src={row.fileUrl}
                            data-caption={row.caption}
                            data-width="720"
                            data-height="480"
                            data-type="html5video"
                        >
                            {row.fileName}
                        </button>
                    </Fancybox>
                </div>
            ),
        },
        {
            dataField: "createDateTime",
            text: localisedValues['created_on'],
            hidden: columnsToShow < 3,
            sort: true,
            sortCaret: sortCaret,
            headerClasses: "tbl-th-createdon",
            headerSortingClasses,
            formatter: (_cell, row) => (
                <div className="file-created-on">
                    {convertUtcToLocalInStr(row.createDateTime)}
                </div>
            ),
        },
        {
            dataField: "size",
            text: localisedValues['file_size'],
            hidden: columnsToShow < 4,
            sort: true,
            sortCaret: sortCaret,
            headerClasses: "tbl-th-filesize",
            headerSortingClasses,
            formatter: (_cell, row) => <div className="file-size">{formatBytes(row.size)}</div>,
        },
        {
            dataField: "filepath",
            text: "",
            sort: false,
            headerClasses: "tbl-th-action",
            formatter: (_cell, row) => (
                <div className="button-inline file-action-button-row">
                    <Fancybox 
                       fancyRef={fancyRef}  
                       options={{
                             Toolbar: {
                              enabled: true,
                        }
                        }}>
                        <div className="button session-image-fncy">
                            <button
                                data-fancybox='session-recordings'
                                data-src={row.fileUrl}
                                data-caption={row.caption}
                                className="btn file-action-btn"
                                data-width="720"
                                data-height="480"
                                data-type="html5video"
                            >
                                <span className="for-icon icon-play"></span>
                                <span className="for-text">{localisedValues['play']}</span>
                            </button>
                        </div>
                    </Fancybox>
                    <div className="button">
                        <button className="btn file-action-btn" onClick={() => { downloadRecording(row) }}>
                            <span className="for-icon icon-download"></span>
                            <span className="for-text">{localisedValues['download_key']}</span>
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    const getSelectedRecordingsCount = () => {
        return recordingList.length === selectedRecordings.length ? localisedValues['user_all'] : `(${selectedRecordings.length})`;
    };

    return (
        <div className="tab-pane fade" id="session-recordings" role="tabpanel" data-testid='session-recordings-main'>  
            {recordingList.length === 0 && (
                <div className="no-session-data custom-scroll-div">
                    <div className="no-session-data-in">
                        <div className="no-session-data-box">{<NoRecordingView localisedValues={localisedValues} getSessionDetailsData={getSessionDetailsData} getSessionRecordingsLoading={getSessionRecordingsLoading} recordingList={recordingList} />}</div>
                    </div>
                </div>
            )}
            <div className="session-recording session-files">
                <div className="files-tab-nav">
                    <div className="files-tab-nav-in">
                        {get(getSessionDetailsData, 'session.pendingRecordingCount', 0) > 0 &&
                            <div className="recording-load-file">
                                <div className="recording-load-file-in">
                                    <div className="recording-load-left">
                                        <span className="for-icon icon-loader spin"></span>
                                    </div>
                                    <div className="recording-load-right">
                                        <p>{localisedValues['recording_processing'] ? localisedValues['recording_processing'].replace("<count>", get(getSessionDetailsData, 'session.pendingRecordingCount', 0)) : ''}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {!isEmpty(selectedRecordings) && <div className="button-inline session-image-action-btn">
                            <div className="button" onKeyDown={() => { }} onClick={uncheckAll}>
                                <button type="button" className="btn btn-outline-secondary border-round" data-testid='CancelBtn'>{localisedValues['user_cancel']}</button>
                            </div>
                            {getSessionDetailsData?.session?.ownerUserId === storage.get(LOCAL_STORAGE_KEYS.UCID) &&
                                <div className="button">
                                    <button type="button" className="btn btn-outline-secondary border-round" data-bs-target="#delete-files-modal" onClick={() => setIsDeleteModalOpen(true)} data-testid='DeleteBtn'>{localisedValues['delete']} <span>{getSelectedRecordingsCount()}</span></button>
                                </div>
                            }
                            <div className="button">
                                <button type="button" className="btn btn-primary border-round" onClick={handleSelectedRecordingsDownload} data-testid='DownloadBtn'>{localisedValues['download_key']} <span>{getSelectedRecordingsCount()}</span></button>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="recording-table files-table custom-scroll-div">
                    <BootstrapDataTable
                        rowClasses={(row) => selectedRecordings.includes(row.filePath) ? 'selected-row' : ''}
                        columns={columns}
                        expandRow={ExpandRowForFilesTab(columnsToShow, localisedValues)}
                        tableData={recordingList}
                        noDataMessage={"No data found"}
                        hidePagination={true}
                        tableKey="filePath"
                    />
                </div>
            </div>
            {isDeleteModalOpen && (
                <DeleteModal
                    setIsModalVisible={setIsDeleteModalOpen}
                    selectedFiles={selectedRecordings}
                    loading={deleteSessionRecordingsLoading}
                    handleOnDelete={handleOnDelete}
                    localisedValues={localisedValues}
                    headerTitleLocalisedKey="delete_popup_recordingcount"
                    bodyContentLocalisedKey="delete_recordingpopup_text"
                />
            )}
        </div>
    );
}

RecordingsTab.propTypes = {
    meetingID: PropTypes.string,
    details: PropTypes.object,
    setDetails: PropTypes.func,
    fancyRef: PropTypes.object
}

export default RecordingsTab;