import React from "react";

export const sortCaret = (order, _column) => {
  if (!order) return (
    <span className="th-sort">
      {/* Show Nothing */}
    </span>
  );
  else if (order === "asc")
    return (
      <span className="th-sort th-sort-asc icon-sorting-up"></span>
    );
  else if (order === "desc")
    return (
      <span className="th-sort th-sort-desc icon-sorting-down"></span>
    );
  return null;
};

export const headerSortingClasses = (_column, sortOrder, _isLastSorting, _colIndex) => (
  (sortOrder === 'asc' || sortOrder === "desc") ? 'sortable-active' : ''
);