import { CHECKING_SIGNUP_EMAIL, EVENTS, REGISTER_SIGNUP_USER } from "../reducers/signUp-reducer"
import SignUpService from "../services/signUpService";
import { afterLoginProcess, handleErrorMsgToastVisiblity } from "../shared/utility";
import { dehydrateCheckingEmail, dehydrateRegisterUser } from "../transformers/signUpTransformer"
import { fetchDomainList } from "./onLoadFunctionAction";

const checkingEmailStart = () => {
    return {
        event: EVENTS.CHECKING_SIGNUP_EMAIL,
        type: CHECKING_SIGNUP_EMAIL.START
    }
}

const checkingEmailSuccess = (userdata) => {
    return {
        event: EVENTS.CHECKING_SIGNUP_EMAIL,
        type: CHECKING_SIGNUP_EMAIL.SUCCESS,
        payload: userdata
    }
}

const checkingEmailError = (error) => {
    return {
        event: EVENTS.CHECKING_SIGNUP_EMAIL,
        type: CHECKING_SIGNUP_EMAIL.ERROR,
        payload: error
    }
}

export const checkEmailForSignUp = (email) => async (dispatch) => {
    dispatch(checkingEmailStart());

    SignUpService
        .postCheckingSignUpEmail(dehydrateCheckingEmail(email))
        .then((response) => {
            dispatch(checkingEmailSuccess(response));
        })
        .catch((error) => {
            dispatch(checkingEmailError(error));
        })
}


const registerUserStart = () => {
    return {
        event: EVENTS.REGISTER_SIGNUP_USER,
        type: REGISTER_SIGNUP_USER.START
    }
}

const registerUserSuccess = (userdata) => {
    return {
        event: EVENTS.REGISTER_SIGNUP_USER,
        type: REGISTER_SIGNUP_USER.SUCCESS,
        payload: userdata
    }
}

const registerUserError = (error) => {
    return {
        event: EVENTS.REGISTER_SIGNUP_USER,
        type: REGISTER_SIGNUP_USER.ERROR,
        payload: error
    }
}

export const handleRegisterUser = (formData) => async (dispatch) => {
    dispatch(registerUserStart());

    SignUpService
        .postRegisterUser(dehydrateRegisterUser(formData))
        .then((response) => {
            afterLoginProcess(response);
            dispatch(fetchDomainList())
            dispatch(registerUserSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error.message);
            dispatch(registerUserError(error));
        })
}