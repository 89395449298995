import clsx from "clsx";
import __ from "lodash";
import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { useHistory, useParams } from "react-router-dom";
import { RouteName } from "../../../../constants/Constants";


const SessionSearch = (props) => {
    const {isDiscardChanged,searchTextChangeHandler, localisedValues} = props;
    
    const [isActive, setIsActive] = useState(false);
    const [searchText, setSearchText] = useState('');

    const params = useParams();
    const history = useHistory();

    const isMount = useRef(false);

    useEffect(() => {
        if(!isMount.current){
            isMount.current = true;
            return;
        }
        const timeOutId = setTimeout(() => {
          const updatedSearchText = searchText.trim()
            if(updatedSearchText.length >= 0){
                if (!__.isEmpty(params) && !isDiscardChanged) {
                    history.push(RouteName.SESSIONS);
                }
                searchTextChangeHandler(updatedSearchText);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        document.addEventListener('keyup', (e) => {
            if (e.key === "Escape" || e.code == 27 ) { // escape key maps to keycode `27`
                setIsActive(false);
                setSearchText('')
            }
        })
    }, [])

    const toggleClass = () => {
        document.getElementById('session-search-autoFocus').focus()
        setIsActive(!isActive);
        if(isActive){
            setSearchText('');
        }
    }

    return (
        <div className={clsx("session-search-box clearfix", isActive && "active")}>
            <div className="session-search-btn-row">
                <button className="btn session-search-btn" onClick={toggleClass}>
                    <span className="btn-icon btn-search-icon icon-search"></span>
                    <span className="btn-icon icon-close-thin"></span>
                </button>
            </div>
            <div className="session-search-form">
                <div className="form-group">
                    <input type="text" id="session-search-autoFocus" value={searchText} className="form-control focus-font-bold" placeholder={localisedValues["search"]} onChange={event => setSearchText(event.target.value)}/>
                    <span className="search-form-icon icon-search"></span>
                </div>
            </div>
        </div>
    );
}

SessionSearch.propTypes = {
  isDiscardChanged: PropTypes.bool,
  searchTextChangeHandler: PropTypes.func,
  localisedValues: PropTypes.object,
};

export default SessionSearch;