import CustomToast from "../components/UI/customToast"
import PublicVariables from "../constants/PublicVariables";
import { Localize } from "../components/Sessions/Detail/Localize";
import { EVENTS, GET_ASSIGNEE_LIST, GET_SESSION_DETAIL, GET_SESSION_IMAGES, GET_SESSION_NOTES, GET_SESSION_INVITATIONS, GET_SESSION_FILES, DELETE_SESSION_CHAT_FILES, DELETE_SESSION_IMAGES, PUT_ASSIGNEE_CHANGE, DELETE_SESSION_OCR_FILES, GET_SESSION_RECORDINGS, GET_SESSION_WORDDOC, PUT_ARCHIVE_SESSION, PUT_END_SESSION, MODIFY_SESSION_TABS_DETAILS, DELETE_SESSION_RECORDINGS, PUT_SESSION_REFERENCE_CHANGE } from "../reducers/sessionDetail-reducer"
import sessionDetailService from "../services/sessionDetailService"
import { afterFetchingSessionImageProcess, handleErrorMsgToastVisiblity } from "../shared/utility"
import { reverse } from "lodash";

export const resetAllSessionDetailReducerObj = () => {
  return {
      event: EVENTS.RESET_SESSIONDETAIL_REDUCER,
      type: null,
  }
}

export const resetAllSessionTabsDetails = () => {
  return {
    event: EVENTS.MODIFY_SESSION_TABS_DETAILS,
    type: MODIFY_SESSION_TABS_DETAILS.RESET,
  };
};

//#region GET_SESSION_DETAILS
const getSessionDetailstStart = () => {
  return {
    event: EVENTS.GET_SESSION_DETAIL,
    type: GET_SESSION_DETAIL.START,
  }
}

const getSessionDetailsSuccess = (data) => {
  return {
    event: EVENTS.GET_SESSION_DETAIL,
    type: GET_SESSION_DETAIL.SUCCESS,
    payload: data,
  }
}

const getSessionDetailsError = (error) => {
  return {
    event: EVENTS.GET_SESSION_DETAIL,
    type: GET_SESSION_DETAIL.ERROR,
    payload: error,
  }
}

export const updateSessionDetails = (data) => {
  return {
    event: EVENTS.GET_SESSION_DETAIL,
    type: GET_SESSION_DETAIL.UPDATE,
    payload: data,
  }
}

export const resetSessionDetails = (data) => {
  return {
    event: EVENTS.GET_SESSION_DETAIL,
    type: GET_SESSION_DETAIL.RESET,
    payload: data,
  }
}

export const getSessionDetails = (req) => async (dispatch) => {
  dispatch(getSessionDetailstStart());
  sessionDetailService
    .getSessionDetail(req)
    .then((response) => {
      dispatch(getSessionDetailsSuccess(response));
    })
    .catch((error) => {
      if (error?.code !== 1018) { //When user is not owner of session(sessionVisibility OFF)
        handleErrorMsgToastVisiblity(error?.message || error);
      }
      dispatch(getSessionDetailsError(error));
    });
}
//#endregion

//#region GET_SESSION_NOTES
const getSessionNotesStart = () => {
  return {
    event: EVENTS.GET_SESSION_NOTES,
    type: GET_SESSION_NOTES.START,
  };
};

const getSessionNotesSuccess = (data) => {
  return {
    event: EVENTS.GET_SESSION_NOTES,
    type: GET_SESSION_NOTES.SUCCESS,
    payload: data,
  };
};

const getSessionNotesError = (error) => {
  return {
    event: EVENTS.GET_SESSION_NOTES,
    type: GET_SESSION_NOTES.ERROR,
    payload: error,
  };
};

export const getSessionNotes = (req) => async (dispatch) => {
  dispatch(getSessionNotesStart());
  sessionDetailService
    .getSessionNotes(req)
    .then((response) => {
      dispatch(getSessionNotesSuccess(response)); //success
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(getSessionNotesError(error)); //error
    });
};

//#region GET_SESSION_IMAGES
const getSessionImagesStart = () => {
  return {
    event: EVENTS.GET_SESSION_IMAGES,
    type: GET_SESSION_IMAGES.START,
  };
};

const getSessionImagesSuccess = (data) => {
  return {
    event: EVENTS.GET_SESSION_IMAGES,
    type: GET_SESSION_IMAGES.SUCCESS,
    payload: data,
  };
};

const getSessionImagesError = (error) => {
  return {
    event: EVENTS.GET_SESSION_IMAGES,
    type: GET_SESSION_IMAGES.ERROR,
    payload: error,
  };
};

export const getSessionImages = (req, sessionWaterMarkImage, fromWhere) => async (dispatch) => {
  dispatch(getSessionImagesStart());
  sessionDetailService
    .getSessionImages(req)
    .then((response) => {
      if (response.success > 0) {
        if (fromWhere === "details") {
          dispatch(getSessionImagesSuccess(response)); //success
        } else {
          afterFetchingSessionImageProcess(response, sessionWaterMarkImage).then((d) => {
            dispatch(getSessionImagesSuccess(d)); //success
          })
        }
      }
      else {
        handleErrorMsgToastVisiblity(response.message || response)
        dispatch(getSessionImagesError(response));  //error
      }
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(getSessionImagesError(error)); //error
    });
};
//#endregion

//#region GET_SESSION_RECORDINGS
const getSessionRecordingsStart = () => {
  return {
    event: EVENTS.GET_SESSION_RECORDINGS,
    type: GET_SESSION_RECORDINGS.START,
  };
};

const getSessionRecordingsSuccess = (data) => {
  return {
    event: EVENTS.GET_SESSION_RECORDINGS,
    type: GET_SESSION_RECORDINGS.SUCCESS,
    payload: data,
  };
};

const getSessionRecordingsError = (error) => {
  return {
    event: EVENTS.GET_SESSION_RECORDINGS,
    type: GET_SESSION_RECORDINGS.ERROR,
    payload: error,
  };
};

export const getSessionRecordings = (req) => async (dispatch) => {
  dispatch(getSessionRecordingsStart());
  sessionDetailService
    .getSessionRecordings(req)
    .then((response) => {
      if (response.success > 0) {
        response.data.list = reverse(response.data?.list);
        dispatch(getSessionRecordingsSuccess(response)); //success
      }
      else {
        handleErrorMsgToastVisiblity(response.message || response)
        dispatch(getSessionRecordingsError(response));  //error
      }
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(getSessionRecordingsError(error)); //error
    });
};
//#endregion


//#region GET_SESSION_WORDDOC
const getSessionWordDocStart = () => {
  return {
    event: EVENTS.GET_SESSION_WORDDOC,
    type: GET_SESSION_WORDDOC.START,
  };
};

const getSessionWordDocSuccess = (data) => {
  return {
    event: EVENTS.GET_SESSION_WORDDOC,
    type: GET_SESSION_WORDDOC.SUCCESS,
    payload: data,
  };
};

const getSessionWordDocError = (error) => {
  return {
    event: EVENTS.GET_SESSION_WORDDOC,
    type: GET_SESSION_WORDDOC.ERROR,
    payload: error,
  };
};

export const getSessionWordDoc = (req) => async (dispatch) => {
  dispatch(getSessionWordDocStart());
  sessionDetailService
    .getSessionWordDoc(req)
    .then((response) => {
      dispatch(getSessionWordDocSuccess(response)); //success
    })
    .catch((error) => {
      dispatch(getSessionWordDocError(error)); //error
    });
};
//#endregion

//#region GET_SESSION_INVITATIONS
const getSessionInvitationstStart = () => {
  return {
    event: EVENTS.GET_SESSION_INVITATIONS,
    type: GET_SESSION_INVITATIONS.START,
  }
}

const getSessionInvitationsSuccess = (data) => {
  return {
    event: EVENTS.GET_SESSION_INVITATIONS,
    type: GET_SESSION_INVITATIONS.SUCCESS,
    payload: data,
  }
}

const getSessionInvitationsError = (error) => {
  return {
    event: EVENTS.GET_SESSION_INVITATIONS,
    type: GET_SESSION_INVITATIONS.ERROR,
    payload: error,
  }
}

export const getSessionInvitations = (req) => async (dispatch) => {
  dispatch(getSessionInvitationstStart());
  sessionDetailService
    .getSessionInvitations(req)
    .then((response) => {
      dispatch(getSessionInvitationsSuccess(response));
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(getSessionInvitationsError(error));
    });
}
//#endregion

//#region GET_ASSIGNEE_LIST
const getAssigneeListStart = () => {
  return {
    event: EVENTS.GET_ASSIGNEE_LIST,
    type: GET_ASSIGNEE_LIST.START,
  }
}

const getAssigneeListSuccess = (data) => {
  return {
    event: EVENTS.GET_ASSIGNEE_LIST,
    type: GET_ASSIGNEE_LIST.SUCCESS,
    payload: data,
  }
}

const getAssigneeListError = (error) => {
  return {
    event: EVENTS.GET_ASSIGNEE_LIST,
    type: GET_ASSIGNEE_LIST.ERROR,
    payload: error,
  }
}

export const getAssigneeList = (req) => async (dispatch) => {
  dispatch(getAssigneeListStart());
  sessionDetailService
    .getAssigneeList(req)
    .then((response) => {
      dispatch(getAssigneeListSuccess({ list: response.items, req: req }));
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(getAssigneeListError(error));
    });
}
//#endregion

//#region PUT_ASSIGNEE_CHANGE
const putAssigneeChangeStart = () => {
  return {
    event: EVENTS.PUT_ASSIGNEE_CHANGE,
    type: PUT_ASSIGNEE_CHANGE.START,
  }
}

const putAssigneeChangeSuccess = (data) => {
  return {
    event: EVENTS.PUT_ASSIGNEE_CHANGE,
    type: PUT_ASSIGNEE_CHANGE.SUCCESS,
    payload: data,
  }
}

const putAssigneeChangeError = (error) => {
  return {
    event: EVENTS.PUT_ASSIGNEE_CHANGE,
    type: PUT_ASSIGNEE_CHANGE.ERROR,
    payload: error,
  }
}

export const putAssigneeChange = (req) => async (dispatch) => {
  dispatch(putAssigneeChangeStart());
  sessionDetailService
    .putAssigneeChange(req)
    .then((response) => {
      const lang = PublicVariables.currentLanguage;
      CustomToast.success(Localize[lang]["assignee_updated"]);
      dispatch(putAssigneeChangeSuccess(req)); // as in reaponse, we are getting no content 
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(putAssigneeChangeError(error));
    });
}
//#endregion

const getSessionFilesStart = () => {
  return {
    event: EVENTS.GET_SESSION_FILES,
    type: GET_SESSION_FILES.START,
  };
};

const getSessionFilesSuccess = (data) => {
  return {
    event: EVENTS.GET_SESSION_FILES,
    type: GET_SESSION_FILES.SUCCESS,
    payload: data,
  };
};

const getSessionFilesError = (error) => {
  return {
    event: EVENTS.GET_SESSION_FILES,
    type: GET_SESSION_FILES.ERROR,
    payload: error,
  };
};

export const getSessionFiles = (req) => async (dispatch) => {
  dispatch(getSessionFilesStart());
  sessionDetailService
    .getSessionFiles(req)
    .then((response) => {
      dispatch(getSessionFilesSuccess(response));  //error
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(getSessionFilesError(error)); //error
    });
};
//#endregion

const deleteSessionChatFilesStart = () => {
  return {
    event: EVENTS.DELETE_SESSION_CHAT_FILES,
    type: DELETE_SESSION_CHAT_FILES.START,
  };
};

const deleteSessionChatFilesSuccess = (data) => {
  return {
    event: EVENTS.DELETE_SESSION_CHAT_FILES,
    type: DELETE_SESSION_CHAT_FILES.SUCCESS,
    payload: data,
  }
}

const deleteSessionChatFilesError = (error) => {
  return {
    event: EVENTS.DELETE_SESSION_CHAT_FILES,
    type: DELETE_SESSION_CHAT_FILES.ERROR,
    payload: error,
  }
}

export const resetSessionChatFiles = () => {
  return {
    event: EVENTS.DELETE_SESSION_CHAT_FILES,
    type: DELETE_SESSION_CHAT_FILES.RESET,
  };
};

export const deleteSessionChatFiles = (req) => async (dispatch) => {
  dispatch(deleteSessionChatFilesStart());
  sessionDetailService
    .deleteSessionChatFiles(req)
    .then((response) => {
      dispatch(deleteSessionChatFilesSuccess(response));  //error
    })
    .catch((error) => {
      dispatch(deleteSessionChatFilesError(error)); //error
    });
};
//#endregion

const deleteSessionOcrFilesStart = () => {
  return {
    event: EVENTS.DELETE_SESSION_OCR_FILES,
    type: DELETE_SESSION_OCR_FILES.START,
  };
};

const deleteSessionOcrFilesSuccess = (data) => {
  return {
    event: EVENTS.DELETE_SESSION_OCR_FILES,
    type: DELETE_SESSION_OCR_FILES.SUCCESS,
    payload: data,
  }
}

const deleteSessionOcrFilesError = (error) => {
  return {
    event: EVENTS.DELETE_SESSION_OCR_FILES,
    type: DELETE_SESSION_OCR_FILES.ERROR,
    payload: error,
  }
}

export const resetSessionOcrFiles = () => {
  return {
    event: EVENTS.DELETE_SESSION_OCR_FILES,
    type: DELETE_SESSION_OCR_FILES.RESET,
  };
};

export const deleteSessionOcrFiles = (req) => async (dispatch) => {
  dispatch(deleteSessionOcrFilesStart());
  sessionDetailService
    .deleteSessionOcrFiles(req)
    .then((response) => {
      dispatch(deleteSessionOcrFilesSuccess(response));  //error
    })
    .catch((error) => {
      dispatch(deleteSessionOcrFilesError(error)); //error
    });
};
//#endregion

//#region DELETE_SESSION_IMAGES
const deleteSessionImagesStart = () => {
  return {
    event: EVENTS.DELETE_SESSION_IMAGES,
    type: DELETE_SESSION_IMAGES.START,
  };
};

const deleteSessionImagesSuccess = (data) => {
  return {
    event: EVENTS.DELETE_SESSION_IMAGES,
    type: DELETE_SESSION_IMAGES.SUCCESS,
    payload: data,
  };
};

const deleteSessionImagesError = (error) => {
  return {
    event: EVENTS.DELETE_SESSION_IMAGES,
    type: DELETE_SESSION_IMAGES.ERROR,
    payload: error,
  };
};

export const resetDeleteSessionImages = () => {
  return {
    event: EVENTS.DELETE_SESSION_IMAGES,
    type: DELETE_SESSION_IMAGES.RESET,
  };
};

export const deleteSessionImages = (req) => (dispatch) => {
  dispatch(deleteSessionImagesStart());
  sessionDetailService
    .deleteSessionImages(req)
    .then((response) => {
      if (response?.success > 0) {
        dispatch(deleteSessionImagesSuccess(response)); //success
      }
      else {
        dispatch(deleteSessionImagesError(response));  //error
      }
    })
    .catch((error) => {
      dispatch(deleteSessionImagesError(error)); //error
    });
};
//#endregion

//#region PUT_ARCHIVE_SESSION
const putArchiveSessionStart = () => {
  return {
    event: EVENTS.PUT_ARCHIVE_SESSION,
    type: PUT_ARCHIVE_SESSION.START,
  }
}

const putArchiveSessionSuccess = (data) => {
  return {
    event: EVENTS.PUT_ARCHIVE_SESSION,
    type: PUT_ARCHIVE_SESSION.SUCCESS,
    payload: data,
  }
}

const putArchiveSessionError = (error) => {
  return {
    event: EVENTS.PUT_ARCHIVE_SESSION,
    type: PUT_ARCHIVE_SESSION.ERROR,
    payload: error,
  }
}

export const putArchiveSession = (sessionId) => async (dispatch) => {
  dispatch(putArchiveSessionStart());
  sessionDetailService
    .putArchiveSession(sessionId)
    .then((response) => {
      dispatch(putArchiveSessionSuccess(response));
    })
    .catch((error) => {
      dispatch(putArchiveSessionError(error));
    });
}
//#endregion

//#region PUT_END_SESSION
const putEndSessionStart = () => {
  return {
    event: EVENTS.PUT_END_SESSION,
    type: PUT_END_SESSION.START,
  }
}

const putEndSessionSuccess = (data) => {
  return {
    event: EVENTS.PUT_END_SESSION,
    type: PUT_END_SESSION.SUCCESS,
    payload: data,
  }
}

const putEndSessionError = (error) => {
  return {
    event: EVENTS.PUT_END_SESSION,
    type: PUT_END_SESSION.ERROR,
    payload: error,
  }
}

export const putEndSession = (sessionId) => async (dispatch) => {
  dispatch(putEndSessionStart());
  sessionDetailService
    .putEndSession(sessionId)
    .then((response) => {
      dispatch(putEndSessionSuccess(response));
    })
    .catch((error) => {
      dispatch(putEndSessionError(error));
    });
}
//#endregion

//#region DELETE_SESSION_RECORDINGS
const deleteSessionRecordingsStart = () => {
  return {
    event: EVENTS.DELETE_SESSION_RECORDINGS,
    type: DELETE_SESSION_RECORDINGS.START,
  };
};

const deleteSessionRecordingsSuccess = (data) => {
  return {
    event: EVENTS.DELETE_SESSION_RECORDINGS,
    type: DELETE_SESSION_RECORDINGS.SUCCESS,
    payload: data,
  };
};

const deleteSessionRecordingsError = (error) => {
  return {
    event: EVENTS.DELETE_SESSION_RECORDINGS,
    type: DELETE_SESSION_RECORDINGS.ERROR,
    payload: error,
  };
};

export const resetDeleteSessionRecordings = () => {
  return {
    event: EVENTS.DELETE_SESSION_RECORDINGS,
    type: DELETE_SESSION_RECORDINGS.RESET,
  };
};

export const deleteSessionRecordings = (req) => (dispatch) => {
  dispatch(deleteSessionRecordingsStart());
  sessionDetailService
    .deleteSessionRecordings(req)
    .then((response) => {
      if (response?.success > 0) {
        dispatch(deleteSessionRecordingsSuccess(response)); //success
      }
      else {
        dispatch(deleteSessionRecordingsError(response));  //error
      }
    })
    .catch((error) => {
      dispatch(deleteSessionRecordingsError(error)); //error
    });
};
//#endregion

//#region PUT_REFERENCE_CHANGE
const putReferenceChangeStart = () => {
  return {
    event: EVENTS.PUT_SESSION_REFERENCE_CHANGE,
    type: PUT_SESSION_REFERENCE_CHANGE.START,
  }
}

const putReferenceChangeSuccess = (data) => {
  return {
    event: EVENTS.PUT_SESSION_REFERENCE_CHANGE,
    type: PUT_SESSION_REFERENCE_CHANGE.SUCCESS,
    payload: data,
  }
}

const putReferenceChangeError = (error) => {
  return {
    event: EVENTS.PUT_SESSION_REFERENCE_CHANGE,
    type: PUT_SESSION_REFERENCE_CHANGE.ERROR,
    payload: error,
  }
}

export const putReferenceChangeReset = () => {
  return {
    event: EVENTS.PUT_SESSION_REFERENCE_CHANGE,
    type: PUT_SESSION_REFERENCE_CHANGE.RESET
  }
}

export const putReferenceChange = (req) => async (dispatch) => {
    dispatch(putReferenceChangeStart());
  sessionDetailService
    .putSessionReferenceChange(req)
    .then((response) => {
      dispatch(putReferenceChangeSuccess(req));
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error?.message || error)
      dispatch(putReferenceChangeError(error));
    });
}
//#endregion