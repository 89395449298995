export const CheckboxHeaderFormatter = (column, _colIndex, _components) => {
  const checked = column?.headerAttrs["data-test"];
  return (
    <div className="checkbox checkbox-sm checkbox-border only-checkbox">
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => column?.formatExtraData?.selectAllChangeHandler(e)
          }
          data-testid="checkBoxForColumnOfTable"
        />
        <span className="for-design"></span>
      </label>
    </div>
  );
};
