import { DefaultLanguage, JOURNEY_STEP_TYPES } from "../../../../../constants/Constants";
import PropTypes from 'prop-types';

const StepDescription = ({ dataObj, currentLanguage }) => {
    if (dataObj.props && dataObj.props.description && (dataObj.props.description[currentLanguage] !== '' || dataObj.props.description[DefaultLanguage] !== '')) {
        const isImageWithTextOrTextOnly = dataObj.type === JOURNEY_STEP_TYPES.IMAGE_WITH_TEXT || dataObj.type === JOURNEY_STEP_TYPES.TEXT_ONLY;

        return (
            <div className={isImageWithTextOrTextOnly ? 'step-info' : 'step-desc'}>
                {dataObj.props.description[currentLanguage] && (
                    <p>{dataObj.props.description[currentLanguage]}</p>
                )}
                {!dataObj.props.description[currentLanguage] && dataObj.props.description[DefaultLanguage] && (
                    <p>{dataObj.props.description[DefaultLanguage]}</p>
                )}
            </div>
        );
    }
    return null;
};

StepDescription.propTypes = {
    dataObj: PropTypes.object,
    currentLanguage: PropTypes.string
}

export default StepDescription;
