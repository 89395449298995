export const APP_VERSION = "4.2";

export const BASEURL = process.env.REACT_APP_API_END_POINT;
export const CLIENTAPPID = process.env.REACT_APP_CLIENTAPPID;
export const LOCAL_STORAGE_PREFIX = "Blitzz";
export const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE;
export const CLOUD_STORAGE_KEY = process.env.REACT_APP_STORAGE_URL;
export const JOURNEYDEV_URL = process.env.REACT_APP_JOURNEYDEV_URL;
export const SSO_TOKEN_URL = process.env.REACT_APP_SSO_TOKEN_URL;
export const COBROWSE_SDK_URL = process.env.REACT_APP_COBROWSE_SDK_URL;
export const COBROWSE_LICENSE_KEY = process.env.REACT_APP_COBROWSE_LICENSE_KEY;
export const TERMS_AND_CONDITION_URL = "https://www.blitzz.co/legal/terms/";
export const TUTORIAL_url =
  "https://help.blitzz.co/support/solutions/articles/44002058156-getting-started-on-windows-or-mac";
export const WHITELABLE_TUTORIAL_URL =
  "http://help.videokonnect.com/support/solutions/articles/44002058156-getting-started-on-windows-or-mac";
export const PAGE_TITLE_EXTENSION = " | Blitzz";
export const SETTINGS_URL = "/v1/#/editprofile/profile";
export const REPORTS_URL = "/v1/#/reports";
export const BILLING_URL = "/v1/#/billing/current";
export const JOURNEY_URL = "/journey/";
export const COBROWSE_URL = "/cobrowse/";
export const NEW_ADMIN_URL = "/settings/profile";
export const NEW_ADMIN_REPORTS_URL = "/settings/reports";
export const NEW_ADMIN_BILLING_URL = "/settings/billing/plan";

export const VERSION_KEY = "version";
export const VERSION_VALUE = "v2.0";
export const DEVICETYPE = 4; // 4 - web
export const APPVERSION = "7";
export const RouteName = {
  DEFAULT_ROUTE: "/",
  LOGIN: "/login",
  SSO_TOKEN: "/sso-tokens",
  AUTH_USER: "/auth",
  SESSIONS: "/sessions",
  GET_STARTED: "/get-started",
  CONFIRMATION: "/get-started/confirmation",
  USER_INFO: "/get-started/userinfo",
  COMPANY_INFO: "/get-started/companyinfo",
  INVITES: "/get-started/invites",
  CREATE_SESSION: "/create",
  SEND_CODE: "/sendcode",
  SESSION_DETAILS: "/sessions/details/:sessionId",
  LOGOUT: "/logout",
  CREATE_ACCOUNT: "/create_account",
  RECOVER_PASSWORD: "/recoverpassword/:id",
  CREATE_AND_INVITE: "/createsession"
};

export const LOGGED_IN = "logged_in"

export const TEN_REDORDS_PER_PAGE = 10;
export const TWENTY_REDORDS_PER_PAGE = 20;

export const APIs = {
  API: "api/",
  version: "v1/",
  FRESH_DESK_VERIFY_URL:'support/login',
  REFRESH_USERTOKEN: "refreshtoken",
  LOGIN: "login",
  LOGOUT: "logout",
  EMAIL_VERIFICATION: "login/verify-email",
  RESEND_EMAIL_VERIFICARION: "login/resend-verification-email",
  ISCOMPANYAVAILABLE: "domains/availability?DomainUrl=",
  FORGOT_PASSWORD: "forgot-password",
  RECOVER_PASSWORD: "reset-password",
  SSO_LOGIN: "sso/token-login?Token=",
  AUTH_LOGIN: "auth",
  GET_DOMAIN_CONFIGURATIONS: "domains/configurations",
  SELF_SIGNUP: {
    GET_STARTED_VERIFICATION_CODE: "trial/sendcode?Email=",
    CHECK_OTP: "trial/verify-email",
    COMPANY_TRIAL: "trial/domain",
    INVITE_USER: "Admin/userInvitation_V5",
  },
  GET_LANGUAGE: "domains/language",
  CHECKING_SIGNUP_EMAIL: "verify-user-invitation",
  REGISTER_USER: "user/signup",
  GET_SMSCOUNTRYLIST: "Meeting/GetSmsCountryList",
  GET_JOURNEY_LIST: "journeys",
  GET_DOMAIN_LIST: "users/domains?Skip=0&Limit=50",
  SESSION: {
    GET_OWN_CP_LIST: "collaborationprofiles/own",
    GET_ALL_COUNTRIES: "helper/countries",
    GET_SESSION_USERS:
      "sessions/users?Search={search}&Skip={Skip}&Limit=" +
      TEN_REDORDS_PER_PAGE,
    GET_SESSION_HISTORY: "sessions/history",
    GET_SESSION_INVITATIONS: "sessions/{sessionId}/invitations",
    GET_ACTIVE_USERS: "activeusers",
    GET_SESSION_DETAIL: "sessions/{sessionId}",
    GET_SESSION_NOTES: "sessions/{sessionId}/notes",
    GET_SESSION_WORDFILE:
      "session/{sessionId}/report?TIMEOFFSET={offSet}&TIMEZONE={localTimezone}",
    CREATE_SESSION: "sessions",
    EDIT_SCHEDULE_SESSION: "sessions/{sessionId}/schedule",
    END_SESSION: "sessions/{sessionId}/end",
    ARCHIVE_SESSION: "sessions/{sessionId}/archive",
    UPDATE_SESSION_REFERENCE: "sessions/{sessionId}/reference",
    UPDATE_SESSION_ASSIGNEE: "sessions/{sessionId}/assign/{userId}",
    GET_FILE_SESSION_IMAGES: "v1/Session/GetFiles/{sessionId}?fileType=img",
    GET_FILE_SESSION_RECORDINGS: "v1/Session/GetFiles/{sessionId}?fileType=rec",
    GET_SESSION_WORD_DOC: "/sessions/{sessionId}/report?TimeZone={TimeZone}&TimeOffset={offSet}",
    GET_FILE_SESSION_ALLFILE: "v1/Session/{sessionId}/files",
    DELETE_FILE_SESSION_CHAT: "v1/Session/Files/{sessionId}/chat",
    DELETE_FILE_SESSION_OCR: "v1/Session/Files/{sessionId}/ocr",
    DELETE_FILE_SESSION_IMAGES: "v1/Session/Files/{sessionId}/image",
    DELETE_FILE_SESSION_RECORDINGS: "v1/Session/Files/{sessionId}/recording",
  },
};

export const ACTIONS_LIST = {
  CREATE_SESSION: 'CREATE_SESSION',
  CHANGING_SESSION: 'CHANGING_SESSION',
  SELECT_USER: 'SELECT_USER',
  CHANGE_FILTER: 'CHANGE_FILTER',
  ARCHIVE_SESSION: 'ARCHIVE_SESSION',
  END_SESSION: 'END_SESSION',
  BACK_BTN_CLICK: 'BACK_BTN_CLICK',
  INVITE_FROM_CELL: 'INVITE_FROM_CELL',
  INVITE_CONTACT: 'INVITE_CONTACT',
  BROWSER_BACK_BTN: 'BROWSER_BACK_BTN',
  EDIT_SCHEDULE_SESSION: 'EDIT_SCHEDULE_SESSION',
  JOIN_CALL: 'JOIN_CALL',
  START_JOIN_SESSION: 'START_JOIN_SESSION',
  INVITE_BTN_FROM_MWEB: 'INVITE_BTN_FROM_MWEB'
}

export const DefaultLanguage = "en_US";
export const LocaliseLanguageArray = [
  {
    id: "en_GB",
    name: "English",
    state: "United Kingdom",
    image: "app/assets/image/united-kingdom.svg",
    checked: false,
    class: "uk-flag",
  },
  {
    id: "de_DE",
    name: "Deutsch",
    state: "Deutschland",
    image: "app/assets/image/german.svg",
    checked: false,
    class: "german-flag",
  },
  {
    id: "en_US",
    name: "English",
    state: "United States",
    image: "app/assets/image/UK.svg",
    checked: false,
    class: "us-flag",
  },
  {
    id: "en_AU",
    name: "English",
    state: "Australia",
    image: "app/assets/image/australia.svg",
    checked: false,
    class: "australia-flag",
  },
  {
    id: "es_ES",
    name: "Español",
    state: "España",
    image: "app/assets/image/spain.svg",
    checked: false,
    class: "espana-flag",
  },
  {
    id: "es_MX",
    name: "Español",
    state: "Mexico",
    image: "app/assets/image/mexico.svg",
    checked: false,
    class: "mexico-flag",
  },
  {
    id: "es_AR",
    name: "Español",
    state: "Argentina",
    image: "app/assets/image/argentina.svg",
    checked: false,
    class: "argentina-flag",
  },
  {
    id: "fr_FR",
    name: "Français",
    state: "France",
    image: "app/assets/image/french.svg",
    checked: false,
    class: "france-flag",
  },
  {
    id: "en_CA",
    name: "English",
    state: "Canada",
    image: "app/assets/image/canada.svg",
    checked: false,
    class: "canada-flag",
  },
  {
    id: "fr_CA",
    name: "Français",
    state: "Canada",
    image: "app/assets/image/canada.svg",
    checked: false,
    class: "canada-flag",
  },
  {
    id: "pt_BR",
    name: "Português",
    state: "Brasil",
    image: "app/assets/image/brazil.svg",
    checked: false,
    class: "brasil-flag",
  },
  {
    id: "pt_PT",
    name: "Português",
    state: "Portugal",
    image: "app/assets/image/portugal.svg",
    checked: false,
    class: "portugal-flag",
  },
  {
    id: "it_IT",
    name: "Italian",
    state: "Italy",
    image: "app/assets/images/italy.svg",
    checked: false,
    class: "italy-flag",
  },
  {
    id: "zh_CN",
    name: "中国人",
    state: "China",
    image: "app/assets/images/china.svg",
    checked: false,
    class: "china-flag",
  },
  {
    id: "ja_JP",
    name: "日本",
    state: "Japan",
    image: "app/assets/image/japan.svg",
    checked: false,
    class: "japan-flag",
  },
];
export const TEST_LOCALISE_LANGUAGE_ARRAY = [
  ["English", "en_US"],
  ["Dutch", "de_DE"],
  ["Español", "es_ES"],
  ["Français", "fr_FR"],
  ["Português", "pt_BR"],
  ["Italian", "it_IT"],
  ["China", "zh_CN"],
  ["Japan", "ja_JP"],
];

export const ExpiredAPICode = "-4";
export const errorStatusCode = {
  trailPeriodExpired: 1004,
}
export const PausedKey = "paused";

export const ContactURL = "https://blitzz.co/contact/";
//remove # once Admin portal migrated to React
export const BillingURLPath = "/v1/#/billing/current";

export const BLITZZ_TWO_FACTOR_AUTH = "BLITZZ_TWO_FACTOR_AUTH";

export const Token_time = 6900;

export const SignUpErrorCode1036 = "1036";
export const SignUpErrorCode1037 = "1037";
export const PASSWORD_MIN_LENGHT = 12;
export const TERMS_AND_CONDITIONURL = "https://www.blitzz.co/legal/terms/";
export const PRIVACY_POLICY_URL = "https://www.blitzz.co/legal/privacy-policy/";

export const EMAIL_PATTERN_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+\.?)+|(".+"))@(\[(\d{1,3}\.){3}\d{1,3}]|[a-zA-Z\-0-9]+(\.[a-zA-Z]+)+)$/;
export const SELF_SIGNUP_EMAIL_PATTERN = /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn|icloud)\..+)(.+@.+\..+)$/;

export const ONLY_NUMERIC_REGEX = /^[0-9]*$/;
export const ACCEPT_ANY_VALUE_REGEX = /^.*$/

export const SESSION_TYPES = [
  {
    name: "all",
    value: 1,
    langkey: "user_all",
    class: "",
    activeclass: "all-active",
    isactive: true,
  },
  {
    name: "active",
    value: 2,
    langkey: "session_active",
    class: "active-mark",
    activeclass: "active",
    isactive: false,
  },
  {
    name: "upcoming",
    value: 3,
    langkey: "session_upcoming",
    class: "upcoming-mark",
    activeclass: "active",
    isactive: false,
  },
  {
    name: "closed",
    value: 4,
    langkey: "session_closed",
    class: "closed-mark",
    activeclass: "active",
    isactive: false,
  },
  {
    name: "archived",
    value: 5,
    langkey: "archived",
    class: "archived-mark",
    activeclass: "active",
    isactive: false,
  },
];
export const DEFAULT_SELECTED_SESSION_TYPE = "all";
export const DEFAULT_FILTER_VALUE = SESSION_TYPES.find(
  (sessionType) => sessionType.name === DEFAULT_SELECTED_SESSION_TYPE
).value;

export const FULL_DATE_FORMAT = "DD-MM-YYYY hh:mm:ss A";
export const FORMATTED_DATE = "MMM DD, YYYY hh:mm A";
export const DATE_TIME_FORMAT_24_HOURS = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT_DDMMYYYY = "DD/MM/YYYY";
export const DATETIME_PARSER_FORMAT = "DD-MM-YYYY h:mm A";
export const LONG_DATEPICKER_FORMAT = "ddd MMMM DD, YYYY"
export const TIME_FORMAT = "h:mm A";

export const STRING_NUMBER = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
};

export const MEETING_TYPE = {
  REGULAR: 1,
  SCHEDULED: 2,
  JOURNEY: 3
};

export const DEFAULT_SORT = "CreatedAt";
export const DEFAULT_SORT_ORDER = "descending";
export const SORT_STARTTIME = "StartAt";
export const SORT_DURATION = "CallDuration";
export const UPCOMING_SESSION_TYPE_VALUE = SESSION_TYPES.find(
  (session) => session.name === "upcoming"
).value;
export const ACTIVE_SESSION_TYPE_VALUE = SESSION_TYPES.find(
  (session) => session.name === "active"
).value;
export const SORT_TYPES = [
  { name: "created", value: "CreatedAt", langkey: "user_created", eval: ">=0" }, //for all types of sessions
  {
    name: "scheduled",
    value: SORT_STARTTIME,
    langkey: "scheduled",
    eval: "==3",
  }, //for upcomming sessions only
  { name: "reference", value: "Reference", langkey: "reference", eval: ">=0" }, //for all types of sessions
  { name: "duration", value: SORT_DURATION, langkey: "duration", eval: "!=3" }, //for sessions except upcoming sessions
];
export const SORT_ORDER = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

//Define storage keys as constants to avoid problem typing same keys every time
export const LOCAL_STORAGE_KEYS = {
  SESSION_FILTER: "sessionFilter",
  SESSION_SORT: "sessionStringSort",
  SESSION_SORT_ORDER: "sessionOrderSort",
  USERS_LANGUAGE: "usersLanguage",
  ALL_TOKEN_TIME: "alltokentime",
  TOKEN_EXPIRY_TIME: "tokenexpirytime",
  USER_TOKEN: "uToken",
  SUBSCRIPTION_TYPE: "subscriptiontype",
  HEADER_OBJ: "apiHeaderOBj",
  COMPANY_TOKEN: "cToken",
  USER_DATA: "userdata", // for current portal
  REFRESH_TOKEN: "refreshToken",
  LDFLags: "ldFlags",
  BETA: "beta",
  ALLOW_ZENDESK: "allowzendesk",
  UCID: "ucid",
  CURRENT_COMPANY: "currentcompany",
  IS_TRIAL: "istrial",
  TOOL_NAME: "toolName",
  AFTER_LOGIN: "afterLogin",
  DATA_FOR_SESSION: "dataForSession",
  FROM_SELF_SIGNUP: "fromSelfSignUp",
  FROM_STATE: "fromState",
  USER_INVITED: "userinvited",
  SELECT_ALL: "selectedAll",
  SELECTED_USER_ID: "selecteduserid",
  SELECTED_USER_INFO: "selectuserinfo",
  DOMAIN_CONFIGURATION: "domainConfiguration",
  IDEAL_LOGOUT_FLAG: "idleLogoutFlag",
  FRESH_DESK_URL: "freshDeskUrl",
  IS_CREATED_SESSION: "isCreatedSession",
  SWITCH_TO_NEW_ADMIN: "switchToNewAdmin"
};

export const LOCAL_STORAGE_KEYS_ADMIN = {
  // this keys, we will remove once we start migrating the admin to reactjs
  CONFIG_DATA: "configdata",
  EDITION: "edition",
  COMPANY_LIST: "companylist",
};

export const SUBSCRIPTIONTYPE = {
  USERS: "Users",
  CREDITS: "Credits",
};

export const ON_LOAD = "OnLoad";

export const INVALID_DOMAIN_URL = "https://blitzz.co/invalid-domain/";

export const INVITECONTACT_WIDTH_CALCULATION_ARRAY = [
  { w: 481, showColumns: 1 },
  { w: 767, showColumns: 2 },
  { w: 991, showColumns: 10 },
  { w: 0, showColumns: 10 },
];

export const FILETABLE_WIDTH_CALCULATION_ARRAY = [
  { w: 481, showColumns: 2 },
  { w: 767, showColumns: 3 },
  { w: 991, showColumns: 10 },
  { w: 0, showColumns: 10 },
];

export const USER_ROLE_CODE = {
  superAdmin: "SA",
  administrator: "ADM",
  userManager: "UM",
  developer: "DEV",
  billing: "BL",
  dataAnalyst: "DA",
  internal: "INT",
  external: "EXT"
}
export const CONTACTSPERPAGE = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

export const INITIAL_FILTER = {
  filter: {
    status: 1,
    searchBy: 'NAME',
    search: '',
  },
  sortOrder: "asc",
  sortField: "NAME",
  pageNumber: 1,
  pageSize: CONTACTSPERPAGE[0].value
};

export const SESSION_FILES_TYPE = {
  all: "all",
  chat: "chat",
  extract: "extract",
}

export const allowedCountriesDialCodePattern = "\\+(998|996|995|994|993|992|977|976|975|974|973|972|971|970|968|967|966|965|964|963|962|961|960|886|880|856|855|853|852|850|692|691|690|689|688|687|686|685|683|682|681|680|679|678|677|676|675|674|673|672|670|599|598|597|596|595|594|593|592|591|590|509|508|507|506|505|504|503|502|501|500|423|421|420|389|387|386|385|383|382|381|380|378|377|376|375|374|373|372|371|370|359|358|357|356|355|354|353|352|351|350|299|298|297|291|290|269|268|267|266|265|264|263|262|261|260|258|257|256|255|254|253|252|251|250|249|248|246|245|244|243|242|241|240|239|238|237|236|235|234|233|232|231|230|229|228|227|226|225|224|223|222|221|220|218|216|213|212|211|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44|43|41|40|39|36|34|33|32|31|30|27|20|7|1)"
export const allowedCountries = ["af", "al", "dz", "ad", "ao", "ar", "am", "au", "at", "az", "bh", "bd", "by", "be", "bz", "bj", "bt", "bo", "ba", "bw", "br", "bn", "bg", "bf", "bi", "kh", "cm", "ca", "cg", "cf", "cv", "td", "cl", "cn", "cx", "cc", "co", "cd", "do", "eh", "fk", "gf", "kp", "kr", "ps", "sr", "nf", "sz", "gy", "km", "ck", "cr", "hr", "cy", "cz", "dk", "dj", "tl", "ec", "eg", "sv", "gq", "gn", "er", "ee", "fo", "fj", "fi", "fr", "pf", "ga", "gm", "ge", "de", "gh", "gi", "gr", "gt", "gn", "gn", "gw", "hn", "hk", "hu", "is", "in", "id", "ir", "iq", "ie", "il", "it", "ci", "jp", "jo", "kz", "ke", "ki", "xk", "kw", "kg", "la", "lv", "lb", "ls", "lr", "ly", "li", "lt", "lu", "mo", "mk", "mg", "mw", "my", "mv", "ml", "mt", "mh", "mr", "mu", "mx", "fm", "md", "mc", "mn", "me", "ma", "eh", "mz", "mm", "na", "np", "nl", "nc", "nz", "ne", "ne", "ng", "nu", "no", "om", "pk", "pw", "pa", "gn", "pg", "py", "pe", "ph", "pl", "pt", "pr", "qa", "yt", "re", "ro", "ru", "rw", "ws", "sm", "st", "sa", "sn", "rs", "sc", "sl", "sg", "sk", "si", "sb", "so", "za", "ss", "sd", "es", "lk", "sd", "sz", "se", "ch", "sy", "tw", "tj", "tz", "th", "tg", "to", "tn", "tr", "cy", "tm", "tv", "ug", "ua", "ae", "gb", "us", "uy", "uz", "vu", "va", "ve", "vn", "ye", "zm", "zw", "gl"]

export const SEND_LINK_TYPES = {
  SMS: {
    name: "SMS",
    value: "1"
  },
  WhatsApp:
  {
    name: "WhatsApp",
    value: "2"
  },
  Smartglass: {
    name: "Smartglass",
    value: "4"
  },
  Email: {
    name: "Invite by Email",
    value: "3"
  },
  Other: {
    name: "Other",
    value: "7"
  },
};
export const INVITE_USERS_TYPE = {
  GUEST: "guest",
  SILENT_OBSERVER: "silent_observer"
}

export const WHATSAPP_INVITATION_PREVIEW_TEXT = "<company_name>: Click this link to join your remote session:"
export const JOURNEY_STEP_TYPES = {
  IMAGE_WITH_TEXT: "image_with_text",
  TEXT_ONLY: "text_only",
  MULTI_SELECT: "multiple_select",//input: checkbox
  LONG_TEXT: "long_text",//textarea
  IMAGE: "image_or_camera",//input: file: image
  VIDEO: "video",//input: file
  CONNECTTOAGENT: "connect_to_agent",
  OCR: "scan_text_from_image",
  BUTTON: "redirect_button",
  GPS_LOCATION: "gps_location",
}
export const JOURNEY_STEP_NAMES = {
  image_with_text: "Display Text with Image",
  text_only: "Display Text",
  multiple_select: "Multiple Choice",
  long_text: "Long Text Input",
  image_or_camera: "Image Upload",
  video: "Video Upload",
  connect_to_agent: "Audio/Video Call",
  scan_text_from_image: "Scan text from image (OCR)",
  redirect_button: "Button",
  gps_location: "GPS Location"
}

export const supportedIOSInfo = {
  safari: 12.1,
  device: 12.1,
  deviceForChrome:14.3
}

export const UK_COUNTRY_CODE_NEW = 'uk';
export const UK_COUNTRY_CODE_OLD = 'gb';
export const UK_COUNTRY_DIALCODE = '44';

export const NOT_SUPPORTED_COUNTRY_CODES = ["1"];
export const NOT_SUPPORTED_AREA_CODES = [["689","237","363"]];

export const CREATE_SESSION_FROM = {
  REGULAR: 1,
  PARAMETERISED_URL: 7, //// if creating session on parameterised URL, than set from value to 7 as per decision on Sasktel issue
}
