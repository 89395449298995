import PropTypes from 'prop-types';
import StepTitle from './StepTitle';
import StepDescription from './StepDescription';
import MultiSelectStep from './MultiSelectStep';
import LongTextStep from './LongTextStep';
import OCRStep from './OCRStep';
import GoToStep from './GoToStep';
import UploadImageStep from './UploadImageStep';
import UploadVideoStep from './UploadVideoStep';
import GPSLocationStep from './GPSLocationStep';
import { JOURNEY_STEP_NAMES, JOURNEY_STEP_TYPES } from '../../../../../constants/Constants';

const FormResponse = ({ dataObj, index, answerObj, answerObjID, imageObj, videoObj, language, goToStepButtonActionName, addTitleOfRedirectionButton, localisedValues , fancyRef }) => (
  <div className="form-response-box">
    <div className="form-response-box-in">
      {/* show count as per index */}
      <div className="form-response-box-left">
        <div className="form-response-count">
          <span className="for-text">{index + 1}</span>
        </div>
      </div>
      {/* show answer as per data */}
      <div className="form-response-box-right">
          {/* image with text step's image URL */}
          {dataObj.type === JOURNEY_STEP_TYPES.IMAGE_WITH_TEXT && (
            <div className="text-with-image">
              <img src={dataObj.image_url} />
            </div>
          )}

          {/* Title */}
          <StepTitle dataObj={dataObj} currentLanguage={language.selectedlanguage.id} />

          {/* Description */}
          <StepDescription dataObj={dataObj} currentLanguage={language.selectedlanguage.id} />

          {/* when no data is filled show below div */}
          {dataObj && dataObj.type !== JOURNEY_STEP_TYPES.IMAGE_WITH_TEXT && dataObj.type !== JOURNEY_STEP_TYPES.TEXT_ONLY && (
            (answerObj === null || answerObj === undefined || answerObj?.length === 0) && (
              <div className="step-noanswer">
                <p>{localisedValues["not_answered"]}</p>
              </div>
            )
          )}

          {/* multi-select option step */}
          <MultiSelectStep dataObj={dataObj} answerObj={answerObj} currentLanguage={language.selectedlanguage.id} />

          {/* long text step */}
          <LongTextStep dataObj={dataObj} answerObj={answerObj} />

          {/* OCR step */}
          <OCRStep dataObj={dataObj} answerObj={answerObj} />

          {/* go to step (redirect button) */}
          <GoToStep
            dataObj={dataObj}
            answerObj={answerObj}
            answerObjID={answerObjID}
            currentLanguage={language.selectedlanguage.id}
            goToStepButtonActionName={goToStepButtonActionName}
            addTitleOfRedirectionButton={addTitleOfRedirectionButton}
            localisedValues={localisedValues}
          />

          {/* upload image step */}
          <UploadImageStep dataObj={dataObj} imageObj={imageObj} localisedValues={localisedValues} fancyRef={fancyRef} />

          {/* upload video step */}
          <UploadVideoStep dataObj={dataObj} answerObj={answerObj} videoObj={videoObj} fancyRef={fancyRef} />

          {/* GPS location step */}
          <GPSLocationStep dataObj={dataObj} answerObj={answerObj} imageObj={imageObj} localisedValues={localisedValues} fancyRef={fancyRef} />
      </div>
      {/* show tooltip on count */}
      <div className="response-tooltip-content">
        <div className="response-tooltip-content-in" style={{ display: answerObjID === dataObj.id ? 'block' : 'none' }}>
          <p>{JOURNEY_STEP_NAMES[dataObj.type]}</p>
        </div>
      </div>
    </div>
  </div>
);

FormResponse.propTypes = {
  dataObj: PropTypes.object,
  index: PropTypes.number,
  answerObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  answerObjID: PropTypes.string,
  imageObj: PropTypes.object,
  videoObj: PropTypes.object,
  language: PropTypes.object,
  goToStepButtonActionName: PropTypes.func,
  addTitleOfRedirectionButton: PropTypes.func,
  localisedValues: PropTypes.object,
  fancyRef:PropTypes.object
};

export default FormResponse;