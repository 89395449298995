import React from "react";

const PublicLayout = (WrappedComponent) => (_props) => {
    return (
        <React.Fragment>
            <WrappedComponent />
        </React.Fragment>
    )

}
export default PublicLayout;