export const GET_SESSIONS = {
    START: "GET_SESSIONS_START",
    SUCCESS: "GET_SESSIONS_SUCCESS",
    ERROR: "GET_SESSIONS_ERROR",
    ARCHIVE: "ARCHIVE_SESSION",
    END: "END_SESSION",
    UPDATE: "UPDATE_SESSION"
}

const defaultGetSessionsState = {
    getSessionsLoading: false,
    getSessionsData: false,
    getSessionsError: false,
    archiveSessionData: null,
    endSessionData: null
}

export const GET_SESSION_USERS = {
    START: "GET_SESSION_USERS_START",
    SUCCESS: "GET_SESSION_USERS_SUCCESS",
    ERROR: "GET_SESSION_USERS_ERROR",
    RESET_SESSION_USERS_LIST: "RESET_SESSION_USERS_LIST",
}

const defaultGetSessionUsersState = {
    getSessionUsersLoading: false,
    getSessionUsersData: false,
    getSessionUsersError: false,
}

export const GET_COUNTRIES = {
    START: "GET_COUNTRIES_START",
    SUCCESS: "GET_COUNTRIES_SUCCESS",
    ERROR: "GET_COUNTRIES_ERROR",
}

const defaultCountriesState = {
    getCountriesLoading: false,
    getCountriesData: false,
    getCountriesError: false,
}

export const GET_OWN_CPLIST = {
    START: "GET_OWN_CPLIST_START",
    SUCCESS: "GET_OWN_CPLIST_SUCCESS",
    ERROR: "GET_OWN_CPLIST_ERROR",
    RESET: "GET_OWN_CPLIST_RESET",
}

const defaultGetOwnCPListState = {
    getOwnCPListLoading: false,
    getOwnCPListData: false,
    getOwnCPListError: false,
}

export const CREATE_SESSION = {
    START: "CREATE_SESSION_START",
    SUCCESS: "CREATE_SESSION_SUCCESS",
    ERROR: "CREATE_SESSION_ERROR",
    RESET: "CREATE_SESSION_RESET",
}

const defaultCreateSessionsState = {
    createSessionsLoading: false,
    createSessionsData: false,
    createSessionsError: false,
}

export const INVITE_SESSION = {
    START: "INVITE_SESSION_START",
    SUCCESS: "INVITE_SESSION_SUCCESS",
    ERROR: "INVITE_SESSION_ERROR",
    RESET: "INVITE_SESSION_RESET",
}

const defaultInviteSessionsState = {
    inviteSessionsLoading: false,
    inviteSessionsData: false,
    inviteSessionsError: false,
}

export const EDIT_SCHEDULE_SESSION = {
    START: "EDIT_SCHEDULE_SESSION_START",
    SUCCESS: "EDIT_SCHEDULE_SESSION_SUCCESS",
    ERROR: "EDIT_SCHEDULE_SESSION_ERROR",
    RESET: "EDIT_SCHEDULE_SESSION_RESET",
}

const defaultEditScheduleSessionsState = {
    putScheduleSessionsLoading: false,
    putScheduleSessionsData: false,
    putScheduleSessionsError: false,
}

export const defaultState = {
    ...defaultGetSessionsState,
    ...defaultGetSessionUsersState,
    ...defaultCountriesState,
    ...defaultGetOwnCPListState,
    ...defaultCreateSessionsState,
    ...defaultInviteSessionsState,
    ...defaultEditScheduleSessionsState
}

export const EVENTS = {
    GET_SESSIONS: 'GET_SESSIONS',
    GET_SESSION_USERS: 'GET_SESSION_USERS',
    GET_COUNTRIES: 'GET_COUNTRIES',
    GET_OWN_CPLIST: 'GET_OWN_CPLIST',
    INVITE_SESSION: 'INVITE_SESSION',
    CREATE_SESSION: 'CREATE_SESSION',
    EDIT_SCHEDULE_SESSION: 'EDIT_SCHEDULE_SESSION',
    RESET_SESSIONLIST_REDUCER: 'RESET_SESSIONLIST_REDUCER'
}

const sessionListReducer = (state = defaultState, action = {}) => {
    switch (action.event) {
        case EVENTS.RESET_SESSIONLIST_REDUCER:
            return defaultState;
        case EVENTS.GET_SESSIONS:
            if (action.type === GET_SESSIONS.ARCHIVE) {
                return { ...state, archiveSessionData: action.payload };
            } else if (action.type === GET_SESSIONS.END) {
                return { ...state, endSessionData: action.payload };
            }
            else {
                return {
                    ...state,
                    getSessionsLoading: action.type === GET_SESSIONS.START ,
                    getSessionsData: (action.type === GET_SESSIONS.SUCCESS || action.type === GET_SESSIONS.UPDATE) ? action.payload : false,
                    getSessionsError: action.type === GET_SESSIONS.ERROR ? action.payload : false,
                };
            }
        case EVENTS.GET_SESSION_USERS:
            if(action.type === GET_SESSION_USERS.RESET_SESSION_USERS_LIST){
                return {
                    ...state,
                    getSessionUsersLoading: false,
                    getSessionUsersData: false,
                    getSessionUsersError: false,
                }
            }
            else {
                return {
                    ...state,
                    getSessionUsersLoading: action.type === GET_SESSION_USERS.START ,
                    getSessionUsersData: action.type === GET_SESSION_USERS.SUCCESS ? action.payload : false,
                    getSessionUsersError: action.type === GET_SESSION_USERS.ERROR ? action.payload : false,
                };
            }
        case EVENTS.GET_COUNTRIES:
            return {
                ...state,
                getCountriesLoading: action.type === GET_COUNTRIES.START ,
                getCountriesData: action.type === GET_COUNTRIES.SUCCESS ? action.payload : false,
                getCountriesError: action.type === GET_COUNTRIES.ERROR ? action.payload : false,
            };
        case EVENTS.GET_OWN_CPLIST:
            if (action.type === GET_OWN_CPLIST.RESET) {
                return {
                    ...state, ...defaultGetOwnCPListState
                };
            }
            return {
                ...state,
                getOwnCPListLoading: action.type === GET_OWN_CPLIST.START ,
                getOwnCPListData: action.type === GET_OWN_CPLIST.SUCCESS ? action.payload : false,
                getOwnCPListError: action.type === GET_OWN_CPLIST.ERROR ? action.payload : false,
            };

        case EVENTS.CREATE_SESSION:
            if (action.type === CREATE_SESSION.RESET) {
                return {
                    ...state, ...defaultCreateSessionsState
                };
            }
            return {
                ...state,
                createSessionsLoading: action.type === CREATE_SESSION.START ,
                createSessionsData: action.type === CREATE_SESSION.SUCCESS ? action.payload : false,
                createSessionsError: action.type === CREATE_SESSION.ERROR ? action.payload : false,
            };

        case EVENTS.INVITE_SESSION:
            if (action.type === INVITE_SESSION.RESET) {
                return {
                    ...state, ...defaultInviteSessionsState
                };
            }
            else {
                return {
                    ...state,
                    inviteSessionsLoading: action.type === INVITE_SESSION.START ,
                    inviteSessionsData: action.type === INVITE_SESSION.SUCCESS ? action.payload : false,
                    inviteSessionsError: action.type === INVITE_SESSION.ERROR ? action.payload : false,
                };
            }

            case EVENTS.EDIT_SCHEDULE_SESSION:
                if (action.type === EDIT_SCHEDULE_SESSION.RESET) {
                    return {
                        ...state, ...defaultEditScheduleSessionsState
                    };
                }
                return {
                    ...state,
                    putScheduleSessionsLoading: action.type === EDIT_SCHEDULE_SESSION.START,
                    putScheduleSessionsData: action.type === EDIT_SCHEDULE_SESSION.SUCCESS ? action.payload : false,
                    putScheduleSessionsError: action.type === EDIT_SCHEDULE_SESSION.ERROR ? action.payload : false,
                };
        default:
            return state;
    }
}

export default sessionListReducer;

