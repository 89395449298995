import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PasswordChecklist from "react-password-checklist"    
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import clsx from "clsx";
import { isEmpty } from "lodash";
import history from "../../history";
import PublicVariables from "../../constants/PublicVariables";
import { PASSWORD_MIN_LENGHT, RouteName } from "../../constants/Constants";
import { FormikInputWithIcon } from "../UI/TextInput/FormikInputWithIcon";
import { Localize } from "./Localize";
import { SubmitButton } from "../UI/Button/Button";
import SelfSignup from "./SelfSignup";

const Userinfo = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [localisedValues, setLocalisedValues] = useState({});
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showPasswordPopup, setShowPasswordPopup] = useState(false);
    const [password, setPassword] = useState("");
    const [termsAndCondCheck, setTermsAndCondCheck] = useState(false);
    const [isTermsAndCondFocused, setIsTermsAndCondFocused] = useState(false)

    useEffect(()=>{
        console.log("PublicVariables.trialemail",PublicVariables.trialemail);
        if(!PublicVariables.trialemail || (PublicVariables.trialemail && !PublicVariables.trialemail.email)){
            history.replace(RouteName.GET_STARTED);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

       const handletermsAndCondCheck = () => {
        setTermsAndCondCheck(!termsAndCondCheck);
   }

    const getFirstName = () => {
        if(PublicVariables.trialemail?.hasuserprofile){
            return PublicVariables.trialemail.hasuserprofile.FNAME;
        } else{
            return PublicVariables.trialemail ? PublicVariables.trialemail.FNAME : '';
        }
    }

    const getLastName = () => {
        if( PublicVariables.trialemail?.hasuserprofile){
            return PublicVariables.trialemail.hasuserprofile.LNAME;
        } else{
            return PublicVariables.trialemail ? PublicVariables.trialemail.LNAME : '';
        } 
                        
    }

    return (
      <SelfSignup>
        <div className="signup-in-scroll custom-scroll-div">
          <div className="signup-stepno">{localisedValues["step_1_of_2"]}</div>
          <div className="login-logo-subtext text-center" data-testid="heading">
            <h2>{localisedValues["user_info"]}</h2>
            <h3>{localisedValues["complete_your_account_details_below"]}</h3>
            <p>{localisedValues["create_account_new_ui_desc"]}</p>
          </div>
          <Formik
            initialValues={{
              firstName: getFirstName(),
              lastName: getLastName(),
              password: "",
              termsAndCondCheck: false,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .trim()
                .max(30, localisedValues["crt_acnt_limit_character_msg"])
                .required(localisedValues["crt_acnt_valid_first_name"]),
              lastName: Yup.string()
                .trim()
                .max(30, localisedValues["crt_acnt_limit_character_msg"])
                .required(localisedValues["crt_acnt_enter_last_name"]),
              password: Yup.string()
                .trim()
                .required(localisedValues["password_validation_msg"]),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                setIsSubmitting(false);
                PublicVariables.trialemail.FNAME = values.firstName.trim();
                PublicVariables.trialemail.LNAME = values.lastName.trim();
                PublicVariables.trialemail.UPASSWORD = values.password.trim();
                history.push(RouteName.COMPANY_INFO);
              }, 400);
            }}
          >
            {(formik) => (
              <Form>
                <FormikInputWithIcon
                  divClass="with-icon"
                  id="firstName"
                  type="text"
                  iconSpanClass="valid-icon"
                  className="height-46 focus-font-bold"
                  placeholder={localisedValues["user_first_name"]}
                  touched={formik.touched.firstName}
                  error={formik.errors.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.firstName && !!formik.errors.firstName
                  }
                  iconClassName="icon-user"
                  isCheckValid={
                    !formik.errors.firstName &&
                    !isEmpty(formik.values.firstName)
                  }
                />
                <FormikInputWithIcon
                  divClass="with-icon"
                  id="lastName"
                  type="text"
                  iconSpanClass="valid-icon"
                  className="height-46 focus-font-bold"
                  placeholder={localisedValues["user_last_name"]}
                  touched={formik.touched.lastName}
                  error={formik.errors.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.lastName && !!formik.errors.lastName
                  }
                  iconClassName="icon-user"
                  isCheckValid={
                    !formik.errors.lastName && !isEmpty(formik.values.lastName)
                  }
                />
                <div className="form-group">
                  <div className="form-group-in with-icon">
                    <Field
                      id="password"
                      className="form-control form-control-grey height-46 focus-font-bold"
                      placeholder={localisedValues["user_password"]}
                      name="password"
                      type="password"
                      touched={formik.touched.password}
                      maxlength="20"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        setShowPasswordPopup(false);
                        formik.handleBlur(e);
                      }}
                      onFocus={(_e) => {
                        setShowPasswordPopup(true);
                      }}
                    />
                    <span
                      className={`form-group-icon bg-none ${
                        isPasswordValid && "valid-icon"
                      }`}
                    >
                      {/* onClick={passwordType === "password" ? passwordType = "text" : passwordType ="password"} */}
                      <span
                        className={"form-group-icon-in icon-padlock"}
                      ></span>
                    </span>
                    {showPasswordPopup && (
                      <div className="password-valid-info">
                        <div className="password-valid-info-in">
                          <PasswordChecklist
                            rules={[
                              "minLength",
                              "specialChar",
                              "number",
                              "capital",
                              "lowercase",
                            ]}
                            minLength={PASSWORD_MIN_LENGHT}
                            value={password}
                            iconComponents={{
                              ValidIcon: (
                                <span className="icon-checked-fill blue-icon"></span>
                              ),
                              InvalidIcon: (
                                <span className="icon-checked-fill"></span>
                              ),
                            }}
                            onChange={(isValid) => {
                              isValid === true
                                ? setIsPasswordValid(true)
                                : setIsPasswordValid(false);
                            }}
                            messages={{
                              minLength: localisedValues["min_12_char"],
                              specialChar:
                                localisedValues["at_least_one_spec_char"],
                              number: localisedValues["at_least_one_num_char"],
                              capital:
                                localisedValues["at_least_one_upper_char"],
                              lowercase:
                                localisedValues["at_least_one_lower_char"],
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                            {formik.touched.password && !showPasswordPopup && (formik.errors.password || !isPasswordValid) && (
                                <div className="validation-error">
                                    {formik.errors.password ||
                                        localisedValues["password_validation_msg"]}
                                </div>
                            )}
                </div>

                <div className="checkbox-list padding-bottom-5 checkbox-inline">
                  <div className="checkbox">
                    <label>
                      <Field
                        type="checkbox"
                        name="termsAndCondCheck"
                        checked={termsAndCondCheck}
                        onChange={handletermsAndCondCheck}
                        touched={formik.touched.firstName}
                        onFocus={()=>{
                            setIsTermsAndCondFocused(true);

                        }}
                        onBlur={(e)=>{
                            setIsTermsAndCondFocused(false);
                            formik.handleBlur(e);

                        }}
                      />
                      <span className="for-design"></span>
                      <span>
                        {localisedValues['crt_acnt_accept']}{" "}
                        <a
                          href="https://www.blitzz.co/legal/terms/"
                          rel="noreferrer"
                          target="_blank"
                          className="text-dec-none"
                        >
                          {localisedValues['new_terms_and_conditions']}
                        </a>
                      </span>
                    </label>
                  </div>
                  {formik.touched.termsAndCondCheck && !isTermsAndCondFocused && !termsAndCondCheck && <div className="validation-error">{localisedValues['crt_acnt_term_condition_val_msg']}</div>}
                </div>
                <div className="button-inline text-center padding-top-15 d-flex justify-content-center">
                  <div className="button button-full-width">
                    <SubmitButton
                      className={clsx(
                        "btn btn-primary font-weight-500 btn-with-icon",
                        (isSubmitting ||
                          !formik.isValid ||
                          !termsAndCondCheck || !isPasswordValid) &&
                          "btn-primary-grey-disable"
                      )}
                      id="continue"
                      data-testid="continueButton"
                      name={localisedValues["continue"]}
                      isSubmitting={isSubmitting}
                      disable={
                        isSubmitting || !formik.isValid || !termsAndCondCheck || !isPasswordValid
                      }
                      showLoader={true}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </SelfSignup>
    );
}

const mapStateToPropsUserinfo = (state) => {
    return {
        language: state.language
    }
}

const mapDispatchToPropsUserinfo = (_dispatch) =>{
    return {
        // CheckOTPForSelfSignup: (req) => dispatch(CheckOTPForSelfSignup(req)),
    }
}

Userinfo.propTypes = {
    language: PropTypes.object
}

export default connect(mapStateToPropsUserinfo, mapDispatchToPropsUserinfo)(Userinfo);