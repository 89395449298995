const PageLoader = () => {
  return (
    <div className="page-loader" id="page-loader">
      <div className="page-loader-box">
        <div className="page-loader-row">
          <div className="page-loader-dots"></div>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
