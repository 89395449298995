export const defaultLDFlags = {
    "invite-via-whats-app": false,
    "invite-via-smartglass": false,
    "invite-buddy": false,
    "privacy-policy-link": false,
    "zendesk-integrations": false,
    "reports-feature": false,
    "capture-device-and-google-event": false,
    "reference-required": false,
    "reference-type": false,
    "silent-observer": false,
    "billing": false,
    "schedule-session": false,
    "download-word": false,
    "notes": false,
    "display-bookmark-latlong": false,
    "getbeamer": false,
    "idle-logout": false,
    "help-view": false,
    "invite-via-cisco": false,
    "custom-storage": false,
    "try-beta": false,
    "job-role-emp-id-lic-required": false,
    "add-user-for-user-manager": false,
    "logo-by-call-experience": false,
    "customize-sendcode": false,
    "cb-for-blitzz-portal": false,
}