import __ from "lodash";
import { APIs } from "../constants/Constants";
import api from "./api";

export default class SessionDetailAPI {
    getSessionDetail(req) {
        const getSessionDetail = APIs.SESSION.GET_SESSION_DETAIL
            .replace('{sessionId}', req.sessionId)
        return api.get(getSessionDetail, req);
    }

    putEndSession(sessionId) {
        const endSessionUrl = APIs.SESSION.END_SESSION.replace('{sessionId}', sessionId);
        return api.put(endSessionUrl);
    }

    putArchiveSession(sessionId) {
        const archiveUrl = APIs.SESSION.ARCHIVE_SESSION.replace('{sessionId}', sessionId);
        return api.put(archiveUrl);
    }

    getSessionNotes(req) {
        const getSessionNotes = APIs.SESSION.GET_SESSION_NOTES.replace('{sessionId}', req.sessionId) + `?Skip=${req.skip}&Limit=${req.limit}`
        return api.get(getSessionNotes, req);
    }

    getSessionInvitations(req) {
        return api.get(APIs.SESSION.GET_SESSION_INVITATIONS.replace('{sessionId}', req.sessionId), req);
    }

    putAssigneeChange(req){
        const UPDATE_SESSION_ASSIGNEE = APIs.SESSION.UPDATE_SESSION_ASSIGNEE
                                        .replace('{sessionId}', req.sessionId)
                                        .replace('{userId}', req.userId);

        req = [__.omit(req[0], ['sessionId'])];//Make changes based on requirement
        return api.put(UPDATE_SESSION_ASSIGNEE, req);
    }

    putSessionReferenceChange(req) {
        const UPDATE_SESSION_REFERENCE = APIs.SESSION.UPDATE_SESSION_REFERENCE.replace('{sessionId}', req.sessionId)
        const reqPayload = { reference: req.reference }
        return api.put(UPDATE_SESSION_REFERENCE, reqPayload);
    }

    getAssigneeList(req) {
        const GET_SESSION_USERS = APIs.SESSION.GET_SESSION_USERS
            .replace('{search}', req.SEARCH)
            .replace('{Skip}', req.SKIP);

        return api.get(GET_SESSION_USERS);
    }

    getSessionImages(req) {
        const getSessionImages = APIs.SESSION.GET_FILE_SESSION_IMAGES.replace('{sessionId}', req.sessionId)
        return api.getStorage(getSessionImages, req);
    }

    getSessionRecordings(req) {
        const recordingUrl = APIs.SESSION.GET_FILE_SESSION_RECORDINGS.replace('{sessionId}', req.sessionId)
        return api.getStorage(recordingUrl, req);
    }
    
    getSessionWordDoc(req) {
        const wordDocUrl = APIs.SESSION.GET_SESSION_WORD_DOC
            .replace('{sessionId}', req.sessionId)
            .replace('{TimeZone}', req.timeZone) 
            .replace('{offSet}', req.offSet) 
        return api.getBlob(wordDocUrl);
    }
    

    getSessionFiles(req) {
        const getSessionFiles = APIs.SESSION.GET_FILE_SESSION_ALLFILE.replace('{sessionId}', req.sessionId)
        return api.getStorage(getSessionFiles, req);
    }

    deleteSessionChatFiles(req) {
        const deleteSessionChatFiles = APIs.SESSION.DELETE_FILE_SESSION_CHAT.replace('{sessionId}', req.sessionId);
        return api.deleteStorage(deleteSessionChatFiles, { data: { Files: req.Files } });
    }

    deleteSessionOcrFiles(req) {
        const deleteSessionOcrFiles = APIs.SESSION.DELETE_FILE_SESSION_OCR.replace('{sessionId}', req.sessionId);
        return api.deleteStorage(deleteSessionOcrFiles, { data: { Files: req.Files } });
    }

    deleteSessionImages(req) {
        const deleteSessionImages = APIs.SESSION.DELETE_FILE_SESSION_IMAGES.replace('{sessionId}', req.sessionId)
        return api.deleteStorage(deleteSessionImages, { data: { Files: req.Files } });
    }

    deleteSessionRecordings(req) {
        const deleteSessionRecordings = APIs.SESSION.DELETE_FILE_SESSION_RECORDINGS.replace('{sessionId}', req.sessionId)
        return api.deleteStorage(deleteSessionRecordings, { data: { Files: req.Files } });
    }
}