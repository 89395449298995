import { EVENTS, GET_SESSIONS, GET_SESSION_USERS, GET_COUNTRIES, GET_OWN_CPLIST, CREATE_SESSION, INVITE_SESSION, EDIT_SCHEDULE_SESSION } from "../reducers/sessionList-reducer";
import sessionListService from "../services/sessionListService";
import { handleErrorMsgToastVisiblity } from "../shared/utility";

export const resetAllSessionListReducerObj = () => {
    return {
        event: EVENTS.RESET_SESSIONLIST_REDUCER,
        type: null,
    }
}
//#region GET_SESSION
const getSessionsStart = () => {
    return {
        event: EVENTS.GET_SESSIONS,
        type: GET_SESSIONS.START,
    }
}

const getSessionsSuccess = (data) => {
    return {
        event: EVENTS.GET_SESSIONS,
        type: GET_SESSIONS.SUCCESS,
        payload: data,
    }
}

const getSessionError = (error) => {
    return {
        event: EVENTS.GET_SESSIONS,
        type: GET_SESSIONS.ERROR,
        payload: error,
    }
}

export const updateSessionList = (data) => {
    return {
        event: EVENTS.GET_SESSIONS,
        type: GET_SESSIONS.UPDATE,
        payload: data,
    }
}

export const getSessions = (req) => async (dispatch) => {
    dispatch(getSessionsStart());
    sessionListService
        .getSessions(req)
        .then((response) => {
            dispatch(getSessionsSuccess(response)); //success
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error.message || error)
            dispatch(getSessionError(error)); //error
        });
}

export const handleArchiveSession = (data) => async (dispatch) => {
    dispatch({
        event: EVENTS.GET_SESSIONS,
        type: GET_SESSIONS.ARCHIVE,
        payload: data,
    });
}

export const handleEndSession = (data) => async (dispatch) => {
    dispatch({
        event: EVENTS.GET_SESSIONS,
        type: GET_SESSIONS.END,
        payload: data,
    });
}
//#endregion

//#region GET_COUNTRIES
const getCountriesStart = () => {
    return {
        event: EVENTS.GET_COUNTRIES,
        type: GET_COUNTRIES.START,
    }
}

const getCountriesSuccess = (data) => {
    return {
        event: EVENTS.GET_COUNTRIES,
        type: GET_COUNTRIES.SUCCESS,
        payload: data,
    }
}

const getCountriesError = (error) => {
    return {
        event: EVENTS.GET_COUNTRIES,
        type: GET_COUNTRIES.ERROR,
        payload: error,
    }
}

export const getAllCountries = () => async (dispatch) => {
    dispatch(getCountriesStart());
    sessionListService
        .getCountries()
        .then((response) => {
            dispatch(getCountriesSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error.message || error)
            dispatch(getCountriesError(error));
        });
}
//#endregion

//#region GET_SESSION_USERS
const getSessionUsersStart = () => {
    return {
        event: EVENTS.GET_SESSION_USERS,
        type: GET_SESSION_USERS.START,
    }
}

const getSessionUsersSuccess = (data) => {
    return {
        event: EVENTS.GET_SESSION_USERS,
        type: GET_SESSION_USERS.SUCCESS,
        payload: data,
    }
}

const getSessionUsersError = (error) => {
    return {
        event: EVENTS.GET_SESSION_USERS,
        type: GET_SESSION_USERS.ERROR,
        payload: error,
    }
}

const resetSessionUsersList = () => {
    return {
        event: EVENTS.GET_SESSION_USERS,
        type: GET_SESSION_USERS.RESET_SESSION_USERS_LIST,
        payload: null,
    }
}

export const getSessionUsers = (req) => async (dispatch) => {
    dispatch(getSessionUsersStart());
    sessionListService
        .getSessionUsers(req)
        .then((response) => {
            dispatch(getSessionUsersSuccess({ list: response.items, req: req }));  //success
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error.message || error)
            dispatch(getSessionUsersError({ error: error, req: req })); //error
        });

}

export const resetSessionUsers = () => (dispatch) => {
    dispatch(resetSessionUsersList());
}
//#endregion

//#region GET_OWN_CPLIST
const getOwnCPListStart = () => {
    return {
        event: EVENTS.GET_OWN_CPLIST,
        type: GET_OWN_CPLIST.START,
    }
}

const getOwnCPListSuccess = (data) => {
    return {
        event: EVENTS.GET_OWN_CPLIST,
        type: GET_OWN_CPLIST.SUCCESS,
        payload: data,
    }
}

const getOwnCPListError = (error) => {
    return {
        event: EVENTS.GET_OWN_CPLIST,
        type: GET_OWN_CPLIST.ERROR,
        payload: error,
    }
}

export const resetOwnCPList  = () => (dispatch) => {
    dispatch({
        event: EVENTS.GET_OWN_CPLIST,
        type: GET_OWN_CPLIST.RESET,
        payload: null,
    });
}

export const getOwnCPList = () => async (dispatch) => {
    dispatch(getOwnCPListStart());
    sessionListService
        .getOwnCPList()
        .then((response) => {
            dispatch(getOwnCPListSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error.message || error)
            dispatch(getOwnCPListError(error));
        });
}
//#endregion

//#region CREATE_SESSION
const createSessionStart = () => {
    return {
        event: EVENTS.CREATE_SESSION,
        type: CREATE_SESSION.START,
    }
}

const createSessionSuccess = (data) => {
    return {
        event: EVENTS.CREATE_SESSION,
        type: CREATE_SESSION.SUCCESS,
        payload: data,
    }
}

const createSessionError = (error) => {
    return {
        event: EVENTS.CREATE_SESSION,
        type: CREATE_SESSION.ERROR,
        payload: error,
    }
}

export const createSessionReset = () => {
    return {
        event: EVENTS.CREATE_SESSION,
        type: CREATE_SESSION.RESET,
    }
}

export const createSession = (req) => async (dispatch) => {
    dispatch(createSessionStart());
    sessionListService
        .postCreateSession(req)
        .then((response) => {
            dispatch(createSessionSuccess(response)); //success
        })
        .catch((error) => {
            dispatch(createSessionError(error)); //error
        });
}
//#endregion

//#region INVITE_SESSION
const inviteSessionStart = () => {
    return {
        event: EVENTS.INVITE_SESSION,
        type: INVITE_SESSION.START,
    }
}

const inviteSessionSuccess = (data) => {
    return {
        event: EVENTS.INVITE_SESSION,
        type: INVITE_SESSION.SUCCESS,
        payload: data,
    }
}

const inviteSessionError = (error) => {
    return {
        event: EVENTS.INVITE_SESSION,
        type: INVITE_SESSION.ERROR,
        payload: error,
    }
}

export const inviteSessionReset = () => {
    return {
        event: EVENTS.INVITE_SESSION,
        type: INVITE_SESSION.RESET,
    }
}

export const inviteSession = (req) => async (dispatch) => {
    dispatch(inviteSessionStart());
    sessionListService
        .postInviteSession(req)
        .then((response) => {
            dispatch(inviteSessionSuccess(response)); //success
        })
        .catch((error) => {
            dispatch(inviteSessionError(error)); //error
        });
}
//#endregion


//#region EDIT_SESSION_SCHEDULE
const editSessionScheduleStart = () => {
    return {
        event: EVENTS.EDIT_SCHEDULE_SESSION,
        type: EDIT_SCHEDULE_SESSION.START,
    }
}

const editSessionScheduleSuccess = (data) => {
    return {
        event: EVENTS.EDIT_SCHEDULE_SESSION,
        type: EDIT_SCHEDULE_SESSION.SUCCESS,
        payload: data,
    }
}

const editSessionScheduleError = (error) => {
    return {
        event: EVENTS.EDIT_SCHEDULE_SESSION,
        type: EDIT_SCHEDULE_SESSION.ERROR,
        payload: error,
    }
}

export const editSessionScheduleReset = () => {
    return {
        event: EVENTS.EDIT_SCHEDULE_SESSION,
        type: EDIT_SCHEDULE_SESSION.RESET,
    }
}

export const editScheduleSession = (req) => async (dispatch) => {
    dispatch(editSessionScheduleStart());
    sessionListService
        .putEditScheduleSession(req)
        .then((response) => {
            dispatch(editSessionScheduleSuccess(response)); //success
        })
        .catch((error) => {
            dispatch(editSessionScheduleError(error)); //error
        });
}
//#endregion