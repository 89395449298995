export const Localize = {
    "en_GB": {
        "settings_language": "Language",
        "whats_new": "What's new",
        "invite_guest": "Invite Guest",
        "new_session": "New Session",
        "session_history": "Session History",
        "hi": "Hi,",
        "setttings": "Settings",
        "reports": "Reports",
        "BL-Role": "Billing",
        "recnt_used_device_sign_out": "Sign out",
        "your_subscription_has_been_paused": "Your subscription has been paused",
        "subscription_ended_modal_title": "Your subscription has ended",
        "subscription_paused_description": "Your Subscription has been paused. Please contact our support team to resume.",
        "subscription_ended_modal_subtitle": "Your subscription has just ended :-(",
        "subscription_ended_modal_msg1": "Your subscription has unfortunately come to an end, but don't worry, we're holding onto your account in case you want to extend your subscription.",
        "subscription_ended_modal_msg2": "Please note that your data will be scheduled for deletion 30 days after your subscription ends.",
        "logout": "Logout",
        "okay_cap": "Okay",
        "contact_us": "Contact Us",
        "go_to_billing": "Go to Billing",
        "trial_key": "Trial",
        "days_left": "days left",
        "ends_today": "ends today",
        "upgrade_key": "Upgrade",
        "day_left": "day left",
        "auto_logout_msg": "For security reasons, you have been logged out due to inactivity. Please log in again.",
        "journey_editor": "Journey Editor",
        "cobrowse": "Cobrowse"
    },
    "de_DE": {
        "settings_language": "Sprache",
        "whats_new": "Was gibt's Neues",
        "invite_guest": "Teilnehmer einladen",
        "new_session": "Neue Sitzung",
        "session_history": "Session History",
        "hi": "Hallo,",
        "setttings": "Einstellungen",
        "reports": "Berichte",
        "BL-Role": "Abrechnung",
        "recnt_used_device_sign_out": "Ausloggen",
        "your_subscription_has_been_paused": "Dein Abonnement wurde pausiert",
        "subscription_ended_modal_title": "Ihr Abonnement ist beendet",
        "subscription_paused_description": "Ihr Abonnement wurde pausiert. Bitte kontaktieren Sie unser Support-Team, um fortzufahren.",
        "subscription_ended_modal_subtitle": "Ihr Abonnement ist gerade beendet :-(",
        "subscription_ended_modal_msg1": "Ihr Abonnement ist leider abgelaufen, aber keine Sorge, wir behalten Ihr Konto bei, falls Sie Ihr Abonnement verlängern möchten.",
        "subscription_ended_modal_msg2": "Bitte beachten Sie, dass Ihre Daten 30 Tage nach Ende Ihres Abonnements gelöscht werden sollen.",
        "logout": "Ausloggen",
        "okay_cap": "okay",
        "contact_us": "Kontaktiere uns",
        "go_to_billing": "Gehen Sie zur Abrechnung",
        "trial_key": "Versuch",
        "days_left": "Tage übrig",
        "ends_today": "endet heute",
        "upgrade_key": "Aktualisierung",
        "day_left": "Tag übrig",
        "auto_logout_msg": "Aus Sicherheitsgründen wurden Sie wegen Inaktivität abgemeldet. Bitte melden Sie sich erneut an.",
        "journey_editor": "Reise-Editor",
        "cobrowse": "Cobrowsen"
    },
    "en_US": {
        "settings_language": "Language",
        "whats_new": "What's new",
        "invite_guest": "Invite Guest",
        "new_session": "New Session",
        "session_history": "Session History",
        "hi": "Hi,",
        "setttings": "Settings",
        "reports": "Reports",
        "BL-Role": "Billing",
        "recnt_used_device_sign_out": "Sign out",
        "your_subscription_has_been_paused": "Your subscription has been paused",
        "subscription_ended_modal_title": "Your subscription has ended",
        "subscription_paused_description": "Your Subscription has been paused. Please contact our support team to resume.",
        "subscription_ended_modal_subtitle": "Your subscription has just ended :-(",
        "subscription_ended_modal_msg1": "Your subscription has unfortunately come to an end, but don't worry, we're holding onto your account in case you want to extend your subscription.",
        "subscription_ended_modal_msg2": "Please note that your data will be scheduled for deletion 30 days after your subscription ends.",
        "logout": "Logout",
        "okay_cap": "Okay",
        "contact_us": "Contact Us",
        "go_to_billing": "Go to Billing",
        "trial_key": "Trial",
        "days_left": "days left",
        "ends_today": "ends today",
        "upgrade_key": "Upgrade",
        "day_left": "day left",
        "auto_logout_msg": "For security reasons, you have been logged out due to inactivity. Please log in again.",
        "journey_editor": "Journey Editor",
        "cobrowse": "Cobrowse"
    },
    "en_AU": {
        "settings_language": "Language",
        "whats_new": "What's new",
        "invite_guest": "Invite Guest",
        "new_session": "New Session",
        "session_history": "Session History",
        "hi": "Hi,",
        "setttings": "Settings",
        "reports": "Reports",
        "BL-Role": "Billing",
        "recnt_used_device_sign_out": "Sign out",
        "your_subscription_has_been_paused": "Your subscription has been paused",
        "subscription_ended_modal_title": "Your subscription has ended",
        "subscription_paused_description": "Your Subscription has been paused. Please contact our support team to resume.",
        "subscription_ended_modal_subtitle": "Your subscription has just ended :-(",
        "subscription_ended_modal_msg1": "Your subscription has unfortunately come to an end, but don't worry, we're holding onto your account in case you want to extend your subscription.",
        "subscription_ended_modal_msg2": "Please note that your data will be scheduled for deletion 30 days after your subscription ends.",
        "logout": "Logout",
        "okay_cap": "Okay",
        "contact_us": "Contact Us",
        "go_to_billing": "Go to Billing",
        "trial_key": "Trial",
        "days_left": "days left",
        "ends_today": "ends today",
        "upgrade_key": "Upgrade",
        "day_left": "day left",
        "auto_logout_msg": "For security reasons, you have been logged out due to inactivity. Please log in again.",
        "journey_editor": "Journey Editor",
        "cobrowse": "Cobrowse"
    },
    "es_ES": {
        "settings_language": "Idioma",
        "whats_new": "Qué hay de nuevo",
        "invite_guest": "Invitar Invitado",
        "new_session": "Nueva sesión",
        "session_history": "historia de las sesiones",
        "hi": "Hola,",
        "setttings": "Ajustes",
        "reports": "Informes",
        "BL-Role": "Facturación",
        "recnt_used_device_sign_out": "desconectar",
        "your_subscription_has_been_paused": "Tu suscripción ha sido pausada",
        "subscription_ended_modal_title": "Tu suscripción ha finalizado",
        "subscription_paused_description": "Su suscripción se ha detenido. Comuníquese con nuestro equipo de soporte para reanudar.",
        "subscription_ended_modal_subtitle": "Tu suscripción acaba de finalizar :-(",
        "subscription_ended_modal_msg1": "Desafortunadamente, su suscripción ha finalizado, pero no se preocupe, mantendremos su cuenta en caso de que desee extender su suscripción.",
        "subscription_ended_modal_msg2": "Tenga en cuenta que se programará la eliminación de sus datos 30 días después de que finalice su suscripción.",
        "logout": "Cerrar sesión",
        "okay_cap": "Bueno",
        "contact_us": "Contáctenos",
        "go_to_billing": "Ir a facturación",
        "trial_key": "Juicio",
        "days_left": "Días restantes",
        "ends_today": "termina hoy",
        "upgrade_key": "Potenciar",
        "day_left": "días que quedan",
        "auto_logout_msg": "Por motivos de seguridad, se ha cerrado su sesión por inactividad. Inicie sesión de nuevo.",
        "journey_editor": "Editor de viaje",
        "cobrowse": "Conavegar"
    },
    "es_MX": {
        "settings_language": "Idioma",
        "whats_new": "Qué hay de nuevo",
        "invite_guest": "Invitar Invitado",
        "new_session": "Nueva sesión",
        "session_history": "historia de las sesiones",
        "hi": "Hola,",
        "setttings": "Ajustes",
        "reports": "Informes",
        "BL-Role": "Facturación",
        "recnt_used_device_sign_out": "desconectar",
        "your_subscription_has_been_paused": "Tu suscripción ha sido pausada",
        "subscription_ended_modal_title": "Tu suscripción ha finalizado",
        "subscription_paused_description": "Su suscripción se ha detenido. Comuníquese con nuestro equipo de soporte para reanudar.",
        "subscription_ended_modal_subtitle": "Tu suscripción acaba de finalizar :-(",
        "subscription_ended_modal_msg1": "Desafortunadamente, su suscripción ha finalizado, pero no se preocupe, mantendremos su cuenta en caso de que desee extender su suscripción.",
        "subscription_ended_modal_msg2": "Tenga en cuenta que se programará la eliminación de sus datos 30 días después de que finalice su suscripción.",
        "logout": "Cerrar sesión",
        "okay_cap": "Bueno",
        "contact_us": "Contáctenos",
        "go_to_billing": "Ir a facturación",
        "trial_key": "Juicio",
        "days_left": "Días restantes",
        "ends_today": "termina hoy",
        "upgrade_key": "Potenciar",
        "day_left": "días que quedan",
        "auto_logout_msg": "Por motivos de seguridad, se ha cerrado su sesión por inactividad. Inicie sesión de nuevo.",
        "journey_editor": "Editor de viaje",
        "cobrowse": "Conavegar"
    },
    "es_AR": {
        "settings_language": "Idioma",
        "whats_new": "Qué hay de nuevo",
        "invite_guest": "Invitar Invitado",
        "new_session": "Nueva sesión",
        "session_history": "historia de las sesiones",
        "hi": "Hola,",
        "setttings": "Ajustes",
        "reports": "Informes",
        "BL-Role": "Facturación",
        "recnt_used_device_sign_out": "desconectar",
        "your_subscription_has_been_paused": "Tu suscripción ha sido pausada",
        "subscription_ended_modal_title": "Tu suscripción ha finalizado",
        "subscription_paused_description": "Su suscripción se ha detenido. Comuníquese con nuestro equipo de soporte para reanudar.",
        "subscription_ended_modal_subtitle": "Tu suscripción acaba de finalizar :-(",
        "subscription_ended_modal_msg1": "Desafortunadamente, su suscripción ha finalizado, pero no se preocupe, mantendremos su cuenta en caso de que desee extender su suscripción.",
        "subscription_ended_modal_msg2": "Tenga en cuenta que se programará la eliminación de sus datos 30 días después de que finalice su suscripción.",
        "logout": "Cerrar sesión",
        "okay_cap": "Bueno",
        "contact_us": "Contáctenos",
        "go_to_billing": "Ir a facturación",
        "trial_key": "Juicio",
        "days_left": "Días restantes",
        "ends_today": "termina hoy",
        "upgrade_key": "Potenciar",
        "day_left": "días que quedan",
        "auto_logout_msg": "Por motivos de seguridad, se ha cerrado su sesión por inactividad. Inicie sesión de nuevo.",
        "journey_editor": "Editor de viajes",
        "cobrowse": "Conavegar"
    },
    "fr_FR": {
        "settings_language": "La langue",
        "whats_new": "Quoi de neuf",
        "invite_guest": "Inviter un invité",
        "new_session": "Nouvelle session",
        "session_history": "session Histoire",
        "hi": "Salut,",
        "setttings": "Réglages",
        "reports": "Rapports",
        "BL-Role": "Facturation",
        "recnt_used_device_sign_out": "Déconnexion",
        "your_subscription_has_been_paused": "Votre abonnement a été suspendu",
        "subscription_ended_modal_title": "Votre abonnement est terminé",
        "subscription_paused_description": "Votre abonnement a été suspendu. Veuillez contacter notre équipe d'assistance pour reprendre.",
        "subscription_ended_modal_subtitle": "Votre abonnement vient de se terminer :-(",
        "subscription_ended_modal_msg1": "Votre abonnement est malheureusement arrivé à son terme, mais ne vous inquiétez pas, nous conservons votre compte au cas où vous souhaiteriez prolonger votre abonnement.",
        "subscription_ended_modal_msg2": "Veuillez noter que la suppression de vos données sera programmée 30 jours après la fin de votre abonnement.",
        "logout": "Connectez - Out",
        "okay_cap": "d'accord",
        "contact_us": "Contactez nous",
        "go_to_billing": "Aller à la facturation",
        "trial_key": "Procès",
        "days_left": "Jours restants",
        "ends_today": "se termine aujourd'hui",
        "upgrade_key": "Améliorer",
        "day_left": "jour restant",
        "auto_logout_msg": "Pour des raisons de sécurité, vous avez été déconnecté pour cause d'inactivité. Veuillez vous reconnecter.",
        "journey_editor": "Éditeur de parcours",
        "cobrowse": "Co-navigation"
    },
    "en_CA": {
        "settings_language": "Language",
        "whats_new": "What's new",
        "invite_guest": "Invite Guest",
        "new_session": "New Session",
        "session_history": "Session History",
        "hi": "Hi,",
        "setttings": "Settings",
        "reports": "Reports",
        "BL-Role": "Billing",
        "recnt_used_device_sign_out": "Sign out",
        "your_subscription_has_been_paused": "Your subscription has been paused",
        "subscription_ended_modal_title": "Your subscription has ended",
        "subscription_paused_description": "Your Subscription has been paused. Please contact our support team to resume.",
        "subscription_ended_modal_subtitle": "Your subscription has just ended :-(",
        "subscription_ended_modal_msg1": "Your subscription has unfortunately come to an end, but don't worry, we're holding onto your account in case you want to extend your subscription.",
        "subscription_ended_modal_msg2": "Please note that your data will be scheduled for deletion 30 days after your subscription ends.",
        "logout": "Logout",
        "okay_cap": "Okay",
        "contact_us": "Contact Us",
        "go_to_billing": "Go to Billing",
        "trial_key": "Trial",
        "days_left": "days left",
        "ends_today": "ends today",
        "upgrade_key": "Upgrade",
        "day_left": "day left",
        "auto_logout_msg": "For security reasons, you have been logged out due to inactivity. Please log in again.",
        "journey_editor": "Journey Editor",
        "cobrowse": "Cobrowse"
    },
    "fr_CA": {
        "settings_language": "La langue",
        "whats_new": "Quoi de neuf",
        "invite_guest": "Inviter un invité",
        "new_session": "Nouvelle session",
        "session_history": "session Histoire",
        "hi": "Salut,",
        "setttings": "Réglages",
        "reports": "Rapports",
        "BL-Role": "Facturation",
        "recnt_used_device_sign_out": "Déconnexion",
        "your_subscription_has_been_paused": "Votre abonnement a été suspendu",
        "subscription_ended_modal_title": "Votre abonnement est terminé",
        "subscription_paused_description": "Votre abonnement a été suspendu. Veuillez contacter notre équipe d'assistance pour reprendre.",
        "subscription_ended_modal_subtitle": "Votre abonnement vient de se terminer :-(",
        "subscription_ended_modal_msg1": "Votre abonnement est malheureusement arrivé à son terme, mais ne vous inquiétez pas, nous conservons votre compte au cas où vous souhaiteriez prolonger votre abonnement.",
        "subscription_ended_modal_msg2": "Veuillez noter que la suppression de vos données sera programmée 30 jours après la fin de votre abonnement.",
        "logout": "Connectez - Out",
        "okay_cap": "d'accord",
        "contact_us": "Contactez nous",
        "go_to_billing": "Aller à la facturation",
        "trial_key": "Procès",
        "days_left": "Jours restants",
        "ends_today": "se termine aujourd'hui",
        "upgrade_key": "Améliorer",
        "day_left": "jour restant",
        "auto_logout_msg": "Pour des raisons de sécurité, vous avez été déconnecté pour cause d'inactivité. Veuillez vous reconnecter.",
        "journey_editor": "Éditeur de parcours",
        "cobrowse": "Co-navigation"
    },
    "pt_BR": {
        "settings_language": "Idioma",
        "whats_new": "O que há de novo",
        "invite_guest": "Convidar Convidado",
        "new_session": "Nova Sessão",
        "session_history": "Histórico de sessões",
        "hi": "Oi,",
        "setttings": "Definições",
        "reports": "Relatórios",
        "BL-Role": "Cobrança",
        "recnt_used_device_sign_out": "Sair",
        "your_subscription_has_been_paused": "Sua assinatura foi pausada",
        "subscription_ended_modal_title": "Sua assinatura terminou",
        "subscription_paused_description": "Sua assinatura foi pausada. Entre em contato com nossa equipe de suporte para retomar.",
        "subscription_ended_modal_subtitle": "Sua assinatura acabou de terminar :-(",
        "subscription_ended_modal_msg1": "Infelizmente, sua assinatura chegou ao fim, mas não se preocupe, estamos mantendo sua conta caso você queira prorrogar sua assinatura.",
        "subscription_ended_modal_msg2": "Observe que seus dados serão programados para exclusão 30 dias após o término de sua assinatura.",
        "logout": "Sair",
        "okay_cap": "OK",
        "contact_us": "Contate-Nos",
        "go_to_billing": "Vá para Faturamento",
        "trial_key": "Tentativas",
        "days_left": "dias restantes",
        "ends_today": "termina hoje",
        "upgrade_key": "Melhoria",
        "day_left": "dia restante",
        "auto_logout_msg": "Por motivos de segurança, você foi desconectado por inatividade. Por favor faça login novamente.",
        "journey_editor": "Editor de Jornada",
        "cobrowse": "Co-navegar"
    },
    "pt_PT": {
        "settings_language": "Língua",
        "whats_new": "O que há de novo",
        "invite_guest": "Convidar Convidado",
        "new_session": "Nova Sessão",
        "session_history": "História sessão",
        "hi": "Oi,",
        "setttings": "Definições",
        "reports": "Relatórios",
        "BL-Role": "Cobrança",
        "recnt_used_device_sign_out": "Sair",
        "your_subscription_has_been_paused": "Sua assinatura foi pausada",
        "subscription_ended_modal_title": "Sua assinatura terminou",
        "subscription_paused_description": "Sua assinatura foi pausada. Entre em contato com nossa equipe de suporte para retomar.",
        "subscription_ended_modal_subtitle": "Sua assinatura acabou de terminar :-(",
        "subscription_ended_modal_msg1": "Infelizmente, sua assinatura chegou ao fim, mas não se preocupe, estamos mantendo sua conta caso você queira prorrogar sua assinatura.",
        "subscription_ended_modal_msg2": "Observe que seus dados serão programados para exclusão 30 dias após o término de sua assinatura.",
        "logout": "Sair",
        "okay_cap": "OK",
        "contact_us": "Contate-Nos",
        "go_to_billing": "Vá para Faturamento",
        "trial_key": "Tentativas",
        "days_left": "dias restantes",
        "ends_today": "termina hoje",
        "upgrade_key": "Melhoria",
        "day_left": "dia restante",
        "auto_logout_msg": "Por motivos de segurança, você foi desconectado por inatividade. Por favor faça login novamente.",
        "journey_editor": "Editor de Jornada",
        "cobrowse": "Co-navegar"
    },
    "it_IT": {
        "settings_language": "Linguaggio",
        "whats_new": "Cosa c'è di nuovo",
        "invite_guest": "Invita ospite",
        "new_session": "Nuova sessione",
        "session_history": "Cronologia della sessione",
        "hi": "Ciao,",
        "setttings": "Impostazioni",
        "reports": "Rapporti",
        "BL-Role": "Fatturazione",
        "recnt_used_device_sign_out": "disconnessione",
        "your_subscription_has_been_paused": "Il tuo abbonamento è stato sospeso",
        "subscription_ended_modal_title": "Il tuo abbonamento è terminato",
        "subscription_paused_description": "Il tuo abbonamento è stato sospeso. Si prega di contattare il nostro team di supporto per riprendere.",
        "subscription_ended_modal_subtitle": "Il tuo abbonamento è appena terminato :-(",
        "subscription_ended_modal_msg1": "Purtroppo il tuo abbonamento è scaduto, ma non preoccuparti, stiamo trattenendo il tuo account nel caso tu voglia prolungare l'abbonamento.",
        "subscription_ended_modal_msg2": "Tieni presente che i tuoi dati saranno programmati per l'eliminazione 30 giorni dopo la fine dell'abbonamento.",
        "logout": "Disconnettersi",
        "okay_cap": "Va bene",
        "contact_us": "Contattaci",
        "go_to_billing": "Vai a Fatturazione",
        "trial_key": "Prova",
        "days_left": "giorni rimasti",
        "ends_today": "finisce oggi",
        "upgrade_key": "Aggiornamento",
        "day_left": "giorno passato",
        "auto_logout_msg": "Per motivi di sicurezza, sei stato disconnesso per inattività. Per favore esegui l'accesso di nuovo.",
        "journey_editor": "Editore di viaggio",
        "cobrowse": "Cobrowse"
    },
    "zh_CN": {
        "settings_language": "语",
        "whats_new": "什么是新的",
        "invite_guest": "邀请客人",
        "new_session": "新会话",
        "session_history": "会话历史",
        "hi": "你好，",
        "setttings": "设置",
        "reports": "报告",
        "BL-Role": "计费",
        "recnt_used_device_sign_out": "登出",
        "your_subscription_has_been_paused": "您的订阅已暂停",
        "subscription_ended_modal_title": "您的订阅已结束",
        "subscription_paused_description": "您的订阅已暂停。请联系我们的支持团队恢复。",
        "subscription_ended_modal_subtitle": "您的订阅刚刚结束:-(",
        "subscription_ended_modal_msg1": "不幸的是，您的订阅已结束，但请放心，我们会保留您的帐户，以防您想延长订阅。",
        "subscription_ended_modal_msg2": "请注意，您的数据将安排在订阅结束 30 天后删除。",
        "logout": "登出",
        "okay_cap": "好的",
        "contact_us": "联系我们",
        "go_to_billing": "转到结算",
        "trial_key": "审判",
        "days_left": "还剩几天",
        "ends_today": "今天结束",
        "upgrade_key": "升级",
        "day_left": "还剩一天",
        "auto_logout_msg": "出于安全原因，您已因不活动而被注销。请重新登录。",
        "journey_editor": "旅程编辑器",
        "cobrowse": "协同浏览"
    },
    "ja_JP": {
        "settings_language": "言語",
        "whats_new": "新着情報",
        "invite_guest": "ゲストを招待",
        "new_session": "新しいセッション",
        "session_history": "セッション履歴",
        "hi": "やあ、",
        "setttings": "設定",
        "reports": "レポート",
        "BL-Role": "請求する",
        "recnt_used_device_sign_out": "サインアウト",
        "your_subscription_has_been_paused": "サブスクリプションが一時停止されました",
        "subscription_ended_modal_title": "サブスクリプションは終了しました",
        "subscription_paused_description": "サブスクリプションが一時停止されました。再開するには、サポート チームにお問い合わせください。",
        "subscription_ended_modal_subtitle": "サブスクリプションが終了しました :-(",
        "subscription_ended_modal_msg1": "残念ながらサブスクリプションは終了しましたが、サブスクリプションを延長したい場合に備えて、アカウントを保持していますのでご安心ください。",
        "subscription_ended_modal_msg2": "サブスクリプションが終了してから 30 日後に、データが削除される予定であることに注意してください。",
        "logout": "ログアウト",
        "okay_cap": "わかった",
        "contact_us": "お問い合わせ",
        "go_to_billing": "請求に移動",
        "trial_key": "トライアル",
        "days_left": "残りの日数",
        "ends_today": "今日で終了",
        "upgrade_key": "アップグレード",
        "day_left": "残り日",
        "auto_logout_msg": "セキュリティ上の理由から、非アクティブなためログアウトされました。もう一度ログインしてください。",
        "journey_editor": "ジャーニーエディター",
        "cobrowse": "コブラウズ"
    }
}