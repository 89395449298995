import { APIs } from "../constants/Constants";
import api from "./api";

export default class SessionListAPI {
    getSessionUsers(req) {
        const GET_SESSION_USERS = APIs.SESSION.GET_SESSION_USERS
                                        .replace('{search}', req.SEARCH)
                                        .replace('{Skip}', req.SKIP);

        return api.get(GET_SESSION_USERS);
    }

    getCountries() {
        return api.get(APIs.SESSION.GET_ALL_COUNTRIES);
    }

    getOwnCPList(){
        return api.get(APIs.SESSION.GET_OWN_CP_LIST);
    }

    getSessions(req) {
        return api.post(APIs.SESSION.GET_SESSION_HISTORY, req);
    }

    postJoinSession(req) {
        return api.post(APIs.SESSION.GET_SESSION_DETAIL, req);
    }

    postInviteSession(req) {
        const SESSION_INVITATION = APIs.SESSION.GET_SESSION_INVITATIONS.replace('{sessionId}', req.sessionId);
        delete req.sessionId
        return api.post(SESSION_INVITATION, req);
    }

    postCreateSession(req) {
        return api.post(APIs.SESSION.CREATE_SESSION, req);
    }

    putEditScheduleSession(req) {
        const EDIT_SCHEDULE_SESSION = APIs.SESSION.EDIT_SCHEDULE_SESSION.replace('{sessionId}', req.sessionId);
        delete req.sessionId
        return api.put(EDIT_SCHEDULE_SESSION, req);
    }
}