import { useState } from "react"
import { Modal } from "react-bootstrap"
import clsx from "clsx";
import PropTypes from 'prop-types';

export const PopupModal = ({ modalClass, dialogClass, headerContent = null, bodyContent, footerContent = null, onConfirm, bodyClass, footerClass,
    confirmText = "Confirm", cancelText = "Cancel", cancelButton = true, closeButton = true, nextArraow = false,
    backdrop = true, disableConfirm = true, showLoader = false, disablekeyboard = false,
    ...otherProps }) => {
    const [showPopup, setShowPopup] = useState(true);

    const onCloseModal = () => {
        setShowPopup(false);
        otherProps.closeModal();
    }

    const confirmButtonStyle = clsx(
        "btn btn-primary font-weight-500 btn-with-icon",
        nextArraow && "btn-with-icon next-btn"
    );

    return (
        <Modal
            show={showPopup}
            onHide={onCloseModal}
            className={modalClass ? modalClass : ""}
            dialogClassName={dialogClass ? dialogClass : ""}
            backdrop={backdrop}
            data-testid="popupModal"
            keyboard={!disablekeyboard}
        >
            <Modal.Header>
                {headerContent}
                {closeButton &&
                    <button type="button" className="btn-close" aria-label="Close" onClick={onCloseModal} data-testid="modalCloseIcon">
                        <span className="icon-close"></span>
                    </button>
                }
            </Modal.Header>
            <Modal.Body className={bodyClass ? bodyClass : ""}>
                {bodyContent}
            </Modal.Body>
            <Modal.Footer
                className={footerClass ? footerClass : ""}
            >
                <div className={clsx("button-inline d-flex", otherProps.footerInlineClass ?? '')}>
                    {footerContent ??
                        <>
                            {cancelButton &&
                                <div className="button">
                                    <button type="button" className="btn btn-outline-secondary font-weight-500" onClick={onCloseModal} data-testid="modalCloseButton">{cancelText}</button>
                                </div>
                            }
                            <div className="button">
                                <button type="button" className={confirmButtonStyle} onClick={onConfirm} disabled={disableConfirm} data-testid="modalSendButton">
                                    {showLoader && <span className="btn-loader-icon"><span className="for-icon icon-loader spin"></span></span>}{confirmText}{nextArraow && <span className="btn-next-arrow icon-right-arrow"></span>}
                                </button>
                            </div>
                        </>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    );
}

PopupModal.propTypes = {
    backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    disableConfirm: PropTypes.bool,
    showLoader: PropTypes.bool,
    disablekeyboard: PropTypes.bool,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    cancelButton: PropTypes.bool,
    closeButton: PropTypes.bool,
    nextArraow: PropTypes.bool,
    modalClass: PropTypes.string,
    dialogClass: PropTypes.string,
    bodyClass: PropTypes.string,
    footerClass: PropTypes.string,
    headerContent: PropTypes.any,
    bodyContent: PropTypes.any,
    footerContent: PropTypes.any,
    onConfirm: PropTypes.func,
}