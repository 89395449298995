import { DefaultLanguage } from "../../../../../constants/Constants";
import PropTypes from 'prop-types';

const StepTitle = ({ dataObj, currentLanguage }) => {
  if (dataObj.title && (dataObj.title[currentLanguage] !== '' || dataObj.title[DefaultLanguage] !== '')) {
    return (
      <div className="step-title">
        {dataObj.title[currentLanguage] && (
          <span>{dataObj.title[currentLanguage]}</span>
        )}
        {!dataObj.title[currentLanguage] && dataObj.title[DefaultLanguage] && (
          <span>{dataObj.title[DefaultLanguage]}</span>
        )}
      </div>
    );
  }
  return null;
};

StepTitle.propTypes = {
  dataObj: PropTypes.object,
  currentLanguage: PropTypes.string
}

export default StepTitle;
