import history from "../../history";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { RouteName } from "../../constants/Constants";
import {authLoginUser} from "../../actions/ssoAuthLoginAction";
import {isEmpty} from "../../shared/utility";
import { resetAllSessionListReducerObj } from "../../actions/sessionListAction";
import { resetAllSessionDetailReducerObj } from "../../actions/sessionDetailAction";
import { resetActiveUsersReducerObj } from "../../actions/activeUsersActions";

const AuthLogin = (props) => {
    const [showLoading, setShowLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const {authLoginUser, ssoAuthLogin } = props;

    const backToLogin = () => {
        history.replace(RouteName.LOGIN);
    }
    useEffect(() => {
        props.resetAllSessionListReducerObj();
        props.resetAllSessionDetailReducerObj();
        props.resetActiveUsersReducerObj();
        const query = new URLSearchParams(history.location.search);
        const apikey = query.get("key");
        const email = query.get("email");

        if(!isEmpty(apikey) && !isEmpty(email)){
            authLoginUser({
                headers: {
                    APIKEY : apikey
                },
                first_name: "", // we are using both keys first_name & last_name in the same API for genesys package  
                last_name: "",
                email: email,
                role: isEmpty(query.get("role")) ? "" : query.get("role"),
                call_experience: isEmpty(query.get("ce")) ? "" : query.get("ce"),
                department: isEmpty(query.get("department")) ? "" : query.get("department"),
                from: 4 // service now flow
            });
        } else{
            backToLogin();
        }
    }, []);

    useEffect(() => {
        setShowLoading(ssoAuthLogin.authLoginLoading);
        if(ssoAuthLogin.authLoginError){
            setIsError(ssoAuthLogin.authLoginError);
        }
    }, [ssoAuthLogin.authLoginLoading, ssoAuthLogin.authLoginError]);

    return <div className="full-height-loader">
                <div className="full-height-loader-in">
                    {showLoading && !isError &&
                        <div className="full-height-loader-box">
                            <div className="loader">
                                <span className="for-icon icon-loader spin"></span>
                            </div>
                            <h3>Please wait...</h3>
                        </div>
                    }
                    {!showLoading && isError &&
                        <div className="loaderview-error">
                            <div className="loaderview-error-msg clearfix">
                                <div className="icon">
                                    <span className="for-icon icon-exclamation-mark"></span>
                                </div>
                                <div className="text">
                                    <p>{isError}</p>
                                </div>
                            </div>
                            <div className="button-inline d-flex justify-content-center">
                                <div className="button">
                                    <button className="btn btn-primary btn-sm" onClick={backToLogin}>
                                        <span className="btn-text">Back to Login</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
		    </div>;
}

AuthLogin.propTypes = {
    authLoginUser: PropTypes.func,
    ssoAuthLogin: PropTypes.object
}

const mapStateToPropsAuthLogin = (state) => {
    return {
        ssoAuthLogin: state.ssoAuthLogin
    }
}

const mapDispatchToAuthLogin = (dispatch) =>{
    return {
        authLoginUser: (req) => dispatch(authLoginUser(req)),
        resetAllSessionListReducerObj: () => dispatch(resetAllSessionListReducerObj()),
        resetAllSessionDetailReducerObj: () => dispatch(resetAllSessionDetailReducerObj()),
        resetActiveUsersReducerObj: () => dispatch(resetActiveUsersReducerObj()),
    }
}

export default connect(mapStateToPropsAuthLogin, mapDispatchToAuthLogin)(AuthLogin);