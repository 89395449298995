import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';

const SessionUsersSearchbar = (props) => {
    const { onSearchTextChange, localisedValues } = props;
    
    const [searchText, setSearchText] = useState('');

    const isMount = useRef(false);

    useEffect(() => {
        if(!isMount.current){
            isMount.current = true;
            return;
        }
        const timeOutId = setTimeout(() => {
            onSearchTextChange(searchText.trim());
        }, 500);
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(()=>{
        document.getElementById("session-user-filter")?.addEventListener("click",() => setSearchText(''));
    },[])
    
    return (
        <div className="search-form esm bottom-border">
            <div className="form-group">
                <div className="form-group-in">
                    <span className="for-icon icon-search"></span>
                    <input type="text" className="form-control" placeholder={localisedValues["search"]} value={searchText} onChange={event => setSearchText(event.target.value)} />
                </div>
            </div>
        </div>
    );
}

SessionUsersSearchbar.propTypes = {
  onSearchTextChange: PropTypes.func,
  localisedValues: PropTypes.object,
};

export default SessionUsersSearchbar;