/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Card } from "../../UI/Card/Card";
import {useEffect, useRef, useState} from 'react'
import clsx from "clsx";
import OTPInput, {} from "otp-input-react";
import CustomToast from "../../UI/customToast";
import PropTypes from 'prop-types';
import __ from "lodash";
import parse from 'html-react-parser';
import PublicVariables from "../../../constants/PublicVariables";
import { afterLoginProcess, handleErrorMsgToastVisiblity } from "../../../shared/utility";

const Authy = (props) => {
    const [otp, setOtp] = useState('');
    const [isResendCodeDisable, setIsResendCodeDisable] = useState(false);
    const toastRef = useRef(null);
    
    const handleOtpValueChange = (value) => {
        const replaceData = value?.replace('-', '')?.trim();
        setOtp(replaceData);
        if(replaceData.length === 6){
            toastRef.current = CustomToast.info(props.localisedValues['validating_code']);
            props.verifyOTP({verificationId:props.verificationId,uemail:PublicVariables.uEmailOnLogin, code: replaceData});
        }
    };

    useEffect(()=>{
        if (props?.login?.resendOtpLoading) {
            setIsResendCodeDisable(true)
        }
    },[props?.login?.resendOtpLoading]);

    useEffect(()=>{
        if(props?.login?.resendOtpData){
            CustomToast.success(props.localisedValues['ccod_resend_text']);
            setIsResendCodeDisable(false)
        }
    },[props?.login?.resendOtpData]);

    useEffect(() => {
        if (props?.login?.resendOtpError) {
            setIsResendCodeDisable(false)
        }
    }, [props?.login?.resendOtpError]);

    const handle = (e) => {
        /* the session has shut down */
        let clipboardData, pastedData;
        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();
        // Get pasted data via clipboard API
        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('Text');
        const replaceData = pastedData.replace('-', '');
        handleOtpValueChange(replaceData)
        const a =document.getElementsByClassName('otp-frm');
        a[0].lastChild.focus();
    }

    useEffect(() => {
        document.addEventListener("paste", handle, false);
        return () => {
            document.removeEventListener("paste", handle);
        }
    }, []);

    useEffect(() => {
        if (props?.login?.verifyOtpLoading) {
            setIsResendCodeDisable(true)
        }
        if (props?.login?.verifyOtpData) {
            if(toastRef.current) CustomToast.dismissToast(toastRef.current);
            setIsResendCodeDisable(false);
            afterLoginProcess(props?.login?.verifyOtpData);
        }
        if (props?.login?.verifyOtpError) {
            console.log(props?.login?.verifyOtpError.message);
            handleErrorMsgToastVisiblity(props?.login?.verifyOtpError.message);
            setIsResendCodeDisable(false)
            setOtp('');
            const a =document.getElementsByClassName('otp-frm')
            a[0].firstChild.focus()
        }
    }, [props?.login?.verifyOtpLoading, props?.login?.verifyOtpData, props?.login?.verifyOtpError]);

    const resendOtpFun = () => {
        props.resendOTP({verificationId:props.verificationId,uemail:PublicVariables.uEmailOnLogin});
    }
    return (
		<div className="login-section-box login-otp-box">
			<div className="login-section-in">
                <div className="signup-out-box">
                    <Card>
                        <div className="signup-in-scroll custom-scroll-div">
                            <div className="login-logo-subtext text-center">
                                <h2 data-testid="authytext">{props.localisedValues['check_ccode_text']}</h2>
                                <p>{parse(__.replace(props.localisedValues['ccod_genral_text'], '<trial_email>', PublicVariables.uEmailOnLogin))}</p>
                            </div>
                            <div className="form-group">
                                <label className="form-label verify-otp-label">{parse(props.localisedValues['your_ccode_text'])}</label>
                                <div className="verification-form">                                    
                                    <OTPInput 
                                        className="otp-frm"
                                        otpType="number"
                                        value={otp}
                                        onChange={handleOtpValueChange}
                                        OTPLength={6}
                                        inputClassName="form-control form-control-grey"
                                    />							
                                </div>
                            </div>
                            <div className="login-bottom-text">
                                <div className="have-an-account text-center"  data-testid="resendcodediv">
                                    {parse(props.localisedValues['ccode_note1_text'])} {!isResendCodeDisable ? <a href={void(0)} className='text-dec-none pointer' onClick={resendOtpFun} rel="noreferrer">{props.localisedValues['resend_code']}</a> : <a href={void(0)} className={clsx('text-dec-none', isResendCodeDisable && 'disable')} rel="noreferrer" target="_blank">{props.localisedValues['resend_code']}</a>}
                                </div>
                            </div>
                        </div>                      
                    </Card>
                </div>
			</div>
		</div>
    );
}

Authy.propTypes = {
    login: PropTypes.object,
    localisedValues: PropTypes.object,
    verifyOTP: PropTypes.func,
    resendOTP: PropTypes.func,
    verificationId: PropTypes.string
}

export default Authy;