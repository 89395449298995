import React from "react";
import PropTypes from 'prop-types';
import PaginationHeader from "./PaginationHeader";
import PaginationFooter from "./PaginationFooter";

const Pagination = (props) => {
  const { children, paginationProps, searchHelper } = props;
  return (
    <>
      <PaginationHeader paginationProps={paginationProps} searchHelper={searchHelper} />
      {children}
      <PaginationFooter paginationProps={paginationProps} />
    </>
  );
};

Pagination.propTypes = {  
  children: PropTypes.object,
  paginationProps: PropTypes.object,
  searchHelper: PropTypes.object,
}

export default Pagination;
