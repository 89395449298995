import React from 'react'

const NotificationBanner = () => {

    const hideNotification = () => {
        if (document.getElementById('notification-banner')) {
            document.getElementById('notification-banner').style.display = 'none';
        }
    };

    return (
        <div id="notification-banner" className="cst-alert" style={{ display: 'none' }}>
            <div className="cst-alert-box alert-warning margin-bottom-0">
                <div className="cst-warning-alert">
                    <div className="warning-alert-button">
                        <button className="btn" onClick={() => hideNotification()}>Dismiss</button>
                    </div>
                    <div className="warning-alert-text">
                        Browser notifications are not supported. Please use the latest version of Chrome, Edge, or Firefox browser to start receiving notifications.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationBanner
