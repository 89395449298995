import { ToastContainer,Slide } from "react-toastify";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import Login from "./components/Login/Login";
import { LOCAL_STORAGE_KEYS, RouteName, VERSION_KEY, VERSION_VALUE } from "./constants/Constants";
import history from "./history";
import PrivateLayout from "./layouts/PrivateLayout";
import PublicLayout from "./layouts/PublicLayout";
import LoginService from "./services/loginService";
import { HeaderAfterAuth, HeaderBeforeAuth } from "./components/Header/Header";
import { BeforeAuthContainer } from "./components/UI/Card/Card";
import SendCode from "./components/SendCode/SendCode";
import Sessions from "./components/Sessions/Sessions";
import Logout from "./components/Logout/Logout";
import SignUp from "./components/SignUp/SignUp";
import SSOLogin from "./components/Login/SSOLogin";
import AuthLogin from "./components/Login/AuthLogin";
import GetStarted from "./components/SelfSignup/GetStarted";
import Confirmation from "./components/SelfSignup/Confirmation";
import Userinfo from "./components/SelfSignup/Userinfo";
import Companyinfo from "./components/SelfSignup/Companyinfo";
import Invites from "./components/SelfSignup/Invites";
import InviteUsers from "./components/InviteContacts/InviteContacts";
import RecoverPassword from "./components/RecoverPassword/RecoverPassword";
import CreateAndInviteSession from "./components/Sessions/CreateAndInvite/CreateAndInviteSession";
import storage from "./services/storage";
import { handleIdleTimeLogic } from "./shared/cookieHandler";
import CustomToast from "./components/UI/customToast";
import { setPageTitle } from "./shared/utility";

export const PublicRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = LoginService.isLoggedIn(); //get token using service
    setPageTitle(rest.title);
    return (
        <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? 
                <Redirect to={RouteName.SESSIONS} /> :
                <BeforeAuthContainer>
                    <HeaderBeforeAuth />
                    <Component {...props} />
                </BeforeAuthContainer>
            } />
    );
}

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = LoginService.isLoggedIn();  //get token using service
    if (!rest.skipTitle) {
        setPageTitle(rest.title);
    }
    if(rest.path === RouteName.CREATE_AND_INVITE && !isEmpty(rest.location.search)){
        storage.set(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION, rest.location.search);
        if(!isLoggedIn){
            const redirectPath = encodeURIComponent(RouteName.CREATE_AND_INVITE);
            const redirectURL = `${RouteName.LOGIN}?redirect_url=${redirectPath}`;
            return (<Route {...rest} render={() => <Redirect to={redirectURL} />} />)
        }
    }
    
    const configData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
    if (configData) {
        handleIdleTimeLogic()
    }

    // if (PublicVariables.configurations.allowzendesk == 1 && PublicVariables.ldFlags['zendeskIntegrations']) {
    //     if (rest.path === RouteName.SEND_CODE || rest.path === RouteName.CREATE_SESSION) {
    //         rest.path = RouteName.SESSIONS
    //     }
    // }
    
     // compulsary logout - removed toast - no need of it
     if ((!storage.get(VERSION_KEY) || storage.get(VERSION_KEY) !== VERSION_VALUE)) {
        storage.clearAll();
        storage.set(VERSION_KEY, VERSION_VALUE);
      }

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn ?
                    <>
                        <HeaderAfterAuth title={rest.title} skipTitle={rest.skipTitle}/>
                        <div className="main-section">
                            <Component {...props} />
                        </div>
                    </> :
                    <Redirect to={RouteName.LOGIN} />
            }
        />
    );
}

const AllRoutes = ({_child}) => {
    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                className='toast-on-top'
                transition={Slide}
                />
        <Router history={history}>
            <Switch>
                <PublicRoute
                    path={[`${RouteName.LOGIN}/:loginId`, RouteName.LOGIN]}
                    component={PublicLayout(Login)}
                    title="Login"
                />
                <PublicRoute
                    path={RouteName.SSO_TOKEN}
                    component={PublicLayout(SSOLogin)}
                    title="SSO-Token"
                    exact
                />
                <PublicRoute
                    path={RouteName.AUTH_USER}
                    component={PublicLayout(AuthLogin)}
                    title="Auth"
                    exact
                />
                <PublicRoute
                    path={RouteName.GET_STARTED}
                    component={PublicLayout(GetStarted)}
                    title="Create a domain"
                    exact
                />
                <PublicRoute
                    path={RouteName.CONFIRMATION}
                    component={PublicLayout(Confirmation)}
                    title="Confirm code | Create a domain"
                    exact
                />
                <PublicRoute
                    path={RouteName.USER_INFO}
                    component={PublicLayout(Userinfo)}
                    title="User info | Create a domain"
                    exact
                />
                <PublicRoute
                    path={RouteName.COMPANY_INFO}
                    component={PublicLayout(Companyinfo)}
                    title="Company info | Create a domain"
                    exact
                />
                <PrivateRoute
                    path={RouteName.INVITES}
                    component={PublicLayout(Invites)}
                    title="Invite more users"
                    exact
                />
                <PublicRoute
                    path={RouteName.CREATE_ACCOUNT}
                    component={PublicLayout(SignUp)}
                    title="Sign Up"
                    exact
                />
                <PrivateRoute
                    path={RouteName.SEND_CODE}
                    component={PrivateLayout(SendCode)}
                    title="Invite Session"
                    exact
                />
                <PrivateRoute
                    path={[RouteName.SESSIONS, RouteName.SESSION_DETAILS]}
                    component={PrivateLayout(Sessions)}
                    title="Session History"
                    exact
                    skipTitle
                />
                 <PrivateRoute
                    path={RouteName.CREATE_AND_INVITE}
                    component={PrivateLayout(CreateAndInviteSession)}
                    title="Create and Invite"
                    exact
                />
                
                <PrivateRoute
                    path={RouteName.CREATE_SESSION}
                    component={PrivateLayout(InviteUsers)}
                    title="Create Session"
                    exact
                />
                <PrivateRoute
                    path={RouteName.LOGOUT}
                    component={PrivateLayout(Logout)}
                    title="Logout"
                    exact
                />
                <PublicRoute
                    path={RouteName.RECOVER_PASSWORD}
                    component={PublicLayout(RecoverPassword)}
                    title="Recover Password"
                    exact
                />
                {/* <Redirect from={RouteName.DEFAULT_ROUTE} to={RouteName.SESSIONS} exact /> */}
                <Redirect from="*" to={RouteName.LOGIN} />
            </Switch>
        </Router>
        </>
    )
}

export default AllRoutes;