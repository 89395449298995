import { useEffect, useRef } from 'react';
/*
UseEffect will work after first/initial render done
in general, all useEffect will call first time when component render
so to avoid first/initial render useEffect call, use useRef hook
*/
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

export default useDidMountEffect;

export const useDidMountEffectWithClear = (func, deps, timer) => {
    const didMount = useRef(false);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (didMount.current) func();
            else didMount.current = true;
        }, timer);
        return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}

