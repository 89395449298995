/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import clsx from "clsx";
import __ from "lodash";

import PublicVariables from "../../constants/PublicVariables";
import { Localize } from "./Localize";
import { changeLanguage } from "../../actions/languageAction";

const Languages = ({isAuthorised, selectedlanguage, ...props}) => {
    const [localisedValues, setLocalisedValues] = useState({});

    useEffect(() => {
        //Use timeout so that UI render first and then set event on it
        setTimeout(() => {
            const profileLanguageDropdownElement = document.getElementsByClassName('profile-language-dropdown-toggle');
            Array.from(profileLanguageDropdownElement).forEach(function (element) {
                element.addEventListener('click', (event) => {
                    event.stopPropagation();
                });
            });
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    const languageChangeHandler = (langId) => {
        props.changeLanguage(langId);
    }

    return (
        <>
            {Object.keys(localisedValues).length > 0 &&
                <div className={clsx("language-selection", isAuthorised ? "header-language-selection" : "login-language-selection")} data-testid={clsx(isAuthorised ? 'authorisedLanguageSection' : 'unauthorisedLanguageSection')}>
                    <div className="dropdown">

                        <button className={clsx("btn btn-secondary dropdown-toggle triangle-none for-selected-language", isAuthorised && "profile-language-dropdown-toggle")}
                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {!isAuthorised &&
                                <div className="selected-flag">
                                    <span className={clsx("selected-flag-span", selectedlanguage.class)} data-testid={selectedlanguage.class}></span>
                                </div>
                            }
                            {isAuthorised &&
                                <span className="dropdown-item-icon icon-global"></span>
                            }
                            <div className="language-name">
                                {localisedValues['settings_language']}
                            </div>
                            {isAuthorised &&
                                <div className="selected-flag">
                                    <span className={clsx("selected-flag-span", selectedlanguage.class)}></span>
                                </div>
                            }
                        </button>
                        {PublicVariables.localiseLanguage.length > 1 &&
                            <div className="dropdown-menu right" aria-labelledby="dropdownMenuButton1">
                                <div className="dropdown-menu-in available-language-list custom-scroll-div-thin">
                                    <ul>
                                        {
                                            __.sortBy(PublicVariables.localiseLanguage, lang => lang.id).map((lang) => {
                                                return (
                                                    <li key={lang.id}>
                                                        <a className={clsx("dropdown-item", lang.id === props.language.selectedlanguage.id && "active")} onKeyDown={() => {}} onClick={() => languageChangeHandler(lang.id)}>
                                                            <div className="available-language-box">
                                                                <div className={clsx("flag-icon", lang.class)}></div>
                                                                <div className="language-name">
                                                                    <div className="country-language">{lang.name}</div>
                                                                    <div className="country-name">{lang.state}</div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

Languages.propTypes = {
    isAuthorised: PropTypes.bool,
    selectedlanguage: PropTypes.object,
    language: PropTypes.object,
    changeLanguage: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

export default connect(mapStateToProps, {
    changeLanguage
})(Languages);