import PropTypes from 'prop-types';
import { LOCAL_STORAGE_KEYS } from "../../../constants/Constants";
import storage from "../../../services/storage";

const SelectedUsers = (props) => {
    const {
        selectAllUsers,
        selectedUsers,
        localisedValues
    } = props;
    /* it is check default Users is selectd or not if selected then return their UCID*/
    const defaultUser = selectedUsers.filter((selectedUser) => selectedUser.id === storage.get(LOCAL_STORAGE_KEYS.UCID))

    return (
        <button className="btn btn-secondary dropdown-toggle triangle-none" type="button"
            id="session-user-filter" data-bs-toggle="dropdown" aria-expanded="false">
            <div className="filter-item">
                <span className="filter-item-name">{(selectedUsers[0] && defaultUser[0]) && (selectedUsers[0]?.id === defaultUser[0]?.id) ? selectedUsers[0]?.name + ' ' + `(${localisedValues["you_lable"]})` : selectedUsers[0]?.name}</span>
                {selectAllUsers ? <span>{localisedValues["user_all"]}</span> : selectedUsers.length === 0 ? <span className="">{localisedValues["assigned_to_lable"]}</span> : selectedUsers.length > 1 ? <span className="">(+{selectedUsers.length > 1 ? selectedUsers.length - 1 : ''})</span> : ''}
            </div>
            <span className="icon-arrow-down"></span>
        </button>
    );
}

SelectedUsers.propTypes = {
    selectAllUsers: PropTypes.bool,
    selectedUsers: PropTypes.array,
    localisedValues: PropTypes.object,
}

export default SelectedUsers;