import clsx from "clsx";
import { connect } from "react-redux";
import { useEffect, useMemo, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { cloneDeep, get, isEmpty } from "lodash";
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { ACCEPT_ANY_VALUE_REGEX, ACTIONS_LIST, LOCAL_STORAGE_KEYS, MEETING_TYPE, ONLY_NUMERIC_REGEX, RouteName, USER_ROLE_CODE } from "../../../../constants/Constants";
import { checkRegexPattern, convertUtcToLocal, convertUtcToLocalInStr, copyValue, fetchInvitationURL, findTimeDiffInHours, formatDate, generateZip, handleErrorMsgToastVisiblity, isUploadOnlySession, replaceSpecialChar, timeSince, toMinSec } from "../../../../shared/utility";
import storage from "../../../../services/storage";
import PublicVariables from "../../../../constants/PublicVariables";
import { getSessionImages, getSessionInvitations, getSessionRecordings, getSessionWordDoc, putArchiveSession, putEndSession, putReferenceChange, putReferenceChangeReset, updateSessionDetails } from "../../../../actions/sessionDetailAction";
import { handleArchiveSession, handleEndSession } from "../../../../actions/sessionListAction";
import AssigneeDropDown from "./AssigneeDropDown";
import CustomToast from "../../../UI/customToast";
import { PopupModal } from "../../../UI/Modal/Modal";
import history from "../../../../history";
import CreateSessionModal from "../../CreateSessionModal/CreateSessionModal";

const DetailsTab = forwardRef((props, ref) => {
    const {
        details,
        meetingID,
        showRecordingTab,
        putSessionReferenceLoading,
        putSessionReferenceData,
        putSessionReferenceError,
        putReferenceChange,
        putReferenceChangeReset,
        setDetails,
        sessionsList,
        setSessionsList,
        isDiscardChanged,
        isRefrenceTitleChanged,
        setIsRefrenceTitleChanged,
        setIsSessionAssignChange,
        setIsDiscardModalOpen,
        setCurrentActionType,
        setIsDiscardModalSaveBtnDisabled,
        localisedValues,
        nextPath
    } = props;
    const configurationdata = useMemo(() => { return storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION) }, []);
    const userData = useMemo(() => { return storage.get(LOCAL_STORAGE_KEYS.USER_DATA) }, []);
    const isDownloadPDFEnabled = useMemo(() => { return (details?.session?.imageCount > 0 || details?.session?.notesCount > 0 || details?.session?.ocrCount > 0 || details?.session?.chatCount > 0) }, [details]);
    const defaultAssignee = useMemo(() => { return { userId: details?.session?.ownerUserId, profileImage: details?.session?.ownerProfileImage, fullName: details?.session?.ownerName } }, [details])
    const isUploadOnlySessionValue = useMemo(() => { return isUploadOnlySession(details?.features?.LIVEVIDEO, details?.features?.ENABLESCREENSHARE, details?.features?.UPLOADFILE) }, [details]);
    const [invitationList, setInvitationList] = useState([]);
    const [isImagesDownloading, setIsImagesDownloading] = useState(false);
    const [isRecordingsDownloading, setIsRecordingsDownloading] = useState(false);
    const [isWordDocDownloading, setIsWordDocDownloading] = useState(false);
    const [isShowArchiveModal, setIsShowArchiveModal] = useState(false);
    const [archiveSessionLoading, setArchiveSessionLoading] = useState(false);
    const [endSessionLoading, setEndSessionLoading] = useState(false);
    const [referenceTitleValue, setReferenceTitleValue] = useState('')
    const [referenceTitleRegexPattern, setReferenceTitleRegexPattern] = useState('')
    const [isReferenceTitleFocused, setIsReferenceTitleFocused] = useState(false)
    const [errorForTitle, setErrorForTitle] = useState(false)
    const [replaceSpaceReferenceTitle, setReplaceSpaceReferenceTitle] = useState('')
    const referenceTitleRef = useRef(null)
    const [guestUrlLink, setGuestUrlLink] = useState('')
    const [agentUrlLink, setAgentUrlLink] = useState('')
    const [silentObserverUrlLink, setSilentObserverUrlLink] = useState('')
    const [isScheduleSessionModalOpen, setIsScheduleSessionModalOpen] = useState(false)
    const assignRef = useRef()

    const setAllUserlink = () => {
        let guestUrl, observerUrl, agentURL = "";
        let inviteURL = fetchInvitationURL();
        if (details?.features?.SHOWMEOBSERVER === '1') {
            observerUrl = inviteURL + "/j/#/" + details?.session?.sessionId + "?o=1" + "&s=" + details?.features?.SMSSUPPORTOPTION;
            setSilentObserverUrlLink(observerUrl);
        }
        if (PublicVariables.ldFlags?.privacyPolicyLink) {
            guestUrl = inviteURL + "/j/#/" + details?.session?.sessionId + "?m=" + details?.features?.SHOWMEMICROPHONE + "&c=" + details?.features?.SHOWMEVIDEO + "&s=" + details?.features?.SMSSUPPORTOPTION + "&d=" + userData.domainId;
            setGuestUrlLink(guestUrl);
        } else {
            guestUrl = inviteURL + "/j/#/" + details?.session?.sessionId + "?m=" + details?.features?.SHOWMEMICROPHONE + "&c=" + details?.features?.SHOWMEVIDEO + "&s=" + details?.features?.SMSSUPPORTOPTION;
            setGuestUrlLink(guestUrl);
        }
        agentURL = inviteURL + '/j/#/' + details?.session?.sessionId + '?id=' + storage.get(LOCAL_STORAGE_KEYS.UCID) + "&m=" + details?.features?.SHOWMEMICROPHONE + "&s=" + details?.features?.SMSSUPPORTOPTION + "&z=1";
        setAgentUrlLink(agentURL);
    }

    const NOT_AVAILABLE = 'N/A'

    const oldRefrenceTitleValue = useMemo(() => {
        const capitlizeReferenceTitle = (val) => {
            if (val) {
                return val.charAt(0).toUpperCase() + val.slice(1);
            }
            return val;
        }

        const refrenceTitle = details?.session?.reference ? capitlizeReferenceTitle(details.session.reference) : NOT_AVAILABLE
        setReferenceTitleValue(refrenceTitle)
        setReplaceSpaceReferenceTitle(refrenceTitle.replace(/ /g, '\u00a0'))
        /* The above code is using a regular expression to replace all occurrences of a space character with a
        non-breaking space character in the string variable `refrenceTitle`. */
        return refrenceTitle
    }, [details])

    useEffect(() => {
        setAllUserlink();
    }, [details])

    // This hook helps a child component share certain functions or values with its parent. Here, the useImperativeHandle hook is used to let the parent access a bunch of functions through a ref.
    useImperativeHandle(ref, () => ({
        cancelReference() {
            setReferenceTitleValue(oldRefrenceTitleValue)
            setReplaceSpaceReferenceTitle(oldRefrenceTitleValue.replace(/ /g, '\u00a0'))
            setIsReferenceTitleFocused(false)
            setIsRefrenceTitleChanged(false)
            setErrorForTitle(false)
        },
        cancelAssignee() {
            assignRef.current.cancelAssignee()
        },
        handleArchiveSession() {
            setIsShowArchiveModal(true)
        },
        handleEndSessionOrLink() {
            handleEndSession()
        },
        saveReference() {
            handleOnSaveReference()
        },
        saveAssignee() {
            assignRef.current.handleOnSaveAssignie()
        },
        handleEditScheduleSession() {
            handleClikcOnSchedulesession(true)
        },
        handleStartJoinSession(agentUrl) {
            window.location.href = agentUrl;
        },
        handleInviteBtnFromMweb() {
            inviteSession(true)
        },
        handleBrowserClick(){
            history.push(nextPath)
        }
    }), [assignRef.current, oldRefrenceTitleValue, referenceTitleValue, nextPath]);

    useEffect(() => {
        if (meetingID !== null && meetingID !== undefined) {
            const req = {
                sessionId: meetingID
            }
            props.getSessionInvitations(req);
        }
        const mainDiv = document.getElementById('session-detail')
        if (mainDiv) {
            mainDiv.classList.add('active', 'show')
        }
        const regexPatternForTitle = PublicVariables.ldFlags.referenceType === 0 ? ONLY_NUMERIC_REGEX : ACCEPT_ANY_VALUE_REGEX;
        setReferenceTitleRegexPattern(regexPatternForTitle)
        setIsRefrenceTitleChanged(false)
        setErrorForTitle(false)
        setIsReferenceTitleFocused(false)
    }, [meetingID]);


    useEffect(() => {
        if (props.sessionInvitationsData) {
            setInvitationList(props.sessionInvitationsData?.items);
        }
    }, [props.sessionInvitationsData])

    useEffect(() => {
        if (!isRecordingsDownloading) return;
        if (!props.getSessionRecordingsData) return;
        const recording_list = get(props.getSessionRecordingsData, "data.list", []);
        if (recording_list.length > 0) {
            const zipPromise = generateZip(recording_list, "-recVideo.zip", meetingID)
            CustomToast.promise(
                zipPromise,
                {
                    pending: localisedValues["zipping_file"],
                    success: localisedValues["successfully_downloaded"],
                    error: localisedValues["download_failed"]
                }
            )

            zipPromise.then(() => {
                setIsRecordingsDownloading(false);
            })
        } else {
            setIsRecordingsDownloading(false);
            handleErrorMsgToastVisiblity(localisedValues["no_recording_to_download"]);
        }
    }, [props.getSessionRecordingsData]);

    useEffect(() => {
        if (!isImagesDownloading) return;
        if (!props.getSessionImagesData) return;
        const images_list = get(props.getSessionImagesData, "data.list", []);
        console.log("images_list", images_list);
        if (images_list.length > 0) {
            const zipPromise = generateZip(images_list, "-images.zip", meetingID)
            CustomToast.promise(
                zipPromise,
                {
                    pending: localisedValues["zipping_file"],
                    success: localisedValues["successfully_downloaded"],
                    error: localisedValues["download_failed"]
                }
            )

            zipPromise.then(() => {
                setIsImagesDownloading(false);
            })
        } else {
            setIsImagesDownloading(false);
            handleErrorMsgToastVisiblity(localisedValues["no_images_to_download"]);
        }
    }, [props.getSessionImagesData]);

    useEffect(() => {
        if (!isWordDocDownloading) return;
        if (!props.getSessionWordDocData) return;
        let filename = "";
        const disposition = props.getSessionWordDocData.contentDisposition;
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }
        saveAs(props.getSessionWordDocData.fileString, filename)
        CustomToast.success(localisedValues["successfully_downloaded"]);
        setIsWordDocDownloading(false);
    }, [props.getSessionWordDocData]);

    useEffect(() => {
        if (props.getSessionWordDocError) {
            setIsWordDocDownloading(false);
            handleErrorMsgToastVisiblity(localisedValues["download_failed"]);
        }
    }, [props.getSessionWordDocError]);

    useEffect(() => {
        if (!archiveSessionLoading) return;
        if (!props.putArchiveSessionData) return;
        setArchiveSessionLoading(false);
        setIsShowArchiveModal(false);
        props.handleArchiveSession(props.putArchiveSessionData);
        history.push(RouteName.SESSIONS);
        CustomToast.success(localisedValues["archive_session_success"]);
    }, [props.putArchiveSessionData]);

    useEffect(() => {
        if (props.putArchiveSessionError) {
            setArchiveSessionLoading(false);
            setIsShowArchiveModal(false);
            handleErrorMsgToastVisiblity(localisedValues["archive_session_fail"]);
        }
    }, [props.putArchiveSessionError]);

    useEffect(() => {
        if (!endSessionLoading) return;
        if (!props.putEndSessionData) return;
        setEndSessionLoading(false);
        props.handleEndSession(props.putEndSessionData);
        history.push(RouteName.SESSIONS);
        CustomToast.success(localisedValues["session_ended"]);
    }, [props.putEndSessionData]);

    useEffect(() => {
        if (props.putEndSessionError) {
            setEndSessionLoading(false);
            handleErrorMsgToastVisiblity("End session failed.");
        }
    }, [props.putEndSessionError]);

    useEffect(() => {
        if (putSessionReferenceLoading) return

        if (putSessionReferenceData) {
            CustomToast.success(localisedValues["reference_field_updated"]);

            // Updating Session Details Data 
            const updatedDetails = cloneDeep(details)
            if (updatedDetails) {
              updatedDetails.session.reference = referenceTitleValue.trim();
              setDetails(updatedDetails);
            }

                        // Updating Session List Data 
            const updatedSessionListData = sessionsList.map((session) => {
                if (session.sessionId === putSessionReferenceData.sessionId) {
                    return { ...session, reference: putSessionReferenceData.reference?.trim() }
                }
                return session
            })
            setSessionsList(updatedSessionListData)

            // Reseting States
            putReferenceChangeReset()
            setIsRefrenceTitleChanged(false)
            setIsReferenceTitleFocused(false)
        }
        else if (putSessionReferenceError) {
            handleErrorMsgToastVisiblity(putSessionReferenceError.message);
            putReferenceChangeReset()
        }
    }, [putSessionReferenceData, putSessionReferenceError])

    useEffect(() => {
        if (!props.putAssigneeChangeData) return;
        if(props.putAssigneeChangeData?.sessionId === details?.session?.sessionId){
            let newDetails = cloneDeep(details);
            newDetails.session.ownerUserId = props.putAssigneeChangeData?.userId;
            newDetails.session.ownerName = props.putAssigneeChangeData?.fullName;
            setDetails(newDetails);
            props.updateSessionDetails(newDetails);
        }
    },  [props.putAssigneeChangeData]);

    const checkEditReference = (type) => {
        const allowEditFlag = (details?.features?.ALLOWEDITREFERENCE === "1");
        if (type === "edit-OFF") {
            return (!allowEditFlag || (allowEditFlag && userData.roleCode !== USER_ROLE_CODE.administrator && userData.roleCode != USER_ROLE_CODE.superAdmin && details?.session?.ownerUserId != userData.userId));
        } else if (type === "edit-ON") {
            return (allowEditFlag && (userData.roleCode === USER_ROLE_CODE.administrator || userData.roleCode === USER_ROLE_CODE.superAdmin || details?.session?.ownerUserId === userData.userId))
        } else return false;
    }

    const showArchiveButton = () => {
        if ((userData.roleCode === USER_ROLE_CODE.administrator || userData.roleCode === USER_ROLE_CODE.superAdmin) && !details?.session?.archive && details?.session?.isExpired) {
            return isOwner();
        } else {
            return false;
        }
    }

    const showEndButton = () => {
        return ((userData.roleCode !== USER_ROLE_CODE.external) && !details?.session?.isExpired && isOwner())
    }

    const isOwner = () => {
        return (details?.features?.SESSIONVISIBILITYPAGE === "1" && details?.session?.ownerUserId !== userData.userId) ? false : true;
    };

    const copyLink = (from) => {
        let guestUrl, observerUrl = "";
        let inviteURL = fetchInvitationURL();

        if (from == 'silentObserver') {
            if (details?.features?.SHOWMEOBSERVER === '1') {
                observerUrl = inviteURL + "/j/#/" + details?.session?.sessionId + "?o=1" + "&s=" + details?.features?.SMSSUPPORTOPTION;
            }
            copyValue(observerUrl, localisedValues["observer_url_copied"]);
        } else {
            if (PublicVariables.ldFlags?.privacyPolicyLink) {
                guestUrl = inviteURL + "/j/#/" + details?.session?.sessionId + "?m=" + details?.features?.SHOWMEMICROPHONE + "&c=" + details?.features?.SHOWMEVIDEO + "&s=" + details?.features?.SMSSUPPORTOPTION + "&d=" + userData.domainId;
            } else {
                guestUrl = inviteURL + "/j/#/" + details?.session?.sessionId + "?m=" + details?.features?.SHOWMEMICROPHONE + "&c=" + details?.features?.SHOWMEVIDEO + "&s=" + details?.features?.SMSSUPPORTOPTION;
            }
            copyValue(guestUrl, localisedValues["guest_url_copy"]);
        }
    };

    const convertInviteDateTime = (date) => {
        if (findTimeDiffInHours(date) < 24) {
            return "(" + timeSince(convertUtcToLocal(date), localisedValues) + ")";
        } else {
            return convertUtcToLocalInStr(date);
        }
    }

    const downloadImages = () => {
        if (!isImagesDownloading) {
            setIsImagesDownloading(true);
            const request = { sessionId: meetingID };
            props.getSessionImages(request, false, "details");
        }
    }

    const downloadRecordings = () => {
        if (!isRecordingsDownloading) {
            setIsRecordingsDownloading(true);
            const request = { sessionId: meetingID };
            props.getSessionRecordings(request);
        }
    }

    const downloadWordDoc = () => {
        if (!isWordDocDownloading) {
            setIsWordDocDownloading(true);
            const tempTimezone = moment.tz.guess();
            const localTimezone = moment.tz(tempTimezone).zoneAbbr();
            const offSet = moment.tz(moment.utc(), tempTimezone).utcOffset();
            const request = {
                sessionId: meetingID,
                timeZone: localTimezone,
                offSet: offSet
            };
            props.getSessionWordDoc(request);
            CustomToast.warn(localisedValues["downloading"]);
        }
    }

    const handleArchiveSession = () => {
        if (!archiveSessionLoading) {
            console.log("archive session");
            setArchiveSessionLoading(true);
            props.putArchiveSession(meetingID);
        }
    }

    const handleEndSession = () => {
        console.log("end session");
        if (!endSessionLoading) {
            setEndSessionLoading(true);
            props.putEndSession(meetingID);
        }
    }

    const onFocusedEditReferenceField = () => {
        referenceTitleRef.current?.setSelectionRange(referenceTitleValue.length, referenceTitleValue.length)
        referenceTitleRef.current.focus()
        setIsReferenceTitleFocused(true)
    }

    const onReferenceFocus = () => {
        setIsReferenceTitleFocused(true)
        if (referenceTitleValue === NOT_AVAILABLE) {
            setReferenceTitleValue('')
        }
    }
    const onReferenceBlur = () => {
        setIsReferenceTitleFocused(false)
        if (isEmpty(referenceTitleValue) && oldRefrenceTitleValue === NOT_AVAILABLE) {
            setReferenceTitleValue(NOT_AVAILABLE)
        }
    }

    const handleOnRefrenceTitleChange = (e) => {
        const val = e.target.value
        setReferenceTitleValue(val)
        setReplaceSpaceReferenceTitle(val.replace(/ /g, '\u00a0').replace(/\n/g, " "))
        /* The above code is using regular expressions in JavaScript to replace all occurrences of spaces with
        non-breaking spaces ('\u00a0') and all occurrences of new lines with spaces. It is then setting the
        result as the title reference for a variable called "val". */

        const trimedValue = val.trim()

        const isFirstLetterChanged = trimedValue !== details.session.reference
        const isChanged = trimedValue !== NOT_AVAILABLE && isFirstLetterChanged && (oldRefrenceTitleValue !== NOT_AVAILABLE ? trimedValue !== oldRefrenceTitleValue : trimedValue !== '')
        setIsRefrenceTitleChanged(isChanged)

        // Handling Errors
        let erroMsg = false
        if (val.length > 50) {
            erroMsg = localisedValues["crt_acnt_limit_character_msg_fifty"]
        }
        else if (PublicVariables.ldFlags.referenceRequired && isEmpty(val)) {
            erroMsg = localisedValues["reference_required"]
        }
        else if (!isEmpty(val) && PublicVariables.ldFlags.referenceType == 0 && !checkRegexPattern(ONLY_NUMERIC_REGEX, trimedValue)) {
            erroMsg = localisedValues["numeric_characters_allowed"]
        }
        setIsDiscardModalSaveBtnDisabled(!!erroMsg)
        setErrorForTitle(erroMsg)
    }

    const handleOnSaveReference = () => {
        const payload = {
            sessionId: details.session.sessionId,
            reference: referenceTitleValue.trim()
        }
        putReferenceChange(payload)
    }

    const openJoinCallWindow = (fromwhere) => {
        if (fromwhere) {//For join as guest/observer, we are opening join link in new tab, so no need to check for discard
            if (fromwhere == "observer") {
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: silentObserverUrlLink,
                }).click();
            } else if (fromwhere == "guest") {
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: guestUrlLink,
                }).click();
            } else {
                window.location.href = agentUrlLink;
            }
        }
        else {
            if (isDiscardChanged) {
                setIsDiscardModalOpen(true)
                setCurrentActionType({ type: ACTIONS_LIST.START_JOIN_SESSION, payload: agentUrlLink })
                return
            }
            window.location.href = agentUrlLink;
        }
    }

    const inviteSession = (fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.INVITE_BTN_FROM_MWEB })
            return
        }
        history.replace(RouteName.SEND_CODE);
    }

    const handleClikcOnSchedulesession = (fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.EDIT_SCHEDULE_SESSION })
            return
        }
        setIsScheduleSessionModalOpen(true)
    }

    const isSessionScheduleChangeBtnVisible = useMemo(() => {
        return details?.session?.ownerUserId == userData.userId && details?.features?.SESSIONSCHEDULING === '1' && details?.session?.sessionType === MEETING_TYPE.SCHEDULED && details?.session?.archive === false
    }, [details])

    return (
        <div className={clsx("tab-pane fade show", details?.session?.sessionType !== MEETING_TYPE.JOURNEY && "active")} id="session-detail" role="tabpanel" ref={ref} data-testid='sessionDetailMain'>
            <div className="session-detail-info">
                <div className="session-detail-info-body custom-scroll-div">
                    {checkEditReference("edit-OFF") && <div className="session-reference" >
                        <h3>{referenceTitleValue}</h3>
                    </div>}
                    {/* Edit reference ON Start here */}
                    {checkEditReference("edit-ON") && <div className="edit-reference-field">
                        <div className="edit-reference-field-box">
                            <div className="edit-reference-field-in">
                                <div className="edit-reference-field-frm">
                                    <textarea className="frm-control" value={referenceTitleValue} maxLength={50} onChange={handleOnRefrenceTitleChange} ref={referenceTitleRef} onFocus={onReferenceFocus} onBlur={onReferenceBlur} onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                        }

                                        const val = e.target.value.replace(/ /g, '\u00a0');
                                        setReplaceSpaceReferenceTitle(val)
                                    }}>
                                    </textarea>
                                </div>
                                <div className="edit-reference-field-result">
                                    {replaceSpaceReferenceTitle}
                                </div>
                            </div>
                            {/* handle edit icon */}
                            {!isReferenceTitleFocused && !isRefrenceTitleChanged && <div className="edit-reference-btn" >
                                <button className="btn custom-tooltip center-tooltip m-hide" onClick={onFocusedEditReferenceField}>
                                    <span className="for-icon icon-edit"></span>
                                    <div className="custom-tooltip-content">
                                        <div className="custom-tooltip-in">
                                            <p>{localisedValues["edit_reference_text"]}</p>
                                        </div>
                                    </div>
                                </button>
                            </div>}
                            {isRefrenceTitleChanged && <div className="quick-info-save-row">
                                <div className="button">
                                    <button className="btn btn-link btn-with-icon text-dec-none" onClick={handleOnSaveReference} disabled={referenceTitleValue.length > 50 || (oldRefrenceTitleValue && isEmpty(referenceTitleValue)) || !checkRegexPattern(referenceTitleRegexPattern, referenceTitleValue.trim()) || !referenceTitleValue.trim() || putSessionReferenceLoading}>
                                        {putSessionReferenceLoading && <span className="btn-loader-icon">
                                            <span className="for-icon icon-loader spin"></span>
                                        </span>}
                                        <span className="btn-icon icon-save"></span>
                                        <span className="btn-text">{localisedValues["save_button"]}</span>
                                    </button>
                                </div>
                                <div className="button">
                                    <button className="btn btn-cancel btn-with-icon text-dec-none" onClick={() => ref.current.cancelReference()} disabled={putSessionReferenceLoading}>
                                        <span className="btn-icon icon-close"></span>
                                        <span className="btn-text">{localisedValues["user_cancel"]}</span>
                                    </button>
                                </div>
                            </div>}
                        </div>
                        {errorForTitle && errorForTitle !== localisedValues["numeric_characters_allowed"] && <div className="validation-error">{errorForTitle}</div>}
                        {errorForTitle && errorForTitle === localisedValues["numeric_characters_allowed"] && isReferenceTitleFocused && <div className="validation-error">{errorForTitle}</div>}
                    </div>}
                    {/* Edit reference ON End here */}
                    <div className="session-info-box">
                        <div className="session-info-row">
                            <div className="short-info-box">
                                <p><strong className="lbl"><span>{localisedValues["search_collaboration_profile"]}</span>:</strong> <span>{details && replaceSpecialChar(details?.session?.collaborationProfileName)}</span></p>
                            </div>
                            {details && configurationdata?.configurations?.sessionvisibilitypage &&
                                <AssigneeDropDown
                                    ref={assignRef}
                                    meetingID={meetingID}
                                    sessionsList={sessionsList}
                                    setSessionsList={setSessionsList}
                                    assigneeDetail={defaultAssignee}
                                    setIsSessionAssignChange={setIsSessionAssignChange}
                                    localisedValues={localisedValues} />
                            }
                            {!isUploadOnlySessionValue && <div className="short-info-box">
                                <p><strong className="lbl"><span>{localisedValues["duration"]}</span>:</strong> <span>{details && toMinSec(details?.session?.callDuration)}</span></p>
                            </div>}
                            {details?.session?.sessionType === MEETING_TYPE.SCHEDULED && <div className="short-info-box">
                                <p><strong className="lbl"><span>{localisedValues["scheduled"]}</span>:&nbsp;</strong>
                                    <span>{formatDate(details?.session?.startAt, 'YYYY-MM-DDTHH:mm:ss', 'MMM DD, YYYY hh:mm A')}</span>
                                    {isSessionScheduleChangeBtnVisible && <button className="btn change-btn" data-bs-target="#new-session-modal" onClick={() => handleClikcOnSchedulesession()}>{localisedValues["user_change"]}</button>}
                                </p>
                            </div>}
                            <div className="short-info-box">
                                <p><strong className="lbl"><span>{localisedValues["user_created"]}</span>:</strong> <span>{details && formatDate(details?.session?.createdAt, 'YYYY-MM-DDTHH:mm:ss', 'MMM DD, YYYY hh:mm A')}</span></p>
                            </div>
                            {isOwner() && details?.session?.sessionType === MEETING_TYPE.SCHEDULED && <div className="short-info-box">
                                <p><strong className="lbl">
                                    <span>{details?.session?.isExpired ? localisedValues["ended_key"] : localisedValues["ends_on"]}</span>
                                    :</strong> <span>{formatDate(details?.session?.endAt, 'YYYY-MM-DDTHH:mm:ss', 'MMM DD, YYYY hh:mm A')}</span>
                                </p>
                            </div>}
                        </div>
                    </div>
                    {details && !details?.session?.isExpired && <div className="session-info-box" data-testid='sessionInfoBox'>
                        <h4>{localisedValues["user_action"]}</h4>
                        <div className="session-action-link">
                            <ul>
                                <li>
                                    <div className="action-link-box" title="Copy guest URL">
                                        <span className="left-icon icon-copylink"></span>
                                        <span className="action-link-text">{localisedValues["copy_guest_url"]}</span>
                                        <button className="btn action-link-btn btn-primary" onClick={() => copyLink()}>
                                            <span className="btn-icon icon-copy"></span>
                                        </button>
                                    </div>
                                </li>
                                {details?.features?.SHOWMEOBSERVER === "1" && <li>
                                    <div className="action-link-box" title="Copy Observer URL">
                                        <span className="left-icon icon-copylink"></span>
                                        <span className="action-link-text">{localisedValues["copy_observer_url"]}</span>
                                        <button className="btn action-link-btn btn-primary" onClick={() => copyLink("silentObserver")}>
                                            <span className="btn-icon icon-copy"></span>
                                        </button>
                                    </div>
                                </li>}
                            </ul>
                        </div>
                    </div>}
                    <div className="session-info-box" data-testid='sessionActionDownloadDiv'>
                        <h4>{localisedValues["download_key"]}</h4>
                        <div className="session-action-link">
                            <ul>
                                {details && <li>
                                    <div className="action-link-box" title="Download Images">
                                        <span className="left-icon icon-imgdwn"></span>
                                        <span className="action-link-text">{details?.session?.imageCount}</span>
                                        <button className="btn action-link-btn btn-primary" disabled={details?.session?.imageCount === 0 && "disabled"} onClick={downloadImages} data-testid='downloadImgBtn'>
                                            <span className="btn-icon icon-download"></span>
                                        </button>
                                    </div>
                                </li>}
                                {showRecordingTab && <li>
                                    <div className="action-link-box" title={details?.session?.pendingRecordingCount === 0 ? "Download Recording" : "Preparing Recording"}>
                                        <span className="left-icon icon-recdwn"></span>
                                        <span className="action-link-text">{details?.session?.recordingCount}</span>
                                        <button className="btn action-link-btn btn-primary" disabled={details?.session?.recordingCount === 0 && "disabled"} onClick={downloadRecordings} data-testid='downloadRecBtn'>
                                            {details?.session?.pendingRecordingCount === 0 && <span className="btn-icon icon-download"></span>}
                                            {details?.session?.pendingRecordingCount > 0 && <span className="btn-loader-icon">
                                                <span className="for-icon icon-loader spin"></span>
                                            </span>}
                                        </button>

                                    </div>
                                </li>}
                                {details?.features?.ENABLEAUDITREPORT === "1" && <li>
                                    <div className="action-link-box" title="Download Report">
                                        <span className="left-icon icon-word-doc"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
                                        <span className="action-link-text">Word (.docx)</span>
                                        <button className="btn action-link-btn btn-primary" disabled={!isDownloadPDFEnabled && "disabled"} onClick={downloadWordDoc} data-testid='downloadWordBtn'>
                                            <span className="btn-icon icon-download"></span>
                                        </button>
                                    </div>
                                </li>}
                            </ul>
                        </div>
                    </div>
                    <div className="session-info-box">
                        <h4>{localisedValues["invites_sent"]}</h4>
                        {details && invitationList.length === 0 && <div className="no-invite-label">
                            {localisedValues["no_invites"]}
                        </div>}
                        {details && invitationList.length > 0 && <div className="invite-sent-list">
                            <ul>

                                {invitationList.map((obj, index) => (
                                    <li key={index}>
                                        <div className="invite-sent-box">
                                            {obj.invitationType === 1 && <><span className="for-icon icon-sms"></span>SMS: <span className="invite-value">{obj.countryCode}{obj.mobileNo}</span> <span className="invite-date">{convertInviteDateTime(obj.invitedAt)}</span></>}
                                            {obj.invitationType === 2 && <><span className="for-icon icon-whatsapp"></span>WhatsApp: <span className="invite-value">{obj.countryCode}{obj.mobileNo}</span> <span className="invite-date">{convertInviteDateTime(obj.invitedAt)}</span></>}
                                            {obj.invitationType === 3 && <><span className="for-icon icon-email"></span>E-mail: <span className="invite-value">{obj.emailAddress}</span> <span className="invite-date">{convertInviteDateTime(obj.invitedAt)}</span></>}
                                            {obj.invitationType === 4 && <><span className="for-icon icon-sg"></span>Smartglass: <span className="invite-value">{obj.mobileNo}</span> <span className="invite-date">{convertInviteDateTime(obj.invitedAt)}</span></>}
                                        </div>
                                    </li>
                                ))}

                            </ul>
                        </div>}
                    </div>
                </div>
                <div className="session-detail-footer" data-testid='sessionDetailFooter'>
                    <div className="button-inline session-detail-footer-btn text-center d-flex justify-content-end align-items-center">

                        {/* Archive Session button */}
                        {showArchiveButton() &&
                            <div className="button" onKeyDown={() => {}} onClick={() => {
                                if (isDiscardChanged) {
                                    setIsDiscardModalOpen(true)
                                    setCurrentActionType({ type: ACTIONS_LIST.ARCHIVE_SESSION })
                                    return
                                }
                                setIsShowArchiveModal(true)
                            }}>
                                <button type="button" className="btn btn-light-grey">
                                    <span className="for-desktop-text">{localisedValues["archive_session"]}</span>
                                    <span className="for-responsive-text">{localisedValues["archive"]}</span>
                                </button>
                            </div>
                        }

                        {/* Session End Div as a button */}
                        {details?.session?.isExpired &&
                            <div className="button" >
                                <div className="session-ended-label big-label">
                                    {localisedValues["session_ended"]}
                                </div>
                            </div>
                        }

                        {/* End session button or End Link button */}
                        {details && showEndButton() &&
                            <div className="button">
                                <button type="button" className="btn btn-light-grey" onClick={() => {
                                    if (isDiscardChanged) {
                                        setIsDiscardModalOpen(true)
                                        setCurrentActionType({ type: ACTIONS_LIST.END_SESSION })
                                        return
                                    }
                                    handleEndSession()
                                }
                                }>
                                    {isUploadOnlySessionValue ? "End Link" :
                                        <>
                                            <span className="for-desktop-text">{localisedValues["end_session"]}</span>
                                            <span className="for-responsive-text">{localisedValues["end_btn"]}</span>
                                        </>
                                    }
                                </button>
                            </div>
                        }

                        {/* Invite or Share button */}
                        {details && !details?.session?.isExpired &&
                            <div className="button for-responsive-button">
                                <button type="button" className="btn btn-primary" onClick={() => inviteSession(false)}>
                                    {isUploadOnlySessionValue ? localisedValues["share"] : localisedValues["invite"]}
                                </button>
                            </div>
                        }

                        {/* Open link or Start Session button */}
                        {details?.session?.sessionType === MEETING_TYPE.SCHEDULED && !details?.session?.isExpired && (isOwner()) &&
                            <div className="button">
                                <button type="button" className="btn btn-success" onClick={() => openJoinCallWindow()}>
                                    {isUploadOnlySessionValue ? localisedValues["open_link"] :
                                        (<>
                                            <span className="for-desktop-text">{localisedValues["start_session"]}</span>
                                            <span className="for-responsive-text">{localisedValues["start_btn"]}</span>
                                        </>)
                                    }
                                </button>
                            </div>
                        }

                        {/* Open Link or Join Session button */}
                        {details?.session?.sessionType === MEETING_TYPE.REGULAR && !details?.session?.isExpired && (isOwner()) &&
                            <div className="button">
                                <button type="button" className="btn btn-success" onClick={() => openJoinCallWindow()}>
                                    {isUploadOnlySessionValue ? localisedValues["open_link"] :
                                        (<>
                                            <span className="for-desktop-text">{localisedValues["join_session"]}</span>
                                            <span className="for-responsive-text">{localisedValues["join"]}</span>
                                        </>)
                                    }
                                </button>
                            </div>
                        }

                        {/* Join as Guest or Join as Observer button*/}
                        {!details?.session?.isExpired && (!isOwner()) &&
                            <div className="button">
                                <div className={clsx("dropdown new-session-dropdown join-option-dropdown", details?.features?.SHOWMEOBSERVER !== '1' && "join-observer-off")}>
                                    <button className="btn btn-success btn-first" type="button" onClick={() => openJoinCallWindow('guest')} >
                                        {localisedValues["join_as_guest"]}
                                    </button>
                                    {details?.features?.SHOWMEOBSERVER === '1' &&
                                        <button className="btn btn-success dropdown-toggle btn-last triangle-none new-session-dropdown-btn"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className="triangle-icon icon-down-triangle-round"></span>
                                        </button>
                                    }
                                    {details?.features?.SHOWMEOBSERVER === '1' &&
                                        <div className="dropdown-menu new-session-dropdown-menu right" aria-labelledby="dropdown">
                                            <div className="dropdown-menu-in">
                                                <ul>
                                                    <li>
                                                        <button className="btn dropdown-item-box" onClick={() => openJoinCallWindow('observer')} >
                                                            <span className="dropdown-item-text">{localisedValues["join_as_observer"]}</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {isShowArchiveModal && (
                    <PopupModal
                        closeModal={() => setIsShowArchiveModal(false)}
                        dialogClass="modal-dialog-centered"
                        closeButton={() => setIsShowArchiveModal(false)}
                        headerContent={
                            <h4 className="modal-title">
                                {localisedValues["archive_session"]}
                            </h4>
                        }
                        bodyContent={
                            <div className="modal-note">
                                <p>{localisedValues["archiving_session_hide_from_session_history"]}<br />{localisedValues["this_cannot_be_undone"]}</p>
                            </div>
                        }
                        cancelText={localisedValues["donnot_archive"]}
                        confirmText={localisedValues["archive_session"]}
                        disableConfirm={archiveSessionLoading}
                        showLoader={archiveSessionLoading}
                        onConfirm={handleArchiveSession}
                        backdrop="static"
                        disablekeyboard={true}
                    />
                )}

                {isScheduleSessionModalOpen && <CreateSessionModal closeModal={() => { setIsScheduleSessionModalOpen(false) }} isForEdit={true} sessionDetails={details} />}
            </div>
        </div>
    );
})

const mapStateToPropsDetailsTab = (state) => {
    return {
        sessionInvitationsData: state.sessionDetail.getSessionInvitationsData,
        getSessionImagesData: state.sessionDetail.getSessionImagesData,
        getSessionRecordingsData: state.sessionDetail.getSessionRecordingsData,
        getSessionWordDocData: state.sessionDetail.getSessionWordDocData,
        getSessionWordDocError: state.sessionDetail.getSessionWordDocError,
        putArchiveSessionData: state.sessionDetail.putArchiveSessionData,
        putArchiveSessionError: state.sessionDetail.putArchiveSessionError,
        putEndSessionData: state.sessionDetail.putEndSessionData,
        putEndSessionError: state.sessionDetail.putEndSessionError,
        putSessionReferenceLoading: state.sessionDetail.putSessionReferenceLoading,
        putSessionReferenceData: state.sessionDetail.putSessionReferenceData,
        putSessionReferenceError: state.sessionDetail.putSessionReferenceError,
        putAssigneeChangeData: state.sessionDetail.putAssigneeChangeData,
        language: state.language
    }
}

const mapDispatchToPropsDetailsTab = (dispatch) => {
    return {
        getSessionInvitations: (req) => dispatch(getSessionInvitations(req)),
        getSessionImages: (req, waterMarkImage, fromWhere) => dispatch(getSessionImages(req, waterMarkImage, fromWhere)),
        getSessionRecordings: (req) => dispatch(getSessionRecordings(req)),
        getSessionWordDoc: (req) => dispatch(getSessionWordDoc(req)),
        putArchiveSession: (sessionId) => dispatch(putArchiveSession(sessionId)),
        putEndSession: (sessionId) => dispatch(putEndSession(sessionId)),
        handleArchiveSession: (data) => dispatch(handleArchiveSession(data)),
        handleEndSession: (data) => dispatch(handleEndSession(data)),
        putReferenceChange: (data) => dispatch(putReferenceChange(data)),
        putReferenceChangeReset: (data) => dispatch(putReferenceChangeReset(data)),
        updateSessionDetails: (data) => dispatch(updateSessionDetails(data)),
    }
}

DetailsTab.propTypes = {
    details: PropTypes.object,
    meetingID: PropTypes.string,
    showRecordingTab: PropTypes.bool,
    putSessionReferenceLoading: PropTypes.bool,
    putSessionReferenceData: PropTypes.bool,
    putSessionReferenceError: PropTypes.bool,
    putReferenceChange: PropTypes.func,
    putReferenceChangeReset: PropTypes.func,
    setDetails: PropTypes.func,
    sessionsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setSessionsList: PropTypes.func,
    isDiscardChanged: PropTypes.bool,
    isRefrenceTitleChanged: PropTypes.bool,
    setIsRefrenceTitleChanged: PropTypes.func,
    setIsSessionAssignChange: PropTypes.func,
    setIsDiscardModalOpen: PropTypes.func,
    setCurrentActionType: PropTypes.func,
    setIsDiscardModalSaveBtnDisabled: PropTypes.func,
    localisedValues: PropTypes.object,
    getSessionInvitations: PropTypes.func,
    getSessionImages: PropTypes.func,
    getSessionRecordings: PropTypes.func,
    getSessionWordDoc: PropTypes.func,
    putArchiveSession: PropTypes.func,
    putEndSession: PropTypes.func,
    handleArchiveSession: PropTypes.func,
    handleEndSession: PropTypes.func,
    language: PropTypes.object,
    sessionInvitationsData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    getSessionImagesData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    getSessionRecordingsData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    getSessionWordDocData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    getSessionWordDocError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    putArchiveSessionData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    putArchiveSessionError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    putEndSessionData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    putEndSessionError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default connect(mapStateToPropsDetailsTab, mapDispatchToPropsDetailsTab, null, { forwardRef: true })(DetailsTab);