import { LOCAL_STORAGE_KEYS } from "../constants/Constants";
import storage from "./storage";
import api from "../apis/api";

class LoginService {
    postLogIn(formData) {
        return api.login.postLogIn(formData);
    }

    postVerifyOTP(formData) {
        return api.login.postVerifyOTP(formData);
    }

    postResendOTP(formData) {
        return api.login.postResendOTP(formData);
    }

    postVerifyDomain(domainName) {
        return api.login.postVerifyDomain(domainName);
    }

    isLoggedIn(){
        const configData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
        if(configData){
            return (configData.userId);
        }
        else{
            return false;
        }
    }

    postFreshDeskVerify(formData){
        return api.login.postFreshDeskVerify(formData);
    }
}

export default new LoginService();