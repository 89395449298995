import { APIs } from "../constants/Constants";
import api from "./api";

export default class LoginAPI {
    postLogIn(formData) {
        return api.post(APIs.LOGIN, formData);
    }

    postVerifyOTP(formData) {
        return api.post(APIs.EMAIL_VERIFICATION, formData);
    }
    
    postResendOTP(formData) {
        return api.post(APIs.RESEND_EMAIL_VERIFICARION, formData);
    }

    postVerifyDomain(domainName) {
        return api.get(APIs.ISCOMPANYAVAILABLE + encodeURIComponent(domainName));
    }

    postFreshDeskVerify(formData) {
        return api.post(APIs.FRESH_DESK_VERIFY_URL, formData);
    }
}
