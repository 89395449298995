import React, { useEffect } from 'react';
import CreateSessionModal from '../CreateSessionModal/CreateSessionModal';
import { LOCAL_STORAGE_KEYS, RouteName } from '../../../constants/Constants';
import history from '../../../history';
import storage from '../../../services/storage';
import { parseURLParams } from '../../../shared/utility';

const CreateAndInviteSession = () => {

  useEffect(() => {
    const dataForSession = storage.get(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION);
    if (dataForSession) {
      const extraParams = parseURLParams(dataForSession);
      if (extraParams.page == '1') {
        history.push(RouteName.SEND_CODE);
      } else {
        history.push(RouteName.CREATE_AND_INVITE + dataForSession);
      }
    }
  }, [])

  return (
    <CreateSessionModal
      closeModal={() => history.push(RouteName.SESSIONS)}
    />
  );
};

export default CreateAndInviteSession;