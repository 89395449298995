import { connect } from "react-redux";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import __ from "lodash";
import OTPInput, {} from "otp-input-react";
import PropTypes from 'prop-types';
import CustomToast from "../UI/customToast";
import history from "../../history";
import PublicVariables from "../../constants/PublicVariables";
import { RouteName } from "../../constants/Constants";
import { CheckOTPForSelfSignup, AgainSendOTPForSelfSignup } from "../../actions/selfSignupAction";
import SelfSignup from "./SelfSignup";
import { Localize } from "./Localize";

const Confirmation = (props) => {
    const [otp, setOtp] = useState('');
    const [isResendCodeDisable, setIsResendCodeDisable] = useState(false);
    const [localisedValues, setLocalisedValues] = useState({});
    const [toastObj, setToastObj] = useState(null);

    const handleOtpValueChange = (value) => {
        setOtp(value);
        if(value.length === 6){
            const currentToastObj = CustomToast.info(localisedValues['validating_code']);
             
            setToastObj(currentToastObj);
            props.CheckOTPForSelfSignup({
                verificationId: PublicVariables.trialemail.verificationId,
                verificationCode: value,
                emailId: PublicVariables.trialemail.email
            },localisedValues);
        }
    };

    useEffect(()=>{
        if(props.selfSignup.checkOTPData){
            console.log(props.selfSignup.checkOTPData);
            if(props.selfSignup.checkOTPData.userExist > 0){
                PublicVariables.trialemail.hasuserprofile = props.selfSignup.checkOTPData.userProfile;  
                history.replace(RouteName.COMPANY_INFO);                                
            }else{
                PublicVariables.trialemail.hasuserprofile = false;
                history.replace(RouteName.USER_INFO);
            }
        }
    },[props.selfSignup.checkOTPData]);

    useEffect(()=>{
        if(props.selfSignup.checkOTPError){
            CustomToast.update(toastObj, props.selfSignup.checkOTPError.message, "error");
        }
    },[props.selfSignup.checkOTPError]);

    useEffect(()=>{
        setIsResendCodeDisable(props.selfSignup.againSendOTPLoading);
        if(props.selfSignup.againSendOTPData){
            CustomToast.success(localisedValues['ccod_resend_text']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.selfSignup.againSendOTPLoading, props.selfSignup.againSendOTPData]);

    const handleOtpPaste = (e) => {
        /* the session has shut down */
        let clipboardData, pastedData;
        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();
        // Get pasted data via clipboard API
        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('Text');
        const replaceData = pastedData?.replace('-', '')?.trim();
        handleOtpValueChange(replaceData)
        const a =document.getElementsByClassName('otp-frm');
        a[0].lastChild.focus();
    }

    useEffect(()=>{
        const a =document.getElementsByClassName('otp-frm');
        a[0].firstChild.focus();
        if(!PublicVariables.trialemail.email){
            history.replace(RouteName.LOGIN);
        }
        document.addEventListener("paste", handleOtpPaste, false);
        return () => {
            document.removeEventListener("paste", handleOtpPaste);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    const resendOtpFun = () => {
        props.AgainSendOTPForSelfSignup(PublicVariables.trialemail.email);
    }

    return (
        <SelfSignup>
            <div className="signup-in-scroll custom-scroll-div">
                <div className="login-logo-subtext text-center" data-testid="heading">
                    <h2>{localisedValues['check_ccode_text']}</h2>
                    <p>{parse(__.replace(localisedValues['ccod_genral_text'], '<trial_email>', PublicVariables.trialemail.email))}</p>
                </div>
                <div className="form-group">
                {!__.isEmpty(localisedValues) && <label className="form-label verify-otp-label">{parse(localisedValues['your_ccode_text'])}</label>}
                    <div className="verification-form"> 
                        <OTPInput 
                            className="otp-frm"
                            otpType="number"
                            value={otp}
                            onChange={handleOtpValueChange}
                            OTPLength={6}
                            inputClassName="form-control form-control-grey"
                        />	                                   
                    </div>
                </div>
                <div className="login-bottom-text">
                    <div className="have-an-account text-center" data-testid="note">
                        {!__.isEmpty(localisedValues) && parse(localisedValues['ccode_note1_text'])}&nbsp;
                        {!isResendCodeDisable ? <a href={void(0)} className="text-dec-none pointer" onClick={resendOtpFun}>{localisedValues['resend_code']}</a> : localisedValues['resend_code']}</div>
                </div>
            </div> 
        </SelfSignup>
    )
}

Confirmation.propTypes = {
    selfSignup: PropTypes.object,
    language: PropTypes.object,
    CheckOTPForSelfSignup: PropTypes.func,
    AgainSendOTPForSelfSignup: PropTypes.func,
}

const mapStateToPropsConfirmation = (state) => {
    return {
        language: state.language,
        selfSignup: state.selfSignup
    }
}

const mapDispatchToPropsConfirmation = (dispatch) =>{
    return {
        CheckOTPForSelfSignup: (req, localisedValues) => dispatch(CheckOTPForSelfSignup(req, localisedValues)),
        AgainSendOTPForSelfSignup: (req) => dispatch(AgainSendOTPForSelfSignup(req))
    }
}

export default connect(mapStateToPropsConfirmation, mapDispatchToPropsConfirmation)(Confirmation);