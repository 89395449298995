import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./List/SessionList.scss";
import "./Detail/SessionDetail.scss";
import { ACTIONS_LIST, LOCAL_STORAGE_KEYS, RouteName } from '../../constants/Constants';

import SessionDetail from "./Detail/SessionDetail";
import SessionList from "./List/SessionList";
import DiscardModal from './DiscardModal/DiscardModal';
import history from '../../history';
import storage from '../../services/storage';
import loginService from '../../services/loginService';
import { setPageTitle } from '../../shared/utility';

const SESSION_LIST_TITLE = "Session History";

const Sessions = () => {

    const params = useParams();
    const [sessionsList, setSessionsList] = useState([]);
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false)
    const [isDiscardChanged, setIsDiscardChanged] = useState(false)
    const [isRefrenceTitleChanged, setIsRefrenceTitleChanged] = useState(false)
    const [isSessionAssignChange, setIsSessionAssignChange] = useState(false)
    const [currentActionType, setCurrentActionType] = useState('')
    const [isDiscardModalSaveBtnDisabled, setIsDiscardModalSaveBtnDisabled] = useState(false)
    const sessionDetailsRef = useRef(null)
    const sessionListRef = useRef(null)
    const eventListenerRef = useRef();

    const getFreshDeskUrl = async () => {
        try {
            let freshDeskUrl = storage.get(LOCAL_STORAGE_KEYS.FRESH_DESK_URL)
            freshDeskUrl = new URLSearchParams(freshDeskUrl)
            if (freshDeskUrl.has('nonce') && freshDeskUrl.has('redirect_uri') && freshDeskUrl.has('state')) {
                const payload = {
                    redirect_uri: freshDeskUrl.get('redirect_uri'),
                    nonce: freshDeskUrl.get('nonce'),
                    state: freshDeskUrl.get('state')
                }
                const res = await loginService.postFreshDeskVerify(payload)
                if (res.redirectUrl) {
                    window.location.href = res.redirectUrl
                    storage.remove(LOCAL_STORAGE_KEYS.FRESH_DESK_URL)
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        // For FreshDesk View
        getFreshDeskUrl()
    }, [])

    useEffect(() => {
        if (isEmpty(params)) {
            setPageTitle(SESSION_LIST_TITLE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    /**
     * The above code is a JavaScript function that adds an event listener to the window object to prompt a
     * confirmation message before leaving the page if there are unsaved changes.
     * @param e - The parameter `e` is an event object that represents the event that triggered the
     * `onUnload` function. In this case, it is the `beforeunload` event, which is fired when the user is
     * about to leave the page.
     */
    const onUnload = (e) => {
        if (isDiscardChanged) {
            e.preventDefault();
            e.stopPropagation();
            setIsDiscardModalOpen(true)
            e.returnValue = "Are you sure you want to leave this page?";
        }
    }

    useEffect(() => {
        eventListenerRef.current = onUnload;
        window.addEventListener('beforeunload', eventListenerRef.current);
        return () => window.removeEventListener('beforeunload', eventListenerRef.current);
    }, [isDiscardChanged]);

    useEffect(() => {
        setIsDiscardChanged(isSessionAssignChange || isRefrenceTitleChanged)
    }, [isSessionAssignChange, isRefrenceTitleChanged])

    const resetAllState = () => {
        setIsDiscardModalOpen(false)
        setIsDiscardChanged(false)
        setIsRefrenceTitleChanged(false)
        setIsSessionAssignChange(false)
        setCurrentActionType('')
    }

    const handleNextAction = () => {
        const { type, payload } = currentActionType
        switch (type) {
            case ACTIONS_LIST.CREATE_SESSION:
                sessionListRef.current.openCreateSessionModal()
                break;
            case ACTIONS_LIST.CHANGING_SESSION:
                sessionListRef.current.changeSession(payload)
                break;
            case ACTIONS_LIST.INVITE_FROM_CELL:
                sessionListRef.current.handlInviteFromCell()
                break;
            case ACTIONS_LIST.INVITE_CONTACT:
                sessionListRef.current.handleInviteContact()
                break;
            case ACTIONS_LIST.CHANGE_FILTER:
                history.push(RouteName.SESSIONS)
                break;
            case ACTIONS_LIST.SELECT_USER:
                history.push(RouteName.SESSIONS)
                break;
            case ACTIONS_LIST.ARCHIVE_SESSION:
                sessionDetailsRef.current.handleArchiveSession()
                break;
            case ACTIONS_LIST.END_SESSION:
                sessionDetailsRef.current.handleEndSessionOrLink()
                break;
            case ACTIONS_LIST.BACK_BTN_CLICK:
                backButtonClick(true)
                break;
            case ACTIONS_LIST.EDIT_SCHEDULE_SESSION:
                sessionDetailsRef.current.handleEditScheduleSession()
                break;
            case ACTIONS_LIST.JOIN_CALL:
                sessionListRef.current.handleJoinSessionCallFromCell(payload)
                break;
            case ACTIONS_LIST.START_JOIN_SESSION:
                window.removeEventListener("beforeunload", eventListenerRef.current)
                sessionDetailsRef.current.handleStartJoinSession(payload)
                break;
            case ACTIONS_LIST.INVITE_BTN_FROM_MWEB:
                sessionDetailsRef.current.handleInviteBtnFromMweb()
                break;
            case ACTIONS_LIST.BROWSER_BACK_BTN:
                sessionDetailsRef.current.handleBrowserClick()
                break;
            default:
                break;
        }
    }

    const handleOnCancelModal = () => {
        setIsDiscardModalOpen(false)
    }

    const handleOnDiscardModal = () => {
        if (isRefrenceTitleChanged) {
            sessionDetailsRef.current?.cancelReference()
        }
        if (isSessionAssignChange) {
            sessionDetailsRef.current?.cancelAssignee()
        }
        handleNextAction()
        resetAllState()
    }

    const handleOnSaveModal = () => {
        if (isRefrenceTitleChanged) {
            sessionDetailsRef.current?.saveReference()
        }
        if (isSessionAssignChange) {
            sessionDetailsRef.current?.saveAssignee()
        }
        setTimeout(() => {
            handleNextAction()
            resetAllState()
        }, 500);
    }

    const backButtonClick = (fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.BACK_BTN_CLICK })
            return
        }
        history.push(RouteName.SESSIONS);
    }

    return (
        <>
            <SessionList ref={sessionListRef} sessionsList={sessionsList} setSessionsList={setSessionsList} isDiscardChanged={isDiscardChanged} setIsDiscardModalOpen={setIsDiscardModalOpen} setCurrentActionType={setCurrentActionType} />
            <SessionDetail ref={sessionDetailsRef} currentActionType={currentActionType} backButtonClick={backButtonClick} sessionsList={sessionsList} setSessionsList={setSessionsList} isDiscardChanged={isDiscardChanged} isSessionAssignChange={isSessionAssignChange} setCurrentActionType={setCurrentActionType} setIsSessionAssignChange={setIsSessionAssignChange} isRefrenceTitleChanged={isRefrenceTitleChanged} setIsRefrenceTitleChanged={setIsRefrenceTitleChanged} setIsDiscardModalOpen={setIsDiscardModalOpen} setIsDiscardChanged={setIsDiscardChanged} setIsDiscardModalSaveBtnDisabled={setIsDiscardModalSaveBtnDisabled} />
            {isDiscardModalOpen && isDiscardChanged && <DiscardModal onCancel={handleOnCancelModal} onDiscard={handleOnDiscardModal} onSave={handleOnSaveModal} isSaveBtnDisabled={isDiscardModalSaveBtnDisabled} />}
        </>
    );
}

export default Sessions;