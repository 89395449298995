import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ReactPasswordChecklist from "react-password-checklist";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PASSWORD_MIN_LENGHT, RouteName } from "../../constants/Constants";
import { Localize } from "../SignUp/Localize";
import { RecoverPasswordForUser } from "../../actions/changePasswordAction";
import { PopupModal } from "../UI/Modal/Modal";
import history from "../../history";

const RecoverPassword = () => {
  const [localisedValues, setLocalisedValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { language, changePassword } = useSelector((state) => state);
  const dispatch = useDispatch();
  const params = useParams();

  const onClose = () => {
    setIsModalVisible(false);
    history.push(RouteName.LOGIN);
  };

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  useEffect(() => {
    setIsSubmitting(changePassword.recoverPasswordLoading);
    if (changePassword.recoverPasswordData) {
      setIsModalVisible(true);
      setTimeout(() => {
        history.push(RouteName.LOGIN);
      }, 5000);
    }
  }, [changePassword]);

  return (
    <>
      <div className="login-section-box custom-scroll-div change-password">
        <div className="login-section-in">
          <div className="login-section-in-center">
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().required(
                  localisedValues["crt_acnt_enter_password"]
                ),
                confirmPassword: Yup.string()
                  .required(localisedValues["crt_acnt_enter_confirm_password"])
                  .oneOf(
                    [Yup.ref("password")],
                    localisedValues["crt_acnt_match_password_msg"]
                  ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  dispatch(
                    RecoverPasswordForUser({
                      authenticationKey: params.id,
                      password: values.password,
                    })
                  );
                  setSubmitting(false);
                }, 400);
              }}
            >
              {(formik) => (
                <Form>
                  <div className="login-logo-subtext text-center">
                    <h3>{localisedValues["user_change_password"]}</h3>
                  </div>
                  <div className="form-group">
                    <div className="form-group-in with-icon">
                      <Field
                        data-testid="password"
                        id="password"
                        className="form-control form-control-grey height-46 focus-font-bold"
                        placeholder={localisedValues["user_enter_new_password"]}
                        name="password"
                        type={!isPasswordVisible ? "password" : "text"}
                        touched={formik.touched.password}
                        maxlength="20"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          setShowPasswordPopup(false);
                          formik.handleBlur(e);
                        }}
                        onFocus={(_e) => {
                          setShowPasswordPopup(true);
                        }}
                      />
                      <span className="form-group-icon bg-none">
                        <span
                          onKeyDown={() => {}}
                          onClick={() =>
                            setIsPasswordVisible(!isPasswordVisible)
                          }
                          className={`form-group-icon-in pointer ${
                            !isPasswordVisible ? "icon-eye" : "icon-eye-off"
                          } `}
                        ></span>
                      </span>
                      {showPasswordPopup && (
                        <div className="password-valid-info">
                          <div className="password-valid-info-in">
                            <ReactPasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital",
                                "lowercase",
                              ]}
                              minLength={PASSWORD_MIN_LENGHT}
                              value={password}
                              iconComponents={{
                                ValidIcon: (
                                  <span className="icon-checked-fill blue-icon"></span>
                                ),
                                InvalidIcon: (
                                  <span className="icon-checked-fill"></span>
                                ),
                              }}
                              onChange={(isValid) => {
                                isValid === true
                                  ? setIsPasswordValid(true)
                                  : setIsPasswordValid(false);
                              }}
                              messages={{
                                minLength: localisedValues["min_12_char"],
                                specialChar:
                                  localisedValues["at_least_one_spec_char"],
                                number:
                                  localisedValues["at_least_one_num_char"],
                                capital:
                                  localisedValues["at_least_one_upper_char"],
                                lowercase:
                                  localisedValues["at_least_one_lower_char"],
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {formik.touched.password &&
                      (formik.errors.password || !isPasswordValid) && (
                        <div className="validation-error">
                          {formik.errors.password ||
                            localisedValues["password_validation_msg"]}
                        </div>
                      )}
                  </div>
                  <div className="form-group">
                    <div className="form-group-in with-icon">
                      <Field
                        data-testid="confirmPassword"
                        id="confirmPassword"
                        className="form-control form-control-grey height-46 focus-font-bold"
                        placeholder={
                          localisedValues["chng_pwd_reenter_new_pwd"]
                        }
                        name="confirmPassword"
                        type="password"
                        touched={formik.touched.confirmPassword}
                        maxlength="20"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      <span
                        className={`form-group-icon ${
                          formik.touched.password &&
                          isPasswordValid &&
                          formik.isValid
                            ? "blue-icon"
                            : ""
                        } bg-none`}
                      >
                        <span className="form-group-icon-in icon-checked-fill"></span>
                      </span>
                    </div>
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <div className="validation-error">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>

                  <div className="button-inline text-center padding-top-10 d-flex justify-content-center">
                    <div className="button">
                      <button
                        data-testid="changeBtn"
                        type="submit"
                        className="btn btn-primary btn-with-icon login-btn font-weight-500"
                        disabled={
                          isSubmitting || !formik.isValid || !isPasswordValid
                        }
                      >
                        {isSubmitting && (
                          <span className="btn-loader-icon">
                            <span className="for-icon icon-loader spin"></span>
                          </span>
                        )}
                        {localisedValues["user_change"]}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <PopupModal
          closeModal={onClose}
          modalClass=""
          dialogClass="modal-dialog-centered"
          headerContent={
            <h4 className="modal-title" data-testid="modalHeaderTitle">
              {localisedValues["pwd_changed"]}
            </h4>
          }
          bodyContent={
            <div className="modal-note">
              <h6>{localisedValues["pwd_changed_please_wait"]}</h6>
            </div>
          }
          disablekeyboard={true}
          backdrop="static"
          disableConfirm={false}
          cancelButton={false}
          confirmText={localisedValues["okay_cap"]}
          showLoader={false}
          onConfirm={onClose}
        />
      )}
    </>
  );
};

export default RecoverPassword;
