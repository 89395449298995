import { AES, enc } from "crypto-js";
import __ from "lodash";
import { LOCAL_STORAGE_PREFIX, LOCAL_STORAGE_KEY, VERSION_KEY, LOCAL_STORAGE_KEYS } from "../constants/Constants";

const secretKey = LOCAL_STORAGE_KEY;
const prefix = LOCAL_STORAGE_PREFIX;

class StoreClass {
  get(key) {
    const item = localStorage.getItem(prefix + '.' + key) && localStorage.getItem(prefix + '.' + key)?.replaceAll('"', '');
    if (item && key !== VERSION_KEY && key !== LOCAL_STORAGE_KEYS.USER_TOKEN && key !== LOCAL_STORAGE_KEYS.REFRESH_TOKEN) {
      const value = AES.decrypt(item, secretKey).toString(enc.Utf8);
      return (value) ? JSON.parse(value) : value;
    }
    else {
      return item;
    }
  }

  set(key, value) {
    if (value !== null && value !== undefined && key !== VERSION_KEY && key !== LOCAL_STORAGE_KEYS.USER_TOKEN && key !== LOCAL_STORAGE_KEYS.REFRESH_TOKEN) {
      localStorage.setItem(prefix + '.' + key, AES.encrypt(JSON.stringify(value), secretKey).toString());
    }
    else {
      localStorage.setItem(prefix + '.' + key, value);
    }
  }

  remove(key) {
    localStorage.removeItem(prefix + '.' + key);
  }

  // Note :  For boolean values isEmpty will return true
  has(key) {
    const val = this.get(key)
    return __.isBoolean(val) ? true : (!__.isUndefined(val) && !__.isNull(val) && (!__.isEmpty(val) || __.isNumber(val)));
  }

  clearAll() {
    localStorage.clear();
  }

  getUserToken() {
    const configData = this.get(LOCAL_STORAGE_KEYS.USER_DATA);
    return configData.userToken;
  }
}

export default new StoreClass();
