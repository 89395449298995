/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from 'yup';
import { isEmpty } from "lodash";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { EMAIL_PATTERN_REGEX } from "../../constants/Constants"
import { PopupModal } from "../UI/Modal/Modal";
import CustomToast from "../UI/customToast";
import { FormikInputGroup } from "../UI/TextInput/FormikInputGroup";
import { ForgotPasswordForUser } from "../../actions/changePasswordAction";

const ForgotPassword = (props) => {
    const [isSubmittingForPwd, setIsSubmittingForPwd] = useState(false);

    useEffect(() => {
        setIsSubmittingForPwd(props.forgotPassword.forgotPasswordLoading);
        if(props.forgotPassword.forgotPasswordData && isSubmittingForPwd){
            setIsSubmittingForPwd(false);
            props.onClose(false);
            CustomToast.success(props.localisedValues['check_link_reset_pwd']);
        }
        // eslint-disable-next-line
    }, [props.forgotPassword.forgotPasswordLoading, props.forgotPassword.forgotPasswordData]);  

    const closeModal = () =>{
        props.onClose(false)
    }
    return (
        <Formik
            initialValues={{
                emailForPwd: ''
            }}
            validationSchema={
                Yup.object({
                    emailForPwd: Yup.string()
                    .required(props.localisedValues['email_ID_is_required'])
                    .max(255, props.localisedValues['error_limit_exceeded'])
                    .matches(EMAIL_PATTERN_REGEX,props.localisedValues['enter_valid_email'])
                })
            }
            onSubmit={(values) => {
                setTimeout(() => {
                    console.log(values);
                    props.ForgotPasswordForUser(values);
                }, 400)
            }}
        >
                {formik => (
                    <PopupModal
                        closeModal={closeModal}
                        modalClass=""
                        dialogClass="modal-dialog-centered"
                        headerContent={
                            <h5 className="modal-title" data-testid="modalHeaderTitle">{props.localisedValues['reset_password']}</h5>
                        }
                        bodyContent={
                            <div className="reset-password-form">
                                <FormikInputGroup
                                    id="emailForPwd"
                                    type="text"
                                    className="height-46 focus-font-bold"
                                    placeholder={props.localisedValues['enter_email_id']}
                                    touched={formik.touched.emailForPwd}
                                    error={formik.errors.emailForPwd}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                />
                                <div className="modal-note">
                                    <p><strong>{props.localisedValues['no_email']}</strong>&nbsp;{props.localisedValues['reset_password_message']}</p>
                                </div>
                            </div>
                        }
                        cancelText={props.localisedValues['close']}
                        confirmText={props.localisedValues['send']}
                        disableConfirm={isSubmittingForPwd || !formik.isValid || isEmpty(formik.values.emailForPwd)}
                        showLoader={isSubmittingForPwd}
                        onConfirm={formik.submitForm}
                        backdrop='static'
                        disablekeyboard={true}
                    />
                )}
                </Formik>
    )
}

const mapStateToPropsForgotPassword = (state) => {
    return {
        language: state.language,
        forgotPassword: state.changePassword
    }
}

const mapDispatchToPropsForgotPassword = (dispatch) =>{
    return {
        ForgotPasswordForUser: (req) => dispatch(ForgotPasswordForUser(req))
    }
}

ForgotPassword.propTypes = {
    forgotPassword: PropTypes.object,
    localisedValues: PropTypes.object,
    ForgotPasswordForUser: PropTypes.func,
    onClose: PropTypes.func,
}

export default connect(mapStateToPropsForgotPassword, mapDispatchToPropsForgotPassword)(ForgotPassword);

