import { APIs } from "../constants/Constants";
import api from "./api";

export default class SSOAuthLoginAPI {
    postSSOLogIn(token) {
        return api.post(APIs.SSO_LOGIN + token);
    }

    postAuthLogIn(...args) {
        return api.post(APIs.AUTH_LOGIN, ...args);
    }

}
