import { EVENTS, LOGIN, RESEND_OTP, VERIFY_DOMAIN, VERIFY_OTP } from "../reducers/login-reducer";
import LoginService from "../services/loginService";
import { dehydrateLogin, dehydrateVerfiyOtp, dehydrateResendOtp} from "../transformers/loginTransformer";
import {afterLoginProcess, handleErrorMsgToastVisiblity} from "../shared/utility"
import { fetchDomainList } from "./onLoadFunctionAction";
import { getLanguage } from "./languageAction";

const loginStart = () => {
    return {
        event: EVENTS.LOGIN,
        type: LOGIN.START
    }
}

export const loginSuccess = (userdata) => {
    return {
        event: EVENTS.LOGIN,
        type: LOGIN.SUCCESS,
        payload: userdata
    }
}

const loginError = (error) => {
    return {
        event: EVENTS.LOGIN,
        type: LOGIN.ERROR,
        payload: error
    }
}
export const loginReset = () => {
    return {
      event: EVENTS.LOGIN,
      type: LOGIN.RESET
    }
  }
export const LoginUser = (formData) => async (dispatch) => {
    dispatch(loginStart());

    LoginService
        .postLogIn(dehydrateLogin(formData))
        .then((result) => {
            console.log("Login successful");
            console.log(result);
            if(!result.otpVerificationRequired){
                dispatch(fetchDomainList())
                afterLoginProcess(result);
            }
            dispatch(loginSuccess(result));
            if(result.userToken){
                dispatch(getLanguage());
            }
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error.message);
            dispatch(loginError(error));
        })
}

const verifyOtpStart = () => {
    return {
        event: EVENTS.VERIFY_OTP,
        type: VERIFY_OTP.START
    }
}

export const verifyOtpSuccess = (userdata) => {
    return {
        event: EVENTS.VERIFY_OTP,
        type: VERIFY_OTP.SUCCESS,
        payload: userdata
    }
}

const verifyOtpError = (error) => {
    return {
        event: EVENTS.VERIFY_OTP,
        type: VERIFY_OTP.ERROR,
        payload: error
    }
}

export const VerifyOTP = (formData) => async (dispatch) => {
    dispatch(verifyOtpStart());

    LoginService
        .postVerifyOTP(dehydrateVerfiyOtp(formData))
        .then((response) => {
            console.log("verfiy otp successful");
            console.log(response);
            dispatch(fetchDomainList())
            // handling afterloginprocess function in authy component
            dispatch(verifyOtpSuccess(response));
            // reseting it on load as when any user do logout from other portal in diff tab then need to redirect to login page in current tab and it should not show Authy page
            dispatch(loginReset());
            if(response.userToken){
                dispatch(getLanguage());
            }
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(verifyOtpError(error));
        })
}

const resendOtpStart = () => {
    return {
        event: EVENTS.RESEND_OTP,
        type: RESEND_OTP.START
    }
}

const resendOtpSuccess = (data) => {
    return {
        event: EVENTS.RESEND_OTP,
        type: RESEND_OTP.SUCCESS,
        payload: data
    }
}

const resendOtpError = (error) => {
    return {
        event: EVENTS.RESEND_OTP,
        type: RESEND_OTP.ERROR,
        payload: error
    }
}

export const ResendOTP = (data) => async (dispatch) => {
    dispatch(resendOtpStart());

    LoginService
        .postResendOTP(dehydrateResendOtp(data))
        .then((response) => {
            console.log(response);
            dispatch(resendOtpSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(resendOtpError(error));
        })
}

const verifyDomainStart = () => {
    return {
        event: EVENTS.VERIFY_DOMAIN,
        type: VERIFY_DOMAIN.START
    }
}

const verifyDomainSuccess = (data) => {
    return {
        event: EVENTS.VERIFY_DOMAIN,
        type: VERIFY_DOMAIN.SUCCESS,
        payload: data
    }
}

const verifyDomainError = (error) => {
    return {
        event: EVENTS.VERIFY_DOMAIN,
        type: VERIFY_DOMAIN.ERROR,
        payload: error
    }
}

export const VerifyDomain = (domainName) => async (dispatch) => {
    dispatch(verifyDomainStart());

    LoginService
        .postVerifyDomain(domainName)
        .then((response) => {
            dispatch(verifyDomainSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(verifyDomainError(error));
        })
}
