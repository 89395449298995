
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Form, Formik, Field } from "formik";
import { FormikInputWithIcon } from "../UI/TextInput/FormikInputWithIcon";
import * as Yup from 'yup';
import PasswordChecklist from "react-password-checklist"
import __ from "lodash";
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { connect } from "react-redux";
import "./SignUp.scss";
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONURL, PASSWORD_MIN_LENGHT } from "../../constants/Constants";
import { SubmitButton } from "../UI/Button/Button";
import { Card } from "../UI/Card/Card";
import { Localize } from "./Localize";
import { useFlags } from 'launchdarkly-react-client-sdk';

const RegisterUser = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [showPasswordPopup, setShowPasswordPopup] = useState(false);
    const [password, setPassword] = useState("");
    const [localisedValues, setLocalisedValues] = useState({});
    const [privacyPolicyFinalLink, setPrivacyPolicyFinalLink] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const ldFlags = useFlags();

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    useEffect(() => {
        if (localisedValues) {
            if(ldFlags && !ldFlags.helpView){
                setPrivacyPolicyFinalLink(localisedValues["by_continuing_you_agree_terms_privacy_policy"]);
            } else{
                let privacyPolicyLink = __.replace(
                    localisedValues["by_continuing_you_agree_terms_privacy_policy"],
                    localisedValues["terms_of_use"],
                    '<a href="' + TERMS_AND_CONDITIONURL + '" target="_blank">' + localisedValues["terms_of_use"] + '</a>')
                setPrivacyPolicyFinalLink(__.replace(
                    privacyPolicyLink,
                    localisedValues["privacy_policy"],
                    '<a href="' + PRIVACY_POLICY_URL + '" style="cursor:pointer" target="_blank">' + localisedValues["privacy_policy"] + '</a>'))
            }
        }
    }, [localisedValues, ldFlags])

    useEffect(() => {
        if (props.registerProps) {
            if (props.registerProps.registerSignUpUserLoading) {
                setIsSubmitting(props.registerProps.registerSignUpUserLoading)
            }
            if (props.registerProps.registerSignUpUserData) {
                props.cleanSignupData();  //user redirect to session list page
                // history.push(RouteName.LOGIN);   
            }
            if (props.registerProps.registerSignUpUserError) {
                setIsSubmitting(false);
            }
        }

        // eslint-disable-next-line
    }, [props.registerProps?.registerSignUpUserData, props.registerProps?.registerSignUpUserLoading, props.registerProps?.registerSignUpUserError])

    useEffect(() => {
        /* Manually trigger validation
           whenever the password changes/autofill */
        validatePassword(password);
      }, [password]);

    const validatePassword = (password) => {
      /* Manually validate the
         password using the rules */
      const isValid = validatePasswordRules(password);
      setIsPasswordValid(isValid);
    };

    const validatePasswordRules = (password) => {
      return (
        password.length >= PASSWORD_MIN_LENGHT &&
        /[a-z]/.test(password) &&
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)
      );
    };

    const handleRegisterUser = (values) => {
        let userdata = {
            FNAME: values.firstName,
            LNAME: values.lastName,
            UPASSWORD: values.password,
            UserName: props.userEmail,
        };
        props.handleRegisterUser(userdata);
    };

    return (
            <div className="login-section-box custom-scroll-div">
                <div className="login-section-in">
                    <Card>
                        <Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                password: '',
                            }}

                            validationSchema={
                                Yup.object().shape({
                                    firstName: Yup
                                        .string().trim()
                                        .max(30, localisedValues["crt_acnt_limit_character_msg"])
                                        .required(localisedValues["crt_acnt_valid_first_name"]),
                                    lastName: Yup
                                        .string().trim()
                                        .max(30, localisedValues["crt_acnt_limit_character_msg"])
                                        .required(localisedValues["crt_acnt_enter_last_name"]),
                                    password: Yup.string().required(localisedValues["password_validation_msg"])
                                })
                            }

                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    handleRegisterUser(values);
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {formik => (
                                    <Form>
                                        <div className="login-logo">
                                            <img src={process.env.REACT_APP_COM_URL + props.userDetails?.domainLogo} alt="logo"></img>
                                        </div>
                                        <div className="login-logo-subtext text-center">
                                            <h3>{localisedValues["complete_your_account_details_below"]}</h3>
                                            <p>{localisedValues["hi_key"]} <strong>{props.userEmail + ","}</strong></p>
                                            <p>{localisedValues["create_account_new_ui_desc"]}</p>
                                        </div>

                                        <FormikInputWithIcon
                                            divClass="with-icon"
                                            id="firstName"
                                            type="text"
                                            iconSpanClass="valid-icon"
                                            className="height-46 focus-font-bold"
                                            placeholder={localisedValues["user_first_name"]}
                                            touched={formik.touched.firstName}
                                            error={formik.errors.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                                            iconClassName="icon-user"
                                            isCheckValid={(!!formik.values.firstName && formik.errors.firstName === undefined) || (formik.errors.firstName === undefined && formik.touched.firstName) || (!formik.touched.firstName && !!formik.values.firstName)}
                                        />

                                        <FormikInputWithIcon
                                            divClass="with-icon"
                                            id="lastName"
                                            type="text"
                                            iconSpanClass="valid-icon"
                                            className="height-46 focus-font-bold"
                                            placeholder={localisedValues["user_last_name"]}
                                            touched={formik.touched.lastName}
                                            error={formik.errors.lastName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                                            iconClassName="icon-user"
                                            isCheckValid={(!!formik.values.lastName && formik.errors.lastName === undefined) || (formik.errors.lastName === undefined && formik.touched.lastName) || (!formik.touched.lastName && !!formik.values.lastName)}
                                        />

                                        <div className="form-group">
                                            <div className="form-group-in with-icon">
                                                <Field
                                                    id="password"
                                                    className="form-control form-control-grey height-46 focus-font-bold"
                                                    placeholder={localisedValues["crt_acnt_password"]}
                                                    name="password"
                                                    type={!isPasswordVisible ? "password" : "text"}
                                                    touched={formik.touched.password}
                                                    maxlength="20"
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                        formik.handleChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        setShowPasswordPopup(false);
                                                        formik.handleBlur(e);
                                                    }}
                                                    onFocus={(_e) => { setShowPasswordPopup(true); }}
                                                />
                                                <span className={"form-group-icon bg-none"}>
                                                    <span
                                                        onClick={() =>
                                                            setIsPasswordVisible(!isPasswordVisible)
                                                        }
                                                        onKeyDown={() => {}}
                                                        className={`form-group-icon-in pointer ${!isPasswordVisible ? "icon-eye" : "icon-eye-off"
                                                            } `}
                                                    ></span>
                                                </span>
                                                {showPasswordPopup && <div className="password-valid-info">
                                                    <div className="password-valid-info-in">
                                                        <PasswordChecklist
                                                            rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                                            minLength={PASSWORD_MIN_LENGHT}
                                                            value={password}
                                                            iconComponents={{ ValidIcon: <span className="icon-checked-fill blue-icon"></span>, InvalidIcon: <span className="icon-checked-fill"></span> }}
                                                            onChange={(isValid) => { isValid === true ? setIsPasswordValid(true) : setIsPasswordValid(false) }}
                                                            messages={{
                                                                minLength: localisedValues["min_12_char"],
                                                                specialChar: localisedValues["at_least_one_spec_char"],
                                                                number: localisedValues["at_least_one_num_char"],
                                                                capital: localisedValues["at_least_one_upper_char"],
                                                                lowercase: localisedValues["at_least_one_lower_char"],
                                                            }}
                                                        />
                                                    </div>
                                                </div>}
                                            </div>
                                            {formik.touched.password && formik.errors.password && <div className="validation-error">{formik.errors.password}</div>}
                                        </div>

                                        <div className="login-bottom-text padding-top-0">
                                            <div className="have-an-account text-center">
                                                {privacyPolicyFinalLink && parse(privacyPolicyFinalLink)}
                                            </div>
                                        </div>

                                        <div className="button-inline text-center padding-top-15 d-flex justify-content-between">
                                            <div className="button">
                                                <SubmitButton type="button" className="btn btn-outline-primary font-weight-500" data-testid="backButton" id="Back" name={localisedValues["back"]} onClick={props.goBackVerifyAccount}></SubmitButton>
                                            </div>
                                            <div className="button">
                                                <SubmitButton className="btn btn-primary font-weight-500" data-testid="continueButton" id="Continue" name={localisedValues["continue"]} isSubmitting={isSubmitting} disable={isSubmitting || !formik.isValid || !isPasswordValid} showLoader={true}></SubmitButton>
                                            </div>
                                        </div>
                                    </Form>
                            )}
                        </Formik>
                    </Card>
                </div>
            </div>
    );
}
const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

RegisterUser.propTypes = {
    userEmail: PropTypes.string,
    userDetails: PropTypes.object,
    language: PropTypes.object,
    registerProps: PropTypes.bool,
    cleanSignupData: PropTypes.func,
    handleRegisterUser: PropTypes.func,
    goBackVerifyAccount: PropTypes.func
    
}

export default connect(mapStateToProps, null)(RegisterUser);