import { APIs } from "../constants/Constants";
import api from "./api";

export default class SignUpAPI {
    postCheckingSignUpEmail(formData) {
        return api.post(APIs.CHECKING_SIGNUP_EMAIL, formData);
    }

    postRegisterUser(formData) {
        return api.postForm(APIs.REGISTER_USER, formData)
    }
}