import api from "../apis/api";


class SessionDetailService {
    getSessionDetail(req) {
        return api.sessiondetail.getSessionDetail(req);
    }

    putEndSession(sessionId) {
        return api.sessiondetail.putEndSession(sessionId);
    }

    putArchiveSession(sessionId) {
        return api.sessiondetail.putArchiveSession(sessionId);
    }

    getSessionNotes(req) {
        return api.sessiondetail.getSessionNotes(req);
    }

    getSessionInvitations(req) {
        return api.sessiondetail.getSessionInvitations(req);
    }

    putAssigneeChange(req){
        return api.sessiondetail.putAssigneeChange(req);
    }

    putSessionReferenceChange(req){
        return api.sessiondetail.putSessionReferenceChange(req);
    }

    getAssigneeList(req) {
        return api.sessiondetail.getAssigneeList(req);
    }

    getSessionImages(req) {
        return api.sessiondetail.getSessionImages(req);
    }

    getSessionRecordings(req) {
        return api.sessiondetail.getSessionRecordings(req);
    }

    getSessionWordDoc(req) {
        return api.sessiondetail.getSessionWordDoc(req);
    }

    getSessionFiles(req) {
        return api.sessiondetail.getSessionFiles(req);
    }

    deleteSessionChatFiles(req) {
        return api.sessiondetail.deleteSessionChatFiles(req);
    }

    deleteSessionOcrFiles(req) {
        return api.sessiondetail.deleteSessionOcrFiles(req);
    }
    
    deleteSessionImages(req) {
        return api.sessiondetail.deleteSessionImages(req);
    }

    deleteSessionRecordings(req) {
        return api.sessiondetail.deleteSessionRecordings(req);
    }
}

export default new SessionDetailService();