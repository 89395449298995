import clsx from "clsx";
import __ from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { DEFAULT_FILTER_VALUE, DEFAULT_SELECTED_SESSION_TYPE, LOCAL_STORAGE_KEYS, RouteName, SESSION_TYPES } from "../../../../constants/Constants";
import { Localize } from "../../Localize";

import storage from "../../../../services/storage";

const StatusFilter = (props) => {
    const {
        statusChangeHandler,
        language,
        isDiscardChanged
    } = props;

    const params = useParams();
    const history = useHistory();

    const [localisedValues, setLocalisedValues] = useState({});

    const [selectedFilter, setSelectedFilter] = useState(storage.has(LOCAL_STORAGE_KEYS.SESSION_FILTER) ? storage.get(LOCAL_STORAGE_KEYS.SESSION_FILTER) : DEFAULT_FILTER_VALUE);// 1=All session, 2=Active sessions, 3=Upcoming sessions, 4=Closed sessons, 5=Archived sessions,
    const [filterTooltip, setFilterTooltip] = useState('');
    const [isFilterSelected, setIsFilterSelected] = useState("");

    useEffect(() => {
        const curLang = language.selectedlanguage;
        const localisedObj = Localize[curLang.id];
        setLocalisedValues(localisedObj);
        setFilterTooltip(localisedObj[SESSION_TYPES.find((sessionType) => sessionType.value === selectedFilter).langkey]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language.selectedlanguage]);

    const sessionFilterChangeHandler = (value) => {
        setIsFilterSelected("selected")

        setTimeout(()=> {
            setIsFilterSelected("")
        },500)

        if (selectedFilter !== value) {
            if (!__.isEmpty(params) && !isDiscardChanged) {
                history.push(RouteName.SESSIONS);
            }
            setSelectedFilter(value);
            setFilterTooltip(localisedValues[SESSION_TYPES.find((sessionType) => sessionType.value === value).langkey]);
            statusChangeHandler(value);
        }
    }

    return (
        <>
            {!__.isEmpty(localisedValues) &&
                <div className="filter-box all-filter-box custom-tooltip right-tooltip tooltip-auto-width m-hide">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle triangle-none" type="button"
                            id="session-status-filter" data-testid="session-status-filter" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="filter-btn-icon icon-filter-result"></span>
                            <span className="filter-btn-text">{filterTooltip}</span>
                            <span className="dropdown-toggle-arrow icon-arrow-down"></span>
                        </button>
                        <div className="dropdown-menu" data-testid="session-status-filter-dropdown" aria-labelledby="session-status-filter">
                            <div className="dropdown-menu-in side-padding">
                                <ul>
                                    {SESSION_TYPES.map((sessionType) => (
                                        <li className={clsx("filter-mark", sessionType.class, sessionType.value === selectedFilter && "active")} key={sessionType.name}>
                                            {/* eslint-disable-next-line */}
                                            <a className="dropdown-item" href={void(0)} onClick={() => sessionFilterChangeHandler(sessionType.value)}>
                                                {localisedValues[sessionType.langkey]}
                                                {sessionType.value === selectedFilter && sessionType.name === DEFAULT_SELECTED_SESSION_TYPE &&
                                                    <span className="check-icon icon-check"></span>
                                                }
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {isFilterSelected !== "selected" && <div className="custom-tooltip-content text-capitalize">
                            <div className="custom-tooltip-in">
                                <p>{filterTooltip}</p>
                            </div>
                        </div>}
                    </div>
                </div>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
    }
}

StatusFilter.propTypes = {
    statusChangeHandler: PropTypes.func,
    language: PropTypes.object,
    isDiscardChanged: PropTypes.bool,
}

export default connect(mapStateToProps, null)(StatusFilter);