import { EVENTS, GET_ACTIVE_USERS } from "../reducers/activeUsers-reducer";
import ActiveUsersService from "../services/activeUsersService";

export const resetActiveUsersReducerObj = () => {
  return {
      event: EVENTS.RESET_ACTIVE_USERS_REDUCER,
      type: null,
  }
}

const getActiveUsersStart = () => {
  return {
    event: EVENTS.GET_ACTIVE_USERS,
    type: GET_ACTIVE_USERS.START,
  };
};

const getActiveUsersSuccess = (data) => {
  return {
    event: EVENTS.GET_ACTIVE_USERS,
    type: GET_ACTIVE_USERS.SUCCESS,
    payload: data,
  };
};

const getActiveUsersError = (error) => {
  return {
    event: EVENTS.GET_ACTIVE_USERS,
    type: GET_ACTIVE_USERS.ERROR,
    payload: error,
  };
};

export const getAllActiveUsers = () => async (dispatch) => {
  dispatch(getActiveUsersStart());
  ActiveUsersService.getActiveUsers()
    .then((response) => {
      dispatch(getActiveUsersSuccess(response));
    })
    .catch((error) => {
      dispatch(getActiveUsersError(error));
    });
};
