export const Localize = {
    "en_GB": {
        "sign_in": "Sign In",
        "email_id": "Email ID",
        "email_placeholder": "Email Id/User Id",
        "email_ID_User_ID_is_required": "Email ID/User ID is required.",
        "email_ID_is_required": "Email ID is required.",
        "pwd_placeholder": "Password",
        "password_required": "Password required.",
        "dont_have_account": "Don't have an account yet?",
        "sign_up": "Sign up",
        "forgot_password": "Forgot password?",
        "or_key": "OR",
        "login_with_sso": "Login with SSO",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "reset_password": "Reset your password",
        "enter_email_id": "Enter Email Id",
        "enter_valid_email": "Enter valid email address.",
        "limit_exceeded": "Limit exceeded",
        "no_email": "No email?",
        "reset_password_message": "If you don’t use an email address to login, please contact your Company Admin or Supervisor with your login ID (Eg. Agent ID) to reset your password.",
        "close": "Close",
        "send": "Send",
        "done": "Done",
        "verify_domain": "Verify domain",
        "enter_domain_name": "Enter Domain Name",
        "domain_val_msg": "Enter valid domain name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "verify": "Verify",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "login_with_sso_not_allow": "Login with SSO is not allowed for this domain, please contact your administrator.",
        "check_link_reset_pwd": "Check your inbox for a link to reset your password.",
        "invalid_account_msg": "We could not find an account with that email address. Please check your email and try again.",
        "error_limit_exceeded": "Limit exceeded!",
        "domain_contact_admin_msg": "That domain does not exist, try again or contact your admin.",
        "login_with_your_credentials": "Login with your credentials"
    },
    "de_DE": {
        "sign_in": "Einloggen",
        "email_id": "E-Mail-ID",
        "email_placeholder": "E-Mail-ID / Benutzer-ID",
        "email_ID_User_ID_is_required": "E-Mail-ID / Benutzer-ID ist erforderlich.",
        "email_ID_is_required": "E-Mail-ID ist erforderlich.",
        "pwd_placeholder": "Passwort",
        "password_required": "Passwort erforderlich.",
        "dont_have_account": "Sie haben noch keinen Account?",
        "sign_up": "Anmelden",
        "forgot_password": "Passwort vergessen?",
        "or_key": "ODER",
        "login_with_sso": "Einloggen mit SSO",
        "check_ccode_text": "Überprüfen Sie Ihre E-Mails auf einen Code",
        "resend_code": "Code erneut senden",
        "reset_password": "Setze dein Passwort zurück",
        "enter_email_id": "Geben Sie E-Mail-Id",
        "enter_valid_email": "Geben Sie eine gültige E-Mail-Adresse ein.",
        "limit_exceeded": "Limit überschritten",
        "no_email": "Keine Email?",
        "reset_password_message": "Wenn Sie keine E-Mail-Adresse verwenden, um sich anzumelden, wenden Sie sich Ihr Unternehmen Admin oder Betreuer mit Ihren Login-ID (z. B. Agent-ID) Ihr Passwort zurücksetzen können.",
        "close": "Schließen",
        "send": "Senden",
        "done": "Erledigt",
        "verify_domain": "Überprüfen Sie die Domain",
        "enter_domain_name": "Geben Sie den Domänennamen ein",
        "domain_val_msg": "Geben Sie eine gültige Domain-Namen.",
        "crt_acnt_limit_character_msg": "Bis zu 30 Zeichen erlaubt.",
        "verify": "Überprüfen",
        "ccod_genral_text": "Wir haben einen 6-stelligen Bestätigungscode an <strong> <trial_email> . </strong> Der Code läuft in Kürze ab. Bitte geben Sie ihn bald ein.",
        "your_ccode_text": "Ihr <strong> Bestätigungscode </strong>",
        "ccode_note1_text": "Lassen Sie dieses Fenster geöffnet, während Sie nach Ihrem Code suchen. <br> haben unsere E-Mail noch nicht erhalten? Probieren Sie Ihren Spam-Ordner oder",
        "validating_code": "Code validieren ...",
        "ccod_resend_text": "Bestätigungscode wird gesendet, bitte überprüfen Sie Ihre E-Mail",
        "login_with_sso_not_allow": "Die Anmeldung mit SSO ist für diese Domain nicht zulässig. Bitte wenden Sie sich an Ihren Administrator.",
        "check_link_reset_pwd": "Überprüfen Sie Ihren Posteingang auf einen Link zum Zurücksetzen des Passworts.",
        "invalid_account_msg": "Wir konnten nicht ein Konto mit dieser E-Mail-Adresse finden. Bitte überprüfen Sie Ihre E-Mail und versuchen Sie es erneut.",
        "error_limit_exceeded": "Limit überschritten!",
        "domain_contact_admin_msg": "Das Domain nicht existiert, versuchen Sie es erneut oder kontaktieren Sie Ihren Administrator.",
        "login_with_your_credentials": "Melden Sie sich mit Ihren Zugangsdaten an"
    },
    "en_US": {
        "sign_in": "Sign In",
        "email_id": "Email ID",
        "email_placeholder": "Email Id/User Id",
        "email_ID_User_ID_is_required": "Email ID/User ID is required.",
        "email_ID_is_required": "Email ID is required.",
        "pwd_placeholder": "Password",
        "password_required": "Password required.",
        "dont_have_account": "Don't have an account yet?",
        "sign_up": "Sign up",
        "forgot_password": "Forgot password?",
        "or_key": "OR",
        "login_with_sso": "Login with SSO",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "reset_password": "Reset your password",
        "enter_email_id": "Enter Email Id",
        "enter_valid_email": "Enter valid email address.",
        "limit_exceeded": "Limit exceeded",
        "no_email": "No email?",
        "reset_password_message": "If you don’t use an email address to login, please contact your Company Admin or Supervisor with your login ID (Eg. Agent ID) to reset your password.",
        "close": "Close",
        "send": "Send",
        "done": "Done",
        "verify_domain": "Verify domain",
        "enter_domain_name": "Enter Domain Name",
        "domain_val_msg": "Enter valid domain name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "verify": "Verify",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "login_with_sso_not_allow": "Login with SSO is not allowed for this domain, please contact your administrator.",
        "check_link_reset_pwd": "Check your inbox for a link to reset your password.",
        "invalid_account_msg": "We could not find an account with that email address. Please check your email and try again.",
        "error_limit_exceeded": "Limit exceeded!",
        "domain_contact_admin_msg": "That domain does not exist, try again or contact your admin.",
        "login_with_your_credentials": "Login with your credentials"
    },
    "en_AU": {
        "sign_in": "Sign In",
        "email_id": "Email ID",
        "email_placeholder": "Email Id/User Id",
        "email_ID_User_ID_is_required": "Email ID/User ID is required.",
        "email_ID_is_required": "Email ID is required.",
        "pwd_placeholder": "Password",
        "password_required": "Password required.",
        "dont_have_account": "Don't have an account yet?",
        "sign_up": "Sign up",
        "forgot_password": "Forgot password?",
        "or_key": "OR",
        "login_with_sso": "Login with SSO",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "reset_password": "Reset your password",
        "enter_email_id": "Enter Email Id",
        "enter_valid_email": "Enter valid email address.",
        "limit_exceeded": "Limit exceeded",
        "no_email": "No email?",
        "reset_password_message": "If you don’t use an email address to login, please contact your Company Admin or Supervisor with your login ID (Eg. Agent ID) to reset your password.",
        "close": "Close",
        "send": "Send",
        "done": "Done",
        "verify_domain": "Verify domain",
        "enter_domain_name": "Enter Domain Name",
        "domain_val_msg": "Enter valid domain name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "verify": "Verify",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "login_with_sso_not_allow": "Login with SSO is not allowed for this domain, please contact your administrator.",
        "check_link_reset_pwd": "Check your inbox for a link to reset your password.",
        "invalid_account_msg": "We could not find an account with that email address. Please check your email and try again.",
        "error_limit_exceeded": "Limit exceeded!",
        "domain_contact_admin_msg": "That domain does not exist, try again or contact your admin.",
        "login_with_your_credentials": "Login with your credentials"
    },
    "es_ES": {
        "sign_in": "Registrarse",
        "email_id": "Identificación de correo",
        "email_placeholder": "ID de correo electrónico / ID de usuario",
        "email_ID_User_ID_is_required": "Se requiere ID de correo electrónico / ID de usuario.",
        "email_ID_is_required": "Se requiere identificación por correo electrónico.",
        "pwd_placeholder": "Contraseña",
        "password_required": "Se requiere contraseña.",
        "dont_have_account": "¿Aún no tienes una cuenta?",
        "sign_up": "Regístrate",
        "forgot_password": "¿Se te olvidó tu contraseña?",
        "or_key": "O",
        "login_with_sso": "Inicie sesión con SSO",
        "check_ccode_text": "Verifique su correo electrónico para obtener un código",
        "resend_code": "Reenviar codigo",
        "reset_password": "Restablecer su contraseña",
        "enter_email_id": "Introduzca Identificación del email",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "limit_exceeded": "Límite excedido",
        "no_email": "¿Sin correo electrónico?",
        "reset_password_message": "Si no utiliza una dirección de correo electrónico para iniciar sesión, póngase en contacto con su administrador o supervisor de la empresa con su nombre de usuario (Ej. Agente ID) para restablecer su contraseña.",
        "close": "Cerrar",
        "send": "Enviar",
        "done": "Hecho",
        "verify_domain": "Verificar dominio",
        "enter_domain_name": "Introduzca nombres de dominio",
        "domain_val_msg": "Introduce el nombre de dominio válido.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "verify": "Verificar",
        "ccod_genral_text": "Enviamos un código de confirmación de 6 dígitos a <strong> <trial_email> . </strong> El código caduca en breve, ingréselo pronto.",
        "your_ccode_text": "Tu <strong> código de confirmación </strong>",
        "ccode_note1_text": "Mantenga esta ventana abierta mientras busca su código. <br> ¿No ha recibido nuestro correo electrónico? Prueba tu carpeta de spam o",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "Se envió el código de confirmación, verifique su correo electrónico",
        "login_with_sso_not_allow": "No se permite iniciar sesión con SSO para este dominio, póngase en contacto con su administrador.",
        "check_link_reset_pwd": "Comprueba si has recibido un enlace para restablecer su contraseña.",
        "invalid_account_msg": "No pudimos encontrar una cuenta con esa dirección de correo electrónico. Por favor, consultar su correo electrónico y vuelve a intentarlo.",
        "error_limit_exceeded": "¡Límite excedido!",
        "domain_contact_admin_msg": "Ese dominio no existe, inténtelo de nuevo o póngase en contacto con el administrador.",
        "login_with_your_credentials": "Inicia sesión con tus credenciales"
    },
    "es_MX": {
        "sign_in": "Registrarse",
        "email_id": "Identificación de correo",
        "email_placeholder": "ID de correo electrónico / ID de usuario",
        "email_ID_User_ID_is_required": "Se requiere ID de correo electrónico / ID de usuario.",
        "email_ID_is_required": "Se requiere identificación por correo electrónico.",
        "pwd_placeholder": "Contraseña",
        "password_required": "Se requiere contraseña.",
        "dont_have_account": "¿Aún no tienes una cuenta?",
        "sign_up": "Regístrate",
        "forgot_password": "¿Se te olvidó tu contraseña?",
        "or_key": "O",
        "login_with_sso": "Inicie sesión con SSO",
        "check_ccode_text": "Verifique su correo electrónico para obtener un código",
        "resend_code": "Reenviar codigo",
        "reset_password": "Restablecer su contraseña",
        "enter_email_id": "Introduzca Identificación del email",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "limit_exceeded": "Límite excedido",
        "no_email": "¿Sin correo electrónico?",
        "reset_password_message": "Si no utiliza una dirección de correo electrónico para iniciar sesión, póngase en contacto con su administrador o supervisor de la empresa con su nombre de usuario (Ej. Agente ID) para restablecer su contraseña.",
        "close": "Cerrar",
        "send": "Enviar",
        "done": "Hecho",
        "verify_domain": "Verificar dominio",
        "enter_domain_name": "Introduzca nombres de dominio",
        "domain_val_msg": "Introduce el nombre de dominio válido.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "verify": "Verificar",
        "ccod_genral_text": "Enviamos un código de confirmación de 6 dígitos a <strong> <trial_email> . </strong> El código caduca en breve, ingréselo pronto.",
        "your_ccode_text": "Tu <strong> código de confirmación </strong>",
        "ccode_note1_text": "Mantenga esta ventana abierta mientras busca su código. <br> ¿No ha recibido nuestro correo electrónico? Prueba tu carpeta de spam o",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "Se envió el código de confirmación, verifique su correo electrónico",
        "login_with_sso_not_allow": "No se permite iniciar sesión con SSO para este dominio, póngase en contacto con su administrador.",
        "check_link_reset_pwd": "Comprueba si has recibido un enlace para restablecer su contraseña.",
        "invalid_account_msg": "No pudimos encontrar una cuenta con esa dirección de correo electrónico. Por favor, consultar su correo electrónico y vuelve a intentarlo.",
        "error_limit_exceeded": "¡Límite excedido!",
        "domain_contact_admin_msg": "Ese dominio no existe, inténtelo de nuevo o póngase en contacto con el administrador.",
        "login_with_your_credentials": "Inicia sesión con tus credenciales"
    },
    "es_AR": {
        "sign_in": "Registrarse",
        "email_id": "Identificación de correo",
        "email_placeholder": "ID de correo electrónico / ID de usuario",
        "email_ID_User_ID_is_required": "Se requiere ID de correo electrónico / ID de usuario.",
        "email_ID_is_required": "Se requiere identificación por correo electrónico.",
        "pwd_placeholder": "Contraseña",
        "password_required": "Se requiere contraseña.",
        "dont_have_account": "¿Aún no tienes una cuenta?",
        "sign_up": "Regístrate",
        "forgot_password": "¿Se te olvidó tu contraseña?",
        "or_key": "O",
        "login_with_sso": "Inicie sesión con SSO",
        "check_ccode_text": "Verifique su correo electrónico para obtener un código",
        "resend_code": "Reenviar codigo",
        "reset_password": "Restablecer su contraseña",
        "enter_email_id": "Introduzca Identificación del email",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "limit_exceeded": "Límite excedido",
        "no_email": "¿Sin correo electrónico?",
        "reset_password_message": "Si no utiliza una dirección de correo electrónico para iniciar sesión, póngase en contacto con su administrador o supervisor de la empresa con su nombre de usuario (Ej. Agente ID) para restablecer su contraseña.",
        "close": "Cerrar",
        "send": "Enviar",
        "done": "Hecho",
        "verify_domain": "Verificar dominio",
        "enter_domain_name": "Introduzca nombres de dominio",
        "domain_val_msg": "Introduce el nombre de dominio válido.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "verify": "Verificar",
        "ccod_genral_text": "Enviamos un código de confirmación de 6 dígitos a <strong> <trial_email> . </strong> El código caduca en breve, ingréselo pronto.",
        "your_ccode_text": "Tu <strong> código de confirmación </strong>",
        "ccode_note1_text": "Mantenga esta ventana abierta mientras busca su código. <br> ¿No ha recibido nuestro correo electrónico? Prueba tu carpeta de spam o",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "Se envió el código de confirmación, verifique su correo electrónico",
        "login_with_sso_not_allow": "No se permite iniciar sesión con SSO para este dominio, póngase en contacto con su administrador.",
        "check_link_reset_pwd": "Comprueba si has recibido un enlace para restablecer su contraseña.",
        "invalid_account_msg": "No pudimos encontrar una cuenta con esa dirección de correo electrónico. Por favor, consultar su correo electrónico y vuelve a intentarlo.",
        "error_limit_exceeded": "¡Límite excedido!",
        "domain_contact_admin_msg": "Ese dominio no existe, inténtelo de nuevo o póngase en contacto con el administrador.",
        "login_with_your_credentials": "Inicia sesión con tus credenciales"
    },
    "fr_FR": {
        "sign_in": "Se connecter",
        "email_id": "Identifiant e-mail",
        "email_placeholder": "ID e-mail / ID utilisateur",
        "email_ID_User_ID_is_required": "L'ID de messagerie / ID utilisateur est requis.",
        "email_ID_is_required": "L'identifiant e-mail est requis.",
        "pwd_placeholder": "Mot de passe",
        "password_required": "Mot de passe requis.",
        "dont_have_account": "Vous n'avez pas encore de compte?",
        "sign_up": "S'inscrire",
        "forgot_password": "mot de passe oublié?",
        "or_key": "OU",
        "login_with_sso": "Se connecter avec SSO",
        "check_ccode_text": "Vérifiez votre email pour un code",
        "resend_code": "Renvoyer le code",
        "reset_password": "réinitialisez votre mot de passe",
        "enter_email_id": "Entrer Email Id",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "limit_exceeded": "Limite dépassée",
        "no_email": "Aucun courriel?",
        "reset_password_message": "Si vous ne l'utilisez pas une adresse e-mail pour vous connecter, s'il vous plaît contacter votre compagnie Admin ou superviseur avec votre ID de connexion (par ex. ID de l'agent) pour réinitialiser votre mot de passe.",
        "close": "Fermer",
        "send": "Envoyer",
        "done": "Terminé",
        "verify_domain": "Valider le domaine",
        "enter_domain_name": "Entrez le nom de domaine",
        "domain_val_msg": "Entrez le nom de domaine valide.",
        "crt_acnt_limit_character_msg": "Jusqu'à 30 caractères autorisés.",
        "verify": "Vérifier",
        "ccod_genral_text": "Nous avons envoyé un code de confirmation à 6 chiffres à <strong> <trial_email> . </strong> Le code expire sous peu, veuillez le saisir bientôt.",
        "your_ccode_text": "Votre <strong> code de confirmation </strong>",
        "ccode_note1_text": "Gardez cette fenêtre ouverte tout en vérifiant votre code. <br> Vous n'avez pas reçu notre e-mail? Essayez votre dossier spam ou",
        "validating_code": "Validation du code ...",
        "ccod_resend_text": "Le code de confirmation est envoyé, veuillez vérifier votre email",
        "login_with_sso_not_allow": "La connexion avec SSO n'est pas autorisée pour ce domaine, veuillez contacter votre administrateur.",
        "check_link_reset_pwd": "Vérifiez votre boîte de réception pour un lien pour réinitialiser votre mot de passe.",
        "invalid_account_msg": "Nous ne pouvions pas trouver un compte avec cette adresse e-mail. S'il vous plaît vérifier votre e-mail et essayez à nouveau.",
        "error_limit_exceeded": "Limite dépassée!",
        "domain_contact_admin_msg": "Ce domaine n'existe pas, essayez à nouveau ou contactez votre administrateur.",
        "login_with_your_credentials": "Connectez-vous avec vos identifiants"
    },
    "en_CA": {
        "sign_in": "Sign In",
        "email_id": "Email ID",
        "email_placeholder": "Email Id/User Id",
        "email_ID_User_ID_is_required": "Email ID/User ID is required.",
        "email_ID_is_required": "Email ID is required.",
        "pwd_placeholder": "Password",
        "password_required": "Password required.",
        "dont_have_account": "Don't have an account yet?",
        "sign_up": "Sign up",
        "forgot_password": "Forgot password?",
        "or_key": "OR",
        "login_with_sso": "Login with SSO",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "reset_password": "Reset your password",
        "enter_email_id": "Enter Email Id",
        "enter_valid_email": "Enter valid email address.",
        "limit_exceeded": "Limit exceeded",
        "no_email": "No email?",
        "reset_password_message": "If you don’t use an email address to login, please contact your Company Admin or Supervisor with your login ID (Eg. Agent ID) to reset your password.",
        "close": "Close",
        "send": "Send",
        "done": "Done",
        "verify_domain": "Verify domain",
        "enter_domain_name": "Enter Domain Name",
        "domain_val_msg": "Enter valid domain name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "verify": "Verify",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "login_with_sso_not_allow": "Login with SSO is not allowed for this domain, please contact your administrator.",
        "check_link_reset_pwd": "Check your inbox for a link to reset your password.",
        "invalid_account_msg": "We could not find an account with that email address. Please check your email and try again.",
        "error_limit_exceeded": "Limit exceeded!",
        "domain_contact_admin_msg": "That domain does not exist, try again or contact your admin.",
        "login_with_your_credentials": "Login with your credentials"
    },
    "fr_CA": {
        "sign_in": "Se connecter",
        "email_id": "Identifiant e-mail",
        "email_placeholder": "ID e-mail / ID utilisateur",
        "email_ID_User_ID_is_required": "L'ID de messagerie / ID utilisateur est requis.",
        "email_ID_is_required": "L'identifiant e-mail est requis.",
        "pwd_placeholder": "Mot de passe",
        "password_required": "Mot de passe requis.",
        "dont_have_account": "Vous n'avez pas encore de compte?",
        "sign_up": "S'inscrire",
        "forgot_password": "mot de passe oublié?",
        "or_key": "OU",
        "login_with_sso": "Se connecter avec SSO",
        "check_ccode_text": "Vérifiez votre email pour un code",
        "resend_code": "Renvoyer le code",
        "reset_password": "réinitialisez votre mot de passe",
        "enter_email_id": "Entrer Email Id",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "limit_exceeded": "Limite dépassée",
        "no_email": "Aucun courriel?",
        "reset_password_message": "Si vous ne l'utilisez pas une adresse e-mail pour vous connecter, s'il vous plaît contacter votre compagnie Admin ou superviseur avec votre ID de connexion (par ex. ID de l'agent) pour réinitialiser votre mot de passe.",
        "close": "Fermer",
        "send": "Envoyer",
        "done": "Terminé",
        "verify_domain": "Valider le domaine",
        "enter_domain_name": "Entrez le nom de domaine",
        "domain_val_msg": "Entrez le nom de domaine valide.",
        "crt_acnt_limit_character_msg": "Jusqu'à 30 caractères autorisés.",
        "verify": "Vérifier",
        "ccod_genral_text": "Nous avons envoyé un code de confirmation à 6 chiffres à <strong> <trial_email> . </strong> Le code expire sous peu, veuillez le saisir bientôt.",
        "your_ccode_text": "Votre <strong> code de confirmation </strong>",
        "ccode_note1_text": "Gardez cette fenêtre ouverte tout en vérifiant votre code. <br> Vous n'avez pas reçu notre e-mail? Essayez votre dossier spam ou",
        "validating_code": "Validation du code ...",
        "ccod_resend_text": "Le code de confirmation est envoyé, veuillez vérifier votre email",
        "login_with_sso_not_allow": "La connexion avec SSO n'est pas autorisée pour ce domaine, veuillez contacter votre administrateur.",
        "check_link_reset_pwd": "Vérifiez votre boîte de réception pour un lien pour réinitialiser votre mot de passe.",
        "invalid_account_msg": "Nous ne pouvions pas trouver un compte avec cette adresse e-mail. S'il vous plaît vérifier votre e-mail et essayez à nouveau.",
        "error_limit_exceeded": "Limite dépassée!",
        "domain_contact_admin_msg": "Ce domaine n'existe pas, essayez à nouveau ou contactez votre administrateur.",
        "login_with_your_credentials": "Connectez-vous avec vos identifiants"
    },
    "pt_BR": {
        "sign_in": "Entrar",
        "email_id": "Identificação do email",
        "email_placeholder": "ID do email / ID do usuário",
        "email_ID_User_ID_is_required": "É necessário o ID do email / ID do usuário.",
        "email_ID_is_required": "O ID do email é obrigatório.",
        "pwd_placeholder": "Senha",
        "password_required": "Senha requerida.",
        "dont_have_account": "Não tem uma conta ainda?",
        "sign_up": "Cadastrar",
        "forgot_password": "Esqueceu a senha?",
        "or_key": "OU",
        "login_with_sso": "Entrar com o SSO",
        "check_ccode_text": "Verifique seu e-mail para obter um código",
        "resend_code": "Reenviar código",
        "reset_password": "Redefinir sua senha",
        "enter_email_id": "Digite E-mail Id",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "limit_exceeded": "Limite excedido",
        "no_email": "No e-mail?",
        "reset_password_message": "Se você não usar um endereço de e-mail de login, entre em contato com o administrador da empresa ou Supervisor com o seu ID de login (Ex. Agent ID) para redefinir sua senha.",
        "close": "Perto",
        "send": "Mandar",
        "done": "Feito",
        "verify_domain": "Verificar domínio",
        "enter_domain_name": "Digitar o Domínio",
        "domain_val_msg": "Digitar o nome de domínio válido.",
        "crt_acnt_limit_character_msg": "São permitidos até 30 caracteres.",
        "verify": "Entrar",
        "ccod_genral_text": "Enviamos um código de confirmação de 6 dígitos para <strong> <trial_email> . </strong> O código expira em breve, insira-o em breve.",
        "your_ccode_text": "Seu <strong> código de confirmação </strong>",
        "ccode_note1_text": "Mantenha esta janela aberta enquanto verifica seu código. <br> Não recebeu nosso email? Tente sua pasta de spam ou",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "O código de confirmação foi enviado, verifique seu e-mail",
        "login_with_sso_not_allow": "O login com SSO não é permitido para este domínio, entre em contato com seu administrador.",
        "check_link_reset_pwd": "Verifique sua caixa de entrada para um link para redefinir sua senha.",
        "invalid_account_msg": "Não foi possível encontrar uma conta com esse endereço de e-mail. Por favor, verifique seu e-mail e tente novamente.",
        "error_limit_exceeded": "Limite excedido!",
        "domain_contact_admin_msg": "Esse domínio não existe, tente novamente ou contate o administrador.",
        "login_with_your_credentials": "Faça login com suas credenciais"
    },
    "pt_PT": {
        "sign_in": "Assinar em",
        "email_id": "Identificação do email",
        "email_placeholder": "ID do email / ID do usuário",
        "email_ID_User_ID_is_required": "É necessário o ID do email / ID do usuário.",
        "email_ID_is_required": "O ID do email é obrigatório.",
        "pwd_placeholder": "Senha",
        "password_required": "Senha requerida.",
        "dont_have_account": "Não tem uma conta ainda?",
        "sign_up": "inscrever-se",
        "forgot_password": "Esqueceu a senha?",
        "or_key": "OU",
        "login_with_sso": "Entrar com o SSO",
        "check_ccode_text": "Verifique seu e-mail para obter um código",
        "resend_code": "Reenviar código",
        "reset_password": "Redefinir sua senha",
        "enter_email_id": "Digite E-mail Id",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "limit_exceeded": "Limite excedido",
        "no_email": "No e-mail?",
        "reset_password_message": "Se você não usar um endereço de e-mail de login, entre em contato com o administrador da empresa ou Supervisor com o seu ID de login (Ex. Agent ID) para redefinir sua senha.",
        "close": "Perto",
        "send": "Mandar",
        "done": "Feito",
        "verify_domain": "Verificar domínio",
        "enter_domain_name": "Digite o nome de domínio",
        "domain_val_msg": "Insira o nome de domínio válido.",
        "crt_acnt_limit_character_msg": "São permitidos até 30 caracteres.",
        "verify": "Verificar",
        "ccod_genral_text": "Enviamos um código de confirmação de 6 dígitos para <strong> <trial_email> . </strong> O código expira em breve, insira-o em breve.",
        "your_ccode_text": "Seu <strong> código de confirmação </strong>",
        "ccode_note1_text": "Mantenha esta janela aberta enquanto verifica seu código. <br> Não recebeu nosso email? Tente sua pasta de spam ou",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "O código de confirmação foi enviado, verifique seu e-mail",
        "login_with_sso_not_allow": "O login com SSO não é permitido para este domínio, entre em contato com seu administrador.",
        "check_link_reset_pwd": "Verifique sua caixa de entrada para um link para redefinir sua senha.",
        "invalid_account_msg": "Não foi possível encontrar uma conta com esse endereço de e-mail. Por favor, verifique seu e-mail e tente novamente.",
        "error_limit_exceeded": "Limite excedido!",
        "domain_contact_admin_msg": "Esse domínio não existe, tente novamente ou contate o administrador.",
        "login_with_your_credentials": "Faça login com suas credenciais"
    },
    "it_IT": {
        "sign_in": "Registrati",
        "email_id": "E-mail identificativo utente",
        "email_placeholder": "ID e-mail/ID utente",
        "email_ID_User_ID_is_required": "L'ID e-mail/ID utente è obbligatorio.",
        "email_ID_is_required": "L'ID e-mail è obbligatorio.",
        "pwd_placeholder": "Parola d'ordine",
        "password_required": "Password richiesta.",
        "dont_have_account": "Non hai ancora un conto?",
        "sign_up": "Iscriviti",
        "forgot_password": "Ha dimenticato la password?",
        "or_key": "O",
        "login_with_sso": "Accedi con SSO",
        "check_ccode_text": "Controlla la tua email per un codice",
        "resend_code": "Codice di rispedizione",
        "reset_password": "reimposta la tua password",
        "enter_email_id": "Inserisci l'ID e-mail",
        "enter_valid_email": "Inserisci un indirizzo email valido.",
        "limit_exceeded": "Limite superato",
        "no_email": "Nessuna e-mail?",
        "reset_password_message": "Se non utilizzi un indirizzo e-mail per accedere, contatta l'amministratore o il supervisore della tua azienda con il tuo ID di accesso (ad es. ID agente) per reimpostare la password.",
        "close": "Vicino",
        "send": "Spedire",
        "done": "Fatto",
        "verify_domain": "Verifica dominio",
        "enter_domain_name": "Inserisci il nome del dominio",
        "domain_val_msg": "Inserisci un nome di dominio valido.",
        "crt_acnt_limit_character_msg": "Sono consentiti fino a 30 caratteri.",
        "verify": "Verificare",
        "ccod_genral_text": "Abbiamo inviato un codice di conferma di 6 cifre a <strong> <trial_email> . </strong> Il codice scade a breve, inseriscilo presto.",
        "your_ccode_text": "Il tuo <strong> codice di conferma </strong>",
        "ccode_note1_text": "Tieni aperta questa finestra mentre controlli il tuo codice. <br> Non hai ricevuto la nostra email? Prova la tua cartella spam o",
        "validating_code": "Convalida del codice in corso...",
        "ccod_resend_text": "Il codice di conferma è stato inviato, controlla la tua email",
        "login_with_sso_not_allow": "L'accesso con SSO non è consentito per questo dominio, contatta il tuo amministratore.",
        "check_link_reset_pwd": "Controlla la tua casella di posta per un collegamento per reimpostare la password.",
        "invalid_account_msg": "Non siamo riusciti a trovare un account con quell'indirizzo email. Controlla la tua email e riprova.",
        "error_limit_exceeded": "Limite superato!",
        "domain_contact_admin_msg": "Quel dominio non esiste, riprova o contatta il tuo amministratore.",
        "login_with_your_credentials": "Accedi con le tue credenziali"
    },
    "zh_CN": {
        "sign_in": "登入",
        "email_id": "电子邮件ID",
        "email_placeholder": "电子邮件 ID/用户 ID",
        "email_ID_User_ID_is_required": "电子邮件 ID/用户 ID 是必需的。",
        "email_ID_is_required": "电子邮件 ID 是必需的。",
        "pwd_placeholder": "密码",
        "password_required": "需要密码。",
        "dont_have_account": "还没有账号？",
        "sign_up": "注册",
        "forgot_password": "忘记密码？",
        "or_key": "或者",
        "login_with_sso": "使用 SSO 登录",
        "check_ccode_text": "检查您的电子邮件以获取代码",
        "resend_code": "重新发送验证码",
        "reset_password": "重置您的密码",
        "enter_email_id": "输入电子邮件 ID",
        "enter_valid_email": "输入有效的电子邮件地址。",
        "limit_exceeded": "超出限制",
        "no_email": "没有邮箱？",
        "reset_password_message": "如果您不使用电子邮件地址登录，请使用您的登录 ID（例如代理 ID）联系您的公司管理员或主管以重置您的密码。",
        "close": "关闭",
        "send": "发送",
        "done": "完毕",
        "verify_domain": "验证域",
        "enter_domain_name": "输入域名",
        "domain_val_msg": "输入有效的域名。",
        "crt_acnt_limit_character_msg": "最多允许 30 个字符。",
        "verify": "核实",
        "ccod_genral_text": "<strong> <trial_email>发送了一个 6 位数的确认码。 </strong>代码即将过期，请尽快输入。",
        "your_ccode_text": "您的<strong>确认码</strong>",
        "ccode_note1_text": "在检查您的代码时保持此窗口打开。 <br>还没有收到我们的电子邮件？试试你的垃圾邮件文件夹或",
        "validating_code": "正在验证代码...",
        "ccod_resend_text": "确认码已发送，请查收邮箱",
        "login_with_sso_not_allow": "此域不允许使用 SSO 登录，请联系您的管理员。",
        "check_link_reset_pwd": "检查您的收件箱以获取重置密码的链接。",
        "invalid_account_msg": "我们找不到具有该电子邮件地址的帐户。请检查您的电子邮件，然后重试。",
        "error_limit_exceeded": "超出限制！",
        "domain_contact_admin_msg": "该域不存在，请重试或联系您的管理员。",
        "login_with_your_credentials": "使用您的凭据登录"
    },
    "ja_JP": {
        "sign_in": "ログイン",
        "email_id": "電子メールID",
        "email_placeholder": "メールID/ユーザーID",
        "email_ID_User_ID_is_required": "メールID/ユーザーIDが必要です。",
        "email_ID_is_required": "メール ID が必要です。",
        "pwd_placeholder": "パスワード",
        "password_required": "パスワードが必要。",
        "dont_have_account": "まだアカウントを持っていませんか?",
        "sign_up": "サインアップ",
        "forgot_password": "パスワードをお忘れですか？",
        "or_key": "また",
        "login_with_sso": "SSO でログイン",
        "check_ccode_text": "メールでコードを確認する",
        "resend_code": "コードを再送する",
        "reset_password": "あなたのパスワードをリセット",
        "enter_email_id": "メールIDを入力",
        "enter_valid_email": "有効なメールアドレスを入力してください。",
        "limit_exceeded": "限度超過",
        "no_email": "メールがありませんか？",
        "reset_password_message": "ログインに電子メール アドレスを使用しない場合は、会社の管理者またはスーパーバイザーにログイン ID (例: エージェント ID) を連絡して、パスワードをリセットしてください。",
        "close": "近い",
        "send": "送信",
        "done": "終わり",
        "verify_domain": "ドメインの確認",
        "enter_domain_name": "ドメイン名を入力してください",
        "domain_val_msg": "有効なドメイン名を入力してください。",
        "crt_acnt_limit_character_msg": "最大 30 文字まで使用できます。",
        "verify": "確認",
        "ccod_genral_text": "<strong> <trial_email>に 6 桁の確認コードを送信しました。 </strong>コードの有効期限が間もなく切れます。すぐに入力してください。",
        "your_ccode_text": "あなたの<strong>確認コード</strong>",
        "ccode_note1_text": "コードをチェックしている間は、このウィンドウを開いたままにしてください。 <br>メールが届いていませんか?迷惑メールフォルダを試すか、",
        "validating_code": "コードを検証しています...",
        "ccod_resend_text": "確認コードが送信されます。メールを確認してください",
        "login_with_sso_not_allow": "このドメインでは SSO でのログインは許可されていません。管理者に連絡してください。",
        "check_link_reset_pwd": "受信ボックスをチェックして、パスワードをリセットするためのリンクを探します。",
        "invalid_account_msg": "そのメール アドレスのアカウントは見つかりませんでした。メールを確認して、もう一度お試しください。",
        "error_limit_exceeded": "限度超過！",
        "domain_contact_admin_msg": "そのドメインは存在しません。再試行するか、管理者に連絡してください。",
        "login_with_your_credentials": "資格情報を使用してログインします"
    }
}