import {
  EVENTS,
  FORGOT_PASSWORD,
  RECOVER_PASSWORD,
} from "../reducers/change-password-reducer";
import ChangePasswordServices from "../services/changePasswordService";
import { handleErrorMsgToastVisiblity } from "../shared/utility";

const forgotPasswordStart = () => {
  return {
    event: EVENTS.FORGOT_PASSWORD,
    type: FORGOT_PASSWORD.START,
  };
};

const forgotPasswordSuccess = (data) => {
  return {
    event: EVENTS.FORGOT_PASSWORD,
    type: FORGOT_PASSWORD.SUCCESS,
    payload: data,
  };
};

const forgotPasswordError = (error) => {
  return {
    event: EVENTS.FORGOT_PASSWORD,
    type: FORGOT_PASSWORD.ERROR,
    payload: error,
  };
};

export const ForgotPasswordForUser = (data) => async (dispatch) => {
  dispatch(forgotPasswordStart());

  ChangePasswordServices.forgotPassword(data.emailForPwd)
    .then((response) => {
      if (response === true) {
        dispatch(forgotPasswordSuccess(response));
      } else {
        handleErrorMsgToastVisiblity(response.data.Error)
        dispatch(forgotPasswordError(response.data.Error));
      }
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error.message);
      handleErrorMsgToastVisiblity(error.message)
      dispatch(forgotPasswordError(error));
    });
};

const recoverPasswordStart = () => {
  return {
    event: EVENTS.RECOVER_PASSWORD,
    type: RECOVER_PASSWORD.START,
  };
};

const recoverPasswordSuccess = (data) => {
  return {
    event: EVENTS.RECOVER_PASSWORD,
    type: RECOVER_PASSWORD.SUCCESS,
    payload: data,
  };
};

const recoverPasswordError = (error) => {
  return {
    event: EVENTS.RECOVER_PASSWORD,
    type: RECOVER_PASSWORD.ERROR,
    payload: error,
  };
};

export const RecoverPasswordForUser = (payload) => (dispatch) => {
  dispatch(recoverPasswordStart());

  ChangePasswordServices.recoverPassword(payload)
    .then((response) => {
      if (response === true) {
        dispatch(recoverPasswordSuccess(response));
      } else {
        handleErrorMsgToastVisiblity(response.data.Error);
        dispatch(recoverPasswordError(response.data.Error));
      }
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error.message);
      dispatch(recoverPasswordError(error));
    });
};
