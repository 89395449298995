import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import IntlTelInput from "react-intl-tel-input";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import {
  NOT_SUPPORTED_AREA_CODES,
  NOT_SUPPORTED_COUNTRY_CODES,
  RouteName,
  SEND_LINK_TYPES,
  UK_COUNTRY_CODE_NEW,
  UK_COUNTRY_DIALCODE,
  allowedCountries,
  allowedCountriesDialCodePattern,
} from "../../constants/Constants";
import { Localize } from "./Localize";
import history from "../../history";
import { handleErrorMsgToastVisiblity, openUrlInBrowser, resetDataForSession } from "../../shared/utility";
import {
  createSessionReset,
  inviteSession,
  inviteSessionReset,
} from "../../actions/sessionListAction";
import storage from "../../services/storage";
import { ButtonWithLoader } from "../UI/Button/Button";
import CustomToast from "../UI/customToast";

const SendMsg = ({
  id,
  ariaLabelledby,
  type,
  message,
  isDefaultInvite,
  sessionDetailData,
  sendUrl,
  agentUrl,
  defaultCountry,
  isUploadOnlySessionValue,
  mobileNumberRef,
  countryRef,
  isInviteAPICalledRef,
  updateNordicCountryMessage,
  isSessionOwner,
  isForSaskTail
}) => {
  const dispatch = useDispatch();
  const { inviteSessionsLoading, inviteSessionsData, inviteSessionsError } =
    useSelector((state) => state.sessionList);
  const { language } = useSelector((state) => state);
  const intlTelInputRef = useRef(null);

  const [isValidValue, setIsValidValue] = useState(true);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true);
  const [isUserInvited, setIsUserInvited] = useState(false);
  const [inviteButton, setInviteButton] = useState("");
  const [localisedValues, setLocalisedValues] = useState({});
  const [mobileNumber, setMobileNumber] = useState(mobileNumberRef.current);
  const [countryData, setCountryData] = useState(countryRef.current);
  const [isInviteAPICalled, setIsInviteAPICalled] = useState(
    isInviteAPICalledRef.current
  );

  const inviteTypeSuccessMessage =
    type === SEND_LINK_TYPES.SMS.name
      ? localisedValues["sms_sent_label"]
      : localisedValues["whatsapp_sent_label"];

  useEffect(() => {
    mobileNumberRef.current = mobileNumber;
  }, [mobileNumber]);

  useEffect(() => {
    countryRef.current = countryData;
        type === "SMS" && updateNordicCountryMessage(countryData);
    }, [countryData]);
    
  useEffect(() => {
    isInviteAPICalledRef.current = isInviteAPICalled;
  }, [isInviteAPICalled]);

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  useEffect(() => {
    if (inviteSessionsLoading) return;

    if (inviteSessionsData) {
      if (isUploadOnlySessionValue) {
        CustomToast.success(
          `${inviteTypeSuccessMessage} ${localisedValues["recipients_can_upload_files"]}`
        );
        history.replace(RouteName.SESSIONS);
      } else {
        CustomToast.success(localisedValues["invite_sent_successfully"]);
      }
      intlTelInputRef.current?.tel?.focus();
      if(!isEmpty(countryData)){
        storage.set(type + "CountrySelected", countryData)
      }
      setMobileNumber("");
      setIsUserInvited(true);
      setIsInviteAPICalled(true);
      setIsButtonsDisabled(true);
      if(!isForSaskTail) dispatch(createSessionReset());

      if (inviteButton === "InviteAndJoin") {
        openUrlInBrowser(agentUrl, "_self");
        resetDataForSession();
      }  

    } else if (inviteSessionsError) {
      handleErrorMsgToastVisiblity(inviteSessionsError.message);
    }
    dispatch(inviteSessionReset());
  }, [inviteSessionsLoading, inviteSessionsData, inviteSessionsError]);

  const isPhoneNumberValidForCustomAreaCode = (countryCode, phoneNumber) => {
    // phone number validation for digit only and with length of 10 character exact
    if(phoneNumber?.replace(/\D+/g, "").length !== 10) return false;
    // Extract area code from the phone number
    const area_code = phoneNumber.substring(0, 3);
    // Check if country code exists
    const country_index = NOT_SUPPORTED_COUNTRY_CODES.indexOf(countryCode);
    if (country_index >= 0) {
      // Check if area code exists for the found country code
      const area_code_array = NOT_SUPPORTED_AREA_CODES[country_index];
      if (area_code_array.includes(area_code)) {
        return true;
      }
    }

    return false;
  }

  const setButtonsDisabled = (isValidPhoneNumber, countryData, value, checkForError=false) => {
    const isValidForCustomAreaCode = isPhoneNumberValidForCustomAreaCode(countryData.dialCode, intlTelInputRef?.current?.getNumber(value, "-"));
    setIsButtonsDisabled(!isValidForCustomAreaCode ? !isValidPhoneNumber : false);
    if(isValidForCustomAreaCode) setCountryData(countryData);
    if(checkForError) setIsValidValue(isValidPhoneNumber || isValidForCustomAreaCode);
  }

  const onPhoneNumberChangeHandler = (_isValid, value, countryData) => {
    setIsUserInvited(false);
    const isValidPhoneNumber = intlTelInputRef.current?.isValidNumber(value);
    if (isValidPhoneNumber) {
      setCountryData(countryData);
      const numberInput = document.getElementById("numberInput");
      numberInput?.addEventListener("keyup", (event) => {
        if (event.key === "Enter") {
          const actionButton = document.getElementById("messageActionButton");
          if(actionButton) actionButton.click();
        }
      });
    }
    let phoneNumber = value;
    let patternMob = /[a-zA-Z`~!@$%^&_|\=?;:'" ,<>\{\}\\\/]/gi;
    let isnonvalid = patternMob.test(phoneNumber);
    if (isnonvalid) {
      phoneNumber = phoneNumber.replace(patternMob, "");
      setMobileNumber(phoneNumber);
    } else {
      setMobileNumber(phoneNumber);
    }

    let matchedCountryCode = phoneNumber.match(allowedCountriesDialCodePattern);
    if (matchedCountryCode && matchedCountryCode.length > 0) {
      const newValueIndex = phoneNumber.lastIndexOf("+");
      phoneNumber = phoneNumber.substring(newValueIndex);
      matchedCountryCode = phoneNumber.match(allowedCountriesDialCodePattern);
      setMobileNumber(phoneNumber.substring(matchedCountryCode[0]?.length));
    }
    setButtonsDisabled(isValidPhoneNumber, countryData, value);
  };

  const onPhoneNumberFocusHandler = (isValid, value, countryData) => {
    const checkForError = value?.replace(/\D+/g, "").length !== 0;
    setButtonsDisabled(isValid, countryData, value, checkForError);
  };

  const onSelectFlagHandler = (
    _currentNumber,
    countryData,
    _fullNumber,
    isValid
  ) => {
    setButtonsDisabled(isValid, countryData, intlTelInputRef?.current?.state.value);
    setCountryData(countryData);
  };

  const inviteSesssionHandler = (buttonType) => {
    const countryIsoCode = countryData.dialCode === UK_COUNTRY_DIALCODE ? UK_COUNTRY_CODE_NEW : countryData?.iso2;

    if (isValidValue) {
      const request = {
        sessionId: sessionDetailData.session?.sessionId,
        isoCode: countryIsoCode?.toUpperCase(),
        CountryCode: countryData?.dialCode,
        mobileNo: mobileNumber?.replace(/\D+/g, ""),
        url: sendUrl,
      };
      if (type === "SMS") {
        request.invitationType = 1;
        request.message = message;
      } else if (type === "WhatsApp") {
        request.invitationType = 2;
      }
      dispatch(inviteSession(request));
      setInviteButton(buttonType);
    }
  };

  return (
    <div
      className={clsx("tab-pane", isDefaultInvite ? "active show" : "")}
      id={id}
      data-testid={id}
      role="tabpanel"
      aria-labelledby={ariaLabelledby}
    >
      <div className="guest-invite-box">
        <div className="guest-invite-body">
          <div className="title">
            <h2 className="title-mob">
              {type === SEND_LINK_TYPES.SMS.name
                ? localisedValues["sms_label"]
                : localisedValues["whatsapp_label"]}
            </h2>
            <h3 className="title-desktop">
              {localisedValues["enter_mobile_number"]}
            </h3>
          </div>
          <div className="form-group mobile-number-group">
            <div className="form-group-in">
              <IntlTelInput
                ref={intlTelInputRef}
                fieldId="numberInput"
                data-testid="numberInput"
                defaultCountry={defaultCountry || "us"}
                autoFocus={true}
                value={mobileNumber}
                containerClassName="intl-tel-input"
                inputClassName="form-control form-control-grey height-46 focus-font-bold"
                separateDialCode={true}
                onlyCountries={allowedCountries}
                autoHideDialCode={true}
                placeholder={localisedValues["mobile_number"]}
                onPhoneNumberChange={onPhoneNumberChangeHandler}
                onPhoneNumberBlur={onPhoneNumberFocusHandler}
                onPhoneNumberFocus={() => setIsValidValue(true)}
                onSelectFlag={onSelectFlagHandler}
              />
            </div>
            {!isValidValue && (
              <div className="validation-error">
                {localisedValues["enter_valid_mobile"]}
              </div>
            )}
          </div>
          <div className="form-group sms-invite-textarea">
            <div className="form-group-in">
              <textarea
                className="form-control form-control-grey focus-font-bold"
                disabled
                value={message}
              />
            </div>
          </div>
          {isUserInvited && (
            <div className="send-success">
              <span className="send-icon icon-checked-fill"></span>
              <span className="send-text">{inviteTypeSuccessMessage}</span>
            </div>
          )}
        </div>
        <div className="guest-invite-footer">
          <div className="button-inline text-center d-flex">
            <div className="button join-call-btn">
              {isInviteAPICalled && !isUploadOnlySessionValue && isSessionOwner && (
                <div className="join-call-btn-in">
                  <button
                    type="button"
                    className="btn btn-link font-weight-500"
                    onClick={() => {openUrlInBrowser(agentUrl, "_self");resetDataForSession();}}
                  >
                    {localisedValues["join_call"]}
                  </button>
                </div>
              )}
            </div>
            {!isUploadOnlySessionValue ? (
              <>
                <div className="button">
                  <ButtonWithLoader
                    className="btn btn-light-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                    name={localisedValues["invite"]}
                    disable={isButtonsDisabled}
                    showLoader={
                      inviteButton === "Invite" && inviteSessionsLoading
                    }
                    onClick={() => inviteSesssionHandler("Invite")}
                  />
                </div>
                {isSessionOwner && <div className="button">
                  <ButtonWithLoader
                    id="messageActionButton"
                    className="btn btn-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                    name={localisedValues["invite_and_join"]}
                    disable={isButtonsDisabled}
                    showLoader={
                      inviteButton === "InviteAndJoin" && inviteSessionsLoading
                    }
                    onClick={() => inviteSesssionHandler("InviteAndJoin")}
                  />
                </div>}
              </>
            ) : (
              <div className="button">
                <ButtonWithLoader
                  id="messageActionButton"
                  className="btn btn-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                  name={localisedValues["send_link"]}
                  disable={isButtonsDisabled}
                  showLoader={
                    inviteButton === "Invite" && inviteSessionsLoading
                  }
                  onClick={() => inviteSesssionHandler("Invite")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SendMsg.propTypes = {
  id: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  isDefaultInvite: PropTypes.bool,
  sessionDetailData: PropTypes.object,
  sendUrl: PropTypes.string,
  agentUrl: PropTypes.string,
  defaultCountry: PropTypes.string,
  isUploadOnlySessionValue: PropTypes.bool,
  mobileNumberRef: PropTypes.object,
  countryRef: PropTypes.object,
  isInviteAPICalledRef: PropTypes.object,
  updateNordicCountryMessage: PropTypes.func,
};

export default SendMsg;
