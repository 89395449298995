import { JOURNEY_STEP_TYPES } from "../../../../../constants/Constants";
import PropTypes from 'prop-types';

const OCRStep = ({ dataObj, answerObj }) => {
  if (dataObj.type === JOURNEY_STEP_TYPES.OCR && answerObj?.length > 0) {
    return (
      <div className="step-info user-fill-info">
        {answerObj.map((ans, index) => (
          <p key={index}>{ans}</p>
        ))}
      </div>
    );
  }
}

OCRStep.propTypes = {
  dataObj: PropTypes.object,
  answerObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default OCRStep;