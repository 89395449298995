import PropTypes from "prop-types";
import CreateSessionModal from "../Sessions/CreateSessionModal/CreateSessionModal";
import DataTable from "../UI/DataTable/DataTable";
import { ExpandRowForInviteContacts } from "./ColumnFormatter/ColumnFormatter";

const ShowInviteContacts = ({
  columns,
  columnsToShow,
  localisedValues,
  isLoading,
  updatedPaginatedData,
  setQueryParams,
  paginationOptions,
  defaultSorted,
  userSearch,
  setUserSearch,
  setSelectedBuddy,
  selectedBuddy,
}) => {
  return (
    <div className="invite-contact custom-scroll-div">
      <div className="invite-contact-sec">
        <div className="invite-contact-sec-in">
          <div className="invite-contact-box">
            <DataTable
              columns={columns}
              expandRow={ExpandRowForInviteContacts(
                columnsToShow,
                localisedValues
              )}
              isLoading={isLoading}
              tableData={updatedPaginatedData}
              tableKey="USERID"
              onTableChangeFunction={setQueryParams}
              paginationOptions={paginationOptions}
              noDataMessage={localisedValues["no_record_found"]}
              defaultSorted={defaultSorted}
              searchHelper={{
                search: userSearch,
                setSearch: setUserSearch,
                localisedValues,
              }}
            />
          </div>
        </div>
      </div>
      {selectedBuddy && (
        <CreateSessionModal
          closeModal={() => setSelectedBuddy(false)}
          inviteBuddyData={selectedBuddy}
        />
      )}
    </div>
  );
};

ShowInviteContacts.propTypes = {
  columns: PropTypes.array,
  columnsToShow: PropTypes.number,
  localisedValues: PropTypes.object,
  isLoading: PropTypes.bool,
  updatedPaginatedData: PropTypes.array,
  setQueryParams: PropTypes.func,
  paginationOptions: PropTypes.object,
  defaultSorted: PropTypes.array,
  userSearch: PropTypes.string,
  setUserSearch: PropTypes.func,
  setSelectedBuddy: PropTypes.func,
  selectedBuddy: PropTypes.bool,
};

export default ShowInviteContacts;
