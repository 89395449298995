import PropTypes from 'prop-types';
import PaginationSearch from "./PaginationSearch";

const PaginationHeader = ({ searchHelper }) => {
  return <PaginationSearch searchHelper={searchHelper} />;
};

PaginationHeader.propTypes = {
  searchHelper: PropTypes.object,
};

export default PaginationHeader;
