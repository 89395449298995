import api from "../apis/api";

class OnLoadFunctionService {
  checkIsCompanyAvailable(req) {
    return api.onloadfunction.checkIsCompanyAvailable(req);
  }

  getJourneyList(req) {
    return api.onloadfunction.getJourneyList(req);
  }
  
  postLogOut(formData){
    return api.onloadfunction.postLogOut(formData);
  }

  getDomainList(){
    return api.onloadfunction.getDomainList();
  }

}

export default new OnLoadFunctionService();
