
export const arrayElementExistCheckWithObject = (arr, obj) => {
    return arr.findIndex(obj) >= 0;
};

export const arrayElementExistCheckWithKey = (arr, key, val) => {
    return arr.findIndex((obj) => obj[key] === val) >= 0;
}

export const arrayAddIfNotExistCheckWithObject = (arr, obj) => {
    if(!arrayElementExistCheckWithObject(arr, obj)){
        arr.push(obj);
    }

    return arr;
}

export const arrayAddIfNotExistCheckWithKey = (arr, obj, key) => {
    if(!arrayElementExistCheckWithKey(arr, key, obj[key])){
        arr.push(obj);
    }

    return arr;
}