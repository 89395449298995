import api from "../apis/api";

class ChangePasswordService {
  forgotPassword(formData) {
    return api.changepassword.forgotPassword(formData);
  }
  recoverPassword(formData) {
    return api.changepassword.recoverPassword(formData);
  }
}



const ChangePasswordServices = new ChangePasswordService();
export default ChangePasswordServices;
