import { toast } from "react-toastify";

const CustomToast = {
    success: (message) => {
        return toast.success(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    },
    info: (message) => {
        return toast.info(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    },
    warn: (message) => {
        return toast.warn(message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    },
    error: (message) => {
        return toast.error(message, {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    },
    update: (existingToast, message, type) => {
        let toastType;

        if (type === "success") {
            toastType = toast.TYPE.SUCCESS;
        } else if (type === "error") {
            toastType = toast.TYPE.ERROR;
        } else {
            toastType = toast.TYPE.INFO;
        }
        return toast.update(existingToast, {
            render: message,
            type: toastType,
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    },
    promise: (prom, details) => {
        return toast.promise(prom, details, {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark"
        });
    },
    dismissToast: (toastObj) => {
        toast.dismiss(toastObj);
    },
    
}

export default CustomToast;