import { createRef, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { handleErrorMsgToastVisiblity, openUrlInBrowser, resetDataForSession } from "../../shared/utility";
import {
  createSessionReset,
  inviteSession,
  inviteSessionReset,
} from "../../actions/sessionListAction";
import { Localize } from "./Localize";
import CustomToast from "../UI/customToast";
import { ButtonWithLoader } from "../UI/Button/Button";

const SendSmartglass = ({
  id,
  isDefaultInvite,
  sessionDetailData,
  sendUrl,
  agentUrl,
  isInviteAPICalledRef,
  isSessionOwner,
  isForSaskTail
}) => {
  const dispatch = useDispatch();
  const { inviteSessionsLoading, inviteSessionsData, inviteSessionsError } =
    useSelector((state) => state.sessionList);
  const { language } = useSelector((state) => state);

  const [activeInput, setActiveInput] = useState(0);
  const [smartglassId, setSmartglassId] = useState("");
  const [isUserInvited, setIsUserInvited] = useState(false);
  const [inviteButton, setInviteButton] = useState("");
  const [localisedValues, setLocalisedValues] = useState({});
  const [isInviteAPICalled, setIsInviteAPICalled] = useState(
    isInviteAPICalledRef.current
  );

  const inputRefs = useRef([...Array(5)].map(() => createRef()));

  useEffect(() => {
    isInviteAPICalledRef.current = isInviteAPICalled;
  }, [isInviteAPICalled]);

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  useEffect(() => {
    if (inviteSessionsLoading) return;

    if (inviteSessionsData) {
      CustomToast.success(localisedValues["invite_sent_successfully"]);
      setSmartglassId(Array(5).fill(""));
      setActiveInput(0);
      setIsUserInvited(true);
      setIsInviteAPICalled(true);
      if(!isForSaskTail) dispatch(createSessionReset());
      inputRefs?.current[0]?.current?.focus();

      if (inviteButton === "InviteAndJoin") {
        openUrlInBrowser(agentUrl, "_self");
        resetDataForSession();
      }  
    } else if (inviteSessionsError) {
      handleErrorMsgToastVisiblity(inviteSessionsError.message);
    }
    dispatch(inviteSessionReset());
  }, [inviteSessionsLoading, inviteSessionsData, inviteSessionsError]);

  /* 
  Focus on the first input
  when the component mounts
  */
  useEffect(() => {
    inputRefs?.current[0]?.current?.focus();
  }, []);

  //Handle input onChange
  const handleInputChange = (index, event) => {
    setIsUserInvited(false);
    const maxLength = 1;
    const value = event.target.value.replace(/\D/g, "");
    event.target.value = value.slice(0, maxLength);

    const newInputValue = [...smartglassId];
    newInputValue[index] = value;
    setSmartglassId(newInputValue.join(""));

    if (value.length === maxLength) {
      if (index < inputRefs.current.length - 1) {
        setActiveInput(index + 1);
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

  //Handle input keyDown
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && event.target.value.length === 0) {
      if (index > 0) {
        setActiveInput(index - 1);
        inputRefs.current[index - 1].current.focus();
      }
    } else if (event.key === "Delete" && event.target.value.length === 0) {
      if (index < inputRefs.current.length - 1) {
        setActiveInput(index + 1);
        inputRefs.current[index + 1].current.focus();
      }
    } else if(event.key === "Enter"){
          const actionButton = document.getElementById("smartGlassActionButton");
          if(actionButton) actionButton.click();
    }
  };

  const inviteViaSmartglassHandler = (buttonType) => {
    if (smartglassId.length === 5) {
      const request = {
        invitationType: 4,
        sessionId: sessionDetailData.session?.sessionId,
        isoCode: "",
        smartGlassId: smartglassId,
        url: sendUrl,
      };
      dispatch(inviteSession(request));
      setInviteButton(buttonType);
    }
  };

  return (
    <div
      className={clsx("tab-pane", isDefaultInvite ? "active show" : "")}
      id={id}
      data-testid={id}
      role="tabpanel"
      aria-labelledby="invite-smartglass-tab"
    >
      <div className="guest-invite-box">
        <div className="guest-invite-body">
          <div className="title">
            <h2 className="title-mob">Smartglass</h2>
            <h3 className="title-desktop">
              {localisedValues["smartglass_disacription"]}
            </h3>
          </div>
          <div className="smartglass-verification">
            <div className="smartglass-verification-in hello">
              {[...Array(5)].map((_, index) => (
                <input
                  key={index}
                  ref={inputRefs.current[index]}
                  className={clsx(
                    "form-control sg-form-control",
                    activeInput === index && "active-input"
                  )}
                  value={smartglassId[index] || ""}
                  placeholder="X"
                  maxLength="1"
                  onChange={(e) => handleInputChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/\D/g, ""))
                  }
                />
              ))}
            </div>
          </div>
          {isUserInvited && (
            <div className="send-success">
              <span className="send-icon icon-checked-fill"></span>
              <span className="send-text">
                {localisedValues["smartglass_sent_label"]}
              </span>
            </div>
          )}
        </div>
        <div className="guest-invite-footer">
          <div className="button-inline text-center d-flex">
            <div className="button join-call-btn">
              {isInviteAPICalled && isSessionOwner && (
                <div className="join-call-btn-in">
                  <button
                    type="button"
                    className="btn btn-link font-weight-500"
                    onClick={() => {openUrlInBrowser(agentUrl, "_self");resetDataForSession();}}
                  >
                    {localisedValues["join_call"]}
                  </button>
                </div>
              )}
            </div>
              <div className="button">
                <ButtonWithLoader
                  className="btn btn-light-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                  name={localisedValues["invite"]}
                  disable={smartglassId.length < 5}
                  showLoader={
                    inviteButton === "Invite" && inviteSessionsLoading
                  }
                  onClick={() => inviteViaSmartglassHandler("Invite")}
                />
              </div>
              {isSessionOwner && <div className="button">
                <ButtonWithLoader
                  id="smartGlassActionButton"
                  className="btn btn-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                  name={localisedValues["invite_and_join"]}
                  disable={smartglassId.length < 5}
                  showLoader={
                    inviteButton === "InviteAndJoin" && inviteSessionsLoading
                  }
                  onClick={() => inviteViaSmartglassHandler("InviteAndJoin")}
                />
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

SendSmartglass.propTypes = {
  id: PropTypes.string,
  isDefaultInvite: PropTypes.bool,
  sessionDetailData: PropTypes.object,
  sendUrl: PropTypes.string,
  agentUrl: PropTypes.string,
  isInviteAPICalledRef: PropTypes.object,
};

export default SendSmartglass;
