import clsx from "clsx";
import __ from "lodash";
import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import {
    DEFAULT_SORT, DEFAULT_SORT_ORDER, LOCAL_STORAGE_KEYS, RouteName, SORT_ORDER, SORT_TYPES,
    UPCOMING_SESSION_TYPE_VALUE
} from "../../../../constants/Constants";
import { Localize } from "../../Localize";

import storage from "../../../../services/storage";

const SortFilter = forwardRef((props, ref) => {
    const {
        selectedFilter,
        sortChangeHandler,
        language,
        isDiscardChanged
    } = props;

    const params = useParams();
    const history = useHistory();

    const callSortChangeHandler = useRef(true);

    const [localisedValues, setLocalisedValues] = useState({});

    const [sortBy, setSortBy] = useState((storage.has(LOCAL_STORAGE_KEYS.SESSION_SORT) && selectedFilter !== UPCOMING_SESSION_TYPE_VALUE) ? storage.get(LOCAL_STORAGE_KEYS.SESSION_SORT) : DEFAULT_SORT);
    const [sortOrder, setSortOrder] = useState((storage.has(LOCAL_STORAGE_KEYS.SESSION_SORT_ORDER) && selectedFilter !== UPCOMING_SESSION_TYPE_VALUE) ? storage.get(LOCAL_STORAGE_KEYS.SESSION_SORT_ORDER) : DEFAULT_SORT_ORDER);
    const [sortingTooltip, setSortingTooltip] = useState('');
    const [isFilterSelected, setIsFilterSelected] = useState("");

    useEffect(() => {
        const curLang = language.selectedlanguage;
        const localisedObj = Localize[curLang.id];
        setLocalisedValues(localisedObj);
        setSortingTooltip(localisedObj[SORT_TYPES.find((sort) => sort.value === sortBy).langkey]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language.selectedlanguage]);

    useEffect(() => {
        storage.set(LOCAL_STORAGE_KEYS.SESSION_SORT, sortBy);
        storage.set(LOCAL_STORAGE_KEYS.SESSION_SORT_ORDER, sortOrder);

        if (callSortChangeHandler.current) {
            sortChangeHandler(sortBy, sortOrder);
        }
        else {
            callSortChangeHandler.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, sortOrder]);

    // This hook helps a child component share certain functions or values with its parent. Here, the useImperativeHandle hook is used to let the parent access a bunch of functions through a ref.
    useImperativeHandle(ref, () => ({
        setDefaultSort() {
            setSortBy(DEFAULT_SORT);
            setSortOrder(DEFAULT_SORT_ORDER);
            setSortingTooltip(localisedValues[SORT_TYPES.find((sort) => sort.value === DEFAULT_SORT).langkey]);
            callSortChangeHandler.current = false;
        }
    }), [DEFAULT_SORT, DEFAULT_SORT_ORDER, localisedValues]);

    const sessionSortingChangeHandler = (value) => {
        setIsFilterSelected("selected")

        setTimeout(()=> {
            setIsFilterSelected("")
        },500)

        if (!__.isEmpty(params) && !isDiscardChanged) {
            history.push(RouteName.SESSIONS);
        }

        if (sortBy === value) {
            if (sortOrder === SORT_ORDER.ASCENDING) setSortOrder(SORT_ORDER.DESCENDING);
            else setSortOrder(SORT_ORDER.ASCENDING);
        } else setSortOrder(DEFAULT_SORT_ORDER);
        setSortBy(value);
        setSortingTooltip(localisedValues[SORT_TYPES.find((sort) => sort.value === value).langkey]);
    }

    const canShowSort = (formula) => {
        // eslint-disable-next-line
        return Function("return " + selectedFilter + formula)();
    }

    return (
        <>
            {!__.isEmpty(localisedValues) &&
                <div className="filter-box all-filter-box session-sort-box custom-tooltip right-tooltip tooltip-auto-width m-hide">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle triangle-none" type="button"
                            id="session-sorting-filter" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className={clsx("filter-btn-icon", sortOrder === SORT_ORDER.ASCENDING ? "icon-session-sort-asc" : "icon-session-sort-desc")}></span>
                            <span className="filter-btn-text">{sortingTooltip}</span>
                            <span className="dropdown-toggle-arrow icon-arrow-down"></span>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="session-sorting-filter">
                            <div className="dropdown-menu-in side-padding">
                                <ul>
                                    <li key="Sort_By">
                                        <div className="sortby-label">{localisedValues['sort_by']}</div>
                                    </li>
                                    {SORT_TYPES.map((sort) => (
                                        <Fragment key={sort.name}>
                                            {canShowSort(sort.eval) &&
                                                <li className={clsx(sortBy === sort.value && 'active')}>
                                                    {/* eslint-disable-next-line */}
                                                    <a className="dropdown-item" href={void (0)} onClick={() => sessionSortingChangeHandler(sort.value)}>
                                                        <div className="dropdown-item-box">
                                                            <span className="dropdown-item-text">{localisedValues[sort.langkey]}</span>
                                                            <span className={clsx("dropdown-item-icon", sortBy === sort.value && (sortOrder === SORT_ORDER.ASCENDING ? 'icon-sort-ascending' : 'icon-sort-descending'))}></span>
                                                        </div>
                                                    </a>
                                                </li>
                                            }
                                        </Fragment>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {isFilterSelected !== "selected" && <div className="custom-tooltip-content text-capitalize">
                            <div className="custom-tooltip-in">
                                <p>{sortingTooltip}</p>
                            </div>
                        </div>}
                    </div>
                </div>
            }
        </>
    );
});

const mapStateToProps = (state) => {
    return {
        language: state.language,
    }
}

SortFilter.propTypes = {
    selectedFilter: PropTypes.number,
    sortChangeHandler: PropTypes.func,
    language: PropTypes.object,
    isDiscardChanged: PropTypes.bool,
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(SortFilter);