import { EVENTS, GET_JOURNEY_LIST, ISCOMPANY_AVAILABLE, LOGOUT } from "../reducers/onLoadFunction-reducer";
import OnLoadFunctionService from "../services/onLoadFunctionService";
import { handleErrorMsgToastVisiblity, storeCompanylistForAdmin } from "../shared/utility";

const isCompanyAvailableStart = () => {
    return {
        event: EVENTS.ISCOMPANY_AVAILABLE,
        type: ISCOMPANY_AVAILABLE.START
    }
}

const isCompanyAvailableSuccess = (data) => {
    return {
        event: EVENTS.ISCOMPANY_AVAILABLE,
        type: ISCOMPANY_AVAILABLE.SUCCESS,
        payload: data
    }
}

const isCompanyAvailableError = (error) => {
    return {
        event: EVENTS.ISCOMPANY_AVAILABLE,
        type: ISCOMPANY_AVAILABLE.ERROR,
        payload: error
    }
}

export const checkIsCompanyAvailable = (req) => async (dispatch) => {
    dispatch(isCompanyAvailableStart());
    OnLoadFunctionService
        .checkIsCompanyAvailable(req)
        .then((response) => {
            dispatch(isCompanyAvailableSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(isCompanyAvailableError(error));
        })
}

const getJourneyListStart = () => {
  return {
    event: EVENTS.GET_JOURNEY_LIST,
    type: GET_JOURNEY_LIST.START,
  };
};

const getJourneyListSuccess = (data) => {
  return {
    event: EVENTS.GET_JOURNEY_LIST,
    type: GET_JOURNEY_LIST.SUCCESS,
    payload: data,
  };
};

const getJourneyListError = (error) => {
  return {
    event: EVENTS.GET_JOURNEY_LIST,
    type: GET_JOURNEY_LIST.ERROR,
    payload: error,
  };
};

export const getJourneyList = () => async (dispatch) => {
  dispatch(getJourneyListStart());
  OnLoadFunctionService.getJourneyList()
    .then((response) => {
      dispatch(getJourneyListSuccess(response));
    })
    .catch((error) => {
      handleErrorMsgToastVisiblity(error);
      dispatch(getJourneyListError(error));
    });
};

const logoutStart = () => {
  return {
      event: EVENTS.LOGOUT,
      type: LOGOUT.START
  }
}

const logoutSuccess = (data) => {
  return {
      event: EVENTS.LOGOUT,
      type: LOGOUT.SUCCESS,
      payload: data
  }
}

const logoutError = (error) => {
  return {
      event: EVENTS.LOGOUT,
      type: LOGOUT.ERROR,
      payload: error
  }
}

export const logoutReset = () => {
  return {
    event: EVENTS.LOGOUT,
    type: LOGOUT.RESET
  }
}

export const LogoutUser = (formdata) => async (dispatch) => {
  dispatch(logoutStart());

  OnLoadFunctionService
      .postLogOut(formdata)
      .then((result) => {
        if(result.hasOwnProperty('items')){
          dispatch(logoutSuccess(result));
        }else{
          handleErrorMsgToastVisiblity(result?.message);
          dispatch(logoutError(result));
        }         
      })
      .catch((error) => {
          handleErrorMsgToastVisiblity(error?.message);
          dispatch(logoutError(error));
      })
}

export const fetchDomainList = () => async () => {
  OnLoadFunctionService
      .getDomainList()
      .then((result) => {
        console.log("result",result);
        storeCompanylistForAdmin(result.items);
      })
      .catch((error) => {
          handleErrorMsgToastVisiblity(error?.message);
      })
}