import { getDomainURL } from "../shared/utility";

export function dehydrateLogin(params) {
  return {
    domainUrl: getDomainURL() ?? "",
    userName: params.email,
    password: params.password,
  }
}

export function dehydrateVerfiyOtp(params) {
  return {
    verificationId: params.verificationId,
    userName:params.uemail,
    verificationCode: params.code,
  }
}

export function dehydrateResendOtp(params){
  return {
    verificationId: params.verificationId,
    userName: params.uemail
  }
}

export function dehydrateForgotPassword(params){
  return {
    DOMAINURL : getDomainURL() ?? "",
    UEMAIL: params.emailForPwd
}
}