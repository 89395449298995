import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, get, isEmpty } from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import { FORMATTED_DATE, FULL_DATE_FORMAT, LOCAL_STORAGE_KEYS } from "../../../../constants/Constants";
import { createdTimeDiff, generateZip, handleErrorMsgToastVisiblity } from "../../../../shared/utility";
import storage from "../../../../services/storage";
import NoImage from "../../../../assets/images/no-image.svg";
import { deleteSessionImages, getSessionImages, resetDeleteSessionImages } from "../../../../actions/sessionDetailAction";
import { Fancybox } from "../../../../shared/FancyboxJS";
import CustomToast from "../../../UI/customToast";
import { Localize } from "../Localize";
import DeleteModal from "../DeleteModal";

const ImagesTab = ({ meetingID, details, setDetails, fancyRef }) => {
  const dispatch = useDispatch();

  const {
    getSessionImagesLoading,
    getSessionImagesData,
    getSessionDetailsData,
    deleteSessionImagesLoading,
    deleteSessionImagesData,
    deleteSessionImagesError
  } = useSelector((state) => state.sessionDetail);

  const { language } = useSelector((state) => state)

  const [imageList, setImageList] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSelectItemsButtonClikced, setIsSelectItemsButtonClikced] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isImagesDownloading, setIsImagesDownloading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [localisedValues, setLocalisedValues] = useState({});

  const findTimeDiff = (createDateTime) => {
    const convertedTime = moment.utc(createDateTime, FULL_DATE_FORMAT).local()
    const timeDiff = createdTimeDiff(convertedTime, localisedValues)
    return timeDiff === true ? convertedTime.format('MMM DD, YYYY') : timeDiff;
  };

  const handleOnCheckImage = (path) => {
    setIsSelectItemsButtonClikced(false);
    let selected_images = [...selectedImages];
    if (selected_images.includes(path)) {
      selected_images = selected_images.filter((d) => d !== path);
    } else {
      selected_images.push(path);
    }
    setSelectedImages(selected_images);
    setIsAllChecked(selected_images.length === imageList.length);
  };

  const handleOnCheck = (type) => {
    let selected_images = [];
    if (type === "ALL") {
      selected_images = imageList.map((d) => d.filePath);
    }
    setSelectedImages(selected_images);
    setIsAllChecked(type === "ALL")
  };

  const handleOnDownload = async () => {
    if (isImagesDownloading) return;
    setIsImagesDownloading(true)
    const List = imageList.filter((d) => selectedImages.includes(d.filePath));
    const zipPromise = generateZip(List, "-images.zip", meetingID)
    CustomToast.promise(
      zipPromise,
      {
        pending: `${localisedValues['zipping_file']} (${selectedImages.length})`,
        success: localisedValues['successfully_downloaded'],
        error: localisedValues['download_failed']
      }
    )

    zipPromise.then(() => {
      setSelectedImages([])
      setIsAllChecked(false)
    })
    setIsImagesDownloading(false)
  }

  const handleOnDelete = () => {
    const deletedImages = imageList.filter((d) => selectedImages.includes(d.filePath)).map((d) => ({ name: d.filePath, uploadtype: d.uploadType }))
    
    deletedImages.map((img) => {
      if(img.uploadtype !== "0" && !img.uploadtype){
        img.uploadtype = "0";
      }
      return img;
    })

    const requestPayload = { sessionId: meetingID, Files: deletedImages }
    dispatch(deleteSessionImages(requestPayload))
  }

  useEffect(() => {
    const mainDiv = document.getElementById('session-image')
    if (mainDiv) {
      mainDiv.classList.add('active', 'show')
    }
    return () => {
      mainDiv.classList.remove('active', 'show')
    }
  }, [])

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  useEffect(() => {
    if (!getSessionImagesData) return
    const images_list = get(getSessionImagesData, "data.list", []);
    setImageList(images_list);
  }, [getSessionImagesData]);

  useEffect(() => {
    if (deleteSessionImagesLoading) return

    if (deleteSessionImagesData) {
      const updatedImagesList = imageList.filter((d) => !selectedImages.includes(d.filePath))
      setImageList(updatedImagesList)
      if (details?.session?.imageCount) {
        const updatedDetails = cloneDeep(details)
        updatedDetails.session.imageCount = deleteSessionImagesData.data.fileCount
        setDetails(updatedDetails)
      }
      setSelectedImages([])
      dispatch(resetDeleteSessionImages())
      CustomToast.success(localisedValues['successfully_deleted']);
    }
    else if (deleteSessionImagesError) {
      handleErrorMsgToastVisiblity(deleteSessionImagesError.message);
    }
    setIsDeleteModalOpen(false)
  }, [deleteSessionImagesData, deleteSessionImagesError])


  useEffect(() => {
    if (meetingID) {
      const request = { sessionId: meetingID };
      const sessionWaterMarkImage = getSessionDetailsData?.features?.WATERMARKONPHOTOS || false
      dispatch(getSessionImages(request, sessionWaterMarkImage));
    }
  }, []);

  const getSelectedImagesCount = () => {
    return isAllChecked ? localisedValues['user_all'] : `(${selectedImages.length})`;
  };


  return (
    <>
      <div className="tab-pane fade" id="session-image" role="tabpanel" data-testid='session-image-main'>
        {isEmpty(imageList) ? (
          <div className="no-session-data custom-scroll-div">
            <div className="no-session-data-in">
              <div className="no-session-data-box">
              {getSessionImagesLoading ? (
                <p> {localisedValues["loading_images"]} </p>
              ) : (
                <>
                  <div className="image">
                    <img src={NoImage} alt="No_image" />
                  </div>
                  <p> {localisedValues["no_image_text"]} </p>
                </>
              )}
              </div>
            </div>
          </div>
        ) : (
          <div className="session-image custom-scroll-div">
            <div className="session-image-head">
              <button
                className="btn"
                data-bs-toggle="collapse"
                data-bs-target="#session-images"
                aria-expanded="true"
                aria-controls="sessionimages"
                data-testid='expandBtn'
              >
                <span className="left-icon icon-image"></span>
                <span className="text" data-testid='imageTagWithCount'>
                  {localisedValues['images']} <span>{`(${imageList.length})`}</span>
                </span>
                <span className="arrow-icon icon-arrow-down"></span>
              </button>
            </div>
            <div className="session-image-body collapse show" id="session-images">
              <div className="session-image-action">
                <div className="checkbox checkbox-border checkbox-sm select-all-image">
                  <label>
                    <input
                      type="checkbox"
                      checked={isAllChecked}
                      onChange={(e) => {
                        setIsAllChecked(e.target.checked);
                        handleOnCheck(e.target.checked ? "ALL" : "NONE");
                      }}
                      data-testid='SelectAllCheckBox'
                    />
                    <span className="for-design"></span>
                    {localisedValues['select_all']}
                  </label>
                </div>
                <div className="select-image-mob">
                  {!isSelectItemsButtonClikced && isEmpty(selectedImages) ? (
                    <div className="button select-item-btn">
                      <button
                        type="button"
                        className="btn btn-primary border-round"
                        onClick={() => setIsSelectItemsButtonClikced(true)}
                      >
                        {localisedValues['select_items']}
                      </button>
                    </div>
                  ) : (
                    <div className="select-item-mob-right clearfix">
                      <div className="checkbox select-item-mob-checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={isAllChecked}
                            onChange={(e) => {
                              setIsAllChecked(e.target.checked);
                              handleOnCheck(e.target.checked ? "ALL" : "NONE");
                            }}
                          />
                          <span className="for-design"></span>
                        </label>
                      </div>
                      <div className="dropdown select-item-dropdown">
                        <button
                          className="btn dropdown-toggle triangle-none"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="triangle-icon icon-down-triangle-round"></span>
                        </button>
                        <div
                          className="dropdown-menu select-item-dropdown-menu"
                          aria-labelledby="dropdown"
                        >
                          <div className="dropdown-menu-in">
                            <ul>
                              <li>
                                <button
                                  className="btn"
                                  onClick={() => handleOnCheck("ALL")}
                                >
                                  {localisedValues['user_all']}
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn"
                                  onClick={() => {
                                    setIsSelectItemsButtonClikced(false)
                                    handleOnCheck("None")
                                  }}
                                >
                                  {localisedValues['none']}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {!isEmpty(selectedImages) && (
                  <div className="button-inline session-image-action-btn">
                    <div className="button">
                      <button
                        type="button"
                        className="btn btn-outline-secondary border-round"
                        onClick={() => {
                          setIsAllChecked(false)
                          handleOnCheck('NONE')
                        }}
                        data-testid='CancelBtn'
                      >
                        {localisedValues['user_cancel']}
                      </button>
                    </div>
                    <div className="button">
                      {getSessionDetailsData?.session?.ownerUserId === storage.get(LOCAL_STORAGE_KEYS.UCID) && <button
                        type="button"
                        className="btn btn-outline-secondary border-round"
                        onClick={() => setIsDeleteModalOpen(true)}
                        // data-bs-toggle="modal"
                        data-bs-target="#delete-image-modal"
                        data-testid='DeleteBtn'
                      >
                        {localisedValues['delete']} <span>{getSelectedImagesCount()}</span>
                      </button>}
                    </div>
                    <div className="button">
                      <button
                        type="button"
                        className="btn btn-primary border-round"
                        onClick={handleOnDownload}
                        data-testid='DownloadBtn'
                      >
                        {localisedValues['download_key']} <span>{getSelectedImagesCount()}</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="session-image-list clearfix">
                <ul>
                  <Fancybox
                    fancyRef={fancyRef}
                    options={{
                      Carousel: {
                        infinite: false
                      },
                      Toolbar: {
                        display: {
                          left: ["infobar"],
                          middle: [],
                          right: ["slideshow", "fullscreen", "download", "close"],
                        },
                      },
                      Thumbs: false,
                    }}>
                    {imageList?.map((d) => {
                      const isImageSelected = selectedImages.includes(d.filePath);
                      const formattedDate = moment.utc(d.createDateTime, FULL_DATE_FORMAT).local().format(FORMATTED_DATE);
                      return (
                        <li key={d.filePath} data-testid='imageBox'>
                          <div
                            className={`session-image-box ${isImageSelected ? "selected-image" : ""}`}
                          >
                            <div className="session-image-box-in">
                              {
                                <div
                                  className={`checkbox checkbox-border checkbox-sm select-image custom-tooltip right-tooltip tooltip-auto-width m-hide ${!isEmpty(selectedImages) || isSelectItemsButtonClikced ? "checkbox-show" : ""} `}
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      data-testid='imageCheckBox'
                                      onChange={() => {
                                        handleOnCheckImage(d.filePath);
                                      }}
                                      checked={isImageSelected}
                                    />
                                    <span className="for-design"></span>
                                  </label>
                                  <div className="custom-tooltip-content">
                                    <div className="custom-tooltip-in">
                                      <p>
                                        {isImageSelected ? localisedValues['unselect'] : localisedValues['select']}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              }
                              <a
                                rel="noreferrer"
                                className="session-image-fncy"
                                data-fancybox="session-image"
                                data-testid='session-image'
                                data-caption={d.caption}
                                href={d.fileUrl}
                              >
                                <div className="view-btn">View</div>
                                <div className="session-image-time">
                                  {findTimeDiff(d.createDateTime)}
                                </div>
                                <img
                                  className="session-image-img"
                                  src={d.fileUrl}
                                  alt={d.fileName}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="session-image-info clearfix">
                            <div className="session-image-name">
                              <div className="session-image-name-trim">
                                {d.fileName}
                              </div>
                            </div>
                            <div className="session-image-info-dropdown">
                              <div className="dropdown">
                                <button
                                  className="btn dropdown-toggle triangle-none"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <span className="btn-icon icon-more-vertical"></span>
                                  <span className="round-design"></span>
                                </button>
                                <div
                                  className="dropdown-menu right"
                                  aria-labelledby="dropdown"
                                >
                                  <div className="dropdown-menu-in">
                                    <ul>
                                      <li>
                                        <div className="dropdown-item-text">
                                          {d.fileName}
                                        </div>
                                      </li>
                                      <li>
                                        <div className="dropdown-item-text">
                                          <strong>{localisedValues['time']}:</strong>
                                          {formattedDate}
                                        </div>
                                      </li>
                                      {d.latVal && d.longVal && (    // Need to add css for this anchor element 
                                        <li>
                                          <a className="image-location-link"
                                            rel="noreferrer"
                                            href={`https://maps.google.com?q=${d.latVal},${d.longVal}`}
                                            target="_blank"
                                            data-download-src={`https://maps.google.com?q=${d.latVal},${d.longVal}`}
                                          >
                                            <span className="for-text">{localisedValues['view_image_location']}</span>
                                            <span className="for-icon icon-newtab"></span>
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </Fancybox>
                </ul>
              </div>
            </div>
          </div>
        )}
     </div>
      {isDeleteModalOpen &&
        <DeleteModal
          setIsModalVisible={setIsDeleteModalOpen}
          selectedFiles={selectedImages}
          loading={deleteSessionImagesLoading}
          handleOnDelete={handleOnDelete}
          localisedValues={localisedValues}
          headerTitleLocalisedKey="delete_popup_imagecount"
          bodyContentLocalisedKey="delete_imagepopup_text"
        />
      }
    </>
  );
};

ImagesTab.propTypes = {
  meetingID: PropTypes.string,
  details: PropTypes.object,
  setDetails: PropTypes.func,
  fancyRef: PropTypes.object
}

export default ImagesTab;