export const Localize = {
    "en_GB": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "no_image_text": "No Images available for this session or may have been deleted by your Admin.",
        "loading_images": "Loading images...",
        "user_cancel": "Cancel",
        "delete": "Delete",
        "download_key": "Download",
        "view_image_location": "View Image Location",
        "time": "Time",
        "view_key": "View",
        "select": "Select",
        "unselect": "Unselect",
        "none": "None",
        "select_items": "Select Items",
        "select_all": "Select All",
        "images": "Images",
        "delete_popup_imagecount": "Delete <count> Image(s)?",
        "delete_imagepopup_text": "Are you sure you want to permanently delete the <count> selected image(s)?",
        "norecording_text": "No Recordings available for this session or may have been deleted by your Admin.",
        "locading_recordings": "Loading recordings...",
        "no_files_found": "No files found.",
        "file_name": "File Name",
        "created_on": "Created on",
        "file_size": "File Size",
        "play": "Play",
        "recording_processing": "You have <count> recordings that need to be processed before viewing.",
        "single_pending_recording_label": "You have <count> recording that needs to be processed before viewing.",
        "you_lable": "You",
        "delete_popup_recordingcount": "Delete <count> Recording(s)?",
        "delete_recordingpopup_text": "Are you sure you want to permanently delete the <count> selected recording(s)?",
        "zipping_file": "Zipping files",
        "download_failed": "Download failed",
        "successfully_downloaded": "Successfully downloaded",
        "successfully_deleted": "Successfully deleted",
        "files_available_text": "No files available for this session or may have been deleted by your Admin.",
        "close": "Close",
        "chat_key": "Chat",
        "extracted_text": "Extracted text",
        "loading_files": "Loading files...",
        "delete_pupup_commontext": "You can't undo this action.",
        "delete_filepopup_text": "Are you sure you want to permanently delete the <count> selected file(s)?",
        "delete_popup_filecount": "Delete <count> File(s)?",
        "hi": "Hi,",
        "getstarted_newsession": "Get started by creating a new session below.",
        "new_session": "New Session",
        "responses": "Responses",
        "details_key": "Details",
        "note_lable": "Notes",
        "recordings": "Recordings",
        "files_key": "Files",
        "edit_reference_text": "Edit Reference Text",
        "save_button": "Save",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "search_collaboration_profile": "Collaboration Profile",
        "assigned_to_lable": "Assigned to",
        "duration": "Duration",
        "scheduled": "Scheduled",
        "user_created": "Created",
        "user_change": "Change",
        "ends_on": "Ends On",
        "ended_key": "Ended",
        "user_action": "Action",
        "copy_guest_url": "Copy guest URL",
        "copy_observer_url": "Copy Observer URL",
        "invites_sent": "Invites Sent",
        "no_invites": "No Invites",
        "archive_session": "Archive Session",
        "archive": "Archive",
        "session_ended": "Session ended",
        "end_link": "End Link",
        "end_session": "End Session",
        "end_btn": "End",
        "share": "Share",
        "invite": "Invite",
        "open_link": "Open Link",
        "start_session": "Start Session",
        "start_btn": "Start",
        "join_session": "Join Session",
        "join": "Join",
        "join_as_guest": "Join as Guest",
        "join_as_observer": "Join as Observer",
        "archiving_session_hide_from_session_history": "Archiving this session will hide it from session history.",
        "this_cannot_be_undone": "This cannot be undone.",
        "donnot_archive": "Don't archive",
        "back": "Back",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "reference_required": "Please enter Title/Reference.",
        "search": "Search",
        "few_seconds_ago": "a few seconds ago",
        "minute_ago": "1 minute ago",
        "minutes_ago": "1 minutes ago",
        "hour_ago": "1 hour ago",
        "hours_ago": "1 hours ago",
        "journey_label": "Journey",
        "submitted_on": "Submitted on:",
        "welcome_to_the_journey": "Welcome to the <Journey>",
        "not_answered": "Not answered",
        "yesterday": "Yesterday",
        "just_now": "Just now",
        "viewed": "Viewed",
        "archive_session_fail": "Archive session failed.",
        "no_images_to_download": "Images no longer available. They are either deleted by your admin or by your domain’s storage policy. Please contact your admin.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "downloading": "Downloading...",
        "reference_field_updated": "Reference field updated successfully.",
        "assignee_updated": "Assignee Updated.",
        "archive_session_success": "Session archived successfully."
    },
    "de_DE": {
        "user_all": "Alles",
        "session_active": "Aktiv",
        "session_upcoming": "Bevorstehende",
        "session_closed": "Geschlossen",
        "archived": "Archiviert",
        "no_image_text": "Für diese Sitzung sind keine Bilder verfügbar oder wurden möglicherweise von Ihrem Administrator gelöscht.",
        "loading_images": "Bilder laden ...",
        "user_cancel": "Stornieren",
        "delete": "Löschen",
        "download_key": "Herunterladen",
        "view_image_location": "Bildposition anzeigen",
        "time": "Zeit",
        "view_key": "Aussicht",
        "select": "Wählen",
        "unselect": "Auswahl aufheben",
        "none": "Keiner",
        "select_items": "Gegenstände auswählen",
        "select_all": "Wählen Sie Alle",
        "images": "Bilder",
        "delete_popup_imagecount": "<count> Bilder löschen?",
        "delete_imagepopup_text": "Möchten Sie die ausgewählten <count> Bilder unbedingt dauerhaft löschen?",
        "norecording_text": "Für diese Sitzung sind keine Aufzeichnungen verfügbar oder wurden möglicherweise von Ihrem Administrator gelöscht.",
        "locading_recordings": "Aufnahmen laden ...",
        "no_files_found": "Keine Dateien gefunden.",
        "file_name": "Dateiname",
        "created_on": "Erstellt am",
        "file_size": "Dateigröße",
        "play": "abspielen",
        "recording_processing": "Sie haben <count> -Aufzeichnungen, die vor dem Anzeigen verarbeitet werden müssen.",
        "single_pending_recording_label": "Sie haben eine <count> -Aufzeichnung, die vor dem Anzeigen verarbeitet werden muss.",
        "you_lable": "Sie",
        "delete_popup_recordingcount": "<count> Aufnahme (n) löschen?",
        "delete_recordingpopup_text": "Möchten Sie die ausgewählten <count> -Aufzeichnungen wirklich dauerhaft löschen?",
        "zipping_file": "Dateien komprimieren",
        "download_failed": "Herunterladen fehlgeschlagen",
        "successfully_downloaded": "erfolgreich heruntergeladen",
        "successfully_deleted": "Erfolgreich gelöscht",
        "files_available_text": "Für diese Sitzung sind keine Dateien verfügbar oder wurden möglicherweise von Ihrem Administrator gelöscht.",
        "close": "Schließen",
        "chat_key": "Plaudern",
        "extracted_text": "Extrahierter Text",
        "loading_files": "Lade Daten...",
        "delete_pupup_commontext": "Sie können diese Aktion nicht rückgängig machen.",
        "delete_filepopup_text": "Möchten Sie die ausgewählten <count> -Dateien wirklich dauerhaft löschen?",
        "delete_popup_filecount": "<count> Datei (en) löschen?",
        "hi": "Hallo,",
        "getstarted_newsession": "Erstellen Sie zunächst eine neue Sitzung.",
        "new_session": "Neue Sitzung",
        "responses": "Antworten",
        "details_key": "Einzelheiten",
        "note_lable": "Anmerkungen",
        "recordings": "Aufnahmen",
        "files_key": "Dateien",
        "edit_reference_text": "Referenztext bearbeiten",
        "save_button": "sparen",
        "numeric_characters_allowed": "Es sind nur numerische Zeichen zulässig.",
        "search_collaboration_profile": "Kooperationsprofil",
        "assigned_to_lable": "Zugewiesen an",
        "duration": "Dauer",
        "scheduled": "Geplant",
        "user_created": "Erstellt",
        "user_change": "Veränderung",
        "ends_on": "Endet am",
        "ended_key": "Beendet",
        "user_action": "Aktion",
        "copy_guest_url": "Gast-URL kopieren",
        "copy_observer_url": "Observer-URL kopieren",
        "invites_sent": "Lädt gesendet",
        "no_invites": "keine Einladungen",
        "archive_session": "Archivsitzung",
        "archive": "Archiv",
        "session_ended": "Sitzung beendet",
        "end_link": "Link beenden",
        "end_session": "Sitzung beenden",
        "end_btn": "Ende",
        "share": "Teilen",
        "invite": "Einladen",
        "open_link": "Verbindung öffnen",
        "start_session": "Sitzung starten",
        "start_btn": "Start",
        "join_session": "Sitzung beitreten",
        "join": "Beitreten",
        "join_as_guest": "Treten Sie als Gast bei",
        "join_as_observer": "Treten Sie als Beobachter bei",
        "archiving_session_hide_from_session_history": "Durch das Archivieren dieser Sitzung wird sie im Sitzungsverlauf ausgeblendet.",
        "this_cannot_be_undone": "Das kann nicht rückgängig gemacht werden.",
        "donnot_archive": "Nicht archivieren",
        "back": "Zurück",
        "crt_acnt_limit_character_msg_fifty": "Bis zu 50 Zeichen erlaubt.",
        "reference_required": "Bitte geben Sie Titel / Referenz ein.",
        "search": "Suche",
        "few_seconds_ago": "vor ein paar Sekunden",
        "minute_ago": "Vor 1 Minute",
        "minutes_ago": "Vor 1 Minuten",
        "hour_ago": "vor 1 Stunde",
        "hours_ago": "Vor 1 Stunde",
        "journey_label": "Reise",
        "submitted_on": "Eingereicht am:",
        "welcome_to_the_journey": "Willkommen auf der <Journey>",
        "not_answered": "Nicht beantwortet",
        "yesterday": "Gestern",
        "just_now": "Gerade jetzt",
        "viewed": "Gesehen",
        "archive_session_fail": "Archivierungssitzung fehlgeschlagen.",
        "no_images_to_download": "Bilder nicht mehr verfügbar. Sie werden entweder von Ihrem Administrator oder von der Speicherrichtlinie Ihrer Domain gelöscht. Bitte wenden Sie sich an Ihren Administrator.",
        "observer_url_copied": "Beobachter-URL in Zwischenablage kopiert.",
        "guest_url_copy": "Gast-URL in die Zwischenablage kopiert.",
        "downloading": "Wird heruntergeladen...",
        "reference_field_updated": "Referenzfeld erfolgreich aktualisiert.",
        "assignee_updated": "Beauftragter aktualisiert.",
        "archive_session_success": "Sitzung erfolgreich archiviert."
    },
    "en_US": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "no_image_text": "No Images available for this session or may have been deleted by your Admin.",
        "loading_images": "Loading images...",
        "user_cancel": "Cancel",
        "delete": "Delete",
        "download_key": "Download",
        "view_image_location": "View Image Location",
        "time": "Time",
        "view_key": "View",
        "select": "Select",
        "unselect": "Unselect",
        "none": "None",
        "select_items": "Select Items",
        "select_all": "Select All",
        "images": "Images",
        "delete_popup_imagecount": "Delete <count> Image(s)?",
        "delete_imagepopup_text": "Are you sure you want to permanently delete the <count> selected image(s)?",
        "norecording_text": "No Recordings available for this session or may have been deleted by your Admin.",
        "locading_recordings": "Loading recordings...",
        "no_files_found": "No files found.",
        "file_name": "File Name",
        "created_on": "Created on",
        "file_size": "File Size",
        "play": "Play",
        "recording_processing": "You have <count> recordings that need to be processed before viewing.",
        "single_pending_recording_label": "You have <count> recording that needs to be processed before viewing.",
        "you_lable": "You",
        "delete_popup_recordingcount": "Delete <count> Recording(s)?",
        "delete_recordingpopup_text": "Are you sure you want to permanently delete the <count> selected recording(s)?",
        "zipping_file": "Zipping files",
        "download_failed": "Download failed",
        "successfully_downloaded": "Successfully downloaded",
        "successfully_deleted": "Successfully deleted",
        "files_available_text": "No files available for this session or may have been deleted by your Admin.",
        "close": "Close",
        "chat_key": "Chat",
        "extracted_text": "Extracted text",
        "loading_files": "Loading files...",
        "delete_pupup_commontext": "You can't undo this action.",
        "delete_filepopup_text": "Are you sure you want to permanently delete the <count> selected file(s)?",
        "delete_popup_filecount": "Delete <count> File(s)?",
        "hi": "Hi,",
        "getstarted_newsession": "Get started by creating a new session below.",
        "new_session": "New Session",
        "responses": "Responses",
        "details_key": "Details",
        "note_lable": "Notes",
        "recordings": "Recordings",
        "files_key": "Files",
        "edit_reference_text": "Edit Reference Text",
        "save_button": "Save",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "search_collaboration_profile": "Collaboration Profile",
        "assigned_to_lable": "Assigned to",
        "duration": "Duration",
        "scheduled": "Scheduled",
        "user_created": "Created",
        "user_change": "Change",
        "ends_on": "Ends On",
        "ended_key": "Ended",
        "user_action": "Action",
        "copy_guest_url": "Copy guest URL",
        "copy_observer_url": "Copy Observer URL",
        "invites_sent": "Invites Sent",
        "no_invites": "No Invites",
        "archive_session": "Archive Session",
        "archive": "Archive",
        "session_ended": "Session ended",
        "end_link": "End Link",
        "end_session": "End Session",
        "end_btn": "End",
        "share": "Share",
        "invite": "Invite",
        "open_link": "Open Link",
        "start_session": "Start Session",
        "start_btn": "Start",
        "join_session": "Join Session",
        "join": "Join",
        "join_as_guest": "Join as Guest",
        "join_as_observer": "Join as Observer",
        "archiving_session_hide_from_session_history": "Archiving this session will hide it from session history.",
        "this_cannot_be_undone": "This cannot be undone.",
        "donnot_archive": "Don't archive",
        "back": "Back",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "reference_required": "Please enter Title/Reference.",
        "search": "Search",
        "few_seconds_ago": "a few seconds ago",
        "minute_ago": "1 minute ago",
        "minutes_ago": "1 minutes ago",
        "hour_ago": "1 hour ago",
        "hours_ago": "1 hours ago",
        "journey_label": "Journey",
        "submitted_on": "Submitted on:",
        "welcome_to_the_journey": "Welcome to the <Journey>",
        "not_answered": "Not answered",
        "yesterday": "Yesterday",
        "just_now": "Just now",
        "viewed": "Viewed",
        "archive_session_fail": "Archive session failed.",
        "no_images_to_download": "Images no longer available. They are either deleted by your admin or by your domain’s storage policy. Please contact your admin.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "downloading": "Downloading...",
        "reference_field_updated": "Reference field updated successfully.",
        "assignee_updated": "Assignee Updated.",
        "archive_session_success": "Session archived successfully."
    },
    "en_AU": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "no_image_text": "No Images available for this session or may have been deleted by your Admin.",
        "loading_images": "Loading images...",
        "user_cancel": "Cancel",
        "delete": "Delete",
        "download_key": "Download",
        "view_image_location": "View Image Location",
        "time": "Time",
        "view_key": "View",
        "select": "Select",
        "unselect": "Unselect",
        "none": "None",
        "select_items": "Select Items",
        "select_all": "Select All",
        "images": "Images",
        "delete_popup_imagecount": "Delete <count> Image(s)?",
        "delete_imagepopup_text": "Are you sure you want to permanently delete the <count> selected image(s)?",
        "norecording_text": "No Recordings available for this session or may have been deleted by your Admin.",
        "locading_recordings": "Loading recordings...",
        "no_files_found": "No files found.",
        "file_name": "File Name",
        "created_on": "Created on",
        "file_size": "File Size",
        "play": "Play",
        "recording_processing": "You have <count> recordings that need to be processed before viewing.",
        "single_pending_recording_label": "You have <count> recording that needs to be processed before viewing.",
        "you_lable": "You",
        "delete_popup_recordingcount": "Delete <count> Recording(s)?",
        "delete_recordingpopup_text": "Are you sure you want to permanently delete the <count> selected recording(s)?",
        "zipping_file": "Zipping files",
        "download_failed": "Download failed",
        "successfully_downloaded": "Successfully downloaded",
        "successfully_deleted": "Successfully deleted",
        "files_available_text": "No files available for this session or may have been deleted by your Admin.",
        "close": "Close",
        "chat_key": "Chat",
        "extracted_text": "Extracted text",
        "loading_files": "Loading files...",
        "delete_pupup_commontext": "You can't undo this action.",
        "delete_filepopup_text": "Are you sure you want to permanently delete the <count> selected file(s)?",
        "delete_popup_filecount": "Delete <count> File(s)?",
        "hi": "Hi,",
        "getstarted_newsession": "Get started by creating a new session below.",
        "new_session": "New Session",
        "responses": "Responses",
        "details_key": "Details",
        "note_lable": "Notes",
        "recordings": "Recordings",
        "files_key": "Files",
        "edit_reference_text": "Edit Reference Text",
        "save_button": "Save",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "search_collaboration_profile": "Collaboration Profile",
        "assigned_to_lable": "Assigned to",
        "duration": "Duration",
        "scheduled": "Scheduled",
        "user_created": "Created",
        "user_change": "Change",
        "ends_on": "Ends On",
        "ended_key": "Ended",
        "user_action": "Action",
        "copy_guest_url": "Copy guest URL",
        "copy_observer_url": "Copy Observer URL",
        "invites_sent": "Invites Sent",
        "no_invites": "No Invites",
        "archive_session": "Archive Session",
        "archive": "Archive",
        "session_ended": "Session ended",
        "end_link": "End Link",
        "end_session": "End Session",
        "end_btn": "End",
        "share": "Share",
        "invite": "Invite",
        "open_link": "Open Link",
        "start_session": "Start Session",
        "start_btn": "Start",
        "join_session": "Join Session",
        "join": "Join",
        "join_as_guest": "Join as Guest",
        "join_as_observer": "Join as Observer",
        "archiving_session_hide_from_session_history": "Archiving this session will hide it from session history.",
        "this_cannot_be_undone": "This cannot be undone.",
        "donnot_archive": "Don't archive",
        "back": "Back",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "reference_required": "Please enter Title/Reference.",
        "search": "Search",
        "few_seconds_ago": "a few seconds ago",
        "minute_ago": "1 minute ago",
        "minutes_ago": "1 minutes ago",
        "hour_ago": "1 hour ago",
        "hours_ago": "1 hours ago",
        "journey_label": "Journey",
        "submitted_on": "Submitted on:",
        "welcome_to_the_journey": "Welcome to the <Journey>",
        "not_answered": "Not answered",
        "yesterday": "Yesterday",
        "just_now": "Just now",
        "viewed": "Viewed",
        "archive_session_fail": "Archive session failed.",
        "no_images_to_download": "Images no longer available. They are either deleted by your admin or by your domain’s storage policy. Please contact your admin.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "downloading": "Downloading...",
        "reference_field_updated": "Reference field updated successfully.",
        "assignee_updated": "Assignee Updated.",
        "archive_session_success": "Session archived successfully."
    },
    "es_ES": {
        "user_all": "Todos",
        "session_active": "Activo",
        "session_upcoming": "Próximamente",
        "session_closed": "Cerrado",
        "archived": "Archivado",
        "no_image_text": "No hay imágenes disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "loading_images": "Cargando imágenes ...",
        "user_cancel": "Cancelar",
        "delete": "Eliminar",
        "download_key": "Descargar",
        "view_image_location": "Ver ubicación de la imagen",
        "time": "Hora",
        "view_key": "Ver",
        "select": "Seleccione",
        "unselect": "Deseleccionar",
        "none": "Ninguna",
        "select_items": "Seleccionar articulos",
        "select_all": "Seleccionar todo",
        "images": "Imagenes",
        "delete_popup_imagecount": "¿Eliminar <count> imagen (s)?",
        "delete_imagepopup_text": "¿Está seguro de que desea eliminar permanentemente las <count> imágenes seleccionadas?",
        "norecording_text": "No hay grabaciones disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "locading_recordings": "Cargando grabaciones ...",
        "no_files_found": "No se encontraron archivos.",
        "file_name": "Nombre del archivo",
        "created_on": "Creado en",
        "file_size": "Tamaño del archivo",
        "play": "Tocar",
        "recording_processing": "Tiene <count> grabaciones que deben procesarse antes de verlas.",
        "single_pending_recording_label": "Tiene una <count> que debe procesarse antes de verla.",
        "you_lable": "usted",
        "delete_popup_recordingcount": "¿Borrar <count> grabación (es)?",
        "delete_recordingpopup_text": "¿Está seguro de que desea eliminar permanentemente las <count> grabaciones seleccionadas?",
        "zipping_file": "Comprimir archivos",
        "download_failed": "Descarga fracasó",
        "successfully_downloaded": "descargado con éxito",
        "successfully_deleted": "Eliminado con éxito",
        "files_available_text": "No hay archivos disponibles para esta sesión o su administrador puede haberlos eliminado.",
        "close": "Cerrar",
        "chat_key": "Charla",
        "extracted_text": "Texto extraído",
        "loading_files": "Cargando archivos ...",
        "delete_pupup_commontext": "No puede deshacer esta acción.",
        "delete_filepopup_text": "¿Está seguro de que desea eliminar de forma permanente los archivos seleccionados <count> ?",
        "delete_popup_filecount": "¿Eliminar <count> archivo (s)?",
        "hi": "Hola,",
        "getstarted_newsession": "Comience creando una nueva sesión a continuación.",
        "new_session": "Nueva sesión",
        "responses": "Respuestas",
        "details_key": "Detalles",
        "note_lable": "Notas",
        "recordings": "Grabaciones",
        "files_key": "Archivos",
        "edit_reference_text": "Editar texto de referencia",
        "save_button": "Salvar",
        "numeric_characters_allowed": "Solo se permiten caracteres numéricos.",
        "search_collaboration_profile": "Perfil de colaboración",
        "assigned_to_lable": "Asignado a",
        "duration": "Duración",
        "scheduled": "Programado",
        "user_created": "Creado",
        "user_change": "Cambio",
        "ends_on": "Termina el",
        "ended_key": "Terminó",
        "user_action": "Acción",
        "copy_guest_url": "Copiar URL de invitado",
        "copy_observer_url": "Copiar URL del observador",
        "invites_sent": "Invitaciones enviadas",
        "no_invites": "No hay invitaciones",
        "archive_session": "Sesión de archivo",
        "archive": "Archivo",
        "session_ended": "sesión terminó",
        "end_link": "Enlace final",
        "end_session": "Sesión final",
        "end_btn": "Final",
        "share": "Cuota",
        "invite": "Invitación",
        "open_link": "Enlace abierto",
        "start_session": "Inicio de sesión",
        "start_btn": "comienzo",
        "join_session": "Unirse a la sesión",
        "join": "Unirse",
        "join_as_guest": "Únete como invitado",
        "join_as_observer": "Únete como observador",
        "archiving_session_hide_from_session_history": "Archivar esta sesión la ocultará del historial de sesiones.",
        "this_cannot_be_undone": "Esto no se puede deshacer.",
        "donnot_archive": "No archivar",
        "back": "atrás",
        "crt_acnt_limit_character_msg_fifty": "Hasta 50 caracteres permitidos.",
        "reference_required": "Por favor, introduzca el título / referencia.",
        "search": "Buscar",
        "few_seconds_ago": "hace unos segundos",
        "minute_ago": "Hace 1 minuto",
        "minutes_ago": "Hace 1 minutos",
        "hour_ago": "1 hora antes",
        "hours_ago": "Hace 1 hora",
        "journey_label": "Viaje",
        "submitted_on": "Enviado el:",
        "welcome_to_the_journey": "Bienvenido al <Journey>",
        "not_answered": "No contestado",
        "yesterday": "Ayer",
        "just_now": "Justo ahora",
        "viewed": "Visto",
        "archive_session_fail": "La sesión de archivo falló.",
        "no_images_to_download": "Las imágenes ya no están disponibles. Son eliminados por su administrador o por la política de almacenamiento de su dominio. Comuníquese con su administrador.",
        "observer_url_copied": "URL del observador copiada al portapapeles.",
        "guest_url_copy": "Invitado URL copiado al portapapeles.",
        "downloading": "La descarga de ...",
        "reference_field_updated": "El campo de referencia se actualizó correctamente.",
        "assignee_updated": "Cesionario actualizado.",
        "archive_session_success": "Sesión archivada con éxito."
    },
    "es_MX": {
        "user_all": "Todos",
        "session_active": "Activo",
        "session_upcoming": "Próximamente",
        "session_closed": "Cerrado",
        "archived": "Archivado",
        "no_image_text": "No hay imágenes disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "loading_images": "Cargando imágenes ...",
        "user_cancel": "Cancelar",
        "delete": "Eliminar",
        "download_key": "Descargar",
        "view_image_location": "Ver ubicación de la imagen",
        "time": "Hora",
        "view_key": "Ver",
        "select": "Seleccione",
        "unselect": "Deseleccionar",
        "none": "Ninguna",
        "select_items": "Seleccionar articulos",
        "select_all": "Seleccionar todo",
        "images": "Imagenes",
        "delete_popup_imagecount": "¿Eliminar <count> imagen (s)?",
        "delete_imagepopup_text": "¿Está seguro de que desea eliminar permanentemente las <count> imágenes seleccionadas?",
        "norecording_text": "No hay grabaciones disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "locading_recordings": "Cargando grabaciones ...",
        "no_files_found": "No se encontraron archivos.",
        "file_name": "Nombre del archivo",
        "created_on": "Creado en",
        "file_size": "Tamaño del archivo",
        "play": "Tocar",
        "recording_processing": "Tiene <count> grabaciones que deben procesarse antes de verlas.",
        "single_pending_recording_label": "Tiene una <count> que debe procesarse antes de verla.",
        "you_lable": "usted",
        "delete_popup_recordingcount": "¿Borrar <count> grabación (es)?",
        "delete_recordingpopup_text": "¿Está seguro de que desea eliminar permanentemente las <count> grabaciones seleccionadas?",
        "zipping_file": "Comprimir archivos",
        "download_failed": "Descarga fracasó",
        "successfully_downloaded": "descargado con éxito",
        "successfully_deleted": "Eliminado con éxito",
        "files_available_text": "No hay archivos disponibles para esta sesión o su administrador puede haberlos eliminado.",
        "close": "Cerrar",
        "chat_key": "Charla",
        "extracted_text": "Texto extraído",
        "loading_files": "Cargando archivos ...",
        "delete_pupup_commontext": "No puede deshacer esta acción.",
        "delete_filepopup_text": "¿Está seguro de que desea eliminar de forma permanente los archivos seleccionados <count> ?",
        "delete_popup_filecount": "¿Eliminar <count> archivo (s)?",
        "hi": "Hola,",
        "getstarted_newsession": "Comience creando una nueva sesión a continuación.",
        "new_session": "Nueva sesión",
        "responses": "Respuestas",
        "details_key": "Detalles",
        "note_lable": "Notas",
        "recordings": "Grabaciones",
        "files_key": "Archivos",
        "edit_reference_text": "Editar texto de referencia",
        "save_button": "Salvar",
        "numeric_characters_allowed": "Solo se permiten caracteres numéricos.",
        "search_collaboration_profile": "Perfil de colaboración",
        "assigned_to_lable": "Asignado a",
        "duration": "Duración",
        "scheduled": "Programado",
        "user_created": "Creado",
        "user_change": "Cambio",
        "ends_on": "Termina el",
        "ended_key": "Terminó",
        "user_action": "Acción",
        "copy_guest_url": "Copiar URL de invitado",
        "copy_observer_url": "Copiar URL del observador",
        "invites_sent": "Invitaciones enviadas",
        "no_invites": "No hay invitaciones",
        "archive_session": "Sesión de archivo",
        "archive": "Archivo",
        "session_ended": "sesión terminó",
        "end_link": "Enlace final",
        "end_session": "Sesión final",
        "end_btn": "Final",
        "share": "Cuota",
        "invite": "Invitación",
        "open_link": "Enlace abierto",
        "start_session": "Inicio de sesión",
        "start_btn": "comienzo",
        "join_session": "Unirse a la sesión",
        "join": "Unirse",
        "join_as_guest": "Únete como invitado",
        "join_as_observer": "Únete como observador",
        "archiving_session_hide_from_session_history": "Archivar esta sesión la ocultará del historial de sesiones.",
        "this_cannot_be_undone": "Esto no se puede deshacer.",
        "donnot_archive": "No archivar",
        "back": "atrás",
        "crt_acnt_limit_character_msg_fifty": "Hasta 50 caracteres permitidos.",
        "reference_required": "Por favor, introduzca el título / referencia.",
        "search": "Buscar",
        "few_seconds_ago": "hace unos segundos",
        "minute_ago": "Hace 1 minuto",
        "minutes_ago": "Hace 1 minutos",
        "hour_ago": "1 hora antes",
        "hours_ago": "Hace 1 hora",
        "journey_label": "Viaje",
        "submitted_on": "Enviado el:",
        "welcome_to_the_journey": "Bienvenido al <Journey>",
        "not_answered": "No contestado",
        "yesterday": "Ayer",
        "just_now": "Justo ahora",
        "viewed": "Visto",
        "archive_session_fail": "La sesión de archivo falló.",
        "no_images_to_download": "Las imágenes ya no están disponibles. Son eliminados por su administrador o por la política de almacenamiento de su dominio. Comuníquese con su administrador.",
        "observer_url_copied": "URL del observador copiada al portapapeles.",
        "guest_url_copy": "Invitado URL copiado al portapapeles.",
        "downloading": "La descarga de ...",
        "reference_field_updated": "El campo de referencia se actualizó correctamente.",
        "assignee_updated": "Cesionario actualizado.",
        "archive_session_success": "Sesión archivada con éxito."
    },
    "es_AR": {
        "user_all": "Todos",
        "session_active": "Activo",
        "session_upcoming": "Próximamente",
        "session_closed": "Cerrado",
        "archived": "Archivado",
        "no_image_text": "No hay imágenes disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "loading_images": "Cargando imágenes ...",
        "user_cancel": "Cancelar",
        "delete": "Eliminar",
        "download_key": "Descargar",
        "view_image_location": "Ver ubicación de la imagen",
        "time": "Hora",
        "view_key": "Ver",
        "select": "Seleccione",
        "unselect": "Deseleccionar",
        "none": "Ninguna",
        "select_items": "Seleccionar articulos",
        "select_all": "Seleccionar todo",
        "images": "Imagenes",
        "delete_popup_imagecount": "¿Eliminar <count> imagen (s)?",
        "delete_imagepopup_text": "¿Está seguro de que desea eliminar permanentemente las <count> imágenes seleccionadas?",
        "norecording_text": "No hay grabaciones disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "locading_recordings": "Cargando grabaciones ...",
        "no_files_found": "No se encontraron archivos.",
        "file_name": "Nombre del archivo",
        "created_on": "Creado en",
        "file_size": "Tamaño del archivo",
        "play": "Tocar",
        "recording_processing": "Tiene <count> grabaciones que deben procesarse antes de verlas.",
        "single_pending_recording_label": "Tiene una <count> que debe procesarse antes de verla.",
        "you_lable": "usted",
        "delete_popup_recordingcount": "¿Borrar <count> grabación (es)?",
        "delete_recordingpopup_text": "¿Está seguro de que desea eliminar permanentemente las <count> grabaciones seleccionadas?",
        "zipping_file": "Comprimir archivos",
        "download_failed": "Descarga fracasó",
        "successfully_downloaded": "descargado con éxito",
        "successfully_deleted": "Eliminado con éxito",
        "files_available_text": "No hay archivos disponibles para esta sesión o su administrador puede haberlos eliminado.",
        "close": "Cerrar",
        "chat_key": "Charla",
        "extracted_text": "Texto extraído",
        "loading_files": "Cargando archivos ...",
        "delete_pupup_commontext": "No puede deshacer esta acción.",
        "delete_filepopup_text": "¿Está seguro de que desea eliminar de forma permanente los archivos seleccionados <count> ?",
        "delete_popup_filecount": "¿Eliminar <count> archivo (s)?",
        "hi": "Hola,",
        "getstarted_newsession": "Comience creando una nueva sesión a continuación.",
        "new_session": "Nueva sesión",
        "responses": "Respuestas",
        "details_key": "Detalles",
        "note_lable": "Notas",
        "recordings": "Grabaciones",
        "files_key": "Archivos",
        "edit_reference_text": "Editar texto de referencia",
        "save_button": "Salvar",
        "numeric_characters_allowed": "Solo se permiten caracteres numéricos.",
        "search_collaboration_profile": "Perfil de colaboración",
        "assigned_to_lable": "Asignado a",
        "duration": "Duración",
        "scheduled": "Programado",
        "user_created": "Creado",
        "user_change": "Cambio",
        "ends_on": "Termina el",
        "ended_key": "Terminó",
        "user_action": "Acción",
        "copy_guest_url": "Copiar URL de invitado",
        "copy_observer_url": "Copiar URL del observador",
        "invites_sent": "Invitaciones enviadas",
        "no_invites": "No hay invitaciones",
        "archive_session": "Sesión de archivo",
        "archive": "Archivo",
        "session_ended": "sesión terminó",
        "end_link": "Enlace final",
        "end_session": "Sesión final",
        "end_btn": "Final",
        "share": "Cuota",
        "invite": "Invitación",
        "open_link": "Enlace abierto",
        "start_session": "Inicio de sesión",
        "start_btn": "comienzo",
        "join_session": "Unirse a la sesión",
        "join": "Unirse",
        "join_as_guest": "Únete como invitado",
        "join_as_observer": "Únete como observador",
        "archiving_session_hide_from_session_history": "Archivar esta sesión la ocultará del historial de sesiones.",
        "this_cannot_be_undone": "Esto no se puede deshacer.",
        "donnot_archive": "No archivar",
        "back": "atrás",
        "crt_acnt_limit_character_msg_fifty": "Hasta 50 caracteres permitidos.",
        "reference_required": "Por favor, introduzca el título / referencia.",
        "search": "Buscar",
        "few_seconds_ago": "hace unos segundos",
        "minute_ago": "Hace 1 minuto",
        "minutes_ago": "Hace 1 minutos",
        "hour_ago": "1 hora antes",
        "hours_ago": "Hace 1 hora",
        "journey_label": "Viaje",
        "submitted_on": "Enviado el:",
        "welcome_to_the_journey": "Bienvenido al <Journey>",
        "not_answered": "No contestado",
        "yesterday": "Ayer",
        "just_now": "Justo ahora",
        "viewed": "Visto",
        "archive_session_fail": "La sesión de archivo falló.",
        "no_images_to_download": "Las imágenes ya no están disponibles. Son eliminados por su administrador o por la política de almacenamiento de su dominio. Comuníquese con su administrador.",
        "observer_url_copied": "URL del observador copiada al portapapeles.",
        "guest_url_copy": "Invitado URL copiado al portapapeles.",
        "downloading": "La descarga de ...",
        "reference_field_updated": "El campo de referencia se actualizó correctamente.",
        "assignee_updated": "Cesionario actualizado.",
        "archive_session_success": "Sesión archivada con éxito."
    },
    "fr_FR": {
        "user_all": "Tout",
        "session_active": "actif",
        "session_upcoming": "A venir",
        "session_closed": "Fermé",
        "archived": "Archivé",
        "no_image_text": "Aucune image disponible pour cette session ou peut avoir été supprimée par votre administrateur.",
        "loading_images": "Chargement des images ...",
        "user_cancel": "Annuler",
        "delete": "Effacer",
        "download_key": "Télécharger",
        "view_image_location": "Afficher l'emplacement de l'image",
        "time": "Temps",
        "view_key": "Vue",
        "select": "Sélectionner",
        "unselect": "Désélectionner",
        "none": "Aucun",
        "select_items": "Sélectionner des éléments",
        "select_all": "Tout sélectionner",
        "images": "Images",
        "delete_popup_imagecount": "Supprimer <count> image (s)?",
        "delete_imagepopup_text": "Voulez-vous vraiment supprimer définitivement les <count> images sélectionnées?",
        "norecording_text": "Aucun enregistrement disponible pour cette session ou peut avoir été supprimé par votre administrateur.",
        "locading_recordings": "Chargement des enregistrements ...",
        "no_files_found": "Aucun fichier trouvé.",
        "file_name": "Nom de fichier",
        "created_on": "Créé sur",
        "file_size": "Taille du fichier",
        "play": "Jouer",
        "recording_processing": "Vous avez <count> enregistrements qui doivent être traités avant d'être visionnés.",
        "single_pending_recording_label": "Vous avez <count> enregistrements qui doivent être traités avant de les visionner.",
        "you_lable": "Toi",
        "delete_popup_recordingcount": "Supprimer <count> enregistrement (s)?",
        "delete_recordingpopup_text": "Voulez-vous vraiment supprimer définitivement le (s) enregistrement (s) sélectionné (s) <count> ?",
        "zipping_file": "Zipper des fichiers",
        "download_failed": "Échec du téléchargement",
        "successfully_downloaded": "avec succès téléchargé",
        "successfully_deleted": "Supprimé avec succès",
        "files_available_text": "Aucun fichier disponible pour cette session ou peut avoir été supprimé par votre administrateur.",
        "close": "Fermer",
        "chat_key": "Bavarder",
        "extracted_text": "Texte extrait",
        "loading_files": "Chargement des fichiers ...",
        "delete_pupup_commontext": "Vous ne pouvez pas annuler cette action.",
        "delete_filepopup_text": "Voulez-vous vraiment supprimer définitivement le (s) fichier (s) sélectionné (s) <count> ?",
        "delete_popup_filecount": "Supprimer le (s) fichier (s) <count> ?",
        "hi": "Salut,",
        "getstarted_newsession": "Commencez par créer une nouvelle session ci-dessous.",
        "new_session": "Nouvelle session",
        "responses": "Réponses",
        "details_key": "Détails",
        "note_lable": "Remarques",
        "recordings": "Enregistrements",
        "files_key": "Des dossiers",
        "edit_reference_text": "Modifier le texte de référence",
        "save_button": "sauver",
        "numeric_characters_allowed": "Seuls les caractères numériques sont autorisés.",
        "search_collaboration_profile": "Profil collaboratif",
        "assigned_to_lable": "Assigné à",
        "duration": "Durée",
        "scheduled": "Programmé",
        "user_created": "Créé",
        "user_change": "Changement",
        "ends_on": "Se termine le",
        "ended_key": "Terminé",
        "user_action": "action",
        "copy_guest_url": "Copier l'URL de l'invité",
        "copy_observer_url": "Copier l'URL de l'observateur",
        "invites_sent": "Invitations envoyées",
        "no_invites": "Aucune invitation",
        "archive_session": "Session d'archivage",
        "archive": "Archiver",
        "session_ended": "session a pris fin",
        "end_link": "Fin du lien",
        "end_session": "fin de session",
        "end_btn": "Fin",
        "share": "Partager",
        "invite": "Inviter",
        "open_link": "Ouvrir le lien",
        "start_session": "session",
        "start_btn": "Début",
        "join_session": "Rejoindre la session",
        "join": "Joindre",
        "join_as_guest": "Rejoindre en tant qu'invité",
        "join_as_observer": "Rejoindre en tant qu'observateur",
        "archiving_session_hide_from_session_history": "L'archivage de cette session la masquera de l'historique de session.",
        "this_cannot_be_undone": "Ça ne peut pas être annulé.",
        "donnot_archive": "Ne pas archiver",
        "back": "Retour",
        "crt_acnt_limit_character_msg_fifty": "Jusqu'à 50 caractères autorisés.",
        "reference_required": "Veuillez saisir le titre / la référence.",
        "search": "Chercher",
        "few_seconds_ago": "il ya quelques secondes",
        "minute_ago": "il y a 1 minute",
        "minutes_ago": "Il y a 1 minute",
        "hour_ago": "Il ya 1 heure",
        "hours_ago": "Il y a 1 heure",
        "journey_label": "Voyage",
        "submitted_on": "Soumis le :",
        "welcome_to_the_journey": "Bienvenue dans le <Journey>",
        "not_answered": "Non répondu",
        "yesterday": "Hier",
        "just_now": "Juste maintenant",
        "viewed": "Vu",
        "archive_session_fail": "La session d'archivage a échoué.",
        "no_images_to_download": "Les images ne sont plus disponibles. Ils sont supprimés par votre administrateur ou par la politique de stockage de votre domaine. Veuillez contacter votre administrateur.",
        "observer_url_copied": "URL d'observateur copiée dans le presse-papiers.",
        "guest_url_copy": "URL d'invité copiée dans le Presse-papiers.",
        "downloading": "Téléchargement...",
        "reference_field_updated": "Champ de référence mis à jour avec succès.",
        "assignee_updated": "Destinataire mis à jour.",
        "archive_session_success": "La session a été archivée avec succès."
    },
    "en_CA": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "no_image_text": "No Images available for this session or may have been deleted by your Admin.",
        "loading_images": "Loading images...",
        "user_cancel": "Cancel",
        "delete": "Delete",
        "download_key": "Download",
        "view_image_location": "View Image Location",
        "time": "Time",
        "view_key": "View",
        "select": "Select",
        "unselect": "Unselect",
        "none": "None",
        "select_items": "Select Items",
        "select_all": "Select All",
        "images": "Images",
        "delete_popup_imagecount": "Delete <count> Image(s)?",
        "delete_imagepopup_text": "Are you sure you want to permanently delete the <count> selected image(s)?",
        "norecording_text": "No Recordings available for this session or may have been deleted by your Admin.",
        "locading_recordings": "Loading recordings...",
        "no_files_found": "No files found.",
        "file_name": "File Name",
        "created_on": "Created on",
        "file_size": "File Size",
        "play": "Play",
        "recording_processing": "You have <count> recordings that need to be processed before viewing.",
        "single_pending_recording_label": "You have <count> recording that needs to be processed before viewing.",
        "you_lable": "You",
        "delete_popup_recordingcount": "Delete <count> Recording(s)?",
        "delete_recordingpopup_text": "Are you sure you want to permanently delete the <count> selected recording(s)?",
        "zipping_file": "Zipping files",
        "download_failed": "Download failed",
        "successfully_downloaded": "Successfully downloaded",
        "successfully_deleted": "Successfully deleted",
        "files_available_text": "No files available for this session or may have been deleted by your Admin.",
        "close": "Close",
        "chat_key": "Chat",
        "extracted_text": "Extracted text",
        "loading_files": "Loading files...",
        "delete_pupup_commontext": "You can't undo this action.",
        "delete_filepopup_text": "Are you sure you want to permanently delete the <count> selected file(s)?",
        "delete_popup_filecount": "Delete <count> File(s)?",
        "hi": "Hi,",
        "getstarted_newsession": "Get started by creating a new session below.",
        "new_session": "New Session",
        "responses": "Responses",
        "details_key": "Details",
        "note_lable": "Notes",
        "recordings": "Recordings",
        "files_key": "Files",
        "edit_reference_text": "Edit Reference Text",
        "save_button": "Save",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "search_collaboration_profile": "Collaboration Profile",
        "assigned_to_lable": "Assigned to",
        "duration": "Duration",
        "scheduled": "Scheduled",
        "user_created": "Created",
        "user_change": "Change",
        "ends_on": "Ends On",
        "ended_key": "Ended",
        "user_action": "Action",
        "copy_guest_url": "Copy guest URL",
        "copy_observer_url": "Copy Observer URL",
        "invites_sent": "Invites Sent",
        "no_invites": "No Invites",
        "archive_session": "Archive Session",
        "archive": "Archive",
        "session_ended": "Session ended",
        "end_link": "End Link",
        "end_session": "End Session",
        "end_btn": "End",
        "share": "Share",
        "invite": "Invite",
        "open_link": "Open Link",
        "start_session": "Start Session",
        "start_btn": "Start",
        "join_session": "Join Session",
        "join": "Join",
        "join_as_guest": "Join as Guest",
        "join_as_observer": "Join as Observer",
        "archiving_session_hide_from_session_history": "Archiving this session will hide it from session history.",
        "this_cannot_be_undone": "This cannot be undone.",
        "donnot_archive": "Don't archive",
        "back": "Back",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "reference_required": "Please enter Title/Reference.",
        "search": "Search",
        "few_seconds_ago": "a few seconds ago",
        "minute_ago": "1 minute ago",
        "minutes_ago": "1 minutes ago",
        "hour_ago": "1 hour ago",
        "hours_ago": "1 hours ago",
        "journey_label": "Journey",
        "submitted_on": "Submitted on:",
        "welcome_to_the_journey": "Welcome to the <Journey>",
        "not_answered": "Not answered",
        "yesterday": "Yesterday",
        "just_now": "Just now",
        "viewed": "Viewed",
        "archive_session_fail": "Archive session failed.",
        "no_images_to_download": "Images no longer available. They are either deleted by your admin or by your domain’s storage policy. Please contact your admin.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "downloading": "Downloading...",
        "reference_field_updated": "Reference field updated successfully.",
        "assignee_updated": "Assignee Updated.",
        "archive_session_success": "Session archived successfully."
    },
    "fr_CA": {
        "user_all": "Tout",
        "session_active": "actif",
        "session_upcoming": "A venir",
        "session_closed": "Fermé",
        "archived": "Archivé",
        "no_image_text": "Aucune image disponible pour cette session ou peut avoir été supprimée par votre administrateur.",
        "loading_images": "Chargement des images ...",
        "user_cancel": "Annuler",
        "delete": "Effacer",
        "download_key": "Télécharger",
        "view_image_location": "Afficher l'emplacement de l'image",
        "time": "Temps",
        "view_key": "Vue",
        "select": "Sélectionner",
        "unselect": "Désélectionner",
        "none": "Aucun",
        "select_items": "Sélectionner des éléments",
        "select_all": "Tout sélectionner",
        "images": "Images",
        "delete_popup_imagecount": "Supprimer <count> image (s)?",
        "delete_imagepopup_text": "Voulez-vous vraiment supprimer définitivement les <count> images sélectionnées?",
        "norecording_text": "Aucun enregistrement disponible pour cette session ou peut avoir été supprimé par votre administrateur.",
        "locading_recordings": "Chargement des enregistrements ...",
        "no_files_found": "Aucun fichier trouvé.",
        "file_name": "Nom de fichier",
        "created_on": "Créé sur",
        "file_size": "Taille du fichier",
        "play": "Jouer",
        "recording_processing": "Vous avez <count> enregistrements qui doivent être traités avant d'être visionnés.",
        "single_pending_recording_label": "Vous avez <count> enregistrements qui doivent être traités avant de les visionner.",
        "you_lable": "Toi",
        "delete_popup_recordingcount": "Supprimer <count> enregistrement (s)?",
        "delete_recordingpopup_text": "Voulez-vous vraiment supprimer définitivement le (s) enregistrement (s) sélectionné (s) <count> ?",
        "zipping_file": "Zipper des fichiers",
        "download_failed": "Échec du téléchargement",
        "successfully_downloaded": "avec succès téléchargé",
        "successfully_deleted": "Supprimé avec succès",
        "files_available_text": "Aucun fichier disponible pour cette session ou peut avoir été supprimé par votre administrateur.",
        "close": "Fermer",
        "chat_key": "Bavarder",
        "extracted_text": "Texte extrait",
        "loading_files": "Chargement des fichiers ...",
        "delete_pupup_commontext": "Vous ne pouvez pas annuler cette action.",
        "delete_filepopup_text": "Voulez-vous vraiment supprimer définitivement le (s) fichier (s) sélectionné (s) <count> ?",
        "delete_popup_filecount": "Supprimer le (s) fichier (s) <count> ?",
        "hi": "Salut,",
        "getstarted_newsession": "Commencez par créer une nouvelle session ci-dessous.",
        "new_session": "Nouvelle session",
        "responses": "Réponses",
        "details_key": "Détails",
        "note_lable": "Remarques",
        "recordings": "Enregistrements",
        "files_key": "Des dossiers",
        "edit_reference_text": "Modifier le texte de référence",
        "save_button": "sauver",
        "numeric_characters_allowed": "Seuls les caractères numériques sont autorisés.",
        "search_collaboration_profile": "Profil collaboratif",
        "assigned_to_lable": "Assigné à",
        "duration": "Durée",
        "scheduled": "Programmé",
        "user_created": "Créé",
        "user_change": "Changement",
        "ends_on": "Se termine le",
        "ended_key": "Terminé",
        "user_action": "action",
        "copy_guest_url": "Copier l'URL de l'invité",
        "copy_observer_url": "Copier l'URL de l'observateur",
        "invites_sent": "Invitations envoyées",
        "no_invites": "Aucune invitation",
        "archive_session": "Session d'archivage",
        "archive": "Archiver",
        "session_ended": "session a pris fin",
        "end_link": "Fin du lien",
        "end_session": "fin de session",
        "end_btn": "Fin",
        "share": "Partager",
        "invite": "Inviter",
        "open_link": "Ouvrir le lien",
        "start_session": "session",
        "start_btn": "Début",
        "join_session": "Rejoindre la session",
        "join": "Joindre",
        "join_as_guest": "Rejoindre en tant qu'invité",
        "join_as_observer": "Rejoindre en tant qu'observateur",
        "archiving_session_hide_from_session_history": "L'archivage de cette session la masquera de l'historique de session.",
        "this_cannot_be_undone": "Ça ne peut pas être annulé.",
        "donnot_archive": "Ne pas archiver",
        "back": "Retour",
        "crt_acnt_limit_character_msg_fifty": "Jusqu'à 50 caractères autorisés.",
        "reference_required": "Veuillez saisir le titre / la référence.",
        "search": "Chercher",
        "few_seconds_ago": "il ya quelques secondes",
        "minute_ago": "il y a 1 minute",
        "minutes_ago": "Il y a 1 minute",
        "hour_ago": "Il ya 1 heure",
        "hours_ago": "Il y a 1 heure",
        "journey_label": "Voyage",
        "submitted_on": "Soumis le :",
        "welcome_to_the_journey": "Bienvenue dans le <Journey>",
        "not_answered": "Non répondu",
        "yesterday": "Hier",
        "just_now": "Juste maintenant",
        "viewed": "Vu",
        "archive_session_fail": "La session d'archivage a échoué.",
        "no_images_to_download": "Les images ne sont plus disponibles. Ils sont supprimés par votre administrateur ou par la politique de stockage de votre domaine. Veuillez contacter votre administrateur.",
        "observer_url_copied": "URL d'observateur copiée dans le presse-papiers.",
        "guest_url_copy": "URL d'invité copiée dans le Presse-papiers.",
        "downloading": "Téléchargement...",
        "reference_field_updated": "Champ de référence mis à jour avec succès.",
        "assignee_updated": "Destinataire mis à jour.",
        "archive_session_success": "La session a été archivée avec succès."
    },
    "pt_BR": {
        "user_all": "Todos",
        "session_active": "Ativo",
        "session_upcoming": "próximos",
        "session_closed": "Fechadas",
        "archived": "Arquivado",
        "no_image_text": "Nenhuma imagem disponível para esta sessão ou pode ter sido excluída pelo seu administrador.",
        "loading_images": "Carregando imagens ...",
        "user_cancel": "Cancelar",
        "delete": "Excluir",
        "download_key": "Baixar",
        "view_image_location": "Ver localização da imagem",
        "time": "Tempo",
        "view_key": "Visão",
        "select": "Selecione",
        "unselect": "Desmarcar",
        "none": "Nenhum",
        "select_items": "Selecione itens",
        "select_all": "Selecionar tudo",
        "images": "Imagens",
        "delete_popup_imagecount": "Excluir <count> imagem (ns)?",
        "delete_imagepopup_text": "Tem certeza de que deseja excluir permanentemente as <count> imagens selecionadas?",
        "norecording_text": "Nenhuma gravação disponível para esta sessão ou pode ter sido excluída pelo seu Admin.",
        "locading_recordings": "Carregando gravações ...",
        "no_files_found": "Nenhum arquivo encontrado.",
        "file_name": "Nome do arquivo",
        "created_on": "Criado em",
        "file_size": "Tamanho do arquivo",
        "play": "Toque",
        "recording_processing": "Você tem <count> gravações que precisam ser processadas antes da visualização.",
        "single_pending_recording_label": "Você tem <count> gravação que precisa ser processada antes de visualizar.",
        "you_lable": "Vocês",
        "delete_popup_recordingcount": "Excluir <count> gravação (ões)?",
        "delete_recordingpopup_text": "Tem certeza de que deseja excluir permanentemente as <count> gravações selecionadas?",
        "zipping_file": "Compactando arquivos",
        "download_failed": "Falha no Download",
        "successfully_downloaded": "baixado com sucesso",
        "successfully_deleted": "Excluído com sucesso",
        "files_available_text": "Nenhum arquivo disponível para esta sessão ou pode ter sido excluído pelo seu administrador.",
        "close": "Perto",
        "chat_key": "Bate-papo",
        "extracted_text": "Texto extraído",
        "loading_files": "Carregando arquivos ...",
        "delete_pupup_commontext": "Você não pode desfazer esta ação.",
        "delete_filepopup_text": "Tem certeza de que deseja excluir permanentemente os <count> arquivo (s) selecionado (s)?",
        "delete_popup_filecount": "Excluir arquivo (s) de <count> ?",
        "hi": "Oi,",
        "getstarted_newsession": "Comece criando uma nova sessão abaixo.",
        "new_session": "Nova Sessão",
        "responses": "Respostas",
        "details_key": "Detalhes",
        "note_lable": "Notas",
        "recordings": "Gravações",
        "files_key": "arquivos",
        "edit_reference_text": "Editar texto de referência",
        "save_button": "Salve ",
        "numeric_characters_allowed": "Apenas caracteres numéricos são permitidos.",
        "search_collaboration_profile": "Perfil de colaboração",
        "assigned_to_lable": "Atribuído a",
        "duration": "Duração",
        "scheduled": "Programado",
        "user_created": "Criado",
        "user_change": "Alteração",
        "ends_on": "Termina em",
        "ended_key": "Terminado",
        "user_action": "Ação",
        "copy_guest_url": "Copiar URL do convidado",
        "copy_observer_url": "Copiar URL do observador",
        "invites_sent": "Convites enviados",
        "no_invites": "Não há convites",
        "archive_session": "Sessão de arquivamento",
        "archive": "Arquivo",
        "session_ended": "A sessão terminou",
        "end_link": "Encerrar link",
        "end_session": "Sessão final",
        "end_btn": "Fim",
        "share": "Participação",
        "invite": "Convite",
        "open_link": "Link aberto",
        "start_session": "Iniciar a Sessão",
        "start_btn": "Começar",
        "join_session": "Participar da sessão",
        "join": "Entrar",
        "join_as_guest": "Junte-se como convidado",
        "join_as_observer": "Junte-se como observador",
        "archiving_session_hide_from_session_history": "Arquivar esta sessão irá ocultá-la do histórico da sessão.",
        "this_cannot_be_undone": "Isto não pode ser desfeito.",
        "donnot_archive": "Não arquive",
        "back": "De volta",
        "crt_acnt_limit_character_msg_fifty": "São permitidos até 50 caracteres.",
        "reference_required": "Por favor, insira Título / Referência.",
        "search": "Procurar",
        "few_seconds_ago": "Alguns segundos atrás",
        "minute_ago": "1 minuto atrás",
        "minutes_ago": "1 minuto atrás",
        "hour_ago": "1 hora atrás",
        "hours_ago": "1 horas atrás",
        "journey_label": "Jornada",
        "submitted_on": "Enviado em:",
        "welcome_to_the_journey": "Bem-vindo à <Journey>",
        "not_answered": "Não respondido",
        "yesterday": "Ontem",
        "just_now": "Agora mesmo",
        "viewed": "visto",
        "archive_session_fail": "Falha na sessão de arquivamento.",
        "no_images_to_download": "As imagens não estão mais disponíveis. Eles são excluídos pelo seu administrador ou pela política de armazenamento do seu domínio. Entre em contato com seu administrador.",
        "observer_url_copied": "URL do observador copiado para a área de transferência.",
        "guest_url_copy": "URL de convidado copiado para a área de transferência.",
        "downloading": "Baixando ...",
        "reference_field_updated": "Campo de referência atualizado com sucesso.",
        "assignee_updated": "Cessionário atualizado.",
        "archive_session_success": "Sessão arquivada com sucesso."
    },
    "pt_PT": {
        "user_all": "Todos",
        "session_active": "Ativo",
        "session_upcoming": "próximos",
        "session_closed": "Fechadas",
        "archived": "Arquivado",
        "no_image_text": "Nenhuma imagem disponível para esta sessão ou pode ter sido excluída pelo seu administrador.",
        "loading_images": "Carregando imagens ...",
        "user_cancel": "Cancelar",
        "delete": "Excluir",
        "download_key": "Baixar",
        "view_image_location": "Ver localização da imagem",
        "time": "Tempo",
        "view_key": "Visão",
        "select": "Selecione",
        "unselect": "Desmarcar",
        "none": "Nenhum",
        "select_items": "Selecione itens",
        "select_all": "Selecionar tudo",
        "images": "Imagens",
        "delete_popup_imagecount": "Excluir <count> imagem (ns)?",
        "delete_imagepopup_text": "Tem certeza de que deseja excluir permanentemente as <count> imagens selecionadas?",
        "norecording_text": "Nenhuma gravação disponível para esta sessão ou pode ter sido excluída pelo seu Admin.",
        "locading_recordings": "Carregando gravações ...",
        "no_files_found": "Nenhum arquivo encontrado.",
        "file_name": "Nome do arquivo",
        "created_on": "Criado em",
        "file_size": "Tamanho do arquivo",
        "play": "Toque",
        "recording_processing": "Você tem <count> gravações que precisam ser processadas antes da visualização.",
        "single_pending_recording_label": "Você tem <count> gravação que precisa ser processada antes de visualizar.",
        "you_lable": "Vocês",
        "delete_popup_recordingcount": "Excluir <count> gravação (ões)?",
        "delete_recordingpopup_text": "Tem certeza de que deseja excluir permanentemente as <count> gravações selecionadas?",
        "zipping_file": "Compactando arquivos",
        "download_failed": "Falha no Download",
        "successfully_downloaded": "baixado com êxito",
        "successfully_deleted": "Excluído com sucesso",
        "files_available_text": "Nenhum arquivo disponível para esta sessão ou pode ter sido excluído pelo seu administrador.",
        "close": "Perto",
        "chat_key": "Bate-papo",
        "extracted_text": "Texto extraído",
        "loading_files": "Carregando arquivos ...",
        "delete_pupup_commontext": "Você não pode desfazer esta ação.",
        "delete_filepopup_text": "Tem certeza de que deseja excluir permanentemente os <count> arquivo (s) selecionado (s)?",
        "delete_popup_filecount": "Excluir arquivo (s) de <count> ?",
        "hi": "Oi,",
        "getstarted_newsession": "Comece criando uma nova sessão abaixo.",
        "new_session": "Nova Sessão",
        "responses": "Respostas",
        "details_key": "Detalhes",
        "note_lable": "Notas",
        "recordings": "Gravações",
        "files_key": "arquivos",
        "edit_reference_text": "Editar texto de referência",
        "save_button": "Salve ",
        "numeric_characters_allowed": "Apenas caracteres numéricos são permitidos.",
        "search_collaboration_profile": "Perfil de colaboração",
        "assigned_to_lable": "Atribuído a",
        "duration": "Duração",
        "scheduled": "Programado",
        "user_created": "Criado",
        "user_change": "mudança",
        "ends_on": "Termina em",
        "ended_key": "Terminado",
        "user_action": "Açao",
        "copy_guest_url": "Copiar URL do convidado",
        "copy_observer_url": "Copiar URL do observador",
        "invites_sent": "Convites enviados",
        "no_invites": "não há convites",
        "archive_session": "Sessão de arquivamento",
        "archive": "Arquivo",
        "session_ended": "sessão terminou",
        "end_link": "Finalizar link",
        "end_session": "Sessão final",
        "end_btn": "Fim",
        "share": "Participação",
        "invite": "Convite",
        "open_link": "Link aberto",
        "start_session": "Sessão início",
        "start_btn": "Começar",
        "join_session": "Participar da sessão",
        "join": "Junte-se",
        "join_as_guest": "Junte-se como convidado",
        "join_as_observer": "Junte-se como observador",
        "archiving_session_hide_from_session_history": "Arquivar esta sessão irá ocultá-la do histórico da sessão.",
        "this_cannot_be_undone": "Isto não pode ser desfeito.",
        "donnot_archive": "Não arquive",
        "back": "De volta",
        "crt_acnt_limit_character_msg_fifty": "São permitidos até 50 caracteres.",
        "reference_required": "Por favor, insira Título / Referência.",
        "search": "Procurar",
        "few_seconds_ago": "Alguns segundos atrás",
        "minute_ago": "1 minuto atrás",
        "minutes_ago": "1 minuto atrás",
        "hour_ago": "1 hora atrás",
        "hours_ago": "1 horas atrás",
        "journey_label": "Jornada",
        "submitted_on": "Enviado em:",
        "welcome_to_the_journey": "Bem-vindo à <Journey>",
        "not_answered": "Não respondido",
        "yesterday": "Ontem",
        "just_now": "Agora mesmo",
        "viewed": "visto",
        "archive_session_fail": "Falha na sessão de arquivamento.",
        "no_images_to_download": "As imagens não estão mais disponíveis. Eles são excluídos pelo seu administrador ou pela política de armazenamento do seu domínio. Entre em contato com seu administrador.",
        "observer_url_copied": "URL do observador copiado para a área de transferência.",
        "guest_url_copy": "URL de convidado copiado para a área de transferência.",
        "downloading": "Baixando ...",
        "reference_field_updated": "Campo de referência atualizado com sucesso.",
        "assignee_updated": "Cessionário atualizado.",
        "archive_session_success": "Sessão arquivada com sucesso."
    },
    "it_IT": {
        "user_all": "Tutti",
        "session_active": "Attivo",
        "session_upcoming": "Prossimo",
        "session_closed": "Chiuso",
        "archived": "Archiviato",
        "no_image_text": "Nessuna immagine disponibile per questa sessione o potrebbe essere stata eliminata dal tuo amministratore.",
        "loading_images": "Caricamento immagini...",
        "user_cancel": "Annulla",
        "delete": "Elimina",
        "download_key": "Scarica",
        "view_image_location": "Visualizza la posizione dell'immagine",
        "time": "Tempo",
        "view_key": "Visualizza",
        "select": "Selezionare",
        "unselect": "Deseleziona",
        "none": "Nessuna",
        "select_items": "Seleziona elementi",
        "select_all": "Seleziona tutto",
        "images": "immagini",
        "delete_popup_imagecount": "Eliminare <count> immagine/i?",
        "delete_imagepopup_text": "Sei sicuro di voler eliminare definitivamente le <count> immagini selezionate?",
        "norecording_text": "Nessuna registrazione disponibile per questa sessione o potrebbe essere stata eliminata dal tuo amministratore.",
        "locading_recordings": "Caricamento registrazioni...",
        "no_files_found": "Nessun file trovato.",
        "file_name": "Nome del file",
        "created_on": "Creato",
        "file_size": "Dimensione del file",
        "play": "Giocare",
        "recording_processing": "Hai <count> registrazioni che devono essere elaborate prima della visualizzazione.",
        "single_pending_recording_label": "Hai <count> registrazione che deve essere elaborata prima della visualizzazione.",
        "you_lable": "voi",
        "delete_popup_recordingcount": "Eliminare <count> registrazioni?",
        "delete_recordingpopup_text": "Sei sicuro di voler eliminare definitivamente le <count> registrazioni selezionate?",
        "zipping_file": "Zippare i file",
        "download_failed": "Scaricamento fallito",
        "successfully_downloaded": "Scaricato con successo",
        "successfully_deleted": "Eliminato con successo",
        "files_available_text": "Nessun file disponibile per questa sessione o potrebbe essere stato eliminato dal tuo amministratore.",
        "close": "Vicino",
        "chat_key": "Chiacchierare",
        "extracted_text": "Testo estratto",
        "loading_files": "Caricamento file...",
        "delete_pupup_commontext": "Non puoi annullare questa azione.",
        "delete_filepopup_text": "Sei sicuro di voler eliminare definitivamente i <count> file selezionati?",
        "delete_popup_filecount": "Eliminare <count> file?",
        "hi": "Ciao,",
        "getstarted_newsession": "Inizia creando una nuova sessione di seguito.",
        "new_session": "Nuova sessione",
        "responses": "Risposte",
        "details_key": "Dettagli",
        "note_lable": "Appunti",
        "recordings": "Registrazioni",
        "files_key": "File",
        "edit_reference_text": "Modifica testo di riferimento",
        "save_button": "Salva",
        "numeric_characters_allowed": "Sono consentiti solo caratteri numerici.",
        "search_collaboration_profile": "Profilo di collaborazione",
        "assigned_to_lable": "Assegnato a",
        "duration": "Durata",
        "scheduled": "Programmato",
        "user_created": "Creato",
        "user_change": "Modificare",
        "ends_on": "Finisce il",
        "ended_key": "Conclusa",
        "user_action": "Azione",
        "copy_guest_url": "Copia URL ospite",
        "copy_observer_url": "Copia URL osservatorer",
        "invites_sent": "Inviti inviati",
        "no_invites": "Nessun invito",
        "archive_session": "Archivia sessione",
        "archive": "Archivio",
        "session_ended": "Sessione terminata",
        "end_link": "Fine collegamento",
        "end_session": "Fine sessione",
        "end_btn": "Fine",
        "share": "Condividere",
        "invite": "Invitare",
        "open_link": "Link aperto",
        "start_session": "Inizia sessione",
        "start_btn": "Inizio",
        "join_session": "Partecipa alla sessione",
        "join": "Aderire",
        "join_as_guest": "Partecipa come ospite",
        "join_as_observer": "Partecipa come osservatore",
        "archiving_session_hide_from_session_history": "L'archiviazione di questa sessione la nasconderà dalla cronologia delle sessioni.",
        "this_cannot_be_undone": "Questo non può essere annullato.",
        "donnot_archive": "Non archiviare",
        "back": "Indietro",
        "crt_acnt_limit_character_msg_fifty": "Sono consentiti fino a 50 caratteri.",
        "reference_required": "Si prega di inserire titolo/riferimento.",
        "search": "Ricerca",
        "few_seconds_ago": "qualche secondo fa",
        "minute_ago": "1 minuto fa",
        "minutes_ago": "1 minuto fa",
        "hour_ago": "1 ora fa",
        "hours_ago": "1 ora fa",
        "journey_label": "Viaggio",
        "submitted_on": "Inviato il:",
        "welcome_to_the_journey": "Benvenuti nel <Journey>",
        "not_answered": "Non risposto",
        "yesterday": "Ieri",
        "just_now": "Proprio adesso",
        "viewed": "Visto",
        "archive_session_fail": "Sessione di archiviazione non riuscita.",
        "no_images_to_download": "Immagini non più disponibili. Vengono eliminati dal tuo amministratore o dai criteri di archiviazione del tuo dominio. Contatta il tuo amministratore.",
        "observer_url_copied": "URL osservatore copiato negli appunti.",
        "guest_url_copy": "URL ospite copiato negli appunti.",
        "downloading": "Download in corso...",
        "reference_field_updated": "Campo di riferimento aggiornato correttamente.",
        "assignee_updated": "Assegnatario aggiornato.",
        "archive_session_success": "Sessione archiviata con successo."
    },
    "zh_CN": {
        "user_all": "全部",
        "session_active": "积极的",
        "session_upcoming": "即将到来",
        "session_closed": "关闭",
        "archived": "存档",
        "no_image_text": "此会话没有可用的图像或可能已被您的管理员删除。",
        "loading_images": "正在加载图片...",
        "user_cancel": "取消",
        "delete": "删除",
        "download_key": "下载",
        "view_image_location": "查看图像位置",
        "time": "时间",
        "view_key": "看法",
        "select": "选择",
        "unselect": "取消选择",
        "none": "没有任何",
        "select_items": "选择项目",
        "select_all": "全选",
        "images": "图片",
        "delete_popup_imagecount": "删除<count>图像？",
        "delete_imagepopup_text": "您确定要永久删除<count>选定的图像吗？",
        "norecording_text": "此会话没有可用的录音，或者可能已被您的管理员删除。",
        "locading_recordings": "正在加载录音...",
        "no_files_found": "没有找到文件。",
        "file_name": "文档名称",
        "created_on": "创建于",
        "file_size": "文件大小",
        "play": "玩",
        "recording_processing": "您有<count>录音需要在查看前进行处理。",
        "single_pending_recording_label": "您有<count>记录需要在查看前进行处理。",
        "you_lable": "你",
        "delete_popup_recordingcount": "删除<count>记录？",
        "delete_recordingpopup_text": "您确定要永久删除<count>选定的录音吗？",
        "zipping_file": "压缩文件",
        "download_failed": "下载失败",
        "successfully_downloaded": "下载成功",
        "successfully_deleted": "已成功删除",
        "files_available_text": "没有可用于此会话的文件或可能已被您的管理员删除。",
        "close": "关闭",
        "chat_key": "聊天",
        "extracted_text": "提取的文本",
        "loading_files": "正在加载文件...",
        "delete_pupup_commontext": "您无法撤消此操作。",
        "delete_filepopup_text": "您确定要永久删除<count>选定的文件吗？",
        "delete_popup_filecount": "删除<count>文件？",
        "hi": "你好，",
        "getstarted_newsession": "通过在下面创建一个新会话开始。",
        "new_session": "新会话",
        "responses": "回应",
        "details_key": "细节",
        "note_lable": "笔记",
        "recordings": "录音",
        "files_key": "文件",
        "edit_reference_text": "编辑参考文本",
        "save_button": "节省",
        "numeric_characters_allowed": "只允许数字字符。",
        "search_collaboration_profile": "合作简介",
        "assigned_to_lable": "分配给",
        "duration": "期间",
        "scheduled": "预定",
        "user_created": "创建",
        "user_change": "改变",
        "ends_on": "结束于",
        "ended_key": "结束",
        "user_action": "行动",
        "copy_guest_url": "复制访客 URL",
        "copy_observer_url": "复制观察者网址",
        "invites_sent": "已发送邀请",
        "no_invites": "没有邀请",
        "archive_session": "存档会话",
        "archive": "档案",
        "session_ended": "会话结束",
        "end_link": "结束链接",
        "end_session": "结束会话",
        "end_btn": "结尾",
        "share": "分享",
        "invite": "邀请",
        "open_link": "打开链接",
        "start_session": "开始会话",
        "start_btn": "开始",
        "join_session": "加入会议",
        "join": "加入",
        "join_as_guest": "以访客身份加入",
        "join_as_observer": "加入观察员",
        "archiving_session_hide_from_session_history": "存档此会话会将其从会话历史记录中隐藏。",
        "this_cannot_be_undone": "这不能被撤消。",
        "donnot_archive": "不存档",
        "back": "后退",
        "crt_acnt_limit_character_msg_fifty": "最多允许 50 个字符。",
        "reference_required": "请输入标题/参考。",
        "search": "搜索",
        "few_seconds_ago": "几秒钟前",
        "minute_ago": "1 分钟前",
        "minutes_ago": "1 分钟前",
        "hour_ago": "1小时前",
        "hours_ago": "1 小时前",
        "journey_label": "旅行",
        "submitted_on": "提交时间：",
        "welcome_to_the_journey": "欢迎来到<Journey>",
        "not_answered": "无解答",
        "yesterday": "昨天",
        "just_now": "现在",
        "viewed": "看过",
        "archive_session_fail": "存档会话失败。",
        "no_images_to_download": "图像不再可用。它们要么被您的管理员删除，要么被您域的存储策略删除。请联系您的管理员。",
        "observer_url_copied": "观察者 URL 已复制到剪贴板。",
        "guest_url_copy": "访客 URL 已复制到剪贴板。",
        "downloading": "正在下载...",
        "reference_field_updated": "参考字段更新成功。",
        "assignee_updated": "受让人更新。",
        "archive_session_success": "会话归档成功。"
    },
    "ja_JP": {
        "user_all": "全て",
        "session_active": "アクティブ",
        "session_upcoming": "今後",
        "session_closed": "閉まっている",
        "archived": "アーカイブ済み",
        "no_image_text": "このセッションで使用できる画像がないか、管理者によって削除された可能性があります。",
        "loading_images": "画像を読み込んでいます...",
        "user_cancel": "キャンセル",
        "delete": "消去",
        "download_key": "ダウンロード",
        "view_image_location": "画像の場所を表示",
        "time": "時間",
        "view_key": "意見",
        "select": "選択する",
        "none": "なし",
        "select_items": "アイテムを選択",
        "select_all": "すべて選択",
        "images": "画像",
        "delete_popup_imagecount": "<count>個の画像を削除しますか?",
        "delete_imagepopup_text": "選択した<count>個の画像を完全に削除してもよろしいですか?",
        "norecording_text": "このセッションで使用できる記録がないか、管理者によって削除された可能性があります。",
        "locading_recordings": "録音を読み込んでいます...",
        "no_files_found": "ファイルが見つかりません。",
        "file_name": "ファイル名",
        "created_on": "作成日",
        "file_size": "ファイルサイズ",
        "play": "遊ぶ",
        "recording_processing": "表示する前に処理する必要がある<count>の録画があります。",
        "single_pending_recording_label": "表示する前に処理する必要がある<count>の録画があります。",
        "network_error": "ネットワークエラー",
        "you_lable": "君は",
        "delete_popup_recordingcount": "<count>の録音を削除しますか?",
        "delete_recordingpopup_text": "選択した<count>の録画を完全に削除してもよろしいですか?",
        "zipping_file": "ファイルの圧縮",
        "download_failed": "ダウンロードに失敗しました",
        "successfully_downloaded": "正常にダウンロードされました",
        "successfully_deleted": "正常に削除されました",
        "files_available_text": "このセッションで使用できるファイルがないか、管理者によって削除された可能性があります。",
        "close": "近い",
        "chat_key": "チャット",
        "extracted_text": "抽出されたテキスト",
        "loading_files": "ファイルを読み込んでいます...",
        "delete_pupup_commontext": "この操作を元に戻すことはできません。",
        "delete_filepopup_text": "選択した<count>個のファイルを完全に削除してもよろしいですか?",
        "delete_popup_filecount": "<count>個のファイルを削除しますか?",
        "hi": "やあ、",
        "getstarted_newsession": "以下の新しいセッションを作成することから始めます。",
        "new_session": "新しいセッション",
        "responses": "反応",
        "details_key": "詳細",
        "note_lable": "ノート",
        "recordings": "録音",
        "files_key": "ファイル",
        "edit_reference_text": "参照テキストの編集",
        "save_button": "保存",
        "numeric_characters_allowed": "数字のみを使用できます。",
        "search_collaboration_profile": "コラボレーションのプロフィール",
        "assigned_to_lable": "に割り当てられた",
        "duration": "間隔",
        "scheduled": "予定",
        "user_created": "作成した",
        "user_change": "変化する",
        "ends_on": "終了日",
        "ended_key": "終了しました",
        "user_action": "アクション",
        "copy_guest_url": "ゲスト URL をコピー",
        "copy_observer_url": "オブザーバーの URL をコピー",
        "invites_sent": "送信された招待状",
        "no_invites": "招待なし",
        "archive_session": "アーカイブ セッション",
        "archive": "記録",
        "session_ended": "セッション終了",
        "end_link": "エンドリンク",
        "end_session": "セッションの終了",
        "end_btn": "終わり",
        "share": "シェア",
        "invite": "招待",
        "open_link": "リンクを開く",
        "start_session": "セッションを開始",
        "start_btn": "始める",
        "join_session": "セッションに参加",
        "join": "加入",
        "join_as_guest": "ゲストとして参加",
        "join_as_observer": "オブザーバーとして参加",
        "archiving_session_hide_from_session_history": "このセッションをアーカイブすると、セッション履歴から非表示になります。",
        "this_cannot_be_undone": "これは、元に戻すことはできません。",
        "donnot_archive": "アーカイブしない",
        "back": "戻る",
        "crt_acnt_limit_character_msg_fifty": "最大 50 文字まで使用できます。",
        "reference_required": "タイトル/参照を入力してください。",
        "search": "探す",
        "few_seconds_ago": "数秒前",
        "minute_ago": "1分前",
        "minutes_ago": "1分前",
        "hour_ago": "1時間前",
        "hours_ago": "1時間前",
        "journey_label": "旅",
        "submitted_on": "提出日:",
        "welcome_to_the_journey": "<Journey>へようこそ",
        "not_answered": "答えなかった",
        "yesterday": "昨日",
        "just_now": "ちょうど今",
        "viewed": "見た",
        "archive_session_fail": "アーカイブ セッションが失敗しました。",
        "no_images_to_download": "画像は利用できなくなりました。これらは、管理者またはドメインのストレージ ポリシーによって削除されます。管理者に連絡してください。",
        "observer_url_copied": "オブザーバーの URL がクリップボードにコピーされました。",
        "guest_url_copy": "クリップボードにコピーされたゲスト URL。",
        "downloading": "ダウンロード中...",
        "reference_field_updated": "参照フィールドが正常に更新されました。",
        "assignee_updated": "担当者が更新されました。",
        "archive_session_success": "セッションが正常にアーカイブされました。"
    }
}