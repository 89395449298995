import PropTypes from 'prop-types';
import { JOURNEY_STEP_TYPES } from "../../../../../constants/Constants";
import { Fancybox } from "../../../../../shared/FancyboxJS";

const GPSLocationStep = ({ dataObj, answerObj, imageObj, localisedValues, fancyRef }) => {
  if (dataObj.type === JOURNEY_STEP_TYPES.GPS_LOCATION && answerObj && imageObj) {
    return (
      <div className="uploaded-image-list">
        <Fancybox
          fancyRef={fancyRef}
          options={{
            Carousel: {
              infinite: false
            },
            Toolbar: {
              display: {
                left: ["infobar"],
                middle: [],
                right: ["slideshow", "fullscreen", "download", "close"],
              },
            },
            Thumbs: false,
          }}>
          <div className="uploaded-image-col">
            <div className="uploaded-image-box">
              <a className="uploaded-image-fncy" data-fancybox={`gps-location-${imageObj.id}`} data-caption={imageObj.caption} href={imageObj.fileUrl}>
                <div className="uploaded-image">
                  <img className="uploaded-image-img" src={imageObj.fileUrl} alt="" />
                </div>
                <div className="view-btn">
                  {localisedValues["view_key"]}
                </div>
              </a>
            </div>
          </div>
        </Fancybox>
      </div>
    );
  }
  return null;
};

GPSLocationStep.propTypes = {
  dataObj: PropTypes.object,
  answerObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  imageObj: PropTypes.object,
  localisedValues: PropTypes.object,
  fancyRef:PropTypes.object
};

export default GPSLocationStep;