export const EVENTS = {
    CHECKING_SIGNUP_EMAIL: 'CHECKINGSIGNUPEMAIL',
    REGISTER_SIGNUP_USER: 'REGISTERSIGNUPUSER',
    CLEAN_SIGNUP_ERROR: 'CLEAN_SIGNUP_ERROR'
};

export const CHECKING_SIGNUP_EMAIL = {
    START: "CHECKING_SIGNUP_EMAIL_START",
    SUCCESS: "CHECKING_SIGNUP_EMAIL_SUCCESS",
    ERROR: "CHECKING_SIGNUP_EMAIL_ERROR",
}

export const REGISTER_SIGNUP_USER = {
    START: "REGISTER_SIGNUP_USER_START",
    SUCCESS: "REGISTER_SIGNUP_USER_SUCCESS",
    ERROR: "REGISTER_SIGNUP_USER_ERROR",
}

export const defaultCheckingSignUpEmailState = {
    checkingSignUpEmailError: false,
    checkingSignUpEmailLoading: false,
    checkingSignUpEmailData: false
}

export const defaultRegisterSignUpUserState = {
    registerSignUpUserError: false,
    registerSignUpUserLoading: false,
    registerSignUpUserData: false
}

export const defaultState = {
    ...defaultCheckingSignUpEmailState,
    ...defaultRegisterSignUpUserState
}

const signUpReducer = (state = defaultState, action = {}) => {
    switch (action.event) {
        case EVENTS.CHECKING_SIGNUP_EMAIL:
            return {
                ...state,
                checkingSignUpEmailLoading: action.type === CHECKING_SIGNUP_EMAIL.START ,
                checkingSignUpEmailData: action.type === CHECKING_SIGNUP_EMAIL.SUCCESS ? action.payload : false,
                checkingSignUpEmailError: action.type === CHECKING_SIGNUP_EMAIL.ERROR ? action.payload : false,
            }
        case EVENTS.REGISTER_SIGNUP_USER:
            return {
                ...state,
                registerSignUpUserLoading: action.type === REGISTER_SIGNUP_USER.START ,
                registerSignUpUserData: action.type === REGISTER_SIGNUP_USER.SUCCESS ? action.payload : false,
                registerSignUpUserError: action.type === REGISTER_SIGNUP_USER.ERROR ? action.payload : false,
            }
            case EVENTS.CLEAN_SIGNUP_ERROR:
                return{
                    ...state,
                    checkingSignUpEmailData: false,
                    checkingSignUpEmailError: false,
                    registerSignUpUserData: false,
                    registerSignUpUserError: false
                }
        default:
            return state;
    }
}

export default signUpReducer;