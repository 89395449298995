import __ from "lodash";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { LOCAL_STORAGE_KEYS, ON_LOAD, TEN_REDORDS_PER_PAGE } from "../../../constants/Constants";
import storage from "../../../services/storage";
import { getSessionUsers } from "../../../actions/sessionListAction";
import SelectedUsers from "./SelectedUsers";
import SessionUsersList from "./SessionUsersList";
import SessionUsersSearchbar from "./SessionUsersSearchbar";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { arrayAddIfNotExistCheckWithKey, arrayElementExistCheckWithKey } from "../../../shared/arrayExtension";
import { getCurrentUser } from "../../../shared/utility";

const SessionUsers = (props) => {
    const {
        selectedUsersChangeHandler,
        selectAllChangeHandler,
        sessionList,
        isDiscardChanged,
        localisedValues
    } = props;

    const [usersList, setUsersList] = useState([]);
    const [pageNo, setPageNo] = useState(0);//setting 1 as first page for first time component loading
    const [selectAllUsers, setSelectAllUsers] = useState(storage.has(LOCAL_STORAGE_KEYS.SELECT_ALL) ? storage.get(LOCAL_STORAGE_KEYS.SELECT_ALL) : false);
    const [selectedUsers, setSelectedUsers] = useState(storage.has(LOCAL_STORAGE_KEYS.SELECTED_USER_ID) ? storage.get(LOCAL_STORAGE_KEYS.SELECTED_USER_ID) : []);
    const [searchText, setSearchText] = useState('');
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [isSearchResultEmpty, setIsSearchResultEmpty] = useState(false);

    const usersListRef = useRef();

    useEffect(() => {
        if (!props.sessionList.getSessionUsersLoading) {
            if (props.sessionList.getCountriesData) {
                const configurationdata = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
                if (!configurationdata?.configurations?.sessionvisibilitypage) {
                    props.fetchSessionsWithoutUsers();
                } else {
                    if (props.sessionList.getSessionUsersData?.req?.INITIATE === 'OnLoad') return;
                    fetchSessionUsers(pageNo, ON_LOAD);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sessionList.getCountriesData]);

    useEffect(() => {
        const sessionUsersData = sessionList.getSessionUsersData;

        if (sessionUsersData) {
            setUsersList(parseUsers(sessionUsersData.list, sessionUsersData.req));
            setHasMoreUsers(sessionUsersData.list.length === TEN_REDORDS_PER_PAGE)
            setPageNo(pageNo + TEN_REDORDS_PER_PAGE);  // get 10 records per page
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionList.getSessionUsersData]);

    useEffect(() => {
        if (usersList.length > 0) {
            setUsersList(usersList.map(user => {
                user.SELECTED = selectAllUsers;
                return user;
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectAllUsers]);

    useDidMountEffect(() => {
        setPageNo(0);//reset page number on search by text
        fetchSessionUsers(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const onChangeSelectAll = (isSelectAll) => {
        const _selectedUsers = [];
        setSelectedUsers(_selectedUsers);
        storage.set(LOCAL_STORAGE_KEYS.SELECTED_USER_ID, _selectedUsers);

        selectAllChangeHandler(isSelectAll);
        setSelectAllUsers(isSelectAll)
    }

    const fetchSessionUsers = (skip = 0, initiate = '') => {
        const request = {
            SKIP: skip,
            SEARCH: searchText,  // search in a strings
            INITIATE: initiate
        };

        props.getSessionUsers(request);
    }

    const fetchMoreSessionUsers = () => {
        fetchSessionUsers(pageNo);
    }

    const parseUsers = (users, req) => {
        setIsSearchResultEmpty((users.length === 0 && searchText !== ''));  // is user lenght is 0 and search text is empty then show the 'No result found msg'
        /* it is check default Users is selectd or not */
        const defaultUser = selectedUsers.filter((el) => el.id === storage.get(LOCAL_STORAGE_KEYS.UCID))
        let usersListCopy = req.SKIP === 0 ? [] : __.cloneDeep(usersList);
        //Add ligin user as default selected user on first page load to avoid empty session list
        if (req.INITIATE === ON_LOAD || (req.SKIP === 0 && __.isEmpty(req.SEARCH))) {
            const user = getCurrentUser();
            if (!selectAllUsers) {
                user.SELECTED = (defaultUser.length === 1)
            }
            usersListCopy = arrayAddIfNotExistCheckWithKey(usersListCopy, user, "userId");
        }
        users.map((value) => {
            if (!arrayElementExistCheckWithKey(usersListCopy, "userId", value.userId)) {
                const userCopy = __.cloneDeep(value);
                userCopy.fullName = userCopy.firstName + " " + userCopy.lastName;
                if (!__.isEmpty(searchText)) {
                    const regex = new RegExp(searchText, 'i');
                    const found = userCopy.fullName.match(regex);
                    userCopy.fullName = __.replace(userCopy.fullName, regex, '<b>' + found + '</b>');
                }
                userCopy.SELECTED = selectAllUsers || (selectedUsers.findIndex((user) => user.id === value.userId) >= 0);
                usersListCopy.push(userCopy);
            }
        });
        return usersListCopy;
    }

    const selectUser = (user) => {
        let _selectedUsers
        if (selectedUsers.findIndex((u) => u.id === user.userId) >= 0) {
            //user selected => deselect it
            _selectedUsers = __.filter(selectedUsers, (u) => u.id !== user.userId);
            setUsersList(usersList.map((u) => {
                if (u.userId === user.userId) {
                    u.SELECTED = false;
                }
                return u;
            }));
        }
        else {
            //user not selected => add it
            _selectedUsers = [...selectedUsers, { id: user.userId, name: user.firstName }];
            setUsersList(usersList.map((u) => {
                if (u.userId === user.userId) {
                    u.SELECTED = true;
                }
                return u;
            }));
        }

        setSelectedUsers(_selectedUsers);
        storage.set(LOCAL_STORAGE_KEYS.SELECTED_USER_ID, _selectedUsers);

        selectedUsersChangeHandler(_selectedUsers);
    }
    const preventEvent = (event) => {
        event.stopPropagation();
    }
    /*clear selected users when clicked on clear button*/
    const clearSelectedUsersHandler = () => {
        const clearSelectedUsers = usersList?.map((users) => { return { ...users, SELECTED: false } })
        setUsersList(clearSelectedUsers)
        setSelectedUsers([])
        storage.remove(LOCAL_STORAGE_KEYS.SELECTED_USER_ID)
        props.clearSelectedUserInSessionList();  // in session list need to pass as a props as we have to clear session user list
    }


    return (
        <>
            {props.showSessionUserDropdown &&
                <div className="filter-box user-filter-box">
                    <div className="dropdown" data-testid="usersDropdown">
                        <SelectedUsers usersList={usersList} selectAllUsers={selectAllUsers} selectedUsers={selectedUsers} localisedValues={localisedValues} />
                        <div className="dropdown-menu user-filter-dropdown-menu" onKeyDown={() => { }} onClick={preventEvent} aria-labelledby="session-user-filter">
                            <div className="dropdown-menu-in side-padding">
                                <SessionUsersSearchbar onSearchTextChange={setSearchText} localisedValues={localisedValues} />
                                <SessionUsersList ref={usersListRef} usersList={usersList} selectedUsers={selectedUsers} selectedUsersChangeHandler={selectUser}
                                    onChangeSelectAll={onChangeSelectAll} loadMore={fetchMoreSessionUsers} pageNo={pageNo}
                                    hasMore={hasMoreUsers} onClearSelectedUsers={clearSelectedUsersHandler} isSearchResultEmpty={isSearchResultEmpty} isDiscardChanged={isDiscardChanged} localisedValues={localisedValues} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        sessionList: state.sessionList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSessionUsers: (req) => dispatch(getSessionUsers(req)),
    }
}

SessionUsers.propTypes = {
    isDiscardChanged: PropTypes.bool,
    localisedValues: PropTypes.object,
    selectedUsersChangeHandler: PropTypes.func,
    sessionList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    selectAllChangeHandler: PropTypes.func,
    getSessionUsers: PropTypes.func,
    fetchSessionsWithoutUsers: PropTypes.func,
    clearSelectedUserInSessionList: PropTypes.func,
    showSessionUserDropdown: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionUsers);