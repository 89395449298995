import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import PropTypes from 'prop-types';
import Pagination from "./Pagination/Pagination";
import BootstrapDataTable from "./BootstrapDataTable";

const DataTable = ({
  columns,
  tableData,
  expandRow,
  isLoading,
  paginationOptions,
  onTableChangeFunction,
  noDataMessage,
  defaultSorted,
  searchHelper,
  tableKey,
}) => {
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination
            paginationProps={paginationProps}
            isLoading={isLoading}
            searchHelper={searchHelper}
          >
            <BootstrapDataTable
              tableKey={tableKey}
              tableData={tableData}
              paginationTableProps={paginationTableProps}
              onTableChangeFunction={onTableChangeFunction}
              isLoading={isLoading}
              noDataMessage={noDataMessage}
              defaultSorted={defaultSorted}
              columns={columns}
              expandRow={expandRow}
              hidePagination={false}
            />
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};

DataTable.propTypes = {  
  columns: PropTypes.object,
  tableData: PropTypes.object,
  expandRow: PropTypes.object,
  isLoading: PropTypes.bool,
  paginationOptions: PropTypes.object,
  onTableChangeFunction: PropTypes.func,
  noDataMessage: PropTypes.string,
  defaultSorted: PropTypes.object,
  searchHelper: PropTypes.object,
  tableKey: PropTypes.string,
}

export default DataTable;
