import React from "react";
import clsx from "clsx";
import PropTypes from 'prop-types';
import { getPages, getPagesCount } from "./TablePaginationHelpers";

const PaginationLinks = ({ paginationProps }) => {
  const {
    totalSize,
    sizePerPage,
    page: pageNumber,
    paginationSize,
  } = paginationProps;
  const pagesCount = getPagesCount(totalSize, sizePerPage);
  const pages = getPages(pageNumber, pagesCount, paginationSize);
  const handleFirstPage = ({ onPageChange }) => {
    onPageChange(1);
  };

  const handlePrevPage = ({ page, onPageChange }) => {
    onPageChange(page - 1);
  };

  const handleNextPage = ({ page, onPageChange }) => {
    if (page < pagesCount) {
      onPageChange(page + 1);
    }
  };

  const handleLastPage = ({ onPageChange }) => {
    onPageChange(pagesCount);
  };

  const handleSelectedPage = ({ onPageChange }, pageNum) => {
    onPageChange(pageNum);
  };

  const disabledClass = pagesCount > 1 ? "" : "disabled";
  return (
    <>
      {pagesCount < 2 && <></>}
      {pagesCount > 1 && (
        <div className={`d-flex flex-wrap tbl-pagination ${disabledClass}`}>
          <button
            data-testid="FirstButton"
            onClick={() => handleFirstPage(paginationProps)}
            className={clsx(
              "btn btn-outline-secondary btn-pagi-icon",
              pageNumber === 1 && "disabled"
            )}
          >
            <span className="icon-previous-free btn-icon"></span>
          </button>
          <button
            data-testid="PreviousButton"
            onClick={() => handlePrevPage(paginationProps)}
            className={clsx(
              "btn btn-outline-secondary btn-pagi-icon",
              pageNumber === 1 && "disabled"
            )}
          >
            <span className="icon-previous btn-icon"></span>
          </button>

          {pageNumber > 1 && <div className="more-page-label">...</div>}
          {pages.map((p) => (
            <button
              key={p}
              data-testid={"page" + p}
              onClick={() => handleSelectedPage(paginationProps, p)}
              className={`btn btn-outline-secondary btn-mob-hide ${
                pageNumber === p ? " active" : ""
              } `}
            >
              {p}
            </button>
          ))}
          {pageNumber < pagesCount && (
            <div className="more-page-label">...</div>
          )}
          <button
            data-testid="NextButton"
            onClick={() => handleNextPage(paginationProps)}
            className={clsx(
              "btn btn-outline-secondary btn-pagi-icon",
              pageNumber === pagesCount && "disabled"
            )}
          >
            <span className="icon-next btn-icon"></span>
          </button>
          <button
            data-testid="LastButton"
            onClick={() => handleLastPage(paginationProps)}
            className={clsx(
              "btn btn-outline-secondary btn-pagi-icon",
              pageNumber === pagesCount && "disabled"
            )}
          >
            <span className="icon-next-free btn-icon"></span>
          </button>
        </div>
      )}
    </>
  );
};

PaginationLinks.propTypes = {
  paginationProps: PropTypes.object,
};

export default PaginationLinks;