import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLDClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import 'react-intl-tel-input/dist/main.css';
import 'react-multi-email/dist/style.css';
import './assets/css/index.scss';
import './assets/css/variables.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { DefaultLanguage, LOCAL_STORAGE_KEYS, LOGGED_IN, RouteName } from './constants/Constants';
import { checkIsCompanyAvailable } from "./actions/onLoadFunctionAction"
import AllRoutes from './routes';
import { createLDUserObj, getDomainURL, setLDFlags, setPublicVariables, updateDomainLogoInStorage } from "./shared/utility";
import PublicVariables from './constants/PublicVariables';
import { getLanguage, setLanguage } from './actions/languageAction';
import storage from './services/storage';
import SignalR from './services/signalR';
import DesktopNotification from './services/desktopNotification';
import history from './history';
import PageLoader from './components/UI/PageLoader';

export const LOCAL_DOMAIN_NAME_FOR_TESTING = 'showmetest.blitzz.app';

const App = (props) => {
  let [isDomainExist, setIsDomainExist] = useState(false);
  const ldClient = useLDClient();

  const checkCompanyInfo = () => {
    props
      .checkIsCompanyAvailable({
        "domainURL": getDomainURL() ?? ""
      })
  }

  const fetchLanguage = () => {
    const configData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
    if (configData) {
      props.getLanguage();
    }
    else {
      const usersLanguage = storage.get(LOCAL_STORAGE_KEYS.USERS_LANGUAGE);
      props.setLanguage(usersLanguage ? usersLanguage : DefaultLanguage, null);
    }
  }


  const handleConnectSignal = () => {
    const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
    const configData = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);

    if (userData) {
      if (configData?.configurations?.journeyeditor === 1) {
        const uToken = storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN)
        DesktopNotification.NotificationPermission();
        SignalR.Connect(uToken)
      } else {
        SignalR.Disconnect()
      }
    } else {
      SignalR.Disconnect()
    }
  }

  useEffect(() => {
    function storageChange(event) {
      if (event.key === LOGGED_IN) {
        if (event.newValue == '0') {
          window.open('', '_self', '');
          window.close();
          history.push(RouteName.LOGIN)
        }
      } else if (event.key === 'Blitzz.configdata') {
        if (event.newValue == null) { // means logout from other tabs then redirect to login state
          history.push(RouteName.LOGIN)
        }
      }
    }
    window.addEventListener('storage', storageChange);
    return () => window.addEventListener('storage', storageChange);
  })

  useEffect(() => {
    if (process.env.REACT_APP_APP_ENV === "production") { // production env
      console.log = function () {
        // This is intentional
      };
      console.error = function () {
        // This is intentional
      };
      console.info = function () {
        // This is intentional
      };
      console.warn = function () {
        // This is intentional
      };
    }

    if (getDomainURL() !== null && getDomainURL() !== "") { // call api when custom url and host mapping url to check
      checkCompanyInfo();
    }
    else {
      PublicVariables.canGetLanguage = true;
      fetchLanguage();
    }

    // For FreshDesk View
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('nonce') && urlParams.has('redirect_uri') && urlParams.has('state')) {
      storage.set(LOCAL_STORAGE_KEYS.FRESH_DESK_URL, urlParams.toString())
    }

    handleConnectSignal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(props?.language?.selectedlanguage).length > 0 && !isDomainExist) {
      setIsDomainExist(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.language?.selectedlanguage]);

  useEffect(() => {
    if (props.language?.languageList?.length > 0) {
      props.setLanguage(props.language?.selectedlanguage?.languageCode, props.language?.languageList);
    }
  }, [props.language?.languageList])

  useEffect(() => { // flow of host mapping URL and Subdomain URL
    const companyData = props.isCompanyAvailableData;
    if (companyData) {
      if (companyData.customHostMappingUrl && (window.location.origin === companyData.customHostMappingUrl) && !companyData.hasSSL) { // If hostmapping URL then only check SSL certificate
        alert(`Redirecting to '${companyData.domainUrl}' as SSL Certificate is not bind for this URL.`);
        window.location.href = companyData.domainUrl;
      } else {
        updateDomainLogoInStorage(companyData?.domainLogo)
        setPublicVariables(companyData);
        let ldUser = createLDUserObj(companyData);
        setLDFlags(ldClient, ldUser);
        props.setLanguage(companyData.defaultLanguage, companyData.languages);
      }
    }
    if (props.isCompanyAvailableError) {
      setIsDomainExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCompanyAvailableData, props.isCompanyAvailableError])

  return (
    <>
      {isDomainExist ? (<AllRoutes />) : <PageLoader/>}
    </>

  );
}

const mapStateToProps = (state) => {
  return {
    isCompanyAvailableData: state.onLoadData.isCompanyAvailableData,
    isCompanyAvailableError: state.onLoadData.isCompanyAvailableError,
    language: state.language,
  }
}

App.propTypes = {
  isCompanyAvailableData: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isCompanyAvailableError: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  language: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  checkIsCompanyAvailable: PropTypes.func,
  setLanguage: PropTypes.func,
  getLanguage: PropTypes.func,
}

export default connect(mapStateToProps,
  {
    checkIsCompanyAvailable,
    setLanguage,
    getLanguage,
  }
)(App);
