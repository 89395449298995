export const Localize = {
    "en_GB": {
        "user_id": "User ID",
        "email_id": "Email ID",
        "mobile_number": "Mobile Number",
        "invite": "Invite",
        "SA-Role": "Super Admin",
        "ADM-Role": "Administrator",
        "UM-Role": "User Manager",
        "DEV-Role": "Developer",
        "BL-Role": "Billing",
        "DA-Role": "Data Analyst",
        "INT-Role": "User (Internal)",
        "no_record_found": "No records found",
        "search": "Search"
    },
    "de_DE": {
        "user_id": "Benutzeridentifikation",
        "email_id": "E-Mail-ID",
        "mobile_number": "Handynummer",
        "invite": "Einladen",
        "SA-Role": "höchster Vorgesetzter",
        "ADM-Role": "Administrator",
        "UM-Role": "Benutzer Manager",
        "DEV-Role": "Entwickler",
        "BL-Role": "Abrechnung",
        "DA-Role": "Daten Analyst",
        "INT-Role": "Benutzer (intern)",
        "no_record_found": "Keine Aufzeichnungen gefunden",
        "search": "Suche"
    },
    "en_US": {
        "user_id": "User ID",
        "email_id": "Email ID",
        "mobile_number": "Mobile Number",
        "invite": "Invite",
        "SA-Role": "Super Admin",
        "ADM-Role": "Administrator",
        "UM-Role": "User Manager",
        "DEV-Role": "Developer",
        "BL-Role": "Billing",
        "DA-Role": "Data Analyst",
        "INT-Role": "User (Internal)",
        "no_record_found": "No records found",
        "search": "Search"
    },
    "en_AU": {
        "user_id": "User ID",
        "email_id": "Email ID",
        "mobile_number": "Mobile Number",
        "invite": "Invite",
        "SA-Role": "Super Admin",
        "ADM-Role": "Administrator",
        "UM-Role": "User Manager",
        "DEV-Role": "Developer",
        "BL-Role": "Billing",
        "DA-Role": "Data Analyst",
        "INT-Role": "User (Internal)",
        "no_record_found": "No records found",
        "search": "Search"
    },
    "es_ES": {
        "user_id": "ID de usuario",
        "email_id": "Identificación de correo",
        "mobile_number": "Número de teléfono móvil",
        "invite": "Invitación",
        "SA-Role": "Super administrador",
        "ADM-Role": "Administrador",
        "UM-Role": "Administrador de usuarios",
        "DEV-Role": "Desarrollador",
        "BL-Role": "Facturación",
        "DA-Role": "Analista de datos",
        "INT-Role": "Usuario (interno)",
        "no_record_found": "No se encontraron registros",
        "search": "Buscar"
    },
    "es_MX": {
        "user_id": "ID de usuario",
        "email_id": "Identificación de correo",
        "mobile_number": "Número de teléfono móvil",
        "invite": "Invitación",
        "SA-Role": "Super administrador",
        "ADM-Role": "Administrador",
        "UM-Role": "Administrador de usuarios",
        "DEV-Role": "Desarrollador",
        "BL-Role": "Facturación",
        "DA-Role": "Analista de datos",
        "INT-Role": "Usuario (interno)",
        "no_record_found": "No se encontraron registros",
        "search": "Buscar"
    },
    "es_AR": {
        "user_id": "ID de usuario",
        "email_id": "Identificación de correo",
        "mobile_number": "Número de teléfono móvil",
        "invite": "Invitación",
        "SA-Role": "Super administrador",
        "ADM-Role": "Administrador",
        "UM-Role": "Administrador de usuarios",
        "DEV-Role": "Desarrollador",
        "BL-Role": "Facturación",
        "DA-Role": "Analista de datos",
        "INT-Role": "Usuario (interno)",
        "no_record_found": "No se encontraron registros",
        "search": "Buscar"
    },
    "fr_FR": {
        "user_id": "Identifiant d'utilisateur",
        "email_id": "Identifiant e-mail",
        "mobile_number": "Numéro de portable",
        "invite": "Inviter",
        "SA-Role": "Super administrateur",
        "ADM-Role": "Administrateur",
        "UM-Role": "Gestionnaire des utilisateurs",
        "DEV-Role": "Développeur",
        "BL-Role": "Facturation",
        "DA-Role": "Analyste de données",
        "INT-Role": "Utilisateur (interne)",
        "no_record_found": "Aucun enregistrement trouvé",
        "search": "Chercher"
    },
    "en_CA": {
        "user_id": "User ID",
        "email_id": "Email ID",
        "mobile_number": "Mobile Number",
        "invite": "Invite",
        "SA-Role": "Super Admin",
        "ADM-Role": "Administrator",
        "UM-Role": "User Manager",
        "DEV-Role": "Developer",
        "BL-Role": "Billing",
        "DA-Role": "Data Analyst",
        "INT-Role": "User (Internal)",
        "no_record_found": "No records found",
        "search": "Search"

    },
    "fr_CA": {
        "user_id": "Identifiant d'utilisateur",
        "email_id": "Identifiant e-mail",
        "mobile_number": "Numéro de portable",
        "invite": "Inviter",
        "SA-Role": "Super administrateur",
        "ADM-Role": "Administrateur",
        "UM-Role": "Gestionnaire des utilisateurs",
        "DEV-Role": "Développeur",
        "BL-Role": "Facturation",
        "DA-Role": "Analyste de données",
        "INT-Role": "Utilisateur (interne)",
        "no_record_found": "Aucun enregistrement trouvé",
        "search": "Chercher"
    },
    "pt_BR": {
        "user_id": "ID do usuário",
        "email_id": "Identificação do email",
        "mobile_number": "Número de celular",
        "invite": "Convite",
        "SA-Role": "Super Admin",
        "ADM-Role": "Administrador",
        "UM-Role": "Gerente de Usuário",
        "DEV-Role": "Desenvolvedor",
        "BL-Role": "Cobrança",
        "DA-Role": "Analista de informações",
        "INT-Role": "Usuário (interno)",
        "no_record_found": "Nenhum registro foi encontrado",
        "search": "Procurar"
    },
    "pt_PT": {
        "user_id": "ID do usuário",
        "email_id": "Identificação do email",
        "mobile_number": "Número de celular",
        "invite": "Convite",
        "SA-Role": "Super Admin",
        "ADM-Role": "Administrador",
        "UM-Role": "Gerente de Usuário",
        "DEV-Role": "Desenvolvedor",
        "BL-Role": "Cobrança",
        "DA-Role": "Analista de informações",
        "INT-Role": "Usuário (interno)",
        "no_record_found": "Nenhum registro foi encontrado",
        "search": "Procurar"
    },
    "it_IT": {
        "user_id": "ID utente",
        "email_id": "E-mail identificativo utente",
        "mobile_number": "Numero di cellulare",
        "invite": "Invitare",
        "SA-Role": "Super amministratore",
        "ADM-Role": "Amministratore",
        "UM-Role": "Gestione utenti",
        "DEV-Role": "Sviluppatore",
        "BL-Role": "Fatturazione",
        "DA-Role": "Analista dati",
        "INT-Role": "Utente (interno)",
        "no_record_found": "Nessun record trovato",
        "search": "Ricerca"
    },
    "zh_CN": {
        "user_id": "用户身份",
        "email_id": "电子邮件ID",
        "mobile_number": "手机号码",
        "invite": "邀请",
        "SA-Role": "超级管理员",
        "ADM-Role": "行政人员",
        "UM-Role": "用户经理",
        "DEV-Role": "开发商",
        "BL-Role": "计费",
        "DA-Role": "数据分析师",
        "INT-Role": "用户（内部）",
        "no_record_found": "没有找到记录",
        "search": "搜索"
    },
    "ja_JP": {
        "user_id": "ユーザーID",
        "email_id": "電子メールID",
        "mobile_number": "携帯電話番号",
        "invite": "招待",
        "SA-Role": "スーパー管理者",
        "ADM-Role": "管理者",
        "UM-Role": "ユーザーマネージャー",
        "DEV-Role": "デベロッパー",
        "BL-Role": "請求する",
        "DA-Role": "データアナリスト",
        "INT-Role": "ユーザー (内部)",
        "no_record_found": "記録が見つかりませんでした",
        "search": "探す"
    }
}