import PropTypes from 'prop-types';
import { JOURNEY_STEP_TYPES } from "../../../../../constants/Constants"
import { Fancybox } from "../../../../../shared/FancyboxJS";

const UploadImageStep = ({ dataObj, imageObj, localisedValues, fancyRef }) => {
    if (dataObj.type == JOURNEY_STEP_TYPES.IMAGE && imageObj?.length > 0) {
        return (
            <div className="uploaded-image-list">
                <Fancybox
                    fancyRef={fancyRef}
                    options={{
                        Carousel: {
                            infinite: false
                        },
                        Toolbar: {
                            display: {
                                left: ["infobar"],
                                middle: [],
                                right: ["slideshow", "fullscreen", "download", "close"],
                            },
                        },
                        Thumbs: false,
                    }}>
                    {imageObj.map((img, index) => (
                        <div key={index} className="uploaded-image-col">
                            <div className="uploaded-image-box">
                                <a className="uploaded-image-fncy" data-fancybox={`answer-Images-${img.id}`} data-caption={img.caption} href={img.fileUrl}>
                                    <div className="uploaded-image">
                                        <img className="uploaded-image-img" src={img.fileUrl} alt="" />
                                    </div>
                                    <div className="view-btn">
                                        {localisedValues["view_key"]}
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </Fancybox>
            </div>
        )
    }
}

UploadImageStep.propTypes = {
    dataObj: PropTypes.object,
    imageObj: PropTypes.object,
    localisedValues: PropTypes.object,
    fancyRef: PropTypes.object
};

export default UploadImageStep;