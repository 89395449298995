import InfiniteScroll from "react-infinite-scroll-component";
import useDidMountEffect, { useDidMountEffectWithClear } from "../../../../hooks/useDidMountEffect";
import parse from 'html-react-parser';
import __, { isEmpty, uniqBy } from "lodash";
import { connect } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import clsx from "clsx";
import PropTypes from 'prop-types';
import { arrayAddIfNotExistCheckWithKey, arrayElementExistCheckWithKey } from "../../../../shared/arrayExtension";
import { LOCAL_STORAGE_KEYS, ON_LOAD, TEN_REDORDS_PER_PAGE } from "../../../../constants/Constants";
import { getCurrentUser } from "../../../../shared/utility";
import { getAssigneeList, putAssigneeChange } from "../../../../actions/sessionDetailAction";
import storage from "../../../../services/storage";
import { Localize } from "../../Localize";
import PublicVariables from "../../../../constants/PublicVariables";
import { updateSessionList } from "../../../../actions/sessionListAction";

const PROFILE_URL = process.env.REACT_APP_PROFILE_URL;


const AssigneeDropDown = forwardRef((props, ref) => {
    const userData = useMemo(() => { return storage.get(LOCAL_STORAGE_KEYS.USER_DATA) }, []);
    const { meetingID, assigneeDetail, setIsSessionAssignChange, localisedValues, sessionsList, setSessionsList } = props;
    const [height, setHeight] = useState(196); //Used static value as of now, will be setup dynamic once SCSS done with media query for landscape screen
    const [assigneeSkip, setAssigneeSkip] = useState(0);
    const [assigneeList, setAssigneeList] = useState([]);
    const [hasMoreAssignee, setHasMoreAssignee] = useState(true);
    const [assigneeSearch, setAssigneeSearch] = useState('');
    const [selectedAssignee, setSelectedAssignee] = useState(assigneeDetail);
    const [defaultAssignee, setDefaultAssignee] = useState(assigneeDetail);
    const [isAPICall, setIsAPICall] = useState(false);

    // This hook helps a child component share certain functions or values with its parent. Here, the useImperativeHandle hook is used to let the parent access a bunch of functions through a ref.
    useImperativeHandle(ref, () => ({
        cancelAssignee() {
            setIsAPICall(false);
            setSelectedAssignee(defaultAssignee);
            setIsSessionAssignChange(false)
        },
        handleOnSaveAssignie() {
            saveAssignee()
        }
    }), [defaultAssignee, selectedAssignee, meetingID]);

    useEffect(() => {
        if (meetingID !== null && assigneeDetail && !props.assigneeChangeData) {
            setSelectedAssignee(assigneeDetail);
            setDefaultAssignee(assigneeDetail);
            fetchAssigneeList(ON_LOAD);
        }
        setAssigneeSearch('')
    }, [meetingID, assigneeDetail]);

    useEffect(() => {
        if (props.assigneeData) {
            let filteredUsers = parseUsers(props.assigneeData?.list, props.assigneeData?.req)
            let user = getCurrentUser();
            user.fullName = user.firstName + " " + user.lastName + " " + `(${localisedValues["you_lable"]})`
            delete user.SELECTED;

            if (!isEmpty(filteredUsers)) {
                filteredUsers.unshift(user)
                filteredUsers = uniqBy(filteredUsers, 'userId')
            }
            setAssigneeList(filteredUsers);
            const canScroll = props.assigneeData?.list.length >= TEN_REDORDS_PER_PAGE;
            setHasMoreAssignee(canScroll);
        }
    }, [props.assigneeData])

    useDidMountEffect(() => {
        fetchAssigneeList();
    }, [assigneeSkip]);

    useEffect(() => {
        if (props.assigneeChangeData) {
            setIsAPICall(false);
            setDefaultAssignee(selectedAssignee);
            setIsSessionAssignChange(false)

            // UPDATING OWNERid in sessionList so it's hide join , start btns according to conditions
            const updatedSessionList = sessionsList.map((session) => {
                if (session.sessionId === props.assigneeChangeData?.sessionId) {
                    return { ...session, ownerUserId: props.assigneeChangeData?.userId }
                }
                return session
            })
            setSessionsList(updatedSessionList);
            props.updateSessionList({items: updatedSessionList});
        }
        if (props.assigneeChangeError) {
            ref.current.cancelAssignee();
        }
    }, [props.assigneeChangeData, props.assigneeChangeError])


    useDidMountEffectWithClear(() => {
        setAssigneeSkip(0);
        setTimeout(() => {
            fetchAssigneeList();
        }, 100);
    }, [assigneeSearch], 500);

    const fetchAssigneeList = (initiate = '') => {
        const request = {
            SKIP: assigneeSkip,
            SEARCH: assigneeSearch.trim(),  // search in a strings
            INITIATE: initiate
        };

        props.getAssigneeList(request);
    }

    const fetchMoreAssignee = () => {
        setAssigneeSkip((prevState) => prevState + TEN_REDORDS_PER_PAGE);
    }

    const parseUsers = (users, req) => {
        let usersListCopy = req.SKIP === 0 ? [] : __.cloneDeep(assigneeList);
        //Add ligin user as default selected user on first page load to avoid empty session list
        if (req.INITIATE === ON_LOAD || (req.SKIP === 0 && __.isEmpty(req.SEARCH))) {
            let user = getCurrentUser();
            user.fullName = user.firstName + " " + user.lastName + " " + `(${localisedValues["you_lable"]})`
            delete user.SELECTED;
            usersListCopy = arrayAddIfNotExistCheckWithKey(usersListCopy, user, "userId");
        }
        users.map((value) => {
            if (!arrayElementExistCheckWithKey(usersListCopy, "userId", value.userId)) {
                const userCopy = __.cloneDeep(value);
                userCopy.fullName = userCopy.firstName + " " + userCopy.lastName;
                if (!__.isEmpty(assigneeSearch)) {
                    const regex = new RegExp(assigneeSearch, 'i');
                    const found = userCopy.fullName.match(regex);
                    userCopy.fullName = __.replace(userCopy.fullName, regex, '<b>' + found + '</b>');
                }
                usersListCopy.push(userCopy);
            }
        });
        return usersListCopy;
    }

    const assigneeChangeHandler = (assignee) => {
        setIsSessionAssignChange(defaultAssignee?.userId !== assignee?.userId)
        setSelectedAssignee(assignee);
    }

    const assigneeOptions = useMemo(() => {
        return (assigneeList) ?
            <>
                {(assigneeList.length === 0 && assigneeSearch !== '') && <span style={{ fontWeight: "600", fontSize: '13px' }}> {Localize[PublicVariables.currentLanguage]['no_user_found_lable']}</span>}
                {assigneeList.map(assignee => (
                    <li key={assignee.userId} className={clsx(selectedAssignee.userId === assignee.userId && "active")} data-testid='assigneeOption'>
                        <a className="dropdown-item" href={void (0)} onClick={() => assigneeChangeHandler(assignee)}>
                            <div className="dropdown-item-box">
                                <div className="user-icon">
                                    <img src={PROFILE_URL + assignee.profileImage} alt="User" />
                                </div>
                                <div className="user-name">
                                    {parse(assignee.fullName)}
                                </div>
                            </div>
                        </a>
                    </li>
                ))
                }
            </>
            : <></>
    }, [assigneeList, selectedAssignee]);

    const saveAssignee = () => {
        if (!isAPICall) {
            const req = {
                sessionId: meetingID,
                userId: selectedAssignee.userId,
                fullName: selectedAssignee?.firstName + " " + selectedAssignee?.lastName
            };
            setIsAPICall(true);
            props.putAssigneeChange(req);
        }
    }

    const removeBTageAndParse = (val = '') => {
        let newFullName = parse(val.replace('<b>', '').replace('</b>', ''))
        const youLocaliselable = `(${localisedValues["you_lable"]})`

        if (selectedAssignee && !selectedAssignee.firstName?.includes(youLocaliselable) && !selectedAssignee.lastName?.includes(youLocaliselable)) {
            newFullName= newFullName.replace(youLocaliselable, "")
        }
        return newFullName
    }

    return (
        <div className="short-info-box assign-user-box clearfix" ref={ref}>
            <p className="assign-p-head"><strong className="lbl"><span>{localisedValues["assigned_to_lable"]}</span>:</strong></p>
            <div className="filter-box user-filter-box">
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle triangle-none" type="button"
                        id="session-user-filter" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className={clsx("user-icon", !selectedAssignee.profileImage && 'user-none-icon')}>
                            {selectedAssignee.profileImage ?
                                <img src={PROFILE_URL + selectedAssignee.profileImage} alt="User" data-testid='selectedAssigneeProfile' /> :
                                <span className="for-icon icon-none-user"></span>
                            }
                        </div>
                        <div className="filter-item">
                            {selectedAssignee.userId ?
                                <>
                                    {(selectedAssignee?.userId === userData?.userId) ? removeBTageAndParse(selectedAssignee?.fullName) + ' ' + `(${localisedValues["you_lable"]})` : removeBTageAndParse(selectedAssignee?.fullName)}
                                </> : 'None'
                            }
                        </div>
                        <span className="triangle-icon icon-down-triangle-round"></span>
                    </button>
                    <div className="dropdown-menu user-filter-dropdown-menu" aria-labelledby="session-user-filter">
                        <div className="dropdown-menu-in side-padding pb-0">
                            <div className="search-form bottom-border esm">
                                <div className="form-group">
                                    <div className="form-group-in">
                                        <span className="for-icon icon-search"></span>
                                        <input type="text" className="form-control" placeholder={localisedValues["search"]} value={assigneeSearch} onChange={(e) => setAssigneeSearch(e.target.value)} data-testid='searchAssigneeInputBox' />
                                    </div>
                                </div>
                            </div>
                            <InfiniteScroll
                                dataLength={assigneeList.length}
                                next={fetchMoreAssignee}
                                hasMore={hasMoreAssignee}
                                scrollableTarget="assigneeScrollableDiv"
                                className="custom-scroll-div-thin"
                                height={height}
                                style={{ overflowX: 'hidden', overflowY: 'auto', height: 'auto' }}
                            >
                                <ul className="user-filter-list" id="assigneeScrollableDiv">
                                    {assigneeOptions}
                                </ul>
                            </InfiniteScroll>

                        </div>
                    </div>
                </div>
            </div>
            {defaultAssignee?.userId !== selectedAssignee?.userId && <div className="quick-info-save-row">
                <div className="button" disabled={isAPICall}>
                    <button className="btn btn-link btn-with-icon text-dec-none" type="submit" onClick={saveAssignee}>
                        {isAPICall && <span className="btn-loader-icon">
                            <span className="for-icon icon-loader spin"></span>
                        </span>}
                        <span className="btn-icon icon-save"></span>
                        <span className="btn-text">{localisedValues["save_button"]}</span>
                    </button>
                </div>
                <div className="button">
                    <button className="btn btn-cancel btn-with-icon text-dec-none" onClick={() => ref.current.cancelAssignee()}>
                        <span className="btn-icon icon-close"></span>
                        <span className="btn-text">{localisedValues["user_cancel"]}</span>
                    </button>
                </div>
            </div>}
        </div>
    )
})

const mapStateToPropsAssigneeDropDownTab = (state) => {
    return {
        assigneeData: state.sessionDetail.getAssigneeListData,
        assigneeChangeData: state.sessionDetail.putAssigneeChangeData,
        assigneeChangeError: state.sessionDetail.putAssigneeChangeError,
    }
}

const mapDispatchToPropsAssigneeDropDownTab = (dispatch) => {
    return {
        getAssigneeList: (req) => dispatch(getAssigneeList(req)),
        putAssigneeChange: (req) => dispatch(putAssigneeChange(req)),
        updateSessionList: (data) => dispatch(updateSessionList(data)),
    }
}

AssigneeDropDown.propTypes = {
    meetingID: PropTypes.string,
    assigneeDetail: PropTypes.object,
    setIsSessionAssignChange: PropTypes.func,
    localisedValues: PropTypes.object,
    assigneeData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    assigneeChangeData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    assigneeChangeError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    getAssigneeList: PropTypes.func,
    putAssigneeChange: PropTypes.func,
}

export default connect(mapStateToPropsAssigneeDropDownTab, mapDispatchToPropsAssigneeDropDownTab, null, { forwardRef: true })(AssigneeDropDown);