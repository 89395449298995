import api from "../apis/api";

class SelfSignupService {
    postVerifyEmail(email) {
        return api.SelfSignUpAPI.postVerifyEmail(email);
    }

    postCheckOTP(formData) {
        return api.SelfSignUpAPI.postCheckOTP(formData);
    }

    postCheckCompany(data) {
        return api.SelfSignUpAPI.postCheckCompany(data);
    }

    postCreateCompany(formData) {
        return api.SelfSignUpAPI.postCreateCompany(formData);
    }

    postInviteUser(formData) {
        return api.SelfSignUpAPI.postInviteUser(formData);
    }

}

export default new SelfSignupService();