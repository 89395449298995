export const PORTAL_NAME_CHANGE = 'PORTAL_NAME_CHANGE';

const defaultState = {
    portalname: '',
};

const portalNameReducer = (state = defaultState, action = {}) => {

    switch (action.type) {
        case PORTAL_NAME_CHANGE:
            return {
                ...state,
                portalname: action.payload,
            }    
        default:
            return state;
    }
}

export default portalNameReducer;