/*eslint no-duplicate-case: "error"*/
import { SUBSCRIPTIONTYPE } from "../constants/Constants";
import PublicVariables from "../constants/PublicVariables";
import { AGAIN_SEND_OTP, CHECK_COMPANY, CHECK_OTP, CREATE_COMPANY, EVENTS, INVITE_USER, VERIFY_EMAIL } from "../reducers/selfSignup-reducer";
import SelfSignupService from "../services/selfSignupService";
import { handleErrorMsgToastVisiblity } from "../shared/utility";


const apiCallEvents = (event, type, data=null) => {
    return {
        event: event,
        type: type,
        payload: data
    }
}

export const VerifyEmailForSelfSignup = (email, subscription_type) => async (dispatch) => {
    dispatch(apiCallEvents(EVENTS.VERIFY_EMAIL, VERIFY_EMAIL.START));

    SelfSignupService
        .postVerifyEmail(email)
        .then((response) => {
            PublicVariables.trialemail.verificationId = response.verificationId;
            PublicVariables.trialemail.SUBSCRIPTIONTYPE = (subscription_type != '' && subscription_type == "2") ? SUBSCRIPTIONTYPE.CREDITS : SUBSCRIPTIONTYPE.USERS;
            dispatch(apiCallEvents(EVENTS.VERIFY_EMAIL, VERIFY_EMAIL.SUCCESS, response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error?.message);
            dispatch(apiCallEvents(EVENTS.VERIFY_EMAIL, VERIFY_EMAIL.ERROR, error));
        })
}

export const CheckOTPForSelfSignup = (formData) => async (dispatch) => {
    dispatch(apiCallEvents(EVENTS.CHECK_OTP, CHECK_OTP.START));

    SelfSignupService
        .postCheckOTP(formData)
        .then((response) => {
            dispatch(apiCallEvents(EVENTS.CHECK_OTP, CHECK_OTP.SUCCESS, response));
        })
        .catch((error) => {
            dispatch(apiCallEvents(EVENTS.CHECK_OTP, CHECK_OTP.ERROR, error));
        })
}

export const AgainSendOTPForSelfSignup = (email) => async (dispatch) => {
    dispatch(apiCallEvents(EVENTS.AGAIN_SEND_OTP, AGAIN_SEND_OTP.START));
    
    SelfSignupService
        .postVerifyEmail(email)
        .then((response) => {
            PublicVariables.trialemail.verificationId = response.verificationId;
            dispatch(apiCallEvents(EVENTS.AGAIN_SEND_OTP, AGAIN_SEND_OTP.SUCCESS, response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(apiCallEvents(EVENTS.AGAIN_SEND_OTP, AGAIN_SEND_OTP.ERROR, error));
        })
}

export const CheckCompanyForSelfSignup = (req) => async (dispatch) => {
    dispatch(apiCallEvents(EVENTS.CHECK_COMPANY, CHECK_COMPANY.START));
    
    SelfSignupService
        .postCheckCompany(req)
        .then((response) => {
            dispatch(apiCallEvents(EVENTS.CHECK_COMPANY, CHECK_COMPANY.SUCCESS, {success: 1}));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(apiCallEvents(EVENTS.CHECK_COMPANY, CHECK_COMPANY.ERROR, error));
        })
}

export const CreateCompanyForSelfSignup = (formData) => async (dispatch) => {
    dispatch(apiCallEvents(EVENTS.CREATE_COMPANY, CREATE_COMPANY.START));
    
    SelfSignupService
        .postCreateCompany(formData)
        .then((response) => {
            dispatch(apiCallEvents(EVENTS.CREATE_COMPANY, CREATE_COMPANY.SUCCESS, response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error?.message);
            dispatch(apiCallEvents(EVENTS.CREATE_COMPANY, CREATE_COMPANY.ERROR, error));
        })
}

export const InviteUserForSelfSignup = (formData) => async (dispatch) => {
    dispatch(apiCallEvents(EVENTS.INVITE_USER, INVITE_USER.START));
    
    SelfSignupService
        .postInviteUser(formData)
        .then((response) => {
            if (response.success > 0) {
                console.log("Create company successful");
                dispatch(apiCallEvents(EVENTS.INVITE_USER, INVITE_USER.SUCCESS, response.data));
            }
            else
            {
                handleErrorMsgToastVisiblity(response.data.Error);
                dispatch(apiCallEvents(EVENTS.INVITE_USER, INVITE_USER.ERROR, response.data.Error));
            }
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(apiCallEvents(EVENTS.INVITE_USER, INVITE_USER.ERROR, error));
        })
}