import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { convertUtcToLocalInStr, formatBytes } from "../../../../../shared/utility";

const expandColObj = (expandedRows) => {
  return {
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return <></>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <div className="table-row-expand-btn">
            <span className="for-icon icon-down-triangle-round"></span>
          </div>
        );
      }
      return (
        <div className="table-row-expand-btn">
          <span
            data-testid="column-expander"
            className="for-icon icon-right-triangle-round"
          ></span>
        </div>
      );
    },
    parentClassName: "open",
    expanded: expandedRows,
  };
};

export const CheckboxColumnFormatter = (_cellContent, row, _rowIndex, {selectChangeHandler}) => {
  return (
      <div className="checkbox checkbox-sm checkbox-border only-checkbox">
        <label>
          <input type="checkbox" checked={row.ISSELECTED} onChange={() => selectChangeHandler(row.filePath, !row.ISSELECTED)} data-testid='checkBoxForRowOfTable'/>
          <span className="for-design"></span>
        </label>
      </div>
  );
};

export const ExpandRowHtmlHelper = ({ columnsToShow, row, localisedValues }) => {
  return (
    <table className="inner-table">
      <tbody>
        {columnsToShow < 3 && (
          <tr>
            <th>
              <div className="inner-table-th-title">{localisedValues["created_on"]}</div>
            </th>
            <td>
              <div className="file-created-on">
                {convertUtcToLocalInStr(row.createDateTime)}
              </div>
            </td>
          </tr>
        )}
        {columnsToShow < 4 && (
          <tr>
            <th>
              <div className="inner-table-th-title">{localisedValues["file_size"]}</div>
            </th>
            <td>
              <div className="file-size">
                {formatBytes(row.size)}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

ExpandRowHtmlHelper.propTypes = {
  columnsToShow: PropTypes.number,
  row: PropTypes.object,
  localisedValues: PropTypes.object
};

export const ExpandRowForFilesTab = (columnsToShow, localisedValues) => {
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setExpandedRows([]);
  }, [columnsToShow]);

  return {
    renderer: (row, _rowIndex) => (
      <ExpandRowHtmlHelper
        columnsToShow={columnsToShow}
        localisedValues={localisedValues}
        row={row}
      />
    ),
    showExpandColumn: columnsToShow < 10,
    ...expandColObj(expandedRows),
  };
};
