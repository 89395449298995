import SignalR from './signalR'

let showNotificationPermission = true;
const permissionTypes = {
    notificationGranted : 'granted',
    notificationDenied : 'denied'
}
let notify = {
    list: [],
    id: 0,
    log: function(msg) {
        console.log('notify log: ' + msg);
    },
    /* The `compatible` function is checking if the browser supports the `Notification` API. It does
    this by checking if the `Notification` object is defined. If the `Notification` object is
    undefined, it means that the browser does not support the `Notification` API. */
    compatible: function() {
        if (typeof Notification === 'undefined') {
            notify.log("Notifications are not available for your browser.");
            if(showNotificationPermission){
                showNotificationPermission = false;
                if (document.getElementById('notification-banner')) {
                    document.getElementById('notification-banner').style.display = 'block';
                }
            }
            return false;
        }
        return true;
    },
    /* The `authorize` function is responsible for requesting permission from the user to display
    notifications. */
    authorize: function() {
        if (notify.compatible()) {
            Notification.requestPermission(function(permission) {
                notify.log("Permission to display: "+permission);
            });
        }
    },
    /* The `show` function is responsible for displaying a desktop notification. */
    show: function(title) {
        if (typeof Notification === 'undefined') { notify.log("Notifications are not available for your browser."); return; }
        if (notify.compatible()) {
            Notification.requestPermission(function(permission) {
                notify.log("Permission to display: "+permission);
                if(permission === permissionTypes.notificationGranted){
                    notify.id++;
                    let id = notify.id;
                    notify.list[id] = new Notification(title, {
                        body: 'Click here to view more details',
                        tag: id,
                        icon: "app/assets/image/icon.png",
                        silent: true,
                    });
                    notify.list[id].onclick = function() { 
                        window.parent.focus();
                    };

                    setTimeout(function() { notify.list[id].close() }, 5000);
                }
            });
        }
    },
};
class DesktopNotification {
    
    handleNotification = (data) => {
        if(SignalR.isincomingCalls) {
            const hasFocus = document.hasFocus() || document.visibilityState == "visible";
            console.warn('hasFocus', hasFocus);
            if(!hasFocus) { 
                notify.show(data?.jName);
            }
            
            try{
                const notificationAudio = document.getElementById("notificationSignal");
                if(notificationAudio){
                    notificationAudio.play();
                }
            }
            catch(e){}
        }
    }
    NotificationPermission = function(){
        notify.authorize();
    }
}

export default new DesktopNotification;