import { configureStore } from '@reduxjs/toolkit';
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

import appReducers from "./reducers/Reducers";

function configureStoreProd(initialState) {
    const middlewares = [thunkMiddleware];
    
    return configureStore({
      reducer: appReducers,
      middleware: middlewares,
      preloadedState: initialState,
    });
  }
  
  function configureStoreDev(initialState) {
    const loggerMiddleware = createLogger({
      level: "info",
      collapsed: true,
      predicate: () => true,
    });
  
    const middlewares = [
      thunkMiddleware,
      loggerMiddleware,
      reduxImmutableStateInvariant(),
    ];
  
    return configureStore({
      reducer: appReducers,
      middleware: middlewares,
      preloadedState: initialState,
    });
  }
  
  const configureEnvStore =
    process.env.NODE_ENV === "production"
      ? configureStoreProd
      : configureStoreDev;
  
  export default configureEnvStore;