// this is the default js file which is added from the (https://fancyapps.com/resources/integration/#react)
import React, { useRef, useEffect } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import PropTypes from 'prop-types';

export const Fancybox = (props) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = {
      ...props.options,
      on: {
        done: () => {
          props.fancyRef.current = true
        },
        close: () => {
          setTimeout(() => {
            props.fancyRef.current = false
          }, 200);
        }
      }
    }
    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div className="uploaded-image-row" ref={containerRef}>{props.children}</div>;
}

Fancybox.propTypes = {
  delegate: PropTypes.string,
  options: PropTypes.object,
  fancyRef: PropTypes.object,
  children: PropTypes.element
}