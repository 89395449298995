import PropTypes from "prop-types";
import { JOURNEY_STEP_TYPES } from "../../../../../constants/Constants";
import { Fancybox } from "../../../../../shared/FancyboxJS";

const UploadVideoStep = ({ dataObj, videoObj, fancyRef }) => {
  if (dataObj.type === JOURNEY_STEP_TYPES.VIDEO && videoObj) {
    return (
      <div className="uploaded-image-list">
        <Fancybox
          fancyRef={fancyRef}
          options={{
            Toolbar: {
              enabled: true,
            },
          }}
        >
          <div className="uploaded-image-col">
            <div className="uploaded-image-box">
              <a
                className="uploaded-image-fncy"
                data-fancybox={`answer-videos-${videoObj.id}`}
                data-caption={videoObj.caption}
                href={videoObj.fileUrl}
              >
                <div className="video-play-icon">
                  <span className="for-icon icon-play"></span>
                </div>
              </a>
            </div>
          </div>
        </Fancybox>
      </div>
    );
  }
  return null;
};

UploadVideoStep.propTypes = {
  dataObj: PropTypes.object,
  videoObj: PropTypes.object,
  fancyRef: PropTypes.object,
};

export default UploadVideoStep;
