import { useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  convertUtcToLocalInStr,
  copyValue,
  openUrlInBrowser,
  resetDataForSession,
} from "../../shared/utility";
import { Localize } from "./Localize";
import { INVITE_USERS_TYPE } from "../../constants/Constants";

const SendOther = ({
  id,
  isDefaultInvite,
  sessionDetailData,
  sendUrl,
  agentUrl,
  message,
  inviteUser,
  isSessionOwner
}) => {
  const { language } = useSelector((state) => state);

  const [isSelected, setIsSelected] = useState();
  const [localisedValues, setLocalisedValues] = useState({});

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  /* The `copyLink` function copies different values to the clipboard based on the `from` parameter and
   displays a corresponding success message. */
  const copyLink = (from) => {
    if (sessionDetailData) {
      const sessionType = sessionDetailData.session?.sessionType;
      const sessionId = sessionDetailData.session?.sessionId;

      const utcStartDate = moment.utc(sessionDetailData.session?.startAt);
      const sessionStartTime =
        sessionType === 2 ? convertUtcToLocalInStr(utcStartDate) : "";

      const referenceSection = `Reference: ${sessionId}\r\n`;
      const timeSection =
        sessionType === 2 && sessionStartTime
          ? `Time: ${sessionStartTime}\r\n\r\n`
          : "\r\n";
      const joinSessionSection = `Join the session\r\n${sendUrl}`;

      const invitationMsg = `${message}\r\n\r\n${referenceSection}${timeSection}${joinSessionSection}`;

      if (from === "copyInvitation") {
        copyValue(invitationMsg, localisedValues["session_invitation_copied"]);
        setIsSelected(from);
      } else if (from === "copySessionCode") {
        copyValue(sessionId, localisedValues["session_code_copy"]);
        setIsSelected(from);
      } else if (from === "copySessionLink") {
        copyValue(
          sendUrl,
          inviteUser === INVITE_USERS_TYPE.GUEST
            ? localisedValues["guest_url_copy"]
            : localisedValues["observer_url_copied"]
        );
        setIsSelected(from);
      }
      const timer = setTimeout(() => setIsSelected(""), 6000);
      return () => clearTimeout(timer);
    }
  };

  const copiedTooltip = (
    <div className="invitation-copied">{localisedValues["copied"]}</div>
  );

  return (
    <div
      className={clsx("tab-pane", isDefaultInvite ? "active show" : "")}
      id={id}
      data-testid={id}
      role="tabpanel"
      aria-labelledby="invite-other-tab"
    >
      <div className="guest-invite-box">
        <div className="guest-invite-body">
          <div className="title">
            <h2 className="title-mob">{localisedValues["other"]} </h2>
            <h3 className="title-desktop">
              {localisedValues["share_invitation_with_others"]}
            </h3>
          </div>

          <div className="other-invitation">
            <div className="other-invitation-scroll custom-scroll-div-thin">
              <div
                className={clsx(
                  "other-invitation-box",
                  isSelected === "copyInvitation" && "copied"
                )}
                onKeyDown={() => {}}
                onClick={() => copyLink("copyInvitation")}
              >
                {isSelected === "copyInvitation" && copiedTooltip}
                <span className="other-invitation-icon icon-session-history"></span>
                <h4>{localisedValues["copy_invitation"]}</h4>
                <p>{localisedValues["copy_invitation_desc"]}</p>
              </div>
              <div
                className={clsx(
                  "other-invitation-box",
                  isSelected === "copySessionCode" && "copied"
                )}
                onKeyDown={() => {}}
                onClick={() => copyLink("copySessionCode")}
              >
                {isSelected === "copySessionCode" && copiedTooltip}
                <span className="other-invitation-icon icon-session-history"></span>
                <h4>{localisedValues["copy_session_code"]}</h4>
                <p>
                  <span
                    className={clsx(
                      isSelected === "copySessionCode" && "selection-div"
                    )}
                  >
                    {sessionDetailData.session?.sessionId}
                  </span>
                </p>
              </div>
              <div
                className={clsx(
                  "other-invitation-box",
                  isSelected === "copySessionLink" && "copied"
                )}
                onKeyDown={() => {}}
                onClick={() => copyLink("copySessionLink")}
              >
                {isSelected === "copySessionLink" && copiedTooltip}
                <span className="other-invitation-icon icon-copylink"></span>
                <h4>{localisedValues["copy_link_to_share"]}</h4>
                <p>
                  <span className={clsx(
                    isSelected === "copySessionLink" && "selection-div"
                  )}
                  >{sendUrl}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="guest-invite-footer">
          <div className="button-inline text-center d-flex">
            <div className="button join-call-btn">

            </div>
            {isSessionOwner && <div className="button">
              <button
                type="button"
                className="btn btn-primary btn-with-icon font-weight-500 hover-boxshadow singlejoinbtn"
                onClick={() => {openUrlInBrowser(agentUrl, "_self");
                          resetDataForSession(); }}
              >
                {localisedValues["join"]}
              </button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

SendOther.propTypes = {
  id: PropTypes.string,
  isDefaultInvite: PropTypes.bool,
  sessionDetailData: PropTypes.object,
  sendUrl: PropTypes.string,
  agentUrl: PropTypes.string,
  message: PropTypes.string,
  inviteUser: PropTypes.string,
};

export default SendOther;
