import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, concat, get } from "lodash";
import clsx from "clsx";
import PropTypes from "prop-types";
import { generateZip, getCurrentScreen, handleErrorMsgToastVisiblity } from "../../../../shared/utility";
import {
  deleteSessionChatFiles,
  deleteSessionOcrFiles,
  getSessionFiles,
  resetSessionChatFiles,
  resetSessionOcrFiles,
} from "../../../../actions/sessionDetailAction";
import {
  FILETABLE_WIDTH_CALCULATION_ARRAY,
  LOCAL_STORAGE_KEYS,
  SESSION_FILES_TYPE,
} from "../../../../constants/Constants";
import { Localize } from "../Localize";
import noFilesImage from "../../../../assets/images/no-files.svg";
import CustomToast from "../../../UI/customToast";
import storage from "../../../../services/storage";
import FilesTableView from "./FilesTableView";
import DeleteModal from "../DeleteModal";

const FilesTab = ({ meetingID, details, setDetails }) => {
  const dispatch = useDispatch();

  const {
    getSessionDetailsData,
    getSessionFilesLoading,
    getSessionFilesData,
    deleteSessionChatFilesLoading,
    deleteSessionChatFilesData,
    deleteSessionChatFilesError,
    deleteSessionOcrFilesData,
    deleteSessionOcrFilesLoading,
    deleteSessionOcrFilesError,
  } = useSelector((state) => state.sessionDetail);

  const { language } = useSelector((state) => state);

  const [columnsToShow, setColumnsToShow] = useState(10); //Set default to desktop //Show All columns
  const [localisedValues, setLocalisedValues] = useState({});

  //File data
  const [allFilesData, setAllFilesData] = useState([]);
  const [chatFilesData, setChatFilesData] = useState([]);
  const [ocrFilesData, setOcrFilesData] = useState([]);
  const [selectedFileTab, setSelectedFileTab] = useState(
    SESSION_FILES_TYPE.all
  );

  //Selected file
  const [selectedFiles, setSelectedFiles] = useState([]);

  //Downloading files
  const [isFilesDownloading, setIsFilesDownloading] = useState(false);

  //File delete modal
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  //Deleted file
  const [deletedFiles, setDeletedFiles] = useState([]);

  const columnsToShowRef = useRef(columnsToShow);

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  //Update the all file data after delete file
  useEffect(() => {
    if (deleteSessionChatFilesLoading && deleteSessionOcrFilesLoading) return;
    const chatSuccess = deleteSessionChatFilesData.success === 1;
    const ocrSuccess = deleteSessionOcrFilesData.success === 1;

    let fileDeletedSuccess;
    if (selectedFileTab === SESSION_FILES_TYPE.all) {
      fileDeletedSuccess = chatSuccess || ocrSuccess;
    } else if (selectedFileTab === SESSION_FILES_TYPE.chat) {
      fileDeletedSuccess = chatSuccess;
    } else if (selectedFileTab === SESSION_FILES_TYPE.extract) {
      fileDeletedSuccess = ocrSuccess;
    }

    if (fileDeletedSuccess) {
      const updatedFilesData = [...allFilesData];

      deletedFiles.forEach((item) => {
        const index = updatedFilesData.findIndex(
          (list) => list.filePath === item.name
        );

        if (index !== -1) {
          updatedFilesData.splice(index, 1);
        }
      });

      const updatedChatFiles = updatedFilesData.filter((item) =>
        item.filePath.includes(SESSION_FILES_TYPE.chat)
      );
      const updatedOcrFiles = updatedFilesData.filter((item) =>
        item.filePath.includes("ocr")
      );

      setAllFilesData(updatedFilesData);
      setChatFilesData(updatedChatFiles);
      setOcrFilesData(updatedOcrFiles);
      if (details?.session?.chatCount || details?.session?.ocrCount) {
        const updatedDetails = cloneDeep(details);
        if (selectedFileTab === SESSION_FILES_TYPE.all) {
          const updatedDeletedChatFiles = deletedFiles.filter((item) =>
            item?.name?.includes(SESSION_FILES_TYPE.chat)
          );
          const updatedDeletedOcrFiles = deletedFiles.filter((item) =>
            item?.name?.includes("ocr")
          );

          updatedDetails.session.chatCount =
            updatedDetails.session.chatCount - updatedDeletedChatFiles.length;

          updatedDetails.session.ocrCount =
            updatedDetails.session.ocrCount - updatedDeletedOcrFiles.length;

          dispatch(resetSessionChatFiles());
          dispatch(resetSessionOcrFiles());
        } else if (selectedFileTab === SESSION_FILES_TYPE.chat) {
          updatedDetails.session.chatCount =
            updatedDetails.session.chatCount - deletedFiles.length;
          dispatch(resetSessionChatFiles());
        } else if (selectedFileTab === SESSION_FILES_TYPE.extract) {
          updatedDetails.session.ocrCount =
            updatedDetails.session.ocrCount - deletedFiles.length;
          dispatch(resetSessionOcrFiles());
        }
        setDetails(updatedDetails);
      }
      CustomToast.success(localisedValues["successfully_deleted"]);
    } else if (deleteSessionChatFilesError || deleteSessionOcrFilesError) {
      handleErrorMsgToastVisiblity(
        deleteSessionChatFilesError || deleteSessionOcrFilesError
      );
    }
    setIsShowDeleteModal(false);
  }, [
    deleteSessionChatFilesData,
    deleteSessionOcrFilesData,
    deleteSessionChatFilesError,
    deleteSessionOcrFilesError,
  ]);

  useEffect(() => {
    const mainDiv = document.getElementById("session-file");
    if (mainDiv) {
      mainDiv.classList.add("active", "show");
    }
    return () => {
      mainDiv.classList.remove("active", "show");
    };
  }, []);

  //Set files data
  useEffect(() => {
    const sessionFilesChatList = get(getSessionFilesData, "data.chatList", []);
    setChatFilesData(cloneDeep(sessionFilesChatList).reverse());

    const sessionFilesOCRList = get(getSessionFilesData, "data.ocrList", []);
    setOcrFilesData(cloneDeep(sessionFilesOCRList).reverse());

    const allFilesData = concat(sessionFilesChatList, sessionFilesOCRList);
    setAllFilesData(cloneDeep(allFilesData).reverse());
  }, [getSessionFilesData]);

  //Session files API call
  useEffect(() => {
    if (meetingID) {
      const request = {
        sessionId: meetingID,
      };
      dispatch(getSessionFiles(request));
    }
  }, []);

  //On screen resize show table columns
  useEffect(() => {
    getCurrentScreen(
      columnsToShowRef,
      setColumnsToShow,
      FILETABLE_WIDTH_CALCULATION_ARRAY
    );
    window.addEventListener("resize", () => {
      getCurrentScreen(
        columnsToShowRef,
        setColumnsToShow,
        FILETABLE_WIDTH_CALCULATION_ARRAY
      );
    });
  }, []);

  useEffect(() => {
    columnsToShowRef.current = columnsToShow;
  }, [columnsToShow]);

  let noFilesView;
  if (getSessionFilesLoading) {
    noFilesView = <p>{localisedValues["loading_files"]}</p>;
  } else {
    noFilesView = (
      <>
        <div className="image">
          <img src={noFilesImage} alt="No file" />
        </div>
        <p>{localisedValues["files_available_text"]}</p>
      </>
    );
  }

  //Unselect all selected files
  const handleCancelSelectedFiles = () => {
    setSelectedFiles([]);
    allFilesData.map((item) => (item.ISSELECTED = false));
    chatFilesData.map((item) => (item.ISSELECTED = false));
    ocrFilesData.map((item) => (item.ISSELECTED = false));
  };

  const handleDownloadAllFiles = async () => {
    if (isFilesDownloading) return;
    setIsFilesDownloading(true);
    const downloadingZip = generateZip(selectedFiles, "-files.zip", meetingID);
    CustomToast.promise(downloadingZip, {
      pending: `${localisedValues["zipping_file"]} (${selectedFiles.length})`,
      success: localisedValues["successfully_downloaded"],
      error: localisedValues["download_failed"],
    });

    downloadingZip.then(() => {
      handleCancelSelectedFiles();
    });
    setIsFilesDownloading(false);
  };

  const handleDeleteFiles = () => {
    //Chat files
    const chatFiles = selectedFiles.filter((item) =>
      item.filePath.includes(SESSION_FILES_TYPE.chat)
    );

    const chatFileNames =
      chatFiles.length > 0 &&
      chatFiles.map((item) => {
        return { name: item.filePath, uploadtype: "0" };
      });

    const chatRequest = {
      sessionId: meetingID,
      Files: chatFileNames,
    };

    //OCR files
    const ocrFiles = selectedFiles.filter((item) =>
      item.filePath.includes("ocr")
    );

    const ocrFileNames =
      ocrFiles.length > 0 &&
      ocrFiles.map((item) => {
        return { name: item.filePath, uploadtype: "0" };
      });

    const ocrRequest = {
      sessionId: meetingID,
      Files: ocrFileNames,
    };

    if (selectedFileTab === SESSION_FILES_TYPE.all) {
      const allFilesNames = concat(chatFileNames, ocrFileNames);
      setDeletedFiles(allFilesNames);

      // Delete chat file API call
      chatFileNames && dispatch(deleteSessionChatFiles(chatRequest));
      //Delete ocr file API call
      ocrFileNames && dispatch(deleteSessionOcrFiles(ocrRequest));
    } else if (selectedFileTab === SESSION_FILES_TYPE.chat) {
      setDeletedFiles(chatFileNames);

      // Delete chat file API call
      chatFileNames && dispatch(deleteSessionChatFiles(chatRequest));
    } else if (selectedFileTab === SESSION_FILES_TYPE.extract) {
      setDeletedFiles(ocrFileNames);

      //Delete ocr file API call
      ocrFileNames && dispatch(deleteSessionOcrFiles(ocrRequest));
    }

    handleCancelSelectedFiles();
  };

  const handleSelectFileTab = (type) => {
    setSelectedFileTab(type);
    handleCancelSelectedFiles();
  };

  const getSelectedFilesCount = () => {
    let filesCount;
    const allTitle = localisedValues["user_all"];
    if (selectedFileTab === SESSION_FILES_TYPE.all) {
      filesCount =
        selectedFiles.length === allFilesData.length
          ? allTitle
          : `(${selectedFiles.length})`;
    } else if (selectedFileTab === SESSION_FILES_TYPE.chat) {
      filesCount =
        selectedFiles.length === chatFilesData.length
          ? allTitle
          : `(${selectedFiles.length})`;
    } else if (selectedFileTab === SESSION_FILES_TYPE.extract) {
      filesCount =
        selectedFiles.length === ocrFilesData.length
          ? allTitle
          : `(${selectedFiles.length})`;
    }

    return filesCount;
  };

  const handleLoadingOnDelete = () => {
    if (selectedFileTab === SESSION_FILES_TYPE.all) {
      return deleteSessionChatFilesLoading || deleteSessionOcrFilesLoading;
    } else if (selectedFileTab === SESSION_FILES_TYPE.chat) {
      return deleteSessionChatFilesLoading;
    } else {
      return deleteSessionOcrFilesLoading;
    }
  };

  return (
    <div className="tab-pane fade" id="session-file" role="tabpanel" data-testid='session-file-main'>
      {allFilesData.length === 0 ? (
        <div className="no-session-data custom-scroll-div">
          <div className="no-session-data-in">
            <div className="no-session-data-box">{noFilesView}</div>
          </div>
        </div>
      ) : (
        <div className="session-files">
          <div className="files-tab-nav">
            <div className="files-tab-nav-in">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#session-all-files"
                    data-testid="AllFilesBtn"
                    type="button"
                    role="tab"
                    onClick={() => handleSelectFileTab(SESSION_FILES_TYPE.all)}
                  >
                    <span className="nav-item-text">
                      {localisedValues["user_all"]}
                    </span>
                    <span className="nav-count">({allFilesData.length})</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#session-chat-files"
                    data-testid="ChatFilesBtn"
                    type="button"
                    role="tab"
                    onClick={() => handleSelectFileTab(SESSION_FILES_TYPE.chat)}
                  >
                    <span className="nav-item-text">
                      {localisedValues["chat_key"]}
                    </span>
                    <span className="nav-count">({chatFilesData.length})</span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#session-extext-files"
                    data-testid="ExtractedFilesBtn"
                    type="button"
                    role="tab"
                    onClick={() =>
                      handleSelectFileTab(SESSION_FILES_TYPE.extract)
                    }
                  >
                    <span className="nav-item-text">
                      {localisedValues["extracted_text"]}
                    </span>
                    <span className="nav-count">({ocrFilesData.length})</span>
                  </button>
                </li>
              </ul>
              {selectedFiles.length > 0 && (
                <div className="button-inline session-image-action-btn">
                  <div className="button">
                    <button
                      type="button"
                      className="btn btn-outline-secondary border-round"
                      onClick={handleCancelSelectedFiles}
                      data-testid="CancelBtn"
                    >
                      {localisedValues["user_cancel"]}
                    </button>
                  </div>
                  {getSessionDetailsData.session.ownerUserId ===
                    storage.get(LOCAL_STORAGE_KEYS.UCID) && (
                    <div className="button">
                      <button
                        type="button"
                        className="btn btn-outline-secondary border-round"
                        data-bs-target="#delete-files-modal"
                        onClick={() => setIsShowDeleteModal(true)}
                        data-testid="DeleteBtn"
                      >
                        {localisedValues["delete"]}{" "}
                        <span>{getSelectedFilesCount()}</span>
                      </button>
                    </div>
                  )}
                  <div className="button">
                    <button
                      type="button"
                      className="btn btn-primary border-round"
                      onClick={handleDownloadAllFiles}
                      data-testid="DownloadBtn"
                    >
                      {localisedValues["download_key"]}{" "}
                      <span>{getSelectedFilesCount()}</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="tab-content">
            {/* All, Chat and Ocr files table view  */}
            <div
              className="tab-pane fade show active"
              id={clsx("tab-pane", "fade", "show", "active", {
                "session-all-files": selectedFileTab === SESSION_FILES_TYPE.all,
                "session-chat-files":
                  selectedFileTab === SESSION_FILES_TYPE.chat,
                "session-extext-files":
                  selectedFileTab === SESSION_FILES_TYPE.extract,
              })}
              role="tabpanel"
            >
              {selectedFileTab === SESSION_FILES_TYPE.all && (
                <FilesTableView
                  key={allFilesData}
                  filesData={allFilesData}
                  setFilesData={setAllFilesData}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  columnsToShow={columnsToShow}
                />
              )}
              {selectedFileTab === SESSION_FILES_TYPE.chat && (
                <FilesTableView
                  key={chatFilesData}
                  filesData={chatFilesData}
                  setFilesData={setChatFilesData}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  columnsToShow={columnsToShow}
                />
              )}
              {selectedFileTab === SESSION_FILES_TYPE.extract && (
                <FilesTableView
                  key={ocrFilesData}
                  filesData={ocrFilesData}
                  setFilesData={setOcrFilesData}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  columnsToShow={columnsToShow}
                />
              )}
            </div>
          </div>

          {isShowDeleteModal && (
            <DeleteModal
              setIsModalVisible={setIsShowDeleteModal}
              selectedFiles={selectedFiles}
              loading={handleLoadingOnDelete()}
              handleOnDelete={handleDeleteFiles}
              localisedValues={localisedValues}
              headerTitleLocalisedKey="delete_popup_filecount"
              bodyContentLocalisedKey="delete_filepopup_text"
            />
          )}
        </div>
      )}
    </div>
  );
};

FilesTab.propTypes = {
  meetingID: PropTypes.string,
  details: PropTypes.object,
  setDetails: PropTypes.func,
};

export default FilesTab;
