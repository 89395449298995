import { AUTH_LOGIN, EVENTS, SSO_LOGIN } from "../reducers/ssoAuthLogin-reducer";
import SSOAuthLoginService from "../services/ssoAuthLoginService";
import {afterLoginProcess, handleErrorMsgToastVisiblity} from "../shared/utility"
import { fetchDomainList } from "./onLoadFunctionAction";

const ssoLoginStart = () => {
    return {
        event: EVENTS.SSO_LOGIN,
        type: SSO_LOGIN.START
    }
}

const ssoLoginSuccess = (userdata) => {
    return {
        event: EVENTS.SSO_LOGIN,
        type: SSO_LOGIN.SUCCESS,
        payload: userdata
    }
}

const ssoLoginError = (error) => {
    return {
        event: EVENTS.SSO_LOGIN,
        type: SSO_LOGIN.ERROR,
        payload: error
    }
}

export const SSOLoginUser = (token) => async (dispatch) => {
    dispatch(ssoLoginStart());

    SSOAuthLoginService
        .postSSOLogIn(token)
        .then((response) => {
            dispatch(fetchDomainList())
            afterLoginProcess(response);
            dispatch(ssoLoginSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(ssoLoginError(error));
        })
}


const authLoginStart = () => {
    return {
        event: EVENTS.AUTH_LOGIN,
        type: AUTH_LOGIN.START
    }
}

const authLoginSuccess = (userdata) => {
    return {
        event: EVENTS.AUTH_LOGIN,
        type: AUTH_LOGIN.SUCCESS,
        payload: userdata
    }
}

const authLoginError = (error) => {
    return {
        event: EVENTS.AUTH_LOGIN,
        type: AUTH_LOGIN.ERROR,
        payload: error
    }
}

export const authLoginUser = (reqData) => async (dispatch) => {
    dispatch(authLoginStart());

    SSOAuthLoginService
        .postAuthLogIn(reqData)
        .then((response) => {
            dispatch(fetchDomainList())
            afterLoginProcess(response);
            dispatch(authLoginSuccess(response));
        })
        .catch((error) => {
            handleErrorMsgToastVisiblity(error);
            dispatch(authLoginError("Invalid parameters, please check and try again."));
        })
}

