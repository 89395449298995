import "./SelfSignup";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import companyLogo from "../../assets/images/signup-logo.svg";
import { Card } from "../UI/Card/Card";
import clsx from "clsx";
import history from "../../history";
import storage from "../../services/storage";
import { ContactURL, LOCAL_STORAGE_KEYS_ADMIN, LOCAL_STORAGE_KEYS, RouteName } from "../../constants/Constants";
import { Localize } from "./Localize";

const SelfSignup = ({showSignIn=false,companyCreated=false, ...otherProps}) => {
  const [localisedValues, setLocalisedValues] = useState({});

  useEffect(() => {
    const curLang = otherProps.language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [otherProps.language.selectedlanguage]);


    const redirectToLogin = () => {
        history.push(RouteName.LOGIN);
    }

    useEffect(() => {
        const configdata = storage.get(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA); 
        if (configdata) {
            const currentcompany = storage.get(LOCAL_STORAGE_KEYS.CURRENT_COMPANY);
            if(currentcompany?.UCID) {  
                if(history.location.pathname !== RouteName.INVITES){
                    history.replace(RouteName.SESSIONS);
                } else{
                    if(storage.get(LOCAL_STORAGE_KEYS.USER_INVITED) === 'True'){
                        history.replace(RouteName.SESSIONS);
                    }
                }                 
            }else {                    
                history.replace(RouteName.LOGIN);
            }
        }  
    }, []);

    const fullText = localisedValues["company_created_popup_description_1"];
    const subText = localisedValues["one_business_day"];
    const formattedTextParts = fullText?.split(subText);

    return (
      <div className={clsx("login-section-box sso-login-section-box", !showSignIn && "no-header")}>
        <div className="login-section-in">
            <div className={clsx("signup-out-box", companyCreated && "signup-account-created")}>
              {!companyCreated && <div className="signup-logo">
                <img src={companyLogo} alt="logo" data-testid="companyLogo" />
              </div>}
              <Card>
                {
                  !companyCreated ? otherProps.children :  
                  <div className="signup-in-scroll custom-scroll-div">
                  <div className="success-image">
                    <span className="for-icon icon-check-circle"></span>
                  </div>
                  <h2>{localisedValues["your_account_has_been_created_successfully"]}</h2>
                  <p>{localisedValues["company_ceated_popup_description"]}</p>
                  <p>
                    {formattedTextParts[0]}<span className="highlight-text">{subText}</span>{formattedTextParts[1]}
                  </p>
                  <div className="button-inline d-flex justify-content-center padding-top-15">
                    <div className="button">
                      <button className="btn btn-outline-primary font-weight-500" onClick={()=> history.push(RouteName.LOGIN)}>
                        {localisedValues["back_to_login"]}
                      </button>
                    </div>
                    <div className="button">
                      <button className="btn btn-primary font-weight-500" onClick={()=> window.open(ContactURL, "_blank")}>
                      {localisedValues["contact_us"]}
                      </button>
                    </div>
                  </div>
                  </div>
                }
              </Card>
              {showSignIn && (
                <div className="login-bottom-text padding-top-0">
                  <div className="have-an-account text-center">
                    Already have an account?{" "}
                    <a
                      href={void 0}
                      className="text-dec-none pointer"
                      onClick={redirectToLogin}
                    >
                      Sign In
                    </a>
                  </div>
                </div>
              )}
            </div>
        </div>
      </div>
    );
}

SelfSignup.propTypes = {
  showSignIn: PropTypes.bool,
  companyCreated: PropTypes.bool,
};

const mapStateToPropsGetStarted = (state) => {
  return {
      language: state.language,
  }
}

export default connect(mapStateToPropsGetStarted)(SelfSignup);
