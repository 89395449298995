
import "./CreateSessionModal.scss";
import history from "../../../history";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import clsx from "clsx";
import "react-datetime/css/react-datetime.css";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { PopupModal } from "../../UI/Modal/Modal";
import PublicVariables from "../../../constants/PublicVariables";
import { checkRegexPattern, filterParams, handleErrorMsgToastVisiblity, parseURLParams, replaceSpecialChar } from "../../../shared/utility";
import { DatePicker, Timepicker, renderDay, renderMonth, renderYear } from "./DateTimePicker";
import { createSession, createSessionReset, getOwnCPList, editScheduleSession, editSessionScheduleReset } from "../../../actions/sessionListAction";
import { ONLY_NUMERIC_REGEX, RouteName, LONG_DATEPICKER_FORMAT, TIME_FORMAT, LOCAL_STORAGE_KEYS, CREATE_SESSION_FROM } from "../../../constants/Constants";
import { disablePrviousDate, generateScheduleSessionPayload, handleOnDateTimeChange, initializeDateAndTimePicker, initializeTimeZone } from "./utils";
import { Localize } from "../Localize";
import storage from "../../../services/storage";


const CreateSessionModal = (props) => {
    const { closeModal, inviteBuddyData = {}, isForEdit = false, sessionDetails = {} } = props
    const dispatch = useDispatch()
    const {
        getOwnCPListLoading,
        getOwnCPListData,
        createSessionsLoading,
        createSessionsData,
        createSessionsError,
        putScheduleSessionsLoading,
        putScheduleSessionsData,
        putScheduleSessionsError,
    } = useSelector((state) => state.sessionList);
    const { language } = useSelector((state) => state)

    const [ownCPList, setOwnCPList] = useState([])
    const [selectedColabrationProfile, setSelectedColabrationProfile] = useState({})
    const [sessionTitle, setSessionTitle] = useState('')
    const [error, setError] = useState(false)
    const [isScheduleSession, setIsScheduleSession] = useState(isForEdit)
    const [timeZonesList, setTimeZonesList] = useState([])
    const [selectedTimeZone, setSelectedTimeZone] = useState({})
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [durationOfSession, setDurationOfSession] = useState('30m')
    const [isDateTimeValid, setIsDateTimeValid] = useState(true)
    const [isDateDiffLimitExceeded, setIsDateDiffLimitExceeded] = useState(false)
    const [localisedValues, setLocalisedValues] = useState({});
    const [isDateTimeFieldFocused, setIsDateTimeFieldFocused] = useState(!isForEdit)
    const startDateRef = useRef()
    const startTimeRef = useRef()
    const endDateRef = useRef()
    const endTimeRef = useRef()
    const referenceInputRef = useRef()
    const dataForSession = useMemo(() => { return parseURLParams(storage.get(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION) || '') }, []);

    const getAllTimeZones = useMemo(() => {
        const timeZones = moment.tz.names().map(tz => ({
            timezoneWithOffset: " (GMT" + moment.tz(tz).format('Z') + ")" + tz,
            timezone: tz
        }))
        return timeZones
    }, [])

    useEffect(() => {
        // Setting Session start , end time & timeZone for edit session schedule
        if (isForEdit && !isEmpty(sessionDetails)) {
            const selectedProfile = {
                name: sessionDetails.session.collaborationProfileName,
                collaborationProfileId: sessionDetails.session.collaborationProfileId
            }
            setSelectedColabrationProfile(selectedProfile)
            setOwnCPList([selectedProfile])
            const seesion_title = isEmpty(sessionDetails.session.reference) ? 'N/A' : sessionDetails.session.reference
            setSessionTitle(seesion_title)
        }
    }, [sessionDetails])

    useEffect(() => {
        const curLang = language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
    }, [language.selectedlanguage]);

    useEffect(() => {
        referenceInputRef?.current?.focus();
        initializeTimeZone({ setTimeZonesList, setSelectedTimeZone, getAllTimeZones, isForEdit, sessionDetails })
        initializeDateAndTimePicker({ setStartDate, setEndDate, setStartTime, setEndTime, isForEdit, sessionDetails })
        /* The below code is checking if the result of the `getOwnCPListData` function is empty. If it
        is empty & isFor not edit than, it dispatches the `getOwnCPList` action. */
        if (isEmpty(get(getOwnCPListData, 'items', [])) && !isForEdit) {
            dispatch(getOwnCPList());
        }

    }, []);

    useEffect(() => {
        if (isForEdit) return
        if (getOwnCPListLoading) return
        const cpListData = get(getOwnCPListData, 'items', [])
        setOwnCPList(cpListData)
        if (dataForSession.callexperience) {
            const callExp = cpListData.find(obj => obj.name === dataForSession.callexperience);
            setSelectedColabrationProfile(callExp ?? cpListData[0]);
        } else {
            setSelectedColabrationProfile(cpListData[0])
        }
        setSessionTitle(dataForSession.ref ?? '')
    }, [getOwnCPListData])

    useEffect(() => {
        if (createSessionsLoading) return
        if (createSessionsData) {
            handleCloseModal()
            history.push({ pathname: RouteName.SEND_CODE, state: {buddyData: inviteBuddyData} })
        }
        else if (createSessionsError) {
            // Resetting create Sessions Store data if any error accured during Creating Session 
            dispatch(createSessionReset())
            handleErrorMsgToastVisiblity(createSessionsError.message)
        }
    }, [createSessionsLoading, createSessionsData, createSessionsError])

    useEffect(() => {
        if (putScheduleSessionsLoading) return
        if (putScheduleSessionsData) {
            handleCloseModal()
            history.push({ pathname: RouteName.SEND_CODE,  state: {isFromChangeSchedule : true}})
            dispatch(editSessionScheduleReset())
        }
        else if (putScheduleSessionsError) {
            // Resetting Edit Sessions Store data if any error accured during Editing Session 
            dispatch(editSessionScheduleReset())
            handleErrorMsgToastVisiblity(putScheduleSessionsError.message)
        }
    }, [putScheduleSessionsLoading, putScheduleSessionsData, putScheduleSessionsError])

  useDidMountEffect(() => {
      if (moment.isMoment(startDate) && moment.isMoment(endDate) && moment.isMoment(startTime) && moment.isMoment(endTime))
          handleOnDateTimeChange({ startDate, endDate, startTime, endTime, selectedTimeZone, setIsDateDiffLimitExceeded, setIsDateTimeValid, setDurationOfSession, isForEdit });
  }, [startDate, endDate, startTime, endTime]);

    const handleCloseModal = (directClick = false) => {
        if (directClick && !isEmpty(dataForSession)) storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION)
        closeModal();
    }

  const handleOnStartDateChange = (v) => {
    setStartDate(v);
    if (moment.isMoment(v) && v.isValid() && v.isAfter(endDate)) {
      setEndDate(v);
    }
  };

    const handleOnCpChange = (e) => {
        const selectedCp = ownCPList.filter((cp) => cp.collaborationProfileId == e.target.value)[0] || {}
        setSelectedColabrationProfile(selectedCp)
    }

    const handleSubmit = () => {
        let payload = {
            sessionType: 1,
            reference: sessionTitle.trim(),
            collaborationProfileId: selectedColabrationProfile.collaborationProfileId,
            from: !isEmpty(dataForSession) ? CREATE_SESSION_FROM.PARAMETERISED_URL : CREATE_SESSION_FROM.REGULAR, //// if creating session on parameterised URL, than set from value to 7 as per decision on Sasktel issue
        }

        // Adding Extra paramas
        const extraParams = filterParams(window.location.search.replace('?', ''))

        if (extraParams.length > 0 && extraParams[0] !== "") {
            let extraParamsStr = ""
            extraParamsStr = '&' + extraParams.join("&")
            if (extraParamsStr) {
                payload.queryString = extraParamsStr
            }
        }

        if (isScheduleSession) {
            const scheduleSessionPayload = generateScheduleSessionPayload({ startDate, endDate, startTime, endTime, selectedTimeZone })
            payload = { ...payload, ...scheduleSessionPayload }
        }

        if (isForEdit) {
            // Removing Extra Keys from Payload
            delete payload.sessionType
            delete payload.reference
            payload.sessionId = sessionDetails.session.sessionId
            dispatch(editScheduleSession(payload))
        } else {
            dispatch(createSession(payload))
        }
    }

    const handleSessionTitleChange = (e) => {
        const val = e.target.value
        let erroMsg = false
        if (val.length > 50) {
            erroMsg = localisedValues['crt_acnt_limit_character_msg_fifty']
        }
        else if (PublicVariables.ldFlags.referenceRequired && isEmpty(val.trim())) {
            erroMsg = localisedValues['reference_required']
        }
        else if (PublicVariables.ldFlags.referenceType == 0 && !checkRegexPattern(ONLY_NUMERIC_REGEX, val.trim())) {
            erroMsg = localisedValues['numeric_characters_allowed']
        }
        setSessionTitle(val)
        setError(erroMsg)
    }

    const handleOnTimeZoneSerach = (e) => {
        const searchVal = e.target.value.toLowerCase().trim()
        const filteredData = getAllTimeZones.filter((tz) => tz.timezoneWithOffset.toLowerCase().includes(searchVal))
        setTimeZonesList(filteredData)
    }

    const handleOnScheduleSessionSwitchChange = () => {
        setIsScheduleSession(!isScheduleSession)
        if (isScheduleSession) {
            initializeTimeZone({ setTimeZonesList, setSelectedTimeZone, getAllTimeZones, isForEdit, sessionDetails })
            initializeDateAndTimePicker({ setStartDate, setEndDate, setStartTime, setEndTime, isForEdit, sessionDetails })
            setDurationOfSession('30m')
            setIsDateTimeValid(true)
            setIsDateDiffLimitExceeded(false)
        }
    }

    const convertMinutesToExact = (minute) => {
      let updatedMintue = minute;
      if (minute > 0 && minute <= 15) {
        updatedMintue = 15;
      } else if (minute > 15 && minute <= 30) {
        updatedMintue = 30;
      } else if (minute > 30 && minute <= 45) {
        updatedMintue = 45;
      } else  {
        updatedMintue = 0;
      }
      return updatedMintue;
    };

  function handleOnTimeChnage(currentTime, isForStartTime = false) {
    let prevTimeHour, prevTimeMin
    isForStartTime ? setStartTime(currentTime) : setEndTime(currentTime);

    if (moment.isMoment(currentTime) && !['0', '15', '30', '45', 0, 15, 30, 45].includes(currentTime.minutes())) {
      let currentMin = currentTime.minutes()
      const updatedTime = currentTime.clone();
      currentMin = convertMinutesToExact(currentMin);
      updatedTime.set('minute', currentMin);
      isForStartTime ? setStartTime(updatedTime) : setEndTime(updatedTime);
      return
    }

    if (!moment.isMoment(currentTime) || !moment.isMoment(startTime) || !moment.isMoment(endTime)) return

    if (isForStartTime) {
      prevTimeHour = startTime.hours();
      prevTimeMin = startTime.minutes();
    } else {
      prevTimeHour = endTime.hours();
      prevTimeMin = endTime.minutes();
    }

    let currentstartTimeHour = currentTime.hours();
    let currentstartTimeMin = currentTime.minutes();
    currentstartTimeMin = convertMinutesToExact(currentstartTimeMin)
    let modifiedTime = currentTime;

    if (
      prevTimeHour === currentstartTimeHour &&
      prevTimeMin === 45 &&
      currentstartTimeMin === 0
    ) {
      modifiedTime = currentTime.clone().add(1, "hour"); // Create a modified copy
    } else if (
      prevTimeHour === currentstartTimeHour &&
      prevTimeMin === 0 &&
      currentstartTimeMin === 45
    ) {
      modifiedTime = currentTime.clone().subtract(1, "hour"); // Create a modified copy
    }

    isForStartTime ? setStartTime(modifiedTime) : setEndTime(modifiedTime);
  }

  const onInputKeyDown = (e) => {
    if (e.key === "Enter") {
      const nextButton = document.querySelector(".next-btn");
      if (nextButton) {
        nextButton.click();
      }
    }
  };

    return (
        <PopupModal
            closeModal={() => handleCloseModal(true)}
            modalClass="modal-medium new-session-modal"
            dialogClass="modal-dialog-centered"
            closeButton={true}
            headerContent={
                <div className="modal-header-step">
                    <div className="modal-header-step-top">
                        {localisedValues['step']} 1 {localisedValues['user_of']} 2 <span className="next-icon icon-right-step"></span>
                    </div>
                    <div className="modal-header-step-mid">
                        {localisedValues['enter_session_details']}
                    </div>
                </div>
            }
            bodyContent={
                <div className="new-session-form">
                    {!isEmpty(inviteBuddyData) && <div className="invite-domain-info" data-testid='inviteBuddyDetails'>
                        <div className="invite-domain-info-logo">
                            <div className="image" style={{ backgroundImage: `url(${inviteBuddyData.USER.image})` }}></div>
                        </div>
                        <div className="invite-domain-info-text">
                            <h3>{inviteBuddyData.USER.name}</h3>
                            <p>{inviteBuddyData.USER.role}</p>
                        </div>
                    </div>}

                    <div className="form-group">
                        {(ownCPList.length > 1 || isForEdit) &&
                            <div className="form-group-in with-icon">
                                <select disabled={isForEdit} className="form-control form-control-grey height-46 focus-font-bold" onChange={handleOnCpChange} value={selectedColabrationProfile.collaborationProfileId} title={localisedValues['select_call_experience']} data-testid='cpDropDownSelect'>
                                    {ownCPList.map((cp) => {
                                        return (
                                            <option value={cp.collaborationProfileId} key={cp.collaborationProfileId}>{replaceSpecialChar(cp.name)}</option>
                                        )
                                    })}
                                </select>
                                <span className="for-bg"></span>
                                {!isForEdit && <span className="form-group-icon bg-none">
                                    <span className="form-group-icon-in icon-arrow-down"></span>
                                </span>}
                            </div>
                        }
                    </div>
                    <div className="form-group">
                        <div className="form-group-in">
                            <input ref={referenceInputRef} className="form-control form-control-grey height-46 focus-font-bold" type="text" placeholder={localisedValues['add_title'] + '/' + localisedValues['reference']} data-testid='sessionTitleInput' value={sessionTitle} onChange={handleSessionTitleChange}
                                disabled={isForEdit || !isEmpty(dataForSession)} onKeyDown={(e)=> onInputKeyDown(e)} autoFocus />
                        </div>
                        {error && <div className="validation-error">{error}</div>}
                    </div>
                    {PublicVariables.configurations?.sessionscheduling === '1' && isEmpty(dataForSession) && <div className="schedule-session-box">
                        <div className="schedule-session-mark">
                            <div className="mark-text">
                                {localisedValues['schedule_session']}
                            </div>
                            <div className={clsx("on-off-switch collapsed", isForEdit ? 'disable-on-off-switch' : 'disable-on-off-switch1')} data-bs-toggle="collapse" data-bs-target="#schedule-session-time" style={{ pointerEvents: isForEdit ? 'none' : '' }}>
                                <label>
                                    <input type="checkbox" checked={isScheduleSession} onChange={handleOnScheduleSessionSwitchChange} data-testid='scheduleSessionSwitch' disabled={isForEdit} />
                                    <span className="for-design"></span>
                                </label>
                            </div>
                        </div>
                        {isScheduleSession && <div className="schedule-session-time clearfix collapse show" id="schedule-session-time">
                            <div className="schedule-session-time-in">
                                <label className="form-label font-weight-400">{localisedValues['date_time']}</label>
                                <div className="schedule-session-duration">{durationOfSession}</div>
                                <div className="session-time-row clearfix">
                                    <div className="session-time-frm clearfix">
                                        <div className="session-date">
                                            <Datetime timeFormat={false} value={startDate} onChange={(v) => handleOnStartDateChange(v)} renderInput={DatePicker} dateFormat={LONG_DATEPICKER_FORMAT} closeOnSelect={true} isValidDate={disablePrviousDate}
                                                renderDay={renderDay}
                                                renderMonth={renderMonth}
                                                renderYear={renderYear}
                                                inputProps={{ onBlur: () => setIsDateTimeFieldFocused(true), ref: startDateRef }}
                                            />
                                        </div>
                                        <div className="session-time">
                                            <Datetime dateFormat={false} value={startTime} onChange={(v) => handleOnTimeChnage(v, true)} renderInput={Timepicker} timeFormat={TIME_FORMAT} timeConstraints={{ minutes: { step: 15 } }} inputProps={{ onBlur: () => setIsDateTimeFieldFocused(true), ref: startTimeRef }} />
                                        </div>
                                    </div>
                                    <div className="session-time-to">
                                        {localisedValues['to_key']}
                                    </div>
                                    <div className="session-time-frm clearfix">
                                        <div className="session-date">
                                            <Datetime timeFormat={false} value={endDate} onChange={setEndDate} renderInput={DatePicker} dateFormat={LONG_DATEPICKER_FORMAT} closeOnSelect={true} isValidDate={(current) => disablePrviousDate(current, startDate)} inputProps={{ onBlur: () => setIsDateTimeFieldFocused(true), ref: endDateRef }} />
                                        </div>
                                        <div className="session-time">
                                            <Datetime dateFormat={false} value={endTime} onChange={(v) => handleOnTimeChnage(v)} renderInput={Timepicker} timeFormat={TIME_FORMAT} timeConstraints={{ minutes: { step: 15 } }} inputProps={{ onBlur: () => setIsDateTimeFieldFocused(true), ref: endTimeRef }} />
                                        </div>
                                    </div>
                                </div>
                                {isDateTimeFieldFocused && !isDateTimeValid && <div className="validation-error">{localisedValues['enter_valid_start_and_end_date']}</div>}
                                {isDateTimeFieldFocused && isDateTimeValid && isDateDiffLimitExceeded && <div className="validation-error">{localisedValues['schedule_session_diff_limit_error']}.</div>}

                                <div className="session-time-zone-box">
                                    <div className="session-time-zone-in">
                                        <div className="time-zone-mark">
                                            {localisedValues['time_zone']}:
                                        </div>
                                        <div className="dropdown session-time-zone-dropdown">
                                            <button className="btn dropdown-toggle triangle-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-testid='timeZoneSelectionDiv'>
                                                <span className="btn-text" data-testid='timeZoneSelectedValue'>{selectedTimeZone.timezoneWithOffset}</span>
                                                <span className="btn-icon icon-arrow-down"></span>
                                            </button>
                                            <div className="dropdown-menu right" aria-labelledby="dropdown" data-testid='timeZoneListDiv'>
                                                <div className="dropdown-menu-in">
                                                    <div className="search-form right-icon time-zone-search">
                                                        <div className="form-group">
                                                            <div className="form-group-in">
                                                                <span className="for-icon icon-search"></span>
                                                                <input className="form-control form-control-grey" type="text" placeholder="Search" onChange={handleOnTimeZoneSerach} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="custom-scroll-div-thin">
                                                        {timeZonesList.map((timeZone) => {
                                                            return (
                                                                <li key={timeZone.timezoneWithOffset}><button className="btn dropdown-item" data-testid='timeZoneItem' onClick={() => {
                                                                    setSelectedTimeZone(timeZone);
                                                                }}>{timeZone.timezoneWithOffset}</button></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-tooltip">
                                            <div className="custom-tooltip-icon">
                                                <span className="icon-info"></span>
                                            </div>
                                            <div className="custom-tooltip-content">
                                                <div className="custom-tooltip-in">
                                                    <p>{localisedValues['default_timezone_text']}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>}
                </div>
            }
            disablekeyboard={true}
            backdrop='static'
            footerContent={
                <div className="button-inline d-flex">
                    <div className="button">
                        <button type="button" className="btn btn-primary btn-with-icon font-weight-500 next-btn" data-testid='nextBtn' onClick={handleSubmit} disabled={(isEmpty(ownCPList) || createSessionsLoading || putScheduleSessionsLoading || error || (PublicVariables.ldFlags.referenceRequired && isEmpty(sessionTitle) && isEmpty(dataForSession)) || (isScheduleSession && (!isDateTimeValid || isDateDiffLimitExceeded)))}>
                            {(createSessionsLoading || putScheduleSessionsLoading) && <span className="btn-loader-icon"><span className="for-icon icon-loader spin"></span></span>}
                            {isForEdit ? localisedValues['save_changes'] : localisedValues['next']} <span className="btn-next-arrow icon-right-arrow"></span></button>
                    </div>
                </div>
            }
        />
    )
}

CreateSessionModal.propTypes = {
    closeModal: PropTypes.func,
    inviteBuddyData: PropTypes.object,
    isForEdit: PropTypes.bool,
    sessionDetails: PropTypes.object,
}

export default CreateSessionModal;