import axios from "axios";
import __ from "lodash";
import * as deviceInfo from 'react-device-detect';
import {
    APIs, BASEURL, CLIENTAPPID, DEVICETYPE, APPVERSION, DefaultLanguage,
    LOCAL_STORAGE_KEYS,
    INVALID_DOMAIN_URL,
    LOCAL_STORAGE_KEYS_ADMIN,
    CLOUD_STORAGE_KEY,
} from "../constants/Constants";
import PublicVariables from "../constants/PublicVariables";
import storage from "../services/storage";
import LoginAPI from "./loginApi";
import LanguageAPI from "./languageApi";
import OnLoadFunctionAPI from "./onLoadFunctionApi";
import SignUpAPI from "./signUpApi";
import SessionListAPI from "./sessionListApi";
import SessionDetailAPI from "./sessionDetailApi";
import ChangePasswordAPI from "./changePasswordApi";
import SSOAuthLoginAPI from "./ssoAuthLoginApi";
import SelfSignupAPI from "./selfSignupApi";
import { checkConfigAPIcall, handleErrorMsgToastVisiblity } from "../shared/utility";
import ActiveUsersAPI from "./activeUsersApi";
import { Localize } from "../components/Login/Localize";

const bearerKey = "Bearer "
const API_STATUS = {
    SUCCESS: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER: 500,
    ERR_NETWORK: 0,
    FORBIDDEN: 403
}
const errorFunction = (error) => {
    if (error.response) {
        const statusCode = error.response?.status;
        if (statusCode === API_STATUS.NOT_FOUND) {
            if (error.config?.url) { // when domain is not exist then redirect to the invalid domain page
                if (error.config.url.includes(APIs.ISCOMPANYAVAILABLE)) {
                    // For Invalid domain Direct Hit
                    if (PublicVariables.companyPortalName || PublicVariables.isVerifyDomainClicked) {
                        PublicVariables.isVerifyDomainClicked = false;
                        handleErrorMsgToastVisiblity(Localize[PublicVariables.currentLanguage]['domain_contact_admin_msg'])
                    }
                    // For SSO Invalid domain 
                    else {
                        window.location.href = INVALID_DOMAIN_URL;
                    }
                }
            }
        } else if (statusCode === API_STATUS.INTERNAL_SERVER) {
            handleErrorMsgToastVisiblity(error.response?.data?.Message);
        } else if (statusCode === API_STATUS.UNAUTHORIZED) {
            PublicVariables.statusCode = error.response?.data?.error?.code;
            PublicVariables.message = error.response?.data?.error?.message;
            if (error.response?.data?.error?.title) {
                PublicVariables.isAlreadyToastShown = true
                PublicVariables.title = error.response?.data?.error?.title;
                document.getElementById("status-error-modal")?.click();
            }
        }
        else if (statusCode === API_STATUS.ERR_NETWORK) {
            handleErrorMsgToastVisiblity(error?.message);
        }
        //Domain is deactivated from superAdmin
        else if (statusCode === API_STATUS.FORBIDDEN) {
            window.location.href = INVALID_DOMAIN_URL
        }
    }
}
const getExactBrowserName = () => {
    const userAgent = navigator.userAgent;
    let browserName = '';
    // Check for popular browser user agent strings
    if (userAgent.includes("Firefox")) {
        browserName = 'Firefox';
    } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg") && !userAgent.includes("SamsungBrowser")) {
        browserName = 'Chrome';
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("CriOS")) {
        browserName = 'Safari';
    } else if (userAgent.includes("Edg")) {
        browserName = 'Edge';
    } else if (userAgent.includes("CriOS")) {
        browserName = 'Chrome';
    } else if (userAgent.includes("SamsungBrowser")) {
        browserName = 'Samsung Internet';
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        browserName = 'Internet Explorer';
    } else {
        console.log("Browser not recognized or using a custom user agent string.");
    }
    return browserName;
};

function getLocalBrowserInfo(header) {
    header.ucid = storage.has(LOCAL_STORAGE_KEYS.UCID) ? storage.get(LOCAL_STORAGE_KEYS.UCID) : null;

    if (PublicVariables.ldFlags?.captureDeviceAndGoogleEvent) {
        header.DEVICEMODEL = getExactBrowserName();
        header.OSVERSION = deviceInfo.browserVersion;
        header.OS = deviceInfo.osName;
        if (deviceInfo.osVersion === 'unknown' && deviceInfo.osName === 'android' && deviceInfo.browserName === 'chrome') {
            const userAgent = deviceInfo.getUA.split(' ');
            userAgent.map(function (value) {
                if (value.toLowerCase().indexOf('samsungbrowser') !== -1) {
                    header.DEVICEMODEL = "Samsung Internet";
                    header.OSVERSION = value.split('/')[1];
                }
            });
        }
    }
    header.DEVICETYPE = DEVICETYPE;
    header.APPVERSION = APPVERSION;
    header.IPADDRESS = "";
    const currentlanguage = PublicVariables.currentLanguage ? PublicVariables.currentLanguage : DefaultLanguage;
    header["Accept-Language"] = currentlanguage.replace(/_/g, '-');
    header.clientappid = CLIENTAPPID;
}

class API {

    __login = new LoginAPI();
    __changepassword = new ChangePasswordAPI();
    __ssoauthlogin = new SSOAuthLoginAPI();
    __selfSignup = new SelfSignupAPI();
    __language = new LanguageAPI();
    __onloadfunction = new OnLoadFunctionAPI();
    __signUp = new SignUpAPI();
    __sessionlist = new SessionListAPI();
    __activeUsers = new ActiveUsersAPI();
    __sessiondetail = new SessionDetailAPI();

    lat = 0; long = 0;

    API() {
        //call this function after LD initialized
        this.getCoordinates();
    }

    get login() {
        return this.__login;
    }

    get changepassword() {
        return this.__changepassword;
    }

    get activeUsers() {
        return this.__activeUsers;
    }

    get ssoauthlogin() {
        return this.__ssoauthlogin;
    }

    get SelfSignUpAPI() {
        return this.__selfSignup;
    }

    get language() {
        return this.__language;
    }

    get onloadfunction() {
        return this.__onloadfunction;
    }

    get signUp() {
        return this.__signUp;
    }

    get sessionlist() {
        return this.__sessionlist;
    }

    get sessiondetail() {
        return this.__sessiondetail;
    }

    api = axios.create({
        baseURL: BASEURL + APIs.API,
        transformRequest: [(data) => JSON.stringify(data)],
        headers: {
            Accept: "application/json",
            clientappid: CLIENTAPPID,
            "Content-Type": "application/json",
        },
    });

    blobApi = axios.create({
        baseURL: BASEURL + APIs.API,
        headers: {
            Accept: "application/octet-stream",
            clientappid: CLIENTAPPID,
        },
        responseType: 'blob',
        withCredentials: false,
    });

    formsApi = axios.create({
        baseURL: BASEURL + APIs.API,
        headers: {
            Accept: "*",
            clientappid: CLIENTAPPID,
            "Content-Type": undefined,
        },
    });

    storageApi = axios.create({
        baseURL: CLOUD_STORAGE_KEY,
        headers: {
            Accept: "*",
            'Content-Type': 'application/json',
        },
    });

    getCoordinates() {
        /* if (navigator.geolocation && PublicVariables?.configurations?.agentLocation && PublicVariables.ldFlags && PublicVariables.ldFlags.captureDeviceAndGoogleEvent) {*/
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.lat = position.coords.latitude;
                    this.long = position.coords.longitude;
                },
                (_error) => {
                    this.lat = this.long = 0;
                }
            );
        }
        else {
            this.lat = this.long = 0;
        }
    }

    get(url, ...args) {
        return this.sendRequestInternal(this.api.get, url, ...args);
    }

    post(url, ...args) {
        return this.sendRequestInternal(this.api.post, url, ...args);
    }

    postForm(url, ...args) {
        return this.sendRequestInternalForm(this.formsApi.post, url, ...args);
    }

    getBlob(url, ...args) {
        return this.sendRequestInternalBlob(this.blobApi.get, url, ...args);
    }

    patch(url, ...args) {
        return this.sendRequestInternal(this.api.patch, url, ...args);
    }

    put(url, ...args) {
        return this.sendRequestInternal(this.api.put, url, ...args);
    }

    delete(url, ...args) {
        return this.sendRequestInternal(this.api.delete, url, ...args);
    }

    getStorage(url, ...args) {
        return this.sendRequestStorage(this.storageApi.get, url, ...args);
    }

    deleteStorage(url, ...args) {
        return this.sendRequestStorage(this.storageApi.delete, url, ...args);
    }

    commonCatchErrorFunction(error) {
        console.log(error);
        let errorVal = {};
        // Handle errors
        if (error?.code === "ERR_NETWORK") {
            errorFunction(error);
        }
        else if (error.response?.data) {
            // The request was made and the server responded with a status code
            console.error('Axios response error:', error.response.status);
            errorFunction(error);
            errorVal = error.response?.data?.error;
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Axios request error:', error.request);
            errorFunction(error);
            errorVal = {
                code: error.code,
                message: error.message,
                status: error.request?.status
            }
        } else {
            // Something happened in setting up the request that triggered an error
            console.error('Axios setup error:', error.message);
            errorVal = {
                code: error.code,
                message: error.message,
                status: error?.request?.status || error?.status
            }
        }
        return errorVal;
    }

    commonSuccessFunction = async (requestFunc, url, ...args) => {
        if (storage.has(LOCAL_STORAGE_KEYS.USER_TOKEN)) { // if user logged in then only fetch header flag
            return this.refreshUserToken()
                .then((_tokenResponse) => {
                    if (storage.has(LOCAL_STORAGE_KEYS.USER_TOKEN)) {
                        this.api.defaults.headers['Authorization'] = bearerKey + storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN);
                    }
                    return requestFunc(url, ...args)
                        .then((response) => {
                            if (response.status === API_STATUS.SUCCESS || response.status === API_STATUS.CREATED) {
                                let finalResult = null;
                                if (response.headers["content-disposition"]) {
                                    finalResult = {
                                        fileString: response.data,
                                        contentDisposition: response.headers["content-disposition"]
                                    }
                                } else {
                                    finalResult = response.data;
                                }
                                const domainConfiguration = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
                                const isAPICall = checkConfigAPIcall(response.headers?.configurationmodifiedat, domainConfiguration?.configurationmodifiedat);
                                if (isAPICall) {
                                    return this.getDomainConfigurations()
                                        .then((result) => {
                                            if (result) {
                                                this.setHeadersFlag(result.data, response.headers);
                                                result.data.configurationmodifiedat = result.headers.configurationmodifiedat;
                                                storage.set(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION, result.data);
                                            }
                                            return finalResult;
                                        })

                                } else {
                                    /* calling setheader flag as getting data from the local storage*/
                                    this.setHeadersFlag(false, response.headers)
                                    return finalResult;
                                }

                            }
                            else if (response.status === API_STATUS.NO_CONTENT) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        })
                }).catch((error) => {

                    throw this.commonCatchErrorFunction(error);
                })
        } else {
            return requestFunc(url, ...args)
                .then((response) => {

                    if (response.status === API_STATUS.SUCCESS) {
                        // If Two Step Verfifaction is On Tta Time We fetching First Time Header From Email Verfication APi cause We nedd Token For Fetching Domain Configuration & in Login APi we will not get if Two step Auth is on, Sign up also
                        if (response.config.url === APIs.LOGIN || response.config.url === APIs.EMAIL_VERIFICATION || response.config.url.includes(APIs.SSO_LOGIN) || response.config.url === APIs.AUTH_LOGIN || response.config.url === APIs.REGISTER_USER) { // first time fetch configurations
                            if (response.data.otpVerificationRequired) {
                                return response.data;
                            }
                            else {
                                return this.getDomainConfigurations(response.data.userToken)
                                    .then((result) => {
                                        if (result) {
                                            this.setHeadersFlag(result.data, response.headers);
                                            result.data.configurationmodifiedat = result.headers.configurationmodifiedat;
                                            storage.set(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION, result.data);
                                        }
                                        return response.data;
                                    })
                            }
                        } else {
                            this.setHeadersFlag(false, response.headers);
                            return response.data;
                        }

                    }
                    else if (response.status === API_STATUS.NO_CONTENT) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })
                .catch((error) => {
                    // this code is using in self sign up flow if domain don't exist then allow to enter it
                    if (args.length > 0 && args[0].from === "get-started") {
                        return { Errorcode: 1 }
                    }
                    throw this.commonCatchErrorFunction(error);
                });
        }
    }

    sendRequestInternal(requestFunc, url, ...args) {
        if (args.length > 0) {
            if (args[0].hasOwnProperty('headers')) {
                Object.keys(args[0].headers).forEach((key) => {
                    this.api.defaults.headers[key] = args[0].headers[key];
                });
                console.log(this.api.defaults.headers);
                args = [__.omit(args[0], ['headers'])];
            }
        }
        if (url === APIs.EMAIL_VERIFICATION || url === APIs.LOGIN) {
            this.api.defaults.withCredentials = true
        }
        getLocalBrowserInfo(this.api.defaults.headers);
        this.api.defaults.headers['ULAT'] = this.lat;
        this.api.defaults.headers['ULONG'] = this.long;
        return this.commonSuccessFunction(requestFunc, url, ...args);
    }

    sendRequestStorage(requestFunc, url, ...args) {
        if (storage.has(LOCAL_STORAGE_KEYS.USER_TOKEN)) {

            getLocalBrowserInfo(this.storageApi.defaults.headers);
            this.storageApi.defaults.headers['Usertoken'] = storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN)
            this.storageApi.defaults.headers['ULAT'] = this.lat;
            this.storageApi.defaults.headers['ULONG'] = this.long;

            return this.refreshUserToken()
                .then((_tokenResponse) => {
                    if (storage.has(LOCAL_STORAGE_KEYS.USER_TOKEN)) {
                        this.storageApi.defaults.headers['Usertoken'] = storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN)
                    }
                    return requestFunc(url, ...args)
                        .then((response) => {
                            if (response.data.success > 0) {
                                const domainConfiguration = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
                                const isAPICall = checkConfigAPIcall(response.headers?.configurationmodifiedat, domainConfiguration?.configurationmodifiedat);
                                if (isAPICall) {
                                    return this.getDomainConfigurations()
                                        .then((result) => {
                                            if (result) {
                                                this.setHeadersFlag(result.data, response.headers);
                                                result.data.configurationmodifiedat = result.headers.configurationmodifiedat;
                                                storage.set(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION, result.data);
                                            }
                                            return response.data;
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        })
                                } else {
                                    /* calling setheader flag as getting data from the local storage*/
                                    this.setHeadersFlag(false, response.headers)
                                    return response.data;
                                }

                            }
                            else {
                                return false;
                            }
                        })
                }).catch((error) => {
                    throw this.commonCatchErrorFunction(error);
                })
        } else {
            console.log("You are logged out from this device");
        }
    }

    sendRequestInternalForm(requestFunc, url, ...args) {
        const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
        if (userData?.userToken) {
            this.formsApi.defaults.headers['Authorization'] = bearerKey + storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN);
        }
        return this.commonSuccessFunction(requestFunc, url, ...args);
    }

    sendRequestInternalBlob(requestFunc, url, ...args) {
        const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
        if (userData?.userToken) {
            this.blobApi.defaults.headers['Authorization'] = bearerKey + storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN);
        }
        return this.commonSuccessFunction(requestFunc, url, ...args);
    }

    async refreshTokenSuccess() {
        return {
            success: 1
        };
    }

    refreshUserToken() {
        const timestamp = Math.floor(+new Date().getTime() / 1000);
        const tokenexpirytime = storage.get(LOCAL_STORAGE_KEYS.TOKEN_EXPIRY_TIME);
        console.log("timestamp", timestamp);
        console.log("tokenexpirytime", tokenexpirytime);
        if (tokenexpirytime) {
            if ((tokenexpirytime - timestamp) > 0) {
                return this.refreshTokenSuccess();
            } else {
                const url = APIs.REFRESH_USERTOKEN;
                const userToken = storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN);
                const refreshToken = storage.get(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
                const reqData = {
                    "userToken": userToken,
                    "refreshToken": refreshToken,
                };
                return this.api.post(url, reqData)
                    .then((response) => {
                        if (response) {
                            console.log("response of utoken", response);
                            // once you get change from API 
                            storage.set(LOCAL_STORAGE_KEYS.ALL_TOKEN_TIME, response.data.tokenGeneratedAt);
                            storage.set(LOCAL_STORAGE_KEYS.TOKEN_EXPIRY_TIME, response.data.tokenGeneratedAt + 6900);
                            storage.set(LOCAL_STORAGE_KEYS.USER_TOKEN, response.data.userToken);
                            return this.refreshTokenSuccess();
                        }
                        else {
                            storage.clearAll();
                            window.location.href = window.location.origin;
                            throw new Error("Refresh token failed");
                        }
                    }).catch((error) => {
                        storage.clearAll();
                        window.location.href = window.location.origin;
                        throw this.commonCatchErrorFunction(error);
                    })
            }
        }
        else {
            storage.clearAll();
            window.location.href = window.location.origin;
            return this.refreshTokenFail();
        }
    }

    getDomainConfigurations = async (token) => {
        if (!this.domainConfigurationApiObject || token) {

            if (storage.has(LOCAL_STORAGE_KEYS.USER_TOKEN)) {
                this.api.defaults.headers['Authorization'] = bearerKey + storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN);
            } else {
                this.api.defaults.headers['Authorization'] = bearerKey + token;
            }
            const url = APIs.GET_DOMAIN_CONFIGURATIONS;
            this.domainConfigurationApiObject = this.api.get(url)
                .then((response) => {
                    console.log(response);
                    if (response.status === API_STATUS.SUCCESS) {
                        console.log(response);
                        storage.set(LOCAL_STORAGE_KEYS.ALLOW_ZENDESK, response.data.ALLOWZENDESK);
                        this.domainConfigurationApiObject = null;
                        return response;
                    }
                })
                .catch((error) => {
                    throw this.commonCatchErrorFunction(error);
                })
            return this.domainConfigurationApiObject;
        }
    }

    setHeadersFlag(data, headers) {
        if (storage.has(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION) && !data) {
            data = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION)
        }
        if (data) {
            PublicVariables.billingData = data.billingData;
            PublicVariables.configurations = data.configurations;
            if (PublicVariables.configurations?.edition) {
                storage.set(LOCAL_STORAGE_KEYS_ADMIN.EDITION, PublicVariables.configurations.edition);
            }
            if (PublicVariables.billingData?.subscriptiontype) {
                storage.set(LOCAL_STORAGE_KEYS.SUBSCRIPTION_TYPE, PublicVariables.billingData?.subscriptiontype);
            }
            PublicVariables.configurations.agentLocation = (PublicVariables.configurations?.agentlocation === "1") ? 1 : 0;
            PublicVariables.configurations.sessionvisibilitypage = (PublicVariables.configurations.sessionvisibilitypage === '1');  // sessionVisibilityPage is add-on configuration from SA
            PublicVariables.configurations.integrations = (PublicVariables.configurations?.integrations === true);
            PublicVariables.configurations.branding = (PublicVariables.configurations?.branding === "1");
            PublicVariables.configurations.idleLogout = Boolean(Number(PublicVariables.configurations?.idleLogout));
            PublicVariables.configurations.showmeidlelogout = Boolean(Number(PublicVariables.configurations?.showmeidlelogout));
            PublicVariables.configurations.istrial = (PublicVariables.configurations?.trialStatus === 1);
            PublicVariables.configurations.journeyeditor = ((PublicVariables.configurations?.journeyeditor === "1" || PublicVariables.configurations?.journeyeditor === 1) ? 1 : 0);
            /* need to handle this expireDay */
            PublicVariables.configurations.expireDay = PublicVariables.configurations.istrial ? PublicVariables.configurations.expireday : -1;
            storage.set(LOCAL_STORAGE_KEYS.IS_TRIAL, PublicVariables.configurations.istrial);

            const timeOutObj = {
                idlelogout: PublicVariables.configurations.idleLogout,
                idletimeout: PublicVariables.configurations.idleTimeout,
                showmeidlelogout: PublicVariables.configurations.showmeIdleLogout,
            }
            storage.set(LOCAL_STORAGE_KEYS.HEADER_OBJ, timeOutObj);
        }

        if (headers.istrial) {
            PublicVariables.istrial = Boolean(Number(headers?.istrial));
            storage.set(LOCAL_STORAGE_KEYS.IS_TRIAL, PublicVariables.istrial);
        }
        if (headers.trialexpiredays) {
            PublicVariables.expireDay = Number(headers?.trialexpiredays);
        }
    }
}

export default new API();