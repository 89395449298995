import BootstrapTable from "react-bootstrap-table-next";
import { getHandlerTableChange } from "./Pagination/TablePaginationHelpers";
import overlayFactory from "react-bootstrap-table2-overlay";
import PropTypes from 'prop-types';

const BootstrapDataTable = ({
  tableData,
  paginationTableProps,
  onTableChangeFunction,
  isLoading,
  noDataMessage,
  defaultSorted,
  columns,
  tableKey,
  expandRow,
  hidePagination = false,
  rowClasses
}) => {
  const remoteProp = !hidePagination ? { remote: true } : {};
  return (
    <BootstrapTable
      wrapperClasses="sortable-table-box"
      bordered={false}
      classes="table cmn-table table-hover align-middle margin-bottom-0 cmn-sortable-table"
      keyField={tableKey}
      data={tableData === null ? [] : tableData}
      bootstrap4
      onTableChange={getHandlerTableChange(onTableChangeFunction)}
      noDataIndication={() => {
        return isLoading ? (
          <></>
        ) : (
          <div className="no-data-found">{noDataMessage}</div>
        );
      }}
      columns={columns}
      loading={isLoading}
      defaultSorted={defaultSorted}
      overlay={overlayFactory({
        spinner: true,
      })}
      expandRow={expandRow}
      rowClasses={rowClasses}
      {...remoteProp}
      {...paginationTableProps}
    ></BootstrapTable>
  );
};

BootstrapDataTable.propTypes = {
  tableData: PropTypes.array,
  paginationTableProps: PropTypes.object,
  onTableChangeFunction: PropTypes.func,
  isLoading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  defaultSorted: PropTypes.bool,
  columns: PropTypes.array,
  tableKey: PropTypes.string,
  expandRow: PropTypes.object,
  hidePagination: PropTypes.bool,
  rowClasses: PropTypes.func,
}

export default BootstrapDataTable;
