import api from "../apis/api";


class SessionListService {
    getSessionUsers(req) {
        return api.sessionlist.getSessionUsers(req);
    }

    getCountries() {
        return api.sessionlist.getCountries();
    }

    getOwnCPList() {
        return api.sessionlist.getOwnCPList();
    }

    getSessions(req) {
        return api.sessionlist.getSessions(req);
    }

    postJoinSession(sessionId) {
        return api.sessionlist.postJoinSession(sessionId);
    }

    postInviteSession(req) {
        return api.sessionlist.postInviteSession(req);
    }

    postCreateSession(req) {
        return api.sessionlist.postCreateSession(req);
    }

    putEditScheduleSession(req) {
        return api.sessionlist.putEditScheduleSession(req);
    }
}

export default new SessionListService();