import clsx from "clsx";
import __ from "lodash";
import PropTypes from 'prop-types';
import { useHistory, useParams } from "react-router-dom";
import { forwardRef, useImperativeHandle, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import parse from 'html-react-parser';
import { LOCAL_STORAGE_KEYS, RouteName, TEN_REDORDS_PER_PAGE } from "../../../constants/Constants";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import storage from "../../../services/storage";

const PROFILE_URL = process.env.REACT_APP_PROFILE_URL;

const SessionUsersList = forwardRef((props, ref) => {
    const {
        onChangeSelectAll,
        selectedUsersChangeHandler,
        usersList, //manupulate usersList in SessionUsers component by passing all event their
        loadMore,
        pageNo,
        hasMore,
        selectedUsers,
        isSearchResultEmpty,
        isDiscardChanged,
        localisedValues
    } = props;

    const params = useParams();
    const history = useHistory();

    const [selectAllUsers, setSelectAllUsers] = useState(storage.has(LOCAL_STORAGE_KEYS.SELECT_ALL) ? storage.get(LOCAL_STORAGE_KEYS.SELECT_ALL) : false);

    useDidMountEffect(() => {
        onChangeSelectAll(selectAllUsers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectAllUsers]);

    // This hook helps a child component share certain functions or values with its parent. Here, the useImperativeHandle hook is used to let the parent access a bunch of functions through a ref.
    useImperativeHandle(ref, () => ({
        clearSelection() {
            storage.set(LOCAL_STORAGE_KEYS.SELECT_ALL, false);
            setSelectAllUsers(false);
        }
    }), []);

    const backToSessionList = () => {
        if (!__.isEmpty(params) && !isDiscardChanged) {
            history.push(RouteName.SESSIONS);
        }
    }

    const updateSelectAll = (value) => {
        storage.set(LOCAL_STORAGE_KEYS.SELECT_ALL, value);
        setSelectAllUsers(value);
    }

    const selectAllUsersHandler = () => {
        backToSessionList();
        updateSelectAll(!selectAllUsers);
    }

    const selectUsersChangeHandler = (user) => {
        backToSessionList();
        selectedUsersChangeHandler(user);
    }

    const clearSelection = () => {
        updateSelectAll(false);
        props.onClearSelectedUsers()
    }

    const loadMoreUsers = () => {
        loadMore();
    }

    return (
        <>
            {!isSearchResultEmpty &&
                <div className="checkbox-list filter-select-all">
                    <div className={clsx("checkbox checkbox-border user-select-checkbox", selectAllUsers && "active")}>
                        <label>
                            <input type="checkbox" checked={selectAllUsers} onChange={selectAllUsersHandler} />
                            <span className="for-design"></span>
                            {selectAllUsers ? localisedValues["deselect_all"] : localisedValues["select_all"]}
                        </label>
                    </div>
                </div>}
            <InfiniteScroll
                dataLength={pageNo * TEN_REDORDS_PER_PAGE}
                hasMore={hasMore}
                next={loadMoreUsers}
                height={196}
                style={{ overflowX: 'hidden', overflowY: 'auto', height: 'auto' }}
                scrollableTarget="sessionUsersList"
                className="custom-scroll-div-thin"
            >
                <ul className="user-filter-list" id="sessionUsersList">
                    {isSearchResultEmpty && <span style={{ fontWeight: "600", fontSize: '13px' }}>{localisedValues["no_user_found_lable"]}</span>}
                    {
                        usersList.map((user) => {
                            return (
                                <li key={user.userId} id={user.userId} className={clsx(selectAllUsers && "disable", user.SELECTED && "active")}>
                                    <a className="dropdown-item" href={void (0)} onClick={() => selectUsersChangeHandler(user)}>
                                        <div className="dropdown-item-box">
                                            <span className="check-design"></span>
                                            <div className="user-icon">
                                                <img src={PROFILE_URL + user.profileImage} alt="User" />
                                            </div>
                                            <div className="user-name">
                                                {user.userId === storage.get(LOCAL_STORAGE_KEYS.UCID) ? parse(user.fullName + ' ' + `(${localisedValues["you_lable"]})`) : parse(user.fullName)}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </InfiniteScroll>
            <div className="clear-filter-btn">
                <button className="btn" disabled={selectedUsers.length <= 0 && !selectAllUsers} onClick={clearSelection}>
                    <span className="btn-icon icon-close"></span>
                    <span className="btn-text">{localisedValues["clear_lable"]}</span>
                </button>
            </div>
        </>
    );
})
SessionUsersList.propTypes = {
    onChangeSelectAll: PropTypes.func,
    selectedUsersChangeHandler: PropTypes.func,
    usersList : PropTypes.array,
    loadMore: PropTypes.func,
    pageNo: PropTypes.number,
    hasMore: PropTypes.bool,
    selectedUsers: PropTypes.array,
    isSearchResultEmpty: PropTypes.bool,
    isDiscardChanged: PropTypes.bool,
    localisedValues: PropTypes.object,
    onClearSelectedUsers: PropTypes.func,
}

//fetch each redux properties from SessionUsers component
export default SessionUsersList;