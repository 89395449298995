import { getDomainURL } from "../shared/utility";

export function dehydrateCheckingEmail(email) {
  return {
    domainUrl: getDomainURL() ?? "",
    userName: email,
  }
}

export function dehydrateRegisterUser(params) {
  let formData = new FormData();
  formData.append('FirstName', params.FNAME);
  formData.append('LastName', params.LNAME);
  formData.append('Password', params.UPASSWORD);
  formData.append('UserName', params.UserName);
  formData.append('DomainUrl', getDomainURL() ?? "");

  return formData;
}