import { DefaultLanguage, LocaliseLanguageArray, LOCAL_STORAGE_KEYS } from "../constants/Constants";
import storage from "../services/storage";
import { setLanguage } from "../actions/languageAction";

export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_ERROR = 'GET_LANGUAGE_ERROR';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';

const defaultState = {
    selectedlanguage: {},
    languageList: []
};

const languageReducer = (state = defaultState, action = {}) => {

    const setLanguageForSuccess = (languagesData) => {
        return {
            selectedlanguage: languagesData?.languages?.items.find(lang => lang.languageCode === languagesData.defaultLanguage) ,
            languageList: languagesData?.languages?.items
        }
      
    }

    const setLanguageForFailed = () => {
        const langInStorage = storage.get(LOCAL_STORAGE_KEYS.USERS_LANGUAGE);
        if(langInStorage){
            setLanguage(langInStorage, LocaliseLanguageArray);
        }
        else{
            if(window.navigator.language){
                const browserLanguage = window.navigator.language.replace('-', '_');
                const index = LocaliseLanguageArray.findIndex(lang => lang.id === browserLanguage);
                if(index >= 0){
                    setLanguage(browserLanguage, LocaliseLanguageArray);
                }
                else{
                    setLanguage(DefaultLanguage, LocaliseLanguageArray);
                }
            }
            else{
                setLanguage(DefaultLanguage, LocaliseLanguageArray);
            }
        }
    }

    switch (action.type) {
        case GET_LANGUAGE_SUCCESS:
            return setLanguageForSuccess(action.payload);
        case GET_LANGUAGE_ERROR:
            return setLanguageForFailed();
        case CHANGE_LANGUAGE_SUCCESS:
            return {
                ...state,
                selectedlanguage: action.payload,
            };
        default:
            return state;
    }
}

export default languageReducer;