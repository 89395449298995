import clsx from "clsx";
import __ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ACTIONS_LIST, LOCAL_STORAGE_KEYS, MEETING_TYPE, RouteName } from "../../../constants/Constants";
import { resetAllSessionTabsDetails } from "../../../actions/sessionDetailAction";
import { formatSessionID, secondsToTime } from "../../../shared/utility";
import storage from "../../../services/storage";

const SessionCell = forwardRef((props, ref) => {
    const {
        session,
        key,
        setIsDiscardModalOpen,
        isDiscardChanged,
        setCurrentActionType,
        joinSession,
        localisedValues
    } = props;

    const [isSelected, setIsSelected] = useState(false);
    const [isInviteClicked, setIsInviteClicked] = useState(false);

    const params = useParams();
    const history = useHistory();
    const { pathname } = useLocation();

    // This hook helps a child component share certain functions or values with its parent. Here, the useImperativeHandle hook is used to let the parent access a bunch of functions through a ref.
    useImperativeHandle(ref, () => ({
        changeSession(payload) {
            sessionClickHandler(payload, true)
        },
        handlInviteFromCell() {
            handleSendcodeRedirect(true)
        },
        handleJoinSessionCall(payload) {
            joinSessionCall(payload, true)
        }
    }), [isInviteClicked]);

    useEffect(() => {
        if (__.isEmpty(params)) {
            setIsSelected(false);
        }
        else {
            setIsSelected(params.sessionId === session.sessionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        handleSendcodeRedirect();
    }, [isInviteClicked]);

    const sessionClickHandler = (session, fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.CHANGING_SESSION, payload: session })
            return
        }
        const url = RouteName.SESSION_DETAILS.replace(':sessionId', session.sessionId);
        const extractNumber = (inputString) => {
            const match = inputString.match(/\/(\d+)$/);
            return match && match[1] ? parseInt(match[1], 10) : null;
        };

        // Example usage with the location pathname
        const extractedNumber = extractNumber(pathname);
        if (extractedNumber !== null) {
            if (extractedNumber == session.sessionId) { // if same session ID clicked then do not fetch session details again
                return;
            }
        }
        history.push(url, {
            sessionId: session.sessionId,
            sessionType: session.sessionType,
            from: pathname
        });
        props.resetAllSessionTabsDetails()
    }

    const handleSendcodeRedirect = (fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.INVITE_FROM_CELL })
            return
        }
        if (isInviteClicked) {
            history.push({ pathname: RouteName.SEND_CODE, state: { isFromSessionList: true } });
        }
    };

    const joinSessionCall = (id, fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.JOIN_CALL, payload: id })
            return
        }
        joinSession(id)
    }

    const getSessionVisibility = () => {
        const configurationdata = storage.get(LOCAL_STORAGE_KEYS?.DOMAIN_CONFIGURATION);
        return configurationdata?.configurations?.sessionvisibilitypage
    }

    const isOwner = () => {
        return !(getSessionVisibility() === true && session?.ownerUserId !== storage.get(LOCAL_STORAGE_KEYS.UCID))
    };

    return (
        <div className={clsx("session-list-box", isSelected ? 'active' : (!session.isExpired) && "start-session", session.sessionType == MEETING_TYPE.JOURNEY ? 'journey-session' : '', (!session.isExpired && session.sessionType == MEETING_TYPE.JOURNEY && !isSelected) ? 'start-session' : '')} data-testid="sessionCell" key={key} ref={ref}>
            <div className="session-list-box-in" onClick={() => sessionClickHandler(session)} onKeyDown={() => { }}>
                <div className="session-list-box-head custom-tooltip right-tooltip tooltip-auto-width m-hide">
                    {session.sessionType == MEETING_TYPE.JOURNEY && <div className="journey-icon">
                        <span className="for-icon icon-journey"></span>
                    </div>}
                    <div className="session-list-ref">
                        {session.reference &&
                            <>
                                {session.reference}
                                <div className="custom-tooltip-content">
                                    <div className="custom-tooltip-in">
                                        <p>{session.reference}</p>
                                    </div>
                                </div>
                            </>}
                        {!session.reference &&
                            <>
                                N/A
                            </>
                        }
                    </div>
                    {!session.isExpired &&
                        <div className="session-list-box-btn">
                            <div className="button">
                                {session.ISUPLOADONLY && session.sessionType != MEETING_TYPE.JOURNEY && (isOwner() || !getSessionVisibility()) &&
                                    <button className="btn btn-light-success" onClick={(e) => {
                                        e.stopPropagation()
                                        joinSessionCall(session?.sessionId)
                                    }}>
                                        <span className="btn-icon icon-copylink"></span>
                                        <span className="btn-text">{localisedValues['open_link']}</span>
                                    </button>
                                }
                                {!session.ISUPLOADONLY && session.sessionType != MEETING_TYPE.JOURNEY && (isOwner() || !getSessionVisibility()) &&
                                    <button className="btn btn-light-success" onClick={(e) => {
                                        joinSessionCall(session?.sessionId)
                                    }}>
                                        <span className="btn-icon icon-video"></span>
                                        <span className="btn-text">
                                            {session.sessionType === MEETING_TYPE.REGULAR &&
                                                <>{localisedValues['join']}</>
                                            }
                                            {session.sessionType === MEETING_TYPE.SCHEDULED &&
                                                <>{localisedValues['start_btn']}</>
                                            }
                                        </span>
                                    </button>
                                }
                            </div>
                            <div className="button">
                                <button className="btn btn-light-primary" onClick={() => setIsInviteClicked(true)}>
                                    <span className="btn-icon icon-add-user"></span>
                                    <span className="btn-text">
                                        {session.ISUPLOADONLY &&
                                            <>{localisedValues['share']}</>
                                        }
                                        {!session.ISUPLOADONLY &&
                                            <>{localisedValues['invite']}</>
                                        }
                                    </span>
                                </button>
                            </div>
                        </div>
                    }
                    {session.isExpired &&
                        <div className="session-ended-label">{localisedValues['session_ended']}</div>
                    }
                </div>
                <div className="session-list-box-row">
                    <div className="session-list-row-box width-50">
                        <strong className="lbl"><span>{localisedValues['code']?.toLowerCase()}</span>:</strong> <span className="code">{formatSessionID(session.sessionId)}</span>
                    </div>
                    {!session.isExpired &&
                        <div className="session-list-row-box width-50 text-end">
                            {session.CONVERTEDENDTIME && session.sessionType != MEETING_TYPE.SCHEDULED &&
                                <>
                                    <strong className="lbl"><span>{localisedValues['end_in']}</span>:</strong> <span>{session.CONVERTEDENDTIME}</span>
                                </>
                            }
                            {session.CONVERTEDSCHEDULETIME && session.sessionType === MEETING_TYPE.SCHEDULED && session.ISUPLOADONLY &&
                                <>
                                    <strong className="lbl"><span>{localisedValues['starts_in']}</span>:</strong> <span>{session.CONVERTEDSCHEDULETIME}</span>
                                </>
                            }
                            {session.CONVERTEDENDTIME && session.sessionType === MEETING_TYPE.SCHEDULED && session.CONVERTEDSCHEDULETIME === false && session.ISUPLOADONLY === true &&
                                <>
                                    <strong className="lbl"><span>{localisedValues['end_in']}</span>:</strong> <span>{session.CONVERTEDENDTIME}</span>
                                </>
                            }
                        </div>
                    }
                </div>
                {session.sessionType === MEETING_TYPE.SCHEDULED && !session.isExpired &&
                    <div className="session-list-box-row">
                        {!session.ISUPLOADONLY &&
                            <div className="session-list-row-box width-50">
                                <strong className="lbl"><span>{localisedValues['duration']}</span>:</strong> <span>{session.callDuration > 0 ? secondsToTime(session.callDuration) : localisedValues['not_started']}</span>
                            </div>
                        }
                        <div className="session-list-row-box width-50 text-end">
                            {session.CONVERTEDSCHEDULETIME && !session.ISUPLOADONLY &&
                                <>
                                    <strong className="lbl"><span>{localisedValues['starts_in']}</span>:</strong> <span>{session.CONVERTEDSCHEDULETIME}</span>
                                </>
                            }
                            {session.CONVERTEDENDTIME && session.CONVERTEDSCHEDULETIME === false && session.ISUPLOADONLY === false &&
                                <>
                                    <strong className="lbl"><span>{localisedValues['end_in']}</span>:</strong> <span>{session.CONVERTEDENDTIME}</span>
                                </>
                            }
                        </div>
                    </div>
                }
                {!session.isExpired &&
                    <div className="session-list-box-row">
                        {session.sessionType !== MEETING_TYPE.SCHEDULED && !session.ISUPLOADONLY &&
                            <div className="session-list-row-box width-50">
                                <strong className="lbl"><span>{localisedValues['duration']}</span>:</strong> <span>{session.callDuration > 0 ? secondsToTime(session.callDuration) : localisedValues['not_started']}</span>
                            </div>
                        }
                        {session.sessionType === MEETING_TYPE.SCHEDULED &&
                            <div className="session-list-row-box width-50">
                                <strong className="lbl"><span>{localisedValues['scheduled']}</span>:</strong> <span>{moment(session.STARTTIME, 'DD-MM-YYYY hh:mm:ss A').utc().local().format('MMM DD, YYYY hh:mm A')}</span>
                            </div>
                        }
                        <div className={clsx("session-list-row-box width-50", !(session.ISUPLOADONLY && session.sessionType !== MEETING_TYPE.SCHEDULED) && "text-end")}>
                            <strong className="lbl"><span>{localisedValues['user_created']}</span>:</strong> <span>{session.CONVERTEDTIME === true ? moment(session.CDATETIME, 'DD-MM-YYYY hh:mm:ss A').utc().local().format('MMM DD, YYYY') : session.CONVERTEDTIME}</span>
                        </div>
                    </div>
                }
                {session.isExpired &&
                    <div className="session-list-box-row">
                        {!session.ISUPLOADONLY &&
                            <div className="session-list-row-box width-50">
                                <strong className="lbl"><span>{localisedValues['duration']}</span>:</strong> <span>{session.callDuration > 0 ? secondsToTime(session.callDuration) : localisedValues['not_started']}</span>
                            </div>
                        }
                        <div className={clsx("session-list-row-box width-50", !session.ISUPLOADONLY && "text-end")}>
                            <strong className="lbl"><span>{localisedValues['user_created']}</span>:</strong> <span>{session.CONVERTEDTIME === true ? moment(session.CDATETIME, 'DD-MM-YYYY hh:mm:ss A').utc().local().format('MMM DD, YYYY') : session.CONVERTEDTIME}</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
})

SessionCell.propTypes = {
    session: PropTypes.object,
    key: PropTypes.string,
    setIsDiscardModalOpen: PropTypes.func,
    isDiscardChanged: PropTypes.bool,
    setCurrentActionType: PropTypes.func,
    joinSession: PropTypes.func,
    resetAllSessionTabsDetails: PropTypes.func,
    localisedValues: PropTypes.object,
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
    }
}
const mapDispatchToPropsDetailsTab = (dispatch) => {
    return {
        resetAllSessionTabsDetails: () => dispatch(resetAllSessionTabsDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToPropsDetailsTab, null, { forwardRef: true })(SessionCell);