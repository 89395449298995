import api from "../apis/api";

class SSOAuthLoginService {
    postSSOLogIn(token) {
        return api.ssoauthlogin.postSSOLogIn(token);
    }

    postAuthLogIn(data) {
        return api.ssoauthlogin.postAuthLogIn(data);
    }
}

export default new SSOAuthLoginService();