import moment from "moment";
import { DATETIME_PARSER_FORMAT, DATE_FORMAT_DDMMYYYY, DATE_TIME_FORMAT_24_HOURS, FULL_DATE_FORMAT, TIME_FORMAT } from "../../../constants/Constants";


export const generateScheduleSessionPayload = ({ startDate, endDate, startTime, endTime, selectedTimeZone }) => {
    let startUtcDateTime = moment(moment(startDate).locale('en').format(DATE_FORMAT_DDMMYYYY) + ' ' + startTime.format(TIME_FORMAT), FULL_DATE_FORMAT);
    let endUtcDateTime = moment(moment(endDate).locale('en').format(DATE_FORMAT_DDMMYYYY) + ' ' + endTime.format(TIME_FORMAT), FULL_DATE_FORMAT);
    let currTimezone = moment.tz.guess();
    let offsetet = moment.tz(moment.utc(), selectedTimeZone.timezone).utcOffset();
    let offsetet2 = moment.tz(moment.utc(), currTimezone).utcOffset();
    if (currTimezone != selectedTimeZone.timezone) {
        startUtcDateTime = moment(startUtcDateTime).subtract(offsetet, "minutes").toDate();
        startUtcDateTime = moment(startUtcDateTime).add(offsetet2, "minutes").toDate();
        endUtcDateTime = moment(endUtcDateTime).subtract(offsetet, "minutes").toDate();
        endUtcDateTime = moment(endUtcDateTime).add(offsetet2, "minutes").toDate();
    }

    return {
        sessionType: 2,
        startAt: startUtcDateTime,
        endAt: endUtcDateTime,
        timeZone: selectedTimeZone.timezone,
        timeOffSet: moment.tz(moment.utc(), selectedTimeZone.timezone).utcOffset()
    }
}

export const handleOnDateTimeChange = ({ startDate, endDate, startTime, endTime, selectedTimeZone, setIsDateDiffLimitExceeded, setIsDateTimeValid, setDurationOfSession, isForEdit, sessionDetails }) => {
    const now = moment.tz(selectedTimeZone.timezone).locale('en');

    const formatedStartDate = startDate.locale('en').format(DATE_FORMAT_DDMMYYYY);
    const startDateTime = formatedStartDate + " " + startTime.local('en').format(TIME_FORMAT);
    let date1 = moment.tz(startDateTime, DATETIME_PARSER_FORMAT, 'en', selectedTimeZone.timezone).locale('en');

    const formatedEndDate = endDate.locale('en').format(DATE_FORMAT_DDMMYYYY);
    const endDateTime = formatedEndDate + " " + endTime.local('en').format(TIME_FORMAT);
    let date2 = moment.tz(endDateTime, DATETIME_PARSER_FORMAT, 'en', selectedTimeZone.timezone).locale('en');

    if (date1 && date2) {
        const dateDiffInMinutes = parseInt(moment.duration(date2.diff(date1)).asMinutes());
        const maxTimeDiffInMinutes = 525600; //Maximum 525600 minutes as one year of scheduled session
        const minTimeDiffInMinutes = 30; //Minimum 30 minutes of scheduled session
        const isDateDiffLimitExceeded = dateDiffInMinutes < minTimeDiffInMinutes || dateDiffInMinutes > maxTimeDiffInMinutes
        setIsDateDiffLimitExceeded(isDateDiffLimitExceeded)
        if (date1.isSame(date2)) {
            setIsDateTimeValid(true)
        }
        else if (isForEdit ? !date1.isBefore(date2) : (date1.isBefore(now) || !date1.isBefore(date2))) {
            setIsDateTimeValid(false);
        }
        else {
            setIsDateTimeValid(!date1.isBefore(now))
        }
    }
    let diff = date2.diff(date1);
    let diffDuration = moment.duration(diff);
    let ddays = parseInt(diffDuration.asDays());
    let dhours = parseInt(diffDuration.asHours());
    dhours = dhours - ddays * 24;
    let dminutes = parseInt(diffDuration.asMinutes());
    dminutes = dminutes - (ddays * 24 * 60 + dhours * 60);
    let durationVal;
    if (!diffDuration.days() && !diffDuration.hours() && !diffDuration.milliseconds() && !diffDuration.minutes() && !diffDuration.months() && !diffDuration.seconds() && !diffDuration.years()) {
        durationVal = ''
    } else {
        if (ddays && dhours && dminutes) {
            durationVal = ddays + "d " + dhours + "h " + dminutes + "m"
        } else if (ddays && dhours) {
            durationVal = ddays + "d " + dhours + "h"
        } else if (ddays && dminutes) {
            durationVal = ddays + "d " + dminutes + "m"
        } else if (dhours && dminutes) {
            durationVal = dhours + "h " + dminutes + "m"
        } else if (dminutes) {
            durationVal = dminutes + "m"
        } else if (dhours) {
            durationVal = dhours + "h"
        } else if (ddays) {
            durationVal = ddays + "d"
        } else {
            durationVal = ''
        }
    }
    setDurationOfSession(durationVal)
}

export const initializeTimeZone = ({ setTimeZonesList, setSelectedTimeZone, getAllTimeZones, isForEdit, sessionDetails }) => {
    setTimeZonesList(getAllTimeZones)
    const systemLocalTimeZone = isForEdit ? sessionDetails.session?.timeZone : moment.tz.guess()
    setSelectedTimeZone({
        timezoneWithOffset: " (GMT" + moment.tz(systemLocalTimeZone).format('Z') + ")" + systemLocalTimeZone,
        timezone: systemLocalTimeZone
    })
}

export const initializeDateAndTimePicker = ({ setStartDate, setEndDate, setStartTime, setEndTime, isForEdit, sessionDetails }) => {

    if (isForEdit) {
        let utcStartDate, utcEndDate;
        if (moment.isMoment(sessionDetails.session.startAt) && moment.isMoment(sessionDetails.session.endAt)) {
            utcStartDate = sessionDetails.startAt.clone().tz(sessionDetails.session.timeZone).locale('en');
            utcEndDate = sessionDetails.endAt.clone().tz(sessionDetails.session.timeZone).locale('en');
        }
        else {
            utcStartDate = moment.utc(sessionDetails.session.startAt, DATE_TIME_FORMAT_24_HOURS).tz(sessionDetails.session.timeZone).locale('en');
            utcEndDate = moment.utc(sessionDetails.session.endAt, DATE_TIME_FORMAT_24_HOURS).tz(sessionDetails.session.timeZone).locale('en');
        }
        setStartTime(utcStartDate)
        setEndTime(utcEndDate)

        const todayDate = moment().locale('en');
        const now = moment().tz(sessionDetails.session.timeZone);

        if (utcStartDate.isBefore(now)) {
            utcStartDate = todayDate;
        }
        if (utcEndDate.isBefore(now)) {
            utcEndDate = todayDate;
        }
        setStartDate(utcStartDate)
        setEndDate(utcEndDate)
    }
    else {
        const initialDate = moment().locale('en');
        setStartDate(initialDate)
        setEndDate(initialDate)
        const remainder = 30 - (moment().local().minute() % 30);
        const sessionStartTime = moment().local().add(remainder, 'minutes').locale('en');
        const sessionEndTime = moment().local().add(remainder + 30, 'minutes').locale('en');
        setStartTime(sessionStartTime)
        setEndTime(sessionEndTime)
    }
}

export const disablePrviousDate = (current, startingDate = false) => {
    if (startingDate) {
        return current.isAfter(moment().subtract(1, 'day')) && current.isAfter(moment(startingDate).subtract(1, 'day'))
    }
    return current.isAfter(moment().subtract(1, 'day'))
}