import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { callEventTypes } from "../constants/SingnalRConstant";
import storage from "./storage";
import { LOCAL_STORAGE_KEYS } from "../constants/Constants";
import moment from "moment";

/* Define Local variable */
let connection = null;
const GroupName = "domain";

class SignalR {
    isincomingCalls = false;
    answeredSessionID = null;
    incomingCallbackVariable = null;
    dropCallVariable = null;
    incomingJourneyCallSession = null;
    acknoledgeCallback = null;
    /*
        Use the Connect function to initiate a connection and start a socket call.
        It sets up an interval to periodically check and maintain the connection.
        The interval is cleared if the socket call is connected to any available socket.
    */
    Connect = (token) => {
        this.CheckConnectionAndSendEvent(token, () => this.ConnectingCallMessage())
    };

    /* 
        This function checks if the connection exists. If not, it creates a new one.
        Then, it checks if the connection is disconnected. If so, it starts the connection.
        If the connection is already active, it executes the provided event.
    */
    CheckConnectionAndSendEvent = (token, eventToCall) => {
        /*
            if the connection has not defined, then first it will defined and then start the socket connection
        */
        if (connection === null) {
            this.DefineConnection(token);
        }

        if (connection.state === HubConnectionState.Disconnected) {
            connection.start()
                .then(() => {
                    connection.on("SendMessage", (user, message) => {
                        this.ReceiveMessage(user, message);
                    });
                    this.JoinGroup(GroupName);
                    eventToCall();
                })
                .catch(error => {
                    connection = null;
                    console.error(error.message);
                });
        }
        else {
            eventToCall();
        }
    };

    DefineConnection = (token) => {
        /* Define SignalR connection */
        connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_SOCKET_CONNECTION_URL, {
                accessTokenFactory: () => token,
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect() // Enable automatic reconnection in case of disconnection
            .configureLogging(LogLevel.Information) // Set log level to Information
            .build(); // Build the connection
    };

    ConnectingCallMessage = () => {
        console.log("ConnectingCallMessage function call")
    };

    /* Handle received messages */
    ReceiveMessage = (user, message) => {
        const parsedMessage = message ? JSON.parse(message) : { event: null };
        if (parsedMessage?.event) {
            switch (parsedMessage?.event) {
                case callEventTypes.JourneyCall:
                    this.CreateNewCallingSession(parsedMessage.data);
                    break;
                case callEventTypes.JourneyCallAgentAnswered:
                    if (user != storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN) || (!this.answeredSessionID)) {
                        this.DropCall(parsedMessage.data);//This will remove incomming call if agent is not one who is answered
                    }
                    break;
                case callEventTypes.JourneyCallAgentJoined:
                    if (user != storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN) || (!this.answeredSessionID)) {
                        this.DropCall(parsedMessage.data);
                    }
                    break;
                case callEventTypes.JourneyAgentStartCall:
                    if (user != storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN) || (!this.answeredSessionID)) {

                    }
                    break;
                case callEventTypes.JourneyUserEndCall:
                    this.DropCall(parsedMessage.data);
                    break;
                case callEventTypes.JourneyUserAcknowledgement:
                    if (parsedMessage.data.uToken == storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN) && this.incomingJourneyCallSession.findIndex(call => call.sessionID == parsedMessage.data.sessionID) >= 0 && this.answeredSessionID) {
                        delete this.answeredSessionID
                        this.acknoledgeCallback && this.acknoledgeCallback(parsedMessage.data)
                    }
                    break;
            }
        }
    };
    /* acknoledge received callback function */ 
    acknoledgeReceived = (callBack) => {
        if (!this.acknoledgeCallback) {
            this.acknoledgeCallback = callBack;
        }
    }

    /* The `JoinGroup` function is used to join a specific group in the SignalR hub. It takes the
    `GroupName` as a parameter and checks if the connection is established and in the connected
    state. If so, it invokes the `JoinGroup` method on the SignalR connection, passing the
    `GroupName` as a parameter. */
    JoinGroup = (GroupName) => {
        console.warn("GroupName-session Id => ", GroupName)
        if (connection && connection.state == HubConnectionState.Connected) {
            connection.invoke("JoinGroup", GroupName?.toString())
                .then(() => {
                    this.SendMessageToGroup(storage.get(LOCAL_STORAGE_KEYS.UCID), "Test message")
                })
                .catch(error => {
                    console.error(error.message);
                });
        }
    };

    /* The above code is defining a function called `SendMessageToGroup` in JavaScript. This function
    takes three parameters: `_sender`, `message`, and `groupName` (which is optional and defaults to
    `null`). */
    SendMessageToGroup = (_sender, message, groupName = null) => {
        return new Promise((resolve, reject) => {
            if (connection && connection.state === HubConnectionState.Connected) {
                connection.invoke("SendMessageToGroup", groupName ?? GroupName, _sender, JSON.stringify(message))
                    .then(() => {
                        if (message.event == callEventTypes.JourneyCallAgentJoined) {
                            resolve(message);
                        }
                        else {
                            resolve('Message Sent');
                        }
                    })
                    .catch(error => {
                        console.error(error.message);
                        reject(error)
                    });
            }
        })
    };

    /* The above code is defining a function called `Disconnect` in JavaScript. */
    Disconnect = () => {
        if (connection && connection.state === HubConnectionState.Connected) {
            connection.invoke("LeftFromGroup", GroupName)
                .then(() => {
                    console.log('User left from group');
                    connection.stop()
                        .then(() => {
                            console.log('Disconnected');
                        });
                })
                .catch(error => {
                    console.error(error.message);
                });
        }
    };

    // Drop the call
    DropCall = (data) => {
        if (this.dropCallVariable !== null) {
            this.dropCallVariable(data)
        }
    };

    DropCallMessage = () => {
        setTimeout(() => {
            this.Disconnect();
        }, 100);
    };

    SetInterval(data) {
        setInterval(() => {
            const actualTime = moment(data.startTime);
            const diff = moment.utc().diff(actualTime);
            const duration = moment.duration(diff).asMilliseconds();
            data.timeSince = moment.utc(duration).format('mm:ss');
        }, 500);
    };

    incomingCallCallbackFunction = (callBack) => {
        this.incomingCallbackVariable = callBack;
    };

    dropCallCallbackFunction = (callBack) => {
        this.dropCallVariable = callBack;
    };

    CreateNewCallingSession = (data) => {
        if (this.incomingCallbackVariable !== null) {
            this.incomingCallbackVariable(data)
        }
    };

    /* The above code is defining a function called `StartNewSession` in JavaScript. This function
    takes two parameters: `message` and `guestURL`. */
    StartNewSession = (message, guestURL) => {
        return new Promise((resolve, reject) => {
            if (connection && connection.state == HubConnectionState.Connected) {
                message.event = callEventTypes.JourneyAgentStartCall;
                message.data.guestURL = guestURL;

                this.SendMessageToGroup(storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN), message, message.data.sessionID)
                    .then(function (result) {
                        resolve('Message Sent');
                    });
            }
            else {
                reject("Not connected");
            }
        })
    };

    /* The above code is defining a function called `JoinSession` in JavaScript. This function takes
    two parameters: `callData` and `guestURL`. */
    JoinSession = (callData, guestURL) => {
        return new Promise((resolve, reject) => {
            if (connection && connection.state == HubConnectionState.Connected) {
                let message = {
                    event: callEventTypes.JourneyCallAgentJoined,
                    data: {
                        sessionID: callData.sessionID,
                        time: moment.utc(),
                        jName: callData.jName,
                        jID: callData.jID,
                    }
                }
                this.SendMessageToGroup(storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN), message)
                    .then((result1) => {
                        this.StartNewSession(result1, guestURL)
                            .then((result2) => {
                                resolve('Message Sent');
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
            else {
                reject("Not connected")
            }
        })
    };

    /*
    Function will be called as soon as any one Agent click on Answer in incomming session call
    And this will send message to all other Agent to remove incomming session call from their list
    */
    CallAnswered = function (callData) {
        if (connection && connection.state == HubConnectionState.Connected) {
            let message = {
                event: callEventTypes.JourneyCallAgentAnswered,
                data: {
                    sessionID: callData.sessionID,
                    time: moment.utc(),
                    jName: callData.jName,
                    jID: callData.jID,
                }
            }
            this.SendMessageToGroup(storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN), message, callData.sessionID);//this message is for user side to stop interval
            this.SendMessageToGroup(storage.get(LOCAL_STORAGE_KEYS.USER_TOKEN), message);//This message to all other Agents to remove incomming call of current session
        }
    };
}

export default new SignalR; 