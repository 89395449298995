import { DefaultLanguage } from "./Constants";

const PublicVariables = {
    //Rootscope variables
    verifiedAccount: false,
    headerBranding: false, //used name to avoid conflict with index branding variable
    currentLanguage: null,
    ldFlags:{},
    localiseLanguage: [],
    defaultLanguage: DefaultLanguage,
    IsBuddy: true,
    statusCode: "",
    title: "",
    message: "",
    expireDay: -1,
    edition: "",
    apiHeaderOBj: {},
    istrial: false,
    // idleLogout: false,
    // idleTimeout: 0,
    // showmeIdleLogout: false,
    allowzendesk:false,
    zendeskurl:null,
    trialemail:{},

    //index variables
    companyLOGO: null,
    companyPortalName: null,
    companyPortalNameList: null,
    companyFAVICON: null,
    branding: null,
    customDomain: false,
    domainDataForLD: null,
    canGetLanguage: false,
    billingData: {}, 
    configurations: {},
    uEmailOnLogin: null,
    isAlreadyToastShown :false,
    isVerifyDomainClicked: false
}

export default PublicVariables;