import { Field } from "formik";
import clsx from "clsx";
import PropTypes from 'prop-types';

export const FormikInputGroup = ({id, type = 'text', placeholder, touched, error, className, onChange, onBlur, ...otherProps}) => {
    const styles = clsx(
                        "form-control form-control-grey", 
                        className ? className : ''
                    );

    return (
        <div className="form-group">
            <Field 
                id={id} 
                className={styles} 
                placeholder={placeholder} 
                name={id} 
                data-testid={id}
                type={type} 
                onChange={onChange} 
                onBlur={onBlur} 
                validate={otherProps.validate}
            />
            {touched && error && <div className="validation-error">{error}</div>}
        </div>
    );
}


FormikInputGroup.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    touched: PropTypes.any,
    error: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
}