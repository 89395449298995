import history from "../../history";
import { connect } from "react-redux";
import { useEffect } from "react";
import PropTypes from 'prop-types';
import { RouteName } from "../../constants/Constants";
import {SSOLoginUser} from "../../actions/ssoAuthLoginAction";
import {handleErrorMsgToastVisiblity, isEmpty} from "../../shared/utility";
import { resetAllSessionListReducerObj } from "../../actions/sessionListAction";
import { resetAllSessionDetailReducerObj } from "../../actions/sessionDetailAction";
import { resetActiveUsersReducerObj } from "../../actions/activeUsersActions";

const SSOLogin = (props) => {
    const query = new URLSearchParams(history.location.search);
    const ssoToken = query.get("sso_token");
    
    const {SSOLoginUser, ssoAuthLogin } = props;

    useEffect(() => {
        if(!isEmpty(ssoToken)){
            SSOLoginUser(ssoToken);
        } else{
            history.replace(RouteName.LOGIN);
        }
        props.resetAllSessionListReducerObj();
        props.resetAllSessionDetailReducerObj();
        props.resetActiveUsersReducerObj();
    }, []);

    useEffect(() => {
        if(ssoAuthLogin.ssoLoginError){
            handleErrorMsgToastVisiblity(ssoAuthLogin.ssoLoginError.message);
            history.replace(RouteName.LOGIN);
        }
    }, [ssoAuthLogin.ssoLoginError]);

    return <></>;
}

SSOLogin.propTypes = {
    SSOLoginUser: PropTypes.func,
    ssoAuthLogin: PropTypes.object
}

const mapStateToPropsSSOLogin = (state) => {
    return {
        ssoAuthLogin: state.ssoAuthLogin
    }
}

const mapDispatchToSSOLogin = (dispatch) =>{
    return {
        SSOLoginUser: (req) => dispatch(SSOLoginUser(req)),
        resetAllSessionListReducerObj: () => dispatch(resetAllSessionListReducerObj()),
        resetAllSessionDetailReducerObj: () => dispatch(resetAllSessionDetailReducerObj()),
        resetActiveUsersReducerObj: () => dispatch(resetActiveUsersReducerObj()),
    }
}

export default connect(mapStateToPropsSSOLogin, mapDispatchToSSOLogin)(SSOLogin);