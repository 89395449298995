import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import {
  FORMATTED_DATE,
  TEN_REDORDS_PER_PAGE,
} from "../../../../constants/Constants";
import PublicVariables from "../../../../constants/PublicVariables";
import { replaceSpecialChar } from "../../../../shared/utility";
import { Localize } from "../../Localize";
import { getSessionNotes } from "../../../../actions/sessionDetailAction";
import noNotesImage from "../../../../assets/images/no-notes.svg";

const NotesTab = ({ meetingID, ...props }) => {
  const [sessionNotes, setSessionNotes] = useState([]);
  const [sessionNotesPageNo, setSessionNotesPageNo] = useState(0);
  const [hasMoreNotes, setHasMoreNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localisedValues, setLocalisedValues] = useState({});

  const lang = PublicVariables.currentLanguage?.toLowerCase()

  useEffect(() => {
    const mainDiv = document.getElementById("session-note");
    if (mainDiv) {
      mainDiv.classList.add("active", "show");
    }
    return () => {
      mainDiv.classList.remove("active", "show");
    };
  }, []);

  const fetchSessionNotes = (pageNumber) => {
    const request = {
      sessionId: meetingID,
      skip: pageNumber,
      limit: TEN_REDORDS_PER_PAGE,
    };

    props.getSessionNotes(request);
  };

  const fetchMoreSessionNotes = () => {
    fetchSessionNotes(sessionNotesPageNo);
  };

  useEffect(() => {
    const curLang = props.language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [props.language.selectedlanguage]);

  useEffect(() => {
    setIsLoading(props.sessionDetail.getSessionNotesLoading);
    const sessionNotesData = props.sessionDetail?.getSessionNotesData;
    if (sessionNotesData?.items) {
      const updatedSessionNotes = [...sessionNotes, ...sessionNotesData.items];
      setSessionNotes(updatedSessionNotes);
      setHasMoreNotes(sessionNotesData.items.length === TEN_REDORDS_PER_PAGE);
      setSessionNotesPageNo(sessionNotesPageNo + TEN_REDORDS_PER_PAGE);
    }
  }, [
    props.sessionDetail?.getSessionNotesData,
    props.sessionDetail.getSessionNotesLoading,
  ]);

  useEffect(() => {
    if (meetingID) {
      fetchSessionNotes(sessionNotesPageNo);
    }
  }, []);

  let noNotesView;
  if (isLoading) {
    noNotesView = <p>{localisedValues["loading_note"]}</p>;
  } else {
    noNotesView = (
      <>
        <div className="image">
          <img src={noNotesImage} alt="No note" />
        </div>
        <p>{localisedValues["no_notes_available_label"]}</p>
      </>
    );
  }

  return (
    <div className="tab-pane fade" id="session-note" role="tabpanel" data-testid='session-note-main'>
      {sessionNotes?.length === 0 ? (
        <div className="no-session-data custom-scroll-div">
          <div className="no-session-data-in">
            <div className="no-session-data-box">{noNotesView}</div>
          </div>
        </div>
      ) : (
        <div className="session-notes custom-scroll-div" id="scrollableDiv">
          {sessionNotes?.length > 0 && (
            <InfiniteScroll
              dataLength={sessionNotesPageNo + TEN_REDORDS_PER_PAGE}
              next={fetchMoreSessionNotes}
              hasMore={hasMoreNotes}
              scrollableTarget="scrollableDiv"
            >
              {sessionNotes.map((item, index) => {
                const formattedDate = moment
                  .utc(item?.modify_at)
                  .locale(lang)
                  .local()
                  .format(FORMATTED_DATE);
                return (
                  <div className="session-note-box" key={index}>
                    <div className="user-icon">
                      <img
                        src={
                          process.env.REACT_APP_PROFILE_URL +
                          item.creatorProfileImage
                        }
                        alt="logo"
                      />
                    </div>
                    <div className="session-note-text">
                      {item?.title && (
                        <div className="note-head">{item.title}</div>
                      )}
                      {item?.noteDetail && (
                        <div className="note-body">
                          <p>{replaceSpecialChar(item.noteDetail)}</p>
                        </div>
                      )}
                      <div className="note-info">
                        <div className="note-info-box">
                          <span className="note-info-icon icon-note-created font-weight-700"></span>
                          <span className="note-info-text">
                            <strong>{localisedValues["created_by"]}: </strong>{" "}
                            {replaceSpecialChar(item.creatorName)}
                          </span>
                        </div>
                        <div className="note-info-box">
                          <span className="note-info-icon icon-note-created-time"></span>
                          <span className="note-info-text">
                            <strong>{localisedValues["created_on"]}: </strong>{" "}
                            {formattedDate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToPropsSessionDetail = (state) => {
  return {
    sessionDetail: state.sessionDetail,
    language: state.language,
  };
};

const mapDispatchToPropsSessionDetail = (dispatch) => {
  return {
    getSessionNotes: (req) => dispatch(getSessionNotes(req)),
  };
};

NotesTab.propTypes = {
  meetingID: PropTypes.string,
  getSessionNotes: PropTypes.func,
  language: PropTypes.object,
  sessionDetail: PropTypes.object
}

export default connect(
  mapStateToPropsSessionDetail,
  mapDispatchToPropsSessionDetail
)(NotesTab);
