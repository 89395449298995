export const Localize = {
    "en_GB": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "open_link": "Open Link",
        "join": "Join",
        "start_btn": "Start",
        "share": "Share",
        "invite": "Invite",
        "code": "CODE",
        "end_in": "Ends in",
        "starts_in": "Starts in",
        "duration": "Duration",
        "not_started": "Not started",
        "scheduled": "Scheduled",
        "user_created": "created",
        "session_ended": "Session ended",
        "no_sessions": "No sessions",
        "new_session": "New Session",
        "invite_contact": "Invite Contact",
        "reference": "Reference",
        "sort_by": "Sort by",
        "you_lable": "You",
        "assigned_to_lable": "Assigned to",
        "my_session_lable": "My Sessions",
        "search": "Search",
        "no_user_found_lable": "No items matched the supplied filter",
        "clear_lable": "Clear",
        "loading_note": "Loading notes...",
        "no_notes_available_label": "No notes available for this session or may have been deleted by your Admin.",
        "created_by": "Created by",
        "created_on": "Created on",
        "step": "Step",
        "user_of": "of",
        "enter_session_details": "Enter Session Details",
        "select_call_experience": "Select Collaboration Profile",
        "add_title": "Add Title",
        "reference_required": "Please enter Title/Reference.",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "schedule_session": "Schedule Session",
        "date_time": "Date & Time",
        "to_key": "to",
        "enter_valid_start_and_end_date": "Enter valid start date-time and end date-time.",
        "time_zone": "Time Zone",
        "default_timezone_text": "By default, we will use your computer's time zone. Click the drop-down menu to select a different time zone.",
        "next": "Next",
        "schedule_session_diff_limit_error": "Scheduled session time can not be less than 30 minutes and greater than 365 days.",
        "discard_change": "Discard Change",
        "discard_change_desc1": "Are you sure you want to discard the changes?",
        "discard_change_desc2": "You can save your changes, discard your changes, or cancel to continue editing.",
        "note": "Note",
        "user_cancel": "Cancel",
        "save_button": "Save",
        "save_changes": "Save Changes",
        "discard_buton": "Discard",
        "deselect_all": "Deselect All",
        "select_all": "Select All",
        "yesterday": "Yesterday",
        "hours_ago": "1 hours ago",
        "hour_ago": "1 hour ago",
        "minutes_ago": "1 minutes ago",
        "minute_ago": "1 minute ago",
        "just_now": "Just now",
        "journey_code": "Code",
        "answer_label": "Answer",
        "waiting_since_lable": "Waiting Since",
        "incoming_call_label": "Incoming Call",
        "welcome_modal_title": "Congratulations! You’re ready to use Blitzz.",
        "welcome_modal_msg": "Get started by inviting a Guest or Contact to join a remote video session.",
        "view_tutorial": "View Tutorial",
        "get_started": "Get Started"
    },
    "de_DE": {
        "user_all": "Alles",
        "session_active": "Aktiv",
        "session_upcoming": "Bevorstehende",
        "session_closed": "Geschlossen",
        "archived": "Archiviert",
        "open_link": "Verbindung öffnen",
        "join": "Beitreten",
        "start_btn": "Start",
        "share": "Teilen",
        "invite": "Einladen",
        "code": "CODE",
        "end_in": "Endet in",
        "starts_in": "Beginnt in",
        "duration": "Dauer",
        "not_started": "Nicht angefangen",
        "scheduled": "Geplant",
        "user_created": "erstellt",
        "session_ended": "Sitzung beendet",
        "no_sessions": "Keine Sitzungen",
        "new_session": "Neue Sitzung",
        "invite_contact": "Kontakt einladen",
        "reference": "Referenz",
        "sort_by": "Sortiere nach",
        "you_lable": "Sie",
        "assigned_to_lable": "Zugewiesen an",
        "my_session_lable": "Meine Sitzungen",
        "search": "Suche",
        "no_user_found_lable": "Keine Artikel stimmten mit dem mitgelieferten Filter überein",
        "clear_lable": "klar",
        "loading_note": "Notizen laden ...",
        "no_notes_available_label": "Für diese Sitzung sind keine Notizen verfügbar oder wurden möglicherweise von Ihrem Administrator gelöscht.",
        "created_by": "Erstellt von",
        "created_on": "Erstellt am",
        "step": "Schritt",
        "user_of": "von",
        "enter_session_details": "Sitzungsdetails eingeben",
        "select_call_experience": "Wählen Sie Zusammenarbeitsprofil aus",
        "add_title": "Titel hinzufügen",
        "reference_required": "Bitte geben Sie Titel / Referenz ein.",
        "numeric_characters_allowed": "Es sind nur numerische Zeichen zulässig.",
        "crt_acnt_limit_character_msg_fifty": "Bis zu 50 Zeichen erlaubt.",
        "schedule_session": "Sitzung planen",
        "date_time": "Terminzeit",
        "to_key": "zu",
        "enter_valid_start_and_end_date": "Geben Sie ein gültiges Startdatum/Uhrzeit und Enddatum/Uhrzeit ein.",
        "time_zone": "Zeitzone",
        "default_timezone_text": "Standardmäßig verwenden wir die Zeitzone Ihres Computers. Klicken Sie auf das Dropdown-Menü, um eine andere Zeitzone auszuwählen.",
        "next": "Nächster",
        "schedule_session_diff_limit_error": "Die geplante Sitzungszeit darf nicht weniger als 30 Minuten und nicht mehr als 365 Tage betragen.",
        "discard_change": "Änderung verwerfen",
        "discard_change_desc1": "Möchten Sie die Änderungen wirklich verwerfen?",
        "discard_change_desc2": "Sie können Ihre Änderungen speichern, verwerfen oder abbrechen, um die Bearbeitung fortzusetzen.",
        "note": "Hinweis",
        "user_cancel": "Stornieren",
        "save_button": "sparen",
        "save_changes": "Änderungen speichern",
        "discard_buton": "Verwerfen",
        "deselect_all": "Alle abwählen",
        "select_all": "Wählen Sie Alle",
        "yesterday": "Gestern",
        "hours_ago": "Vor 1 Stunde",
        "hour_ago": "vor 1 Stunde",
        "minutes_ago": "Vor 1 Minuten",
        "minute_ago": "Vor 1 Minute",
        "just_now": "Gerade jetzt",
        "journey_code": "Code",
        "answer_label": "Antworten",
        "waiting_since_lable": "Warten seit",
        "incoming_call_label": "Eingehender Anruf",
        "welcome_modal_title": "Herzliche Glückwünsche! Sie können Blitzz jetzt verwenden.",
        "welcome_modal_msg": "Laden Sie zunächst einen Gast oder Kontakt ein, um an einer Remote-Videositzung teilzunehmen.",
        "view_tutorial": "Tutorial anzeigen",
        "get_started": "Loslegen"
    },
    "en_US": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "open_link": "Open Link",
        "join": "Join",
        "start_btn": "Start",
        "share": "Share",
        "invite": "Invite",
        "code": "CODE",
        "end_in": "Ends in",
        "starts_in": "Starts in",
        "duration": "Duration",
        "not_started": "Not started",
        "scheduled": "Scheduled",
        "user_created": "created",
        "session_ended": "Session ended",
        "no_sessions": "No sessions",
        "new_session": "New Session",
        "invite_contact": "Invite Contact",
        "reference": "Reference",
        "sort_by": "Sort by",
        "you_lable": "You",
        "assigned_to_lable": "Assigned to",
        "my_session_lable": "My Sessions",
        "search": "Search",
        "no_user_found_lable": "No items matched the supplied filter",
        "clear_lable": "Clear",
        "loading_note": "Loading notes...",
        "no_notes_available_label": "No notes available for this session or may have been deleted by your Admin.",
        "created_by": "Created by",
        "created_on": "Created on",
        "step": "Step",
        "user_of": "of",
        "enter_session_details": "Enter Session Details",
        "select_call_experience": "Select Collaboration Profile",
        "add_title": "Add Title",
        "reference_required": "Please enter Title/Reference.",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "schedule_session": "Schedule Session",
        "date_time": "Date & Time",
        "to_key": "to",
        "enter_valid_start_and_end_date": "Enter valid start date-time and end date-time.",
        "time_zone": "Time Zone",
        "default_timezone_text": "By default, we will use your computer's time zone. Click the drop-down menu to select a different time zone.",
        "next": "Next",
        "schedule_session_diff_limit_error": "Scheduled session time can not be less than 30 minutes and greater than 365 days.",
        "discard_change": "Discard Change",
        "discard_change_desc1": "Are you sure you want to discard the changes?",
        "discard_change_desc2": "You can save your changes, discard your changes, or cancel to continue editing.",
        "note": "Note",
        "user_cancel": "Cancel",
        "save_button": "Save",
        "save_changes": "Save Changes",
        "discard_buton": "Discard",
        "deselect_all": "Deselect All",
        "select_all": "Select All",
        "yesterday": "Yesterday",
        "hours_ago": "1 hours ago",
        "hour_ago": "1 hour ago",
        "minutes_ago": "1 minutes ago",
        "minute_ago": "1 minute ago",
        "just_now": "Just now",
        "journey_code": "Code",
        "answer_label": "Answer",
        "waiting_since_lable": "Waiting Since",
        "incoming_call_label": "Incoming Call",
        "welcome_modal_title": "Congratulations! You’re ready to use Blitzz.",
        "welcome_modal_msg": "Get started by inviting a Guest or Contact to join a remote video session.",
        "view_tutorial": "View Tutorial",
        "get_started": "Get Started"
    },
    "en_AU": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "open_link": "Open Link",
        "join": "Join",
        "start_btn": "Start",
        "share": "Share",
        "invite": "Invite",
        "code": "CODE",
        "end_in": "Ends in",
        "starts_in": "Starts in",
        "duration": "Duration",
        "not_started": "Not started",
        "scheduled": "Scheduled",
        "user_created": "created",
        "session_ended": "Session ended",
        "no_sessions": "No sessions",
        "new_session": "New Session",
        "invite_contact": "Invite Contact",
        "reference": "Reference",
        "sort_by": "Sort by",
        "you_lable": "You",
        "assigned_to_lable": "Assigned to",
        "my_session_lable": "My Sessions",
        "search": "Search",
        "no_user_found_lable": "No items matched the supplied filter",
        "clear_lable": "Clear",
        "loading_note": "Loading notes...",
        "no_notes_available_label": "No notes available for this session or may have been deleted by your Admin.",
        "created_by": "Created by",
        "created_on": "Created on",
        "step": "Step",
        "user_of": "of",
        "enter_session_details": "Enter Session Details",
        "select_call_experience": "Select Collaboration Profile",
        "add_title": "Add Title",
        "reference_required": "Please enter Title/Reference.",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "schedule_session": "Schedule Session",
        "date_time": "Date & Time",
        "to_key": "to",
        "enter_valid_start_and_end_date": "Enter valid start date-time and end date-time.",
        "time_zone": "Time Zone",
        "default_timezone_text": "By default, we will use your computer's time zone. Click the drop-down menu to select a different time zone.",
        "next": "Next",
        "schedule_session_diff_limit_error": "Scheduled session time can not be less than 30 minutes and greater than 365 days.",
        "discard_change": "Discard Change",
        "discard_change_desc1": "Are you sure you want to discard the changes?",
        "discard_change_desc2": "You can save your changes, discard your changes, or cancel to continue editing.",
        "note": "Note",
        "user_cancel": "Cancel",
        "save_button": "Save",
        "save_changes": "Save Changes",
        "discard_buton": "Discard",
        "deselect_all": "Deselect All",
        "select_all": "Select All",
        "yesterday": "Yesterday",
        "hours_ago": "1 hours ago",
        "hour_ago": "1 hour ago",
        "minutes_ago": "1 minutes ago",
        "minute_ago": "1 minute ago",
        "just_now": "Just now",
        "journey_code": "Code",
        "answer_label": "Answer",
        "waiting_since_lable": "Waiting Since",
        "incoming_call_label": "Incoming Call",
        "welcome_modal_title": "Congratulations! You’re ready to use Blitzz.",
        "welcome_modal_msg": "Get started by inviting a Guest or Contact to join a remote video session.",
        "view_tutorial": "View Tutorial",
        "get_started": "Get Started"
    },
    "es_ES": {
        "user_all": "Todos",
        "session_active": "Activo",
        "session_upcoming": "Próximamente",
        "session_closed": "Cerrado",
        "archived": "Archivado",
        "open_link": "Enlace abierto",
        "join": "Unirse",
        "start_btn": "comienzo",
        "share": "Cuota",
        "invite": "Invitación",
        "code": "CÓDIGO",
        "end_in": "Termina en",
        "starts_in": "Empieza en",
        "duration": "Duración",
        "not_started": "No empezado",
        "scheduled": "Programado",
        "user_created": "creado",
        "session_ended": "sesión terminó",
        "no_sessions": "Sin sesiones",
        "new_session": "Nueva sesión",
        "invite_contact": "Contacto de invitación",
        "reference": "Referencia",
        "sort_by": "Ordenar por",
        "you_lable": "usted",
        "assigned_to_lable": "Asignado a",
        "my_session_lable": "Mis Sesiones",
        "search": "Buscar",
        "no_user_found_lable": "Ningún elemento coincide con el filtro suministrado",
        "clear_lable": "Claro",
        "loading_note": "Cargando notas ...",
        "no_notes_available_label": "No hay notas disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "created_by": "Creado por",
        "created_on": "Creado en",
        "step": "Paso",
        "user_of": "de",
        "enter_session_details": "Ingrese los detalles de la sesión",
        "select_call_experience": "Seleccionar perfil de colaboración",
        "add_title": "Añadir título",
        "reference_required": "Por favor, introduzca el título / referencia.",
        "numeric_characters_allowed": "Solo se permiten caracteres numéricos.",
        "crt_acnt_limit_character_msg_fifty": "Hasta 50 caracteres permitidos.",
        "schedule_session": "Programar sesión",
        "date_time": "Fecha y hora",
        "to_key": "a",
        "enter_valid_start_and_end_date": "Introduzca una fecha y hora de inicio y una fecha de finalización válidas.",
        "time_zone": "Zona horaria",
        "default_timezone_text": "Por defecto, usaremos la zona horaria de su computadora. Haga clic en el menú desplegable para seleccionar una zona horaria diferente.",
        "next": "próximo",
        "schedule_session_diff_limit_error": "El tiempo de sesión programado no puede ser inferior a 30 minutos ni superior a 365 días.",
        "discard_change": "Descartar cambio",
        "discard_change_desc1": "¿Seguro que quieres descartar los cambios?",
        "discard_change_desc2": "Puede guardar sus cambios, descartarlos o cancelar para continuar editando.",
        "note": "Nota",
        "user_cancel": "Cancelar",
        "save_button": "Salvar",
        "save_changes": "Guardar cambios",
        "discard_buton": "Descarte",
        "deselect_all": "Deseleccionar todo",
        "select_all": "Seleccionar todo",
        "yesterday": "Ayer",
        "hours_ago": "Hace 1 hora",
        "hour_ago": "1 hora antes",
        "minutes_ago": "Hace 1 minutos",
        "minute_ago": "Hace 1 minuto",
        "just_now": "Justo ahora",
        "journey_code": "Código",
        "answer_label": "Respuesta",
        "waiting_since_lable": "esperando desde",
        "incoming_call_label": "Llamada entrante",
        "welcome_modal_title": "¡Felicidades! Estás listo para usar Blitzz.",
        "welcome_modal_msg": "Comience invitando a un invitado o contacto a unirse a una sesión de video remota.",
        "view_tutorial": "Ver tutorial",
        "get_started": "Empezar"
    },
    "es_MX": {
        "user_all": "Todos",
        "session_active": "Activo",
        "session_upcoming": "Próximamente",
        "session_closed": "Cerrado",
        "archived": "Archivado",
        "open_link": "Enlace abierto",
        "join": "Unirse",
        "start_btn": "comienzo",
        "share": "Cuota",
        "invite": "Invitación",
        "code": "CÓDIGO",
        "end_in": "Termina en",
        "starts_in": "Empieza en",
        "duration": "Duración",
        "not_started": "No empezado",
        "scheduled": "Programado",
        "user_created": "creado",
        "session_ended": "sesión terminó",
        "no_sessions": "Sin sesiones",
        "new_session": "Nueva sesión",
        "invite_contact": "Contacto de invitación",
        "reference": "Referencia",
        "sort_by": "Ordenar por",
        "you_lable": "usted",
        "assigned_to_lable": "Asignado a",
        "my_session_lable": "Mis Sesiones",
        "search": "Buscar",
        "no_user_found_lable": "Ningún elemento coincide con el filtro suministrado",
        "clear_lable": "Claro",
        "loading_note": "Cargando notas ...",
        "no_notes_available_label": "No hay notas disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "created_by": "Creado por",
        "created_on": "Creado en",
        "step": "Paso",
        "user_of": "de",
        "enter_session_details": "Ingrese los detalles de la sesión",
        "select_call_experience": "Seleccionar perfil de colaboración",
        "add_title": "Añadir título",
        "reference_required": "Por favor, introduzca el título / referencia.",
        "numeric_characters_allowed": "Solo se permiten caracteres numéricos.",
        "crt_acnt_limit_character_msg_fifty": "Hasta 50 caracteres permitidos.",
        "schedule_session": "Programar sesión",
        "date_time": "Fecha y hora",
        "to_key": "a",
        "enter_valid_start_and_end_date": "Introduzca una fecha y hora de inicio y una fecha de finalización válidas.",
        "time_zone": "Zona horaria",
        "default_timezone_text": "Por defecto, usaremos la zona horaria de su computadora. Haga clic en el menú desplegable para seleccionar una zona horaria diferente.",
        "next": "próximo",
        "schedule_session_diff_limit_error": "El tiempo de sesión programado no puede ser inferior a 30 minutos ni superior a 365 días.",
        "discard_change": "Descartar cambio",
        "discard_change_desc1": "¿Seguro que quieres descartar los cambios?",
        "discard_change_desc2": "Puede guardar sus cambios, descartarlos o cancelar para continuar editando.",
        "note": "Nota",
        "user_cancel": "Cancelar",
        "save_button": "Salvar",
        "save_changes": "Guardar cambios",
        "discard_buton": "Descarte",
        "deselect_all": "Deseleccionar todo",
        "select_all": "Seleccionar todo",
        "yesterday": "Ayer",
        "hours_ago": "Hace 1 hora",
        "hour_ago": "1 hora antes",
        "minutes_ago": "Hace 1 minutos",
        "minute_ago": "Hace 1 minuto",
        "just_now": "Justo ahora",
        "journey_code": "Código",
        "answer_label": "Respuesta",
        "waiting_since_lable": "esperando desde",
        "incoming_call_label": "Llamada entrante",
        "welcome_modal_title": "¡Felicidades! Estás listo para usar Blitzz.",
        "welcome_modal_msg": "Comience invitando a un invitado o contacto a unirse a una sesión de video remota.",
        "view_tutorial": "Ver tutorial",
        "get_started": "Empezar"
    },
    "es_AR": {
        "user_all": "Todos",
        "session_active": "Activo",
        "session_upcoming": "Próximamente",
        "session_closed": "Cerrado",
        "archived": "Archivado",
        "open_link": "Enlace abierto",
        "join": "Unirse",
        "start_btn": "comienzo",
        "share": "Cuota",
        "invite": "Invitación",
        "code": "CÓDIGO",
        "end_in": "Termina en",
        "starts_in": "Empieza en",
        "duration": "Duración",
        "not_started": "No empezado",
        "scheduled": "Programado",
        "user_created": "creado",
        "session_ended": "sesión terminó",
        "no_sessions": "Sin sesiones",
        "new_session": "Nueva sesión",
        "invite_contact": "Contacto de invitación",
        "reference": "Referencia",
        "sort_by": "Ordenar por",
        "you_lable": "usted",
        "assigned_to_lable": "Asignado a",
        "my_session_lable": "Mis Sesiones",
        "search": "Buscar",
        "no_user_found_lable": "Ningún elemento coincide con el filtro suministrado",
        "clear_lable": "Claro",
        "loading_note": "Cargando notas ...",
        "no_notes_available_label": "No hay notas disponibles para esta sesión o es posible que su administrador las haya eliminado.",
        "created_by": "Creado por",
        "created_on": "Creado en",
        "step": "Paso",
        "user_of": "de",
        "enter_session_details": "Ingrese los detalles de la sesión",
        "select_call_experience": "Seleccionar perfil de colaboración",
        "add_title": "Añadir título",
        "reference_required": "Por favor, introduzca el título / referencia.",
        "numeric_characters_allowed": "Solo se permiten caracteres numéricos.",
        "crt_acnt_limit_character_msg_fifty": "Hasta 50 caracteres permitidos.",
        "schedule_session": "Programar sesión",
        "date_time": "Fecha y hora",
        "to_key": "a",
        "enter_valid_start_and_end_date": "Introduzca una fecha y hora de inicio y una fecha de finalización válidas.",
        "time_zone": "Zona horaria",
        "default_timezone_text": "Por defecto, usaremos la zona horaria de su computadora. Haga clic en el menú desplegable para seleccionar una zona horaria diferente.",
        "next": "próximo",
        "schedule_session_diff_limit_error": "El tiempo de sesión programado no puede ser inferior a 30 minutos ni superior a 365 días.",
        "discard_change": "Descartar cambio",
        "discard_change_desc1": "¿Seguro que quieres descartar los cambios?",
        "discard_change_desc2": "Puede guardar sus cambios, descartarlos o cancelar para continuar editando.",
        "note": "Nota",
        "user_cancel": "Cancelar",
        "save_button": "Salvar",
        "save_changes": "Guardar cambios",
        "discard_buton": "Descarte",
        "deselect_all": "Deseleccionar todo",
        "select_all": "Seleccionar todo",
        "yesterday": "Ayer",
        "hours_ago": "Hace 1 hora",
        "hour_ago": "1 hora antes",
        "minutes_ago": "Hace 1 minutos",
        "minute_ago": "Hace 1 minuto",
        "just_now": "Justo ahora",
        "journey_code": "Código",
        "answer_label": "Respuesta",
        "waiting_since_lable": "esperando desde",
        "incoming_call_label": "Llamada entrante",
        "welcome_modal_title": "¡Felicidades! Estás listo para usar Blitzz.",
        "welcome_modal_msg": "Comience invitando a un invitado o contacto a unirse a una sesión de video remota.",
        "view_tutorial": "Ver tutorial",
        "get_started": "Empezar"
    },
    "fr_FR": {
        "user_all": "Tout",
        "session_active": "actif",
        "session_upcoming": "A venir",
        "session_closed": "Fermé",
        "archived": "Archivé",
        "open_link": "Ouvrir le lien",
        "join": "Joindre",
        "start_btn": "Début",
        "share": "Partager",
        "invite": "Inviter",
        "code": "CODE",
        "end_in": "Fini dans",
        "starts_in": "Commence dans",
        "duration": "Durée",
        "not_started": "Pas commencé",
        "scheduled": "Programmé",
        "user_created": "créé",
        "session_ended": "session a pris fin",
        "no_sessions": "Aucune session",
        "new_session": "Nouvelle session",
        "invite_contact": "Inviter un contact",
        "reference": "Référence",
        "sort_by": "Trier par",
        "you_lable": "Toi",
        "assigned_to_lable": "Assigné à",
        "my_session_lable": "Mes sessions",
        "search": "Chercher",
        "no_user_found_lable": "Aucun élément ne correspond au filtre fourni",
        "clear_lable": "Clair",
        "loading_note": "Chargement des notes ...",
        "no_notes_available_label": "Aucune note disponible pour cette session ou peut avoir été supprimée par votre administrateur.",
        "created_by": "Créé par",
        "created_on": "Créé sur",
        "step": "Étape",
        "user_of": "de",
        "enter_session_details": "Entrez les détails de la session",
        "select_call_experience": "Sélectionnez le profil de collaboration",
        "add_title": "Ajouter un titre",
        "reference_required": "Veuillez saisir le titre / la référence.",
        "numeric_characters_allowed": "Seuls les caractères numériques sont autorisés.",
        "crt_acnt_limit_character_msg_fifty": "Jusqu'à 50 caractères autorisés.",
        "schedule_session": "Planifier une session",
        "date_time": "Date et heure",
        "to_key": "à",
        "enter_valid_start_and_end_date": "Saisissez une date-heure de début et une date-heure de fin valides.",
        "time_zone": "Fuseau horaire",
        "default_timezone_text": "Par défaut, nous utiliserons le fuseau horaire de votre ordinateur. Cliquez sur le menu déroulant pour sélectionner un autre fuseau horaire.",
        "next": "Prochain",
        "schedule_session_diff_limit_error": "La durée de session programmée ne peut pas être inférieure à 30 minutes et supérieure à 365 jours.",
        "discard_change": "Ignorer le changement",
        "discard_change_desc1": "Voulez-vous vraiment annuler les modifications?",
        "discard_change_desc2": "Vous pouvez enregistrer vos modifications, les annuler ou annuler pour continuer la modification.",
        "note": "Remarque",
        "user_cancel": "Annuler",
        "save_button": "sauver",
        "save_changes": "Sauvegarder les modifications",
        "discard_buton": "Jeter",
        "deselect_all": "Tout déselectionner",
        "select_all": "Tout sélectionner",
        "yesterday": "Hier",
        "hours_ago": "Il y a 1 heure",
        "hour_ago": "Il ya 1 heure",
        "minutes_ago": "Il y a 1 minute",
        "minute_ago": "il y a 1 minute",
        "just_now": "Juste maintenant",
        "journey_code": "Code",
        "answer_label": "Répondre",
        "waiting_since_lable": "En attente depuis",
        "incoming_call_label": "Appel entrant",
        "welcome_modal_title": "Toutes nos félicitations! Vous êtes prêt à utiliser Blitzz.",
        "welcome_modal_msg": "Commencez par inviter un invité ou un contact à rejoindre une session vidéo à distance.",
        "view_tutorial": "Voir le didacticiel",
        "get_started": "Commencer"
    },
    "en_CA": {
        "user_all": "All",
        "session_active": "Active",
        "session_upcoming": "Upcoming",
        "session_closed": "Closed",
        "archived": "Archived",
        "open_link": "Open Link",
        "join": "Join",
        "start_btn": "Start",
        "share": "Share",
        "invite": "Invite",
        "code": "CODE",
        "end_in": "Ends in",
        "starts_in": "Starts in",
        "duration": "Duration",
        "not_started": "Not started",
        "scheduled": "Scheduled",
        "user_created": "created",
        "session_ended": "Session ended",
        "no_sessions": "No sessions",
        "new_session": "New Session",
        "invite_contact": "Invite Contact",
        "reference": "Reference",
        "sort_by": "Sort by",
        "you_lable": "You",
        "assigned_to_lable": "Assigned to",
        "my_session_lable": "My Sessions",
        "search": "Search",
        "no_user_found_lable": "No items matched the supplied filter",
        "clear_lable": "Clear",
        "loading_note": "Loading notes...",
        "no_notes_available_label": "No notes available for this session or may have been deleted by your Admin.",
        "created_by": "Created by",
        "created_on": "Created on",
        "step": "Step",
        "user_of": "of",
        "enter_session_details": "Enter Session Details",
        "select_call_experience": "Select Collaboration Profile",
        "add_title": "Add Title",
        "reference_required": "Please enter Title/Reference.",
        "numeric_characters_allowed": "Only numeric characters are allowed.",
        "crt_acnt_limit_character_msg_fifty": "Up to 50 characters allowed.",
        "schedule_session": "Schedule Session",
        "date_time": "Date & Time",
        "to_key": "to",
        "enter_valid_start_and_end_date": "Enter valid start date-time and end date-time.",
        "time_zone": "Time Zone",
        "default_timezone_text": "By default, we will use your computer's time zone. Click the drop-down menu to select a different time zone.",
        "next": "Next",
        "schedule_session_diff_limit_error": "Scheduled session time can not be less than 30 minutes and greater than 365 days.",
        "discard_change": "Discard Change",
        "discard_change_desc1": "Are you sure you want to discard the changes?",
        "discard_change_desc2": "You can save your changes, discard your changes, or cancel to continue editing.",
        "note": "Note",
        "user_cancel": "Cancel",
        "save_button": "Save",
        "save_changes": "Save Changes",
        "discard_buton": "Discard",
        "deselect_all": "Deselect All",
        "select_all": "Select All",
        "yesterday": "Yesterday",
        "hours_ago": "1 hours ago",
        "hour_ago": "1 hour ago",
        "minutes_ago": "1 minutes ago",
        "minute_ago": "1 minute ago",
        "just_now": "Just now",
        "journey_code": "Code",
        "answer_label": "Answer",
        "waiting_since_lable": "Waiting Since",
        "incoming_call_label": "Incoming Call",
        "welcome_modal_title": "Congratulations! You’re ready to use Blitzz.",
        "welcome_modal_msg": "Get started by inviting a Guest or Contact to join a remote video session.",
        "view_tutorial": "View Tutorial",
        "get_started": "Get Started"
    },
    "fr_CA": {
        "user_all": "Tout",
        "session_active": "actif",
        "session_upcoming": "A venir",
        "session_closed": "Fermé",
        "archived": "Archivé",
        "open_link": "Ouvrir le lien",
        "join": "Joindre",
        "start_btn": "Début",
        "share": "Partager",
        "invite": "Inviter",
        "code": "CODE",
        "end_in": "Fini dans",
        "starts_in": "Commence dans",
        "duration": "Durée",
        "not_started": "Pas commencé",
        "scheduled": "Programmé",
        "user_created": "créé",
        "session_ended": "session a pris fin",
        "no_sessions": "Aucune session",
        "new_session": "Nouvelle session",
        "invite_contact": "Inviter un contact",
        "reference": "Référence",
        "sort_by": "Trier par",
        "you_lable": "Toi",
        "assigned_to_lable": "Assigné à",
        "my_session_lable": "Mes sessions",
        "search": "Chercher",
        "no_user_found_lable": "Aucun élément ne correspond au filtre fourni",
        "clear_lable": "Clair",
        "loading_note": "Chargement des notes ...",
        "no_notes_available_label": "Aucune note disponible pour cette session ou peut avoir été supprimée par votre administrateur.",
        "created_by": "Créé par",
        "created_on": "Créé sur",
        "step": "Étape",
        "user_of": "de",
        "enter_session_details": "Entrez les détails de la session",
        "select_call_experience": "Sélectionnez le profil de collaboration",
        "add_title": "Ajouter un titre",
        "reference_required": "Veuillez saisir le titre / la référence.",
        "numeric_characters_allowed": "Seuls les caractères numériques sont autorisés.",
        "crt_acnt_limit_character_msg_fifty": "Jusqu'à 50 caractères autorisés.",
        "schedule_session": "Planifier une session",
        "date_time": "Date et heure",
        "to_key": "à",
        "enter_valid_start_and_end_date": "Saisissez une date-heure de début et une date-heure de fin valides.",
        "time_zone": "Fuseau horaire",
        "default_timezone_text": "Par défaut, nous utiliserons le fuseau horaire de votre ordinateur. Cliquez sur le menu déroulant pour sélectionner un autre fuseau horaire.",
        "next": "Prochain",
        "schedule_session_diff_limit_error": "La durée de session programmée ne peut pas être inférieure à 30 minutes et supérieure à 365 jours.",
        "discard_change": "Ignorer le changement",
        "discard_change_desc1": "Voulez-vous vraiment annuler les modifications?",
        "discard_change_desc2": "Vous pouvez enregistrer vos modifications, les annuler ou annuler pour continuer la modification.",
        "note": "Remarque",
        "user_cancel": "Annuler",
        "save_button": "sauver",
        "save_changes": "Sauvegarder les modifications",
        "discard_buton": "Jeter",
        "deselect_all": "Tout déselectionner",
        "select_all": "Tout sélectionner",
        "yesterday": "Hier",
        "hours_ago": "Il y a 1 heure",
        "hour_ago": "Il ya 1 heure",
        "minutes_ago": "Il y a 1 minute",
        "minute_ago": "il y a 1 minute",
        "just_now": "Juste maintenant",
        "journey_code": "Code",
        "answer_label": "Répondre",
        "waiting_since_lable": "En attente depuis",
        "incoming_call_label": "Appel entrant",
        "welcome_modal_title": "Toutes nos félicitations! Vous êtes prêt à utiliser Blitzz.",
        "welcome_modal_msg": "Commencez par inviter un invité ou un contact à rejoindre une session vidéo à distance.",
        "view_tutorial": "Voir le didacticiel",
        "get_started": "Commencer"
    },
    "pt_BR": {
        "user_all": "Todos",
        "session_active": "Ativo",
        "session_upcoming": "próximos",
        "session_closed": "Fechadas",
        "archived": "Arquivado",
        "open_link": "Link aberto",
        "join": "Entrar",
        "start_btn": "Começar",
        "share": "Participação",
        "invite": "Convite",
        "code": "CÓDIGO",
        "end_in": "Termina em",
        "starts_in": "Começa em",
        "duration": "Duração",
        "not_started": "Não começou",
        "scheduled": "Programado",
        "user_created": "criado",
        "session_ended": "A sessão terminou",
        "no_sessions": "Sem sessões",
        "new_session": "Nova Sessão",
        "invite_contact": "Convidar contato",
        "reference": "Referência",
        "sort_by": "Ordenar por",
        "you_lable": "Vocês",
        "assigned_to_lable": "Atribuído a",
        "my_session_lable": "Minhas sessões",
        "search": "Procurar",
        "no_user_found_lable": "Nenhum item corresponde ao filtro fornecido",
        "clear_lable": "Claro",
        "loading_note": "Carregando notas ...",
        "no_notes_available_label": "Nenhuma nota disponível para esta sessão ou pode ter sido excluída pelo seu Admin.",
        "created_by": "Criado por",
        "created_on": "Criado em",
        "step": "Degrau",
        "user_of": "do",
        "enter_session_details": "Digite os detalhes da sessão",
        "select_call_experience": "Selecione o perfil de colaboração",
        "add_title": "Adicionar título",
        "reference_required": "Por favor, insira Título / Referência.",
        "numeric_characters_allowed": "Apenas caracteres numéricos são permitidos.",
        "crt_acnt_limit_character_msg_fifty": "São permitidos até 50 caracteres.",
        "schedule_session": "Agendar Sessão",
        "date_time": "Data hora",
        "to_key": "para",
        "enter_valid_start_and_end_date": "Insira a data e hora de início e a data de término válidas.",
        "time_zone": "Fuso horário",
        "default_timezone_text": "Por padrão, usaremos o fuso horário do seu computador. Clique no menu suspenso para selecionar um fuso horário diferente.",
        "next": "Próximo",
        "schedule_session_diff_limit_error": "O tempo de sessão agendada não pode ser inferior a 30 minutos e superior a 365 dias.",
        "discard_change": "Descartar as alterações",
        "discard_change_desc1": "Tem certeza de que deseja descartar as alterações?",
        "discard_change_desc2": "Você pode salvar suas alterações, descartá-las ou cancelar para continuar editando.",
        "note": "Nota",
        "user_cancel": "Cancelar",
        "save_button": "Salve",
        "save_changes": "Salvar alterações",
        "discard_buton": "Descartar",
        "deselect_all": "Desmarcar todos",
        "select_all": "Selecionar tudo",
        "yesterday": "Ontem",
        "hours_ago": "1 horas atrás",
        "hour_ago": "1 hora atrás",
        "minutes_ago": "1 minuto atrás",
        "minute_ago": "1 minuto atrás",
        "just_now": "Agora mesmo",
        "journey_code": "Código",
        "answer_label": "Responder",
        "waiting_since_lable": "esperando desde",
        "incoming_call_label": "Chamada recebida",
        "welcome_modal_title": "Parabéns! Você está pronto para usar o Blitzz.",
        "welcome_modal_msg": "Comece convidando um Visitante ou Contato para participar de uma sessão de vídeo remota.",
        "view_tutorial": "Ver tutorial",
        "get_started": "Iniciar"
    },
    "pt_PT": {
        "user_all": "Todos",
        "session_active": "Ativo",
        "session_upcoming": "próximos",
        "session_closed": "Fechadas",
        "archived": "Arquivado",
        "open_link": "Link aberto",
        "join": "Junte-se",
        "start_btn": "Começar",
        "share": "Participação",
        "invite": "Convite",
        "code": "CÓDIGO",
        "end_in": "Termina em",
        "starts_in": "Começa em",
        "duration": "Duração",
        "not_started": "Não foi iniciado",
        "scheduled": "Programado",
        "user_created": "criado",
        "session_ended": "sessão terminou",
        "no_sessions": "Sem sessões",
        "new_session": "Nova Sessão",
        "invite_contact": "Convidar contato",
        "reference": "Referência",
        "sort_by": "Ordenar por",
        "you_lable": "Vocês",
        "assigned_to_lable": "Atribuído a",
        "my_session_lable": "Minhas sessões",
        "search": "Procurar",
        "no_user_found_lable": "Nenhum item corresponde ao filtro fornecido",
        "clear_lable": "Claro",
        "loading_note": "Carregando notas ...",
        "no_notes_available_label": "Nenhuma nota disponível para esta sessão ou pode ter sido excluída pelo seu Admin.",
        "created_by": "Criado por",
        "created_on": "Criado em",
        "step": "Degrau",
        "user_of": "do",
        "enter_session_details": "Digite os detalhes da sessão",
        "select_call_experience": "Selecione o perfil de colaboração",
        "add_title": "Adicionar título",
        "reference_required": "Por favor, insira Título / Referência.",
        "numeric_characters_allowed": "Apenas caracteres numéricos são permitidos.",
        "crt_acnt_limit_character_msg_fifty": "São permitidos até 50 caracteres.",
        "schedule_session": "Agendar Sessão",
        "date_time": "Data hora",
        "to_key": "para",
        "enter_valid_start_and_end_date": "Insira a data e hora de início e a data de término válidas.",
        "time_zone": "Fuso horário",
        "default_timezone_text": "Por padrão, usaremos o fuso horário do seu computador. Clique no menu suspenso para selecionar um fuso horário diferente.",
        "next": "Próximo",
        "schedule_session_diff_limit_error": "O tempo de sessão agendada não pode ser inferior a 30 minutos e superior a 365 dias.",
        "discard_change": "Descartar as alterações",
        "discard_change_desc1": "Tem certeza de que deseja descartar as alterações?",
        "discard_change_desc2": "Você pode salvar suas alterações, descartá-las ou cancelar para continuar editando.",
        "note": "Nota",
        "user_cancel": "Cancelar",
        "save_button": "Salve",
        "save_changes": "Salvar alterações",
        "discard_buton": "Descartar",
        "deselect_all": "Desmarcar todos",
        "select_all": "Selecionar tudo",
        "yesterday": "Ontem",
        "hours_ago": "1 horas atrás",
        "hour_ago": "1 hora atrás",
        "minutes_ago": "1 minuto atrás",
        "minute_ago": "1 minuto atrás",
        "just_now": "Agora mesmo",
        "journey_code": "Código",
        "answer_label": "Responder",
        "waiting_since_lable": "esperando desde",
        "incoming_call_label": "Chamada recebida",
        "welcome_modal_title": "Parabéns! Você está pronto para usar o Blitzz.",
        "welcome_modal_msg": "Comece convidando um Visitante ou Contato para participar de uma sessão de vídeo remota.",
        "view_tutorial": "Ver tutorial",
        "get_started": "Iniciar"
    },
    "it_IT": {
        "user_all": "Tutti",
        "session_active": "Attivo",
        "session_upcoming": "Prossimo",
        "session_closed": "Chiuso",
        "archived": "Archiviato",
        "open_link": "Link aperto",
        "join": "Aderire",
        "start_btn": "Inizio",
        "share": "Condividere",
        "invite": "Invitare",
        "code": "CODICE",
        "end_in": "Finisce tra",
        "starts_in": "Inizia tra",
        "duration": "Durata",
        "not_started": "Non iniziato",
        "scheduled": "Programmato",
        "user_created": "creato",
        "session_ended": "Sessione terminata",
        "no_sessions": "Nessuna sessione",
        "new_session": "Nuova sessione",
        "invite_contact": "Invita contatto",
        "reference": "Riferimento",
        "sort_by": "Ordina per",
        "you_lable": "voi",
        "assigned_to_lable": "Assegnato a",
        "my_session_lable": "Le mie sessioni",
        "search": "Ricerca",
        "no_user_found_lable": "Nessun articolo corrisponde al filtro fornito",
        "clear_lable": "Chiaro",
        "loading_note": "Caricamento note ...",
        "no_notes_available_label": "Nessuna nota disponibile per questa sessione o potrebbe essere stata eliminata dal tuo amministratore.",
        "created_by": "Creato da",
        "created_on": "Creato",
        "step": "Passo",
        "user_of": "di",
        "enter_session_details": "Inserisci i dettagli della sessione",
        "select_call_experience": "Seleziona Profilo di collaborazione",
        "add_title": "Aggiungi titolo",
        "reference_required": "Si prega di inserire titolo/riferimento.",
        "numeric_characters_allowed": "Sono consentiti solo caratteri numerici.",
        "crt_acnt_limit_character_msg_fifty": "Sono consentiti fino a 50 caratteri.",
        "schedule_session": "Programma sessione",
        "date_time": "Appuntamento",
        "to_key": "per",
        "enter_valid_start_and_end_date": "Inserisci data-ora di inizio e ora di fine valide.",
        "time_zone": "Fuso orario",
        "default_timezone_text": "Per impostazione predefinita, utilizzeremo il fuso orario del tuo computer. Fare clic sul menu a discesa per selezionare un fuso orario diverso.",
        "next": "Il prossimo",
        "schedule_session_diff_limit_error": "La durata della sessione pianificata non può essere inferiore a 30 minuti e superiore a 365 giorni.",
        "discard_change": "Annulla modifica",
        "discard_change_desc1": "Sei sicuro di voler annullare le modifiche?",
        "discard_change_desc2": "Puoi salvare le modifiche, ignorare le modifiche o annullare per continuare a modificare.",
        "note": "Nota",
        "user_cancel": "Annulla",
        "save_button": "Salva",
        "save_changes": "Salvare le modifiche",
        "discard_buton": "Scartare",
        "deselect_all": "Deselezionare tutto",
        "select_all": "Seleziona tutto",
        "yesterday": "Ieri",
        "hours_ago": "1 ora fa",
        "hour_ago": "1 ora fa",
        "minutes_ago": "1 minuto fa",
        "minute_ago": "1 minuto fa",
        "just_now": "Proprio adesso",
        "journey_code": "Codice",
        "answer_label": "Risposta",
        "waiting_since_lable": "Aspettando da",
        "incoming_call_label": "Chiamata in arrivo",
        "welcome_modal_title": "Congratulazioni! Sei pronto per usare Blitzz.",
        "welcome_modal_msg": "Inizia invitando un ospite o un contatto a partecipare a una sessione video remota.",
        "view_tutorial": "Visualizza tutorial",
        "get_started": "Iniziare"
    },
    "zh_CN": {
        "user_all": "全部",
        "session_active": "积极的",
        "session_upcoming": "即将到来",
        "session_closed": "关闭",
        "archived": "存档",
        "open_link": "打开链接",
        "join": "加入",
        "start_btn": "开始",
        "share": "分享",
        "invite": "邀请",
        "code": "代码",
        "end_in": "结束于",
        "starts_in": "开始于",
        "duration": "期间",
        "not_started": "没有开始",
        "scheduled": "预定",
        "user_created": "创建",
        "session_ended": "会话结束",
        "no_sessions": "没有会话",
        "new_session": "新会话",
        "invite_contact": "邀请联系人",
        "reference": "参考",
        "sort_by": "排序方式",
        "you_lable": "你",
        "assigned_to_lable": "分配给",
        "my_session_lable": "我的课程",
        "search": "搜索",
        "no_user_found_lable": "没有项目匹配提供的过滤器",
        "clear_lable": "清除",
        "loading_note": "加载笔记 ...",
        "no_notes_available_label": "没有可用于此会话的备注或可能已被您的管理员删除。",
        "created_by": "由...制作",
        "created_on": "创建于",
        "step": "步",
        "user_of": "的",
        "enter_session_details": "输入会话详细信息",
        "select_call_experience": "选择协作配置文件",
        "add_title": "添加标题",
        "reference_required": "请输入标题/参考。",
        "numeric_characters_allowed": "只允许数字字符。",
        "crt_acnt_limit_character_msg_fifty": "最多允许 50 个字符。",
        "schedule_session": "安排会议",
        "date_time": "约会时间",
        "to_key": "到",
        "enter_valid_start_and_end_date": "输入有效的开始日期时间和结束日期时间。",
        "time_zone": "时区",
        "default_timezone_text": "默认情况下，我们将使用您计算机的时区。单击下拉菜单以选择不同的时区。",
        "next": "下一个",
        "schedule_session_diff_limit_error": "预定会话时间不能少于 30 分钟且不能大于 365 天。",
        "discard_change": "放弃更改",
        "discard_change_desc1": "确定要放弃更改吗？",
        "discard_change_desc2": "您可以保存更改、放弃更改或取消以继续编辑。",
        "note": "笔记",
        "user_cancel": "取消",
        "save_button": "节省",
        "save_changes": "保存更改",
        "discard_buton": "丢弃",
        "deselect_all": "取消全选",
        "select_all": "全选",
        "yesterday": "昨天",
        "hours_ago": "1 小时前",
        "hour_ago": "1小时前",
        "minutes_ago": "1 分钟前",
        "minute_ago": "1 分钟前",
        "just_now": "现在",
        "journey_code": "代码",
        "answer_label": "回答",
        "waiting_since_lable": "等待时间",
        "incoming_call_label": "来电",
        "welcome_modal_title": "恭喜！您已准备好使用 Blitzz。",
        "welcome_modal_msg": "首先邀请来宾或联系人加入远程视频会话。",
        "view_tutorial": "查看教程",
        "get_started": "开始"
    },
    "ja_JP": {
        "user_all": "全て",
        "session_active": "アクティブ",
        "session_upcoming": "今後",
        "session_closed": "閉まっている",
        "archived": "アーカイブ済み",
        "open_link": "リンクを開く",
        "join": "加入",
        "start_btn": "始める",
        "share": "シェア",
        "invite": "招待",
        "code": "コード",
        "end_in": "で終わる",
        "starts_in": "で始まる",
        "duration": "間隔",
        "not_started": "始まっていない",
        "scheduled": "予定",
        "user_created": "作成した",
        "session_ended": "セッション終了",
        "no_sessions": "セッションなし",
        "new_session": "新しいセッション",
        "invite_contact": "連絡先を招待",
        "reference": "参照",
        "sort_by": "並び替え",
        "you_lable": "君は",
        "assigned_to_lable": "に割り当てられた",
        "my_session_lable": "私のセッション",
        "search": "探す",
        "no_user_found_lable": "提供されたフィルターに一致するアイテムはありませんでした",
        "clear_lable": "クリア",
        "loading_note": "読み込み中の注意事項 ...",
        "no_notes_available_label": "このセッションで使用できるメモがないか、管理者によって削除された可能性があります。",
        "created_by": "によって作成された",
        "created_on": "作成日",
        "step": "ステップ",
        "user_of": "の",
        "enter_session_details": "セッションの詳細を入力",
        "select_call_experience": "コラボレーション プロファイルを選択",
        "add_title": "表題を加える",
        "reference_required": "タイトル/参照を入力してください。",
        "numeric_characters_allowed": "数字のみを使用できます。",
        "crt_acnt_limit_character_msg_fifty": "最大 50 文字まで使用できます。",
        "schedule_session": "セッションのスケジュール",
        "date_time": "日付時刻",
        "to_key": "に",
        "enter_valid_start_and_end_date": "有効な開始日時と終了日時を入力してください。",
        "time_zone": "タイムゾーン",
        "default_timezone_text": "デフォルトでは、コンピューターのタイム ゾーンが使用されます。ドロップダウン メニューをクリックして、別のタイム ゾーンを選択します。",
        "next": "次",
        "schedule_session_diff_limit_error": "スケジュールされたセッション時間は 30 分以上、365 日を超えることはできません。",
        "discard_change": "変更を破棄",
        "discard_change_desc1": "変更を破棄してもよろしいですか?",
        "discard_change_desc2": "変更を保存するか、変更を破棄するか、キャンセルして編集を続けることができます。",
        "note": "ノート",
        "user_cancel": "キャンセル",
        "save_button": "保存",
        "save_changes": "変更内容を保存",
        "discard_buton": "破棄",
        "deselect_all": "すべての選択を解除",
        "select_all": "すべて選択",
        "yesterday": "昨日",
        "hours_ago": "1時間前",
        "hour_ago": "1時間前",
        "minutes_ago": "1分前",
        "minute_ago": "1分前",
        "just_now": "ちょうど今",
        "journey_code": "コード",
        "answer_label": "答え",
        "waiting_since_lable": "待機中",
        "incoming_call_label": "電話の着信",
        "welcome_modal_title": "おめでとう！ Blitzz を使用する準備が整いました。",
        "welcome_modal_msg": "ゲストまたは連絡先をリモート ビデオ セッションに招待することから始めます。",
        "view_tutorial": "チュートリアルを見る",
        "get_started": "始めましょう"
    }
}