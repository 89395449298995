export const GET_ACTIVE_USERS = {
  START: "GET_ACTIVEUSERS_START",
  SUCCESS: "GET_ACTIVEUSERS_SUCCESS",
  ERROR: "GET_ACTIVEUSERS_ERROR",
};

const defaultActiveUsersState = {
  getActiveUsersLoading: false,
  getActiveUsersData: false,
  getActiveUsersError: false,
};

export const defaultState = {
  ...defaultActiveUsersState,
};

export const EVENTS = {
  GET_ACTIVE_USERS: "GET_ACTIVE_USERS",
  RESET_ACTIVE_USERS_REDUCER: 'RESET_ACTIVE_USERS_REDUCER'
};

const activeUsersReducer = (state = defaultState, action = {}) => {
  switch (action.event) {
    case EVENTS.RESET_ACTIVE_USERS_REDUCER:
      return defaultState;
    case EVENTS.GET_ACTIVE_USERS:
      return {
        ...state,
        getActiveUsersLoading:
          action.type === GET_ACTIVE_USERS.START ,
        getActiveUsersData:
          action.type === GET_ACTIVE_USERS.SUCCESS ? action.payload : false,
        getActiveUsersError:
          action.type === GET_ACTIVE_USERS.ERROR ? action.payload : false,
      };
    default:
      return state;
  }
};

export default activeUsersReducer;
