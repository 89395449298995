export const EVENTS = {
    SSO_LOGIN: 'SSO_LOGIN',
    AUTH_LOGIN: 'AUTH_LOGIN',
};

export const SSO_LOGIN = {
    START: "SSO_LOGIN_START",
    SUCCESS: "SSO_LOGIN_SUCCESS",
    ERROR: "SSO_LOGIN_ERROR"
}

export const AUTH_LOGIN = {
    START: "AUTH_LOGIN_START",
    SUCCESS: "AUTH_LOGIN_SUCCESS",
    ERROR: "AUTH_LOGIN_ERROR"
}

const defaultSSOLoginState = {
    ssoLoginError: false,
    ssoLoginLoading: false,
    ssoLoginData:false
}

const defaultAuthLoginState = {
    authLoginError: false,
    authLoginLoading: false,
    authLoginData:false
}

const defaultState = {
    ...defaultSSOLoginState,
    ...defaultAuthLoginState
}

const ssoAuthLoginReducer = (state = defaultState, action = {}) => {
    switch (action.event) {
        case EVENTS.SSO_LOGIN:
            return {
                ...state,
                ssoLoginLoading: action.type === SSO_LOGIN.START ,
                ssoLoginData: action.type === SSO_LOGIN.SUCCESS ? action.payload : false,
                ssoLoginError: action.type === SSO_LOGIN.ERROR ? action.payload : false,
            };
        case EVENTS.AUTH_LOGIN:
            return {
                ...state,
                authLoginLoading: action.type === AUTH_LOGIN.START ,
                authLoginData: action.type === AUTH_LOGIN.SUCCESS ? action.payload : false,
                authLoginError: action.type === AUTH_LOGIN.ERROR ? action.payload : false,
            };
        default:
            return state;
    }
}

export default ssoAuthLoginReducer;

