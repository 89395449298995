import React from 'react';
import ReactDOM from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import { Provider } from "react-redux";
import configureEnvStore from "./configureEnvStore";
import { CookiesProvider } from "react-cookie";
import { defaultLDFlags } from './constants/SingleUseConstants';
import { LOCAL_STORAGE_KEYS, RouteName } from './constants/Constants';
import storage from './services/storage';

const store = configureEnvStore();

if (window.location.pathname.startsWith(RouteName.CREATE_AND_INVITE)) {
  if (window.location.pathname.includes('%')) {
    window.location.href = decodeURIComponent(window.location.pathname);
  }
}

(async () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  const domainId = storage.get(LOCAL_STORAGE_KEYS.USER_DATA)?.domainId;
  let ldObj = {
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENTID,
    // deferInitialization: false,
    context: {
      kind: "user",
    },
    flags: defaultLDFlags, //this will add watch to listed flags only
    options: {
      bootstrap: 'localStorage'
    },
  }
  if (domainId) {
    ldObj.context.key = domainId;
  }
  const LDProvider = await asyncWithLDProvider(ldObj);

  root.render(
    <CookiesProvider>
      <Provider store={store}>
        <LDProvider>
          <App />
        </LDProvider>
      </Provider>
    </CookiesProvider>
  );
})();