import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const expandColObj = (expandedRows) => {
  return {
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return <></>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <div className="table-row-expand-btn">
            <span className="for-icon icon-down-triangle-round"></span>
          </div>
        );
      }
      return (
        <div className="table-row-expand-btn">
          <span
            data-testid="column-expander"
            className="for-icon icon-right-triangle-round"
          ></span>
        </div>
      );
    },
    parentClassName: "open",
    expanded: expandedRows,
  };
};

export const EmailColumnFormatterHTML = ({ row }) => {
  return (
    <>
      {row?.EMAIL ? (
        <div className="invitecontact-email">
          <span className="for-icon icon-email-fill"></span>
          <div className="invitecontact-email-value" title={row.EMAIL}>
            {row.EMAIL}
          </div>
        </div>
      ) : (
        <div className="no-data">-</div>
      )}
    </>
  );
};

EmailColumnFormatterHTML.propTypes = {
  row: PropTypes.object,
};

export const EmailColumnFormatter = (_cellContent, row, _rowIndex) => {
  return <EmailColumnFormatterHTML row={row} />;
};

export const PhoneColumnFormatterHTML = ({ row }) => {
  return (
    <>
      {row?.PHONENUMBER !== "-" ? (
        <div className="invitecontact-number">
          <span className="for-icon icon-call-fill"></span>
          <div className="invitecontact-email-value">{row.PHONENUMBER}</div>
        </div>
      ) : (
        <div className="no-data">-</div>
      )}
    </>
  );
};

PhoneColumnFormatterHTML.propTypes = {
  row: PropTypes.object,
};

export const PhoneColumnFormatter = (_cellContent, row, _rowIndex) => {
  return <PhoneColumnFormatterHTML row={row} />;
};

PhoneColumnFormatter.propTypes = {
  row: PropTypes.object,
};

export const ExpandRowHtmlHelper = ({ columnsToShow, row, localisedValues }) => {
  return (
    <table className="inner-table">
      <tbody>
        {columnsToShow < 2 && (
          <tr>
            <th>{localisedValues["email_id"]}</th>
            <td>
              <EmailColumnFormatterHTML row={row} />
            </td>
          </tr>
        )}
        {columnsToShow < 3 && (
          <tr>
            <th>
              <div className="inner-table-th-title">
                {localisedValues["mobile_number"]}
              </div>
            </th>
            <td>
              <PhoneColumnFormatterHTML row={row} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

ExpandRowHtmlHelper.propTypes = {
  columnsToShow: PropTypes.number,
  localisedValues: PropTypes.object
};

export const ExpandRowForInviteContacts = (columnsToShow, localisedValues) => {
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setExpandedRows([]);
  }, [columnsToShow]);

  return {
    renderer: (row, _rowIndex) => (
      <ExpandRowHtmlHelper
        columnsToShow={columnsToShow}
        localisedValues={localisedValues}
        row={row}
      />
    ),
    showExpandColumn: columnsToShow < 10,
    ...expandColObj(expandedRows),
  };
};

ExpandRowForInviteContacts.propTypes = {
  columnsToShow: PropTypes.number,
  localisedValues: PropTypes.object
};
