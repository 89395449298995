import { JOURNEY_STEP_TYPES } from "../../../../../constants/Constants";
import PropTypes from 'prop-types';

const LongTextStep = ({ dataObj, answerObj }) => {
  if (dataObj.type === JOURNEY_STEP_TYPES.LONG_TEXT && answerObj) {
    return (
      <div className="step-info user-fill-info">
        <p>{answerObj}</p>
      </div>
    );
  }
}

LongTextStep.propTypes = {
  dataObj: PropTypes.object,
  answerObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

}

export default LongTextStep;