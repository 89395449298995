import { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { getAllActiveUsers } from "../../actions/activeUsersActions";
import { getCurrentScreen } from "../../shared/utility";
import {
  CONTACTSPERPAGE,
  INITIAL_FILTER,
  INVITECONTACT_WIDTH_CALCULATION_ARRAY,
  RouteName,
} from "../../constants/Constants";
import { Localize } from "./Localize";
import {
  sortCaret,
  headerSortingClasses,
} from "../UI/DataTable/Pagination/TableSortingHelpers";
import history from "../../history";
import {
  EmailColumnFormatter,
  PhoneColumnFormatter,
} from "./ColumnFormatter/ColumnFormatter";
import "./InviteContact.scss";
import CreateSessionModal from "../Sessions/CreateSessionModal/CreateSessionModal";
import ShowInviteContacts from "./ShowInviteContacts";

const InviteContacts = (props) => {
  const location = useLocation();
  const isInviteFromSession = location.state?.isInviteFromSession;
  const isFromSendCode = location.state?.isFromSendCode;

  const ldFlags = useFlags();

  const [activeUsersData, setActiveUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columnsToShow, setColumnsToShow] = useState(10); //Set default to desktop //Show All columns
  const [localisedValues, setLocalisedValues] = useState({});
  const [userSearch, setUserSearch] = useState("");
  const [queryParams, setQueryParams] = useState(INITIAL_FILTER);
  const [selectedBuddy, setSelectedBuddy] = useState(false);
  const [isShowView, setIsShowView] = useState(ldFlags.inviteBuddy ? "showInviteBuddy" : "")

  const columnsToShowRef = useRef(columnsToShow);

  //Sort the data
  const sortedData = useMemo(()=> {

    if(activeUsersData.length > 0){
      return activeUsersData.sort((userDataA, userDataB) => {
        let fieldValueA;
        let fieldValueB;
        if (
          typeof userDataA[queryParams.sortField] === "string" &&
          typeof userDataB[queryParams.sortField] === "string"
        ) {
          fieldValueA = userDataA[queryParams.sortField].toLowerCase();
          fieldValueB = userDataB[queryParams.sortField].toLowerCase();
        } else {
          fieldValueA = userDataA[queryParams.sortField]?.name;
          fieldValueB = userDataB[queryParams.sortField]?.name;
        }
    
        if (fieldValueA < fieldValueB) {
          return queryParams.sortOrder === "asc" ? -1 : 1;
        }
        if (fieldValueA > fieldValueB) {
          return queryParams.sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    else {
      return [];
    }
  },[activeUsersData, queryParams])

  //Filter the data with search value
  const filteredData = useMemo(() => {
    if (sortedData.length === 0) {
        return [];
    }
    const searchQuery = userSearch.trim().toLowerCase(); // Trim and convert to lowercase
    return sortedData.filter((item) =>
        (item.USER?.name?.toString().toLowerCase().includes(searchQuery)) ||
        (item.EMAIL?.toLowerCase().includes(searchQuery)) ||
        (item.PHONENUMBER?.toLowerCase().includes(searchQuery))
    );
}, [sortedData, userSearch, queryParams]);

  //Update data as per page size in data table
  const startIndex = (queryParams.pageNumber - 1) * queryParams.pageSize;
  const endIndex = startIndex + queryParams.pageSize;
  let updatedPaginatedData = filteredData;
  if(filteredData.length > startIndex){
    updatedPaginatedData = filteredData.slice(startIndex, endIndex);
  }
  if(filteredData.length < startIndex){
    queryParams.pageNumber = 1;
  }

  const paginationOptions = {
    custom: true,
    totalSize: filteredData.length,
    page: queryParams.pageNumber,
    sizePerPageList: CONTACTSPERPAGE,
    sizePerPage: queryParams.pageSize,
  };

  useEffect(() => {
    const curLang = props.language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [props.language.selectedlanguage]);

  useEffect(() => {
    getCurrentScreen(
      columnsToShowRef,
      setColumnsToShow,
      INVITECONTACT_WIDTH_CALCULATION_ARRAY
    );
    window.addEventListener("resize", () => {
      getCurrentScreen(
        columnsToShowRef,
        setColumnsToShow,
        INVITECONTACT_WIDTH_CALCULATION_ARRAY
      );
    });
  }, []);

  useEffect(() => {
    columnsToShowRef.current = columnsToShow;
  }, [columnsToShow]);

  useEffect(() => {
    if (ldFlags.inviteBuddy) {
      if (
        !props.activeUsers.getActiveUsersLoading &&
        !props.activeUsers.getActiveUsersData
      ) {
        props.getAllActiveUsers();
        setIsShowView("showInviteBuddy");
      } else {
        setActiveUsersData(props.activeUsers.getActiveUsersData);
      }
    } else {
      setIsShowView("showCreateSessionModal");
    }
  }, [ldFlags]);

  useEffect(()=> {
    if(isFromSendCode){
      setIsShowView("showInviteBuddy");
    }
  },[isFromSendCode])

  useEffect(() => {
    setIsLoading(props.activeUsers.getActiveUsersLoading);
    const activeUserData = props.activeUsers.getActiveUsersData;
    if (activeUserData && activeUserData.items.length > 0) {
      const updatedData = activeUserData.items.map((item) => {
        return {
          USERID: item.userId,
          USER: {
            name: item.fullName,
            image: process.env.REACT_APP_PROFILE_URL + item.profileImage,
            role: localisedValues[item.roleCode + "-Role"],
          },
          EMAIL: item.email,
          PHONENUMBER: item.phoneNo ? item.phoneNo : "-",
          INVITE: localisedValues["invite"],
        };
      });
      setActiveUsersData(updatedData);
    }
  }, [
    localisedValues,
    props.activeUsers.getActiveUsersData,
    props.activeUsers.getActiveUsersLoading,
  ]);

  const columns = [
    {
      dataField: "USERID",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "USER",
      text: localisedValues["user_id"],
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "tbl-th-userid",
      formatter: (_cell, row) => (
        <div className="invitecontact-info">
          <div
            className="invitecontact-info-image"
            style={{ backgroundImage: `url(${row.USER.image})` }}
          ></div>
          <div className="invitecontact-info-desc">
            <div className="title" title={row.USER.name}>
              <div className="title-in">{row.USER.name}</div>
            </div>
            <div className="desc">{row.USER.role}</div>
          </div>
        </div>
      ),
      sortValue: (_cell, row) => row.USER.name,
      headerSortingClasses,
    },
    {
      dataField: "EMAIL",
      text: localisedValues["email_id"],
      hidden: columnsToShow < 2,
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "tbl-th-emailid",
      formatter: EmailColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "PHONENUMBER",
      text: localisedValues["mobile_number"],
      hidden: columnsToShow < 3,
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "tbl-th-monumber",
      formatter: PhoneColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "INVITE",
      text: localisedValues["invite"],
      headerClasses: "tbl-th-action text-center",
      formatter: (_cell, row) => (
        <div className="button contact-invite-btn">
          <button
            className="btn btn-light-primary"
            onClick={() => setSelectedBuddy(row)}
          >
            {row.INVITE}
          </button>
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "USER",
      order: "asc",
    },
    {
      dataField: "EMAIL",
      order: "asc",
    },
    {
      dataField: "PHONENUMBER",
      order: "asc",
    },
  ];

  return (
    <>
      {(isShowView === "showInviteBuddy" || isInviteFromSession) && (
        <ShowInviteContacts
          isShowView={isShowView}
          columns={columns}
          columnsToShow={columnsToShow}
          localisedValues={localisedValues}
          isLoading={isLoading}
          updatedPaginatedData={updatedPaginatedData}
          setQueryParams={setQueryParams}
          paginationOptions={paginationOptions}
          defaultSorted={defaultSorted}
          userSearch={userSearch}
          setUserSearch={setUserSearch}
          setSelectedBuddy={setSelectedBuddy}
          selectedBuddy={selectedBuddy}
        />
      )}
      {isShowView === "showCreateSessionModal" && (
        <CreateSessionModal
          closeModal={() => history.push(RouteName.SESSIONS)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
    activeUsers: state.activeUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllActiveUsers: () => dispatch(getAllActiveUsers()),
  };
};

InviteContacts.propTypes = {
  getAllActiveUsers: PropTypes.func,
  activeUsers: PropTypes.object,
  language: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteContacts);
