export const EVENTS = {
    ISCOMPANY_AVAILABLE: 'ISCOMPANY_AVAILABLE',
    GET_JOURNEY_LIST: "GET_JOURNEY_LIST",
    LOGOUT:'LOGOUT'
};

export const ISCOMPANY_AVAILABLE = {
    START: "ISCOMPANY_AVAILABLE__START",
    SUCCESS: "ISCOMPANY_AVAILABLE__SUCCESS",
    ERROR: "ISCOMPANY_AVAILABLE__ERROR"
}

export const GET_JOURNEY_LIST = {
    START: "GET_JOURNEY_LIST_START",
    SUCCESS: "GET_JOURNEY_LIST_SUCCESS",
    ERROR: "GET_JOURNEY_LIST_ERROR"
}

export const LOGOUT = {
    START: "LOGOUT_START",
    SUCCESS: "LOGOUT_SUCCESS",
    ERROR: "LOGOUT_ERROR",
    RESET: "LOGOUT_RESET"
}

const defaultIsCompanyAvailableState = {
    isCompanyAvailableError: false,
    isCompanyAvailableData: false,
    isCompanyAvailableLoading: false
}

const defaultGetJourneyListState = {
    getJourneyListError: false,
    getJourneyListData: false,
    getJourneyListLoading: false
}

const defaultLogoutState = {
    logoutError: false,
    logoutLoading: false,
    logoutData:false
}

const defaultState = {
    ...defaultIsCompanyAvailableState,
    ...defaultGetJourneyListState,
    ...defaultLogoutState
}

const onLoadFunctionReducer = (state = defaultState, action = {}) => {
    switch (action.event) {
        case EVENTS.ISCOMPANY_AVAILABLE:
            return {
                ...state,
                isCompanyAvailableLoading: action.type === ISCOMPANY_AVAILABLE.START ,
                isCompanyAvailableData: action.type === ISCOMPANY_AVAILABLE.SUCCESS ? action.payload : false,
                isCompanyAvailableError: action.type === ISCOMPANY_AVAILABLE.ERROR ? action.payload : false,
            };
        case EVENTS.GET_JOURNEY_LIST:
            return {
                ...state,
                getJourneyListLoading: action.type === GET_JOURNEY_LIST.START ,
                getJourneyListData: action.type === GET_JOURNEY_LIST.SUCCESS ? action.payload : false,
                getJourneyListError: action.type === GET_JOURNEY_LIST.ERROR ? action.payload : false,
            };
        case EVENTS.LOGOUT:{
            if(action.type === LOGOUT.RESET){
                return {
                        ...state,
                        ...defaultLogoutState
                   }
            }
            else{
                return {
                    ...state,
                    logoutLoading: action.type === LOGOUT.START ,
                    logoutData: action.type === LOGOUT.SUCCESS ? action.payload : false,
                    logoutError: action.type === LOGOUT.ERROR ? action.payload : false,
               };
            }
        }
        default:
            return state;
    }
}

export default onLoadFunctionReducer;

