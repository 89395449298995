import {combineReducers} from 'redux';

import languageReducer from './language-reducer';
import portalNameReducer from './portalname-reducer';
import onLoadFunctionReducer from "./onLoadFunction-reducer"
import loginReducer from "./login-reducer";
import signUpReducer from './signUp-reducer';
import sessionListReducer from './sessionList-reducer';
import sessionDetailReducer from './sessionDetail-reducer';
import changePasswordReducer from './change-password-reducer';
import ssoAuthLoginReducer from './ssoAuthLogin-reducer';
import selfSignupReducer from './selfSignup-reducer';
import activeUsersReducer from './activeUsers-reducer';

export const Reducers = combineReducers({
    login: loginReducer,
    language: languageReducer,
    portal: portalNameReducer,
    onLoadData: onLoadFunctionReducer,
    signUp: signUpReducer,
    sessionList: sessionListReducer,
    activeUsers: activeUsersReducer,
    sessionDetail: sessionDetailReducer,
    changePassword:changePasswordReducer,
    ssoAuthLogin:ssoAuthLoginReducer,
    selfSignup:selfSignupReducer
})

export default Reducers;