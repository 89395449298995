import { Field } from "formik";
import clsx from "clsx";
import PropTypes from 'prop-types';

export const FormikInputWithIcon = ({ id, type = 'text', placeholder, touched, error, className, onChange, onBlur, iconSpanClass, showIcon=true, ...otherProps }) => {
    const styles = clsx(
        "form-control form-control-grey",
        className ? className : ''
    );
    const iconClass = clsx(
        "form-group-icon-in",
        otherProps.iconClassName ? otherProps.iconClassName : ''
    )
    const divClass = clsx(
        "form-group-in",
        otherProps.divClass ? otherProps.divClass : ''
    )

    return (
        <div className="form-group">
            <div className={divClass}>
                <Field
                    id={id}
                    data-testid={id}
                    className={styles}
                    placeholder={placeholder}
                    name={id}
                    type={type}
                    onChange={onChange}
                    onBlur={onBlur}
                    validate={otherProps.validate}
                    touched={touched && touched.toString()}
                />
                {showIcon &&
                    <span className={clsx("form-group-icon bg-none",
                        (otherProps.isCheckValid) ? iconSpanClass : "")}>
                        <span className={iconClass}></span>
                    </span>
                }
            </div>
            {touched && error && <div className="validation-error">{error}</div>}
        </div>
    );
}

FormikInputWithIcon.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    touched: PropTypes.any,
    error: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    iconSpanClass: PropTypes.string,
    showIcon: PropTypes.bool,
}