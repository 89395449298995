import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import __ from "lodash";
import PropTypes from 'prop-types';
import moment from 'moment';
import { RouteName } from '../../../constants/Constants';
import history from '../../../history';
import { formatSessionID } from '../../../shared/utility';
let waitData = '';

const JourneySessionCell = (props) => {
    const {
        journeySession,
        localisedValues
    } = props;
    const [isSelected, setIsSelected] = useState(false);
    const [waitingData, setWaitingData] = useState(null);

    const params = useParams();

    useEffect(() => {
        if (__.isEmpty(params)) {
            setIsSelected(false);
        }
        else {
            setIsSelected(params.sessionId === journeySession.sessionID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        setInterval(() => {
            const actualTime = moment(journeySession.startTime);
            const diff = moment.utc().diff(actualTime);
            const duration = moment.duration(diff).asMilliseconds();
            waitData = moment.utc(duration).format('mm:ss')
            setWaitingData(waitData);
        }, 500)
    }, []);

    const sessionClickHandler = () => {
        const url = RouteName.SESSION_DETAILS.replace(':sessionId', journeySession.sessionID)
        history.push(url, {
            sessionId: journeySession.sessionID,
            sessionType: journeySession.sessionType
        });
    }

    return (
        <div className={clsx("session-list-box journey-session incoming-call", isSelected ? 'active' : '')} key={journeySession.sessionID}>
            <div className="session-list-box-in" onKeyDown={() => { }} onClick={sessionClickHandler}>
                <div className="session-list-box-head custom-tooltip right-tooltip tooltip-auto-width m-hide">
                    <div className="journey-icon">
                        <span className="for-icon icon-journey"></span>
                    </div>
                    <div className="session-list-ref">
                        {journeySession.jName}
                        <div className="custom-tooltip-content">
                            <div className="custom-tooltip-in">
                                <p>{journeySession.jName}</p>
                            </div>
                        </div>
                    </div>
                    <div className="session-list-box-btn">
                        <div className="button">
                            <button className="btn btn-light-success1 btn-success" onClick={() => props.joinJourneySession(journeySession.sessionID, journeySession)}>
                                <span className="btn-icon icon-video"></span>
                                <span className="btn-text">{localisedValues["answer_label"]}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="width-50">
                        <div className="session-list-box-row">
                            <div className="session-list-row-box">
                                <strong className="lbl"><span>{localisedValues["journey_code"]}</span>:</strong> <span className="code">{formatSessionID(journeySession.sessionID)}</span>
                            </div>
                        </div>
                        <div className="session-list-box-row">
                            <div className="session-list-row-box">
                                <strong className="lbl"><span>{localisedValues["waiting_since_lable"]}</span>:</strong> <span>{waitingData}</span>
                            </div>
                        </div>
                    </div>
                    <div className="width-50 text-end">
                        <div className="incoming-call-label">
                            <span>{localisedValues["incoming_call_label"]}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

JourneySessionCell.propTypes = {
    journeySession: PropTypes.object,
    localisedValues: PropTypes.object,
    joinJourneySession: PropTypes.func
}

export default JourneySessionCell;
