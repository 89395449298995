import clsx from "clsx";
import PropTypes from 'prop-types';
import { LOCAL_STORAGE_KEYS } from "../../../constants/Constants";
import storage from "../../../services/storage";
import { getCurrentUser } from "../../../shared/utility";
import SessionUsers from "../SessionUsers/SessionUsers";
import SessionSearch from "./Filter/SessionSearch";
import SortFilter from "./Filter/SortFilter";
import StatusFilter from "./Filter/StatusFilter";


const SessionFilter = (props) => {

    const sortChangeHandler = (updatedSortBy, updatedSortOrder) => {
        props.updateSortBy(updatedSortBy);
        props.updateSortOrder(updatedSortOrder);
    }

    const statusChangeHandler = (value) => {
        props.statusChangeHandler(value);
    }

    const selectAllChangeHandler = (isSelectAll) => {
        props.selectAllChangeHandler(isSelectAll);
    }

    const selectedUsersChangeHandler = (selectedUsers) => {
        props.selectedUsersChangeHandler(selectedUsers);
    }

    const handleFetchSessionsWithoutUsers = () => {
        const user = getCurrentUser();
        const selectedUsers = [{ id: user.userId, name: user.firstName }];
        storage.set(LOCAL_STORAGE_KEYS.SELECTED_USER_ID, selectedUsers);
        storage.set(LOCAL_STORAGE_KEYS.SELECT_ALL, false)
        props.fetchSessionsWithoutUsers(selectedUsers);
    };

    return (
        <div className="session-sidebar-head clearfix">
            <div className={clsx("main-filter-box clearfix", props.showSessionUserDropdown === false && "session-visibility-off")}>
                <div className="filter-sort-box clearfix">
                    <StatusFilter statusChangeHandler={statusChangeHandler} isDiscardChanged={props.isDiscardChanged} />
                    <SortFilter ref={props.sortFilterRef} selectedFilter={props.selectedFilter} sortChangeHandler={sortChangeHandler} isDiscardChanged={props.isDiscardChanged} />
                </div>
                <SessionUsers
                    isDiscardChanged={props.isDiscardChanged}
                    selectAllChangeHandler={selectAllChangeHandler}
                    selectedUsersChangeHandler={selectedUsersChangeHandler}
                    showSessionUserDropdown={props.showSessionUserDropdown} // session user dropdown is visible or not pass as a props
                    clearSelectedUserInSessionList={props.clearSelectedUserInSessionList} // handle Clear selected user button in session list user dropdown
                    fetchSessionsWithoutUsers={handleFetchSessionsWithoutUsers} // handle on=load fetch session without any other selected user
                    localisedValues={props.localisedValues}
                />
            </div>
            <SessionSearch searchTextChangeHandler={props.searchTextChangeHandler} isDiscardChanged={props.isDiscardChanged} localisedValues={props.localisedValues} />
        </div>
    );
}


SessionFilter.propTypes = {
    isDiscardChanged: PropTypes.bool,
    updateSortBy: PropTypes.func,
    updateSortOrder: PropTypes.func,
    statusChangeHandler: PropTypes.func,
    selectAllChangeHandler: PropTypes.func,
    selectedUsersChangeHandler: PropTypes.func,
    fetchSessionsWithoutUsers: PropTypes.func,
    searchTextChangeHandler: PropTypes.func,
    localisedValues: PropTypes.object,
    showSessionUserDropdown: PropTypes.bool,
    clearSelectedUserInSessionList: PropTypes.func
}

export default SessionFilter;