import PropTypes from 'prop-types';
import { DefaultLanguage, FORMATTED_DATE, JOURNEY_STEP_TYPES, MEETING_TYPE } from '../../../../constants/Constants';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormResponse from './AnswerStep/FormResponse';
import { Localize } from '../Localize';
import PublicVariables from '../../../../constants/PublicVariables';

const ResponsesTab = (props) => {

    const { sessionType, journeySessionDetails, imageData, videoData, fancyRef } = props;

    const [formResponseData, setFormResponseData] = useState([]);
    const [localisedValues, setLocalisedValues] = useState({});

    const { language } = useSelector((state) => state)
    const lang = PublicVariables.currentLanguage.toLowerCase()

    useEffect(() => {
        const curLang = language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
    }, [language.selectedlanguage]);


    useEffect(() => {
        if (journeySessionDetails?.journeyDetail && (imageData || videoData)) {
            const jsonParseData = JSON.parse(journeySessionDetails?.journeyDetail?.formResponse);
            const finalResult = [];
            for (let i = 0; i < jsonParseData.answer.length; i++) {
                const answerItem = jsonParseData.answer[i];
                // Find corresponding question
                const questionItem = jsonParseData.data.find((q) => q.id === answerItem.id);

                // Find corresponding image
                let imageItem, videoItem
                if (questionItem.type === JOURNEY_STEP_TYPES.GPS_LOCATION && imageData.length > 0) {
                    imageItem = imageData.find((img) => img?.fileName === answerItem.answer[0]?.fileName);
                }
                if (questionItem.type === JOURNEY_STEP_TYPES.VIDEO && videoData.length > 0) {
                    videoItem = videoData.find((img) => img?.fileName === answerItem.answer[0]?.fileName);
                }
                if (questionItem.type === JOURNEY_STEP_TYPES.IMAGE && imageData.length > 0) {
                    imageItem = answerItem.answer.map((file) => {
                        return imageData.find((img) => img?.fileName === file?.fileName);
                    })
                }

                // Create a new object with the merged details
                const mergedItem = {
                    id: answerItem.id,
                    data: questionItem,
                    answer: answerItem.answer
                };
                if (imageItem) {
                    mergedItem.image = imageItem;
                }
                if (videoItem) {
                    mergedItem.video = videoItem;
                }
                
                // Add the merged item to the result array
                finalResult.push(mergedItem);
            }
            // Update the state once with the final data
            console.log('______finalResult_____', finalResult)
            setFormResponseData(finalResult);
        }
    }, [journeySessionDetails?.journeyDetail, imageData, videoData]);


    const goToStepButtonActionName = (btnName, btnKey, id, localisedValues) => {
        let val = formResponseData?.find(element => element?.id === id);
        if (btnKey === val.answer.go_to[0]) {
            return btnName
        } else if (val.answer.redirect_to.includes(btnKey)) {
            return localisedValues["viewed"]
        } else {
            return btnName
        }
    };

    const addTitleOfRedirectionButton = (btnName, btnValue) => {
        if (btnName === "skip") {
            if (btnValue === "-1") {
                return "Submit (Last step)"
            } else if (btnValue === "-2") {
                return "Next Step"
            } else {
                let val = formResponseData.find(element => element.id === btnValue);
                if (val?.data.title[language.selectedlanguage.id]) {
                    return val?.data.title[language.selectedlanguage.id];
                } else if (val?.data.title[DefaultLanguage]) {
                    return val?.data.title[DefaultLanguage];
                } else {
                    return val?.data.title[DefaultLanguage];
                }
            }
        } else if (btnName === "url") {
            return btnValue;
        }
    };

    return (
        <>
            {journeySessionDetails && <div className={clsx("tab-pane fade show", sessionType === MEETING_TYPE.JOURNEY && "active")} id="session-responses" role="tabpanel">
                <div className="form-response custom-scroll-div">
                    <div className="form-response-head">
                        <div className="form-response-head-title">
                            <span className="title">{localisedValues["journey_label"]}: </span>
                            <span className="info">{journeySessionDetails?.journeyDetail?.name}</span>
                        </div>
                        <div className="form-response-head-date">
                            <span className="title">{localisedValues["submitted_on"]} </span>
                            <span className="info">{moment.utc(journeySessionDetails?.journeyDetail?.formSubmittedAt).locale(lang).format(FORMATTED_DATE)}</span>
                        </div>
                    </div>
                    <div className="form-response-body custom-scroll-div">
                        <div className="form-response-list">
                            {formResponseData?.map((data, index) => (
                                <FormResponse
                                    fancyRef={fancyRef}
                                    key={index}
                                    answerObj={data?.answer}
                                    answerObjID={data?.id}
                                    dataObj={data.data}
                                    imageObj={data?.image}
                                    videoObj={data?.video}
                                    index={index}
                                    language={language}
                                    localisedValues={localisedValues}
                                    goToStepButtonActionName={goToStepButtonActionName}
                                    addTitleOfRedirectionButton={addTitleOfRedirectionButton}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
}

ResponsesTab.propTypes = {
  sessionType: PropTypes.number,
  journeySessionDetails: PropTypes.object,
  imageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  videoData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fancyRef: PropTypes.object,
};

export default ResponsesTab;