/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from 'yup';
import { isEmpty } from "lodash";
import React, { useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import {getDomainURL} from "../../shared/utility"
import PublicVariables from "../../constants/PublicVariables";
import { LOCAL_STORAGE_KEYS, RouteName, SSO_TOKEN_URL} from "../../constants/Constants"
import { Card } from "../UI/Card/Card";
import {FormikInputGroup} from "../UI/TextInput/FormikInputGroup";
import {SubmitButton} from "../UI/Button/Button";
import {LoginUser, VerifyOTP, ResendOTP, VerifyDomain} from "../../actions/loginAction";
import Authy from "./Authy/Authy";
import { Localize } from "./Localize";
import history from "../../history";
import ForgotPassword from "./ForgotPassword";
import SSOLoginModal from "./SSOLoginModal";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import storage from "../../services/storage";
import { resetAllSessionListReducerObj } from "../../actions/sessionListAction";
import { resetAllSessionDetailReducerObj } from "../../actions/sessionDetailAction";
import { resetActiveUsersReducerObj } from "../../actions/activeUsersActions";

let loginID = null;
const Login = (props) => {
        //used React.useState instead of useState direct to update state from test file
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [istwoFactorAuth, setIstwoFactorAuth] = React.useState(false);
    const [verificationId, setVerificationId] = React.useState(null);
    const [uemail, setUemail] = React.useState(null);
    const [isCustomDomain, setIsCustomDomain] = React.useState(false);
    const [showSSoLogin, setShowSSoLogin] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [localisedValues, setLocalisedValues] = React.useState({});
    const params = useParams();
    const dataForSession = useMemo(() => { return storage.get(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION)}, []);

    if (!isEmpty(params?.loginId)) {
        loginID = decodeURIComponent(params?.loginId);
    } else {
        loginID = null;
    }
    
    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    useEffect(()=>{
        if(getDomainURL() == null || getDomainURL() === ""){
            setIsCustomDomain(false);
            setShowSSoLogin(true);
        }else{
            setIsCustomDomain(true);
            console.log(PublicVariables.domainDataForLD);
            if (PublicVariables.domainDataForLD && PublicVariables.domainDataForLD.integrations === true && PublicVariables.domainDataForLD.samlIdentity) {
                setShowSSoLogin(true);
            }            
        }
        props.resetAllSessionListReducerObj();
        props.resetAllSessionDetailReducerObj();
        props.resetActiveUsersReducerObj();
    },[])

    useEffect(() => {
        setIsSubmitting(props.login.loginLoading);
        if(props.login.loginData){
            setIstwoFactorAuth(props.login.loginData.otpVerificationRequired);
            if(props.login.loginData.otpVerificationRequired){ // if true then show otp verify screen
                setVerificationId(props.login.loginData.verificationId);
            }
        }
        if(props.login.resendOtpData && props.login.resendOtpData.verificationId){
            setVerificationId(props.login.resendOtpData.verificationId);
        }
        // eslint-disable-next-line
    }, [props.login.loginLoading, props.login.loginData, props.login.resendOtpData, props.login.verifyOtpData]);    

    const loginUser = (values) => {
        console.log(values);
        setUemail(values.email);
        PublicVariables.uEmailOnLogin = values.email;
        let userdata = {
            email: values.email,
            password: values.password,
        };
        props.LoginUser(userdata);
    }

    const redirectToSignUpPage = () => {	
        history.push(RouteName.CREATE_ACCOUNT)	
    }; 

    const checkSSOLogin = () => {
        if (!isCustomDomain) { // default URL open model
            setShowModal('ssoLogin');
        } else { // sub domain/Host mapping url
            if (PublicVariables.domainDataForLD && PublicVariables.domainDataForLD.integrations === true && PublicVariables.domainDataForLD.samlIdentity) {
                window.location.href = SSO_TOKEN_URL + PublicVariables.domainDataForLD.samlIdentity;
            }
        }
    }

    return (
        <>
            {!istwoFactorAuth && <div className="login-section-box custom-scroll-div">
                <div className="login-section-in">
                    <Formik
                        initialValues={{
                            email: !isEmpty(loginID) ? loginID : '',
                            password: ''
                        }}
                        validationSchema={
                            Yup.object({
                                email: isCustomDomain ? 
                                        Yup.string().trim().required(localisedValues['email_ID_User_ID_is_required']).max(255, localisedValues['error_limit_exceeded'])
                                        : Yup.string().email(localisedValues['enter_valid_email']).max(255, localisedValues['error_limit_exceeded']).required(localisedValues['email_ID_is_required']),
                                password: Yup.string().required(localisedValues['password_required'])
                            })
                        }
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                loginUser(values);
                                setSubmitting(false);
                            }, 400)
                        }}
                    >
                    {formik => (
                        <Card>
                            <Form>
                                <div className="login-logo-subtext text-center">
                                    <h3>{localisedValues['sign_in']}</h3>
                                </div>
                                {/* handle sasktel login model start here */}
                                <div className={clsx('login-credential-button-div login-with-credential-collapse collapse', (dataForSession && showSSoLogin) && "show")}> 
                                    <div className="button-inline login-credential-button-inline text-center d-flex justify-content-center">
                                        <div className="button">
                                            <button type="button" className="btn btn-link btn-with-icon font-weight-500 text-dec-none" data-bs-toggle="collapse" data-bs-target=".login-with-credential-collapse" aria-expanded="false">
                                                {localisedValues['login_with_your_credentials']}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* handle sasktel login model end here */}

                                <div className={clsx("login-with-credential login-with-credential-collapse collapse", (!dataForSession || (dataForSession && !showSSoLogin)) && 'show')}>
                                    <FormikInputGroup
                                        id="email"
                                        type={isCustomDomain ? "text":"email"}
                                        className="height-46 focus-font-bold"
                                        placeholder={isCustomDomain ? localisedValues['email_placeholder']:localisedValues['email_id']}
                                        touched={formik.touched.email}
                                        error={formik.errors.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                    />
                                    <FormikInputGroup
                                        id="password"
                                        type="password"
                                        placeholder={localisedValues['pwd_placeholder']}
                                        className="height-46 focus-font-bold"
                                        touched={formik.touched.password}
                                        error={formik.errors.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} 
                                    />
                                    
                                    <div className="button-inline text-center padding-top-10 d-flex justify-content-center">
                                        <div className="button">
                                            <SubmitButton className="btn btn-primary font-weight-500" data-testid="signInButton" id="SignIn" name={localisedValues['sign_in']} isSubmitting={isSubmitting} disable={isSubmitting || !formik.isValid} showLoader={true} />
                                        </div>
                                    </div>
                                    <div className="login-bottom-text">
                                        <div className="have-an-account text-center">	
                                            {localisedValues['dont_have_account']} <a href={void(0)} className="text-dec-none pointer" rel="noreferrer" data-testid="signUpButton" onClick={redirectToSignUpPage} >{localisedValues['sign_up']}</a>	
                                        </div>
                                        <div className="have-an-account text-center">
                                            <a href={void(0)} className="text-dec-none pointer" data-testid="forgotPasswordButton"  onClick={()=>{setShowModal('forgotPassword')}}>{localisedValues['forgot_password']}</a>
                                        </div>
                                        <div className="have-an-account text-center" style={{display:'none'}}>
                                            <a href={void(0)} className="text-dec-none pointer" data-testid="recoverPasswordBtn"  onClick={()=>{history.push('/recoverpassword/17e728c3-e6ee-4e35-aac9-547914c4a830')}}>{"Recover Password"}</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </Form>
                            {showSSoLogin &&
                                <div className="or-seperator">
                                    <span className="or-text">{localisedValues['or_key']}</span>
                                </div>
                            }
                            {showSSoLogin &&
                                <div className="button-inline text-center padding-top-10 d-flex justify-content-center">
                                    <div className="button">
                                        <button type="button" className="btn btn-outline-primary btn-with-icon font-weight-500" onClick={checkSSOLogin}>
                                            {localisedValues['login_with_sso']}</button>
                                    </div>
                                </div>
                            }
                        </Card>
                    )}
                    </Formik>
                </div>
            </div>}
            {istwoFactorAuth && 
                <Authy 
                    verificationId={verificationId}
                    login={props.login}
                    uemail={uemail}
                    verifyOTP={props.VerifyOTP}
                    resendOTP={props.ResendOTP}
                    localisedValues={localisedValues}> 
                </Authy>
            }
            {showModal === 'forgotPassword' && 
                <ForgotPassword
                    localisedValues={localisedValues}
                    onClose={(val)=>{setShowModal(val);}}
                />
            }
            {showModal === 'ssoLogin' && 
                <SSOLoginModal
                    localisedValues={localisedValues}
                    onClose={(val)=>{setShowModal(val);}}
                    verifyDomain={props.VerifyDomain}
                    login={props.login}
                />
            }
        </>
    )
}

const mapStateToPropsLogin = (state) => {
    return {
        login: state.login,
        language: state.language
    }
}

const mapDispatchToPropsLogin = (dispatch) =>{
    return {
        LoginUser: (req) => dispatch(LoginUser(req)),
        VerifyOTP: (req) => dispatch(VerifyOTP(req)),
        ResendOTP: (req) => dispatch(ResendOTP(req)),
        VerifyDomain: (req) => dispatch(VerifyDomain(req)),
        resetAllSessionListReducerObj: () => dispatch(resetAllSessionListReducerObj()),
        resetAllSessionDetailReducerObj: () => dispatch(resetAllSessionDetailReducerObj()),
        resetActiveUsersReducerObj: () => dispatch(resetActiveUsersReducerObj()),
    }
}

Login.propTypes = {
    login: PropTypes.object,
    language: PropTypes.object,
    LoginUser: PropTypes.func,
    VerifyOTP: PropTypes.func,
    ResendOTP: PropTypes.func,
    VerifyDomain: PropTypes.func,
}

export default connect(mapStateToPropsLogin, mapDispatchToPropsLogin)(Login);
