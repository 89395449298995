import clsx from "clsx";
import PropTypes from 'prop-types';

export const BeforeAuthContainer = (props) => {
    return (
        <div className={clsx("login-section login-bg", props.className ? props.className : '')}>
            {props.children}
        </div>
    );
}

BeforeAuthContainer.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
}

export const Card = (props) => {
    return (
        <div className={clsx("login-section-in-center", props.className ? props.className : '')}>
            {props.children}
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
}
