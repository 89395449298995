import { get, parseInt, reverse } from 'lodash';
import moment from 'moment/min/moment-with-locales';
import 'moment/min/locales.min'
import { beamerInitialize } from 'react-beamer';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as JSZipUtils from 'jszip-utils';
import { LOCAL_DOMAIN_NAME_FOR_TESTING } from "../App";
import { RouteName, LOCAL_STORAGE_KEYS, Token_time, LOCAL_STORAGE_KEYS_ADMIN, INVITECONTACT_WIDTH_CALCULATION_ARRAY, DATE_TIME_FORMAT_24_HOURS, DefaultLanguage, VERSION_KEY, VERSION_VALUE, supportedIOSInfo, PAGE_TITLE_EXTENSION, LOGGED_IN } from "../constants/Constants";
import PublicVariables from "../constants/PublicVariables";
import history from "../history";
import storage from "../services/storage";
import CustomToast from '../components/UI/customToast';
import DesktopNotification from '../services/desktopNotification';
import SignalR from '../services/signalR';
import { Localize } from '../components/Sessions/Localize';
import DetectRTC from 'detectrtc';
import { convertKeysToLowerCase } from './extensions';

const ReservedHosts = ['web.blitzz.co', 'journeydev.blitzz.app', 'journeydev.blitzz.co', 'rc.blitzz.us', 'rc.blitzz.co', 'dev.blitzz.co', 'agentdev.blitzz.co', 'blitzz-dev-agent.azurewebsites.net'];

export const getDomainURL = () => {
    const currentHost = window.location.host;
    let customizeDomainUrl = '';

    if (!ReservedHosts.includes(currentHost)) {
        customizeDomainUrl = window.location.origin;
    }

    if (window.location.hostname === 'localhost' || window.location.hostname === 'agentdev.blitzz.co') {
        customizeDomainUrl = ReservedHosts.includes(LOCAL_DOMAIN_NAME_FOR_TESTING) ? '' : "https://" + LOCAL_DOMAIN_NAME_FOR_TESTING;
    }

    return customizeDomainUrl;
}

export const isSelfSignupPath = () => {
    return history.location.pathname === RouteName.GET_STARTED ||
        history.location.pathname === RouteName.CONFIRMATION ||
        history.location.pathname === RouteName.USER_INFO ||
        history.location.pathname === RouteName.COMPANY_INFO ||
        history.location.pathname === RouteName.INVITES;
}

export const updatePortalName = (toolname) => async (dispatch) => {
    let portalName = toolname;//''; //setting toolname as portalName for testing
    if (!PublicVariables.configurations?.branding && PublicVariables.branding) {
        portalName = PublicVariables.branding ? toolname : '';
    }
    else if (PublicVariables.configurations?.branding) {
        portalName = toolname;
    }

    PublicVariables.companyPortalName = portalName;
    storage.set(LOCAL_STORAGE_KEYS.TOOL_NAME, portalName);
    dispatch(portalName);
}

export const setPublicVariables = (data) => {
    PublicVariables.customDomain = true;
    PublicVariables.domainDataForLD = data;
    PublicVariables.companyLOGO = data.domainLogo;
    PublicVariables.branding = data.branding;
    if (data?.languages) {
        PublicVariables.companyPortalNameList = data.languages;
    }
    PublicVariables.companyFAVICON = data.faviconImage;
    const favicon = document.getElementById("favicon");
    if (data.faviconImage) {
        favicon.setAttribute("href", process.env.REACT_APP_FAVICON_URL + data.faviconImage);
    }
}

export const inItBeamer = () => {
    const userdata = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
    const domainConfigdata = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
    const beamer_config = {
        user_firstname: userdata?.firstName, /*Optional : Input your user firstname for better statistics*/
        user_lastname: userdata?.lastName, /*Optional : Input your user lastname for better statistics*/
        user_email: userdata?.notificationEmail, /*Optional : Input your user email for better statistics*/
        user_id: userdata?.userId, /*Optional : Input your user ID for better statistics*/
        company_role: userdata?.roleCode,
        company_id: userdata?.domainId,
        company_name: userdata?.domainName,
        company_plan: domainConfigdata?.configurations.edition,
        website: PublicVariables?.domainDataForLD?.domainUrl,
        subscription_type: domainConfigdata?.billingData?.subscriptiontype,
        ontrial: PublicVariables?.domainDataForLD?.isTrial,
        expiration_date: PublicVariables?.domainDataForLD?.expireAt,
        notification_prompt: 'sidebar',
        selector: '.beamerButton',
    }

    if (domainConfigdata?.billingData) {
        beamer_config['Billable Sessions'] = domainConfigdata.billingData.billablesessions;
        beamer_config['Billable Users'] = domainConfigdata.billingData.billableusers;
        beamer_config['Contract Start Date'] = domainConfigdata.billingData.contractstartdate;
        beamer_config['Domain Type'] = domainConfigdata.billingData.domaintype;
        beamer_config['Free Licences'] = domainConfigdata.billingData.freelicences;
        beamer_config['Subscription Frequency'] = domainConfigdata.billingData.frequency;
        beamer_config['Partner email'] = domainConfigdata.billingData.partneremail;
        beamer_config['Partner Name'] = domainConfigdata.billingData.partnername;
        beamer_config['Subscription Status'] = domainConfigdata.billingData.subscriptionstatus;
    }
    console.log("beamer_config", beamer_config);
    beamerInitialize(process.env.REACT_APP_GETBEAMER, null, beamer_config);
}

export const formatSessionID = (sessionId) => {
    if (sessionId) {
        let id = sessionId.replace(/[^\d]/g, "");
        if (id.length === 9) {
            //reformat and return num number
            return id.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
        }
        return id;
    }
    else {
        return "";
    }
}

export const secondsToTime = (seconds) => {
    return new Date(seconds * 1000).toISOString().slice(seconds >= 3600 ? 11 : 14, 19).toString();
}

export const createdTimeDiff = (createdDate, localisedValues = Localize[DefaultLanguage]) => {
    const currentDate = moment().utc();
    const diffDays = parseInt(currentDate.diff(createdDate, 'days', true));
    let timeFormat = null;

    if (diffDays > 7) {
        timeFormat = true;
    }
    else if (diffDays >= 2) {
        const lang = PublicVariables.currentLanguage.toLowerCase()
        timeFormat = createdDate.locale(lang).format('dddd');
    }
    else if (diffDays >= 1) {
        timeFormat = localisedValues["yesterday"];
    }
    else {
        const diffHours = parseInt(currentDate.diff(createdDate, 'hours', true));
        if (diffHours > 1) {
            timeFormat = localisedValues["hours_ago"]?.replace("1", diffHours);
        }
        else if (diffHours === 1) {
            timeFormat = localisedValues["hour_ago"]?.replace("1", diffHours);
        }
        else {
            const diffMinutes = parseInt(currentDate.diff(createdDate, 'minutes', true));
            if (diffMinutes > 10) {
                timeFormat = localisedValues["minutes_ago"]?.replace("1", diffMinutes);
            }
            else {
                timeFormat = localisedValues["just_now"];
            }
        }
    }

    return timeFormat;
}

export const scheduleTimeDiff = (scheduleDate) => {

    let scurrentDate = moment().local().format(DATE_TIME_FORMAT_24_HOURS);
    scurrentDate = moment.utc(scurrentDate, DATE_TIME_FORMAT_24_HOURS);
    let createddate = scheduleDate.format(DATE_TIME_FORMAT_24_HOURS);
    createddate = moment.utc(createddate, DATE_TIME_FORMAT_24_HOURS)
    let sdiffhours = createddate.diff(scurrentDate, 'hours', true);
    let sdiffminutes = createddate.diff(scurrentDate, 'minutes', true);
    let stimeFormat = null;
    if (sdiffhours <= 24 && sdiffhours >= 1) {
        stimeFormat = parseInt(sdiffhours) + " hrs"
    } else if (sdiffminutes < 60 && sdiffminutes > 0) {
        stimeFormat = parseInt(sdiffminutes) + " min"
    } else {
        stimeFormat = false;
    }
    return stimeFormat;
}

export const isUploadOnlySession = (video, shareScreen, uploadFile) => {
    return (video === '0' && shareScreen === '0' && uploadFile === '1');
}

// Function to parse URL parameters
export const parseURLParams = function (url) {
    let params = {};

    if (!isEmpty(url)) {
        const queryParams = url.split('?')[1];
        const paramPairs = queryParams.split('&');

        for (let i = 0; i < paramPairs.length; i++) {
            const param = paramPairs[i].split('=');
            const paramName = decodeURIComponent(param[0]);
            const paramValue = decodeURIComponent(param[1] || '');

            params[paramName] = paramValue;
        }
    }
    return params;
}

// Function to filter URL parameters
export const filterParams = (urlParamsString) => {
    if(isEmpty(urlParamsString)) return {}
    const params = urlParamsString.split('&');
    const excludedParamKeys = [
        'callexperience=', 'ref=', 'sms=', 'whatsapp=',
        'email=', 'closetab=', 'platform=', 'redirect_url=',
        'page='
    ];

    return params.filter(param => excludedParamKeys.every(key => !param.includes(key)));
}

export const afterLoginProcess = (data) => {
    if ((data.userToken) && (storage.get(LOCAL_STORAGE_KEYS.USER_DATA) == null)) {
        storage.set(LOCAL_STORAGE_KEYS.USER_DATA, data);
        storage.set(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA, data);
        storage.set(LOCAL_STORAGE_KEYS.TOKEN_EXPIRY_TIME, data.tokenGeneratedAt + Token_time);
        storage.set(LOCAL_STORAGE_KEYS.USER_TOKEN, data.userToken);
        storage.set(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
        // storage.set(LOCAL_STORAGE_KEYS.ALLOW_ZENDESK, data.COMPANYDETAIL.ALLOWZENDESK); // configuration API
        storage.set(LOCAL_STORAGE_KEYS.UCID, data.userId);
        storage.set(LOCAL_STORAGE_KEYS.AFTER_LOGIN, 1); // used for billing
        storage.set(LOCAL_STORAGE_KEYS.SELECTED_USER_ID, [{ id: data.userId, name: data.firstName || '' }]); // store current user UCID for fetching users list for first time of login
        localStorage.setItem(LOGGED_IN, "1")
        storage.set(VERSION_KEY, VERSION_VALUE);
        // PublicVariables.agentLocation = (data.COMPANYDETAIL.AGENTLOCATION && data.COMPANYDETAIL.AGENTLOCATION === 1) ? 1 : 0;
        // PublicVariables.allowzendesk = data.COMPANYDETAIL.ALLOWZENDESK;
        // PublicVariables.zendeskurl = data.COMPANYDETAIL.ZENDESKURL;

        storeLoginResultForAdminPortal(); // change in localstorage
        const configData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
        if (configData) {
            const domainConfigData = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
            if (domainConfigData?.configurations?.journeyeditor === 1) {
                DesktopNotification.NotificationPermission();
                SignalR.Connect(configData.userToken)
            } else {
                SignalR.Disconnect()
            }
        } else {
            SignalR.Disconnect()
        }

        let dataForSession = storage.get(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION);
        if (dataForSession) { // genesys functionality
            const extraParams = parseURLParams(dataForSession);
            if (extraParams.page == '1') {
                history.push(RouteName.SEND_CODE);
            } else {
                history.push(RouteName.CREATE_AND_INVITE + dataForSession);
            }
        } else {
            const tempParams = new URLSearchParams(window.location.search)
            if (!tempParams.has('redirect_url')) history.push(RouteName.SESSIONS)
        }
    }
}

export const afterFetchingSessionImageProcess = async (data, sessionWaterMarkImage) => {
    let imageList = get(data, 'data.list', [])

    if (isEmpty(imageList)) {
        return data
    }
    else {

        const getHeaderData = (image, res, sessionWaterMarkImage) => {
            try {
                const header = convertKeysToLowerCase(res.headers);
                let imgExtraData = {}
                imgExtraData.uploadType = header["x-amz-meta-uploadtype"] || header["x-ms-meta-uploadtype"] || '0'

                if(imgExtraData.uploadType !== "0" && !imgExtraData.uploadType){
                    imgExtraData.uploadType = "0";
                }

                if (sessionWaterMarkImage) {
                    imgExtraData.latVal = header["x-amz-meta-latitude"] || header["x-ms-meta-latitude"] || false
                    imgExtraData.longVal = header["x-amz-meta-longitude"] || header["x-ms-meta-longitude"] || false

                    if (!imgExtraData.latVal) delete imgExtraData.latVal
                    if (!imgExtraData.longVal) delete imgExtraData.longVal
                }

                return { ...image, ...imgExtraData }
            }
            catch (e) {
                console.log(e);
                image.uploadType = 0;
                return image
            }
        }

        let promiseList = imageList.map(async (p, i) => {
            const res = await axios(p.fileUrl)
            return getHeaderData(imageList[i], res, sessionWaterMarkImage)
        })

        promiseList = await Promise.all(promiseList)

        let updateData = { ...data.data }
        updateData.list = reverse(promiseList)
        return { ...data, data: updateData }
    }
}

const getLandingPage = function (pagename) {
    let landingPage;
    if (pagename == 1 || pagename == "INQUIRIES") {
        landingPage = "inquiries";
    } else if (pagename == 2 || pagename == "CONTACTS") {
        landingPage = "contacts";
    } else if (pagename == 3 || pagename == "SHOWME") {
        landingPage = "support";
    } else {
        //do nothing 
    }
    return landingPage;
}

export const storeLoginResultForAdminPortal = () => {
    const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
    const configurationdata = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
    const isTrial = storage.get(LOCAL_STORAGE_KEYS.IS_TRIAL);
    const companyDetail = {
        "CID": userData?.domainId,
        "CNAME": userData?.domainName,
        "LOGOIMAGE": configurationdata?.domainLogo,
        "ROLECODE": userData?.roleCode,
        "UCID": userData?.userId,
        "JOBROLE": userData?.jobRole,
        "ISTRAIL": isTrial,
        "DOMAINVERSION": configurationdata?.configurations?.domainversion,
        "ALLOWMEETINGROOM": configurationdata?.configurations?.allowmeetingroom,
        "DOMAINRELEASEVERSION": configurationdata?.configurations?.domainreleaseversion,
        "INVITEBUDDY": configurationdata?.configurations?.invitebuddy,
        "AGENTLOCATION": configurationdata?.configurations?.agentlocation,
        "DOMAINURL": PublicVariables?.domainDataForLD?.domainUrl || configurationdata?.domainUrl,
        "LINKEDURL": PublicVariables?.domainDataForLD?.customHostMappingUrl || configurationdata?.customHostMappingUrl,
        "ALLOWZENDESK": configurationdata?.configurations?.allowzendesk,
        "SUBSCRIPTIONTYPE": configurationdata?.billingData?.subscriptiontype,
        "TOOLNAME": PublicVariables?.domainDataForLD?.toolName,
        "SUBSCRIPTIONSTATUS": configurationdata?.billingData?.subscriptionstatus,
        "PARTNERNAME": configurationdata?.billingData?.partnername,
        "DOMAINSTATUS": "ACTIVE",
        "LDTRIALSTATUS": false,
        "STORAGETYPE": configurationdata?.storageType,
        "ZENDESKURL": "",
        "DOMAINEMAIL": configurationdata?.configurations?.domainemail,
        "NEWLANDINGPAGE": configurationdata?.configurations?.landingpage,
        "EDITION": configurationdata?.configurations?.edition,
        "EXPIREDATE": PublicVariables.domainDataForLD?.expireAt,
        "EXPIREDAY": PublicVariables.expireDay,
        "LANDINGPAGE": getLandingPage(configurationdata?.configurations?.landingpage),
        "COMPANY": null,
        "DISPLAYNAME": userData?.roleDisplayName,
        "ENABLECHANGEPASSWORD": configurationdata?.configurations?.changepassword,
        // default value for below keys
        "CHATDIALOGIMAGE": null,
        "WEBIMAGE": null,
        "IOSIMAGE": null,
        "ANDROIDIMAGE": null,
        "ALLOWJOURNEY": "0",
        "ISBILLINGON": false,
        "ISREPORTON": false,
        "BETA": true,
    }
    const configData = {
        "FNAME": userData?.firstName,
        "LNAME": userData?.lastName,
        "PROFILEPIC": userData?.profileImage,
        "PCCODE": userData?.countryCode,
        "PNO": userData?.phoneNo,
        "UID": storage.get(LOCAL_STORAGE_KEYS.UCID),
        "JOBROLE": userData?.jobRole,
        "CID": userData?.domainId,
        "COMPANY": "",
        "TYPE": userData?.userType,
        "NOTIFYEMAIL": userData?.notificationEmail,
        "LOGINID": userData?.loginId,
        "UIDENTITY": "",
        "TOKEN": null,
        "SASTOKEN": null,
        "TOKENTIME": userData?.tokenGeneratedAt,
        "USERTOKEN": userData?.userToken,
        "REFRESHTOKEN": userData?.refreshToken,
        "TOTALACTIVEUSERS": null,
        "USERCREATIONDATE": null,
        "OPTVERIFICATIONREQUIRED": userData?.otpVerificationRequired,
        "VERIFICATIONID": userData?.verificationId,
        "COMPANYDETAIL": companyDetail,
        "companylist": [
        ]
    }
    storage.set(LOCAL_STORAGE_KEYS.CURRENT_COMPANY, companyDetail); // get currentcompany from configdata
    storage.set(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA, configData)
}

export const storeCompanylistForAdmin = (companylist) => {
    const configData = storage.get(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA);
    let domainList = [];
    companylist.forEach((value, key) => {
        domainList.push({
            "CID": value.domainId,
            "CNAME": value.domainName,
            "LOGOIMAGE": value.domainLogo,
            "ROLECODE": value.roleCode,
            "UCID": value.userUcid,
            "CHATDIALOGIMAGE": value.chatDialogImage,
            "WEBIMAGE": value.webImage,
            "IOSIMAGE": value.iosImage,
            "ANDROIDIMAGE": value.androidImage,
            "JOBROLE": value.jobRole,
            "COMPANY": value.company,
            "DISPLAYNAME": value.roleDisplayName,
            "ENABLECHANGEPASSWORD": value.enablePasswordChange,
            "ISTRAIL": value.isTrial,
            "EXPIREDATE": value.expireAt,
            "EXPIREDAY": value.expireDays,
            "COMPANYALLOWCUSTCALL": value.companyAllowCustomerCall,
            "USERALLOWCUSTCALL": value.userAllowCustomerCall,
            "ENABLERECORD": value.enableRecord,
            "ISEXPIRE": value.isExpire,
            "ERRORMESSAGE": value.errorMessage,
            "ERRORTITLE": value.errorTitle,
            "ALLOWMEETINGROOM": value.allowMeetingRoom,
            "ALLOWNOTES": value.allowNotes,
            "ALLOWBETA": value.allowBeta,
            "TWILIOTOKEN": value.twilioToken,
            "TOKENTIME": value.tokenTime,
            "SASTOKEN": value.sasToken,
            "USERTOKEN": value.userToken,
            "UCGUID": value.ucGuid,
            "LANDINGPAGE": value.landingPage,
            "DOMAINVERSION": value.domainVersion,
            "ALLOWJOURNEY": value.allowJourney,
            "NEWLANDINGPAGE": value.newLandingPage,
            "EDITION": value.edition,
            "DOMAINRELEASEVERSION": value.domainReleaseVersion,
            "INVITEBUDDY": value.inviteBuddy,
            "DOMAINEMAIL": value.domainEmail,
            "AGENTLOCATION": value.agentLocation,
            "DOMAINURL": value.domainUrl,
            "LINKEDURL": value.customHostMappingUrl,
            "BETA": value.beta,
            "STORAGETYPE": value.storageType,
            "ZENDESKURL": value.zendeskUrl,
            "ALLOWZENDESK": value.allowZendesk
        })
    });
    configData.companylist = domainList;
    storage.set(LOCAL_STORAGE_KEYS_ADMIN.COMPANY_LIST, domainList); // get companylist from configdata
    storage.set(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA, configData)

    const tempParams = new URLSearchParams(window.location.search)
    if (tempParams.has('redirect_url')) window.location.href = window.location.origin + tempParams.get('redirect_url')
}
// for admin setting domain logo
export const updateDomainLogoInStorage = (logoImage) => {
    if(storage.has(LOCAL_STORAGE_KEYS.CURRENT_COMPANY)){
        let currentCompanyData = storage.get(LOCAL_STORAGE_KEYS.CURRENT_COMPANY);
        currentCompanyData.LOGOIMAGE = logoImage;
        storage.set(LOCAL_STORAGE_KEYS.CURRENT_COMPANY, currentCompanyData);
    }
}

/**isEmpty fun will return true if the variable is an empty string, false, array(), NULL, 0,*/
export const isEmpty = function (value) {
    let retVal;
    if (value == undefined || value == null || value == "undefined" || value == "null") {
        retVal = true;
    } else {
        let varType = typeof (value);
        if (varType == "object" && value == undefined) { //for object = {}
            retVal = true;
        } else if (varType == "object" && value.length == 0) { //for arr = []
            retVal = true;
        } else if (varType == "string" && value == "") { // empty string
            retVal = true;
        } else if (varType == "boolean" && value == false) {
            retVal = true;
        } else if (varType == "number" && value == 0) {
            retVal = true;
        } else {
            retVal = false;
        }
    }
    console.log("::empty(value))=" + retVal);
    return retVal;
}

export const createLDUserObj = (obj) => {
    console.log(obj);
    return {
        "kind": 'user',
        "key": obj?.domainId ? obj.domainId.toString() : null,
        "name": obj.domainName,
        "email": obj.domainEmail,
        "custom": {
            "ReleaseVersion": obj.releaseVersion,
            "Edition": obj.edition,
            "SubscriptionType": obj.subscriptiontype,
            "DomainStatus": "ACTIVE", // this will be static as we are calling LD after successful domain check
            "IsTrial": Boolean(Number(obj.IsTrial)),
            "SubscriptionStatus": obj.subscriptionstatus,
            "PartnerName": obj.partnername
        }
    };
}

export const setLDFlags = (ldClient, ldUser) => {
    if (ldClient) {
        ldClient.identify(ldUser);
    }
}

export const getCurrentUser = () => {
    const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
    const configData = storage.get(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA) || {};

    const user = {
        userId: storage.get(LOCAL_STORAGE_KEYS.UCID),
        roleCode: '',
        firstName: configData.FNAME || userData.firstName || '',
        lastName: configData.LNAME || userData.lastName || '',
        profileImage: configData.PROFILEPIC || userData.profileImage,
    }
    user.fullName = user.firstName + " " + user.lastName;
    user.SELECTED = true;

    return user;
}

export const checkConfigAPIcall = (modifiedDate, storedDate) => {
    if (modifiedDate && storedDate) {
        const moment = require('moment');
        const newDate = moment.utc(modifiedDate);
        const pastDate = moment.utc(storedDate);
        return newDate.isAfter(pastDate);
    } else {
        return false;
    }
}

/*Get current screen for invite contacts*/
export const getCurrentScreen = (columnsToShowRef, setColumnsToShow, widthCalculationArray) => {
    const width = Math.min(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
    const currentColumnsToShow = widthCalculationArray.find(item => width < item.w)?.showColumns || INVITECONTACT_WIDTH_CALCULATION_ARRAY[3].showColumns;

    if (columnsToShowRef?.current !== currentColumnsToShow) {
        setColumnsToShow(currentColumnsToShow);
    }
}

/* replace special chars from CP */
export const replaceSpecialChar = (name) => {
    return name ? name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&') : 'N/A';
}

export const toMinSec = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let formattedTime = '';
    if (hours > 0) {
        formattedTime += hours.toString().padStart(2, '0') + ':';
    }
    formattedTime += remainingMinutes.toString().padStart(2, '0') + ':' +
        remainingSeconds.toString().padStart(2, '0');
    return formattedTime;
}

export const convertUtcToLocalInStr = (value) => {
    return moment.utc(value, 'DD-MM-YYYYTHH:mm:ss').local().format('MMM DD, YYYY hh:mm A');
}

export const formatDate = (value, currentFormat, targetFormat) => {
    const lang = PublicVariables.currentLanguage.toLowerCase()
    return moment.utc(value, currentFormat).locale(lang).local().format(targetFormat);
}
/* date will return in moment obj*/
export const convertUtcToLocal = (value) => {
    return moment.utc(value).local();
}

export const fetchInvitationURL = () => {
    let url = "";
    // journey URL
    if (getDomainURL() === null || getDomainURL() === "") {
        const domainConfigData = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
        url = domainConfigData?.customHostMappingUrl ? domainConfigData?.customHostMappingUrl : domainConfigData?.domainUrl;
    } else {
        url = PublicVariables.domainDataForLD?.customHostMappingUrl ? PublicVariables.domainDataForLD?.customHostMappingUrl : PublicVariables.domainDataForLD?.domainUrl;
    }
    return url;
}

const copyLinkToClipboard = (link, tempInput, msg) => {
    navigator.clipboard.writeText(link)
        .then(() => {
            if (link !== undefined) {
                CustomToast.success(msg); // We have also added this key with localize 
            } else {
                handleErrorMsgToastVisiblity('Error copying text to clipboard:');
            }
        })
        .catch(error => {
            handleErrorMsgToastVisiblity('Error copying text to clipboard:');
        });
    document.body.removeChild(tempInput);
}

export const copyValue = (value, msg) => {
    let range, selection;

    const tempInput = document.createElement('textarea');
    tempInput.value = value;
    document.body.appendChild(tempInput);
    /* If we are copy from ipad and iphone */
    if (navigator.userAgent.match(/ipad|iphone/i)) {
        range = document.createRange();
        range.selectNodeContents(tempInput);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        tempInput.setSelectionRange(0, 999999);
        copyLinkToClipboard(value, tempInput, msg);
    } else {
        tempInput.select();
        copyLinkToClipboard(value, tempInput, msg);
    }
}

export const findTimeDiffInHours = (date) => {
    const currentUtcDate = moment.utc();
    const dueDate = moment.utc(date);
    return currentUtcDate.diff(dueDate, 'hours');
};

const timeUnits = [
    { unit: 'year', seconds: 31536000 }, // 31536000 seconds in a year
    { unit: 'month', seconds: 2592000 }, // 2592000 seconds in a month
    { unit: 'day', seconds: 86400 },     // 86400 seconds in a day
    { unit: 'hour', seconds: 3600 },     // 3600 seconds in an hour
    { unit: 'minute', seconds: 60 },     // 60 seconds in a minute
    { unit: 'second', seconds: 1 }       // 1 second
];

export const timeSince = (date, localisedValues) => {
    if (typeof date !== 'object') {
        date = new Date(date);
    }
    let seconds = Math.floor((new Date() - date) / 1000);
    let intervalType;
    let interval = Math.floor(seconds / 31536000);
    for (const unit of timeUnits) {
        if (seconds >= unit.seconds) {
            interval = Math.floor(seconds / unit.seconds);
            intervalType = unit.unit;
            if (interval > 1 || interval === 0) {
                intervalType = intervalType + 's';
            }
            if (intervalType == "second" || intervalType == "seconds") {
                return localisedValues["few_seconds_ago"];
            } else {
                const intervalText = localisedValues[`${intervalType}_ago`]
                return intervalText?.replace("1", interval)
            }
        }
    }

};

export const generateZip = (dataList, zipType, sessionId) => {

    const getZipFolderName = () => {
        if (dataList[0].folderName && dataList[0].folderName != '') {
            return sessionId + dataList[0].folderName;
        }
        else {
            const userdata = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
            const configData = storage.get(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA) || {};

            const domainName = userdata.domainName || "domain";
            let agentName = "agent";

            if (!isEmpty(userdata.firstName)) {
                agentName = configData.FNAME || userdata.firstName;
            }
            
            if (!isEmpty(userdata.lastName)) {
                const lastName = configData.LNAME || userdata.lastName
                agentName = agentName + "_" + lastName
            }
            const folderName = domainName + "_" + agentName + "_" + sessionId;
            //replace space by underscore
            return folderName.replace(/\s+/g, '_');
        }
    }


    const zip = new JSZip();
    const zipFolderName = getZipFolderName()
    let count = 0

    return new Promise((resolve, reject) => {
        dataList.forEach((d) => {
            JSZipUtils.getBinaryContent(d.fileUrl, function (err, data) {
                if (err) {
                    console.error(err);
                    reject(err);
                } else {
                    const fileName = decodeURI(d.fileName);
                    zip.file(fileName, data, { binary: true });
                    count++

                    if (count === dataList.length) {
                        // function from jszip api to generate zip file
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            console.log("content ==?", content);
                            saveAs(content, zipFolderName + zipType);
                            resolve("success")
                        }).catch((e) => {
                            console.error(e);
                            reject(e);
                        });
                    }

                }
            })
        });
    })
}
export const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
];

export const removeFromArray = (arr, index) => {
    let newFormValues = [...arr];
    newFormValues.splice(index, 1);
    return newFormValues;
};

export const formatBytes = (bytes) => {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (bytes === 0) return '0 B';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

export const checkRegexPattern = (pattern, value) => {
    const regex = new RegExp(pattern);
    const isValid = regex.test(value);
    return isValid
}

export const openUrlInBrowser = (url, openType) => {
    window.open(url, openType);
}

const fetchOsInfo = () => {
    return {
        OSName: DetectRTC.osName.toLowerCase(),
        osVersion: parseFloat(DetectRTC.osVersion)
    };
}
const fetchBrowserInfo = () => {
    return {
        browserName: DetectRTC.browser.name.toLowerCase(),
        browserVersion: parseFloat(DetectRTC.browser.version)
    }
}

//check browser is lower version
export const isValidDevice = () => {
    const osinfo = fetchOsInfo();
    if (osinfo.OSName === 'ios' && osinfo.osVersion < supportedIOSInfo.device) {
        return false;
    } else {
        return true;
    }
}

//supported only safari for iOS
export const isSafariBrowser = () => {
    const osinfo = fetchOsInfo();
    const browserInfo = fetchBrowserInfo();
    if (osinfo.OSName === "ios" && (browserInfo.browserName).toLowerCase() === 'safari' && browserInfo.browserVersion >= supportedIOSInfo.safari) {
        return true
    } else if (osinfo.OSName === "ios" && (browserInfo.browserName).toLowerCase() === 'chrome' && osinfo.osVersion >= supportedIOSInfo.deviceForChrome) {
        return true;
    } else if (osinfo.OSName === "mac os x" && (browserInfo.browserName).toLowerCase() === 'safari' && browserInfo.browserVersion >= supportedIOSInfo.safari) {
        return true;
    } else {
        return false;
    }
}

//supported only safari for iOS
export const isChromeBrowser = () => {
    const osinfo = fetchOsInfo();
    const browserInfo = fetchBrowserInfo();

    if (osinfo.OSName === 'android' && browserInfo.browserName === 'chrome') {
        const strUseragent = navigator.userAgent.toLowerCase();
        const unsupportedList = ['edga', 'edge', 'firefox', 'opera'];
        return !unsupportedList.some(value => strUseragent.includes(value));
    }

    return false;
}

//supported android browser with version
export const isValidAndroidBrowser = () => {
    const osinfo = fetchOsInfo();
    const browserInfo = fetchBrowserInfo();
    if (osinfo.OSName == "android" && browserInfo.browserName == 'chrome' && browserInfo.browserVersion < 60) {
        return false
    } else {
        return true;
    }
}

//desktop supported
export const desktopSupportedBrowser = () => {
    const osinfo = fetchOsInfo();
    const browserInfo = fetchBrowserInfo();

    const isChrome = browserInfo.browserName === "chrome" && browserInfo.browserVersion >= 60;
    const isFirefox = browserInfo.browserName === "firefox" && browserInfo.browserVersion > 59;
    const isSafari = osinfo.OSName.includes("mac") && browserInfo.browserName === "safari" && browserInfo.browserVersion > supportedIOSInfo.safari;

    return isChrome || isFirefox || isSafari;
}

export const setPageTitle = (pageTitle) => {
    const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA)
    let titleExtension = '';
    if (userData) {
        titleExtension = PAGE_TITLE_EXTENSION;
        if (PublicVariables.ldFlags && !PublicVariables.ldFlags.helpView) {
            titleExtension = ' | ' + userData.domainName;
        }
    }
    document.title = pageTitle + titleExtension;
}


export const handleErrorMsgToastVisiblity = (error) => {
    if (!PublicVariables.isAlreadyToastShown){
        PublicVariables.isAlreadyToastShown = true
        CustomToast.error(error.message || error)

        setTimeout(() => {
            PublicVariables.isAlreadyToastShown = false
        }, 1000);
    }
}

export const resetDataForSession = () => {
    storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION);
    history.push(RouteName.SESSIONS);
}