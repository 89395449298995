export const Localize = {
    "en_GB": {
        "self_signup_title": "Start your free 14-day trial of Blitzz today!",
        "self_signup_heading1": "Just enter your work email below. No sales call. No credit card. No download. Jump in and test drive Blitzz for yourself.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Enter valid email address.",
        "use_business_email_error": "Please use your business email address.",
        "error_limit_exceeded": "Limit exceeded!",
        "continue": "Continue",
        "already_have_account": "Already have an account?",
        "sign_in": "Sign In",
        "dismiss": "Dismiss",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "step_1_of_2": "Step 1 of 2",
        "user_info": "User Info",
        "complete_your_account_details_below": "Complete your account details below",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "user_last_name": "Last name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "user_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "new_terms_and_conditions": "Terms & Conditions",
        "crt_acnt_term_condition_val_msg": "Please accept Terms & Conditions.",
        "crt_acnt_accept": "Accept",
        "step_2_of_3": "Step 2 of 3",
        "company_info": "Company Info",
        "company_name": "Company name",
        "company_name_error": "Please enter Company name.",
        "number_of_employees": "Number of employees",
        "invalid_number_of_employees": "Invalid Number of employees",
        "no_of_inspections": "No. of inspections/month",
        "invalid_no_of_inspections": "Invalid No. of inspections/month",
        "blitzz_subdomain_url": "Your Blitzz subdomain URL",
        "subdomain": "Subdomain",
        "subdomain_error": "Please enter Subdomain.",
        "domain_length_msg": "Minimum 3 characters required.",
        "not_a_valid_subdomain": "This is not a valid subdomain.",
        "create_my_account_text1": "By clicking “Create My Account” you agree to the Blitzz",
        "subdomain_not_available": "This subdomain is not available.",
        "create_my_account": "Create My Account",
        "step_3_of_3": "Step 3 of 3",
        "send_invitations_title": "Send Invitation",
        "send_invitations_heading": "Your Blitzz company is ready to go. Know a few friends or co-workers who'd like to explore Blitzz with you?",
        "new_email_address": "Email address",
        "email_id": "Email ID",
        "duplicate_email_address": "Duplicate email address.",
        "add_members_msg": "You can add more members from the Admin portal after you sign in.",
        "add_another_user": "Add another user",
        "skip_for_now": "Skip for now",
        "select_state_label": "Select state",
        "no_search_result_found_label": "No search result found",
        "select_country_label": "Select country",
        "your_account_has_been_created_successfully": "Your account has been created successfully",
        "company_ceated_popup_description": "Your account is being configured by our team to ensure it is setup correctly for your use case. We will get in touch with you shortly.",
        "company_created_popup_description_1": "This usually takes 1 business day to complete.",
        "back_to_login": "Back to Login",
        "contact_us": "Contact Us",
        "one_business_day": "1 business day",
        "step_2_of_2": "Step 2 of 2",
    },
    "de_DE": {
        "self_signup_title": "Starten Sie noch heute Ihre kostenlose 14-tägige Testversion von Blitzz!",
        "self_signup_heading1": "Geben Sie einfach unten Ihre geschäftliche E-Mail-Adresse ein. Kein Verkaufsgespräch. Keine Kreditkarte. Kein Download. Steigen Sie ein und testen Sie Blitzz selbst.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Geben Sie eine gültige E-Mail-Adresse ein.",
        "use_business_email_error": "Bitte verwenden Sie Ihre geschäftliche E-Mail-Adresse.",
        "error_limit_exceeded": "Limit überschritten!",
        "continue": "Fortsetzen",
        "already_have_account": "Sie haben bereits ein Konto?",
        "sign_in": "Einloggen",
        "dismiss": "Entlassen",
        "check_ccode_text": "Überprüfen Sie Ihre E-Mails auf einen Code",
        "resend_code": "Code erneut senden",
        "ccod_genral_text": "Wir haben einen 6-stelligen Bestätigungscode an <strong> <trial_email> . </strong> Der Code läuft in Kürze ab. Bitte geben Sie ihn bald ein.",
        "your_ccode_text": "Ihr <strong>Bestätigungscode </strong>",
        "ccode_note1_text": "Lassen Sie dieses Fenster geöffnet, während Sie nach Ihrem Code suchen. <br> haben unsere E-Mail noch nicht erhalten? Probieren Sie Ihren Spam-Ordner oder",
        "validating_code": "Code validieren ...",
        "ccod_resend_text": "Bestätigungscode wird gesendet, bitte überprüfen Sie Ihre E-Mail",
        "step_1_of_2": "Schritt 1 von 2",
        "user_info": "Benutzerinformation",
        "complete_your_account_details_below": "Vervollständigen Sie Ihre Kontodaten unten",
        "create_account_new_ui_desc": "Bitte geben Sie Ihren Namen an und erstellen Sie ein Passwort, um fortzufahren",
        "user_first_name": "Vorname",
        "crt_acnt_valid_first_name": "Bitte geben Sie Vorname.",
        "crt_acnt_limit_character_msg": "Bis zu 30 Zeichen erlaubt.",
        "user_last_name": "Nachname",
        "crt_acnt_enter_last_name": "Bitte geben Sie den Nachnamen ein.",
        "user_password": "Passwort",
        "password_validation_msg": "Bitte geben Sie ein gültiges Passwort ein.",
        "new_terms_and_conditions": "Terms & amp; Bedingungen",
        "crt_acnt_term_condition_val_msg": "Bitte akzeptieren Allgemeine Geschäftsbedingungen.",
        "crt_acnt_accept": "Akzeptieren",
        "step_2_of_3": "Schritt 2 von 3",
        "company_info": "Firmeninfo",
        "company_name": "Name der Firma",
        "company_name_error": "Bitte geben Sie den Firmennamen ein.",
        "number_of_employees": "Anzahl der Angestellten",
        "invalid_number_of_employees": "Ungültige Anzahl der Mitarbeiter",
        "no_of_inspections": "Anzahl der Inspektionen / Monat",
        "invalid_no_of_inspections": "Ungültige Anzahl Inspektionen / Monat",
        "blitzz_subdomain_url": "Ihre Blitzz-Subdomain-URL",
        "subdomain": "Subdomain",
        "subdomain_error": "Bitte geben Sie die Subdomain ein.",
        "domain_length_msg": "Mindestens 3 Zeichen erforderlich.",
        "not_a_valid_subdomain": "Dies ist keine gültige Subdomain.",
        "create_my_account_text1": "Wenn Sie auf \"Mein Konto erstellen\" klicken, stimmen Sie dem Blitzz zu",
        "subdomain_not_available": "Diese Subdomain ist nicht verfügbar.",
        "create_my_account": "Erstelle meinen Account",
        "step_3_of_3": "Schritt 3 von 3",
        "send_invitations_title": "Einladung senden",
        "send_invitations_heading": "Ihre Firma Blitzz ist bereit zu gehen. Kennen Sie ein paar Freunde oder Kollegen, die Blitzz mit Ihnen erkunden möchten?",
        "new_email_address": "E-Mail-Addresse",
        "email_id": "E-Mail-ID",
        "duplicate_email_address": "Doppelte E-Mail-Adresse.",
        "add_members_msg": "Sie können nach der Anmeldung weitere Mitglieder aus dem Admin-Portal hinzufügen.",
        "add_another_user": "Fügen Sie einen weiteren Benutzer hinzu",
        "skip_for_now": "Für jetzt überspringen",
        "select_state_label": "Staat wählen",
        "no_search_result_found_label": "Kein Suchergebnis gefunden",
        "select_country_label": "Land auswählen",
        "your_account_has_been_created_successfully": "Ihr Konto wurde erfolgreich erstellt",
        "company_ceated_popup_description": "Ihr Konto wird von unserem Team konfiguriert, um sicherzustellen, dass es für Ihren Anwendungsfall korrekt eingerichtet ist. Wir werden uns in Kürze mit Ihnen in Verbindung setzen.",
        "company_created_popup_description_1": "Dies dauert in der Regel 1 Werktag.",
        "back_to_login": "Zurück zur Anmeldung",
        "contact_us": "Kontaktiere uns",
        "one_business_day": "1 Werktag",
        "step_2_of_2": "Schritt 2 von 2",
    },
    "en_US": {
        "self_signup_title": "Start your free 14-day trial of Blitzz today!",
        "self_signup_heading1": "Just enter your work email below. No sales call. No credit card. No download. Jump in and test drive Blitzz for yourself.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Enter valid email address.",
        "use_business_email_error": "Please use your business email address.",
        "error_limit_exceeded": "Limit exceeded!",
        "continue": "Continue",
        "already_have_account": "Already have an account?",
        "sign_in": "Sign In",
        "dismiss": "Dismiss",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "step_1_of_2": "Step 1 of 2",
        "user_info": "User Info",
        "complete_your_account_details_below": "Complete your account details below",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "user_last_name": "Last name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "user_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "new_terms_and_conditions": "Terms & Conditions",
        "crt_acnt_term_condition_val_msg": "Please accept Terms & Conditions.",
        "crt_acnt_accept": "Accept",
        "step_2_of_3": "Step 2 of 3",
        "company_info": "Company Info",
        "company_name": "Company name",
        "company_name_error": "Please enter Company name.",
        "number_of_employees": "Number of employees",
        "invalid_number_of_employees": "Invalid Number of employees",
        "no_of_inspections": "No. of inspections/month",
        "invalid_no_of_inspections": "Invalid No. of inspections/month",
        "blitzz_subdomain_url": "Your Blitzz subdomain URL",
        "subdomain": "Subdomain",
        "subdomain_error": "Please enter Subdomain.",
        "domain_length_msg": "Minimum 3 characters required.",
        "not_a_valid_subdomain": "This is not a valid subdomain.",
        "create_my_account_text1": "By clicking “Create My Account” you agree to the Blitzz",
        "subdomain_not_available": "This subdomain is not available.",
        "create_my_account": "Create My Account",
        "step_3_of_3": "Step 3 of 3",
        "send_invitations_title": "Send Invitation",
        "send_invitations_heading": "Your Blitzz company is ready to go. Know a few friends or co-workers who'd like to explore Blitzz with you?",
        "new_email_address": "Email address",
        "email_id": "Email ID",
        "duplicate_email_address": "Duplicate email address.",
        "add_members_msg": "You can add more members from the Admin portal after you sign in.",
        "add_another_user": "Add another user",
        "skip_for_now": "Skip for now",
        "select_state_label": "Select state",
        "no_search_result_found_label": "No search result found",
        "select_country_label": "Select country",
        "your_account_has_been_created_successfully": "Your account has been created successfully",
        "company_ceated_popup_description": "Your account is being configured by our team to ensure it is setup correctly for your use case. We will get in touch with you shortly.",
        "company_created_popup_description_1": "This usually takes 1 business day to complete.",
        "back_to_login": "Back to Login",
        "contact_us": "Contact Us",
        "one_business_day": "1 business day",
        "step_2_of_2": "Step 2 of 2",
    },
    "en_AU": {
        "self_signup_title": "Start your free 14-day trial of Blitzz today!",
        "self_signup_heading1": "Just enter your work email below. No sales call. No credit card. No download. Jump in and test drive Blitzz for yourself.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Enter valid email address.",
        "use_business_email_error": "Please use your business email address.",
        "error_limit_exceeded": "Limit exceeded!",
        "continue": "Continue",
        "already_have_account": "Already have an account?",
        "sign_in": "Sign In",
        "dismiss": "Dismiss",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "step_1_of_2": "Step 1 of 2",
        "user_info": "User Info",
        "complete_your_account_details_below": "Complete your account details below",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "user_last_name": "Last name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "user_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "new_terms_and_conditions": "Terms & Conditions",
        "crt_acnt_term_condition_val_msg": "Please accept Terms & Conditions.",
        "crt_acnt_accept": "Accept",
        "step_2_of_3": "Step 2 of 3",
        "company_info": "Company Info",
        "company_name": "Company name",
        "company_name_error": "Please enter Company name.",
        "number_of_employees": "Number of employees",
        "invalid_number_of_employees": "Invalid Number of employees",
        "no_of_inspections": "No. of inspections/month",
        "invalid_no_of_inspections": "Invalid No. of inspections/month",
        "blitzz_subdomain_url": "Your Blitzz subdomain URL",
        "subdomain": "Subdomain",
        "subdomain_error": "Please enter Subdomain.",
        "domain_length_msg": "Minimum 3 characters required.",
        "not_a_valid_subdomain": "This is not a valid subdomain.",
        "create_my_account_text1": "By clicking “Create My Account” you agree to the Blitzz",
        "subdomain_not_available": "This subdomain is not available.",
        "create_my_account": "Create My Account",
        "step_3_of_3": "Step 3 of 3",
        "send_invitations_title": "Send Invitation",
        "send_invitations_heading": "Your Blitzz company is ready to go. Know a few friends or co-workers who'd like to explore Blitzz with you?",
        "new_email_address": "Email address",
        "email_id": "Email ID",
        "duplicate_email_address": "Duplicate email address.",
        "add_members_msg": "You can add more members from the Admin portal after you sign in.",
        "add_another_user": "Add another user",
        "skip_for_now": "Skip for now",
        "select_state_label": "Select state",
        "no_search_result_found_label": "No search result found",
        "select_country_label": "Select country",
        "your_account_has_been_created_successfully": "Your account has been created successfully",
        "company_ceated_popup_description": "Your account is being configured by our team to ensure it is setup correctly for your use case. We will get in touch with you shortly.",
        "company_created_popup_description_1": "This usually takes 1 business day to complete.",
        "back_to_login": "Back to Login",
        "contact_us": "Contact Us",
        "one_business_day": "1 business day",
        "step_2_of_2": "Step 2 of 2",
    },
    "es_ES": {
        "self_signup_title": "¡Empiece hoy mismo su prueba gratuita de 14 días de Blitzz!",
        "self_signup_heading1": "Simplemente ingrese su correo electrónico de trabajo a continuación. Sin llamada de ventas. Sin tarjeta de crédito. Sin descarga. Súbete y prueba Blitzz por ti mismo.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "use_business_email_error": "Utilice su dirección de correo electrónico comercial.",
        "error_limit_exceeded": "¡Límite excedido!",
        "continue": "Continuar",
        "already_have_account": "¿Ya tienes una cuenta?",
        "sign_in": "Registrarse",
        "dismiss": "Despedir",
        "check_ccode_text": "Verifique su correo electrónico para obtener un código",
        "resend_code": "Reenviar codigo",
        "ccod_genral_text": "Enviamos un código de confirmación de 6 dígitos a <strong> <trial_email> . </strong> El código caduca en breve, ingréselo pronto.",
        "your_ccode_text": "Tu <strong> código de confirmación </strong>",
        "ccode_note1_text": "Mantenga esta ventana abierta mientras busca su código. <br> ¿No ha recibido nuestro correo electrónico? Prueba tu carpeta de spam o",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "Se envió el código de confirmación, verifique su correo electrónico",
        "step_1_of_2": "Paso 1 de 2",
        "user_info": "Información de usuario",
        "complete_your_account_details_below": "Complete los detalles de su cuenta a continuación",
        "create_account_new_ui_desc": "Por favor escriba su nombre y cree una contraseña para continuar",
        "user_first_name": "Nombre de pila",
        "crt_acnt_valid_first_name": "Por favor, introduzca Nombre.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "user_last_name": "Apellido",
        "crt_acnt_enter_last_name": "Ingrese el apellido.",
        "user_password": "Contraseña",
        "password_validation_msg": "Por favor ingrese una contraseña válida.",
        "new_terms_and_conditions": "Términos y condiciones",
        "crt_acnt_term_condition_val_msg": "Por favor, acepte términos y condiciones.",
        "crt_acnt_accept": "Aceptar",
        "step_2_of_3": "Paso 2 de 3",
        "company_info": "Información de la compañía",
        "company_name": "Nombre de empresa",
        "company_name_error": "Introduzca el nombre de la empresa.",
        "number_of_employees": "Número de empleados",
        "invalid_number_of_employees": "Número de empleados no válido",
        "no_of_inspections": "No de inspecciones / mes",
        "invalid_no_of_inspections": "No válido de inspecciones / mes",
        "blitzz_subdomain_url": "La URL de su subdominio Blitzz",
        "subdomain": "Subdominio",
        "subdomain_error": "Introduzca el subdominio.",
        "domain_length_msg": "Se requieren 3 caracteres como mínimo.",
        "not_a_valid_subdomain": "Este no es un subdominio válido.",
        "create_my_account_text1": "Al hacer clic en \"Crear mi cuenta\", acepta el Blitzz",
        "subdomain_not_available": "Este subdominio no está disponible.",
        "create_my_account": "Crear mi cuenta",
        "step_3_of_3": "Paso 3 de 3",
        "send_invitations_title": "Enviar invitacion",
        "send_invitations_heading": "Su empresa Blitzz está lista para funcionar. ¿Conoces a algunos amigos o compañeros de trabajo a quienes les gustaría explorar Blitzz contigo?",
        "new_email_address": "Dirección de correo electrónico",
        "email_id": "Identificación de correo",
        "duplicate_email_address": "Dirección de correo electrónico duplicada.",
        "add_members_msg": "Puede agregar más miembros desde el portal de administración después de iniciar sesión.",
        "add_another_user": "Agregar otro usuario",
        "skip_for_now": "Saltar por ahora",
        "select_state_label": "Seleccione estado",
        "no_search_result_found_label": "No se encontró ningún resultado de búsqueda",
        "select_country_label": "Seleccionar país",
        "your_account_has_been_created_successfully": "Su cuenta ha sido creada con éxito",
        "company_ceated_popup_description": "Nuestro equipo está configurando su cuenta para garantizar que esté configurada correctamente para su caso de uso. Nos pondremos en contacto con usted en breve.",
        "company_created_popup_description_1": "Esto generalmente toma 1 día hábil para completarse.",
        "back_to_login": "Atrás para iniciar sesión",
        "contact_us": "Contáctenos",
        "one_business_day": "1 día hábil",
        "step_2_of_2": "Paso 2 de 2",
    },
    "es_MX": {
        "self_signup_title": "¡Empiece hoy mismo su prueba gratuita de 14 días de Blitzz!",
        "self_signup_heading1": "Simplemente ingrese su correo electrónico de trabajo a continuación. Sin llamada de ventas. Sin tarjeta de crédito. Sin descarga. Súbete y prueba Blitzz por ti mismo.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "use_business_email_error": "Utilice su dirección de correo electrónico comercial.",
        "error_limit_exceeded": "¡Límite excedido!",
        "continue": "Continuar",
        "already_have_account": "¿Ya tienes una cuenta?",
        "sign_in": "Registrarse",
        "dismiss": "Despedir",
        "check_ccode_text": "Verifique su correo electrónico para obtener un código",
        "resend_code": "Reenviar codigo",
        "ccod_genral_text": "Enviamos un código de confirmación de 6 dígitos a <strong> <trial_email> . </strong> El código caduca en breve, ingréselo pronto.",
        "your_ccode_text": "Tu <strong> código de confirmación </strong>",
        "ccode_note1_text": "Mantenga esta ventana abierta mientras busca su código. <br> ¿No ha recibido nuestro correo electrónico? Prueba tu carpeta de spam o",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "Se envió el código de confirmación, verifique su correo electrónico",
        "step_1_of_2": "Paso 1 de 2",
        "user_info": "Información de usuario",
        "complete_your_account_details_below": "Complete los detalles de su cuenta a continuación",
        "create_account_new_ui_desc": "Por favor escriba su nombre y cree una contraseña para continuar",
        "user_first_name": "Nombre de pila",
        "crt_acnt_valid_first_name": "Por favor, introduzca Nombre.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "user_last_name": "Apellido",
        "crt_acnt_enter_last_name": "Ingrese el apellido.",
        "user_password": "Contraseña",
        "password_validation_msg": "Por favor ingrese una contraseña válida.",
        "new_terms_and_conditions": "Términos y condiciones",
        "crt_acnt_term_condition_val_msg": "Por favor, acepte términos y condiciones.",
        "crt_acnt_accept": "Aceptar",
        "step_2_of_3": "Paso 2 de 3",
        "company_info": "Información de la compañía",
        "company_name": "Nombre de empresa",
        "company_name_error": "Introduzca el nombre de la empresa.",
        "number_of_employees": "Número de empleados",
        "invalid_number_of_employees": "Número de empleados no válido",
        "no_of_inspections": "No de inspecciones / mes",
        "invalid_no_of_inspections": "No válido de inspecciones / mes",
        "blitzz_subdomain_url": "La URL de su subdominio Blitzz",
        "subdomain": "Subdominio",
        "subdomain_error": "Introduzca el subdominio.",
        "domain_length_msg": "Se requieren 3 caracteres como mínimo.",
        "not_a_valid_subdomain": "Este no es un subdominio válido.",
        "create_my_account_text1": "Al hacer clic en \"Crear mi cuenta\", acepta el Blitzz",
        "subdomain_not_available": "Este subdominio no está disponible.",
        "create_my_account": "Crear mi cuenta",
        "step_3_of_3": "Paso 3 de 3",
        "send_invitations_title": "Enviar invitacion",
        "send_invitations_heading": "Su empresa Blitzz está lista para funcionar. ¿Conoces a algunos amigos o compañeros de trabajo a quienes les gustaría explorar Blitzz contigo?",
        "new_email_address": "Dirección de correo electrónico",
        "email_id": "Identificación de correo",
        "duplicate_email_address": "Dirección de correo electrónico duplicada.",
        "add_members_msg": "Puede agregar más miembros desde el portal de administración después de iniciar sesión.",
        "add_another_user": "Agregar otro usuario",
        "skip_for_now": "Saltar por ahora",
        "select_state_label": "Seleccione estado",
        "no_search_result_found_label": "No se encontró ningún resultado de búsqueda",
        "select_country_label": "Seleccionar país",
        "your_account_has_been_created_successfully": "Su cuenta ha sido creada con éxito",
        "company_ceated_popup_description": "Nuestro equipo está configurando su cuenta para garantizar que esté configurada correctamente para su caso de uso. Nos pondremos en contacto con usted en breve.",
        "company_created_popup_description_1": "Esto generalmente toma 1 día hábil para completarse.",
        "back_to_login": "Atrás para iniciar sesión",
        "contact_us": "Contáctenos",
        "one_business_day": "1 día hábil",
        "step_2_of_2": "Paso 2 de 2",
    },
    "es_AR": {
        "self_signup_title": "¡Empiece hoy mismo su prueba gratuita de 14 días de Blitzz!",
        "self_signup_heading1": "Simplemente ingrese su correo electrónico de trabajo a continuación. Sin llamada de ventas. Sin tarjeta de crédito. Sin descarga. Súbete y prueba Blitzz por ti mismo.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "use_business_email_error": "Utilice su dirección de correo electrónico comercial.",
        "error_limit_exceeded": "¡Límite excedido!",
        "continue": "Continuar",
        "already_have_account": "¿Ya tienes una cuenta?",
        "sign_in": "Registrarse",
        "dismiss": "Despedir",
        "check_ccode_text": "Verifique su correo electrónico para obtener un código",
        "resend_code": "Reenviar codigo",
        "ccod_genral_text": "Enviamos un código de confirmación de 6 dígitos a <strong> <trial_email> . </strong> El código caduca en breve, ingréselo pronto.",
        "your_ccode_text": "Tu <strong> código de confirmación </strong>",
        "ccode_note1_text": "Mantenga esta ventana abierta mientras busca su código. <br> ¿No ha recibido nuestro correo electrónico? Prueba tu carpeta de spam o",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "Se envió el código de confirmación, verifique su correo electrónico",
        "step_1_of_2": "Paso 1 de 2",
        "user_info": "Información de usuario",
        "complete_your_account_details_below": "Complete los detalles de su cuenta a continuación",
        "create_account_new_ui_desc": "Por favor escriba su nombre y cree una contraseña para continuar",
        "user_first_name": "Nombre de pila",
        "crt_acnt_valid_first_name": "Por favor, introduzca Nombre.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "user_last_name": "Apellido",
        "crt_acnt_enter_last_name": "Ingrese el apellido.",
        "user_password": "Contraseña",
        "password_validation_msg": "Por favor ingrese una contraseña válida.",
        "new_terms_and_conditions": "Términos y condiciones",
        "crt_acnt_term_condition_val_msg": "Por favor, acepte términos y condiciones.",
        "crt_acnt_accept": "Aceptar",
        "step_2_of_3": "Paso 2 de 3",
        "company_info": "Información de la compañía",
        "company_name": "Nombre de empresa",
        "company_name_error": "Introduzca el nombre de la empresa.",
        "number_of_employees": "Número de empleados",
        "invalid_number_of_employees": "Número de empleados no válido",
        "no_of_inspections": "No de inspecciones / mes",
        "invalid_no_of_inspections": "No válido de inspecciones / mes",
        "blitzz_subdomain_url": "La URL de su subdominio Blitzz",
        "subdomain": "Subdominio",
        "subdomain_error": "Introduzca el subdominio.",
        "domain_length_msg": "Se requieren 3 caracteres como mínimo.",
        "not_a_valid_subdomain": "Este no es un subdominio válido.",
        "create_my_account_text1": "Al hacer clic en \"Crear mi cuenta\", acepta el Blitzz",
        "subdomain_not_available": "Este subdominio no está disponible.",
        "create_my_account": "Crear mi cuenta",
        "step_3_of_3": "Paso 3 de 3",
        "send_invitations_title": "Enviar invitacion",
        "send_invitations_heading": "Su empresa Blitzz está lista para funcionar. ¿Conoces a algunos amigos o compañeros de trabajo a quienes les gustaría explorar Blitzz contigo?",
        "new_email_address": "Dirección de correo electrónico",
        "email_id": "Identificación de correo",
        "duplicate_email_address": "Dirección de correo electrónico duplicada.",
        "add_members_msg": "Puede agregar más miembros desde el portal de administración después de iniciar sesión.",
        "add_another_user": "Agregar otro usuario",
        "skip_for_now": "Saltar por ahora",
        "select_state_label": "Seleccione estado",
        "no_search_result_found_label": "No se encontró ningún resultado de búsqueda",
        "select_country_label": "Seleccionar país",
        "your_account_has_been_created_successfully": "Su cuenta ha sido creada con éxito",
        "company_ceated_popup_description": "Nuestro equipo está configurando su cuenta para garantizar que esté configurada correctamente para su caso de uso. Nos pondremos en contacto con usted en breve.",
        "company_created_popup_description_1": "Esto generalmente toma 1 día hábil para completarse.",
        "back_to_login": "Atrás para iniciar sesión",
        "contact_us": "Contáctenos",
        "one_business_day": "1 día hábil",
        "step_2_of_2": "Paso 2 de 2",
    },
    "fr_FR": {
        "self_signup_title": "Commencez votre essai gratuit de 14 jours de Blitzz dès aujourd'hui!",
        "self_signup_heading1": "Entrez simplement votre e-mail professionnel ci-dessous. Pas d'appel de vente. Pas de carte de crédit. Pas de téléchargement. Lancez-vous et testez Blitzz par vous-même.",
        "self_signup_sample_email": "nom@work-email.com",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "use_business_email_error": "Veuillez utiliser votre adresse e-mail professionnelle.",
        "error_limit_exceeded": "Limite dépassée!",
        "continue": "Continuer",
        "already_have_account": "Vous avez déjà un compte?",
        "sign_in": "Se connecter",
        "dismiss": "Rejeter",
        "check_ccode_text": "Vérifiez votre email pour un code",
        "resend_code": "Renvoyer le code",
        "ccod_genral_text": "Nous avons envoyé un code de confirmation à 6 chiffres à <strong> <trial_email> . </strong> Le code expire sous peu, veuillez le saisir bientôt.",
        "your_ccode_text": "Votre <strong> code de confirmation </strong>",
        "ccode_note1_text": "Gardez cette fenêtre ouverte tout en vérifiant votre code. <br> Vous n'avez pas reçu notre e-mail? Essayez votre dossier spam ou",
        "validating_code": "Validation du code ...",
        "ccod_resend_text": "Le code de confirmation est envoyé, veuillez vérifier votre email",
        "step_1_of_2": "Étape 1 sur 2",
        "user_info": "informations utilisateur",
        "complete_your_account_details_below": "Complétez les détails de votre compte ci-dessous",
        "create_account_new_ui_desc": "Veuillez indiquer votre nom et créer un mot de passe pour continuer",
        "user_first_name": "Prénom",
        "crt_acnt_valid_first_name": "S'il vous plaît entrez le nom d'abord.",
        "crt_acnt_limit_character_msg": "Jusqu'à 30 caractères autorisés.",
        "user_last_name": "Nom de famille",
        "crt_acnt_enter_last_name": "Veuillez entrer le nom.",
        "user_password": "Mot de passe",
        "password_validation_msg": "Veuillez entrer un mot de passe valide.",
        "new_terms_and_conditions": "termes et conditions",
        "crt_acnt_term_condition_val_msg": "S'il vous plaît accepter les conditions générales de vente.",
        "crt_acnt_accept": "Acceptez",
        "step_2_of_3": "Étape 2 de 3",
        "company_info": "Information d'entreprise",
        "company_name": "Nom de la compagnie",
        "company_name_error": "Veuillez saisir le nom de l'entreprise.",
        "number_of_employees": "Nombre d'employés",
        "invalid_number_of_employees": "Nombre d'employés invalide",
        "no_of_inspections": "Nombre d'inspections / mois",
        "invalid_no_of_inspections": "Nombre d'inspections / mois invalide",
        "blitzz_subdomain_url": "L'URL de votre sous-domaine Blitzz",
        "subdomain": "Sous-domaine",
        "subdomain_error": "Veuillez saisir un sous-domaine.",
        "domain_length_msg": "Minimum 3 caractères requis.",
        "not_a_valid_subdomain": "Ce n'est pas un sous-domaine valide.",
        "create_my_account_text1": "En cliquant sur «Créer mon compte», vous acceptez le Blitzz",
        "subdomain_not_available": "Ce sous-domaine n'est pas disponible.",
        "create_my_account": "Créer mon compte",
        "step_3_of_3": "Étape 3 de 3",
        "send_invitations_title": "Envoyer une invitation",
        "send_invitations_heading": "Votre entreprise Blitzz est prête à fonctionner. Vous connaissez quelques amis ou collègues qui aimeraient explorer Blitzz avec vous?",
        "new_email_address": "Adresse électronique",
        "email_id": "Identifiant e-mail",
        "duplicate_email_address": "Adresse e-mail en double.",
        "add_members_msg": "Vous pouvez ajouter d'autres membres depuis le portail d'administration après vous être connecté.",
        "add_another_user": "Ajouter un autre utilisateur",
        "skip_for_now": "Ignorer pour l'instant",
        "select_state_label": "Sélectionnez l'état",
        "no_search_result_found_label": "Aucun résultat de recherche trouvé",
        "select_country_label": "Choisissez le pays",
        "your_account_has_been_created_successfully": "Votre compte a été créé avec succès",
        "company_ceated_popup_description": "Votre compte est configuré par notre équipe pour s'assurer qu'il est configuré correctement pour votre cas d'utilisation. Nous vous contacterons prochainement.",
        "company_created_popup_description_1": "Cela prend généralement 1 jour ouvrable.",
        "back_to_login": "Retour connexion",
        "contact_us": "Contactez nous",
        "one_business_day": "1 jour ouvrable",
        "step_2_of_2": "Étape 2 sur 2",
    },
    "en_CA": {
        "self_signup_title": "Start your free 14-day trial of Blitzz today!",
        "self_signup_heading1": "Just enter your work email below. No sales call. No credit card. No download. Jump in and test drive Blitzz for yourself.",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "Enter valid email address.",
        "use_business_email_error": "Please use your business email address.",
        "error_limit_exceeded": "Limit exceeded!",
        "continue": "Continue",
        "already_have_account": "Already have an account?",
        "sign_in": "Sign In",
        "dismiss": "Dismiss",
        "check_ccode_text": "Check your email for a code",
        "resend_code": "Resend code",
        "ccod_genral_text": "We've sent a 6-digit confirmation code to <strong><trial_email>.</strong> The code expires shortly, please enter it soon.",
        "your_ccode_text": "Your <strong>Confirmation code</strong>",
        "ccode_note1_text": "Keep this window open while checking for your code. <br> Haven't received our email? Try your spam folder or",
        "validating_code": "Validating code...",
        "ccod_resend_text": "Confirmation code is sent, please check your email",
        "step_1_of_2": "Step 1 of 2",
        "user_info": "User Info",
        "complete_your_account_details_below": "Complete your account details below",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "user_last_name": "Last name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "user_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "new_terms_and_conditions": "Terms & Conditions",
        "crt_acnt_term_condition_val_msg": "Please accept Terms & Conditions.",
        "crt_acnt_accept": "Accept",
        "step_2_of_3": "Step 2 of 3",
        "company_info": "Company Info",
        "company_name": "Company name",
        "company_name_error": "Please enter Company name.",
        "number_of_employees": "Number of employees",
        "invalid_number_of_employees": "Invalid Number of employees",
        "no_of_inspections": "No. of inspections/month",
        "invalid_no_of_inspections": "Invalid No. of inspections/month",
        "blitzz_subdomain_url": "Your Blitzz subdomain URL",
        "subdomain": "Subdomain",
        "subdomain_error": "Please enter Subdomain.",
        "domain_length_msg": "Minimum 3 characters required.",
        "not_a_valid_subdomain": "This is not a valid subdomain.",
        "create_my_account_text1": "By clicking “Create My Account” you agree to the Blitzz",
        "subdomain_not_available": "This subdomain is not available.",
        "create_my_account": "Create My Account",
        "step_3_of_3": "Step 3 of 3",
        "send_invitations_title": "Send Invitation",
        "send_invitations_heading": "Your Blitzz company is ready to go. Know a few friends or co-workers who'd like to explore Blitzz with you?",
        "new_email_address": "Email address",
        "email_id": "Email ID",
        "duplicate_email_address": "Duplicate email address.",
        "add_members_msg": "You can add more members from the Admin portal after you sign in.",
        "add_another_user": "Add another user",
        "skip_for_now": "Skip for now",
        "select_state_label": "Select state",
        "no_search_result_found_label": "No search result found",
        "select_country_label": "Select country",
        "your_account_has_been_created_successfully": "Your account has been created successfully",
        "company_ceated_popup_description": "Your account is being configured by our team to ensure it is setup correctly for your use case. We will get in touch with you shortly.",
        "company_created_popup_description_1": "This usually takes 1 business day to complete.",
        "back_to_login": "Back to Login",
        "contact_us": "Contact Us",
        "one_business_day": "1 business day",
        "step_2_of_2": "Step 2 of 2",
    },
    "fr_CA": {
        "self_signup_title": "Commencez votre essai gratuit de 14 jours de Blitzz dès aujourd'hui !",
        "self_signup_heading1": "Entrez simplement votre e-mail professionnel ci-dessous. Pas d'appel de vente. Pas de carte de crédit. Pas de téléchargement. Lancez-vous et testez Blitzz par vous-même.",
        "self_signup_sample_email": "nom@work-email.com",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "use_business_email_error": "Veuillez utiliser votre adresse e-mail professionnelle.",
        "error_limit_exceeded": "Limite dépassée!",
        "continue": "Continuer",
        "already_have_account": "Vous avez déjà un compte?",
        "sign_in": "Se connecter",
        "dismiss": "Rejeter",
        "check_ccode_text": "Vérifiez votre email pour un code",
        "resend_code": "Renvoyer le code",
        "ccod_genral_text": "Nous avons envoyé un code de confirmation à 6 chiffres à <strong> <trial_email> . </strong> Le code expire sous peu, veuillez le saisir bientôt.",
        "your_ccode_text": "Votre <strong> code de confirmation </strong>",
        "ccode_note1_text": "Gardez cette fenêtre ouverte tout en vérifiant votre code. <br> Vous n'avez pas reçu notre e-mail? Essayez votre dossier spam ou",
        "validating_code": "Validation du code ...",
        "ccod_resend_text": "Le code de confirmation est envoyé, veuillez vérifier votre email",
        "step_1_of_2": "Étape 1 sur 2",
        "user_info": "informations utilisateur",
        "complete_your_account_details_below": "Complétez les détails de votre compte ci-dessous",
        "create_account_new_ui_desc": "Veuillez indiquer votre nom et créer un mot de passe pour continuer",
        "user_first_name": "Prénom",
        "crt_acnt_valid_first_name": "S'il vous plaît entrez le nom d'abord.",
        "crt_acnt_limit_character_msg": "Jusqu'à 30 caractères autorisés.",
        "user_last_name": "Nom de famille",
        "crt_acnt_enter_last_name": "Veuillez entrer le nom.",
        "user_password": "Mot de passe",
        "password_validation_msg": "Veuillez entrer un mot de passe valide.",
        "new_terms_and_conditions": "termes et conditions",
        "crt_acnt_term_condition_val_msg": "S'il vous plaît accepter les conditions générales de vente.",
        "crt_acnt_accept": "Acceptez",
        "step_2_of_3": "Étape 2 de 3",
        "company_info": "Information d'entreprise",
        "company_name": "Nom de la compagnie",
        "company_name_error": "Veuillez saisir le nom de l'entreprise.",
        "number_of_employees": "Nombre d'employés",
        "invalid_number_of_employees": "Nombre d'employés invalide",
        "no_of_inspections": "Nombre d'inspections / mois",
        "invalid_no_of_inspections": "Nombre d'inspections / mois invalide",
        "blitzz_subdomain_url": "L'URL de votre sous-domaine Blitzz",
        "subdomain": "Sous-domaine",
        "subdomain_error": "Veuillez saisir un sous-domaine.",
        "domain_length_msg": "Minimum 3 caractères requis.",
        "not_a_valid_subdomain": "Ce n'est pas un sous-domaine valide.",
        "create_my_account_text1": "En cliquant sur «Créer mon compte», vous acceptez le Blitzz",
        "subdomain_not_available": "Ce sous-domaine n'est pas disponible.",
        "create_my_account": "Créer mon compte",
        "step_3_of_3": "Étape 3 de 3",
        "send_invitations_title": "Envoyer une invitation",
        "send_invitations_heading": "Votre entreprise Blitzz est prête à fonctionner. Vous connaissez quelques amis ou collègues qui aimeraient explorer Blitzz avec vous?",
        "new_email_address": "Adresse électronique",
        "email_id": "Identifiant e-mail",
        "duplicate_email_address": "Adresse e-mail en double.",
        "add_members_msg": "Vous pouvez ajouter d'autres membres depuis le portail d'administration après vous être connecté.",
        "add_another_user": "Ajouter un autre utilisateur",
        "skip_for_now": "Ignorer pour l'instant",
        "select_state_label": "Sélectionnez l'état",
        "no_search_result_found_label": "Aucun résultat de recherche trouvé",
        "select_country_label": "Choisissez le pays",
        "your_account_has_been_created_successfully": "Votre compte a été créé avec succès",
        "company_ceated_popup_description": "Votre compte est configuré par notre équipe pour s'assurer qu'il est configuré correctement pour votre cas d'utilisation. Nous vous contacterons prochainement.",
        "company_created_popup_description_1": "Cela prend généralement 1 jour ouvrable.",
        "back_to_login": "Retour connexion",
        "contact_us": "Contactez nous",
        "one_business_day": "1 jour ouvrable",
        "step_2_of_2": "Étape 2 sur 2",
    },
    "pt_BR": {
        "self_signup_title": "Comece sua avaliação gratuita de 14 dias do Blitzz hoje!",
        "self_signup_heading1": "Basta inserir seu e-mail comercial abaixo. Nenhuma chamada de vendas. Sem cartão de crédito. Sem download. Entre e teste o Blitzz por si mesmo.",
        "self_signup_sample_email": "nome@trabalho-email.com",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "use_business_email_error": "Use seu endereço de e-mail comercial.",
        "error_limit_exceeded": "Limite excedido!",
        "continue": "Continuar",
        "already_have_account": "já tem uma conta?",
        "sign_in": "Entrar",
        "dismiss": "Dispensar",
        "check_ccode_text": "Verifique seu e-mail para obter um código",
        "resend_code": "Reenviar código",
        "ccod_genral_text": "Enviamos um código de confirmação de 6 dígitos para <strong> <trial_email> . </strong> O código expira em breve, insira-o em breve.",
        "your_ccode_text": "Seu <strong> código de confirmação </strong>",
        "ccode_note1_text": "Mantenha esta janela aberta enquanto verifica seu código. <br> Não recebeu nosso email? Tente sua pasta de spam ou",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "O código de confirmação foi enviado, verifique seu e-mail",
        "step_1_of_2": "Etapa 1 de 2",
        "user_info": "informação de usuário",
        "complete_your_account_details_below": "Preencha os detalhes da sua conta abaixo",
        "create_account_new_ui_desc": "Liste seu nome e crie uma senha para continuar",
        "user_first_name": "Nome",
        "crt_acnt_valid_first_name": "Por favor, indique primeiro nome.",
        "crt_acnt_limit_character_msg": "São permitidos até 30 caracteres.",
        "user_last_name": "Sobrenome",
        "crt_acnt_enter_last_name": "Por favor, insira o sobrenome.",
        "user_password": "Senha",
        "password_validation_msg": "Por favor, insira a senha válida.",
        "new_terms_and_conditions": "termos e Condições",
        "crt_acnt_term_condition_val_msg": "Por favor, aceite os Termos e Condições.",
        "crt_acnt_accept": "Aceitar",
        "step_2_of_3": "Etapa 2 de 3",
        "company_info": "Informação da companhia",
        "company_name": "Nome da empresa",
        "company_name_error": "Insira o nome da empresa.",
        "number_of_employees": "Número de empregados",
        "invalid_number_of_employees": "Número inválido de funcionários",
        "no_of_inspections": "Nº de inspeções / mês",
        "invalid_no_of_inspections": "Nº inválido de inspeções / mês",
        "blitzz_subdomain_url": "URL do seu subdomínio Blitzz",
        "subdomain": "Subdomínio",
        "subdomain_error": "Por favor, insira o subdomínio.",
        "domain_length_msg": "São necessários no mínimo 3 caracteres.",
        "not_a_valid_subdomain": "Este não é um subdomínio válido.",
        "create_my_account_text1": "Ao clicar em “Criar minha conta” você concorda com o Blitzz",
        "subdomain_not_available": "Este subdomínio não está disponível.",
        "create_my_account": "Criar a minha conta",
        "step_3_of_3": "Etapa 3 de 3",
        "send_invitations_title": "Enviar convite",
        "send_invitations_heading": "Sua empresa Blitzz está pronta para começar. Conhece alguns amigos ou colegas de trabalho que gostariam de explorar Blitzz com você?",
        "new_email_address": "Endereço de e-mail",
        "email_id": "Identificação do email",
        "duplicate_email_address": "Endereço de email duplicado.",
        "add_members_msg": "Você pode adicionar mais membros do portal de administração após fazer login.",
        "add_another_user": "Adicionar outro usuário",
        "skip_for_now": "Pular por agora",
        "select_state_label": "Selecione o estado",
        "no_search_result_found_label": "Nenhum resultado de pesquisa encontrado",
        "select_country_label": "Selecione o pais",
        "your_account_has_been_created_successfully": "Sua conta foi criada com sucesso",
        "company_ceated_popup_description": "Sua conta está sendo configurada por nossa equipe para garantir que ela seja configurada corretamente para seu caso de uso. Nós entraremos em contato com você em breve.",
        "company_created_popup_description_1": "Isso geralmente leva 1 dia útil para ser concluído.",
        "back_to_login": "Volte ao login",
        "contact_us": "Contate-Nos",
        "one_business_day": "1 dia útil",
        "step_2_of_2": "Etapa 2 de 2",
    },
    "pt_PT": {
        "self_signup_title": "Comece sua avaliação gratuita de 14 dias do Blitzz hoje!",
        "self_signup_heading1": "Basta inserir seu e-mail comercial abaixo. Nenhuma chamada de vendas. Sem cartão de crédito. Sem download. Entre e teste o Blitzz por si mesmo.",
        "self_signup_sample_email": "nome@trabalho-email.com",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "use_business_email_error": "Use seu endereço de e-mail comercial.",
        "error_limit_exceeded": "Limite excedido!",
        "continue": "Continuar",
        "already_have_account": "já tem uma conta?",
        "sign_in": "Assinar em",
        "dismiss": "Dispensar",
        "check_ccode_text": "Verifique seu e-mail para obter um código",
        "resend_code": "Reenviar código",
        "ccod_genral_text": "Enviamos um código de confirmação de 6 dígitos para <strong> <trial_email> . </strong> O código expira em breve, insira-o em breve.",
        "your_ccode_text": "Seu <strong> código de confirmação </strong>",
        "ccode_note1_text": "Mantenha esta janela aberta enquanto verifica seu código. <br> Não recebeu nosso email? Tente sua pasta de spam ou",
        "validating_code": "Validando código ...",
        "ccod_resend_text": "O código de confirmação foi enviado, verifique seu e-mail",
        "step_1_of_2": "Etapa 1 de 2",
        "user_info": "informação de usuário",
        "complete_your_account_details_below": "Preencha os detalhes da sua conta abaixo",
        "create_account_new_ui_desc": "Liste seu nome e crie uma senha para continuar",
        "user_first_name": "Primeiro nome",
        "crt_acnt_valid_first_name": "Por favor, indique primeiro nome.",
        "crt_acnt_limit_character_msg": "São permitidos até 30 caracteres.",
        "user_last_name": "Último nome",
        "crt_acnt_enter_last_name": "Por favor, insira o sobrenome.",
        "user_password": "Senha",
        "password_validation_msg": "Por favor, insira a senha válida.",
        "new_terms_and_conditions": "termos e Condições",
        "crt_acnt_term_condition_val_msg": "Por favor, aceite os Termos e Condições.",
        "crt_acnt_accept": "Aceitar",
        "step_2_of_3": "Etapa 2 de 3",
        "company_info": "Informação da companhia",
        "company_name": "Nome da empresa",
        "company_name_error": "Insira o nome da empresa.",
        "number_of_employees": "Número de empregados",
        "invalid_number_of_employees": "Número inválido de funcionários",
        "no_of_inspections": "Nº de inspeções / mês",
        "invalid_no_of_inspections": "Nº inválido de inspeções / mês",
        "blitzz_subdomain_url": "URL do seu subdomínio Blitzz",
        "subdomain": "Subdomínio",
        "subdomain_error": "Por favor, insira o subdomínio.",
        "domain_length_msg": "São necessários no mínimo 3 caracteres.",
        "not_a_valid_subdomain": "Este não é um subdomínio válido.",
        "create_my_account_text1": "Ao clicar em “Criar minha conta” você concorda com o Blitzz",
        "subdomain_not_available": "Este subdomínio não está disponível.",
        "create_my_account": "Criar a minha conta",
        "step_3_of_3": "Etapa 3 de 3",
        "send_invitations_title": "Enviar convite",
        "send_invitations_heading": "Sua empresa Blitzz está pronta para começar. Conhece alguns amigos ou colegas de trabalho que gostariam de explorar Blitzz com você?",
        "new_email_address": "Endereço de e-mail",
        "email_id": "Identificação do email",
        "duplicate_email_address": "Endereço de email duplicado.",
        "add_members_msg": "Você pode adicionar mais membros do portal de administração após fazer login.",
        "add_another_user": "Adicionar outro usuário",
        "skip_for_now": "Pular por agora",
        "select_state_label": "Selecione o estado",
        "no_search_result_found_label": "Nenhum resultado de pesquisa encontrado",
        "select_country_label": "Selecione o pais",
        "your_account_has_been_created_successfully": "Sua conta foi criada com sucesso",
        "company_ceated_popup_description": "Sua conta está sendo configurada por nossa equipe para garantir que ela seja configurada corretamente para seu caso de uso. Nós entraremos em contato com você em breve.",
        "company_created_popup_description_1": "Isso geralmente leva 1 dia útil para ser concluído.",
        "back_to_login": "Volte ao login",
        "contact_us": "Contate-Nos",
        "one_business_day": "1 dia útil",
        "step_2_of_2": "Etapa 2 de 2",
    },
    "it_IT": {
        "self_signup_title": "Inizia oggi la tua prova gratuita di 14 giorni di Blitzz!",
        "self_signup_heading1": "Inserisci la tua email di lavoro qui sotto. Nessuna chiamata di vendita. Nessuna carta di credito. Nessun download. Salta e prova tu stesso Blitzz.",
        "self_signup_sample_email": "nome@lavoro-email.com",
        "enter_valid_email": "Inserisci un indirizzo email valido.",
        "use_business_email_error": "Utilizza il tuo indirizzo email aziendale.",
        "error_limit_exceeded": "Limite superato!",
        "continue": "Continua",
        "already_have_account": "Hai già un account?",
        "sign_in": "Registrati",
        "dismiss": "Respingere",
        "check_ccode_text": "Controlla la tua email per un codice",
        "resend_code": "Codice di rispedizione",
        "ccod_genral_text": "Abbiamo inviato un codice di conferma di 6 cifre a <strong> <trial_email> . </strong> Il codice scade a breve, inseriscilo presto.",
        "your_ccode_text": "Il tuo <strong> codice di conferma </strong>",
        "ccode_note1_text": "Tieni aperta questa finestra mentre controlli il tuo codice. <br> Non hai ricevuto la nostra email? Prova la tua cartella spam o",
        "validating_code": "Convalida del codice in corso...",
        "ccod_resend_text": "Il codice di conferma è stato inviato, controlla la tua email",
        "step_1_of_2": "Passaggio 1 di 2",
        "user_info": "Informazioni utente",
        "complete_your_account_details_below": "Completa i dettagli del tuo account qui sotto",
        "create_account_new_ui_desc": "Inserisci il tuo nome e crea una password per continuare",
        "user_first_name": "Nome di battesimo",
        "crt_acnt_valid_first_name": "Si prega di inserire il nome.",
        "crt_acnt_limit_character_msg": "Sono consentiti fino a 30 caratteri.",
        "user_last_name": "Cognome",
        "crt_acnt_enter_last_name": "Si prega di inserire il cognome.",
        "user_password": "Parola d'ordine",
        "password_validation_msg": "Si prega di inserire una password valida.",
        "new_terms_and_conditions": "Termini & Condizioni",
        "crt_acnt_term_condition_val_msg": "Si prega di accettare Termini e Condizioni.",
        "crt_acnt_accept": "Accettare",
        "step_2_of_3": "Passaggio 2 di 3",
        "company_info": "Informazioni sull'azienda",
        "company_name": "Nome della ditta",
        "company_name_error": "Inserisci il nome dell'azienda.",
        "number_of_employees": "Numero di dipendenti",
        "invalid_number_of_employees": "Numero di dipendenti non valido",
        "no_of_inspections": "N. di ispezioni/mese",
        "invalid_no_of_inspections": "N. di ispezioni/mese non valido",
        "blitzz_subdomain_url": "L'URL del tuo sottodominio Blitzz",
        "subdomain": "Sottodominio",
        "subdomain_error": "Inserisci sottodominio.",
        "domain_length_msg": "Sono richiesti minimo 3 caratteri.",
        "not_a_valid_subdomain": "Questo non è un sottodominio valido.",
        "create_my_account_text1": "Facendo clic su \"Crea il mio account\" accetti il Blitzz",
        "subdomain_not_available": "Questo sottodominio non è disponibile.",
        "create_my_account": "Creare il mio account",
        "step_3_of_3": "Passaggio 3 di 3",
        "send_invitations_title": "Spedire un invito",
        "send_invitations_heading": "La tua compagnia Blitzz è pronta per partire. Conosci alcuni amici o colleghi che vorrebbero esplorare Blitzz con te?",
        "new_email_address": "Indirizzo e-mail",
        "email_id": "E-mail identificativo utente",
        "duplicate_email_address": "Indirizzo email duplicato.",
        "add_members_msg": "Puoi aggiungere più membri dal portale di amministrazione dopo aver effettuato l'accesso.",
        "add_another_user": "Aggiungi un altro utente",
        "skip_for_now": "Salta per ora",
        "select_state_label": "Seleziona stato",
        "no_search_result_found_label": "Nessun risultato di ricerca trovato",
        "select_country_label": "Seleziona il paese",
        "your_account_has_been_created_successfully": "Il tuo account è stato creato con successo",
        "company_ceated_popup_description": "Il tuo account è stato configurato dal nostro team per assicurarsi che sia configurato correttamente per il tuo caso d'uso. Ci metteremo in contatto con te a breve.",
        "company_created_popup_description_1": "Questo di solito richiede 1 giorno lavorativo per essere completato.",
        "back_to_login": "Torna al login",
        "contact_us": "Contattaci",
        "one_business_day": "1 giorno lavorativo",
        "step_2_of_2": "Passaggio 2 di 2",
    },
    "zh_CN": {
        "self_signup_title": "立即开始 14 天免费试用 Blitzz！",
        "self_signup_heading1": "只需在下面输入您的工作电子邮件。没有销售电话。没有信用卡。没有下载。加入并亲自试驾 Blitzz。",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "输入有效的电子邮件地址。",
        "use_business_email_error": "请使用您的企业电子邮件地址。",
        "error_limit_exceeded": "超出限制！",
        "continue": "继续",
        "already_have_account": "已经有账户？",
        "sign_in": "登入",
        "dismiss": "解雇",
        "check_ccode_text": "检查您的电子邮件以获取代码",
        "resend_code": "重新发送验证码",
        "ccod_genral_text": "<strong> <trial_email>发送了一个 6 位数的确认码。 </strong>代码即将过期，请尽快输入。",
        "your_ccode_text": "您的<strong>确认码</strong>",
        "ccode_note1_text": "在检查您的代码时保持此窗口打开。 <br>还没有收到我们的电子邮件？试试你的垃圾邮件文件夹或",
        "validating_code": "正在验证代码...",
        "ccod_resend_text": "确认码已发送，请查收邮箱",
        "step_1_of_2": "第 1 步，共 2 步",
        "user_info": "用户信息",
        "complete_your_account_details_below": "在下面填写您的帐户详细信息",
        "create_account_new_ui_desc": "请列出您的姓名并创建密码以继续",
        "user_first_name": "名",
        "crt_acnt_valid_first_name": "请输入名字。",
        "crt_acnt_limit_character_msg": "最多允许 30 个字符。",
        "user_last_name": "姓",
        "crt_acnt_enter_last_name": "请输入姓氏。",
        "user_password": "密码",
        "password_validation_msg": "请输入有效密码。",
        "new_terms_and_conditions": "条款和条件",
        "crt_acnt_term_condition_val_msg": "请接受条款和条件。",
        "crt_acnt_accept": "接受",
        "step_2_of_3": "第 2 步（共 3 步）",
        "company_info": "公司信息",
        "company_name": "公司名",
        "company_name_error": "请输入公司名称。",
        "number_of_employees": "在职员工人数",
        "invalid_number_of_employees": "员工人数无效",
        "no_of_inspections": "检查次数/月",
        "invalid_no_of_inspections": "无效检查次数/月",
        "blitzz_subdomain_url": "您的 Blitzz 子域 URL",
        "subdomain": "子域",
        "subdomain_error": "请输入子域。",
        "domain_length_msg": "至少需要 3 个字符。",
        "not_a_valid_subdomain": "这不是有效的子域。",
        "create_my_account_text1": "单击“创建我的帐户”即表示您同意 Blitzz",
        "subdomain_not_available": "此子域不可用。",
        "create_my_account": "创建我的账户",
        "step_3_of_3": "第 3 步（共 3 步）",
        "send_invitations_title": "发送邀请",
        "send_invitations_heading": "您的 Blitzz 公司已准备就绪。认识几个想和你一起探索闪电战的朋友或同事吗？",
        "new_email_address": "电子邮件地址",
        "email_id": "电子邮件ID",
        "duplicate_email_address": "重复的电子邮件地址。",
        "add_members_msg": "登录后，您可以从管理门户添加更多成员。",
        "add_another_user": "添加另一个用户",
        "skip_for_now": "暂时跳过",
        "select_state_label": "选择状态",
        "no_search_result_found_label": "没有找到搜索结果",
        "select_country_label": "选择国家",
        "your_account_has_been_created_successfully": "您的帐户已成功创建",
        "company_ceated_popup_description": "我们的团队正在配置您的帐户，以确保为您的用例正确设置。我们会尽快与您联系。",
        "company_created_popup_description_1": "这通常需要 1 个工作日才能完成。",
        "back_to_login": "回到登入",
        "contact_us": "联系我们",
        "one_business_day": "1 个工作日",
        "step_2_of_2": "第 2 步（共 2 步）",
    },
    "ja_JP": {
        "self_signup_title": "Blitzz の 14 日間無料トライアルを今すぐ始めましょう!",
        "self_signup_heading1": "仕事用のメールアドレスを下に入力してください。営業電話なし。クレジットカードはありません。ダウンロードなし。飛び込んで、自分で Blitzz を試乗してください。",
        "self_signup_sample_email": "name@work-email.com",
        "enter_valid_email": "有効なメールアドレスを入力してください。",
        "use_business_email_error": "ビジネスメールアドレスを使用してください。",
        "error_limit_exceeded": "限度超過！",
        "continue": "継続する",
        "already_have_account": "すでにアカウントをお持ちですか？",
        "sign_in": "ログイン",
        "dismiss": "解散",
        "check_ccode_text": "メールでコードを確認する",
        "resend_code": "コードを再送する",
        "ccod_genral_text": "<strong> <trial_email>に 6 桁の確認コードを送信しました。 </strong>コードの有効期限が間もなく切れます。すぐに入力してください。",
        "your_ccode_text": "あなたの<strong>確認コード</strong>",
        "ccode_note1_text": "コードをチェックしている間は、このウィンドウを開いたままにしてください。 <br>メールが届いていませんか?迷惑メールフォルダを試すか、",
        "validating_code": "コードを検証しています...",
        "ccod_resend_text": "確認コードが送信されます。メールを確認してください",
        "step_1_of_2": "ステップ 1/2",
        "user_info": "ユーザー情報",
        "complete_your_account_details_below": "アカウントの詳細を以下に入力してください",
        "create_account_new_ui_desc": "続行するには、名前を入力してパスワードを作成してください",
        "user_first_name": "ファーストネーム",
        "crt_acnt_valid_first_name": "名を入力してください。",
        "crt_acnt_limit_character_msg": "最大 30 文字まで使用できます。",
        "user_last_name": "苗字",
        "crt_acnt_enter_last_name": "姓を入力してください。",
        "user_password": "パスワード",
        "password_validation_msg": "有効なパスワードを入力してください。",
        "new_terms_and_conditions": "利用規約",
        "crt_acnt_term_condition_val_msg": "利用規約に同意してください。",
        "crt_acnt_accept": "承認",
        "step_2_of_3": "ステップ 2/3",
        "company_info": "会社情報",
        "company_name": "会社名",
        "company_name_error": "会社名を入力してください。",
        "number_of_employees": "就業者数",
        "invalid_number_of_employees": "従業員数が無効です",
        "no_of_inspections": "点検件数/月",
        "invalid_no_of_inspections": "無効な検査数/月",
        "blitzz_subdomain_url": "あなたの Blitzz サブドメイン URL",
        "subdomain": "サブドメイン",
        "subdomain_error": "サブドメインを入力してください。",
        "domain_length_msg": "最低3文字必要です。",
        "not_a_valid_subdomain": "これは有効なサブドメインではありません。",
        "create_my_account_text1": "「アカウントを作成」をクリックすることにより、Blitzz に同意したことになります。",
        "subdomain_not_available": "このサブドメインは利用できません。",
        "create_my_account": "アカウントを作る",
        "step_3_of_3": "ステップ 3/3",
        "send_invitations_title": "招待状を送る",
        "send_invitations_heading": "あなたの Blitzz カンパニーは準備万端です。あなたと一緒に Blitzz を探索したい友人や同僚を何人か知っていますか?",
        "new_email_address": "電子メールアドレス",
        "email_id": "電子メールID",
        "duplicate_email_address": "メールアドレスが重複しています。",
        "add_members_msg": "サインインした後、管理ポータルからメンバーを追加できます。",
        "add_another_user": "別のユーザーを追加",
        "skip_for_now": "今のところスキップ",
        "select_state_label": "状態を選択",
        "no_search_result_found_label": "検索結果が見つかりませんでした",
        "select_country_label": "国を選択",
        "your_account_has_been_created_successfully": "アカウントが正常に作成されました",
        "company_ceated_popup_description": "あなたのアカウントは、ユースケースに合わせて正しくセットアップされるように、私たちのチームによって構成されています。まもなくご連絡いたします。",
        "company_created_popup_description_1": "通常、これには 1 営業日かかります。",
        "back_to_login": "ログインに戻る",
        "contact_us": "お問い合わせ",
        "one_business_day": "1 営業日",
        "step_2_of_2": "ステップ 2/2",
    }
}