import PropTypes from 'prop-types';

export const DatePicker = (props) => {
    return (
        <div className="form-group custom-tooltip tooltip-auto-width center-tooltip top-tooltip m-hide">
            <div className="form-group-in with-icon">
                <input className="form-control form-control-grey height-46 focus-font-bold" {...props} data-testid='datePicker' ref={props.ref} />
                <span className="form-group-icon font-size-18" onClick={() => props.ref.current.click()} onKeyDown={() => { }}>
                    <span className="icon-calendar"></span>
                </span>
            </div>
            <div className="custom-tooltip-content text-nowrap text-center">
                <div className="custom-tooltip-in">
                    <p>{props.value}</p>
                </div>
            </div>
        </div>
    )
}

DatePicker.propTypes = {
    value: PropTypes.string.isRequired,
    ref: PropTypes.object
};

export const Timepicker = (props) => {
    return (
        <div className="form-group">
            <div className="form-group-in with-icon">
                <input className="form-control form-control-grey height-46 focus-font-bold"  {...props} data-testid='timePicker' ref={props.ref} />
                <span className="form-group-icon font-size-18" onClick={() => props.ref.current.click()} onKeyDown={() => { }}>
                    <span className="icon-time-clock"></span>
                </span>
            </div>
        </div>
    )
}

Timepicker.propTypes = {
    value: PropTypes.string.isRequired,
    ref: PropTypes.object
};

export const renderDay = (props, currentDate, selectedDate) => {
    return <td {...props}>{currentDate.date()}</td>;
}

export const renderMonth = (props, month, year, selectedDate) => {
    const shortMonthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    return <td {...props}>{shortMonthList[month]}</td>;
}

export const renderYear = (props, year, selectedDate) => {
    return <td {...props}>{year}</td>;
}
