import { DefaultLanguage, JOURNEY_STEP_TYPES } from "../../../../../constants/Constants";
import PropTypes from 'prop-types';

const MultiSelectStep = ({ dataObj, answerObj, currentLanguage }) => {
  if (dataObj.type === JOURNEY_STEP_TYPES.MULTI_SELECT && answerObj.length > 0) {
    return (
      <div className="step-info user-fill-info">
        {answerObj.map((ans, ansIndex) => (
          <div key={ansIndex}>
            <ul className="step-info-listing">
              {dataObj.props.options.map((option, optionIndex) => (
                option.key === ans && (
                  <li key={optionIndex}>
                    {option.value[currentLanguage] ? option.value[currentLanguage] : option.value[DefaultLanguage]}
                  </li>
                )
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

MultiSelectStep.propTypes = {
  dataObj: PropTypes.object,
  answerObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currentLanguage: PropTypes.string
}

export default MultiSelectStep;