import api from "../apis/api";

class SignUpServices {
    postCheckingSignUpEmail (formData) {
        return api.signUp.postCheckingSignUpEmail(formData);
    }

    postRegisterUser (formData) {
        return api.signUp.postRegisterUser(formData);
    }
}

export default new SignUpServices();