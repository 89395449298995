import __ from "lodash";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useParams, Prompt } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { ACTIONS_LIST, LOCAL_STORAGE_KEYS, MEETING_TYPE, RouteName } from "../../../constants/Constants";
import storage from "../../../services/storage";
import { getSessionDetails, getSessionImages, resetSessionDetails } from "../../../actions/sessionDetailAction";
import { formatSessionID, isUploadOnlySession, setPageTitle } from "../../../shared/utility";
import { Localize } from "./Localize";
import NotesTab from "./NotesTab/NotesTab";
import ImagesTab from "./ImageTab/ImagesTab";
import ResponsesTab from "./ResponsesTab/ResponsesTab";
import DetailsTab from "./DetailTab/DetailsTab";
import RecordingsTab from "./RecordingTab/RecordingsTab";
import FilesTab from "./FilesTab/FilesTab";
import history from "../../../history";
import CreateSessionModal from "../CreateSessionModal/CreateSessionModal";
import { isEmpty } from "lodash";
import CustomToast from "../../UI/customToast";

const SessionDetail = forwardRef(function SessionDetail(props, ref) {
    const {
        sessionsList,
        backButtonClick,
        setSessionsList,
        setIsDiscardModalOpen,
        isDiscardChanged,
        setIsDiscardChanged,
        isSessionAssignChange,
        setIsSessionAssignChange,
        setIsRefrenceTitleChanged,
        isRefrenceTitleChanged,
        setCurrentActionType,
        setIsDiscardModalSaveBtnDisabled,
        language,
        currentActionType,
    } = props;

    const params = useParams();
    const userData = useMemo(() => { return storage.get(LOCAL_STORAGE_KEYS.USER_DATA) }, []);
    const [meetingID, setMeetingID] = useState('');
    const [details, setDetails] = useState(null);
    const [showNotesTab, setShowNotesTab] = useState(false);
    const [showRecordingTab, setShowRecordingTab] = useState(false);
    const [showFilesTab, setShowFilesTab] = useState(false);
    const [isNotesTabSelected, setIsNotesTabSelected] = useState(false);
    const [isImagesTabSelected, setIsImagesTabSelected] = useState(false);
    const [isFilesTabSelected, setIsFilesTabSelected] = useState(false)
    const [isRecordingsTabSelected, setIsRecordingsTabSelected] = useState(false)
    const [showCreateSessionModal, setShowCreateSessionModal] = useState(false)
    const [localisedValues, setLocalisedValues] = useState({});
    const [currentPath, setCurrentPath] = useState("");

    const fancyRef = useRef(false)

    useEffect(() => {
        const curLang = language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
    }, [language.selectedlanguage]);

    const changeActiveTabUi = () => {
        let allTabs = document.querySelectorAll('[role="tab"]') || [];
        allTabs = Array.from(allTabs)

        if (allTabs.length > 0) {
            allTabs.forEach((tab) => {
                tab.classList.remove('active')
            })
            allTabs[0].classList.add('active')
        }
        setIsImagesTabSelected(false)
        setIsNotesTabSelected(false)
        setIsFilesTabSelected(false)
        setIsRecordingsTabSelected(false)

        setShowNotesTab(false)
        setShowRecordingTab(false)
        setShowFilesTab(false)
        setDetails(null)
    }

    useEffect(() => {
        if(isDiscardChanged && currentActionType?.type === ACTIONS_LIST.BROWSER_BACK_BTN) return
        if (!__.isEmpty(params)) {
            setMeetingID(params.sessionId);
            if (!props.sessionDetail?.getSessionDetailsLoading){
                props.getSessionDetails({ sessionId: params.sessionId });
            }
        }
        else {
            setMeetingID('');
        }

        if (fancyRef.current === false)
            changeActiveTabUi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        if (props.sessionDetail?.getSessionDetailsData) {
            const sessionInfo = props.sessionDetail?.getSessionDetailsData?.session;
            const featureFlags = props.sessionDetail?.getSessionDetailsData?.features;
            setDetails(props.sessionDetail?.getSessionDetailsData);
            let documentTitle = __.get(sessionInfo, 'reference', '')
            documentTitle = __.isEmpty(documentTitle) ? 'Session Details' : __.capitalize(documentTitle)
            setPageTitle(documentTitle);
            const isUploadOnlySessionValue = isUploadOnlySession(featureFlags?.LIVEVIDEO, featureFlags?.ENABLESCREENSHARE, featureFlags?.UPLOADFILE);
            /* check notes tab available or not */
            if (sessionInfo?.notesCount > 0) {
                setShowNotesTab(true);
            } else if (isUploadOnlySessionValue) {
                setShowNotesTab(false);
            } else {
                setShowNotesTab(featureFlags?.SHOWMENOTES === "1")
            }

            /* check recording tab available or not */
            if (sessionInfo?.recordingCount > 0 || sessionInfo?.pendingRecordingCount > 0) {
                setShowRecordingTab(true);
            }
            else if (isUploadOnlySessionValue) {
                setShowRecordingTab(false);
            } else {
                setShowRecordingTab(featureFlags?.SHOWMERECORD !== "0")
            }

            /* check files tab available or not */
            if ((sessionInfo?.ocrCount + sessionInfo?.chatCount) > 0) {
                setShowFilesTab(true)
            } else {
                setShowFilesTab(!isUploadOnlySessionValue)
            }
        }
        if (props.sessionDetail?.getSessionDetailsData?.session?.sessionType === MEETING_TYPE.JOURNEY) {
            const request = { sessionId: meetingID };
            if(request.sessionId){
                props.getSessionImages(request, false, "details");
            }
        }
    }, [props.sessionDetail?.getSessionDetailsData])

    useEffect(() => {
        if (!props.endSessionData) return;
        const newDetails = { ...details };
        newDetails.isExpired = true;
        setDetails(newDetails);
    }, [props.endSessionData]);

    useEffect(() => {
        if (props.sessionDetail?.getSessionDetailsError) {
            if (props.sessionDetail?.getSessionDetailsError?.code === 1018) { // when selected session owner is not current user than redirect to session list page 
                CustomToast.info(props.sessionDetail?.getSessionDetailsError?.message);
                history.push(RouteName.SESSIONS);
                props.resetSessionDetails();
            }

        }
    }, [props.sessionDetail?.getSessionDetailsError])


    const handleBlockedNavigation = (nextLocation) => {
        setIsDiscardModalOpen(false);
      if (isDiscardChanged && isEmpty(currentActionType)) {
        setIsDiscardModalOpen(true);
        setCurrentPath(nextLocation);
        setCurrentActionType({ type: ACTIONS_LIST.BROWSER_BACK_BTN });
        return false;
      }
      return true;
    };

    const openCreateSessionModalByClick = () => {
        setShowCreateSessionModal(true);
        storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION);
    } 

    return (
        <>
        <Prompt when={isDiscardChanged} message={handleBlockedNavigation}/>
        <div className={clsx("session-right-side", params.sessionId ? 'active' : '')}>
            {!meetingID && <div className="session-welcome-box">
                <div className="session-welcome-text">
                    <h3>{localisedValues["hi"]} {parse(userData.firstName)} {parse(userData.lastName)}</h3>
                    <p>{localisedValues["getstarted_newsession"]}</p>
                    <div className="button-inline">
                        <div className="button">
                            <button type="button" className="btn btn-primary btn-sm" onClick={openCreateSessionModalByClick}>{localisedValues["new_session"]}</button>
                        </div>
                    </div>
                </div>
            </div>}
            {meetingID && <div className="session-detail">
                <div className="session-detail-top-row">
                    <div className="back-btn-div">
                        <button className="btn session-back-btn" onClick={() => backButtonClick()}>
                            <span className="btn-icon icon-previous"></span>
                            <span className="btn-text">{localisedValues["back"]}</span>
                        </button>
                    </div>
                    <div className="session-detail-top-right">
                        {!details && <div className="session-detail-top-loader">
                            <span className="for-icon icon-loader spin"></span>
                        </div>}
                        <div className="session-detail-top-code">
                            {params?.sessionId && formatSessionID(params?.sessionId)}
                        </div>
                    </div>
                </div>
                <div className="session-detail-tab-nav" data-testid='sessionDetailTabNav'>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {details?.session?.sessionType === MEETING_TYPE.JOURNEY && <li className="nav-item" role="presentation">
                            <button className={clsx("nav-link", details?.session?.sessionType === MEETING_TYPE.JOURNEY && "active")} data-bs-toggle="tab" data-bs-target="#session-responses"
                                type="button" role="tab" aria-selected="true">
                                <span className="nav-item-text">{localisedValues["responses"]}</span>
                            </button>
                        </li>}
                        <li className="nav-item" role="presentation">
                            <button className={clsx("nav-link", details?.session?.sessionType !== MEETING_TYPE.JOURNEY && "active")} data-bs-toggle="tab" data-bs-target="#session-detail"
                                type="button" role="tab" aria-selected="false">
                                <span className="nav-item-text">{localisedValues["details_key"]}</span>
                            </button>
                        </li>
                        {showNotesTab && <li className="nav-item" role="presentation">
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#session-note" data-testid='session-note-btn'
                                type="button" role="tab" aria-selected="false" onClick={() => { setIsNotesTabSelected(true) }}>
                                <span className="nav-item-text">{localisedValues["note_lable"]}</span>
                                <span className="countnumber">{details?.session?.notesCount}</span>
                            </button>
                        </li>}
                        <li className="nav-item" role="presentation" >
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#session-image" data-testid='session-image-btn'
                                type="button" role="tab" aria-selected="false" onClick={() => { setIsImagesTabSelected(true) }}>
                                <span className="nav-item-text">{localisedValues["images"]}</span>
                                <span className="countnumber">{details?.session?.imageCount}</span>
                            </button>
                        </li>
                        {showRecordingTab && <li className="nav-item" role="presentation">
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#session-recordings" data-testid='session-recordings-btn'
                                type="button" role="tab" aria-selected="false" onClick={() => setIsRecordingsTabSelected(true)}>
                                <span className="nav-item-text">{localisedValues["recordings"]}</span>
                                <span className="countnumber">{details?.session?.recordingCount}</span>
                            </button>
                        </li>}
                        {showFilesTab && <li className="nav-item" role="presentation">
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#session-file" data-testid='session-file-btn'
                                type="button" role="tab" aria-selected="false" onClick={() => setIsFilesTabSelected(true)}>
                                <span className="nav-item-text">{localisedValues["files_key"]}</span>
                                <span className="countnumber">{details?.session?.ocrCount + details?.session?.chatCount}</span>
                            </button>
                        </li>}
                    </ul>
                </div>
                <div className="tab-content">
                    {details &&
                        <ResponsesTab
                            fancyRef={fancyRef}
                            sessionType={details?.session?.sessionType}
                            journeySessionDetails={details}
                            imageData={props.sessionDetail?.getSessionImagesData?.data?.journeyImages}
                            videoData={props.sessionDetail?.getSessionImagesData?.data?.journeyVideos} />
                    }
                    <DetailsTab
                        ref={ref}
                        details={details}
                        meetingID={meetingID}
                        showRecordingTab={showRecordingTab}
                        setDetails={setDetails}
                        sessionsList={sessionsList}
                        setSessionsList={setSessionsList}
                        setIsDiscardModalOpen={setIsDiscardModalOpen}
                        isDiscardChanged={isDiscardChanged}
                        setIsDiscardChanged={setIsDiscardChanged}
                        setCurrentActionType={setCurrentActionType}
                        setIsRefrenceTitleChanged={setIsRefrenceTitleChanged}
                        isRefrenceTitleChanged={isRefrenceTitleChanged}
                        isSessionAssignChange={isSessionAssignChange}
                        setIsSessionAssignChange={setIsSessionAssignChange}
                        localisedValues={localisedValues}
                        nextPath={currentPath}
                        setIsDiscardModalSaveBtnDisabled={setIsDiscardModalSaveBtnDisabled} />
                    {isNotesTabSelected && <NotesTab meetingID={meetingID} />}
                    {isImagesTabSelected && <ImagesTab meetingID={meetingID} details={details} setDetails={setDetails} fancyRef={fancyRef} />}
                    {isFilesTabSelected && <FilesTab meetingID={meetingID} details={details} setDetails={setDetails} />}
                    {isRecordingsTabSelected && <RecordingsTab meetingID={meetingID} details={details} setDetails={setDetails} fancyRef={fancyRef} />}
                </div>
            </div >}
            {showCreateSessionModal && <CreateSessionModal closeModal={() => setShowCreateSessionModal(false)} />}
        </div >
        </>
    );
})

const mapStateToPropsSessionDetail = (state) => {
    return {
        sessionDetail: state.sessionDetail,
        endSessionData: state.sessionList.endSessionData,
        language: state.language
    }
}

const mapDispatchToPropsSessionDetail = (dispatch) => {
    return {
        getSessionDetails: (req) => dispatch(getSessionDetails(req)),
        getSessionImages: (req) => dispatch(getSessionImages(req)),
        resetSessionDetails: (req) => dispatch(resetSessionDetails(req))
    }
}

SessionDetail.propTypes = {
    sessionsList: PropTypes.array,
    backButtonClick: PropTypes.func,
    setSessionsList: PropTypes.func,
    setIsDiscardModalOpen: PropTypes.func,
    isDiscardChanged: PropTypes.bool,
    setIsDiscardChanged: PropTypes.func,
    isSessionAssignChange: PropTypes.bool,
    setIsSessionAssignChange: PropTypes.func,
    setIsRefrenceTitleChanged: PropTypes.func,
    isRefrenceTitleChanged: PropTypes.bool,
    setCurrentActionType: PropTypes.func,
    setIsDiscardModalSaveBtnDisabled: PropTypes.func,
    sessionDetail: PropTypes.object,
    endSessionData: PropTypes.object,
    language: PropTypes.object,
    getSessionDetails: PropTypes.func,
    getSessionImages: PropTypes.func,
};

export default connect(mapStateToPropsSessionDetail, mapDispatchToPropsSessionDetail, null, { forwardRef: true })(SessionDetail);