import { DefaultLanguage, JOURNEY_STEP_TYPES } from "../../../../../constants/Constants";
import PropTypes from 'prop-types';

const GoToStep = (props) => {
  if (props.dataObj.type === JOURNEY_STEP_TYPES.BUTTON && props.dataObj.props.buttons.length > 0) {
    return (
      <div className="step-info user-fill-info">
        <div className="button-control-list">
          {props.dataObj.props.buttons.map((button, index) => (
            <div key={index} className={`button-control-box`}>
              {(button.key === props.answerObj.go_to[0] || props.answerObj.redirect_to.includes(button.key)) &&
                <div className="button-click-icon">
                  <span className="for-icon icon-button-click"></span>
                </div>
              }
              <div className="button-control-title">
                <span>{button.text[props.currentLanguage] ? button.text[props.currentLanguage] : button.text[DefaultLanguage]}</span>
              </div>
              <div className="button-control-desc">
                {props.goToStepButtonActionName(button.type.name, button.key, props.answerObjID, props.localisedValues)}:
                <span> {props.addTitleOfRedirectionButton(button.type.value, button.value)}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

GoToStep.propTypes = {
  dataObj: PropTypes.object,
  answerObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  currentLanguage: PropTypes.string,
  localisedValues: PropTypes.object,
  answerObjID: PropTypes.string,
  goToStepButtonActionName: PropTypes.func,
  addTitleOfRedirectionButton: PropTypes.func,
}

export default GoToStep;
