import { APIs } from "../constants/Constants";
import api from "./api";

export default class ChangePasswordAPI {
  forgotPassword(Email) {
    return api.post(`${APIs.FORGOT_PASSWORD}?Email=${encodeURI(Email)}`, {});
  }
  recoverPassword(formData) {
    return api.post(APIs.RECOVER_PASSWORD, formData);
  }
}
