import __ from "lodash";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from 'prop-types';
import {
    DEFAULT_FILTER_VALUE, DEFAULT_SORT, DEFAULT_SORT_ORDER,
    SORT_DURATION, SORT_STARTTIME, LOCAL_STORAGE_KEYS, UPCOMING_SESSION_TYPE_VALUE,
    TWENTY_REDORDS_PER_PAGE, TUTORIAL_url, WHITELABLE_TUTORIAL_URL, ON_LOAD, RouteName, ACTIVE_SESSION_TYPE_VALUE, DATE_TIME_FORMAT_24_HOURS, ACTIONS_LIST
} from "../../../constants/Constants";
import { getSessions, getAllCountries, getSessionUsers, getOwnCPList, resetSessionUsers } from "../../../actions/sessionListAction";
import PublicVariables from "../../../constants/PublicVariables";
import { createdTimeDiff, getCurrentUser, isUploadOnlySession, scheduleTimeDiff } from "../../../shared/utility";
import { Localize } from "../Localize";
import storage from "../../../services/storage";
import SessionFilter from "./SessionFilter";
import { PopupModal } from "../../UI/Modal/Modal";
import CreateSessionModal from "../CreateSessionModal/CreateSessionModal";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import ScrollingList from "./ScrollingList";
import { arrayElementExistCheckWithKey } from "../../../shared/arrayExtension";
import history from "../../../history";


const SessionList = forwardRef((props, ref) => {

    const sessionCellRef = useRef()

    useImperativeHandle(ref, () => ({
        openCreateSessionModal() {
            handleClickOnNewSession(true)
        },
        handleInviteContact() {
            handleClickOnInviteContact(true)
        },
        changeSession(payload) {
            sessionCellRef.current.changeSession(payload)
        },
        handlInviteFromCell() {
            sessionCellRef.current.handlInviteFromCell()
        },
        handleJoinSessionCallFromCell(payload) {
            sessionCellRef.current.handleJoinSessionCall(payload)
        }
    }), [sessionCellRef.current]);

    const sortFilterRef = useRef();

    const { sessionsList, setSessionsList, setIsDiscardModalOpen, isDiscardChanged, setCurrentActionType } = props
    const [selectAllUsers, setSelectAllUsers] = useState(storage.has(LOCAL_STORAGE_KEYS.SELECT_ALL) ? storage.get(LOCAL_STORAGE_KEYS.SELECT_ALL) : false);
    const [selectedUsers, setSelectedUsers] = useState(storage.has(LOCAL_STORAGE_KEYS.SELECTED_USER_ID) ? storage.get(LOCAL_STORAGE_KEYS.SELECTED_USER_ID) : []);
    const [fetchingSessions, setFetchingSessions] = useState(true);


    /* const [subDomainUrl, setSubDomainUrl] = useState('');
     const [hostMappingUrl, setHostMappingUrl] = useState('');
     const [enableScheuduleSession, setEnableScheduleSession] = useState(false); */
    const [teamList, setTeamList] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState(storage.has(LOCAL_STORAGE_KEYS.SESSION_FILTER) ? storage.get(LOCAL_STORAGE_KEYS.SESSION_FILTER) : DEFAULT_FILTER_VALUE);// 1=All session, 2=Active sessions, 3=Upcoming sessions, 4=Closed sessons, 5=Archived sessions,
    const [sortBy, setSortBy] = useState((storage.has(LOCAL_STORAGE_KEYS.SESSION_SORT) && selectedFilter !== UPCOMING_SESSION_TYPE_VALUE) ? storage.get(LOCAL_STORAGE_KEYS.SESSION_SORT) : DEFAULT_SORT);
    const [sortOrder, setSortOrder] = useState((storage.has(LOCAL_STORAGE_KEYS.SESSION_SORT_ORDER) && selectedFilter !== UPCOMING_SESSION_TYPE_VALUE) ? storage.get(LOCAL_STORAGE_KEYS.SESSION_SORT_ORDER) : DEFAULT_SORT_ORDER);
    const [sessionSearchText, setSessionSearchText] = useState('');
    const [sessionPageNo, setSessionPageNo] = useState(0);//setting 0 as first page for first time component loading
    const [hasMoreSessions, setHasMoreSessions] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [localisedValues, setLocalisedValues] = useState({});

    /* const [referenceRequired, setReferenceRequired] = useState(false);
    const [referencePattern, setReferencePattern] = useState(''); */
    const [showSessionUserDropdown, setShowSessionUserDropdown] = useState(false);

    const fetchSessions = (pageNo) => {
        const selectedUserUcids = selectedUsers.map((user) => user.id); // send UCIDS array in the request body

        const request = {
            filter: selectedFilter,
            skip: pageNo, //session skip value,
            limit: TWENTY_REDORDS_PER_PAGE,
            search: sessionSearchText,  // search in a strings
            allUsers: selectAllUsers,  // selectall key
            userIds: selectedUserUcids, // selected user array send. if array empty then all session visible
            orderBy: sortOrder,
            orderColumn: sortBy,
        };

        props.getSessions(request);
    }

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
    }, [props.language.selectedlanguage]);

    useEffect(() => {
        if (!props.sessionList.getCountriesLoading && !props.sessionList.getCountriesData && !props.sessionList.getCountriesError){
            props.getAllCountries();
        }
        if (!props.sessionList.getOwnCPListLoading && !props.sessionList.getOwnCPListData)
            props.getOwnCPList(); 
        
        const fromSelfSignUp = storage.get(LOCAL_STORAGE_KEYS.FROM_SELF_SIGNUP);
        if (fromSelfSignUp == '1') {
            setShowModal("welcomeModal");
            storage.remove('fromSelfSignUp');
        }
    }, []);

    /* useEffect(() => {
        if(!__.isEmpty(PublicVariables.ldFlags)){
            setReferenceRequired(PublicVariables.ldFlags.referenceRequired);
            setReferencePattern(PublicVariables.ldFlags.referenceType === 0 ? "^[0-9]*$" : "");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PublicVariables.ldFlags]); */

    useDidMountEffect(() => {
        if (isDiscardChanged) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.CHANGE_FILTER })
        }
        if (props.sessionList.getSessionUsersData || !PublicVariables?.configurations?.sessionvisibilitypage) {
            resetListAndFetchAgain();
            storage.set(LOCAL_STORAGE_KEYS.SESSION_FILTER, selectedFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilter, sortBy, sortOrder, sessionSearchText]);

    useEffect(() => {
        const ownCPList = props.sessionList.getOwnCPListData;
        if (ownCPList?.items) {
            /* setSubDomainUrl(PublicVariables?.domainDataForLD?.domainUrl);
            setHostMappingUrl(PublicVariables?.domainDataForLD?.customHostMappingUrl); 
            setEnableScheduleSession(PublicVariables.configurations.sessionscheduling); */

            const teams = ownCPList.items.map((team) => {
                //due to mutation error, created copy of actual data and manupulate it.
                const teamCopy = __.cloneDeep(team);
                teamCopy.ISUPLOADONLY = isUploadOnlySession(teamCopy.configurations.LIVEVIDEO, teamCopy.configurations.ENABLESCREENSHARE, teamCopy.configurations.UPLOADFILE);
                return teamCopy;
            });

            setTeamList(teams);

            // PublicVariables.allowzendesk = smsCountriesData.ALLOWZENDESK;
            // PublicVariables.zendeskurl = smsCountriesData.ZENDESKURL;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sessionList.getOwnCPListData]);

    useEffect(() => {
        const sessionsData = props.sessionList.getSessionsData;
        const countriesData = props.sessionList.getCountriesData;
        //if sessionSettings LD flag is false than do not check with Users List/Data
        // const sessionUsersData = waitForUsers ? props.sessionList.getSessionUsersData : true;
        if (sessionsData && countriesData) {
            setSessionsList(parseSessions(sessionsData.items));
            setHasMoreSessions(sessionsData.items.length === TWENTY_REDORDS_PER_PAGE)
            setSessionPageNo(sessionPageNo + TWENTY_REDORDS_PER_PAGE);
            setFetchingSessions(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sessionList.getSessionsData, props.sessionList.getCountriesData]);

    useEffect(() => {
      const createSessionError = props.sessionList.createSessionsError;
      /*
      Hide create session modal
      when domain deactivated and
      password changed error modal shows
       */
      if (
        createSessionError &&
        (createSessionError.code === 1007 || //UserDeactivated
            createSessionError.code === 1038 || //UserAutoDeactivate
            createSessionError.code === 1039 || //UserPasswordExpire
            createSessionError.code === 1003 || //DomainDeactivated
            createSessionError.code === 1012 || //UserLogoutAdminChangePassword
            createSessionError.code === 1011 || //UserLogoutPasswordChange
            createSessionError.code === 1013 || //UserLogoutAdminChangeRole
            createSessionError.code === 1040 || //UserLogoutAdminChangedLoginId - update email id from admin portal
            createSessionError.code === 1010 || // logout all from admin portal
            createSessionError.code === 1004 ) // trial expired view
      ) {
        setShowModal(false);
      }
    }, [props.sessionList.createSessionsError]);

    useEffect(() => {
        const sessionUsersData = props.sessionList.getSessionUsersData;
        if (sessionUsersData && !props.sessionList.getSessionUsersLoading) {
            if (sessionUsersData.req.INITIATE === ON_LOAD) {
                if (sessionUsersData.list.length === 0) {  // handle on load if session user data list length is empty
                    const user = getCurrentUser();
                    const selectedUsers = [{ id: user.userId, name: user.firstName }];
                    storage.set(LOCAL_STORAGE_KEYS.SELECTED_USER_ID, selectedUsers)
                    storage.set(LOCAL_STORAGE_KEYS.SELECT_ALL, false)
                    setShowSessionUserDropdown(false);  // hide select user dropdown if session user data list length is empty
                } else {
                    setShowSessionUserDropdown(true);  // show select user dropdown if session user data list length is not empty
                }
                setSelectedUsers(selectedUsers);
            }
            resetListAndFetchAgain()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sessionList.getSessionUsersData])

    useEffect(() => {
        if (!props.sessionList.archiveSessionData) return;
        const newSessionList = [...sessionsList];
        const objIndex = newSessionList.findIndex((obj => obj.sessionId === props.sessionList.archiveSessionData.sessionId));
        if (objIndex != -1) {
            newSessionList.splice(objIndex, 1);
            setSessionsList(newSessionList);
        }
    }, [props.sessionList.archiveSessionData]);

    useEffect(() => {
        if (!props.sessionList.endSessionData) return;
        console.log("in the session list");
        const newSessionList = [...sessionsList];
        const objIndex = newSessionList.findIndex((obj => obj.sessionId === props.sessionList.endSessionData.sessionId));
        console.error(objIndex);
        if (objIndex != -1) {
            newSessionList[objIndex].isExpired = true;
            if (selectedFilter == ACTIVE_SESSION_TYPE_VALUE || selectedFilter == UPCOMING_SESSION_TYPE_VALUE) {
                newSessionList.splice(objIndex, 1);
            }
            setSessionsList(newSessionList);
        }
    }, [props.sessionList.endSessionData]);

    useDidMountEffect(() => {
        if (isDiscardChanged) {
            setIsDiscardModalOpen(true)
            setCurrentActionType({ type: ACTIONS_LIST.SELECT_USER })
        }
        resetListAndFetchAgain();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectAllUsers, selectedUsers]);

    const resetListAndFetchAgain = (fetchAgain = true) => {
        setSessionsList([]);
        setFetchingSessions(true);
        setSessionPageNo(0); //set to first page on changing filter or sort
        setHasMoreSessions(false);
        if (fetchAgain) {
            fetchSessions(0);
        }
    }

    const fetchMoreSessions = () => {
        fetchSessions(sessionPageNo);
    }

    //sessionList.getSessionsData.SESSIONLIST.0.STARTTIME
    const parseSessions = (sessions) => {
        if (!selectAllUsers && __.isEmpty(selectedUsers)) {
            return []
        }
        const sessionsListCopy = __.cloneDeep(sessionsList);
        sessions.map((value) => {
            if (!arrayElementExistCheckWithKey(sessionsListCopy, "sessionId", value.sessionId)) {
                //due to mutation error, created copy of actual data and manupulate it.
                const sessionCopy = __.cloneDeep(value);
                const lang = PublicVariables.currentLanguage.toLowerCase()
                const convertedTime = moment.utc(sessionCopy.createdAt, DATE_TIME_FORMAT_24_HOURS).locale(lang)
                sessionCopy.CONVERTEDTIME = createdTimeDiff(convertedTime) === true ? convertedTime.format('MMM DD, YYYY') : createdTimeDiff(convertedTime, localisedValues);

                if (sessionCopy.startAt) {
                    const startTime = moment.utc(sessionCopy.startAt, DATE_TIME_FORMAT_24_HOURS).locale(lang);
                    sessionCopy.STARTTIME = startTime;
                    sessionCopy.CONVERTEDSCHEDULETIME = scheduleTimeDiff(startTime.local());
                }

                if (sessionCopy.endAt) {
                    const endTime = moment.utc(sessionCopy.endAt, DATE_TIME_FORMAT_24_HOURS);
                    sessionCopy.ENDTIME = endTime;
                    sessionCopy.CONVERTEDENDTIME = scheduleTimeDiff(endTime.local());
                }

                const selectedSessionTeam = teamList.length > 0 ? teamList.find(team => team.collaborationProfileId === sessionCopy.collaborationProfileId) : undefined;
                sessionCopy.ISUPLOADONLY = (selectedSessionTeam !== undefined) ? selectedSessionTeam.ISUPLOADONLY : false;

                sessionsListCopy.push(sessionCopy);
            }
        })

        return sessionsListCopy;
    }

    const updateSortBy = (updatedSortBy) => {
        if (updatedSortBy !== sortBy) {
            setSortBy(updatedSortBy);
        }
    }

    const updateSortOrder = (updatedSortOrder) => {
        if (updatedSortOrder !== sortOrder) {
            setSortOrder(updatedSortOrder);
        }
    }

    const selectAllChangeHandler = (isSelectAll) => {
        setSelectAllUsers(isSelectAll);
        setSelectedUsers([]);
    }

    const selectedUsersChangeHandler = (usersArray) => {
        setSelectedUsers(usersArray);
    }

    const fetchSessionsWithoutUsers = (usersArray) => {
        if (!__.isEqual(selectedUsers, usersArray)) {
            setSelectedUsers(usersArray);
        }
        else {
            resetListAndFetchAgain();
        }
    }

    const statusChangeHandler = (value) => {
        setSelectedFilter(value);
        if ((value !== UPCOMING_SESSION_TYPE_VALUE && sortBy === SORT_STARTTIME) ||
            (value === UPCOMING_SESSION_TYPE_VALUE && sortBy === SORT_DURATION)) {
            setSortBy(DEFAULT_SORT);
            setSortOrder(DEFAULT_SORT_ORDER);
            sortFilterRef.current.setDefaultSort();
        }
    }

    const gotoViewTutorial = function () {
        window.open(PublicVariables.ldFlags.helpView ? TUTORIAL_url : WHITELABLE_TUTORIAL_URL, '_blank');
    }

    /* 
        - clear user array
        - on clear state variable, resetListAndFetchAgain function will be called on useDidMountEffect method
    */
    const handleClearSelectedUserButton = function () {
        setSelectedUsers([]);
    }

    const handleClickOnNewSession = (fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setCurrentActionType({ type: ACTIONS_LIST.CREATE_SESSION })
            setIsDiscardModalOpen(true)
            return
        }

        setShowModal("createNewSessionModal")
    }


    const handleClickOnInviteContact = (fromParent = false) => {
        if (isDiscardChanged && !fromParent) {
            setCurrentActionType({ type: ACTIONS_LIST.INVITE_CONTACT })
            setIsDiscardModalOpen(true)
            return
        }
        history.push({
            pathname: RouteName.CREATE_SESSION,
            state: { isInviteFromSession: true },
        });
        
    }

    return (
        <>
            <div className="session-sidebar" ref={ref}>
                <SessionFilter
                    isDiscardChanged={isDiscardChanged}
                    updateSortBy={updateSortBy}
                    updateSortOrder={updateSortOrder}
                    selectAllChangeHandler={selectAllChangeHandler}
                    selectedUsersChangeHandler={selectedUsersChangeHandler}
                    fetchSessionsWithoutUsers={fetchSessionsWithoutUsers}
                    searchTextChangeHandler={setSessionSearchText}
                    statusChangeHandler={statusChangeHandler}
                    sortFilterRef={sortFilterRef}
                    selectedFilter={selectedFilter}
                    showSessionUserDropdown={showSessionUserDropdown}  // show session assigned userd ropdown or not variable (based on sessionVisibility setting)
                    clearSelectedUserInSessionList={handleClearSelectedUserButton}   // clear user array if session assigned dropdown clear button clicked
                    localisedValues={localisedValues}
                />
                <ScrollingList
                    ref={sessionCellRef}
                    setCurrentActionType={setCurrentActionType}
                    isDiscardChanged={isDiscardChanged}
                    setIsDiscardModalOpen={setIsDiscardModalOpen}
                    sessionsList={sessionsList}
                    fetchingSessions={fetchingSessions}
                    sessionPageNo={sessionPageNo}
                    fetchMoreSessions={fetchMoreSessions}
                    canFetchMoreSession={hasMoreSessions}
                    localisedValues={localisedValues}
                />
                <div className="session-sidebar-footer">
                    <div className="session-sidebar-footer-row">
                        <div className={clsx("dropdown new-session-dropdown", !PublicVariables.ldFlags?.inviteBuddy && "invite-contact-off")} >
                            <button className="btn btn-primary btn-first" type="button" data-testid="newSessionButton" data-bs-target="#new-session-modal" onClick={() => handleClickOnNewSession()}>
                                {localisedValues["new_session"]}
                            </button>
                            {PublicVariables.ldFlags?.inviteBuddy && <button className="btn btn-primary dropdown-toggle btn-last triangle-none new-session-dropdown-btn"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="triangle-icon icon-down-triangle-round"></span>
                            </button>}
                            <div className="dropdown-menu new-session-dropdown-menu right" aria-labelledby="dropdown">
                                <div className="dropdown-menu-in">
                                    <ul>
                                        <li>
                                            <button className="btn dropdown-item-box" data-testid="inviteContactButton" onClick={() => { handleClickOnInviteContact() }}>
                                                <span className="dropdown-item-icon icon-invite-contact"></span>
                                                <span className="dropdown-item-text">{localisedValues["invite_contact"]}</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal === "welcomeModal" &&
                <PopupModal
                    closeModal={() => { setShowModal(false); }}
                    modalClass="modal-small no-header-modal"
                    dialogClass="modal-dialog-centered"
                    closeButton={true}
                    bodyClass="padding-top-0"
                    bodyContent={
                        <div className="cms-modal-text welcome-modal-box">
                            <div className="welcome-msg-head">
                                <span className="for-icon icon-check-circle"></span>
                                {localisedValues["welcome_modal_title"]}
                            </div>
                            <p>{localisedValues["welcome_modal_msg"]}</p>
                        </div>
                    }
                    backdrop='static'
                    footerClass="justify-content-center"
                    footerContent={
                        <div className="button-inline d-flex">
                            <div className="button">
                                <button type="button" className="btn btn-light-primary font-weight-500"
                                    onClick={() => { setShowModal("createNewSessionModal"); }}>{localisedValues["get_started"]}</button>
                            </div>
                            <div className="button">
                                <button type="button" className="btn btn-primary font-weight-500" onClick={gotoViewTutorial}>{localisedValues["view_tutorial"]}</button>
                            </div>
                        </div>
                    }
                />
            }
            {showModal === "createNewSessionModal" &&
                <CreateSessionModal
                    closeModal={() => { setShowModal(false); }}
                />
            }
        </>
    );
})


const mapStateToProps = (state) => {
    return {
        language: state.language,
        sessionList: state.sessionList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSessions: (req) => dispatch(getSessions(req)),
        getSessionUsers: (req) => dispatch(getSessionUsers(req)),
        getAllCountries: () => dispatch(getAllCountries()),
        getOwnCPList: () => dispatch(getOwnCPList()),
        resetSessionUsers: () => dispatch(resetSessionUsers()),
    }
}


SessionList.propTypes = {
    sessionList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    sessionsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setSessionsList: PropTypes.func,
    setIsDiscardModalOpen: PropTypes.func,
    isDiscardChanged: PropTypes.bool,
    setCurrentActionType: PropTypes.func,
    language: PropTypes.object,
    getSessions: PropTypes.func,
    getSessionUsers: PropTypes.func,
    getAllCountries: PropTypes.func,
    getOwnCPList: PropTypes.func,
    resetSessionUsers: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SessionList);