import PropTypes from 'prop-types';

const PaginationSearch = ({ searchHelper }) => {
  return (
    <div
      className="search-box invite-contact-search form-group"
      data-testid="searchBar"
    >
      <span className="frm-search-icon icon-search"></span>
      <input
        type="text"
        value={searchHelper.search}
        className="form-control form-control-grey height-46 focus-font-bold"
        placeholder={searchHelper.localisedValues["search"]}
        onChange={(e) => searchHelper.setSearch(e.target.value)}
      />
    </div>
  );
};

PaginationSearch.propTypes = {
  searchHelper: PropTypes.object,
};

export default PaginationSearch;
