export const EVENTS = {
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RECOVER_PASSWORD: "RECOVER_PASSWORD",
};

export const FORGOT_PASSWORD = {
  START: "FORGOT_PASSWORD_START",
  SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  ERROR: "FORGOT_PASSWORD_ERROR",
};

const defaultStateForForgotPassword = {
  forgotPasswordError: false,
  forgotPasswordLoading: false,
  forgotPasswordData: false,
};

export const RECOVER_PASSWORD = {
  START: "RECOVER_PASSWORD_START",
  SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  ERROR: "RECOVER_PASSWORD_ERROR",
};

const defaultRecoverPasswordState = {
  recoverPasswordLoading: false,
  recoverPasswordData: false,
  recoverPassworError: false,
};

export const defaultState = {
  ...defaultStateForForgotPassword,
  ...defaultRecoverPasswordState,
};

const changePasswordReducer = (state = defaultState, action = {}) => {
  switch (action.event) {
    case EVENTS.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordLoading:
          action.type === FORGOT_PASSWORD.START,
        forgotPasswordData:
          action.type === FORGOT_PASSWORD.SUCCESS ? action.payload : false,
        forgotPasswordError:
          action.type === FORGOT_PASSWORD.ERROR ? action.payload : false,
      };
    case EVENTS.RECOVER_PASSWORD:
      return {
        ...state,
        recoverPasswordLoading:
          action.type === RECOVER_PASSWORD.START,
        recoverPasswordData:
          action.type === RECOVER_PASSWORD.SUCCESS ? action.payload : false,
        resetPassworError:
          action.type === RECOVER_PASSWORD.ERROR ? action.payload : false,
      };
    default:
      return state;
  }
};

export default changePasswordReducer;
