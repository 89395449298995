import React from 'react'
import { FormikInputWithIcon } from '../UI/TextInput/FormikInputWithIcon'
import clsx from 'clsx'
import PropTypes from 'prop-types';

export const CountryStateDropDown = ({ selectedCountryCode, selectedCountryName, noCountrySelectedText, formik, ...otherProps }) => {

    return (
        <div className="row">
            <div className="col-lg-6 col-md-6">
                <div className="form-group">
                    <div className="dropdown dropdown-box country-selection">
                        <button
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-btn triangle-none"
                            type="button"
                            onClick={() => otherProps.handleSetSelectCountry(formik.setFieldValue)} id="country-selection"
                            data-testid="country-selection"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {selectedCountryCode && <span className={`for-flag ${selectedCountryCode.toLowerCase()}`}></span>}
                            <span className="for-text">{selectedCountryCode ? selectedCountryName : noCountrySelectedText}</span>
                            <span className="dropdown-toggle-arrow icon-down-triangle-round"></span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-list" aria-labelledby="country-selection">
                            <div className="dropdown-menu-in">
                                <div className="search-form sm">
                                    <div className="form-group">
                                        <span className="for-icon icon-search"></span>
                                        <FormikInputWithIcon
                                            id="countryNameSearchId"
                                            type="text"
                                            className="form-control"
                                            placeholder='Search'
                                            touched={formik.touched?.countryNameSearch}
                                            error={formik.errors?.countryNameSearch}
                                            onChange={e => otherProps.setCountrySearchValue(e.target.value)}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="country-selection-list custom-scroll-div-thin">
                                    <ul>
                                        {!otherProps.countrySearchValue && <li>
                                            <div className="no-search-found">
                                                {noCountrySelectedText}
                                            </div>
                                        </li>}
                                        {otherProps.filteredCountryBasedOnSearch.map((data, index) => {
                                            return (
                                                <li key={index}>
                                                    <button className='btn available-country-btn' type='button' onClick={() => otherProps.handleCountryChanged(data.CODE)} >
                                                        <span className={clsx('for-flag', data?.CODE?.toLowerCase())}></span>
                                                        <span className="for-text">{data.NAME}</span>
                                                    </button>
                                                </li>
                                            )
                                        })}
                                        {(otherProps.filteredCountryBasedOnSearch.length < 1 && otherProps.countrySearchValue !== '') && <li>
                                            <div className="no-search-found">
                                                <strong>{otherProps.noSearchFound}</strong>
                                            </div>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="form-group">
                    <div className="dropdown dropdown-box">
                        <button
                            className="btn btn-secondary dropdown-toggle dropdown-toggle-btn triangle-none"
                            type="button"
                            onClick={() => otherProps.handleSetSelectState(formik.setFieldValue)}
                            id="state-selection"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <span className="for-text">{otherProps.selectedStateName}</span>
                            <span className="dropdown-toggle-arrow icon-down-triangle-round"></span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-list" aria-labelledby="state-selection">
                            <div className="dropdown-menu-in">
                                <div className="search-form sm">
                                    <div className="form-group">
                                        <span className="for-icon icon-search"></span>
                                        <FormikInputWithIcon
                                            id="stateNameSearchId"
                                            type="text"
                                            className="form-control"
                                            placeholder='Search'
                                            touched={formik.touched?.stateNameSearch}
                                            error={formik.errors?.stateNameSearch}
                                            onChange={e => otherProps.setStateSearchValue(e.target.value)}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                                <div className="country-selection-list custom-scroll-div-thin">
                                    <ul>
                                        {!otherProps.stateSearchValue &&
                                            <li>
                                                <div className="no-search-found">
                                                    {otherProps.noStateSelectedText}
                                                </div>
                                            </li>
                                        }
                                        {otherProps.filteredStateBasedOnSearch?.map((data, index) => {
                                            return (
                                                <li key={index}>
                                                    <button className='btn available-country-btn' type='button' onClick={() => otherProps.setSelectedStateName(data)} >
                                                        <span className="for-text">{data}</span>
                                                    </button>
                                                </li>
                                            )
                                        })
                                        }
                                        {(otherProps.filteredStateBasedOnSearch.length < 1 && otherProps.stateSearchValue !== '') &&
                                            <li>
                                                <div className="no-search-found">
                                                    <strong> {otherProps.noSearchFound} </strong>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

CountryStateDropDown.propTypes = {
    selectedCountryCode: PropTypes.string,
    selectedCountryName: PropTypes.string,
    noCountrySelectedText: PropTypes.string,
    formik: PropTypes.object,
}