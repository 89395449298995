import clsx from "clsx";
import PropTypes from 'prop-types';
import { CONTACTSPERPAGE } from "../../../../constants/Constants";

const PaginationSize = ({ paginationProps }) => {
  const {
    sizePerPageList,
    sizePerPage,
    totalSize,
    onSizePerPageChange = CONTACTSPERPAGE,
  } = paginationProps;

  const onSizeChange = (newSize) => {
    onSizePerPageChange(newSize);
  };

  return (
    <div className="page-result-size">
      <div className="first-label">Show</div>
      <div className="dropdown page-result-dropdown top-dropdown">
        <button
          className="btn dropdown-toggle triangle-none btn-sm"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          disabled={totalSize === 0}
        >
          <span className="btn-text">{sizePerPage}</span>
          <span className="dropdown-toggle-arrow icon-arrow-down"></span>
        </button>
        <div className="dropdown-menu">
          <div className="dropdown-menu-in form-type-list custom-scroll-div-thin">
            <ul>
              {sizePerPageList.map((option) => {
                const isSelect = sizePerPage === option.value;
                return (
                  <li key={option.value}>
                    <button
                      className={clsx(
                        "btn dropdown-item",
                        isSelect && "active"
                      )}
                      onClick={() => onSizeChange(option.value)}
                    >
                      <span className="dropdown-item-text">{option.text}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="last-label">rows</div>
    </div>
  );
};

PaginationSize.propTypes = {
  paginationProps: PropTypes.object,
};

export default PaginationSize;
