import { APIs } from "../constants/Constants";
import api from "./api";

export default class SelfSignupAPI {
    postVerifyEmail(email) {
        return api.post(APIs.SELF_SIGNUP.GET_STARTED_VERIFICATION_CODE + encodeURIComponent(email));
    }

    postCheckOTP(formData) {
        return api.post(APIs.SELF_SIGNUP.CHECK_OTP, formData);
    }

    postCheckCompany(req) {
        return api.get( APIs.ISCOMPANYAVAILABLE + encodeURIComponent(req.domainURL), req);
    }

    postCreateCompany(formData) {
        return api.post(APIs.SELF_SIGNUP.COMPANY_TRIAL, formData);
    }

    postInviteUser(formData) {
        return api.post(APIs.SELF_SIGNUP.INVITE_USER, formData);
    }

}
