export const Localize = {
    "en_GB": {
        "step_2_of_2": "Step 2 of 2",
        "share_invite": "Share Invite",
        "share_invite_with": "Share Invite with {0}",
        "enter_mobile_number": "Enter Mobile Number",
        "enter_valid_mobile": "Enter valid mobile number.",
        "mobile_number": "Mobile Number",
        "guest": "Guest",
        "silent_observer": "Silent Observer",
        "silent_observer_tooltip": "Microphone and camera will be muted for Silent Observer, and they will not be visible to guest participants.",
        "join": "Join",
        "invite": "Invite",
        "invite_and_join": "Invite and Join",
        "invite_by_email": "Invite by Email",
        "enter_email_send_invite": "Enter Email to Send an Invite",
        "new_email_address": "Email address",
        "enter_valid_email": "Enter valid email address.",
        "preview_email": "Preview email",
        "sender_email": "Sender Email",
        "sender_name": "Sender Name",
        "subject": "Subject",
        "body": "Body",
        "close": "Close",
        "smartglass_disacription": "Smartglass Participant ID should be provided by the Smartglass User and entered below.",
        "share_invitation_with_others": "Share Session Invitation with others",
        "copy_invitation_desc": "Copy the invitation text with scheduled time and send it manually",
        "copy_session_code": "Copy session code",
        "copy_link_to_share": "Copy link to share",
        "copy_invitation": "Copy invitation",
        "invite_sent_successfully": "Invite sent successfully",
        "join_call": "Join Call",
        "other": "Other",
        "sms_sent_label": "SMS Sent!",
        "whatsapp_sent_label": "WhatsApp Invitation Sent!",
        "email_sent_label": "Email Sent!",
        "smartglass_sent_label": "Smartglass Invitation Sent!",
        "copied": "Copied!",
        "session_invitation_copied": "Session Invitation copied to clipboard.",
        "session_code_copy": "Session code copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "More",
        "send_link": "Send Link",
        "recipients_can_upload_files": "Recipients can now upload files."
    },
    "de_DE": {
        "step_2_of_2": "Schritt 2 von 2",
        "share_invite": "Einladung teilen",
        "share_invite_with": "Einladung mit {0} teilen",
        "enter_mobile_number": "Geben Sie die Handynummer ein",
        "enter_valid_mobile": "Geben Sie eine gültige Mobilfunknummer ein.",
        "mobile_number": "Handynummer",
        "guest": "Gast",
        "silent_observer": "Stiller Beobachter",
        "silent_observer_tooltip": "Mikrofon und Kamera werden für Silent Observer stummgeschaltet und sind für Gastteilnehmer nicht sichtbar.",
        "join": "Beitreten",
        "invite": "Einladen",
        "invite_and_join": "Einladen und Mitglied werden",
        "invite_by_email": "Einladung per E-Mail",
        "enter_email_send_invite": "Geben Sie E-Mail ein, um eine Einladung zu senden",
        "new_email_address": "E-Mail-Addresse",
        "enter_valid_email": "Geben Sie eine gültige E-Mail-Adresse ein.",
        "preview_email": "E-Mail-Vorschau",
        "sender_email": "Absender E-Mail",
        "sender_name": "Absender",
        "subject": "Thema",
        "body": "Körper",
        "close": "Schließen",
        "smartglass_disacription": "Die Smartglass-Teilnehmer-ID sollte vom Smartglass-Benutzer bereitgestellt und unten eingegeben werden.",
        "share_invitation_with_others": "Sitzungseinladung mit anderen teilen",
        "copy_invitation_desc": "Kopieren Sie den Einladungstext mit der geplanten Zeit und senden Sie ihn manuell",
        "copy_session_code": "Sitzungscode kopieren",
        "copy_link_to_share": "Kopiere den Link zum Teilen",
        "copy_invitation": "Einladung kopieren",
        "invite_sent_successfully": "Verschickt Einladung erfolgreich",
        "join_call": "Call beitreten",
        "other": "Andere",
        "sms_sent_label": "SMS gesendet!",
        "whatsapp_sent_label": "WhatsApp-Einladung gesendet!",
        "email_sent_label": "E-Mail gesendet!",
        "smartglass_sent_label": "Smartglass-Einladung gesendet!",
        "copied": "Kopiert!",
        "session_invitation_copied": "Sitzungseinladung in Zwischenablage kopiert.",
        "session_code_copy": "Sitzungscode in die Zwischenablage kopiert.",
        "guest_url_copy": "Gast-URL in die Zwischenablage kopiert.",
        "observer_url_copied": "Beobachter-URL in Zwischenablage kopiert.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "Mehr",
        "send_link": "Link senden",
        "recipients_can_upload_files": "Empfänger können jetzt Dateien hochladen."
    },
    "en_US": {
        "step_2_of_2": "Step 2 of 2",
        "share_invite": "Share Invite",
        "share_invite_with": "Share Invite with {0}",
        "enter_mobile_number": "Enter Mobile Number",
        "enter_valid_mobile": "Enter valid mobile number.",
        "mobile_number": "Mobile Number",
        "guest": "Guest",
        "silent_observer": "Silent Observer",
        "silent_observer_tooltip": "Microphone and camera will be muted for Silent Observer, and they will not be visible to guest participants.",
        "join": "Join",
        "invite": "Invite",
        "invite_and_join": "Invite and Join",
        "invite_by_email": "Invite by Email",
        "enter_email_send_invite": "Enter Email to Send an Invite",
        "new_email_address": "Email address",
        "enter_valid_email": "Enter valid email address.",
        "preview_email": "Preview email",
        "sender_email": "Sender Email",
        "sender_name": "Sender Name",
        "subject": "Subject",
        "body": "Body",
        "close": "Close",
        "smartglass_disacription": "Smartglass Participant ID should be provided by the Smartglass User and entered below.",
        "share_invitation_with_others": "Share Session Invitation with others",
        "copy_invitation_desc": "Copy the invitation text with scheduled time and send it manually",
        "copy_session_code": "Copy session code",
        "copy_link_to_share": "Copy link to share",
        "copy_invitation": "Copy invitation",
        "invite_sent_successfully": "Invite sent successfully",
        "join_call": "Join Call",
        "other": "Other",
        "sms_sent_label": "SMS Sent!",
        "whatsapp_sent_label": "WhatsApp Invitation Sent!",
        "email_sent_label": "Email Sent!",
        "smartglass_sent_label": "Smartglass Invitation Sent!",
        "copied": "Copied!",
        "session_invitation_copied": "Session Invitation copied to clipboard.",
        "session_code_copy": "Session code copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "More",
        "send_link": "Send Link",
        "recipients_can_upload_files": "Recipients can now upload files."
    },
    "en_AU": {
        "step_2_of_2": "Step 2 of 2",
        "share_invite": "Share Invite",
        "share_invite_with": "Share Invite with {0}",
        "enter_mobile_number": "Enter Mobile Number",
        "enter_valid_mobile": "Enter valid mobile number.",
        "mobile_number": "Mobile Number",
        "guest": "Guest",
        "silent_observer": "Silent Observer",
        "silent_observer_tooltip": "Microphone and camera will be muted for Silent Observer, and they will not be visible to guest participants.",
        "join": "Join",
        "invite": "Invite",
        "invite_and_join": "Invite and Join",
        "invite_by_email": "Invite by Email",
        "enter_email_send_invite": "Enter Email to Send an Invite",
        "new_email_address": "Email address",
        "enter_valid_email": "Enter valid email address.",
        "preview_email": "Preview email",
        "sender_email": "Sender Email",
        "sender_name": "Sender Name",
        "subject": "Subject",
        "body": "Body",
        "close": "Close",
        "smartglass_disacription": "Smartglass Participant ID should be provided by the Smartglass User and entered below.",
        "share_invitation_with_others": "Share Session Invitation with others",
        "copy_invitation_desc": "Copy the invitation text with scheduled time and send it manually",
        "copy_session_code": "Copy session code",
        "copy_link_to_share": "Copy link to share",
        "copy_invitation": "Copy invitation",
        "invite_sent_successfully": "Invite sent successfully",
        "join_call": "Join Call",
        "other": "Other",
        "sms_sent_label": "SMS Sent!",
        "whatsapp_sent_label": "WhatsApp Invitation Sent!",
        "email_sent_label": "Email Sent!",
        "smartglass_sent_label": "Smartglass Invitation Sent!",
        "copied": "Copied!",
        "session_invitation_copied": "Session Invitation copied to clipboard.",
        "session_code_copy": "Session code copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "More",
        "send_link": "Send Link",
        "recipients_can_upload_files": "Recipients can now upload files."
    },
    "es_ES": {
        "step_2_of_2": "Paso 2 de 2",
        "share_invite": "Compartir Invitar",
        "share_invite_with": "Compartir invitación con {0}",
        "enter_mobile_number": "Ingrese el número de celular",
        "enter_valid_mobile": "Ingrese un numero de móvil valido.",
        "mobile_number": "Número de teléfono móvil",
        "guest": "Huésped",
        "silent_observer": "Observador silencioso",
        "silent_observer_tooltip": "El micrófono y la cámara se silenciarán para Silent Observer y no serán visibles para los participantes invitados.",
        "join": "Unirse",
        "invite": "Invitación",
        "invite_and_join": "Invitar y unirse",
        "invite_by_email": "Invitar por correo electrónico",
        "enter_email_send_invite": "Ingrese el correo electrónico para enviar una invitación",
        "new_email_address": "Dirección de correo electrónico",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "preview_email": "Vista previa de correo electrónico",
        "sender_email": "Correo electrónico del remitente",
        "sender_name": "Nombre del remitente",
        "subject": "Tema",
        "body": "Cuerpo",
        "close": "Cerrar",
        "smartglass_disacription": "La identificación del participante de Smartglass debe ser proporcionada por el usuario de Smartglass e ingresada a continuación.",
        "share_invitation_with_others": "Compartir la invitación a la sesión con otros",
        "copy_invitation_desc": "Copie el texto de la invitación con la hora programada y envíelo manualmente",
        "copy_session_code": "Copiar código de sesión",
        "copy_link_to_share": "Copiar enlace para compartir",
        "copy_invitation": "Copiar invitación",
        "invite_sent_successfully": "Invitar enviado con éxito",
        "join_call": "Unirse a la llamada",
        "other": "Otro",
        "sms_sent_label": "SMS enviado!",
        "whatsapp_sent_label": "¡Invitación de WhatsApp enviada!",
        "email_sent_label": "¡Email enviado!",
        "smartglass_sent_label": "¡Invitación Smartglass enviada!",
        "copied": "Copiado!",
        "session_invitation_copied": "Se copió la invitación a la sesión en el portapapeles.",
        "session_code_copy": "Código de sesión copiado al portapapeles.",
        "guest_url_copy": "Invitado URL copiado al portapapeles.",
        "observer_url_copied": "URL del observador copiada al portapapeles.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "Más",
        "send_link": "Enviar enlace",
        "recipients_can_upload_files": "Los destinatarios ahora pueden cargar archivos."
    },
    "es_MX": {
        "step_2_of_2": "Paso 2 de 2",
        "share_invite": "Compartir Invitar",
        "share_invite_with": "Compartir invitación con {0}",
        "enter_mobile_number": "Ingrese el número de celular",
        "enter_valid_mobile": "Ingrese un numero de móvil valido.",
        "mobile_number": "Número de teléfono móvil",
        "guest": "Huésped",
        "silent_observer": "Observador silencioso",
        "silent_observer_tooltip": "El micrófono y la cámara se silenciarán para Silent Observer y no serán visibles para los participantes invitados.",
        "join": "Unirse",
        "invite": "Invitación",
        "invite_and_join": "Invitar y unirse",
        "invite_by_email": "Invitar por correo electrónico",
        "enter_email_send_invite": "Ingrese el correo electrónico para enviar una invitación",
        "new_email_address": "Dirección de correo electrónico",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "preview_email": "Vista previa de correo electrónico",
        "sender_email": "Correo electrónico del remitente",
        "sender_name": "Nombre del remitente",
        "subject": "Tema",
        "body": "Cuerpo",
        "close": "Cerrar",
        "smartglass_disacription": "La identificación del participante de Smartglass debe ser proporcionada por el usuario de Smartglass e ingresada a continuación.",
        "share_invitation_with_others": "Compartir la invitación a la sesión con otros",
        "copy_invitation_desc": "Copie el texto de la invitación con la hora programada y envíelo manualmente",
        "copy_session_code": "Copiar código de sesión",
        "copy_link_to_share": "Copiar enlace para compartir",
        "copy_invitation": "Copiar invitación",
        "invite_sent_successfully": "Invitar enviado con éxito",
        "join_call": "Unirse a la llamada",
        "other": "Otro",
        "sms_sent_label": "SMS enviado!",
        "whatsapp_sent_label": "¡Invitación de WhatsApp enviada!",
        "email_sent_label": "¡Email enviado!",
        "smartglass_sent_label": "¡Invitación Smartglass enviada!",
        "copied": "Copiado!",
        "session_invitation_copied": "Se copió la invitación a la sesión en el portapapeles.",
        "session_code_copy": "Código de sesión copiado al portapapeles.",
        "guest_url_copy": "Invitado URL copiado al portapapeles.",
        "observer_url_copied": "URL del observador copiada al portapapeles.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "Más",
        "send_link": "Enviar enlace",
        "recipients_can_upload_files": "Los destinatarios ahora pueden cargar archivos."
    },
    "es_AR": {
        "step_2_of_2": "Paso 2 de 2",
        "share_invite": "Compartir Invitar",
        "share_invite_with": "Compartir invitación con {0}",
        "enter_mobile_number": "Ingrese el número de celular",
        "enter_valid_mobile": "Ingrese un numero de móvil valido.",
        "mobile_number": "Número de teléfono móvil",
        "guest": "Huésped",
        "silent_observer": "Observador silencioso",
        "silent_observer_tooltip": "El micrófono y la cámara se silenciarán para Silent Observer y no serán visibles para los participantes invitados.",
        "join": "Unirse",
        "invite": "Invitación",
        "invite_and_join": "Invitar y unirse",
        "invite_by_email": "Invitar por correo electrónico",
        "enter_email_send_invite": "Ingrese el correo electrónico para enviar una invitación",
        "new_email_address": "Dirección de correo electrónico",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "preview_email": "Vista previa de correo electrónico",
        "sender_email": "Correo electrónico del remitente",
        "sender_name": "Nombre del remitente",
        "subject": "Tema",
        "body": "Cuerpo",
        "close": "Cerrar",
        "smartglass_disacription": "La identificación del participante de Smartglass debe ser proporcionada por el usuario de Smartglass e ingresada a continuación.",
        "share_invitation_with_others": "Compartir la invitación a la sesión con otros",
        "copy_invitation_desc": "Copie el texto de la invitación con la hora programada y envíelo manualmente",
        "copy_session_code": "Copiar código de sesión",
        "copy_link_to_share": "Copiar enlace para compartir",
        "copy_invitation": "Copiar invitación",
        "invite_sent_successfully": "Invitar enviado con éxito",
        "join_call": "Unirse a la llamada",
        "other": "Otro",
        "sms_sent_label": "SMS enviado!",
        "whatsapp_sent_label": "¡Invitación de WhatsApp enviada!",
        "email_sent_label": "¡Email enviado!",
        "smartglass_sent_label": "¡Invitación Smartglass enviada!",
        "copied": "Copiado!",
        "session_invitation_copied": "Se copió la invitación a la sesión en el portapapeles.",
        "session_code_copy": "Código de sesión copiado al portapapeles.",
        "guest_url_copy": "Invitado URL copiado al portapapeles.",
        "observer_url_copied": "URL del observador copiada al portapapeles.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "Más",
        "send_link": "Enviar enlace",
        "recipients_can_upload_files": "Los destinatarios ahora pueden cargar archivos."
    },
    "fr_FR": {
        "step_2_of_2": "Étape 2 sur 2",
        "share_invite": "Partager l'invitation",
        "share_invite_with": "Partager l'invitation avec {0}",
        "enter_mobile_number": "Entrez le numéro de mobile",
        "enter_valid_mobile": "Entrez un numéro de mobile valide.",
        "mobile_number": "Numéro de portable",
        "guest": "Client",
        "silent_observer": "Observateur silencieux",
        "silent_observer_tooltip": "Le microphone et la caméra seront désactivés pour Silent Observer, et ils ne seront pas visibles par les participants invités.",
        "join": "Joindre",
        "invite": "Inviter",
        "invite_and_join": "Inviter et rejoindre",
        "invite_by_email": "Inviter par email",
        "enter_email_send_invite": "Saisissez un e-mail pour envoyer une invitation",
        "new_email_address": "Adresse électronique",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "preview_email": "Aperçu de l'e-mail",
        "sender_email": "E-mail de l'expéditeur",
        "sender_name": "Nom de l'expéditeur",
        "subject": "Matière",
        "body": "Corps",
        "close": "Fermer",
        "smartglass_disacription": "L'identifiant de participant Smartglass doit être fourni par l'utilisateur Smartglass et entré ci-dessous.",
        "share_invitation_with_others": "Partager l'invitation à la session avec d'autres",
        "copy_invitation_desc": "Copiez le texte d'invitation avec l'heure programmée et envoyez-le manuellement",
        "copy_session_code": "Copier le code de session",
        "copy_link_to_share": "Copier le lien pour partager",
        "copy_invitation": "Copier l'invitation",
        "invite_sent_successfully": "Inviter envoyé avec succès",
        "join_call": "Rejoindre l'appel",
        "other": "Autre",
        "sms_sent_label": "SMS envoyé !",
        "whatsapp_sent_label": "Invitation WhatsApp envoyée !",
        "email_sent_label": "Email envoyé!",
        "smartglass_sent_label": "Invitation aux lunettes intelligentes envoyée !",
        "copied": "Copié!",
        "session_invitation_copied": "Invitation à la session copiée dans le presse-papiers.",
        "session_code_copy": "Code de session copié dans le presse-papier.",
        "guest_url_copy": "URL d'invité copiée dans le Presse-papiers.",
        "observer_url_copied": "URL d'observateur copiée dans le presse-papiers.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "Plus",
        "send_link": "Envoyer un lien",
        "recipients_can_upload_files": "Les destinataires peuvent désormais télécharger des fichiers."
    },
    "en_CA": {
        "step_2_of_2": "Step 2 of 2",
        "share_invite": "Share Invite",
        "share_invite_with": "Share Invite with {0}",
        "enter_mobile_number": "Enter Mobile Number",
        "enter_valid_mobile": "Enter valid mobile number.",
        "mobile_number": "Mobile Number",
        "guest": "Guest",
        "silent_observer": "Silent Observer",
        "silent_observer_tooltip": "Microphone and camera will be muted for Silent Observer, and they will not be visible to guest participants.",
        "join": "Join",
        "invite": "Invite",
        "invite_and_join": "Invite and Join",
        "invite_by_email": "Invite by Email",
        "enter_email_send_invite": "Enter Email to Send an Invite",
        "new_email_address": "Email address",
        "enter_valid_email": "Enter valid email address.",
        "preview_email": "Preview email",
        "sender_email": "Sender Email",
        "sender_name": "Sender Name",
        "subject": "Subject",
        "body": "Body",
        "close": "Close",
        "smartglass_disacription": "Smartglass Participant ID should be provided by the Smartglass User and entered below.",
        "share_invitation_with_others": "Share Session Invitation with others",
        "copy_invitation_desc": "Copy the invitation text with scheduled time and send it manually",
        "copy_session_code": "Copy session code",
        "copy_link_to_share": "Copy link to share",
        "copy_invitation": "Copy invitation",
        "invite_sent_successfully": "Invite sent successfully",
        "join_call": "Join Call",
        "other": "Other",
        "sms_sent_label": "SMS Sent!",
        "whatsapp_sent_label": "WhatsApp Invitation Sent!",
        "email_sent_label": "Email Sent!",
        "smartglass_sent_label": "Smartglass Invitation Sent!",
        "copied": "Copied!",
        "session_invitation_copied": "Session Invitation copied to clipboard.",
        "session_code_copy": "Session code copied to clipboard.",
        "guest_url_copy": "Guest URL copied to clipboard.",
        "observer_url_copied": "Observer URL copied to clipboard.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "More",
        "send_link": "Send Link",
        "recipients_can_upload_files": "Recipients can now upload files."
    },
    "fr_CA": {
        "step_2_of_2": "Étape 2 sur 2",
        "share_invite": "Partager l'invitation",
        "share_invite_with": "Partager l'invitation avec {0}",
        "enter_mobile_number": "Entrez le numéro de mobile",
        "enter_valid_mobile": "Entrez un numéro de mobile valide.",
        "mobile_number": "Numéro de portable",
        "guest": "Client",
        "silent_observer": "Observateur silencieux",
        "silent_observer_tooltip": "Le microphone et la caméra seront désactivés pour Silent Observer, et ils ne seront pas visibles par les participants invités.",
        "join": "Joindre",
        "invite": "Inviter",
        "invite_and_join": "Inviter et rejoindre",
        "invite_by_email": "Inviter par email",
        "enter_email_send_invite": "Saisissez un e-mail pour envoyer une invitation",
        "new_email_address": "Adresse électronique",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "preview_email": "Aperçu de l'e-mail",
        "sender_email": "E-mail de l'expéditeur",
        "sender_name": "Nom de l'expéditeur",
        "subject": "Matière",
        "body": "Corps",
        "close": "Fermer",
        "smartglass_disacription": "L'identifiant de participant Smartglass doit être fourni par l'utilisateur Smartglass et entré ci-dessous.",
        "share_invitation_with_others": "Partager l'invitation à la session avec d'autres",
        "copy_invitation_desc": "Copiez le texte d'invitation avec l'heure programmée et envoyez-le manuellement",
        "copy_session_code": "Copier le code de session",
        "copy_link_to_share": "Copier le lien pour partager",
        "copy_invitation": "Copier l'invitation",
        "invite_sent_successfully": "Inviter envoyé avec succès",
        "join_call": "Rejoindre l'appel",
        "other": "Autre",
        "sms_sent_label": "SMS envoyé !",
        "whatsapp_sent_label": "Invitation WhatsApp envoyée !",
        "email_sent_label": "Email envoyé!",
        "smartglass_sent_label": "Invitation aux lunettes intelligentes envoyée !",
        "copied": "Copié!",
        "session_invitation_copied": "Invitation à la session copiée dans le presse-papiers.",
        "session_code_copy": "Code de session copié dans le presse-papier.",
        "guest_url_copy": "URL d'invité copiée dans le Presse-papiers.",
        "observer_url_copied": "URL d'observateur copiée dans le presse-papiers.",
        "email": "Email",
        "sms_label": "SMS",
        "whatsapp_label": "WhatsApp",
        "more": "Plus",
        "send_link": "Envoyer un lien",
        "recipients_can_upload_files": "Les destinataires peuvent désormais télécharger des fichiers."
    },
    "pt_BR": {
        "step_2_of_2": "Etapa 2 de 2",
        "share_invite": "Compartilhar convite",
        "share_invite_with": "Compartilhar convite com {0}",
        "enter_mobile_number": "Digite o número do celular",
        "enter_valid_mobile": "Insira um número de celular válido.",
        "mobile_number": "Número de celular",
        "guest": "Convidado",
        "silent_observer": "Observador Silencioso",
        "silent_observer_tooltip": "O microfone e a câmera serão silenciados para o Silent Observer e não ficarão visíveis para os participantes convidados.",
        "join": "Entrar",
        "invite": "Convite",
        "invite_and_join": "Convidar e Entrar",
        "invite_by_email": "Convidar por email",
        "enter_email_send_invite": "Digite o e-mail para enviar um convite",
        "new_email_address": "Endereço de e-mail",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "preview_email": "Visualizar e-mail",
        "sender_email": "E-mail do remetente",
        "sender_name": "Nome do remetente",
        "subject": "Sujeito",
        "body": "Corpo",
        "close": "Perto",
        "smartglass_disacription": "O ID do participante do Smartglass deve ser fornecido pelo usuário do Smartglass e digitado abaixo.",
        "share_invitation_with_others": "Compartilhe o convite da sessão com outras pessoas",
        "copy_invitation_desc": "Copie o texto do convite com a hora programada e envie-o manualmente",
        "copy_session_code": "Copiar código da sessão",
        "copy_link_to_share": "Copiar link para compartilhar",
        "copy_invitation": "Copiar convite",
        "invite_sent_successfully": "Convite enviado com sucesso",
        "join_call": "Entrar na chamada",
        "other": "De outros",
        "sms_sent_label": "SMS enviado!",
        "whatsapp_sent_label": "Convite do WhatsApp enviado!",
        "email_sent_label": "E-mail enviado!",
        "smartglass_sent_label": "Convite Smartglass enviado!",
        "copied": "Copiado!",
        "session_invitation_copied": "Convite de sessão copiado para a área de transferência.",
        "session_code_copy": "Código da sessão copiado para a área de transferência.",
        "guest_url_copy": "URL de convidado copiado para a área de transferência.",
        "observer_url_copied": "URL do observador copiado para a área de transferência.",
        "email": "O email",
        "sms_label": "SMS",
        "whatsapp_label": "Whatsapp",
        "more": "Mais",
        "send_link": "Enviar Link",
        "recipients_can_upload_files": "Os destinatários agora podem fazer upload de arquivos."
    },
    "pt_PT": {
        "step_2_of_2": "Etapa 2 de 2",
        "share_invite": "Compartilhar convite",
        "share_invite_with": "Compartilhar convite com {0}",
        "enter_mobile_number": "Digite o número do celular",
        "enter_valid_mobile": "Insira um número de celular válido.",
        "mobile_number": "Número de celular",
        "guest": "Convidado",
        "silent_observer": "Observador Silencioso",
        "silent_observer_tooltip": "O microfone e a câmera serão silenciados para o Silent Observer e não ficarão visíveis para os participantes convidados.",
        "join": "Junte-se",
        "invite": "Convite",
        "invite_and_join": "Convidar e Junte",
        "invite_by_email": "Convidar por email",
        "enter_email_send_invite": "Digite o e-mail para enviar um convite",
        "new_email_address": "Endereço de e-mail",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "preview_email": "Visualizar e-mail",
        "sender_email": "E-mail do remetente",
        "sender_name": "Nome do remetente",
        "subject": "Sujeito",
        "body": "Corpo",
        "close": "Perto",
        "smartglass_disacription": "O ID do participante do Smartglass deve ser fornecido pelo usuário do Smartglass e digitado abaixo.",
        "share_invitation_with_others": "Compartilhe o convite da sessão com outras pessoas",
        "copy_invitation_desc": "Copie o texto do convite com a hora programada e envie-o manualmente",
        "copy_session_code": "Copiar código da sessão",
        "copy_link_to_share": "Copiar link para compartilhar",
        "copy_invitation": "Copiar convite",
        "invite_sent_successfully": "Convidar enviada com sucesso",
        "join_call": "Entrar na chamada",
        "other": "De outros",
        "sms_sent_label": "SMS enviado!",
        "whatsapp_sent_label": "Convite do WhatsApp enviado!",
        "email_sent_label": "E-mail enviado!",
        "smartglass_sent_label": "Convite Smartglass enviado!",
        "copied": "Copiado!",
        "session_invitation_copied": "Convite de sessão copiado para a área de transferência.",
        "session_code_copy": "Código da sessão copiado para a área de transferência.",
        "guest_url_copy": "URL de convidado copiado para a área de transferência.",
        "observer_url_copied": "URL do observador copiado para a área de transferência.",
        "email": "O email",
        "sms_label": "SMS",
        "whatsapp_label": "Whatsapp",
        "more": "Mais",
        "send_link": "Enviar Link",
        "recipients_can_upload_files": "Os destinatários agora podem fazer upload de arquivos."
    },
    "it_IT": {
        "step_2_of_2": "Passaggio 2 di 2",
        "share_invite": "Condividi invito",
        "share_invite_with": "Condividi invito con {0}",
        "enter_mobile_number": "Inserisci il numero di cellulare",
        "enter_valid_mobile": "Inserisci un numero di telefono valido.",
        "mobile_number": "Numero di cellulare",
        "guest": "ospite",
        "silent_observer": "Osservatore silenzioso",
        "silent_observer_tooltip": "Il microfono e la videocamera saranno disattivati per Silent Observer e non saranno visibili ai partecipanti ospiti.",
        "join": "Aderire",
        "invite": "Invitare",
        "invite_and_join": "Invita e partecipa",
        "invite_by_email": "Invita via e-mail",
        "enter_email_send_invite": "Inserisci l'e-mail per inviare un invito",
        "new_email_address": "Indirizzo e-mail",
        "enter_valid_email": "Inserisci un indirizzo email valido.",
        "preview_email": "Anteprima e-mail",
        "sender_email": "E-mail mittente",
        "sender_name": "Nome del mittente",
        "subject": "Materia",
        "body": "Corpo",
        "close": "Vicino",
        "smartglass_disacription": "L'ID partecipante Smartglass deve essere fornito dall'utente Smartglass e inserito di seguito.",
        "share_invitation_with_others": "Condividi l'invito alla sessione con altri",
        "copy_invitation_desc": "Copia il testo dell'invito con l'orario programmato e invialo manualmente",
        "copy_session_code": "Copia il codice della sessione",
        "copy_link_to_share": "Copia il link per condividere",
        "copy_invitation": "Copia invito",
        "invite_sent_successfully": "Invito inviato con successo",
        "join_call": "Partecipa alla chiamata",
        "other": "Altro",
        "sms_sent_label": "SMS inviato!",
        "whatsapp_sent_label": "Invito WhatsApp inviato!",
        "email_sent_label": "Email inviata!",
        "smartglass_sent_label": "Invito Smartglass inviato!",
        "copied": "copiato!",
        "session_invitation_copied": "Invito alla sessione copiato negli appunti.",
        "session_code_copy": "Codice sessione copiato negli appunti.",
        "guest_url_copy": "URL ospite copiato negli appunti.",
        "observer_url_copied": "URL osservatore copiato negli appunti.",
        "email": "E-mail",
        "sms_label": "sms",
        "whatsapp_label": "WhatsApp",
        "more": "Di più",
        "send_link": "Invia il link",
        "recipients_can_upload_files": "I destinatari ora possono caricare file."
    },
    "zh_CN": {
        "step_2_of_2": "第 2 步（共 2 步）",
        "share_invite": "分享邀请",
        "share_invite_with": "与{0}分享邀请",
        "enter_mobile_number": "输入手机号码",
        "enter_valid_mobile": "请输入有效的手机号码。",
        "mobile_number": "手机号码",
        "guest": "客人",
        "silent_observer": "沉默的观察者",
        "silent_observer_tooltip": "Silent Observer 的麦克风和摄像头将被静音，访客参与者将看不到它们。",
        "join": "加入",
        "invite": "邀请",
        "invite_and_join": "邀请和加入",
        "invite_by_email": "通过电子邮件邀请",
        "enter_email_send_invite": "输入电子邮件以发送邀请",
        "new_email_address": "电子邮件地址",
        "enter_valid_email": "输入有效的电子邮件地址。",
        "preview_email": "预览电子邮件",
        "sender_email": "发件人电子邮件",
        "sender_name": "发件者姓名",
        "subject": "主题",
        "body": "身体",
        "close": "关闭",
        "smartglass_disacription": "Smartglass 参与者 ID 应由 Smartglass 用户提供并在下方输入。",
        "share_invitation_with_others": "与他人分享会议邀请",
        "copy_invitation_desc": "复制预定时间的邀请文本并手动发送",
        "copy_session_code": "复制会话代码",
        "copy_link_to_share": "复制链接以共享",
        "copy_invitation": "复制邀请函",
        "invite_sent_successfully": "邀请发送成功",
        "join_call": "加入通话",
        "other": "其他",
        "sms_sent_label": "短信已发送！",
        "whatsapp_sent_label": "WhatsApp 邀请已发送！",
        "email_sent_label": "邮件已发送！",
        "smartglass_sent_label": "智能玻璃邀请函已发送！",
        "copied": "已复制！",
        "session_invitation_copied": "会话邀请已复制到剪贴板。",
        "session_code_copy": "会话代码已复制到剪贴板。",
        "guest_url_copy": "访客 URL 已复制到剪贴板。",
        "observer_url_copied": "观察者 URL 已复制到剪贴板。",
        "email": "电子邮件",
        "sms_label": "短信",
        "whatsapp_label": "微信",
        "more": "更多的",
        "send_link": "发送链接",
        "recipients_can_upload_files": "收件人现在可以上传文件。"
    },
    "ja_JP": {
        "step_2_of_2": "ステップ 2/2",
        "share_invite": "共有の招待",
        "share_invite_with": "招待状を{0}と共有",
        "enter_mobile_number": "携帯電話番号を入力",
        "enter_valid_mobile": "有効な携帯電話番号を入力してください。",
        "mobile_number": "携帯電話番号",
        "guest": "ゲスト",
        "silent_observer": "沈黙の観察者",
        "silent_observer_tooltip": "Silent Observer のマイクとカメラはミュートされ、ゲスト参加者には表示されません。",
        "join": "加入",
        "invite": "招待",
        "invite_and_join": "招待して参加する",
        "invite_by_email": "メールで招待",
        "enter_email_send_invite": "メールを入力して招待状を送信",
        "new_email_address": "電子メールアドレス",
        "enter_valid_email": "有効なメールアドレスを入力してください。",
        "preview_email": "メールのプレビュー",
        "sender_email": "送信者の電子メール",
        "sender_name": "送信者名",
        "subject": "主題",
        "body": "体",
        "close": "近い",
        "smartglass_disacription": "Smartglass 参加者 ID は、Smartglass ユーザーが提供し、以下に入力する必要があります。",
        "share_invitation_with_others": "セッションへの招待を他のユーザーと共有する",
        "copy_invitation_desc": "スケジュールされた時間で招待テキストをコピーし、手動で送信します",
        "copy_session_code": "セッションコードをコピー",
        "copy_link_to_share": "リンクをコピーして共有",
        "copy_invitation": "招待状をコピーする",
        "invite_sent_successfully": "招待状を送信しました",
        "join_call": "通話に参加",
        "other": "他の",
        "sms_sent_label": "SMS を送信しました!",
        "whatsapp_sent_label": "WhatsApp の招待状を送信しました!",
        "email_sent_label": "メールを送信しました!",
        "smartglass_sent_label": "スマートグラスの招待状を送信しました!",
        "copied": "コピーしました！",
        "session_invitation_copied": "セッションへの招待がクリップボードにコピーされました。",
        "session_code_copy": "セッション コードがクリップボードにコピーされました。",
        "guest_url_copy": "クリップボードにコピーされたゲスト URL。",
        "observer_url_copied": "オブザーバーの URL がクリップボードにコピーされました。",
        "email": "Eメール",
        "sms_label": "SMS",
        "whatsapp_label": "ワッツアップ",
        "more": "もっと",
        "send_link": "リンクを送る",
        "recipients_can_upload_files": "受信者はファイルをアップロードできるようになりました。"
    }
}