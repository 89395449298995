import PropTypes from 'prop-types';
import { PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import PaginationSize from "./PaginationSize";
import PaginationLinks from "./PaginationLinks";

const PaginationFooter = ({ paginationProps }) => {
  return (
    <div className="pagination-result-bar">
      <div className="pagination-result-left">
        <div className="showing-result">
          <PaginationTotalStandalone
            className="text-muted"
            {...paginationProps}
          />
        </div>
        <PaginationSize paginationProps={paginationProps} />
      </div>
      <PaginationLinks paginationProps={paginationProps} />
    </div>
  );
};

PaginationFooter.propTypes = {
  paginationProps: PropTypes.object,
};

export default PaginationFooter;
