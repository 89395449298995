import moment from "moment";
import PublicVariables from "../constants/PublicVariables";
import storage from "../services/storage";
import { LOCAL_STORAGE_KEYS, RouteName } from "../constants/Constants";
import history from "../history";
import { Localize } from "../components/Header/Localize";
import { isEmpty } from "lodash";
import { handleErrorMsgToastVisiblity } from "./utility";

export const getCookie = (cookieName) => {
    const targetCookieName = cookieName + "=";
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookies.split(';').map(cookie => cookie.trim());

    const foundCookie = cookieArray.find(cookie => cookie.startsWith(targetCookieName));

    return foundCookie ? foundCookie.substring(targetCookieName.length) : "";
};

export const setCookie = (shouldLogout = false) => {
    const idleTimeout = PublicVariables?.configurations?.idletimeout;

    let activityObj = {
        activity: moment().unix(),
        idle: Number(idleTimeout),
    };

    if (shouldLogout || window.location.pathname === '/logout') {
        activityObj.activity = null;
    }

    const topLevelDomains = ['blitzz.co', 'blitzz.app', 'blitzz.us'];
    const hostnameParts = window.location.hostname.split('.');

    let domainToSet = '';

    if (hostnameParts.length > 2 && (topLevelDomains.includes(hostnameParts[1] + '.' + hostnameParts[2]))) {
        domainToSet = hostnameParts[1] + '.' + hostnameParts[2];
    } else {
        domainToSet = window.location.hostname === 'localhost' ? '' : window.location.hostname;
    }

    document.cookie = storage.get(LOCAL_STORAGE_KEYS.UCID) + '=' + JSON.stringify(activityObj) + '; domain=' + domainToSet + '; path=/';
};


export const handleIdleTimeLogic = () => {
    const doNothandleCookiestates = [RouteName.LOGIN, RouteName.CREATE_ACCOUNT, RouteName.SSO_TOKEN, RouteName.AUTH_USER, RouteName.CREATE_SESSION, RouteName.GET_STARTED, RouteName.CONFIRMATION, RouteName.USER_INFO, RouteName.COMPANY_INFO, RouteName.INVITES];

    if (doNothandleCookiestates.includes(window.location.pathname)) return

    const idlelogout = PublicVariables?.configurations?.idlelogout ?? ''
    const showmeidlelogout = PublicVariables?.configurations?.showmeidlelogout ?? ''

    let idleLogout = PublicVariables.ldFlags?.idleLogout ?? ''
    const ucid = storage.get(LOCAL_STORAGE_KEYS.UCID)

    if (isEmpty(idleLogout)) {
        idleLogout = storage.get(LOCAL_STORAGE_KEYS.IDEAL_LOGOUT_FLAG)
    }

    if (showmeidlelogout && idleLogout && idlelogout) {
        const cookieValue = getCookie(ucid)

        if (!cookieValue) {
            setCookie();
        }
        else {
            const currentTime = moment().unix();
            const cookieParseValue = JSON.parse(cookieValue);

            if (cookieParseValue.activity == null) {
                setCookie(); // first time after login
            }
            else if ((Math.ceil((currentTime - cookieParseValue.activity) / 60)) > cookieParseValue.idle) {
                history.push({ pathname: RouteName.LOGOUT, state: { AUTOLOGOUT: true } })
                document.cookie = cookieValue + "=; expires=Thu, 18 Dec 2013 12:00:00 UTC";  // delete cookie                         
                setCookie(true);
                setTimeout(() => {
                    handleErrorMsgToastVisiblity(Localize[PublicVariables.currentLanguage]['auto_logout_msg'])
                }, 500);
            }
            else {
                setCookie() // update cookie
            }
        }
    }
}