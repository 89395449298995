import { APIs } from "../constants/Constants";
import api from "./api";

export default class LanguageAPI {
    getLanguage(req) {
        const args = {
            headers: req.headers,
        };

        return api.get(APIs.GET_LANGUAGE, args);
    }
}
