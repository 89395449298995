import { LocaliseLanguageArray, LOCAL_STORAGE_KEYS } from "../constants/Constants";
import PublicVariables from "../constants/PublicVariables";
import { CHANGE_LANGUAGE_SUCCESS, GET_LANGUAGE_ERROR, GET_LANGUAGE_SUCCESS } from "../reducers/language-reducer";
import { PORTAL_NAME_CHANGE } from "../reducers/portalname-reducer";
import languageService from "../services/languageService";
import storage from "../services/storage";
import { updatePortalName } from "../shared/utility";


export const getLanguageSuccess = (languagesData) => {
    return {
        type: GET_LANGUAGE_SUCCESS,
        payload: languagesData,
    }
}

export const getLanguageError = () => {
    return {
        type: GET_LANGUAGE_ERROR,
    }
}

export const getLanguage = () => async (dispatch) => {
    const req = {
        headers: {
            userToken: storage.getUserToken()
        },
    };
    languageService
        .getLanguage(req)
        .then((response) => {
            dispatch(getLanguageSuccess(response));
            if (response.languages?.items?.length > 0) {
                dispatch(setLanguage(response.defaultLanguage, response.languages?.items));
              }
        })
        .catch((_error) => {
            dispatch(getLanguageError());
        });

}

export const changeLanguageSuccess = (selectedlanguage) => {
    return {
        type: CHANGE_LANGUAGE_SUCCESS,
        payload: selectedlanguage,
    }
}

export const changeLanguage = (curLang) => (dispatch) => {
    PublicVariables.currentLanguage = curLang;

    const engLangArr = ['en_AU','en_CA','en_GB','en_US'];
    const filteredLanguages = engLangArr.filter(lang => lang === curLang);
    if(filteredLanguages.length > 0){
        document.getElementsByTagName('body')[0].classList.remove('body-language-change');
    }
    else{
        document.getElementsByTagName('body')[0].classList.add('body-language-change');
    }

    if(curLang === 'zh_CN'){
        document.getElementsByTagName('body')[0].classList.add('body-china');
    }
    else{
        document.getElementsByTagName('body')[0].classList.remove('body-china');
    }

    if(curLang === 'ja_JP'){
        document.getElementsByTagName('body')[0].classList.add('japan-body');
    }
    else{
        document.getElementsByTagName('body')[0].classList.remove('japan-body');
    }

    storage.set(LOCAL_STORAGE_KEYS.USERS_LANGUAGE, curLang);

    dispatch(changeLanguageSuccess(PublicVariables.localiseLanguage.find(lang => lang.id === curLang)));
}

export const setLanguage = (defaultLanguage, languages) => (dispatch) => {
    PublicVariables.localiseLanguage = LocaliseLanguageArray;

    if(languages){
        const localiseLanguage = languages
            .filter(lang => lang.isActive) // Filter out languages where isactive is true
            .map(lang => {
                let language = LocaliseLanguageArray.find(lan => lan.id === lang.languageCode);
                if (language) {
                    language.agentPortalName = lang.agentPortalName;
                    language.guestPortalName = lang.guestPortalName;
                }
                return language;
            });

        PublicVariables.localiseLanguage = localiseLanguage;
    }
    const langInStorage = storage.get(LOCAL_STORAGE_KEYS.USERS_LANGUAGE);

    /* language list and default value set up 
        checking storage first
        then Check if Browser has set Language than check, 
        If find browser language in list than use it else use global language
        above condition don't satisfy then else set global language we get from API */
    if(langInStorage && PublicVariables.localiseLanguage.findIndex(lang => lang.id === langInStorage) >= 0){
        PublicVariables.currentLanguage = langInStorage;
    } 
    else if(window.navigator.language){ 
        const browserLanguage = window.navigator.language.replace('-', '_');
        const index = PublicVariables.localiseLanguage.findIndex(lang => lang.id === browserLanguage);
        if (index >= 0) { 
            PublicVariables.currentLanguage = browserLanguage;
        }
        else { 
            PublicVariables.currentLanguage = defaultLanguage;
        }
    }
    else{ 
        PublicVariables.currentLanguage = defaultLanguage;
    }

    setTimeout(() => {
        updatePortalName(PublicVariables.localiseLanguage.find(lang => lang.id === PublicVariables.currentLanguage).agentPortalName)(
            (portalName) => dispatch({
                type: PORTAL_NAME_CHANGE,       
                payload: portalName,
            }));
    }, 500);

    dispatch(changeLanguage(PublicVariables.currentLanguage));
}