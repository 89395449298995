export const EVENTS = {
    LOGIN: 'LOGIN',
    VERIFY_OTP: 'VERIFY_OTP',
    RESEND_OTP: 'RESEND_OTP',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    VERIFY_DOMAIN: 'VERIFY_DOMAIN'
};

export const LOGIN = {
    START: "LOGIN_START",
    SUCCESS: "LOGIN_SUCCESS",
    ERROR: "LOGIN_ERROR",
    RESET: "LOGIN_RESET"
}

export const VERIFY_OTP = {
    START: "VERIFY_OTP_START",
    SUCCESS: "VERIFY_OTP_SUCCESS",
    ERROR: "VERIFY_OTP_ERROR",
}

export const RESEND_OTP = {
    START: "RESEND_OTP_START",
    SUCCESS: "RESEND_OTP_SUCCESS",
    ERROR: "RESEND_OTP_ERROR",
}

export const VERIFY_DOMAIN = {
    START: "VERIFY_DOMAIN_START",
    SUCCESS: "VERIFY_DOMAIN_SUCCESS",
    ERROR: "VERIFY_DOMAIN_ERROR",
}

const defaultLoginState = {
    loginError: false,
    loginLoading: false,
    loginData:false
}

const defaultVerifyOtpState = {
    verifyOtpError: false,
    verifyOtpLoading: false,
    verifyOtpData: false
}

const defaultResendOtpState = {
    resendOtpError: false,
    resendOtpLoading: false,
    resendOtpData: false
}

const defaultVerifyDomainState = {
    verifyDomainError: false,
    verifyDomainLoading: false,
    verifyDomainData: false
}

const defaultState = {
    ...defaultLoginState,
    ...defaultVerifyOtpState,
    ...defaultResendOtpState,
    ...defaultVerifyDomainState
}

const loginReducer = (state = defaultState, action = {}) => {
    switch (action.event) {
        case EVENTS.LOGIN:
            {
                if(action.type === LOGIN.RESET){
                    return {
                            ...state,
                            ...defaultLoginState
                       }
                }
                else{
                    return {
                        ...state,
                        loginLoading: action.type === LOGIN.START ,
                        loginData: action.type === LOGIN.SUCCESS ? action.payload : false,
                        loginError: action.type === LOGIN.ERROR ? action.payload : false,
                    };
                }
            }
            
        case EVENTS.VERIFY_OTP:
            return {
                ...state,
                verifyOtpLoading: action.type === VERIFY_OTP.START ,
                verifyOtpData: action.type === VERIFY_OTP.SUCCESS ? action.payload : false,
                verifyOtpError: action.type === VERIFY_OTP.ERROR ? action.payload : false,
            };
        case EVENTS.RESEND_OTP:
            return {
                ...state,
                resendOtpLoading: action.type === RESEND_OTP.START ,
                resendOtpData: action.type === RESEND_OTP.SUCCESS ? action.payload : false,
                resendOtpError: action.type === RESEND_OTP.ERROR ? action.payload : false,
            };
        case EVENTS.VERIFY_DOMAIN:
            return {
                ...state,
                verifyDomainLoading: action.type === VERIFY_DOMAIN.START ,
                verifyDomainData: action.type === VERIFY_DOMAIN.SUCCESS ? action.payload : false,
                verifyDomainError: action.type === VERIFY_DOMAIN.ERROR ? action.payload : false,
            };
        default:
            return state;
    }
}

export default loginReducer;

