export const Localize = {
    "en_GB": {
        "complete_your_account_details_below": "Complete your account details below",
        "hi_key": "Hi",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First Name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "new_account": "New Account",
        "sign_up_with_work_email": "Sign up with your work email address",
        "sign_up_with_work_email_or_userid": "Sign up with your work email address or userid",
        "enter_your_work_email": "Enter your work email",
        "enter_your_work_email_userid": "Enter your work email or userid",
        "enter_valid_email": "Enter valid email address.",
        "valid_email_user_id": "Enter valid email address or user id.",
        "limit_exceeded": "Limit exceeded",
        "sign_up": "Sign up",
        "already_have_account": "Already have an account?",
        "login": "Login",
        "invalid_email_user_id": "Invalid Email or User ID",
        "already_registerd": "Already registered",
        "invalid_email_user_id_msg_first": "Sorry, You have entered an invalid email address or user ID.",
        "invalid_email_user_id_msg_second": "Please contact your administrator or use an approved email/User ID.",
        "user_seems_already_registerd": "This user seems already registered. Press Continue to go ahead.",
        "dismiss": "Dismiss",
        "continue": "Continue",
        "user_last_name": "Last Name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "crt_acnt_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "back": "Back",
        "crt_acnt_unable_signup_contact_your_admin": "Unable to sign up, please contact your company admin",
        "by_continuing_you_agree_terms_privacy_policy": "By continuing, you agree to our Terms of use and Privacy Policy",
        "terms_of_use": "Terms of use",
        "privacy_policy": "Privacy Policy",
        "permission_for_denied_contact_admin": "You do not have appropriate permissions to perform this operation please contact your administrator.",
        "min_12_char": "Minimum 12 characters",
        "at_least_one_spec_char": "At least one special character",
        "at_least_one_num_char": "At least one numeric character",
        "at_least_one_upper_char": "At least one upper case character",
        "at_least_one_lower_char": "At least one lower case character",
        "user_change_password": "Change Password",
        "crt_acnt_enter_password": "Please enter Password.",
        "crt_acnt_enter_confirm_password": "Please Enter Confirm Password.",
        "crt_acnt_match_password_msg": "Passwords do not match.",
        "user_enter_new_password": "Enter new password",
        "chng_pwd_reenter_new_pwd": "Re-enter new password",
        "user_change": "Change",
        "pwd_changed_please_wait": "Your password changed successfully, please wait while are redirecting you to Login",
        "pwd_changed": "Password Changed",
        "okay_cap": "Okay",
    },
    "de_DE": {
        "complete_your_account_details_below": "Vervollständigen Sie Ihre Kontodaten unten",
        "hi_key": "Hallo",
        "create_account_new_ui_desc": "Bitte geben Sie Ihren Namen an und erstellen Sie ein Passwort, um fortzufahren",
        "user_first_name": "Vorname",
        "crt_acnt_valid_first_name": "Bitte Vornamen eingeben.",
        "crt_acnt_limit_character_msg": "Bis zu 30 Zeichen erlaubt.",
        "new_account": "Neues Konto",
        "sign_up_with_work_email": "Melden Sie sich mit Ihrer Arbeits-E-Mail-Adresse an",
        "sign_up_with_work_email_or_userid": "Melden Sie sich mit Ihrer geschäftlichen E-Mail-Adresse oder Benutzer-ID an",
        "enter_your_work_email": "Geben Sie Ihre Arbeits-E-Mail-Adresse ein",
        "enter_your_work_email_userid": "Geben Sie Ihre Arbeits-E-Mail-Adresse oder Benutzer-ID ein",
        "enter_valid_email": "Geben Sie eine gültige E-Mail-Adresse ein.",
        "valid_email_user_id": "Geben Sie eine gültige E-Mail-Adresse oder Benutzer-ID.",
        "limit_exceeded": "Limit überschritten",
        "sign_up": "Anmelden",
        "already_have_account": "Sie haben bereits ein Konto?",
        "login": "Anmeldung",
        "invalid_email_user_id": "Ungültige E-Mail oder Benutzer-ID",
        "already_registerd": "Bereits registriert",
        "invalid_email_user_id_msg_first": "Sorry, Sie haben eine ungültige E-Mail-Adresse oder Benutzer-ID eingegeben.",
        "invalid_email_user_id_msg_second": "Bitte kontaktieren Sie Ihren Administrator oder verwenden Sie eine zugelassene E-Mail / Benutzer-ID.",
        "user_seems_already_registerd": "Dieser Benutzer scheint bereits registriert zu sein. Drücken Sie Weiter, um fortzufahren.",
        "dismiss": "Entlassen",
        "continue": "Fortsetzen",
        "user_last_name": "Nachname",
        "crt_acnt_enter_last_name": "Bitte Nachnamen eingeben.",
        "crt_acnt_password": "Passwort",
        "password_validation_msg": "Bitte geben Sie ein gültiges Passwort ein.",
        "back": "Zurück",
        "crt_acnt_unable_signup_contact_your_admin": "Kann nicht anmelden, erhalten Sie bei Ihrem Unternehmen Admin",
        "by_continuing_you_agree_terms_privacy_policy": "Wenn Sie fortfahren, stimmen Sie unseren Nutzungsbedingungen und Datenschutz-bestimmungen zu",
        "terms_of_use": "Nutzungsbedingungen",
        "privacy_policy": "Datenschutz-Bestimmungen",
        "permission_for_denied_contact_admin": "Sie haben nicht die erforderlichen Berechtigungen, um diesen Vorgang auszuführen. Wenden Sie sich an Ihren Administrator.",
        "min_12_char": "Mindestens 12 Zeichen",
        "at_least_one_spec_char": "Mindestens ein Sonderzeichen",
        "at_least_one_num_char": "Mindestens ein numerisches Zeichen",
        "at_least_one_upper_char": "Mindestens ein Großbuchstabe",
        "at_least_one_lower_char": "Mindestens ein Kleinbuchstabe",
        "user_change_password": "Ändere das Passwort",
        "crt_acnt_enter_password": "Bitte Passwort eingeben.",
        "crt_acnt_enter_confirm_password": "Bitte geben Sie das Bestätigungskennwort ein.",
        "crt_acnt_match_password_msg": "Passwörter stimmen nicht überein.",
        "user_enter_new_password": "Neues Passwort eingeben",
        "chng_pwd_reenter_new_pwd": "neues Passwort erneut eingeben",
        "user_change": "Veränderung",
        "pwd_changed_please_wait": "Ihr Passwort wurde erfolgreich geändert. Bitte warten Sie, während Sie zur Anmeldung weitergeleitet werden",
        "pwd_changed": "Passwort geändert",
        "okay_cap": "okay",
    },
    "en_US": {
        "complete_your_account_details_below": "Complete your account details below",
        "hi_key": "Hi",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First Name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "new_account": "New Account",
        "sign_up_with_work_email": "Sign up with your work email address",
        "sign_up_with_work_email_or_userid": "Sign up with your work email address or userid",
        "enter_your_work_email": "Enter your work email",
        "enter_your_work_email_userid": "Enter your work email or userid",
        "enter_valid_email": "Enter valid email address.",
        "valid_email_user_id": "Enter valid email address or user id.",
        "limit_exceeded": "Limit exceeded",
        "sign_up": "Sign up",
        "already_have_account": "Already have an account?",
        "login": "Login",
        "invalid_email_user_id": "Invalid Email or User ID",
        "already_registerd": "Already registered",
        "invalid_email_user_id_msg_first": "Sorry, You have entered an invalid email address or user ID.",
        "invalid_email_user_id_msg_second": "Please contact your administrator or use an approved email/User ID.",
        "user_seems_already_registerd": "This user seems already registered. Press Continue to go ahead.",
        "dismiss": "Dismiss",
        "continue": "Continue",
        "user_last_name": "Last Name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "crt_acnt_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "back": "Back",
        "crt_acnt_unable_signup_contact_your_admin": "Unable to sign up, please contact your company admin",
        "by_continuing_you_agree_terms_privacy_policy": "By continuing, you agree to our Terms of use and Privacy Policy",
        "terms_of_use": "Terms of use",
        "privacy_policy": "Privacy Policy",
        "permission_for_denied_contact_admin": "You do not have appropriate permissions to perform this operation please contact your administrator.",
        "min_12_char": "Minimum 12 characters",
        "at_least_one_spec_char": "At least one special character",
        "at_least_one_num_char": "At least one numeric character",
        "at_least_one_upper_char": "At least one upper case character",
        "at_least_one_lower_char": "At least one lower case character",
        "user_change_password": "Change Password",
        "crt_acnt_enter_password": "Please enter Password.",
        "crt_acnt_enter_confirm_password": "Please Enter Confirm Password.",
        "crt_acnt_match_password_msg": "Passwords do not match.",
        "user_enter_new_password": "Enter new password",
        "chng_pwd_reenter_new_pwd": "Re-enter new password",
        "user_change": "Change",
        "pwd_changed_please_wait": "Your password changed successfully, please wait while are redirecting you to Login",
        "pwd_changed": "Password Changed",
        "okay_cap": "Okay",
    },
    "en_AU": {
        "complete_your_account_details_below": "Complete your account details below",
        "hi_key": "Hi",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First Name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "new_account": "New Account",
        "sign_up_with_work_email": "Sign up with your work email address",
        "sign_up_with_work_email_or_userid": "Sign up with your work email address or userid",
        "enter_your_work_email": "Enter your work email",
        "enter_your_work_email_userid": "Enter your work email or userid",
        "enter_valid_email": "Enter valid email address.",
        "valid_email_user_id": "Enter valid email address or user id.",
        "limit_exceeded": "Limit exceeded",
        "sign_up": "Sign up",
        "already_have_account": "Already have an account?",
        "login": "Login",
        "invalid_email_user_id": "Invalid Email or User ID",
        "already_registerd": "Already registered",
        "invalid_email_user_id_msg_first": "Sorry, You have entered an invalid email address or user ID.",
        "invalid_email_user_id_msg_second": "Please contact your administrator or use an approved email/User ID.",
        "user_seems_already_registerd": "This user seems already registered. Press Continue to go ahead.",
        "dismiss": "Dismiss",
        "continue": "Continue",
        "user_last_name": "Last Name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "crt_acnt_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "back": "Back",
        "crt_acnt_unable_signup_contact_your_admin": "Unable to sign up, please contact your company admin",
        "by_continuing_you_agree_terms_privacy_policy": "By continuing, you agree to our Terms of use and Privacy Policy",
        "terms_of_use": "Terms of use",
        "privacy_policy": "Privacy Policy",
        "permission_for_denied_contact_admin": "You do not have appropriate permissions to perform this operation please contact your administrator.",
        "min_12_char": "Minimum 12 characters",
        "at_least_one_spec_char": "At least one special character",
        "at_least_one_num_char": "At least one numeric character",
        "at_least_one_upper_char": "At least one upper case character",
        "at_least_one_lower_char": "At least one lower case character",
        "user_change_password": "Change Password",
        "crt_acnt_enter_password": "Please enter Password.",
        "crt_acnt_enter_confirm_password": "Please Enter Confirm Password.",
        "crt_acnt_match_password_msg": "Passwords do not match.",
        "user_enter_new_password": "Enter new password",
        "chng_pwd_reenter_new_pwd": "Re-enter new password",
        "user_change": "Change",
        "pwd_changed_please_wait": "Your password changed successfully, please wait while are redirecting you to Login",
        "pwd_changed": "Password Changed",
        "okay_cap": "Okay",
    },
    "es_ES": {
        "complete_your_account_details_below": "Complete los detalles de su cuenta a continuación",
        "hi_key": "Hola",
        "create_account_new_ui_desc": "Por favor escriba su nombre y cree una contraseña para continuar",
        "user_first_name": "Primer nombre",
        "crt_acnt_valid_first_name": "Ingrese el nombre.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "new_account": "Nueva cuenta",
        "sign_up_with_work_email": "Regístrese con su dirección de correo electrónico de trabajo",
        "sign_up_with_work_email_or_userid": "Regístrese con su dirección de correo electrónico de trabajo o ID de usuario",
        "enter_your_work_email": "Ingrese su correo electrónico de trabajo",
        "enter_your_work_email_userid": "Ingrese su correo electrónico de trabajo o ID de usuario",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "valid_email_user_id": "Introduzca la dirección de correo electrónico válida o una identificación de usuario.",
        "limit_exceeded": "Límite excedido",
        "sign_up": "Regístrate",
        "already_have_account": "¿Ya tienes una cuenta?",
        "login": "Iniciar sesión",
        "invalid_email_user_id": "E-mail no válido o ID de usuario",
        "already_registerd": "Ya registrado",
        "invalid_email_user_id_msg_first": "Lo sentimos, Ha introducido una dirección de correo electrónico o ID de usuario válido.",
        "invalid_email_user_id_msg_second": "Por favor, póngase en contacto con el administrador o utilizar un ID de correo electrónico / usuario autorizado.",
        "user_seems_already_registerd": "Este usuario ya parece estar registrado. Presione Continuar para continuar.",
        "dismiss": "Despedir",
        "continue": "Continuar",
        "user_last_name": "Apellido",
        "crt_acnt_enter_last_name": "Ingrese el apellido.",
        "crt_acnt_password": "Contraseña",
        "password_validation_msg": "Por favor ingrese una contraseña válida.",
        "back": "atrás",
        "crt_acnt_unable_signup_contact_your_admin": "No es posible inscribirse, por favor contacte a su administrador de la empresa",
        "by_continuing_you_agree_terms_privacy_policy": "Al continuar, acepta nuestros Términos de uso y nuestra Política de privacidad",
        "terms_of_use": "Términos de Uso",
        "privacy_policy": "Política de privacidad",
        "permission_for_denied_contact_admin": "No tiene los permisos adecuados para realizar esta operación, póngase en contacto con su administrador.",
        "min_12_char": "Mínimo 12 caracteres",
        "at_least_one_spec_char": "Al menos un personaje especial",
        "at_least_one_num_char": "Al menos un caracter numérico",
        "at_least_one_upper_char": "Al menos un carácter en mayúscula",
        "at_least_one_lower_char": "Al menos un carácter en minúscula",
        "user_change_password": "Cambia la contraseña",
        "crt_acnt_enter_password": "Por favor, ingrese contraseña.",
        "crt_acnt_enter_confirm_password": "Por favor ingrese Confirmar contraseña.",
        "crt_acnt_match_password_msg": "Las contraseñas no coinciden.",
        "user_enter_new_password": "Introduzca nueva contraseña",
        "chng_pwd_reenter_new_pwd": "re-ingrese nueva contraseña",
        "user_change": "Cambio",
        "pwd_changed_please_wait": "Su contraseña se cambió correctamente, espere mientras lo redirigen a Iniciar sesión",
        "pwd_changed": "Contraseña cambiada",
        "okay_cap": "Bueno",
    },
    "es_MX": {
        "complete_your_account_details_below": "Complete los detalles de su cuenta a continuación",
        "hi_key": "Hola",
        "create_account_new_ui_desc": "Por favor escriba su nombre y cree una contraseña para continuar",
        "user_first_name": "Primer nombre",
        "crt_acnt_valid_first_name": "Ingrese el nombre.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "new_account": "Nueva cuenta",
        "sign_up_with_work_email": "Regístrese con su dirección de correo electrónico de trabajo",
        "sign_up_with_work_email_or_userid": "Regístrese con su dirección de correo electrónico de trabajo o ID de usuario",
        "enter_your_work_email": "Ingrese su correo electrónico de trabajo",
        "enter_your_work_email_userid": "Ingrese su correo electrónico de trabajo o ID de usuario",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "valid_email_user_id": "Introduzca la dirección de correo electrónico válida o una identificación de usuario.",
        "limit_exceeded": "Límite excedido",
        "sign_up": "Regístrate",
        "already_have_account": "¿Ya tienes una cuenta?",
        "login": "Iniciar sesión",
        "invalid_email_user_id": "E-mail no válido o ID de usuario",
        "already_registerd": "Ya registrado",
        "invalid_email_user_id_msg_first": "Lo sentimos, Ha introducido una dirección de correo electrónico o ID de usuario válido.",
        "invalid_email_user_id_msg_second": "Por favor, póngase en contacto con el administrador o utilizar un ID de correo electrónico / usuario autorizado.",
        "user_seems_already_registerd": "Este usuario ya parece estar registrado. Presione Continuar para continuar.",
        "dismiss": "Despedir",
        "continue": "Continuar",
        "user_last_name": "Apellido",
        "crt_acnt_enter_last_name": "Ingrese el apellido.",
        "crt_acnt_password": "Contraseña",
        "password_validation_msg": "Por favor ingrese una contraseña válida.",
        "back": "atrás",
        "crt_acnt_unable_signup_contact_your_admin": "No es posible inscribirse, por favor contacte a su administrador de la empresa",
        "by_continuing_you_agree_terms_privacy_policy": "Al continuar, acepta nuestros Términos de uso y nuestra Política de privacidad",
        "terms_of_use": "Términos de Uso",
        "privacy_policy": "Política de privacidad",
        "permission_for_denied_contact_admin": "No tiene los permisos adecuados para realizar esta operación, póngase en contacto con su administrador.",
        "min_12_char": "Mínimo 12 caracteres",
        "at_least_one_spec_char": "Al menos un personaje especial",
        "at_least_one_num_char": "Al menos un caracter numérico",
        "at_least_one_upper_char": "Al menos un carácter en mayúscula",
        "at_least_one_lower_char": "Al menos un carácter en minúscula",
        "user_change_password": "Cambia la contraseña",
        "crt_acnt_enter_password": "Por favor, ingrese contraseña.",
        "crt_acnt_enter_confirm_password": "Por favor ingrese Confirmar contraseña.",
        "crt_acnt_match_password_msg": "Las contraseñas no coinciden.",
        "user_enter_new_password": "Introduzca nueva contraseña",
        "chng_pwd_reenter_new_pwd": "re-ingrese nueva contraseña",
        "user_change": "Cambio",
        "pwd_changed_please_wait": "Su contraseña se cambió correctamente, espere mientras lo redirigen a Iniciar sesión",
        "pwd_changed": "Contraseña cambiada",
        "okay_cap": "Bueno",
    },
    "es_AR": {
        "complete_your_account_details_below": "Complete los detalles de su cuenta a continuación",
        "hi_key": "Hola",
        "create_account_new_ui_desc": "Por favor escriba su nombre y cree una contraseña para continuar",
        "user_first_name": "Primer nombre",
        "crt_acnt_valid_first_name": "Ingrese el nombre.",
        "crt_acnt_limit_character_msg": "Hasta 30 caracteres permitidos.",
        "new_account": "Nueva cuenta",
        "sign_up_with_work_email": "Regístrese con su dirección de correo electrónico de trabajo",
        "sign_up_with_work_email_or_userid": "Regístrese con su dirección de correo electrónico de trabajo o ID de usuario",
        "enter_your_work_email": "Ingrese su correo electrónico de trabajo",
        "enter_your_work_email_userid": "Ingrese su correo electrónico de trabajo o ID de usuario",
        "enter_valid_email": "Introduzca la dirección de correo electrónico válida.",
        "valid_email_user_id": "Introduzca la dirección de correo electrónico válida o una identificación de usuario.",
        "limit_exceeded": "Límite excedido",
        "sign_up": "Regístrate",
        "already_have_account": "¿Ya tienes una cuenta?",
        "login": "Iniciar sesión",
        "invalid_email_user_id": "E-mail no válido o ID de usuario",
        "already_registerd": "Ya registrado",
        "invalid_email_user_id_msg_first": "Lo sentimos, Ha introducido una dirección de correo electrónico o ID de usuario válido.",
        "invalid_email_user_id_msg_second": "Por favor, póngase en contacto con el administrador o utilizar un ID de correo electrónico / usuario autorizado.",
        "user_seems_already_registerd": "Este usuario ya parece estar registrado. Presione Continuar para continuar.",
        "dismiss": "Despedir",
        "continue": "Continuar",
        "user_last_name": "Apellido",
        "crt_acnt_enter_last_name": "Ingrese el apellido.",
        "crt_acnt_password": "Contraseña",
        "password_validation_msg": "Por favor ingrese una contraseña válida.",
        "back": "atrás",
        "crt_acnt_unable_signup_contact_your_admin": "No es posible inscribirse, por favor contacte a su administrador de la empresa",
        "by_continuing_you_agree_terms_privacy_policy": "Al continuar, acepta nuestros Términos de uso y nuestra Política de privacidad",
        "terms_of_use": "Términos de Uso",
        "privacy_policy": "Política de privacidad",
        "permission_for_denied_contact_admin": "No tiene los permisos adecuados para realizar esta operación, póngase en contacto con su administrador.",
        "min_12_char": "Mínimo 12 caracteres",
        "at_least_one_spec_char": "Al menos un personaje especial",
        "at_least_one_num_char": "Al menos un caracter numérico",
        "at_least_one_upper_char": "Al menos un carácter en mayúscula",
        "at_least_one_lower_char": "Al menos un carácter en minúscula",
        "user_change_password": "Cambia la contraseña",
        "crt_acnt_enter_password": "Por favor, ingrese contraseña.",
        "crt_acnt_enter_confirm_password": "Por favor ingrese Confirmar contraseña.",
        "crt_acnt_match_password_msg": "Las contraseñas no coinciden.",
        "user_enter_new_password": "Introduzca nueva contraseña",
        "chng_pwd_reenter_new_pwd": "re-ingrese nueva contraseña",
        "user_change": "Cambio",
        "pwd_changed_please_wait": "Su contraseña se cambió correctamente, espere mientras lo redirigen a Iniciar sesión",
        "pwd_changed": "Contraseña cambiada",
        "okay_cap": "Bueno",
    },
    "fr_FR": {
        "complete_your_account_details_below": "Complétez les détails de votre compte ci-dessous",
        "hi_key": "salut",
        "create_account_new_ui_desc": "Veuillez indiquer votre nom et créer un mot de passe pour continuer",
        "user_first_name": "Prénom",
        "crt_acnt_valid_first_name": "Veuillez saisir le prénom.",
        "crt_acnt_limit_character_msg": "Jusqu'à 30 caractères autorisés.",
        "new_account": "Nouveau compte",
        "sign_up_with_work_email": "Inscrivez-vous avec votre adresse e-mail professionnelle",
        "sign_up_with_work_email_or_userid": "Inscrivez-vous avec votre adresse e-mail professionnelle ou votre ID utilisateur",
        "enter_your_work_email": "Saisissez votre adresse e-mail professionnelle",
        "enter_your_work_email_userid": "Saisissez votre adresse e-mail professionnelle ou votre ID utilisateur",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "valid_email_user_id": "Entrez l'adresse e-mail valide ou l'identifiant de l'utilisateur.",
        "limit_exceeded": "Limite dépassée",
        "sign_up": "S'inscrire",
        "already_have_account": "Vous avez déjà un compte?",
        "login": "S'identifier",
        "invalid_email_user_id": "Invalide Email ou ID utilisateur",
        "already_registerd": "Déjà enregistré",
        "invalid_email_user_id_msg_first": "Désolé, vous avez saisi une adresse e-mail non valide ou ID utilisateur.",
        "invalid_email_user_id_msg_second": "S'il vous plaît contacter votre administrateur ou utiliser un e-mail / ID utilisateur approuvé.",
        "user_seems_already_registerd": "Cet utilisateur semble déjà enregistré. Appuyez sur Continuer pour continuer.",
        "dismiss": "Rejeter",
        "continue": "Continuer",
        "user_last_name": "Nom de famille",
        "crt_acnt_enter_last_name": "Veuillez saisir le nom de famille.",
        "crt_acnt_password": "Mot de passe",
        "password_validation_msg": "Veuillez entrer un mot de passe valide.",
        "back": "Retour",
        "crt_acnt_unable_signup_contact_your_admin": "Impossible de vous inscrire, s'il vous plaît contacter votre compagnie admin",
        "by_continuing_you_agree_terms_privacy_policy": "En continuant, vous acceptez nos conditions d'utilisation et notre politique de confidentialité",
        "terms_of_use": "Conditions d'utilisation",
        "privacy_policy": "Politique de confidentialité",
        "permission_for_denied_contact_admin": "Vous ne disposez pas des autorisations appropriées pour effectuer cette opération, veuillez contacter votre administrateur.",
        "min_12_char": "12 caractères minimum",
        "at_least_one_spec_char": "Au moins un caractère spécial",
        "at_least_one_num_char": "Au moins un caractère numérique",
        "at_least_one_upper_char": "Au moins un caractère majuscule",
        "at_least_one_lower_char": "Au moins un caractère minuscule",
        "user_change_password": "Changer le mot de passe",
        "crt_acnt_enter_password": "Veuillez entrer le mot de passe.",
        "crt_acnt_enter_confirm_password": "Veuillez saisir le mot de passe confirmé.",
        "crt_acnt_match_password_msg": "Les mots de passe ne correspondent pas.",
        "user_enter_new_password": "Entrez un nouveau mot de passe",
        "chng_pwd_reenter_new_pwd": "ré-entrez le nouveau mot de passe",
        "user_change": "Changement",
        "pwd_changed_please_wait": "Votre mot de passe a été modifié avec succès, veuillez patienter pendant que nous vous redirigeons vers la connexion",
        "pwd_changed": "Mot de passe changé",
        "okay_cap": "d'accord",
    },
    "en_CA": {
        "complete_your_account_details_below": "Complete your account details below",
        "hi_key": "Hi",
        "create_account_new_ui_desc": "Please list your name and create a password to continue",
        "user_first_name": "First Name",
        "crt_acnt_valid_first_name": "Please enter first name.",
        "crt_acnt_limit_character_msg": "Up to 30 characters allowed.",
        "new_account": "New Account",
        "sign_up_with_work_email": "Sign up with your work email address",
        "sign_up_with_work_email_or_userid": "Sign up with your work email address or userid",
        "enter_your_work_email": "Enter your work email",
        "enter_your_work_email_userid": "Enter your work email or userid",
        "enter_valid_email": "Enter valid email address.",
        "valid_email_user_id": "Enter valid email address or user id.",
        "limit_exceeded": "Limit exceeded",
        "sign_up": "Sign up",
        "already_have_account": "Already have an account?",
        "login": "Login",
        "invalid_email_user_id": "Invalid Email or User ID",
        "already_registerd": "Already registered",
        "invalid_email_user_id_msg_first": "Sorry, You have entered an invalid email address or user ID.",
        "invalid_email_user_id_msg_second": "Please contact your administrator or use an approved email/User ID.",
        "user_seems_already_registerd": "This user seems already registered. Press Continue to go ahead.",
        "dismiss": "Dismiss",
        "continue": "Continue",
        "user_last_name": "Last Name",
        "crt_acnt_enter_last_name": "Please enter last name.",
        "crt_acnt_password": "Password",
        "password_validation_msg": "Please enter valid password.",
        "back": "Back",
        "crt_acnt_unable_signup_contact_your_admin": "Unable to sign up, please contact your company admin",
        "by_continuing_you_agree_terms_privacy_policy": "By continuing, you agree to our Terms of use and Privacy Policy",
        "terms_of_use": "Terms of use",
        "privacy_policy": "Privacy Policy",
        "permission_for_denied_contact_admin": "You do not have appropriate permissions to perform this operation please contact your administrator.",
        "min_12_char": "Minimum 12 characters",
        "at_least_one_spec_char": "At least one special character",
        "at_least_one_num_char": "At least one numeric character",
        "at_least_one_upper_char": "At least one upper case character",
        "at_least_one_lower_char": "At least one lower case character",
        "user_change_password": "Change Password",
        "crt_acnt_enter_password": "Please enter Password.",
        "crt_acnt_enter_confirm_password": "Please Enter Confirm Password.",
        "crt_acnt_match_password_msg": "Passwords do not match.",
        "user_enter_new_password": "Enter new password",
        "chng_pwd_reenter_new_pwd": "Re-enter new password",
        "user_change": "Change",
        "pwd_changed_please_wait": "Your password changed successfully, please wait while are redirecting you to Login",
        "pwd_changed": "Password Changed",
        "okay_cap": "Okay",
    },
    "fr_CA": {
        "complete_your_account_details_below": "Complétez les détails de votre compte ci-dessous",
        "hi_key": "salut",
        "create_account_new_ui_desc": "Veuillez indiquer votre nom et créer un mot de passe pour continuer",
        "user_first_name": "Prénom",
        "crt_acnt_valid_first_name": "Veuillez saisir le prénom.",
        "crt_acnt_limit_character_msg": "Jusqu'à 30 caractères autorisés.",
        "new_account": "Nouveau compte",
        "sign_up_with_work_email": "Inscrivez-vous avec votre adresse e-mail professionnelle",
        "sign_up_with_work_email_or_userid": "Inscrivez-vous avec votre adresse e-mail professionnelle ou votre ID utilisateur",
        "enter_your_work_email": "Saisissez votre adresse e-mail professionnelle",
        "enter_your_work_email_userid": "Saisissez votre adresse e-mail professionnelle ou votre ID utilisateur",
        "enter_valid_email": "Entrez l'adresse e-mail valide.",
        "valid_email_user_id": "Entrez l'adresse e-mail valide ou l'identifiant de l'utilisateur.",
        "limit_exceeded": "Limite dépassée",
        "sign_up": "S'inscrire",
        "already_have_account": "Vous avez déjà un compte?",
        "login": "S'identifier",
        "invalid_email_user_id": "Invalide Email ou ID utilisateur",
        "already_registerd": "Déjà enregistré",
        "invalid_email_user_id_msg_first": "Désolé, vous avez saisi une adresse e-mail non valide ou ID utilisateur.",
        "invalid_email_user_id_msg_second": "S'il vous plaît contacter votre administrateur ou utiliser un e-mail / ID utilisateur approuvé.",
        "user_seems_already_registerd": "Cet utilisateur semble déjà enregistré. Appuyez sur Continuer pour continuer.",
        "dismiss": "Rejeter",
        "continue": "Continuer",
        "user_last_name": "Nom de famille",
        "crt_acnt_enter_last_name": "Veuillez saisir le nom de famille.",
        "crt_acnt_password": "Mot de passe",
        "password_validation_msg": "Veuillez entrer un mot de passe valide.",
        "back": "Retour",
        "crt_acnt_unable_signup_contact_your_admin": "Impossible de vous inscrire, s'il vous plaît contacter votre compagnie admin",
        "by_continuing_you_agree_terms_privacy_policy": "En continuant, vous acceptez nos conditions d'utilisation et notre politique de confidentialité",
        "terms_of_use": "Conditions d'utilisation",
        "privacy_policy": "Politique de confidentialité",
        "permission_for_denied_contact_admin": "Vous ne disposez pas des autorisations appropriées pour effectuer cette opération, veuillez contacter votre administrateur.",
        "min_12_char": "12 caractères minimum",
        "at_least_one_spec_char": "Au moins un caractère spécial",
        "at_least_one_num_char": "Au moins un caractère numérique",
        "at_least_one_upper_char": "Au moins un caractère majuscule",
        "at_least_one_lower_char": "Au moins un caractère minuscule",
        "user_change_password": "Changer le mot de passe",
        "crt_acnt_enter_password": "Veuillez entrer le mot de passe.",
        "crt_acnt_enter_confirm_password": "Veuillez saisir le mot de passe confirmé.",
        "crt_acnt_match_password_msg": "Les mots de passe ne correspondent pas.",
        "user_enter_new_password": "Entrez un nouveau mot de passe",
        "chng_pwd_reenter_new_pwd": "ré-entrez le nouveau mot de passe",
        "user_change": "Changement",
        "pwd_changed_please_wait": "Votre mot de passe a été modifié avec succès, veuillez patienter pendant que nous vous redirigeons vers la connexion",
        "pwd_changed": "Mot de passe changé",
        "okay_cap": "d'accord",
    },
    "pt_BR": {
        "complete_your_account_details_below": "Preencha os detalhes da sua conta abaixo",
        "hi_key": "Oi",
        "create_account_new_ui_desc": "Liste seu nome e crie uma senha para continuar",
        "user_first_name": "Primeiro nome",
        "crt_acnt_valid_first_name": "Por favor, insira o primeiro nome.",
        "crt_acnt_limit_character_msg": "São permitidos até 30 caracteres.",
        "new_account": "Nova conta",
        "sign_up_with_work_email": "Inscreva-se com seu endereço de e-mail comercial",
        "sign_up_with_work_email_or_userid": "Inscreva-se com seu endereço de e-mail comercial ou ID do usuário",
        "enter_your_work_email": "Digite seu e-mail comercial",
        "enter_your_work_email_userid": "Digite seu e-mail comercial ou ID do usuário",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "valid_email_user_id": "Digite o endereço de e-mail válido ou ID de usuário.",
        "limit_exceeded": "Limite excedido",
        "sign_up": "Cadastrar",
        "already_have_account": "já tem uma conta?",
        "login": "Entrar",
        "invalid_email_user_id": "E-mail inválido ou User ID",
        "already_registerd": "Já registrado",
        "invalid_email_user_id_msg_first": "Desculpe, Você digitou um inválido endereço de e-mail ou usuário ID.",
        "invalid_email_user_id_msg_second": "Entre em contato com o administrador ou usar um ID de e-mail / Utilizador aprovado.",
        "user_seems_already_registerd": "Este usuário parece já estar registrado. Pressione Continuar para prosseguir.",
        "dismiss": "Dispensar",
        "continue": "Continuar",
        "user_last_name": "Sobrenome",
        "crt_acnt_enter_last_name": "Por favor, insira o sobrenome.",
        "crt_acnt_password": "Senha",
        "password_validation_msg": "Por favor, insira a senha válida.",
        "back": "De volta",
        "crt_acnt_unable_signup_contact_your_admin": "Se não conseguir se cadastrar, entre em contato com o administrador do serviço",
        "by_continuing_you_agree_terms_privacy_policy": "Ao continuar, você concorda com nossos Termos de uso e Política de Privacidade",
        "terms_of_use": "Termos de uso",
        "privacy_policy": "Política de Privacidade",
        "permission_for_denied_contact_admin": "Você não possui permissões apropriadas para executar esta operação, entre em contato com seu administrador.",
        "min_12_char": "Mínimo de 12 caracteres",
        "at_least_one_spec_char": "Pelo menos um caractere especial",
        "at_least_one_num_char": "Pelo menos um caractere numérico",
        "at_least_one_upper_char": "Pelo menos um caractere maiúsculo",
        "at_least_one_lower_char": "Pelo menos um caractere minúsculo",
        "user_change_password": "Mudar a senha",
        "crt_acnt_enter_password": "Digite a senha.",
        "crt_acnt_enter_confirm_password": "Por favor, insira a confirmação da senha.",
        "crt_acnt_match_password_msg": "As senhas são diferentes.",
        "user_enter_new_password": "Insira a nova senha",
        "chng_pwd_reenter_new_pwd": "Re-introduza a nova palavra-passe",
        "user_change": "Alteração",
        "pwd_changed_please_wait": "Sua senha foi alterada com sucesso, aguarde enquanto estamos redirecionando você para Login",
        "pwd_changed": "Senha alterada",
        "okay_cap": "OK",
    },
    "pt_PT": {
        "complete_your_account_details_below": "Preencha os detalhes da sua conta abaixo",
        "hi_key": "Oi",
        "create_account_new_ui_desc": "Liste seu nome e crie uma senha para continuar",
        "user_first_name": "Primeiro nome",
        "crt_acnt_valid_first_name": "Por favor, insira o primeiro nome.",
        "crt_acnt_limit_character_msg": "São permitidos até 30 caracteres.",
        "new_account": "Nova conta",
        "sign_up_with_work_email": "Inscreva-se com seu endereço de e-mail comercial",
        "sign_up_with_work_email_or_userid": "Inscreva-se com seu endereço de e-mail comercial ou ID do usuário",
        "enter_your_work_email": "Digite seu e-mail comercial",
        "enter_your_work_email_userid": "Digite seu e-mail comercial ou ID do usuário",
        "enter_valid_email": "Digite o endereço de e-mail válido.",
        "valid_email_user_id": "Digite o endereço de e-mail válido ou ID de usuário.",
        "limit_exceeded": "Limite excedido",
        "sign_up": "inscrever-se",
        "already_have_account": "já tem uma conta?",
        "login": "Entrar",
        "invalid_email_user_id": "E-mail inválido ou User ID",
        "already_registerd": "Já registrado",
        "invalid_email_user_id_msg_first": "Desculpe, Você digitou um inválido endereço de e-mail ou usuário ID.",
        "invalid_email_user_id_msg_second": "Entre em contato com o administrador ou usar um ID de e-mail / Utilizador aprovado.",
        "user_seems_already_registerd": "Este usuário parece já estar registrado. Pressione Continuar para prosseguir.",
        "dismiss": "Dispensar",
        "continue": "Continuar",
        "user_last_name": "Sobrenome",
        "crt_acnt_enter_last_name": "Por favor, insira o sobrenome.",
        "crt_acnt_password": "Senha",
        "password_validation_msg": "Por favor, insira a senha válida.",
        "back": "De volta",
        "crt_acnt_unable_signup_contact_your_admin": "Incapaz de se inscrever, entre em contato com o administrador da empresa",
        "by_continuing_you_agree_terms_privacy_policy": "Ao continuar, você concorda com nossos Termos de uso e Política de Privacidade",
        "terms_of_use": "Termos de uso",
        "privacy_policy": "Política de Privacidade",
        "permission_for_denied_contact_admin": "Você não possui permissões apropriadas para executar esta operação, entre em contato com seu administrador.",
        "min_12_char": "Mínimo de 12 caracteres",
        "at_least_one_spec_char": "Pelo menos um caractere especial",
        "at_least_one_num_char": "Pelo menos um caractere numérico",
        "at_least_one_upper_char": "Pelo menos um caractere maiúsculo",
        "at_least_one_lower_char": "Pelo menos um caractere minúsculo",
        "user_change_password": "Mudar senha",
        "crt_acnt_enter_password": "Digite a senha.",
        "crt_acnt_enter_confirm_password": "Por favor, insira a confirmação da senha.",
        "crt_acnt_match_password_msg": "As senhas não coincidem.",
        "user_enter_new_password": "Insira a nova senha",
        "chng_pwd_reenter_new_pwd": "Re-introduza a nova palavra-passe",
        "user_change": "mudança",
        "pwd_changed_please_wait": "Sua senha foi alterada com sucesso, aguarde enquanto estamos redirecionando você para Login",
        "pwd_changed": "Senha alterada",
        "okay_cap": "OK",
    },
    "it_IT": {
        "complete_your_account_details_below": "Completa i dettagli del tuo account qui sotto",
        "hi_key": "Ciao",
        "create_account_new_ui_desc": "Inserisci il tuo nome e crea una password per continuare",
        "user_first_name": "Nome di battesimo",
        "crt_acnt_valid_first_name": "Si prega di inserire il nome.",
        "crt_acnt_limit_character_msg": "Sono consentiti fino a 30 caratteri.",
        "new_account": "Nuovo account",
        "sign_up_with_work_email": "Iscriviti con il tuo indirizzo email di lavoro",
        "sign_up_with_work_email_or_userid": "Iscriviti con il tuo indirizzo email di lavoro o ID utente user",
        "enter_your_work_email": "Inserisci la tua email di lavoro",
        "enter_your_work_email_userid": "Inserisci la tua email di lavoro o l'ID utente",
        "enter_valid_email": "Inserisci un indirizzo email valido.",
        "valid_email_user_id": "Inserisci un indirizzo email o un ID utente valido.",
        "limit_exceeded": "Limite superato",
        "sign_up": "Iscriviti",
        "already_have_account": "Hai già un account?",
        "login": "Login",
        "invalid_email_user_id": "Email o ID utente non validi",
        "already_registerd": "Già registrato",
        "invalid_email_user_id_msg_first": "Spiacenti, hai inserito un indirizzo email o un ID utente non valido.",
        "invalid_email_user_id_msg_second": "Contatta l'amministratore o utilizza un'e-mail/ID utente approvato.",
        "user_seems_already_registerd": "Questo utente sembra già registrato. Premi Continua per andare avanti.",
        "dismiss": "Respingere",
        "continue": "Continua",
        "user_last_name": "Cognome",
        "crt_acnt_enter_last_name": "Si prega di inserire il cognome.",
        "crt_acnt_password": "Parola d'ordine",
        "password_validation_msg": "Si prega di inserire una password valida.",
        "back": "Indietro",
        "crt_acnt_unable_signup_contact_your_admin": "Impossibile registrarsi, contatta l'amministratore della tua azienda",
        "by_continuing_you_agree_terms_privacy_policy": "Continuando, accetti i nostri Termini di utilizzo e l'Informativa sulla privacy",
        "terms_of_use": "Termini di utilizzo",
        "privacy_policy": "politica sulla riservatezza",
        "permission_for_denied_contact_admin": "Non disponi delle autorizzazioni appropriate per eseguire questa operazione, contatta il tuo amministratore.",
        "min_12_char": "Minimo 12 caratteri",
        "at_least_one_spec_char": "Almeno un carattere speciale",
        "at_least_one_num_char": "Almeno un carattere numerico",
        "at_least_one_upper_char": "Almeno un carattere maiuscolo",
        "at_least_one_lower_char": "Almeno un carattere minuscolo",
        "user_change_password": "Cambia la password",
        "crt_acnt_enter_password": "Per favore, inserisci la password.",
        "crt_acnt_enter_confirm_password": "Inserisci la password di conferma.",
        "crt_acnt_match_password_msg": "Le passwords non corrispondono.",
        "user_enter_new_password": "Inserire una nuova password",
        "chng_pwd_reenter_new_pwd": "Reinserire la nuova password",
        "user_change": "Modificare",
        "pwd_changed_please_wait": "La tua password è stata modificata correttamente, attendi mentre ti reindirizziamo al login",
        "pwd_changed": "Password cambiata",
        "okay_cap": "Va bene",
    },
    "zh_CN": {
        "complete_your_account_details_below": "在下面填写您的帐户详细信息",
        "hi_key": "你好",
        "create_account_new_ui_desc": "请列出您的姓名并创建密码以继续",
        "user_first_name": "名",
        "crt_acnt_valid_first_name": "请输入名字。",
        "crt_acnt_limit_character_msg": "最多允许 30 个字符。",
        "new_account": "新账户",
        "sign_up_with_work_email": "使用您的工作电子邮件地址注册",
        "sign_up_with_work_email_or_userid": "使用您的工作电子邮件地址或用户名注册",
        "enter_your_work_email": "输入您的工作电子邮件",
        "enter_your_work_email_userid": "输入您的工作电子邮件或用户名",
        "enter_valid_email": "输入有效的电子邮件地址。",
        "valid_email_user_id": "输入有效的电子邮件地址或用户 ID。",
        "limit_exceeded": "超出限制",
        "sign_up": "注册",
        "already_have_account": "已经有账户？",
        "login": "登录",
        "invalid_email_user_id": "无效的电子邮件或用户 ID",
        "already_registerd": "已经注册",
        "invalid_email_user_id_msg_first": "抱歉，您输入的电子邮件地址或用户 ID 无效。",
        "invalid_email_user_id_msg_second": "请联系您的管理员或使用经批准的电子邮件/用户 ID。",
        "user_seems_already_registerd": "此用户似乎已注册。按继续继续。",
        "dismiss": "解雇",
        "continue": "继续",
        "user_last_name": "姓",
        "crt_acnt_enter_last_name": "请输入姓氏。",
        "crt_acnt_password": "密码",
        "password_validation_msg": "请输入有效密码。",
        "back": "后退",
        "crt_acnt_unable_signup_contact_your_admin": "无法注册，请联系您的公司管理员",
        "by_continuing_you_agree_terms_privacy_policy": "继续，即表示您同意我们的使用条款和隐私政策",
        "terms_of_use": "使用条款",
        "privacy_policy": "隐私政策",
        "permission_for_denied_contact_admin": "您没有执行此操作的适当权限，请联系您的管理员。",
        "min_12_char": "最少 12 个字符",
        "at_least_one_spec_char": "至少一个特殊字符",
        "at_least_one_num_char": "至少一个数字字符",
        "at_least_one_upper_char": "至少一个大写字符",
        "at_least_one_lower_char": "至少一个小写字符",
        "user_change_password": "更改密码",
        "crt_acnt_enter_password": "请输入密码。",
        "crt_acnt_enter_confirm_password": "请输入确认密码。",
        "crt_acnt_match_password_msg": "密码不匹配。",
        "user_enter_new_password": "输入新密码",
        "chng_pwd_reenter_new_pwd": "重新输入新的密码",
        "user_change": "改变",
        "pwd_changed_please_wait": "您的密码更改成功，正在将您重定向到登录，请稍候",
        "pwd_changed": "密码已更改",
        "okay_cap": "好的",
    },
    "ja_JP": {
        "complete_your_account_details_below": "アカウントの詳細を以下に入力してください",
        "hi_key": "やあ",
        "create_account_new_ui_desc": "続行するには、名前を入力してパスワードを作成してください",
        "user_first_name": "ファーストネーム",
        "crt_acnt_valid_first_name": "名を入力してください。",
        "crt_acnt_limit_character_msg": "最大 30 文字まで使用できます。",
        "new_account": "新しいアカウント",
        "sign_up_with_work_email": "仕事用のメールアドレスでサインアップ",
        "sign_up_with_work_email_or_userid": "仕事用のメール アドレスまたはユーザー ID でサインアップする",
        "enter_your_work_email": "仕事用のメールアドレスを入力してください",
        "enter_your_work_email_userid": "仕事用のメールアドレスまたはユーザー ID を入力してください",
        "enter_valid_email": "有効なメールアドレスを入力してください。",
        "valid_email_user_id": "有効なメールアドレスまたはユーザー ID を入力してください。",
        "limit_exceeded": "限度超過",
        "sign_up": "サインアップ",
        "already_have_account": "すでにアカウントをお持ちですか？",
        "login": "ログイン",
        "invalid_email_user_id": "メールアドレスまたはユーザー ID が無効です",
        "already_registerd": "すでに登録",
        "invalid_email_user_id_msg_first": "入力したメール アドレスまたはユーザー ID が無効です。",
        "invalid_email_user_id_msg_second": "管理者に連絡するか、承認済みの電子メール/ユーザー ID を使用してください。",
        "user_seems_already_registerd": "このユーザーはすでに登録されているようです。 [続行] を押して先に進みます。",
        "dismiss": "解散",
        "continue": "継続する",
        "user_last_name": "苗字",
        "crt_acnt_enter_last_name": "姓を入力してください。",
        "crt_acnt_password": "パスワード",
        "password_validation_msg": "有効なパスワードを入力してください。",
        "back": "戻る",
        "crt_acnt_unable_signup_contact_your_admin": "サインアップできません。会社の管理者に連絡してください",
        "by_continuing_you_agree_terms_privacy_policy": "続行すると、利用規約とプライバシー ポリシーに同意したことになります",
        "terms_of_use": "利用規約",
        "privacy_policy": "プライバシーポリシー",
        "permission_for_denied_contact_admin": "この操作を実行するための適切な権限がありません。管理者に連絡してください。",
        "min_12_char": "最小 12 文字",
        "at_least_one_spec_char": "少なくとも 1 つの特殊文字",
        "at_least_one_num_char": "少なくとも 1 つの数字",
        "at_least_one_upper_char": "少なくとも 1 つの大文字",
        "at_least_one_lower_char": "少なくとも 1 つの小文字",
        "user_change_password": "パスワードを変更する",
        "crt_acnt_enter_password": "パスワードを入力してください。",
        "crt_acnt_enter_confirm_password": "確認用のパスワードを入力してください。",
        "crt_acnt_match_password_msg": "パスワードが一致していません。",
        "user_enter_new_password": "新しいパスワードを入力してください",
        "chng_pwd_reenter_new_pwd": "新しいパスワードを再入力してください",
        "user_change": "変化する",
        "pwd_changed_please_wait": "パスワードが正常に変更されました。ログインにリダイレクトされるまでお待ちください。",
        "pwd_changed": "パスワード変更済み",
        "okay_cap": "わかった",
    }
}