import api from "./api";
import { APIs } from '../constants/Constants';

export default class OnLoadFunctionAPI {
    checkIsCompanyAvailable(req) {
        return api.get( APIs.ISCOMPANYAVAILABLE + encodeURIComponent(req.domainURL));
    }

    getJourneyList() {
        return api.get(APIs.GET_JOURNEY_LIST);
    }

    postLogOut(formData){
        return api.post(APIs.LOGOUT, formData);
    }

    getDomainList(){
        return api.get(APIs.GET_DOMAIN_LIST);
    }
}
