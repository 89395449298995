export const EVENTS = {
    VERIFY_EMAIL: 'VERIFY_EMAIL',
    CHECK_OTP: 'CHECK_OTP',
    AGAIN_SEND_OTP: 'AGAIN_SEND_OTP',
    CHECK_COMPANY: 'CHECK_COMPANY',
    CREATE_COMPANY: 'CREATE_COMPANY',
    INVITE_USER:'INVITE_USER'
};

export const VERIFY_EMAIL = {
    START: "VERIFY_EMAIL_START",
    SUCCESS: "VERIFY_EMAIL_SUCCESS",
    ERROR: "VERIFY_EMAIL_ERROR"
};

export const CHECK_OTP = {
    START: "CHECK_OTP_START",
    SUCCESS: "CHECK_OTP_SUCCESS",
    ERROR: "CHECK_OTP_ERROR"
};

export const AGAIN_SEND_OTP = {
    START: "AGAIN_SEND_OTP_START",
    SUCCESS: "AGAIN_SEND_OTP_SUCCESS",
    ERROR: "AGAIN_SEND_OTP_ERROR"
};

export const CHECK_COMPANY = {
    START: "CHECK_COMPANY_START",
    SUCCESS: "CHECK_COMPANY_SUCCESS",
    ERROR: "CHECK_COMPANY_ERROR"
};

export const CREATE_COMPANY = {
    START: "CREATE_COMPANY_START",
    SUCCESS: "CREATE_COMPANY_SUCCESS",
    ERROR: "CREATE_COMPANY_ERROR"
};

export const INVITE_USER = {
    START: "INVITE_USER_START",
    SUCCESS: "INVITE_USER_SUCCESS",
    ERROR: "INVITE_USER_ERROR"
};

const defaultVerifyEmailState = {
    verifyEmailError: false,
    verifyEmailLoading: false,
    verifyEmailData:false
};

const defaultCheckOTPState = {
    checkOTPError: false,
    checkOTPLoading: false,
    checkOTPData: false
};

const defaultAgainSendOTPState = {
    againSendOTPError: false,
    againSendOTPLoading: false,
    againSendOTPData: false
};

const defaultCheckCompany = {
    checkCompanyError: false,
    checkCompanyLoading: false,
    checkCompanyData: false
};

const defaultCreateCompany = {
    createCompanyError: false,
    createCompanyLoading: false,
    createCompanyData: false
};

const defaultInviteUser = {
    inviteUserError: false,
    inviteUserLoading: false,
    inviteUserData: false
};

const defaultState = {
    ...defaultVerifyEmailState,
    ...defaultCheckOTPState,
    ...defaultAgainSendOTPState,
    ...defaultCheckCompany,
    ...defaultCreateCompany,
    ...defaultInviteUser
};

const selfSignupReducer = (state = defaultState, action = {}) => {
    switch (action.event) {
        case EVENTS.VERIFY_EMAIL:
            return {
                ...state,
                verifyEmailLoading: action.type === VERIFY_EMAIL.START ,
                verifyEmailData: action.type === VERIFY_EMAIL.SUCCESS ? action.payload : false,
                verifyEmailError: action.type === VERIFY_EMAIL.ERROR ? action.payload : false,
            };
        case EVENTS.CHECK_OTP:
            return {
                ...state,
                checkOTPLoading: action.type === CHECK_OTP.START ,
                checkOTPData: action.type === CHECK_OTP.SUCCESS ? action.payload : false,
                checkOTPError: action.type === CHECK_OTP.ERROR ? action.payload : false,
            };
        case EVENTS.AGAIN_SEND_OTP:
            return {
                ...state,
                againSendOTPLoading: action.type === AGAIN_SEND_OTP.START ,
                againSendOTPData: action.type === AGAIN_SEND_OTP.SUCCESS ? action.payload : false,
                againSendOTPError: action.type === AGAIN_SEND_OTP.ERROR ? action.payload : false,
            };
        case EVENTS.CHECK_COMPANY:
            return {
                ...state,
                checkCompanyLoading: action.type === CHECK_COMPANY.START ,
                checkCompanyData: action.type === CHECK_COMPANY.SUCCESS ? action.payload : false,
                CheckCompanyError: action.type === CHECK_COMPANY.ERROR ? action.payload : false,
            };
        case EVENTS.CREATE_COMPANY:
            return {
                ...state,
                createCompanyLoading: action.type === CREATE_COMPANY.START ,
                createCompanyData: action.type === CREATE_COMPANY.SUCCESS ? action.payload : false,
                createCompanyError: action.type === CREATE_COMPANY.ERROR ? action.payload : false,
            };
        case EVENTS.INVITE_USER:
            return {
                ...state,
                inviteUserLoading: action.type === INVITE_USER.START ,
                inviteUserData: action.type === INVITE_USER.SUCCESS ? action.payload : false,
                inviteUserError: action.type === INVITE_USER.ERROR ? action.payload : false,
            };
        default:
            return state;
    }
}

export default selfSignupReducer;

